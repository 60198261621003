import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import { ADD_UPDATE_NEW_ON_SPOT_PACKAGES } from "../../Apis/new_on_spot_api";

function AddNewOnSpotPackage({
  open,
  handleOnClose,
  on_spot_roles_id,
  row,
  is_paid,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = React.useState(row ? row.price : null);
  const [qty, setQty] = React.useState(row ? row.qty : null);
  const [minutes, setMinutes] = React.useState(row ? row.minutes : null);

  // console.log(on_spot_roles_id);

  const handleOnSubmit = async () => {
    if (!+price && is_paid) {
      ToastShow("Please enter the price");
    } else if (!+qty) {
      ToastShow("Please enter the qty");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_NEW_ON_SPOT_PACKAGES({
        price: price,
        qty: qty,
        on_spot_roles_id: on_spot_roles_id,
        minutes: minutes,
        id: row?.id ?? 0,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!row?.id ? "Add Package" : "Update Package"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            is_paid ?
            
            <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Price"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
            :null
          }

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Qty"
            type="number"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
          />

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Minutes"
            type="number"
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddNewOnSpotPackage;
