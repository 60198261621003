import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_WEB_FEATURE = async (data) => {
  const formData = new FormData();


  if (data?.logo) {
    formData.append("logo", data.logo, "logo.png");
  }

  if (data?.row) {
    formData.append("id", data.row.id);
  }

  formData.append("name", data?.name);
  formData.append("description", data?.description);
  formData.append("url", data?.url);

  const res = await POSTAPI(
    data?.row?.id ? API_URL.webUpdateFeature : API_URL.webAddFeature,
    formData
  );
  return res;
};

export const GET_WEB_FEATURE = async () => {
  const res = await GETAPI(API_URL.webGetFeature);
  return res;
};

export const DELETE_WEB_FEATURE = async (id) => {
  const res = await GETAPI(API_URL.webDeleteFeature, "&id=" + id);
  return res;
};
