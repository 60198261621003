import React, { useEffect } from "react";
import { Button, Popover, Stack, Typography } from "@mui/material";
import { GrClose } from "react-icons/gr";
import DataGrideView from "../../../../Component/DataGrideView/DataGrideView";
import ButtonIcon from "../../../../Component/Button/ButtonIcon";
import add_button from "../../../../Assets/add_button.png";
import { useState } from "react";
import DiscountModal from "./DiscountModal";
import {
  ADD_UPDATE_CITY_DISCOUNT,
  DELETE_CITY_DISCOUNT,
  GET_CITY_DISCOUNT,
} from "../../../../Apis/city_api";
import ButtonForGrid from "../../../../Component/Button/ButtonForGrid";

const AddDiscountViewModal = ({
  open,
  anchorEl,
  handleClose,
  service_name,
  serviceID,
}) => {
  const [openDiscount, setOpenDiscount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const [discountsData, setDiscountsData] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      width: 60,
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>
            {params.row.renderID}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>
            {params.row.type === 1 ? "Hour" : "Amount"}
          </Typography>
        );
      },
    },
    {
      field: "applyOn",
      headerName: "Apply On",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="11.8px">{params.row.on}</Typography>;
      },
    },
    {
      field: "applicable",
      headerName: "Applicable",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="11.8px">{params.row.apply}</Typography>;
      },
    },
    {
      field: "maxAmount",
      headerName: "Max Amount",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="11.8px">{params.row.maxAmount}</Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <ButtonForGrid
            label="Delete"
            onClick={() => deleteServiceDiscount(params.row.id)}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            sx={{
              paddingLeft: "9px",
              paddingRight: "9px",
              borderRadius: "5px",
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              color: "#484848",
              textTransform: "none",
            }}
            onClick={() => {
              setSelectedRow(params.row);
              setOpenDiscount(1);
            }}
          >
            <img
              src={require("../../../../Assets/Edit_pencil.png")}
              style={{ objectFit: "fill", width: "11px" }}
              alt=""
            />
            <p style={{ fontSize: "11px" }}>Edit</p>
          </Button>
        );
      },
    },
  ];

  const handleSubmit = async ({
    type,
    applyOn,
    applicable,
    maxAmount,
    isPercentage,
    isClose,
  }) => {
    if (isClose) {
      const res = await ADD_UPDATE_CITY_DISCOUNT({
        cityServicesId: serviceID,
        id: selectedRow ? selectedRow.id : 0,
        type: type,
        apply: applyOn,
        applicable: applicable,
        isPercentage: isPercentage ? 1 : 0,
        maxAmount: maxAmount,
      });

      if (res && res.s === 1) {
        getServiceDiscount();
        console.log("Added");
      } else {
        console.log("Error");
      }
    }
    setSelectedRow(null);
  };

  const getServiceDiscount = async () => {
    const res = await GET_CITY_DISCOUNT(serviceID); //GET CITY > CATEGORY > SERVICE > DISCOUNT

    if (res && res.s === 1) {
      //To render sequential ID's in the Grid
      const modifiedData = res?.r?.map((row, index) => ({
        ...row,
        renderID: index + 1,
      }));

      setDiscountsData(modifiedData);
    } else {
      console.log("Error");
    }
  };

  const deleteServiceDiscount = async (servicesDiscountID) => {
    const res = await DELETE_CITY_DISCOUNT(servicesDiscountID);
    if (res && res.s === 1) {
      getServiceDiscount();
      console.log("Deleted successfully");
    } else {
      console.log("Error");
    }
  };

  useEffect(() => {
    getServiceDiscount();
  }, []);

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "#1C1000B2",
          height: "100vh",
        }}
      >
        <Stack
          sx={{
            width: "63vw",
            p: 1.9,
            gap: 2,
            bgcolor: "white",
            borderRadius: "5px",
          }}
        >
          <Stack
            direction="row"
            bgcolor="#F6F6F6"
            paddingY="0.450rem"
            paddingX="0.6rem"
            borderRadius="5px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="18px" fontWeight="500">
              {service_name} Discount
            </Typography>
            <GrClose
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{
              bgcolor: "#F6F6F6",
              p: 1,
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "6px",
            }}
          >
            <ButtonIcon
              icon={add_button}
              label="Add Discount"
              bgcolor="#FFAB30"
              gap="0.3rem"
              width="9.5rem"
              onClick={() => setOpenDiscount(1)}
            />
          </Stack>

          <DataGrideView
            rows={discountsData ?? []}
            columns={columns}
            colHeight={"3.4rem"}
            columnColor="#F6F6F6"
            hideFooter={true}
            sx={{ height: "83.8vh" }}
          />
        </Stack>
      </Popover>

      {openDiscount && (
        <DiscountModal
          open={openDiscount}
          handleClose={() => {
            setSelectedRow(null);
            setOpenDiscount(0);
          }}
          handleSubmit={handleSubmit}
          row={selectedRow}
        />
      )}
    </>
  );
};

export default AddDiscountViewModal;
