import { Typography, Button } from "@mui/material";
import React from "react";

const ButtonForGrid = ({
  label,
  onClick,
  bgcolor = "#FFD393",
  fontWeight,
  isDisable,
}) => {
  return (
    <Button
      sx={{
        bgcolor: !isDisable ? bgcolor : "#DCDCDC",
        borderRadius: "5px",
        height: "1.8rem",
        "&:hover": {
          bgcolor: "#c2a276",
        },
      }}
      disabled={isDisable}
      onClick={onClick}
    >
      <Typography
        sx={{
          textTransform: "capitalize",
          fontSize: "11.8px",
          fontWeight,
          color: isDisable ? "#989a9c" : "default",
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default ButtonForGrid;
