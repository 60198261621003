import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import { ADD_VIDEO_STORY } from "../../Apis/video_api";
import MultiSelection from "../Multiselection/MultiSelection";
import { ADD_CITIES } from "../../Apis/add_cities";
import ButtonView from "../Button/ButtonView";
import { BsChevronDown } from "react-icons/bs";

function AddCityServiceArea({ open, handleOnClose, cities, citiesW, id, c }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = React.useState([]);

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities(value);
  };

  const handleOnSubmit = async () => {
    if (!selectedCities.length && !id) {
      ToastShow("Please select city");
    } else {
      const c = citiesW
        .filter((v) => selectedCities.includes(v.name))
        .map((v) => v.id);
      console.log(c);
      setIsLoading(true);
      const d = await ADD_CITIES(c.join(","), id);
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    // console.log(c);
    const t = [];
    if (c && c.length) {
      for (let name of c) {
        t.push(name.name);
      }
    }
    setSelectedCities(t);
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Typography
          sx={{ fontSize: "19px", fontWeight: "500", mx: 1.5, mt: 1 }}
        >
          Update area
        </Typography>
        <Box
          sx={{
            mx: 1.5,
            mt: 1,
            mb: 0.8,
          }}
        >
          <FormControl
            sx={{
              width: "280px",
              borderRadius: "5px",
              "& fieldset": { border: "none" },
              // "& .css-1cdoino-MuiInputBase-root-MuiOutlinedInput-root": {
              //   fontSize: "0.720rem",
              // },
              "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  fontSize: "0.720rem",
                },
              "& .css-3qbkez-MuiSelect-icon": {
                width: "0.688rem",
              },
              "& .css-10q54uo-MuiSelect-icon": {
                width: "0.688rem",
              },
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  fontSize: "0.720rem",
                },
            }}
          >
            <Select
              disabled={false}
              defaultValue=""
              multiple={true}
              value={selectedCities}
              onChange={handleSelectCity}
              input={<OutlinedInput label={"Cities"} />}
              renderValue={(select) => select.join(", ")}
              style={{
                backgroundColor: "#F6F6F6",
                color: "#969696",
                borderRadius: "6px",
                height: "2.5rem",
              }}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
              IconComponent={BsChevronDown}
              // MenuProps={MenuProps}
            >
              {cities.map((name, i) => (
                <MenuItem
                  key={i}
                  value={name}
                  sx={{
                    boxShadow: "0px 0.3px 0px 0px #00000040",
                  }}
                >
                  <Checkbox
                    sx={{ padding: "0", marginRight: ".4rem" }}
                    icon={
                      <IconButton
                        sx={{
                          border: "1px solid black",
                          padding: "0",
                          border: "0",
                        }}
                      >
                        <img
                          src={require("../../Assets/Unchecked_2.png")}
                          style={{ width: "13px", objectFit: "fill" }}
                          alt=""
                        />
                      </IconButton>
                    }
                    checkedIcon={
                      <IconButton
                        sx={{
                          border: "1px solid black",
                          padding: "0",
                          border: "0",
                        }}
                      >
                        <img
                          src={require("../../Assets/CheckedIcon.png")}
                          style={{ width: "13px", objectFit: "fill" }}
                          alt=""
                        />
                      </IconButton>
                    }
                    checked={selectedCities.indexOf(name) > -1}
                  />
                  <Typography fontSize="14px">{name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <DialogActions>
          <ButtonView
            lable="Cancel"
            color="#E50000"
            bgcolor="#E5000033"
            fontSize="14px"
            sx={{ width: "100%" }}
            hoverBg="pink"
            onClick={() => handleOnClose(0)}
          />

          <ButtonView
            lable="Update"
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={handleOnSubmit}
          />
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddCityServiceArea;
