import * as React from "react";
import SplitButton from "../../../Component/Button/SplitButton";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";

const columns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "poNo", headerName: "PONO", width: 90, type: "number" },
  { field: "poDate", headerName: "PODate", width: 70 },
  { field: "photographer", headerName: "Photographer", width: 120 },
  { field: "name", headerName: "Name", width: 130 },
  {
    field: "role",
    headerName: "Role",
    width: 90,
  },
  {
    field: "city",
    headerName: "City",
    width: 90,
    renderCell: (params) => (
      <p style={{ textAlign: "start", whiteSpace: "normal" }}>{params.value}</p>
    ),
  },
  {
    field: "typeshot",
    headerName: "Type/Shot",
    width: 180,
    renderCell: (params) => (
      <p style={{ textAlign: "start", whiteSpace: "normal" }}>{params.value}</p>
    ),
  },
  {
    field: "shootingDate",
    headerName: "ShootingDate",
    width: 130,
    renderCell: (params) => (
      <p style={{ textAlign: "start", whiteSpace: "normal" }}>{params.value}</p>
    ),
  },
  {
    field: "hrs",
    headerName: "Hrs",
    width: 90,
    type: "number",
  },
  {
    field: "address",
    headerName: "Address",
    width: 180,
    renderCell: (params) => (
      <p style={{ textAlign: "start", whiteSpace: "normal" }}>{params.value}</p>
    ),
  },
  {
    field: "total",
    headerName: "Total",
    width: 90,
    type: "number",
  },
  {
    field: "payBy",
    headerName: "PayBy",
    width: 90,
  },
  {
    field: "payStatus",
    headerName: "PayStatus",
    width: 90,
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
  },
  {
    field: "action",
    headerName: "Action",
    width: 150,
    renderCell: (params) => (
      <SplitButton key={params.id} title={params.value} />
    ),
  },
];

const rows = [
  {
    id: "1",
    poNo: 1,
    poDate: "Snow",
    photographer: "Jon",
    name: "madhvi chhabra",
    role: "Customer",
    city: "Ahmedabad",
    typeshot: "-Events-Birthday-Basic Plan @1499 per hour-Indoor",
    shootingDate: "01/08/2022 : 17:45-18:45",
    hrs: 1,
    address: "ujarat 380013, India   AEC cross road",
    total: 1354.64,
    payBy: "PAS",
    payStatus: "Paid",
    status: "Competed",
  },
  {
    id: "2",
    poNo: 1,
    poDate: "Snow",
    photographer: "Jon",
    name: "madhvi chhabra",
    role: "Customer",
    city: "Ahmedabad",
    typeshot: "-Events-Birthday-Basic Plan @1499 per hour-Indoor",
    shootingDate: "01/08/2022 : 17:45-18:45",
    hrs: 1,
    address:
      "A 502   suryakutir naranpura aec CROSS road Ahmedabad Gujrat 380000, Gopal Nagar, Naranpura, Ahmedabad, Gujarat 380013, India   AEC cross road, A 502   suryakutir naranpura aec CROSS road Ahmedabad Gujrat 380000, Gopal Nagar, Naranpura, Ahmedabad, Gujarat 380013, India   AEC cross road",
    total: 1354.64,
    payBy: "PAS",
    payStatus: "Paid",
    status: "Competed",
  },
  {
    id: "3",
    poNo: 1,
    poDate: "Snow",
    photographer: "Jon",
    name: "madhvi chhabra",
    role: "Customer",
    city: "Ahmedabad",
    typeshot: "-Events-Birthday-Basic Plan @1499 per hour-Indoor",
    shootingDate: "01/08/2022 : 17:45-18:45",
    hrs: 1,
    address: "ndia   AEC cross road",
    total: 1354.64,
    payBy: "PAS",
    payStatus: "Paid",
    status: "Competed",
  },
];

export default function InqueryList() {
  return <DataGrideView rows={rows} columns={columns} />;
}
