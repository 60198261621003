import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CustomerSubmitLinkModal from "./CustomerSubmitLinkModal";

const CustomerSubmitLink = ({ handleOpenAddLink }) => {
  return (
    <>
      <Box
        sx={{
          width: "37%",
          height: "2.370rem",
          paddingX: "0.8rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "#FFAB30",
          borderRadius: "5px",
          boxShadow: 4,
        }}
      >
        <Typography fontSize="15px" fontWeight="500">
          Customer submit link
        </Typography>

        <Button
          sx={{
            bgcolor: "white",
            fontSize: "10px",
            color: "black",
            minWidth: "0px",
            paddingX: "0.7rem",
            height: "1.5rem",
            borderRadius: "5px",
            "&:hover": {
              bgcolor: "#bfbebb",
            },
          }}
          onClick={() => handleOpenAddLink(null)}
        >
          Add Link
        </Button>
      </Box>
      {/* <CustomerSubmitLinkModal open={open} setOpen={setOpen} /> */}
    </>
  );
};

export default CustomerSubmitLink;
