import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import {
  ACTIVATE_DEACTIVATE_ACCOUNT,
  GETBANKDETAILS,
} from "../../../../../Apis/service_api";
import ButtonForGrid from "../../../../../Component/Button/ButtonForGrid";

function BankingDetails({ userID }) {
  const [data, setData] = useState([]);

  const rows = data?.map((row, index) => ({
    ...row,
    rowId: index + 1,
  }));

  const getData = async () => {
    const res = await GETBANKDETAILS(userID); //ID 4 for try

    if (res && res.s === 1) {
      console.log(res.r);
      setData(res.r.items.length ? res.r.items : []);
    } else {
      console.log("error");
    }
  };

  const handleActiveDeactivateAccount = async (id, status) => {
    const data = { id, status };
    const res = await ACTIVATE_DEACTIVATE_ACCOUNT(data);
    if (res && res.s === 1) {
      getData();
    } else {
      console.log("error");
    }
  };

  const cols = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.row.rowId}</Typography>;
      },
    },
    {
      field: "account_type",
      headerName: "Account Type",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.account_type}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.account_type === "vpa"
              ? params.row.vpa.username
              : params.row.bank_account.name}
          </Typography>
        );
      },
    },
    {
      field: "ifsc",
      headerName: "IFSC Code",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.account_type !== "vpa"
              ? params.row.bank_account.ifsc
              : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "account_number",
      headerName: "Account Number",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.account_type !== "vpa"
              ? params.row.bank_account.account_number
              : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "active",
      headerName: "Status",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Typography
          color={params.row.active ? "#0DA800" : "red"}
          fontSize="12.4px"
        >
          {params.row.active ? "Active" : "Inactive"}
        </Typography>
      ),
    },
    {
      field: "button",
      headerName: "Active/Deactivate",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Button
          sx={{
            bgcolor: params.row.active ? "red" : "#0DA800",
            borderRadius: "5px",
            height: "1.8rem",
            "&:hover": {
              bgcolor: params.row.active ? "#e61002" : "#0d9902",
            },
          }}
          onClick={() =>
            handleActiveDeactivateAccount(
              params.row.id,
              params.row.active === true ? 0 : 1
            )
          }
        >
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "11.8px",
              color: "white",
            }}
          >
            {params.row.active ? "Deactivate" : "Activate"}
          </Typography>
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  console.log("BD", data);

  return (
    <Box width="100%" boxShadow="3px 3px 7px 0px #00000040">
      <DataGrideView columns={cols} rows={rows ?? []} />
    </Box>
  );
}

export default BankingDetails;
