import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GrClose } from "react-icons/gr";
import ButtonView from "../../../Component/Button/ButtonView";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { BsChevronDown } from "react-icons/bs";
import SelectComponent from "../../../Component/SelectComponent/SelectComponent";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import TextFieldLarge from "../../../Component/TextFields/TextFieldLarge";
import DateField from "../../../Component/DateField/DateField";
import timer_icon from "../../../Assets/timer_icon.png";

const cities = ["Ahemdabad", "Mumbai", "Bangalore", "Jaipur", "Baroda"];

const AddOnSpotModal = ({ open, setOpen, categories, categoriesW }) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [inputs, setInputs] = useState({
    image: "",
    city: "",
    address: "",
    description: "",
    date: "",
    fromTime: "",
    toTime: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  /*For Image Selection*/
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [netWorkImage, setNetWorkImage] = React.useState(null);
  const handleSelectImage = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
      setInputs({ ...inputs, image: event.target.files[0] });
    }
  };

  /*Dates*/
  const [selectDate, setSelectDate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  // console.log(selectDate);
  // console.log(fromTime);
  // console.log(toTime);

  const handleSubmit = () => {
    console.log(inputs);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ backdropFilter: "blur(4px)" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "6px",
            display: "flex",
            paddingX: "1rem",
            alignItems: "start",
            height: "92vh",
            overflowY: "auto",
            overflowX: "hidden",
            paddingY: "1.1rem",
          }}
        >
          <Box
            sx={{
              width: "31.25rem",
              display: "flex",
              flexDirection: "column",
              gap: ".8rem",
            }}
          >
            <Typography
              sx={{
                backgroundColor: "#F6F6F6",
                width: "100%",
                height: "40px",
                padding: "10px",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Add OnSpot
              <GrClose
                style={{ cursor: "pointer" }}
                onClick={() => setOpen(false)}
              />
            </Typography>

            <Box
              sx={{
                bgcolor: "#F6F6F6",
                width: "100%",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                gap: ".8rem",
                alignItems: "center",
                paddingY: ".7rem",
              }}
            >
              <Box
                sx={{
                  bgcolor: "white",
                  width: "96%",
                  height: "10rem",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/*SELECT IMAGE*/}
                <Box
                  sx={{
                    width: "9rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "white",
                    borderRadius: "6px",
                  }}
                >
                  {!selectedImage && !netWorkImage && (
                    <Typography color="#595959" fontSize="11.9px" mb=".1rem">
                      Select Image
                    </Typography>
                  )}
                  <Button
                    sx={{
                      width: "60px",
                      height: "60px",
                      boxShadow: 0,
                      borderRadius: 3,
                      backgroundColor:
                        !selectedImage && !netWorkImage ? "#F6F6F6" : "white",
                      "&:hover": {
                        backgroundColor:
                          !selectedImage && !netWorkImage ? "#D3D3D3" : "white",
                        boxShadow: "none",
                      },
                    }}
                    variant="contained"
                    component="label"
                    disableRipple
                  >
                    <input
                      hidden
                      type="file"
                      accept="image/png, image/jpeg"
                      cId="select-image"
                      placeholder="asdf"
                      onChange={handleSelectImage}
                    />
                    {selectedImage || netWorkImage ? (
                      <Box
                        sx={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: ".8rem",
                        }}
                      >
                        <img
                          style={{ width: "160px", objectFit: "fill" }}
                          height="auto"
                          src={
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : netWorkImage
                          }
                          alt=""
                        />
                      </Box>
                    ) : (
                      <img
                        src={require("../../../Assets/img-placeholder.png")}
                        alt="Select"
                        width="35px"
                      />
                    )}
                  </Button>
                </Box>
              </Box>

              {/*City Select*/}
              <SelectComponent
                label="City"
                name="city"
                value={inputs.city}
                onChange={handleChange}
                menuItems={cities}
                defaultValue="Select City"
                width="96%"
                inputWidth="96%"
              />

              {/*Address*/}
              <TextFieldLarge
                label="Address"
                width="96%"
                placeholder="Enter address"
                type="text"
                name="address"
                value={inputs.address}
                onChange={handleChange}
              />

              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "5px",
                  width: "96%",
                  height: "8rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  width="96%"
                  display="flex"
                  flexDirection="column"
                  gap=".3rem"
                  height="100%"
                  paddingTop=".4rem"
                >
                  <Typography fontSize="12px">Description</Typography>

                  <TextField
                    multiline
                    rows={4}
                    maxRows={4}
                    placeholder="Enter..."
                    type="text"
                    size="small"
                    sx={{
                      width: "500px",
                      height: "92px",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      width: "100%",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="description"
                    value={inputs.description}
                    onChange={handleChange}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "6px",
                  width: "96%",
                  height: "4.3rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  py: "8px",
                }}
              >
                <Box width="96%">
                  <Box
                    display="flex"
                    gap=".5rem"
                    alignItems="center"
                    mb=".1rem"
                    width="90%"
                    ml=".2rem"
                  >
                    <img
                      src={require("../../../Assets/calendar-icon.png")}
                      alt=""
                      style={{ width: "10px", objectFit: "fill" }}
                    />
                    <Typography fontSize="12px">Select Date</Typography>
                  </Box>

                  <Box sx={{ backgroundColor: "#F6F6F6", borderRadius: "6px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={selectDate}
                        onChange={(newValue) => setSelectDate(newValue)}
                        renderInput={(props) => (
                          <TextField
                            sx={{
                              width: "100%",
                              height: "2.3rem",
                              display: "flex",
                              justifyContent: "center",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  fontSize: "0.8rem",
                                },
                            }}
                            size="small"
                            {...props}
                          />
                        )}
                        disableOpenPicker
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>

              {/*From Time & To Time*/}
              <Box
                sx={{
                  width: "96%",
                  height: "4.4rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    width: "48.55%",
                    borderRadius: "6px",
                    height: "100%",
                    padding: ".2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: "8px",
                  }}
                >
                  <DateField
                    label="From Time"
                    value={fromTime}
                    setValue={setFromTime}
                    width="94.5%"
                    icon={timer_icon}
                  />
                </Box>

                <Box
                  sx={{
                    bgcolor: "white",
                    width: "48.55%",
                    borderRadius: "6px",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: "8px",
                  }}
                >
                  <DateField
                    label="To Time"
                    value={toTime}
                    setValue={setToTime}
                    width="93%"
                    icon={timer_icon}
                  />
                </Box>
              </Box>
            </Box>

            <ButtonView
              lable="Add OnSpot"
              sx={{
                borderRadius: "6px",
                height: "2.6rem",
              }}
              fontSize="16px"
              fontWeight="500"
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddOnSpotModal;
