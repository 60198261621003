import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";
import { ADD_COIN } from "../../Apis/add_coin";

function AddCoinDailog({ open, handleOnClose,userId }) {
  const [coin, setCoin] = useState("");
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCoin = (v) => {
    setCoin(v);
  };

  const handleNote = (v) => {
    setNote(v);
  };

  const handleOnSubmit = async () => {
    if (!coin) {
      ToastShow("Please enter the coin.");
      return;
    } else if (+coin <= 0) {
      ToastShow("Please enter the valid coin.");
      return;
    } else {
      const res = await ADD_COIN(userId, coin, note);

      if (res && res.s) {
        handleOnClose(1);
      } else {
        ToastShow(res?.m ?? "Opps! something went wrong. Please try again.");
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => handleOnClose(0)}>
      <DialogTitle sx={{}}>Add Coin</DialogTitle>

      <DialogContent>
        <Box sx={{ width: "100%", mt: 1 }}>
          <TextFieldView
            value={coin}
            onchange={handleCoin}
            label="Coin"
            type="number"
            sx={{ width: "100%", my: 2 }}
            width="100%"
          />

          <TextFieldView
            value={note}
            onchange={handleNote}
            label="Note"
            type="text"
            sx={{ width: "100%" }}
            width="100%"
          />
        </Box>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default AddCoinDailog;
