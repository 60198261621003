import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import ButtonIcon from "../Button/ButtonIcon";
import add_button from "../../Assets/add_button.png";

const DataGridCover = ({
  component,
  title = "Service Provider List",
  enableButton,
  buttonLabel,
  onClick,
  rightChildren,
  buttonWidth = "13rem",
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "3px 3px 7px 0px #00000040",
        paddingTop: "14px",
        gap: "7px",
      }}
    >
      <Box
        sx={{
          width: "97.5%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: ".1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "18px",
          }}
        >
          {title}
        </Typography>
        {rightChildren}

        {enableButton && (
          <ButtonIcon
            width={buttonWidth}
            gap="0.5rem"
            bgcolor="#F8AF41"
            icon={add_button}
            label={buttonLabel}
            onClick={onClick}
          />
        )}
      </Box>

      <Box width="97.5%">{component}</Box>
    </Box>
  );
};

export default DataGridCover;
