import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import React from "react";
import { useState } from "react";
import { ADD_NOTIFICATION } from "../../Apis/notification_api";
import { ToastShow } from "../Toast/Toast";

function EditNotification({ open, handleOnClose, row }) {
  const [title, setTitle] = React.useState(row.title);
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState(row.message);
  const [dateTime, setDateTime] = useState(Date.parse(row.date_time));
  const [notificationFor, setNotificationFor] = useState(row.app);

  const handleDateTime = (v) => {
    setDateTime(v);
  };

  const handleChange = (e) => {
    setNotificationFor(e.target.value);
  };

  const addNotification = async () => {
    if (!title) {
      ToastShow("Please fill title");
    } else if (!message) {
      ToastShow("Please fill message");
    } else {
      setIsLoading(true);
      const d = await ADD_NOTIFICATION({
        title: title,
        message: message,
        app: notificationFor,
        date_time: format(dateTime, "yyyy-MM-dd hh:mm:ss"),
        id: row.id,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Added successfully");
        handleOnClose(1);
      } else {
        ToastShow("Opps! something went wrong please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{"Edit Notification"}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             
            }}
          >
            <Box
              sx={{
                width: "100%",
                p: 5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel id="notificationFor">Notification for</InputLabel>
                <Select
                  labelId="notificationFor"
                  id="selection"
                  label="Notification for"
                  value={notificationFor}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>All</MenuItem>
                  <MenuItem value={1}>Customer</MenuItem>
                  <MenuItem value={2}>Provider</MenuItem>
                  <MenuItem value={3}>Admin & Support</MenuItem>
                </Select>
              </FormControl>

              <TextField
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                fullWidth
                label="Title"
              />
              <TextField
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                fullWidth
                label="Message"
                sx={{ my: 3 }}
                rows={5}
                multiline={true}
              />

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Schedule (Default is now)"
                  value={dateTime}
                  onChange={handleDateTime}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button onClick={addNotification} variant="text">
                {"Update"}
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EditNotification;
