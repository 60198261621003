import { Box, Fab, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { GET_CITIE } from "../../../Apis/city_api";
import {
  DELETE_PRAMOTIONAL_BANNER,
  GET_PRAMOTIONAL_BANNER,
} from "../../../Apis/pramotional_banner_api";
import AddBanner from "../../../Component/Dailog/AddBannerDailog";
import { API_URL } from "../../../Apis/config";
import { Delete, Edit } from "@mui/icons-material";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { ToastShow } from "../../../Component/Toast/Toast";

function PamotionalBanner() {
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [cityId, setSelectedId] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [bannerId, setBannerId] = React.useState(0);
  const [src, setSrc] = React.useState("");
  const [url, setUrl] = React.useState("");

  const handleOpen = (v = 0, src = "", url = "") => {
    if (v) {
      setSrc(src);
      setUrl(url);
      setBannerId(v);
    }
    setOpen(true);
  };

  const handleOpenConfirmation = (id) => {
    setBannerId(id);
    setOpenConfirmation(true);
  };

  const handleOnClose = (v) => {
    setSrc("");
    setUrl("");
    setBannerId(0);
    if (v) {
      getData(cityId, 0);
    }
    setOpen(0);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const d = await DELETE_PRAMOTIONAL_BANNER(bannerId);
      if (d && d.s) {
        ToastShow("Deleted successfully");
      }
      setBannerId(0);
      getData(cityId, 0);
    }
    setOpenConfirmation(0);
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
      setSelectedId(t.r[0].id);
      setSelectedCities([t.r[0].name]);
      getData(t.r[0].id, 0);
    }
  }

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(cityId, 1);
    }
  };

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    const c = citiesW.filter((v) => v.name == value);
    setSelectedId(c.length ? c[0].id : 0);
    await getData(c.length ? c[0].id : 0, 0);
  };

  async function getData(id, v) {
    if (!v) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GET_PRAMOTIONAL_BANNER(v ? rows.length : 0, id);

    if (t && t.s == 1) {
      if (v) {
        setRows([...rows, ...t.r]);
      } else {
        if (t.r == null) {
          setRows([]);
        } else {
          setRows([...t.r]);
        }
      }
    }

    if ((t.r ?? []).length == 0) {
      setIsCall(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getCities();
  }, []);

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "80vh",
      }}
    >
      <MultiSelection
        multiple={false}
        names={cities}
        handleChange={handleSelectCity}
        selected={selectedCities}
        lable="Cities"
      />
      <Box display="flex" flexWrap="wrap">
        {rows.map((v) => {
          return (
            <Box
              key={v.id}
              sx={{
                position: "relative",
                m: 3,
                mb: 6,
                height: "200px",
                width: "300px",
                objectFit: "cover",
                transition: "all",
                transitionDuration: "1000ms",
                boxShadow: " 2px 3px 15px 2px #754f4f7c",
                "& div": {
                  transform: "scale(1)",
                  transition: "all",
                  transitionDuration: "1000ms",
                },
                "&:hover div": {
                  display: "flex",
                  transform: "scale(1.1)",
                },
              }}
            >
              <Box
                key={v.id + "123"}
                height="200px"
                width="300px"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                }}
                onClick={() => {
                  if (v.url) {
                    window.open(v.url, "_blank");
                  }
                }}
              >
                <img
                  height="100%"
                  width="100%"
                  src={API_URL.baseUrl + API_URL.bannerImage + v.file}
                />
              </Box>
              {v.url ? (
                <Typography
                  color="blue"
                  sx={{
                    width: "300px",
                    height: "60px",
                    overflow: "hidden",
                    wordWrap: "break-word",
                    cursor: "pointer",
                    p: 1,
                    mb: 1,
                    bgcolor: "#fff",
                  }}
                >
                  {v.url}
                </Typography>
              ) : null}

              <Box
                sx={{
                  top: 5,
                  right: 10,
                  position: "absolute",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleOpen(v.id, v.file, v.url ?? "")}
                  sx={{
                    mr: 1,
                    backgroundColor: "#ffffff63",
                  }}
                >
                  <Edit />
                </IconButton>

                <IconButton
                  size="small"
                  onClick={() => handleOpenConfirmation(v.id)}
                  sx={{
                    backgroundColor: "#ffffff63",
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          );
        })}

        <Fab
          onClick={() => handleOpen()}
          sx={{
            position: "absolute",
            bottom: 30,
            right: 30,
          }}
        >
          <AddIcon />
        </Fab>

        {open ? (
          <AddBanner
            cities={cities}
            citiesW={citiesW}
            cityId={cityId}
            open={open}
            id={bannerId}
            handleOnClose={handleOnClose}
            src={src}
            urls={url}
          />
        ) : null}

        {openConfirmation ? (
          <ConfirmationDailog
            open={openConfirmation}
            handleOnClose={handleOnCloseConfirmation}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default PamotionalBanner;
