import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import MultiSelection from "../Multiselection/MultiSelection";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";
import { GETROLE } from "../../Apis/podian_api";
import { ADD_UPDATE_SERVICE } from "../../Apis/category_api";
import ReactQuill from "react-quill";
import TextFieldSmall from "../TextFields/TextFieldSmall";
import RadioButtonSmall from "../RadioButton/RadioButtonSmall";
import Selection from "../Multiselection/Selection";
import ButtonView from "../Button/ButtonView";
import { GrClose } from "react-icons/gr";
import RadioButtonNew from "../RadioButton/RadioButtonNew";

let podianRole = { r: [] };
function AddServiceDailog({ open, handleOnClose, cId, row, service }) {
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const [isLoading, setIsLoading] = React.useState(false);
  const [isOnSite, setIsOnSite] = React.useState(row ? row.isOnSite : 2);
  const [isNormal, setIsNormal] = React.useState(row ? row.type : 0);
  const [isInventory, setIsInventory] = React.useState(
    row ? row.isInventory : 2
  );
  const [roles, setRoles] = React.useState([]);
  const [rolesSelected, setRolesSelected] = React.useState([]);
  const [isLoadingP, setIsLoadingP] = React.useState(true);
  const [name, setName] = React.useState(row ? row.name : "");
  const [description, setDescription] = React.useState(
    row ? row.description : ""
  );
  const [price, setPrice] = React.useState(row ? row.price : "");
  // const [isFixed, setIsFixed] = React.useState(row ? row.isFixed : 2);
  // const [service, setService] = React.useState(
  //   row ? (row.isFixed ? 1 : row.isExtra ? 2 : 3) : 0
  // );
  // const [isPost, setIsPost] = React.useState(row ? row.isPost : 2);
  const [isQty, setIsQty] = React.useState(row ? row.isQty : 2);
  const [isPerHour, setIsPerHour] = React.useState(row ? row.isPerHour : 2);
  // const [isExtra, setIsExtra] = React.useState(row ? row.isExtra : 2);
  const [childQty, setChildQty] = useState(row ? row.child_qty : 0);
  const [isRequiredRowData, setIsRequiredRowData] = useState(
    row ? row.isRequiredRowData : 0
  );
  const [isRequiredEditedData, setIsRequiredEditedData] = useState(
    row ? row.isRequiredEditedData : 0
  );

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please fill name");
    } else if (!description) {
      ToastShow("Please fill description");
    } else if (!price) {
      ToastShow("Please fill price");
    }
    // else if (!service) {
    //   ToastShow("Please select service");
    // }
    else {
      setIsLoading(true);
      let ids = [];
      if (rolesSelected.length) {
        podianRole.r.map((v) => {
          if (rolesSelected.includes(v.name)) {
            ids.push(v.id);
          }
        });
      }

      const res = await ADD_UPDATE_SERVICE({
        name: name,
        description: description,
        price: price,
        roleIds: ids.join(","),
        cId: cId ?? 0,
        id: row ? row.id : null,
        isExtra: service == 1 ? 1 : 0,
        isFixed: service == 0 ? 1 : 0,
        isInventory: isInventory == 1 ? 1 : 0,
        isOnSite: isOnSite == 1 ? 1 : 0,
        isPerHour: isPerHour,
        isPost: service == 2 ? 1 : 0,
        isQty: isQty,
        serviceType: isNormal,
        child_qty: childQty,
        isRequiredRowData: isRequiredRowData,
        isRequiredEditedData: isRequiredEditedData,
      });
      setIsLoading(false);
      if (res && res.s == 1) {
        handleOnClose(1);
      }
      // window.location.reload();
      ToastShow("added successfully!");
    }
  };

  const handleSelectPodian = (e) => {
    const {
      target: { value },
    } = e;
    setRolesSelected([...value]);
  };

  async function getRoles() {
    if (row) {
      podianRole.r = row.roles;

      const names = [];

      for (let v of row.roles) {
        names.push(v.name);
      }
      setRoles([...names]);

      const d = [];
      row.roles.map((v) => {
        if (v.status) {
          d.push(v.name);
        }
      });

      setRolesSelected([...d]);
      setIsLoadingP(false);
    } else {
      setIsLoadingP(true);
      const d = await GETROLE();
      podianRole = d;
      const names = [];

      for (let v of d.r) {
        names.push(v.name);
      }
      setRoles([...names]);
      setIsLoadingP(false);
    }
  }

  useEffect(() => {
    podianRole = { r: [] };
    getRoles();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Box
          sx={{
            borderRadius: "6px",
            display: "flex",
            padding: "0.580rem",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.6rem",
          }}
        >
          <Stack
            width="100%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.4rem"
            px="0.5rem"
          >
            <Typography
              fontWeight="500"
              fontSize="15px"
              sx={{ userSelect: "none" }}
            >
              {!row ? "Add Service" : "Edit Service"}
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={() => handleOnClose(0)}
            />
          </Stack>

          <Stack
            width="100%"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="center"
            alignItems="center"
            py="0.5rem"
            px="0.580rem"
            gap="0.7rem"
          >
            <TextFieldSmall
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Name"
              type="text"
              placeholder="Enter Service Name"
              bgColor="white"
              width="99.8%"
              height="4.250rem"
              inputFieldWidth="98%"
              inputHeight="2.180rem"
            />
            <TextFieldSmall
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              label="Service Price"
              type="number"
              placeholder="Enter Price Service"
              bgColor="white"
              width="99.8%"
              height="4.250rem"
              inputFieldWidth="98%"
              inputHeight="2.180rem"
            />

            <Box
              width="100%"
              sx={{
                bgcolor: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                pb: "0.4rem",
                "& .ql-container": {
                  height: "auto",
                  resize: "both",
                  backgroundColor: "white",
                  borderBottomLeftRadius: "6px",
                  borderBottomRightRadius: "6px",
                },
                "& .ql-toolbar": {
                  backgroundColor: "#EFEFEF",
                  borderTopLeftRadius: "6px",
                  borderTopRightRadius: "6px",
                },
              }}
            >
              <Typography
                sx={{
                  textAlign: "start",
                  fontSize: "0.8rem",
                  ml: "0.4rem",
                  mb: "0.1rem",
                  mt: "0.2rem",
                  alignSelf: "flex-start",
                }}
              >
                Description
              </Typography>
              <ReactQuill
                style={{ width: "98%" }}
                theme="snow"
                value={description}
                onChange={(e) => {
                  setDescription(e);
                }}
                formats={formats}
              />
            </Box>

            <Stack
              sx={{
                width: "99.8%",
                bgcolor: "white",
                borderRadius: "6px",
                justifyContent: "center",
                alignItems: "center",
                paddingY: "8px",
                gap: "0.3rem",
              }}
            >
              {/*Row 1*/}
              <Stack direction="row" width="98%" gap={1.5}>
                <Stack width="100%">
                  <Typography
                    style={{ fontSize: "11.8px", userSelect: "none" }}
                  >
                    Can book in multiple quantity for order?
                  </Typography>
                  <RadioButtonSmall value={isQty} handleChange={setIsQty} />
                </Stack>

                <Stack width="100%">
                  <Typography
                    style={{ fontSize: "11.8px", userSelect: "none" }}
                  >
                    Is this service can charge hourly?
                  </Typography>
                  <RadioButtonSmall
                    value={isPerHour}
                    handleChange={setIsPerHour}
                  />
                </Stack>
              </Stack>

              {/*Row 2*/}
              <Stack direction="row" width="98%" gap={1.5}>
                <Stack width="100%">
                  <Typography
                    style={{ fontSize: "11.8px", userSelect: "none" }}
                  >
                    PODIAN must be avail on the shoot location. and
                    Transportation cost will be applied.
                  </Typography>
                  <RadioButtonSmall
                    value={isOnSite}
                    handleChange={setIsOnSite}
                  />
                </Stack>

                <Stack width="100%">
                  <Typography
                    style={{
                      fontSize: "11.8px",
                      width: "90%",
                      userSelect: "none",
                    }}
                  >
                    Is this service is doable with other service (Inventory
                    Item)?
                  </Typography>
                  <RadioButtonSmall
                    value={isInventory}
                    handleChange={setIsInventory}
                  />
                </Stack>
              </Stack>

              {/*Row 3*/}
              <Stack direction="row" width="98%" gap={1.5}>
                <Stack width="100%">
                  <Typography
                    style={{ fontSize: "11.8px", userSelect: "none" }}
                  >
                    Is required row data ?
                  </Typography>
                  <RadioButtonSmall
                    value={isRequiredRowData}
                    handleChange={setIsRequiredRowData}
                  />
                </Stack>

                <Stack width="100%">
                  <Typography
                    style={{ fontSize: "11.8px", userSelect: "none" }}
                  >
                    Is required edited data ?
                  </Typography>
                  <RadioButtonSmall
                    value={isRequiredEditedData}
                    handleChange={setIsRequiredEditedData}
                  />
                </Stack>
              </Stack>

              {/*Row 4*/}
              <Stack direction="row" width="98%">
                <Stack width="100%">
                  <Typography
                    style={{ fontSize: "11.8px", userSelect: "none" }}
                  >
                    Service Type
                  </Typography>
                  <RadioButtonSmall
                    labelB="Normal"
                    labelA="Regenerate Link"
                    value={isNormal}
                    handleChange={setIsNormal}
                  />
                </Stack>
              </Stack>
            </Stack>

            <TextFieldSmall
              value={childQty}
              onChange={(e) => setChildQty(e.target.value)}
              label="Child Quantity"
              type="number"
              placeholder="Enter quantity"
              bgColor="white"
              width="99.8%"
              height="4.250rem"
              inputFieldWidth="98%"
              inputHeight="2.180rem"
            />

            <Selection
              handleChange={handleSelectPodian}
              selected={rolesSelected}
              lable={"Who will do this service?"}
              names={roles}
              multiple={true}
            />
          </Stack>

          <Stack width="26rem" direction="row" gap="0.5rem">
            <ButtonView
              lable="Cancel"
              color="#E50000"
              bgcolor="#E5000033"
              fontSize="14px"
              sx={{ width: "100%" }}
              hoverBg="pink"
              onClick={() => handleOnClose(0)}
            />

            <ButtonView
              lable="Submit"
              fontSize="14px"
              hoverBg="#ffb952"
              sx={{ width: "100%" }}
              onClick={handleOnSubmit}
            />
          </Stack>
        </Box>

        {/* <DialogActions>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button onClick={handleOnSubmit} variant="text">
                  {row ? "Update" : "Submit"}
                </Button>
              )}
              <Button onClick={() => handleOnClose(0)} variant="text">
                Cancel
              </Button>
            </DialogActions>
          </DialogContent> */}
      </Dialog>
    </>
  );
}

export default AddServiceDailog;

function RadioButtonView({ title, callBack, value }) {
  return (
    <Box ml={2} display="flex" flexDirection="column" my={1}>
      <Typography variant="subtitle">{title}</Typography>
      <RadioGroup value={value} row={true}>
        <FormControlLabel
          onChange={callBack}
          value={1}
          label={<Typography variant="subtitle2">Yes</Typography>}
          control={<Radio />}
        />
        <FormControlLabel
          onChange={callBack}
          value={0}
          label={<Typography variant="subtitle2">No</Typography>}
          control={<Radio />}
        />
      </RadioGroup>
    </Box>
  );
}

function RadioButtonServiceView({ title, callBack, value }) {
  return (
    <Box width={"95%"} ml={2} display="flex" flexDirection="column" my={1}>
      <Typography>{title}</Typography>
      <RadioGroup value={value} row={true}>
        <FormControlLabel
          onChange={callBack}
          value={1}
          label={<Typography variant="subtitle2">Main Service</Typography>}
          control={<Radio />}
        />
        <FormControlLabel
          onChange={callBack}
          value={2}
          label={<Typography variant="subtitle2">Add-ons</Typography>}
          control={<Radio />}
        />
        <FormControlLabel
          onChange={callBack}
          value={3}
          label={<Typography variant="subtitle2">Post Add-ons</Typography>}
          control={<Radio />}
        />
      </RadioGroup>
    </Box>
  );
}
