import { API_URL, GETAPI, POSTAPI } from "./config";

export const GETORDERISSUE = async (id) => {
  const res = await GETAPI(API_URL.getOrderIssues, "&orders_id=" + id);
  return res;
};

export const SOLVE_ISSUE = async (data) => {
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("response", data.response);
  formData.append("status", data.status);
  const res = await POSTAPI(API_URL.solveOrderIssues, formData);
  return res;
};
