import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { ADD_UPDATE_CITY } from "../../../Apis/city_api";
import LocationSearchInput from "../../../Component/AutoComplate/AddressAutoComplate";
import TextFieldChip from "../../../Component/TextField/TextFieldChip";
import TextFieldView from "../../../Component/TextField/TextFieldView";
import { ToastShow } from "../../../Component/Toast/Toast";
import ReactDragListView from "react-drag-listview/lib/index";
import ButtonView from "../../../Component/Button/ButtonView";
import TextFieldLarge from "../../../Component/TextFields/TextFieldLarge";
import LocationSearchField from "../../../Component/TextFields/LocationSearchField";
import InfoIcon from "../../../Assets/InfoIcon.png";
import { useParams } from "react-router-dom";
import ChipsField from "../../../Component/TextFields/ChipsField";
import SearchBox from "../../../Component/SearchBox/SearchBox";
import RadioButtonNew from "../../../Component/RadioButton/RadioButtonNew";

function CitiesAdd({ row, chip }) {
  const { id } = useParams();
  const [chips, setChips] = React.useState(chip ?? []);
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState(row ? row.name : "");
  const [address, setAddress] = React.useState(row ? row.address : "");
  const [freeDistance, setFreeDistance] = React.useState(
    row ? row.free_distance : ""
  );
  const [charges, setCharges] = React.useState(row ? row.charge_per_km : "");
  const [status, setStatus] = React.useState(row ? row.status : 2);
  const [distanceCharge, setDistanceCharge] = React.useState(
    row ? row.fixed_distance_charges : ""
  );
  const [priceHike, setPriceHike] = React.useState(row ? row.price_hike : "");
  const [canEdit, setCanEdit] = React.useState(row ? row.canEditBefore : 0);
  const [sgstLabel, setSgstLabel] = React.useState(
    row ? row.sgst_label : "SGST"
  );
  const [cgstLabel, setCgstLabel] = React.useState(
    row ? row.cgst_label : "CGST"
  );
  const [sgst, setSgst] = React.useState(row ? row.sgst : 9);
  const [cgst, setCgst] = React.useState(row ? row.cgst : 9);
  const [providerSM, setProviderSM] = React.useState(
    row ? row.provider_support_mobile : ""
  );
  const [latLng, setLatLng] = React.useState(
    row
      ? {
          lat: row.latitude,

          lng: row.longitude,
        }
      : {}
  );

  const [rowData, setRowData] = useState(row ? row.categories : []);

  const [bufferTime, setBufferTime] = React.useState(row ? row.bufferTime : "");
  const [orderCanAfterTime, setOrderCanAfterTime] = React.useState(
    row ? row.orderCanAfterTime : ""
  );

  const [search, setSearch] = useState("");

  //For ProviderSm
  const maxTotalLength = 15;
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatPhoneNumber(inputValue);
    setProviderSM(formattedValue);
  };
  const formatPhoneNumber = (inputValue) => {
    const digitsOnly = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

    let formattedValue = "+";
    for (let i = 0; i < digitsOnly.length && i < maxTotalLength - 1; i++) {
      if (i === 2 || i === 7) {
        formattedValue += " " + digitsOnly[i];
      } else {
        formattedValue += digitsOnly[i];
      }
    }

    // Handle case when user clears all input
    if (digitsOnly.length === 0) {
      formattedValue = "";
    }

    return formattedValue;
  };

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please fill name");
    } else if (!address) {
      ToastShow("Please fill address");
    }
    // else if (!freeDistance) {
    //   ToastShow("Please fill free distance");
    // }
    // else if (charges) {
    //   ToastShow("Please fill charge per KM");
    // }
    else if (status == 2) {
      ToastShow("Please select Active/InActive status");
    } else if (!chips.length) {
      ToastShow("Please fill synonyms name");
    }
    // else if (!priceHike) {
    //   ToastShow("Please fill price hike");
    // }
    // else if (!distanceCharge) {
    //   ToastShow("Please fill distance charge");
    // }
    else {
      setIsLoading(true);
      // console.log("canEdit",canEdit);
      const res = await ADD_UPDATE_CITY({
        name: name,
        status: status,
        latitude: latLng.lat,
        longitude: latLng.lng,
        address: address,
        freeDistance: "0.0",
        fixedDistanceCharges: distanceCharge ?? 0,
        priceHike: priceHike ?? 0,
        // freeDistance,
        chargePerKm: charges ?? 0,
        alternateNames: chips,
        categories: rowData,
        row: row,
        bufferTime: bufferTime,
        orderCanAfterTime: orderCanAfterTime,
        canEditBefore: canEdit,
        sgstLabel: sgstLabel,
        sgst: sgst,
        cgstLabel: cgstLabel,
        cgst: cgst,
        id: id,
        providerSM: providerSM,
      });
      setIsLoading(false);
      if (res && res.s == 1) {
        if (!row) {
          setName("");
          setAddress("");
          setCharges("");
          setChips([]);
          setDistanceCharge("");
          setLatLng({});
          setPriceHike("");
          setStatus(2);
          ToastShow("Added successfully");
        } else {
          ToastShow("Updated successfully");
          // window.location.reload();
        }
      }
    }
  };

  const handleOrderCanAfterTime = (v) => {
    setOrderCanAfterTime(v);
  };

  const handleBufferTime = (v) => {
    setBufferTime(v);
  };

  const handleChange = (newChips) => {
    console.log(newChips);
    setChips(newChips);
  };

  const handleName = (v) => {
    setName(v);
  };

  const handleCharges = (v) => {
    setCharges(v);
  };

  const handleDistance = (v) => {
    setFreeDistance(v);
  };

  const handleDistanceCharge = (v) => {
    setDistanceCharge(v);
  };

  const handlePriceHike = (v) => {
    setPriceHike(v);
  };

  const handleAddress = (address) => {
    // console.log(address);
    setAddress(address);
  };

  const handleStatus = (e) => {
    // console.log(e);
    setStatus(e);
  };

  const updateLatLng = (latLngs) => {
    setLatLng(latLngs);
    // console.log("lat",latLng.lat, "lng",latLng.lng);
  };

  //   useEffect(() => {
  //     console.log("value", rowData);
  //   }, [rowData]);

  const categoryTextChange = (v, i) => {
    // console.log("v", v);
    // console.log("i", i);
    const d = rowData;
    // console.log("nameB", d[i].name);
    d[i].price_hike = v;
    // console.log("nameA", d[i].name);
    setRowData([...d]);
  };

  const categoriesRadioChange = (v, i) => {
    const d = rowData;
    d[i].status = v;
    setRowData([...d]);
  };

  const categoryServiceTextChange = (v, i, j) => {
    // console.log("v", v);
    // console.log("i", i);
    const d = rowData;
    // console.log("nameB", d[i].name);
    d[i].services[j].price_hike = v;
    // console.log("nameA", d[i].name);
    setRowData([...d]);
  };

  const categoriesServiceRadioChange = (v, i, j) => {
    const d = rowData;
    d[i].services[j].status = v;
    setRowData([...d]);
  };

  const addDiscount = (sI, pI) => {
    const d = rowData;
    d[pI].services[sI].discount.push({
      id: 0,
      type: 1,
      on: 0,
      apply: 0,
      isPercentage: 1,
      maxAmount: 1,
      status: 1,
    });
    setRowData([...d]);
  };

  const deleteDiscount = (sI, pI, dI) => {
    const d = rowData;
    const checkData = d[pI].services[sI].discount[dI];
    checkData.status = -1;
    d[pI].services[sI].discount[dI] = checkData;
    setRowData([...d]);
  };

  const updateDiscount = (sI, pI, dI, value, i) => {
    const d = rowData;
    const checkData = d[pI].services[sI].discount[dI];
    if (i == 1) {
      checkData.type = value;
    } else if (i == 2) {
      checkData.on = value;
    } else if (i == 3) {
      checkData.apply = value;
    } else if (i == 4) {
      checkData.isPercentage = value == 1 ? true : false;
    } else if (i == 5) {
      checkData.maxAmount = value;
    }

    d[pI].services[sI].discount[dI] = checkData;
    setRowData([...d]);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      // console.log("call", fromIndex, toIndex);
      const data = [...rowData];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      data.map((v, index) => (v.ordr = index));
      // console.log(data);
      setRowData(data);

      // console.log("from",fromIndex,"to",toIndex);
    },
    nodeSelector: "li",
    handleSelector: "a",
  };

  const dragPropsC = (i) => {
    // console.log(i)
    return {
      onDragEnd(fromIndex, toIndex) {
        // console.log("i",i)
        const data = [...rowData];
        const item = data[i].services.splice(fromIndex, 1)[0];
        data[i].services.splice(toIndex, 0, item);
        data[i].services.map((v, index) => (v.ordr = index));
        console.log(data[i].services);
        setRowData(data);
        // console.log("from",fromIndex,"to",toIndex);
      },
      nodeSelector: "li",
      handleSelector: "span",
    };
  };

  return (
    <>
      <Box display="flex" gap="1rem">
        <Box
          sx={{
            backgroundColor: "background.paper",
            p: "1rem",
            boxShadow: "3px 3px 7px 0px #00000040",
            borderRadius: "5px",
            height: "87vh",
            overflow: "auto",
          }}
        >
          <Typography variant="h5">
            {row ? "Update City" : "Add City"}
          </Typography>
          <Box sx={{ width: "350px", mt: 1 }}>
            <TextFieldLarge
              label="City Name"
              placeholder="Enter City Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              width="100%"
            />
            <LocationSearchField
              address={address}
              handleAddress={handleAddress}
              updateLatLng={updateLatLng}
              width="100%"
            />

            {/* <TextFieldView
                onchange={handleDistance}
                value={freeDistance}
                label="Free distance in KM"
                type="number"
                sx={{ my: 1, width: "100%" }}
              /> */}

            {/*Charge per km & order can after time*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Charge per km
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter km"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setCharges(e.target.value)}
                    value={charges}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Fix distance charge
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Time"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setDistanceCharge(e.target.value)}
                    value={distanceCharge}
                  />
                </Box>
              </Box>
            </Box>

            {/*orderCanAfterTime & bufferTime*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Order can after time
                    </Typography>
                    <Tooltip title="Customer can book the shoot after given time.">
                      <img src={InfoIcon} height="9.4px" alt="" />
                    </Tooltip>
                  </Box>

                  <TextField
                    placeholder="Enter Time"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={orderCanAfterTime}
                    onChange={(e) => setOrderCanAfterTime(e.target.value)}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Buffer time
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Time"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={bufferTime}
                    onChange={(e) => setBufferTime(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>

            {/*hikePrice & canEditBefore*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Hike price %
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter price %"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={priceHike}
                    onChange={(e) => setPriceHike(e.target.value)}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Can edit before ?
                    </Typography>
                    <Tooltip title="Customer can edit order before shoot time.">
                      <img src={InfoIcon} height="10px" alt="" />
                    </Tooltip>
                  </Box>

                  <TextField
                    placeholder="Enter Number of Clips"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={canEdit}
                    onChange={(e) => setCanEdit(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>

            {/*Capital GST 1 & Capital GST 2*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      SGST
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Label"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={sgstLabel}
                    onChange={(e) => setSgstLabel(e.target.value)}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      SGST Applicable %
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter GST"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={sgst}
                    onChange={(e) => setSgst(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>

             {/*Capital CGST 1 & Capital CGST 2*/}
             <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={0.5}
              mb={1}
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      CGST
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Label"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={cgstLabel}
                    onChange={(e) => setCgstLabel(e.target.value)}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      CGST Applicable %
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter CGST"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={cgst}
                    onChange={(e) => setCgst(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>

            <TextFieldLarge
              label="Mobile no for support for provider"
              placeholder="Enter number"
              value={providerSM}
              onChange={handlePhoneNumberChange}
              maxLength={maxTotalLength}
              width="100%"
            />

            <RadioButtonNew
              value={status}
              handleChange={setStatus}
              width="100%"
              btnWidth="30%"
              justifyContent="none"
              gapInButtons="1rem"
            />

            <ChipsField
              sx={{ width: "95%", border: "1px solid white" }}
              chips={chips}
              handleChange={handleChange}
              label="Enter names"
            />
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ButtonView
              lable={row ? "Update" : "Submit"}
              onClick={handleOnSubmit}
              sx={{ mt: "1rem" }}
            />
          )}
        </Box>

        {rowData.length ? (
          <Box
            sx={{
              flex: 1,
              backgroundColor: "background.paper",
              p: "1rem",
              px: "2rem",
              minHeight: "80vh",
              boxShadow: "3px 3px 7px 0px #00000040",
              borderRadius: "5px",
              overflow: "auto",
              height: "87vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Category/Service</Typography>
              <TextField
                size="small"
                sx={{
                  ml: 1,
                }}
                label="Search Category"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </Box>
            <ReactDragListView {...dragProps}>
              <ol>
                {rowData.map((v, i) => {
                  return (
                    <CategoryView
                      dragProps={dragPropsC}
                      updateDiscount={updateDiscount}
                      search={search}
                      key={v.id + "" + i + v.name}
                      v={v}
                      i={i}
                      addDiscount={addDiscount}
                      deleteDiscount={deleteDiscount}
                      categoryTextChange={categoryTextChange}
                      categoriesRadioChange={categoriesRadioChange}
                      categoryServiceTextChange={categoryServiceTextChange}
                      categoriesServiceRadioChange={
                        categoriesServiceRadioChange
                      }
                    />
                  );
                })}
              </ol>
            </ReactDragListView>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default CitiesAdd;

function CategoryView({
  search,
  v,
  i,
  categoryTextChange,
  categoriesRadioChange,
  categoryServiceTextChange,
  categoriesServiceRadioChange,
  addDiscount,
  deleteDiscount,
  updateDiscount,
  dragProps,
}) {
  // console.log("search=>", v.name.startsWith(search));
  // console.log("search=== ", search, v.name);
  return search.length &&
    !v.name.toLowerCase().startsWith(search.toLowerCase()) ? null : (
    <li key={v.id + "" + i + "li"} style={{ listStyle: "none" }}>
      <Box key={v.id + "" + i} mt={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>{"#" + v.name} (Category)</Typography>
          <a style={{ cursor: "move", color: "#0077ff" }}>Start Drag</a>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="start">
          <TextFieldLarge
            label="Price Hike(%)"
            type="number"
            value={v.price_hike}
            onChange={(e) => categoryTextChange(e.target.value)}
            width="100%"
            fieldWidth="100%"
          />

          <Box display="flex" alignItems="center">
            <Typography sx={{ fontWeight: "bold", mr: "5px" }}>
              Active -{" "}
            </Typography>
            {/* <RadioGroup value={v.status} row={true} sx={{ display: "flex" }}>
              <FormControlLabel
                label={<Typography variant="subtitle2">Yes</Typography>}
                onClick={(e) => {
                  categoriesRadioChange(e.target.value, i);
                }}
                value={1}
                control={<Radio />}
              />
              <FormControlLabel
                label={<Typography variant="subtitle2">No</Typography>}
                onClick={(e) => {
                  categoriesRadioChange(e.target.value, i);
                }}
                value={0}
                control={<Radio />}
              />
            </RadioGroup> */}
            <RadioButtonNew
              value={v.status}
              handleChange={categoriesRadioChange}
              param={i}
              width="30%"
              btnWidth="48%"
            />
          </Box>
        </Box>
        {v.services.length ? (
          <Typography variant="h6">Service</Typography>
        ) : null}
        <ReactDragListView {...dragProps(i)} key={i}>
          <ol>
            {v.services.map((d, j) => {
              return (
                <li key={d.id + "" + j + j}>
                  <span style={{ cursor: "move", color: "#0077ff" }}>
                    Start Drag
                  </span>
                  <CategoryServiceView
                    key={d.id + "" + j}
                    v={d}
                    i={j}
                    j={i}
                    categoriesServiceRadioChange={categoriesServiceRadioChange}
                    categoryServiceTextChange={categoryServiceTextChange}
                  />

                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      Discount
                    </Typography>
                    <IconButton
                      onClick={() => {
                        addDiscount(j, i);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Box>

                  {d.discount.map((v, di) => {
                    return v.status == 1 ? (
                      <DiscountView
                        key={di}
                        v={v}
                        sI={j}
                        pI={i}
                        dI={di}
                        deleteDiscount={deleteDiscount}
                        updateDiscount={updateDiscount}
                      />
                    ) : null;
                  })}
                </li>
              );
            })}
          </ol>
        </ReactDragListView>
        <Divider sx={{ my: 3 }} />
      </Box>
    </li>
  );
}

function DiscountView({ dI, pI, sI, deleteDiscount, v, updateDiscount }) {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="start"
      justifyContent="start"
      mb={2}
    >
      <Box display="flex" alignItems="center">
        <RadioGroup
          value={v.type}
          row
          onChange={(e) => updateDiscount(sI, pI, dI, e.target.value, 1)}
        >
          <FormControlLabel
            control={<Radio value={1} />}
            label="Hour"
            labelPlacement="end"
          />
          <FormControlLabel
            control={<Radio value={2} />}
            label="Amount"
            labelPlacement="end"
          />
        </RadioGroup>
        <IconButton onClick={() => deleteDiscount(sI, pI, dI)}>
          <Delete />
        </IconButton>
      </Box>

      <Box display="flex" mt={1} alignItems="center">
        <TextFieldLarge
          label="Apply on"
          type="number"
          value={v.on}
          onChange={(e) => updateDiscount(sI, pI, dI, e, 2)}
          width="50%"
          fieldWidth="100%"
          mr="1rem"
        />

        <TextFieldLarge
          label="Applicable"
          type="number"
          value={v.apply}
          onChange={(e) => updateDiscount(sI, pI, dI, e, 3)}
          width="50%"
          fieldWidth="100%"
          mr="1rem"
        />
      </Box>

      <Box display="flex" mt={1} alignItems="center">
        <FormControlLabel
          label="Is percentage?"
          control={
            <Checkbox
              checked={v.isPercentage == 1 ? true : false}
              onChange={(e) => updateDiscount(sI, pI, dI, e.target.checked, 4)}
            />
          }
        />
        <TextFieldLarge
          label="Max amount"
          type="number"
          value={v.maxAmount}
          onChange={(e) => updateDiscount(sI, pI, dI, e, 5)}
          width="50%"
          fieldWidth="100%"
        />
      </Box>
    </Box>
  );
}

function CategoryServiceView({
  v,
  i,
  j,
  categoryServiceTextChange,
  categoriesServiceRadioChange,
}) {
  return (
    <Box>
      <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
        {v.name} ({v.price} rs.)
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="start">
        {/* <TextFieldView
          sx={{
            width: "150px",
            mr: "2rem",
            mt: 0.5,
            mb: 0.3,
          }}
          label="Price Hike(%)"
          type="number"
          value={v.price_hike}
          onchange={(e) => categoryServiceTextChange(e, j, i)}
        /> */}

        <TextFieldLarge
          label="Price Hike(%)"
          type="number"
          value={v.price_hike}
          onChange={(e) => categoryServiceTextChange(e, j, i)}
          width="50%"
          fieldWidth="100%"
          mr="1rem"
        />

        <Box display="flex" alignItems="center">
          <Typography sx={{ mr: 1, fontWeight: "500" }}>Active - </Typography>
          <RadioGroup value={v.status} row={true} sx={{ display: "flex" }}>
            <FormControlLabel
              label={<Typography variant="subtitle2">Yes</Typography>}
              onClick={(e) => {
                categoriesServiceRadioChange(e.target.value, j, i);
              }}
              value={1}
              control={<Radio />}
            />
            <FormControlLabel
              label={<Typography variant="subtitle2">No</Typography>}
              onClick={(e) => {
                categoriesServiceRadioChange(e.target.value, j, i);
              }}
              value={0}
              control={<Radio />}
            />
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
}
