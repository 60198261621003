import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_WEB_CITY = async (data) => {
  const formData = new FormData();


  if (data?.photo) {
    formData.append("photo", data.photo, "photo.png");
  }


  if (data?.row) {
    formData.append("id", data.row.id);
  }

  // formData.append("name", data?.name);
  formData.append("min_shoots", data?.min_shoots);

  const res = await POSTAPI(
    data?.row?.id ? API_URL.webUpdateCity : API_URL.webAddCity,
    formData
  );
  return res;
};

export const GET_WEB_CITY = async () => {
  const res = await GETAPI(API_URL.webGetCities);
  return res;
};

export const DELETE_WEB_CITY = async (id) => {
  const res = await GETAPI(API_URL.webDeleteCity, "&id=" + id);
  return res;
};
