import axios from "axios";
import { devlopment_mode } from "../Service/Constant";

export const API_URL = {
  // baseUrl:"https://api.proxx.in/api/auth/event/create",
  baseUrl: devlopment_mode
    ? "https://dev.podahmedabad.com/"
    : "https://api.photographerondemand.in/",
  // baseUrl: "https://dev.podahmedabad.com/",
  // "https://test.photographerondemand.in/",
  //CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.
  send_otp: "api/auth/otp",
  varify: "api/auth/verify",
  getWaitingForAdmin: "api/orders/getWaitingForAdmin",
  getForAdmin: "api/orders/getForAdmin",
  getOrderById: "api/orders/getDetails",
  deleteOrderCouponById: "api/orders/removeCoupon",
  getProviderOfOrder: "api/orders/getProvidersOfService",
  getOtherProvidersOfService: "api/orders/getOtherProvidersOfService",
  userProfileImage: "uploads/users/pp/",
  categoryImage: "uploads/category/",
  selfieImage: "uploads/order/selfie/",
  onShotImage: "uploads/onspot/logo/",
  supportTicketAttachmentURL: "uploads/support/ticket/",
  orderItemApproveDisApprove: "api/orders/approveOrDisapprove",
  availableCities: "api/availableCities",
  availabilityCities: "api/city/availability",
  getCity: "api/city/get",
  addCity: "api/city/add",
  updateCity: "api/city/update",
  getDetailsCity: "api/city/getDetails",
  activateOrInactivateCity: "api/city/activateOrInactivate",
  providersGet: "api/providers/get",
  providersGetAVL: "api/providers/getUnavailability",
  providerDetails: "api/providers/getDetails",
  providerFormDetails: "api/forms/getOfProvider",
  providerApproveReject: "api/providers/approveOrReject",
  providerKYC: "api/docs/get",
  providerAggrementLink: "api/providers/getAgreement",
  providerKYCApprove: "api/docs/approve",
  providerKYCReject: "api/docs/reject",
  getRoleForPodion: "api/user/getRolesForPodian",
  submitEditedLink: "api/orders/submitEditedLink",
  categoryAdd: "api/category/add",
  categoryUpdate: "api/category/update",
  categoryDelete: "api/category/delete",
  categoryGet: "api/category/get",
  categoryGetDetails: "api/category/getDetails",
  serviceGet: "/api/services/getForWebAdmin",
  serviceDelete: "api/services/delete",
  addService: "api/services/add",
  updateService: "api/services/update",
  getByMobile: "api/user/getByMobile",
  addByAdmin: "api/user/addByAdmin",
  getService: "api/services/get",
  deleteService: "api/orders/deleteService",
  addOrderService: "api/orders/addService",
  updateOrderService: "api/orders/updateService",
  draftOrder: "api/orders/draft",
  updateOrder: "api/orders/updateDraft",
  swapProvider: "api/orders/swapProvider",
  confirmOrder: "api/orders/confirm",
  cancelOrder: "api/orders/cancel",
  updateCut: "api/providers/updateCut",
  addFCM: "api/user/addFCM",
  getNotification: "api/user/getNotifications",
  getNotificationAdmin: "api/notification/get",
  deleteNotificationAdmin: "api/notification/delete",
  addNotification: "api/notification/add",
  updateNotification: "api/notification/update",
  getKYC: "uploads/users/id/",
  getPramotionBanner: "api/banner/get",
  addPramotionBanner: "api/banner/add",
  updatePramotionBanner: "api/banner/update",
  deletePramotionBanner: "api/banner/delete",
  getPramotionBannerDetails: "api/banner/getDetails",

  bannerImage: "uploads/banner/file/",
  userStoryImage: "uploads/userstory/photo/",
  videoStoryImage: "uploads/videostory/file/",
  portfolioImage: "uploads/portfolio/file/",
  KYCImage: "uploads/users/doc/",
  OrderAudio: "uploads/order/audio/",
  OrderServiceAudio: "uploads/order/services/audio/",
  attachmentIssueFile: "uploads/issue/",

  getUsersStories: "api/userstory/get",
  addUserStory: "api/userstory/add",
  updateUserStory: "api/userstory/update",
  deleteUserStory: "api/userstory/delete",

  getVideoStories: "api/videostory/get",
  addVideoStory: "api/videostory/add",
  updateVideoStory: "api/videostory/update",
  deleteVideoStory: "api/videostory/delete",

  getFaq: "api/faq/get",
  addFaq: "api/faq/add",
  editFaq: "api/faq/update",
  deleteFaq: "api/faq/delete",
  getFaqDetails: "api/faq/getDetails",

  getPortfolio: "api/portfolio/getFiles",
  addPortfolio: "api/portfolio/addFile",
  updatePortfolio: "api/portfolio/updateFile",
  deletePortfolio: "api/portfolio/deleteFile",

  getPolicy: "api/policies/getList",
  getPolicyDetails: "api/policies/getDetails",
  updatePolicyDetails: "api/policies/update",

  getUserList: "api/user/get",
  getUserBasicDetails: "api/user/getBasic",
  getUserDetails: "api/user/getDetails",
  getUserOrder: "api/orders/get",

  getSchedule: "api/providers/getSchedule",

  getOrderIssues: "api/orders/getIssues",
  solveOrderIssues: "api/orders/issueChangeStatus",

  reportCustomer: "api/report/customer",
  reportOrder: "api/report/order",
  reportProvider: "api/report/provider",

  chatNotify: "api/chat/notify",

  getSubAdminDetails: "api/subadmin/getDetails",
  saveSubAdminDetails: "api/subadmin/save",
  getSubAdminList: "api/subadmin/get",
  deleteSubAdminList: "api/subadmin/delete",

  getMyRole: "api/user/getMyRoles",

  approveRejectService: "api/forms/approveOrReject",
  getHistoryService: "api/forms/getQuestionHistory",

  staticsOrders: "api/statistics/orders",
  staticsClick: "api/statistics/clicks",
  staticsclips: "api/statistics/clips",
  staticsorderAmount: "api/statistics/orderAmount",
  staticscustomer: "api/statistics/customer",
  staticsproviders: "api/statistics/providers",

  coupenGet: "api/coupon/get",
  coupenAdd: "api/coupon/add",
  coupenDelete: "api/coupon/delete",
  coupenUpdate: "api/coupon/update",
  coupenActivate: "api/coupon/activate",
  coupenDeactivate: "api/coupon/deactivate",
  coupenForCustomer: "api/coupon/getForCustomer",
  coupenApplyToOrder: "api/orders/applyCoupon",
  coupenCanApplyToOrder: "api/coupon/canApply",

  updateServiceAutoApprove: "api/providers/updateAutoApprove",

  walletApi: "api/wallet/getCoinHistory",
  rescheduleHistoryApi: "api/orders/getRescheduleHistory",
  removeTransportApi: "api/orders/removeTransportCharge",
  removeTransportChargeProvider: "api/orders/removeTransportChargeProvider",
  updateProviderStatus: "api/orders/updateProviderStatus",
  notifyAgainApi: "api/orders/notifyAgainNewOrder",
  addPaymentRecord: "api/orders/addPaymentRecord",
  addInvoicePaymentRecord: "api/orders/addPaymentRecordInvoice",
  getGstDetailsByNo: "api/utils/getGstDetailsByNo",

  addOnShot: "api/onspot/add",
  updateOnShot: "api/onspot/update",
  getOnShot: "api/onspot/get",
  addOnShotLink: "api/onspot/addLink",

  addRestriction: "api/user/restriction",
  removeRestriction: "api/user/removeRestriction",

  addCities: "api/providers/addCities",

  addOrderLink: "api/orders/submitEditedLink",
  updateOrderLink: "api/orders/updateLink",
  deleteOrderLink: "api/orders/deleteLink",
  addOrderInvoiceLink: "api/orders/completeInvoice",
  getCategories: "api/providers/getCategories",
  setAutoApproveCategories: "api/providers/setAutoApproveCategories",
  approveOrRejectExtend: "api/providers/approveOrRejectExtend",
  getAllOIssues: "api/orders/getAllOIssues",

  getIssueTitle: "api/orders/getIssueTitles",
  updateIssueTitle: "api/orders/updateIssueTitles",
  deleteIssueTitle: "api/orders/deleteIssueTitles",
  addIssueTitle: "api/orders/addIssueTitles",
  rescheduleOrder: "api/orders/reschedule",
  getSuggestedProvider: "api/orders/getSuggestedProvider",

  onspot2AddCategory: "api/onspot2/addCategory",
  onspot2UpdateCategory: "api/onspot2/updateCategory",
  onspot2DeleteCategory: "api/onspot2/deleteCategory",
  onspot2GetCategory: "api/onspot2/getCategory",
  onspot2Add: "api/onspot2/add",
  onspot2Update: "api/onspot2/update",
  onspot2Update: "api/onspot2/update",
  onspot2Get: "api/onspot2/getForAdmin",
  onspot2GetDetails: "api/onspot2/getDetails",
  onspot2Delete: "api/onspot2/delete",
  onspot2LogoURL: "uploads/onspot/logo",
  onspot2AddSponser: "api/onspot2/addSponser",
  onspot2UpdateSponser: "api/onspot2/updateSponser",
  onspot2DeleteSponser: "api/onspot2/deleteSponser",
  onspot2AddGallery: "api/onspot2/addGallery",
  onspot2UpdateGallery: "api/onspot2/updateGallery",
  onspot2DeleteGallery: "api/onspot2/deleteGallery",
  onspot2GalleryLogo: "uploads/onspot/gallery/",
  onspot2SponsorLogo: "uploads/onspot/logo/",
  onspot2AddFaq: "api/onspot2/addFaq",
  onspot2UpdateFaq: "api/onspot2/updateFaq",
  onspot2DeleteFaq: "api/onspot2/deleteFaq",
  onspot2aUDCity: "api/onspot2/aUDCity",
  onspot2AddCityLocation: "api/onspot2/addCityLocation",
  onspot2UpdateCityLocation: "api/onspot2/updateCityLocation",
  onspot2DeleteCityLocation: "api/onspot2/deleteCityLocation",
  onspot2AddCityLocationZone: "api/onspot2/addCityLocationZone",
  onspot2UpdateCityLocationZone: "api/onspot2/updateCityLocationZone",
  onspot2DeleteCityLocationZone: "api/onspot2/deleteCityLocationZone",
  onSpot2AUDRole: "api/onspot2/aUDRole",
  onSpot2AddPackage: "api/onspot2/addPackage",
  onSpot2UdatePackage: "api/onspot2/updatePackage",
  onSpot2DeletePackage: "api/onspot2/deletePackage",
  onSpot2GetProviderToAdd: "api/onspot2/getProviderToadd",
  onSpot2AddProvider: "api/onspot2/addProvider",
  onSpot2UpdateProvider: "api/onspot2/updateProvider",
  onSpot2DeleteProvider: "api/onspot2/deleteProvider",
  onSpot2GetProvider: "api/onspot2/getProviders",
  onSpot2Publish: "api/onspot2/publish",
  onSpot2AddLink: "api/onspot2/addLink",
  onSpot2UpdateLink: "api/onspot2/updateLink",
  onSpot2DeleteLink: "api/onspot2/deleteLink",
  onSpot2ProviderBooking: "api/onspot2/getBookingsForProvider",
  onSpot2UnPublish: "api/onspot2/unpublish",
  onSpot2GetLink: "api/onspot2/getLink",

  supportCategoryGet: "api/support/getCategory",
  supportCategoryUpdate: "api/support/updateCategory",
  supportCategoryAdd: "api/support/addCategory",
  supportCategoryDelete: "api/support/deleteCategory",
  supportGetAdminUserToAdd: "api/support/getAdminUserToAdd",
  supportAddAdminUser: "api/support/addAdminUser",
  supportRemoveAdminUser: "api/support/removeAdminUser",
  supportGetAdminUser: "api/support/getAdminUsers",
  supportForAdmin: "api/support/getForAdmin",
  supportGetDetails: "api/support/getDetails",
  supportAddReply: "api/support/addReplay",
  supportGetReplies: "api/support/getReplies",
  supportCloseTicket: "api/support/closeTicket",
  supportReOpenTicket: "api/support/reOpenTicket",
  supportCallBack: "api/support/getPendingCallBack",
  supportCallBackDone: "api/support/requestCallBackDone",
  getPendingCallBackCount: "api/support/getPendingCallBackCount",
  createTicket: "api/support/createTicket",
  updateTicket: "api/support/updateTicket",

  getAppIssue: "api/utils/getAppIssue",
  getUnavailability: "api/subadmin/getUnavailability",

  addDiscountOnService: "api/city/discountOnService",
  getDiscountOnService: "api/city/getDiscountOnService",
  deleteDiscountOnService: "api/city/deleteDiscountOnService",

  updateProviderPrice: "api/orders/updateProviderPrice",

  getOrdersAndEarnings: "api/orders/getForProvider",

  getRateReviews: "api/providers/getRateReviews",

  getServiceProviderUnavailability: "api/providers/getUnavailability",

  getBankDetails: "api/bank/getFundAccount",
  activateOrDeactivateAccount: "api/bank/activateOrDeactivateFundAccount",

  getUnreadCount: "api/notification/getUnreadCount",
  getUnAvailableProvidersOfService:
    "api/orders/getUnAvailableProvidersOfService",
  getSwapProviderHistory: "api/orders/getSwapProviderHistory",

  notificationMarkRead: "api/notification/markRead",

  podianAvgRate: "api/report/podianAvgRate",
  podianAvgWork: "api/report/podianAvgWork",
  podianTotalWork: "api/report/podianTotalWork",
  podianTotalEarn: "api/report/podianTotalEarn",
  podianTotalShoot: "api/report/podianTotalShoot",
  podianTotalShootByHours: "api/report/podianTotalShootByHours",
  imageDeliveredByCategory: "api/report/imageDeliveredByCategory",
  imageReceivedByCategory: "api/report/imageReceivedByCategory",

  addCoin: "api/wallet/addCoin",
  userUpdate: "api/user/update",

  getsPodion: "api/role/get",
  addPage: "api/forms/addPage",
  getPages: "api/forms/getPages",
  updatePage: "api/forms/updatePage",
  deletePage: "api/forms/deletePage",
  orderPages: "api/forms/orderPages",
  getQuestions: "api/forms/getQuestions",
  addQuestions: "api/forms/addQuestion",
  updateQuestions: "api/forms/updateQuestion",
  deleteQuestions: "api/forms/deleteQuestion",
  orderQuestions: "api/forms/orderQuestions",
  addUpdateOrderDiscount: "api/orders/addUpdateDiscount",
  roleAdd: "api/role/add",
  roleUpdate: "api/role/update",
  roleDelete: "api/role/delete",
  roleActivate: "api/role/activate",
  roleDeactivate: "api/role/deactivate",

  changeAddress: "api/orders/changeAddress",

  getCutOffLogs: "api/providers/getCutOffLogs",

  payByCoinWallet: "api/orders/payByCoinWallet",

  // web ******************************
  webAnnouncement: "api/web/addAnnouncement",
  webUpdateAnnouncement: "api/web/updateAnnouncement",
  webGetAnnouncementDetails: "api/web/getAnnouncementDetails",
  webGetAnnouncements: "api/web/getAnnouncements",
  webDeleteAnnouncements: "api/web/deleteAnnouncement",
  webAnnouncementImage: "uploads/web/announcement/",

  webAddCity: "api/web/addCity",
  webUpdateCity: "api/web/updateCity",
  webGetCityDetails: "api/web/getCityDetails",
  webGetCities: "api/web/getCities",
  webDeleteCity: "api/web/deleteCity",
  webCityImage: "uploads/web/city/",

  masterGetList: "api/master/getList",
  masterGetDetails: "api/master/getDetails",
  masterUpdate: "api/master/update",
  masterPhotoUrl: "uploads/master/",

  webAddBrand: "api/web/addBrand",
  webUpdateBrand: "api/web/updateBrand",
  webGetDetailsBrand: "api/web/getBrandDetails",
  webGetBrand: "api/web/getBrands",
  webDeleteBrand: "api/web/deleteBrand",
  webBrandLogo: "uploads/web/brand/",

  webAddFeature: "api/web/addFeature",
  webUpdateFeature: "api/web/updateFeature",
  webGetDetailsFeature: "api/web/getFeatureDetails",
  webGetFeature: "api/web/getFeatures",
  webDeleteFeature: "api/web/deleteFeature",
  webFeatureLogo: "uploads/web/feature/",

  updateForPodian: "api/web/updateForPodian",
  getForPodians: "api/web/getForPodians",

  updateJoinProcessPodian: "api/web/updateJoinProcessPodian",
  getJoinProcessPodian: "api/web/getJoinProcessPodian",

  getPodiansOfMonth: "api/web/getPodiansOfMonth",
  addUpdatePodianOfMonth: "api/web/addUpdatePodianOfMonth",

  getPodians: "api/wallet/getPodians",
  getMoneyTrans: "api/wallet/getMoneyTrans",
  getBalance: "api/wallet/getBalance",
  addPayout: "api/wallet/addPayout",
  addRazorPayout: "api/wallet/addPayoutRazorPay",
  deletePayout: "api/wallet/deletePayout",

  webAddValue: "api/web/addValue",
  webUpdateValue: "api/web/updateValue",
  webGetValue: "api/web/getValues",
  webDeleteValue: "api/web/deleteValue",
  webValueLogo: "uploads/web/value/",
};

export const API = axios.create({ baseURL: API_URL.baseUrl });

export async function GETAPI(url, data = "") {
  try {
    const auths = `?apikey=${tokens.apikey}&token=${tokens.token}&a=3`;
    const result = await API.get(url + auths + data, {
      headers: null,
    });
    // console.log(result.data);
    return result.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function POSTAPI(url, data, headers = null) {
  try {
    data.append("apikey", tokens.apikey);
    data.append("token", tokens.token);
    data.append("a", 3);

    const result = await axios.post(API_URL.baseUrl + url, data, {
      headers: null,
    });
    console.log(result.data);
    return result.data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

// export async function POSTAPIT() {
//   try {
//     const f = new FormData();

//   f.append("user_id",1);
//   f.append("cat_id", 1);
//   f.append("event_name", "payload.event_name");
//   f.append("description", "payload.description");
//   f.append("start_time","2023-10-17 14:10:00");
//   f.append("end_time", "2023-10-17 14:10:00");
//   f.append("location", "payload.location");
//   f.append("lat",25.22);
//   f.append("lang", 25.22);
//   f.append("type", 1);
//   f.append("event_set", 1);
//   f.append("capacity", 1);
//   f.append("t_c", "payload.t_c");
//   f.append("sponsors", '[{"name":"ysssss "}]');
//   f.append("img", "payload.img");
//   f.append("services", 1);
//   const AccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbmNyeXB0ZWREYXRhIjoiYTEyYmI5NmMyNTgxYzcwZjMwZGI2YzE1ZDc1MDQ5NmI1OWY3ZTkwMjc0NzljNTJkYWFiYWFiN2Q3NGQ1NDVlNWU4YTI2MzBiOWRmMTE1NTlhNjlmNDRlNDBkMzA2NDk3IiwiaWF0IjoxNjk4Mzk5NTE0LCJleHAiOjE2OTg0ODU5MTR9.KVVopxVJnDE5J7zLCfg8AWzsXR6UG9Itt4oDdKjriOM";
//   const authString = 'Bearer '.concat(AccessToken);
//     const result = await axios.post(API_URL.baseUrl, f, {
//       headers:{
//         'Access-Control-Allow-Origin':'*',
//         'Authorization':authString
//       }

//     });
//     console.log(result.data);
//     return result.data;
//   } catch (e) {
//     console.log(e);
//     return null;
//   }
// }

const tokens = {};
export default tokens;
