import React from "react";
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { BsChevronDown } from "react-icons/bs";

const Selection = ({
  selected,
  handleChange,
  names,
  lable,
  multiple = false,
  size = "small",
  disabled = false,
  width = "100%",
  icon = false,
}) => {
  return (
    <Box
      sx={{
        width: "99.8%",
        bgcolor: "white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingY: ".4rem",
        gap: ".1rem",
      }}
    >
      <Box width="98%">
        <Box
          sx={{
            display: "flex",
            gap: ".3rem",
            alignItems: "center",
          }}
        >
          <Typography fontSize="11.8px">{lable}</Typography>
        </Box>
      </Box>

      <Box width="98%">
        <FormControl
          sx={{
            width: width,
            borderRadius: "5px",
            "& fieldset": { border: "none" },
            // "& .css-1cdoino-MuiInputBase-root-MuiOutlinedInput-root": {
            //   fontSize: "0.720rem",
            // },
            "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                fontSize: "0.720rem",
              },
            "& .css-3qbkez-MuiSelect-icon": {
              width: "0.688rem",
            },
            "& .css-10q54uo-MuiSelect-icon": {
              width: "0.688rem",
            },
          }}
        >
          {/* <InputLabel id="demo-multiple-checkbox-label">{lable}</InputLabel> */}
          <Select
            size={size}
            disabled={disabled}
            multiple={multiple}
            value={selected}
            onChange={handleChange}
            input={<OutlinedInput label={lable} />}
            renderValue={(select) => select.join(", ")}
            displayEmpty
            style={{
              backgroundColor: "#F6F6F6",
              color: "#969696",
              borderRadius: "6px",
              height: "1.7rem",
            }}
            MenuProps={{ MenuListProps: { disablePadding: true } }}
            IconComponent={BsChevronDown}
            // MenuProps={MenuProps}
          >
            <MenuItem>
              <em>Select an option</em> {/* Placeholder text */}
            </MenuItem>
            {names.map((name, i) => (
              <MenuItem key={i} value={name}>
                <Checkbox
                  sx={{ padding: "0", marginRight: ".4rem" }}
                  icon={
                    <IconButton
                      sx={{
                        border: "1px solid black",
                        padding: "0",
                        border: "0",
                      }}
                    >
                      <img
                        src={require("../../Assets/Unchecked_2.png")}
                        style={{ width: "13px", objectFit: "fill" }}
                        alt=""
                      />
                    </IconButton>
                  }
                  checkedIcon={
                    <IconButton
                      sx={{
                        border: "1px solid black",
                        padding: "0",
                        border: "0",
                      }}
                    >
                      <img
                        src={require("../../Assets/CheckedIcon.png")}
                        style={{ width: "13px", objectFit: "fill" }}
                        alt=""
                      />
                    </IconButton>
                  }
                  checked={selected.indexOf(name) > -1}
                />
                <Typography fontSize="14px">{name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Selection;
