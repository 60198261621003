import { Badge, Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GET_NOTIFICATION,
  MARKED_NOTIFICATION_ADMIN,
} from "../../../Apis/notification_api";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import { useNavigate } from "react-router-dom";
import { GET_UNREAD_COUNT } from "../../../Apis/unreadData";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import SelectionSearch from "../../../Component/Multiselection/SelectionSearch";

const values = ["All", "Customer", "Provider", "Admin & Support"];

function Notification() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [options, setOptions] = React.useState(["All"]);
  const [type, setType] = React.useState(0);
  const status = ["All", "Read", "Unread"];
  const [isRead, setIsRead] = useState(["All"]);

  const [categories, setCategories] = useState([]);
  const [isReadC, setIsReadC] = useState(["All"]);

  function getStatusByValue(v) {
    if (v === "All") {
      return -1;
    } else if (v === "Read") {
      return 1;
    } else if (v === "Unread") {
      return 0;
    } else {
      return -1;
    }
  }

  const handleIsRead = (e) => {
    const {
      target: { value },
    } = e;
    setIsRead([value]);
    getData(0, type, getStatusByValue(value));
  };

  const handleCategory = (e) => {
    const value = e.target.value;
    setIsReadC([value]);

    const selectedObject = data.find((item) => item.name === value);
    if (selectedObject) {
      setType(selectedObject.type);
    } else if (value === "All") {
      setType(0);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setOptions([value]);
  };

  const markedAsRead = async (types) => {
    // const res = await MARKED_NOTIFICATION_ADMIN(types);
    // if(res && res.s){
    //   getUnreadData();
    // }
    // getUnreadData();
  };

  const navigation = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 80,
      minWidth: 80,
      maxWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography fontSize="12px">
            {params.api.getRowIndex(params.row.id) + 1}
          </Typography>
        );
      },
    },

    {
      field: "title",
      headerName: "Title",
      width: 140,
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px" textAlign="center">
            {params.row.title}
          </Typography>
        );
      },
    },
    {
      field: "ref_id",
      headerName: "RefID",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            sx={{
              flex: 1,
              width: "100%",
              height: "100%",
            }}
            onClick={() => {
              if (params.row.ref_table == "orders") {
                window.open("/order/all/" + params.value, "_blank");
              }
            }}
          >
            <Typography>
              {params.row.ref_table == "orders"
                ? "OrderID - " + params.value
                : "-"}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "body",
      headerName: "Message",
      width: 450,
      minWidth: 450,
      maxWidth: 500,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px" textAlign="center">
            {params.row.body}
          </Typography>
        );
      },
    },

    {
      field: "status",
      headerName: "Is Read",
      width: 110,
      minWidth: 110,
      maxWidth: 240,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value ? (
          <Typography sx={{ fontWeight: "bold", color: "green" }}>
            Yes
          </Typography>
        ) : (
          <Typography sx={{ fontWeight: "bold", color: "red" }}>No</Typography>
        );
      },
    },

    {
      field: "created_at",
      headerName: "Date & Time",
      width: 110,
      minWidth: 110,
      maxWidth: 240,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} isUtc={false} />;
      },
    },
  ];

  async function getData(count, types, readType = -1) {
    //console.log(readType,"readType")
    if (count == 0) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GET_NOTIFICATION(count, types, readType);

    if (t && t.s == 1) {
      if (t.r) {
        if (count == 0) {
          setRows([...t.r]);
        } else {
          setRows([...rows, ...t.r]);
        }
      } else {
        if (count == 0) {
          setRows([]);
        }
      }
    }

    if ((t.r ?? []).length == 0 && count > 0) {
      setIsCall(false);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getData(0, type, getStatusByValue(isRead[0]));
  }, [type]);

  useEffect(() => {
    try {
      getUnreadData();
    } catch (e) {}
  }, []);

  const getUnreadData = async () => {
    const d = await GET_UNREAD_COUNT();
    if (d && d.s) {
      setData(d?.r ?? []);
    }

    const newArray = d?.r.map((item) => item.name);
    const newArrayWithAll = ["All", ...newArray];
    setCategories(newArrayWithAll);
  };

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length, type);
    }
  };

  const markAllRead = async () => {
    await MARKED_NOTIFICATION_ADMIN(type);
    await getUnreadData();
  };

  return (
    <Stack gap="1rem">
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          gap="15px"
          overflow="auto"
          py={1.5}
          sx={{
            scrollbarWidth: "none",
          }}
        >
          <Badge
            max={999}
            color="error"
            badgeContent={
              data.length
                ? data.reduce((v, c) => {
                    return v + c.count;
                  }, 0)
                : 0
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              // onClick={() => {
              //   setType(0);
              // }}
              sx={{
                p: 1,
                px: 1.5,
                borderRadius: "10px",
                display: "flex",
                backgroundColor: type == 0 ? "#F8B03F" : "#eafbff",
              }}
            >
              <Typography color="#4F4F4F">All</Typography>
            </Box>
          </Badge>

          {data.map((v) => (
            <Badge
              key={v.id}
              // onClick={() => {
              //   markedAsRead(v.type);
              // }}
              sx={
                {
                  // cursor: "pointer",
                }
              }
              max={99}
              color="error"
              badgeContent={v.count}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box
                // onClick={() => {
                //   setType(v.type);
                // }}
                sx={{
                  p: 1,
                  // cursor: "pointer",
                  borderRadius: "10px",
                  display: "flex",
                  backgroundColor: type == v.type ? "#F8B03F" : "#eafbff",
                }}
              >
                <Typography color="#4F4F4F">{v.name}</Typography>

                {/* <Typography fontWeight="bold" color="red">
              {v.count}
            </Typography> */}
              </Box>
            </Badge>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            mt: 1,
          }}
        >
          <MultiSelection
            selected={isRead}
            handleChange={handleIsRead}
            names={status}
            lable={"Status"}
            multiple={false}
          />

          <SelectionSearch
            selected={isReadC}
            handleChange={handleCategory}
            names={categories}
            lable={"Topic"}
            multiple={false}
          />

          <Button
            onClick={() => markAllRead()}
            variant="contained"
            sx={{
              backgroundColor: "#F8B03F",
              color: "#000",
            }}
          >
            Mark All Read
          </Button>
        </Box>
      </Box>
      <Box boxShadow="3px 3px 7px 0px #00000040">
        <DataGrideView
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          callBack={callBack}
          colHeight="4rem"
          sx={{ height: "74vh" }}
        />
      </Box>
    </Stack>
  );
}

export default Notification;
