import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Compressor from "compressorjs";
import { Delete, PhotoCamera } from "@mui/icons-material";
import { API_URL } from "../../../Apis/config";
import TextFieldView from "../../../Component/TextField/TextFieldView";
import { GETONSPOTCAT } from "../../../Apis/on_sport_category_api";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import ReactQuill from "react-quill";
import { ADD_UPDATE_NEW_ON_SPOT } from "../../../Apis/new_on_spot_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import { useNavigate } from "react-router-dom";
import MultiSelectOld from "../../../Component/Multiselection/MultiSelectOld";
import ButtonView from "../../../Component/Button/ButtonView";

function CreateOnSpot({ row }) {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = React.useState(
    row?.logo ?? null
  );
  const [selectedImageCover, setSelectedImageCover] = React.useState(null);
  const [name, setName] = React.useState(row?.name ?? "");
  const [category, setCategory] = React.useState([]);
  const [categoryW, setCategoryW] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState(
    row?.category ? [row.category] : []
  );
  const [description, setDescription] = React.useState(row?.description ?? "");
  const [categoryId, setSelectedId] = React.useState(
    row?.on_spot_category_id ?? 0
  );
  const [termcon, setTermCon] = React.useState(row?.term_condition ?? "");
  const [isPaid, setIsPaid] = React.useState(row?.isPaid ?? -1);
  const [isLoading, setIsLoading] = React.useState(false);
  const src = "";
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const navigation = useNavigate();

  async function getCategories() {
    const t = await GETONSPOTCAT();

    if (t && t.s == 1) {
      setCategoryW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCategory([...c]);
      if (!row) {
        setSelectedId(t.r[0].id);
        setSelectedCategories([t.r[0].name]);
      }
    }
  }

  const handleSelectCategory = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCategories([value]);
    const c = categoryW.filter((v) => v.name == value);
    setSelectedId(c.length ? c[0].id : 0);
  };

  const handleIsPaid = (e) => {
    // console.log(e);
    setIsPaid(e);
  };

  const handleSubmit = async () => {
    if (!selectedImage && !selectedImageUrl) {
      ToastShow("Please select the logo");
    } else if (categoryId < 1) {
      ToastShow("Please select the category");
    } else if (name.trim().length <=0) {
      ToastShow("Please fill the name");
    } else if (description.trim().length <=0) {
      ToastShow("Please fill the description");
    } else if (termcon.trim().length <=0) {
      ToastShow("Please fill the terms & condition");
    } else if (isPaid == -1) {
      ToastShow("Please fill the spot is paid or not");
    } else {
      setIsLoading(true);
      const c = await ADD_UPDATE_NEW_ON_SPOT({
        name: name,
        on_spot_category_id: categoryId,
        logo: selectedImage,
        description: description,
        term_condition: termcon,
        isPaid: isPaid,
        id: row?.id ?? 0,
      });

      if (c && c.s) {
        ToastShow("Succss");

        if (!row?.id) {
          setName("");
          getCategories();
          setSelectedImage(null);
          setDescription("");
          setTermCon("");
          setIsPaid(-1);
          navigation("/new-onSpot/create/" + c.r.id + "/details");
        } else {
          navigation("/new-onSpot/edit/" + row?.id + "/details");
        }
      } else {
        ToastShow(c?.m ?? "Opps! something went wrong. Please try again.");
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          width: { sm: "90%", md: "100%" },
          backgroundColor: "#fff",
          height: "87vh",
          overflow: "auto",
          pt: 2,
          boxShadow: "3px 3px 7px 0px #00000040",
          overFlowY: "auto",
          borderRadius: "6px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Select Logo</Typography>
          <Box
            sx={{
              height: "250px",
              width: "450px",
              border: "solid .3px",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#c0c0c081",
              borderColor: "#c0c0c081",
              cursor: "pointer",
              position: "relative",
            }}
            aria-label="upload picture"
            component="label"
          >
            {selectedImageUrl || selectedImage ? (
              <img
                style={{
                  borderRadius: "5px",
                  borderRadius: "20px",
                  objectFit: "cover",
                }}
                height="100%"
                width="100%"
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : API_URL.baseUrl +
                      API_URL.onspot2LogoURL +
                      "/" +
                      selectedImageUrl
                }
                alt="banner image"
              />
            ) : (
              <PhotoCamera sx={{ fontSize: "55px" }} />
            )}

            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  setSelectedImageUrl(null);
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      setSelectedImage(compressedResult);
                    },
                  });
                  new Compressor(event.target.files[0], {
                    quality: 0.5,
                    success: (compressedResult) => {
                      setSelectedImageCover(compressedResult);
                    },
                  });
                }
              }}
            />

            {selectedImage || selectedImageUrl ? (
              <IconButton
                sx={{
                  position: "absolute",
                  top: 2,
                  right: 3.5,
                  backgroundColor: "#0000004c",
                }}
                onClick={() => {
                  setSelectedImage(null);
                  setSelectedImageUrl(null);
                  setSelectedImageCover(null);
                }}
              >
                <Delete sx={{ color: "#fff" }} />
              </IconButton>
            ) : null}
          </Box>
        </Box>

        <Box
          sx={{
            mt: "25px",
          }}
        >
          <MultiSelectOld
            width={450}
            multiple={false}
            names={category}
            handleChange={handleSelectCategory}
            selected={selectedCategories}
            lable="Category *"
            sx={{
              m: "0px",
            }}
          />
        </Box>

        {/* <TextFieldView
          label="Name"
          onchange={(e) => {
            setName(e);
          }}
          sx={{
            width: "450px",
            mt: "8px",
          }}
          value={name}
          required
        /> */}
        <TextField
          required
          sx={{
            mt: "15px",
            width: "450px",
          }}
          value={name}
          label="Name"
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          required
          sx={{
            mt: "15px",
            width: "450px",
          }}
          value={description}
          label="Description"
          multiline={true}
          rows={3}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Box>
          <Typography sx={{ mt: 1 }}>Terms & Conditions *</Typography>
          <ReactQuill
            style={{ width: "450px", marginBottom: "10px", marginTop: "3px" }}
            theme="snow"
            value={termcon}
            onChange={(e) => {
              setTermCon(e);
            }}
            formats={formats}
          />
        </Box>

        <Box
          sx={{
            mt: "15px",
          }}
        >
          <Typography>Select on-spot is paid condition *</Typography>
          <RadioGroup row value={isPaid} sx={{ width: "450px" }}>
            <FormControlLabel
              value={0}
              onClick={(e) => handleIsPaid(e.target.value)}
              control={<Radio />}
              label={<Typography variant="subtitle2">Un Paid</Typography>}
            />
            <FormControlLabel
              value={1}
              onClick={(e) => handleIsPaid(e.target.value)}
              control={<Radio />}
              label={<Typography variant="subtitle2">Pay per click</Typography>}
            />
            <FormControlLabel
              value={2}
              onClick={(e) => handleIsPaid(e.target.value)}
              control={<Radio />}
              label={
                <Typography variant="subtitle2">Pay per minutes</Typography>
              }
            />
          </RadioGroup>
        </Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ButtonView
            lable={row?.id ? "Update" : "Create"}
            onClick={() => {
              handleSubmit();
            }}
            sx={{ marginBottom: "1rem", width: "450px" }}
          />
        )}
      </Box>
    </Box>
  );
}

export default CreateOnSpot;
