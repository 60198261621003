import {  Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataGrideView from "../../../../Component/DataGrideView/DataGrideView";
import SplitButton from "../../../../Component/Button/SplitButton";
import { GET_WEB_PODIAN_PROCESS } from "../../../../Apis/web/processPodian_api";
import AddUpdateWebPodianProcessDailog from "../../../../Component/Dailog/web/PodianProcess";

function WebPodianProcess() {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, SetIsLoading] = useState(true);

  const getData = async () => {
    SetIsLoading(true);
    const res = await GET_WEB_PODIAN_PROCESS();

    if (res && res?.s) {
      setRow(res?.r ?? []);
    } else {
      setRow([]);
    }
    SetIsLoading(false);
  };

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setSelectedRow(null);
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "SR.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "sr",
      headerName: "Step",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "details",
      headerName: "Details",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      minWidth: 70,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          // isView={rightsUser.includes(4) ? 1 : null}
          dataId={params.row.id}
          isDelete={null}
          isEdit={1}
          onEdit={onEdit}
          onView={null}
          //   onDelete={onDelete}
          // deleteText="Delete"
        />
      ),
    },
  ];

  const onEdit = (id) => {
    // console.log(id, "id");
    setSelectedRow(() => row.find((v) => v.id == id));
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
          <DataGrideView
            rows={row}
            columns={columns}
            // callBack={callBack}
            isLoading={isLoading}
            sx={{
              height: "75vh",
            }}
            autoHeight={true}
          />
        </Box>
      </Box>
      {open && (
        <AddUpdateWebPodianProcessDailog
          open={open}
          handleOnClose={handleClose}
          row={selectedRow}
        />
      )}
    </>
  );
}

export default WebPodianProcess;
