import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import ConfirmationDailog from "../../../../../Component/Dailog/ConfirmationDailog";
import { ADD_UPDATE_NEW_ON_SPOT_ROLE } from "../../../../../Apis/new_on_spot_api";
import { useState, useEffect } from "react";
import { ToastShow } from "../../../../../Component/Toast/Toast";
import { GETROLE } from "../../../../../Apis/podian_api";
import AddNewOnSpotRole from "../../../../../Component/Dailog/AddNewOnSpotRoleDailog";

function RoleOnSpot({ getData, id, row }) {
  const [open, setOpen] = React.useState(false);
  const [isLoadig, setIsLoading] = React.useState(true);
  const [role, setRole] = React.useState([]);
  const [roleW, setRoleW] = React.useState([]);

  const handleOpen = (v = null) => {
    setOpen(true);
  };

  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOpen(false);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    setSelectedIdN(id);
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      // console.log(selectedId)
      const ids = row
        .filter((v) => v.role_id != selectedId)
        .map((v) => v.role_id)
        .join(",");
      const r = await ADD_UPDATE_NEW_ON_SPOT_ROLE({
        role_ids: ids.length ? ids : "0",
        on_spot_id: id,
      });
      if (r && r.s) {
        getData();
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };



  async function getRoles() {
    setIsLoading(true);
    const d = await GETROLE();
    const names = [];

    for (let v of d.r) {
      names.push(v.name);
    }
    setRole(names);
    setRoleW(d?.r ?? []);
    setIsLoading(false);
  }

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <Paper
        sx={{
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Role</Typography>
          <Button onClick={handleOpen}>Add Update Role</Button>
        </Box>
        <Divider />

        {row.length ? (
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              gap: "15px",
              p: 1,
              flexWrap: "wrap",
            }}
          >
            {row.map((v, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>{v.name}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                    }}
                  >
                    {/* <Button
                    onClick={()=>handleOpen(v)}
                      variant="outlined"
                      sx={{
                        borderRadius: "0px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        flex: 1,
                      }}
                    >
                      Edit
                    </Button> */}
                    <Button
                      onClick={() => onDelete(v.role_id)}
                      variant="outlined"
                      sx={{ flex: 1, borderRadius: "10px" }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography>
            Please add the role by the "ADD-UPDATE ROLE" button
          </Typography>
        )}
      </Paper>

      {open ? (
        <AddNewOnSpotRole
          row={row}
          handleOnClose={handleOnClose}
          open={open}
          on_spot_id={id}
          role={role}
          roleW={roleW}
        />
      ) : null}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete Role !"
          content="Are you sure, You want to delete the role ?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default RoleOnSpot;
