import React, { useEffect, useState } from "react";
import { Box, Button, Popover, Stack, Typography } from "@mui/material";
import { GrClose } from "react-icons/gr";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import DateMDYHMA from "../../../../../Component/Date/DateMDYHMA";

const SubmittedLinksHistory = ({ rows, open, anchorEl, handleClose }) => {
  const columns = [
    {
      field: "editor",
      headerName: "Editor name",
      width: 40,
      minWidth: 40,
      flex: 1,
      headerAlign: "start",
      align: "start",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>
            {params.row.editor}
          </Typography>
        );
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      width: 80,
      minWidth: 80,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="11.8px">{params.row.clicks}</Typography>;
      },
    },
    {
      field: "clips",
      headerName: "Clips",
      width: 80,
      minWidth: 80,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="11.8px">{params.row.clips}</Typography>;
      },
    },
    {
      field: "link",
      headerName: "Link",
      width: 160,
      minWidth: 160,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box
            onClick={() => window.open(params.row.link)}
            style={{ overflow: "hidden" }}
          >
            <Typography
              fontSize="11.2px"
              bgcolor="#DDDDDD"
              py="0.280rem"
              px="0.470rem"
              borderRadius="5px"
              sx={{ cursor: "pointer", overflow: "hidden" }}
            >
              {params.row.link}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "expiry",
      headerName: "Expire Date",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.row.expiry} isUtc={true} />;
      },
    },
    {
      field: "created_at",
      headerName: "Submitted Date",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.row.created_at} isUtc={true} />;
      },
    },
  ];

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        height: "100vh",
      }}
    >
      <Stack
        sx={{
          width: "63vw",
          p: 1.9,
          gap: 2,
          bgcolor: "white",
          borderRadius: "5px",
        }}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="18px" fontWeight="500">
            Customer Submit Link History
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        <DataGrideView
          rows={rows}
          columns={columns}
          colHeight={null}
          columnColor="#F6F6F6"
          rowsLimit={rows.length}
          hideFooter={true}
          sx={{ height: "83.8vh" }}
        />
      </Stack>
    </Popover>
  );
};

export default SubmittedLinksHistory;
