import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GETONSPOTNEWDETAILS } from "../../../Apis/new_on_spot_api";
import { Box, CircularProgress, Typography } from "@mui/material";
import CreateOnSpot from "./CreateOnSpot";

function EditOnSpotNew() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [row, setRow] = useState(null);

  const getData = async () => {
    const data = await GETONSPOTNEWDETAILS(id);

    if (data && data.s && data.r) {
      setRow(data.r);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width:"100vw",
        height:"100vh",
       
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Typography>Opps something went wrong. Please try again.</Typography>
      ) : (
        <CreateOnSpot row={row} />
      )}
    </Box>
  );
}

export default EditOnSpotNew;
