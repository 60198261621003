import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React from "react";
import { ToastShow } from "../Toast/Toast";
import {
  ADD_UPDATE_SUPPORT_CATEGORY_ADMIN,
  GET_SUPPORT_ADMIN_TO_ADD,
} from "../../Apis/support_api";
import { useEffect } from "react";
import MultiSelection from "../Multiselection/MultiSelection";
import SelectSmall from "../SelectComponent/SelectSmall";

function AddUpdateSupportCategoryAdmin({ categoryId, open, handleOnClose }) {
  const [admins, setAdmins] = React.useState([]);
  const [adminsW, setAdminsW] = React.useState([]);
  const [selectedAdmins, setSelectedAdmins] = React.useState([]);
  const [adminId, setSelectedId] = React.useState("");

  const handleOnSubmit = async () => {
    if (!adminId) {
      ToastShow("Please select the admin.");
    } else {
      const data = await ADD_UPDATE_SUPPORT_CATEGORY_ADMIN({
        adminUserIds: adminId,
        categoryId: categoryId,
      });

      if (data && data.s) {
        ToastShow("Success");
        handleOnClose(1);
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
        handleOnClose(0);
      }
    }
  };

  async function getAdmins() {
    const t = await GET_SUPPORT_ADMIN_TO_ADD(categoryId);

    if (t && t.s == 1) {
      setAdminsW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.first_name + " "+v.last_name);
      }
      setAdmins([...c]);
      setSelectedId(t.r[0].id);
      setSelectedAdmins([t.r[0].first_name + " "+ t.r[0].last_name ]);
    }
  }

  const handleSelectAdmin = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedAdmins([value]);
    const c = adminsW.filter((v) => value.includes(v.first_name + " "+v.last_name));
    const i = [];

    for (let d of c) {
      i.push(d.id);
    }

    const r = i.join(",");
    setSelectedId(r);
  };

  useEffect(() => {
    getAdmins();
  }, []);

  return (
    <Dialog open={open} onClose={() => handleOnClose(0)}>
      <DialogTitle>Add Admins</DialogTitle>
      <DialogContent
        sx={{
          width: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <SelectSmall
         label="Admins"
         name="Admins"
          value={selectedAdmins }
          onChange={handleSelectAdmin}
          menuItems={admins}
          defaultValue="Admins"
          sx={{ width: "100%", mt: 1.5 }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleOnSubmit} variant="text">
          Submit
        </Button>
        <Button onClick={() => handleOnClose(0)} variant="text">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddUpdateSupportCategoryAdmin;
