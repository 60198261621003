import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_LINK = async (link,expiry,editor,clips,clicks,id,order_ids) => {

    const formData = new FormData();

    console.log("order_ids",order_ids)

    if(link){
        formData.append("link",link);
    }

    if(order_ids){
        formData.append("orders_id",order_ids);
    }

    if(expiry){
        formData.append("expiry",expiry);
    }

    if(editor){
        formData.append("editor",editor);
    }

    if(clips){
        formData.append("clips",clips);
    }

    if(clicks){
        formData.append("clicks",clicks);
    }

    if(id){
        formData.append("link_id",id);
    }

  const res = await POSTAPI(
   id ? API_URL.updateOrderLink : API_URL.addOrderLink ,
    formData
  );
  return res;
};

export const GET_CATEGORIES_FOR_PROVIDER = async ( for_user_id) => {
  const res = await GETAPI(
    API_URL.getCategories,
    "&for_users_role_id=" + for_user_id 
  );
  return res;
};

export const SET_CATEGORIES_FOR_PROVIDER = async ( for_users_role_id,category_ids,for_user_id) => {
    const formeData = new FormData();
    formeData.append("for_users_role_id",for_users_role_id);
    formeData.append("category_ids",category_ids);
    formeData.append("for_user_id",for_user_id);
    const res = await POSTAPI(
      API_URL.setAutoApproveCategories,
      formeData
    );
    return res;
  };


