// ===========================|| DASHBOARD - TOTAL GROWTH BAR CHART ||=========================== //

const chartData  = (categories,data)=>{
    return {
        options: {
            chart: {
                id: 'POD'
            },
            xaxis: {
                categories: categories
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
        },
        series: [
            {
                name: 'Units',
                data: data
            }
        ]
    }
}
;
export default chartData;
