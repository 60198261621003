import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_PRAMOTIONAL_BANNER = async (count, id) => {
  const res = await GETAPI(
    API_URL.getPramotionBanner,
    "&city_id=" + id + "&count=" + count
  );
  return res;
};

export const GET_PRAMOTIONAL_BANNER_DETAILS = async (id) => {
  const res = await GETAPI(API_URL.getPramotionBannerDetails, "&id=" + id);
  return res;
};

export const ADD_PRAMOTIONAL_BANNER = async (data) => {
  const formData = new FormData();

  if (data.file) {
    formData.append("file", data.file, "file.png");
  }

  formData.append("city_id", data.city_id);

  if (data.url) {
    formData.append("url", data.url);
  } else {
    formData.append("url", "");
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updatePramotionBanner : API_URL.addPramotionBanner,
    formData
  );
  return res;
};

export const DELETE_PRAMOTIONAL_BANNER = async (id) => {
  const res = await GETAPI(API_URL.deletePramotionBanner, "&id=" + id);
  return res;
};
