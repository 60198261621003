import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { GET_Unavalability } from "../../../Apis/appIssue";
import { API_URL } from "../../../Apis/config";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import { CustomProvider, DatePicker  } from "rsuite";
import { addDays, format, lastDayOfMonth, subDays } from "date-fns";
import { GET_CITIE } from "../../../Apis/city_api";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { dateFormateHHMMA } from "../../../Service/DateSevice";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

function Unavailability() {
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [cityId, setSelectedId] = React.useState(0);
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const theme = useTheme();
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [dateRange, setDateRange] = useState({});

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "photo_thumb",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Avatar
          sx={{ width: "32px", height: "32px" }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "uname",
      headerName: "User Name",
      filterable: false,
      headerAlign: "center",
      width: 180,
      align: "center",
      renderCell: (params) => {
        return <Typography>{params.value ?? "-"}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      filterable: false,
      headerAlign: "center",
      width: 250,
      align: "center",
      renderCell: (params) => {
        return <Typography>{params.value ?? "-"}</Typography>;
      },
    },
    {
      field: "from_date_time",
      headerName: "From D/T",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "to_date_time",
      headerName: "To D/T",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
      setSelectedId(t.r[0].id);
      setSelectedCities([t.r[0].name]);
      getData(Date.now(), t.r[0].id);
    }
  }

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    const c = citiesW.filter((v) => v.name == value);
    setSelectedId(c.length ? c[0].id : 0);
    await getData(selectedDate, c.length ? c[0].id : 0);
  };

  // const getData = async (c, dateRange, city_ID,) => {
  //   if (!c) {
  //     setIsCall(true);
  //   }

  //   setIsLoading(true);

  //   const data = await GET_Unavalability(c, dateRange, city_ID);

  //   if (!c) {
  //     if (data && data?.r) {
  //       setRows([...(data?.r ?? [])]);
  //     } else {
  //       setRows([]);
  //     }
  //   } else {
  //     if (data && data?.r) {
  //       setRows([...rows, ...(data.r ?? [])]);
  //     }
  //   }

  //   setIsLoading(false);
  //   if ((data.r ?? []).length == 0) {
  //     setIsCall(false);
  //   }
  // };

  const getData = async (date, city_ID) => {
    // if (!c) {
    //   setIsCall(true);
    // }

    setIsLoading(true);

    const data = await GET_Unavalability(format(date, "yyyy-MM-dd"), city_ID);

    setRows([...(data?.r ?? [])]);
    // if (!c) {
    //   if (data && data?.r) {
    //     setRows([...(data?.r ?? [])]);
    //   } else {
    //     setRows([]);
    //   }
    // } else {
    //   if (data && data?.r) {
    //     setRows([...rows, ...(data.r ?? [])]);
    //   }
    // }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length, dateRange, cityId);
    }
  };

  const handleSelectDate = (v) => {

    // console.log(v   , "dsfawsdf")
    getData( v ?? Date.now(), cityId);
    
    // if (Object.keys(v).length) {
    //   const d = {
    //     start:
    //       format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
    //       " 00:00:01",
    //     end:
    //       format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
    //       " 23:59:59",
    //   };
    //   setDateRange(d);
    //   getData( d, cityId);
     
    // } else {
    //   // setDateRange(v);
    //   // getData( v, cityId);
    // }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState(null);

  const handleClick = (event, e) => {
    setSelectedData(event);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setSelectedData(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box display="flex" alignItems={"flex-end"} flexDirection="column">
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              gap: 2,
              mb: 2,
            }}
          >
            <MultiSelection
              multiple={false}
              names={cities}
              handleChange={handleSelectCity}
              selected={selectedCities}
              lable="Cities"
            />

            <Box
              sx={{
                bgcolor: "white",
                boxShadow: "3px 3px 7px 0px #00000040",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingY: ".245rem",
                gap: ".1rem",
              }}
            >
              <Box width="95%">
                <Box
                  sx={{
                    display: "flex",
                    gap: ".3rem",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "0",
                      padding: "0",
                    }}
                  >
                    <img
                      src={require("../../../Assets/calendar-icon.png")}
                      style={{ objectFit: "fill", width: "11px" }}
                      alt=""
                    />
                  </IconButton>
                  <Typography fontSize="10px">Date Select</Typography>
                </Box>
              </Box>

              <Box width="95%">
                <CustomProvider theme={theme.palette.mode}>
                  <DatePicker
                   format="MM/dd/yyyy"
                    className="date"
                    placeholder="dd/mm/yyy"
                    appearance="subtle"
                    placement="bottomEnd"
                    caretAs="a"
                    style={{
                      backgroundColor: "#F6F6F6",
                      width: "11rem",
                      height: "1.7rem",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    
                    size="sm"
                    onChange={(v) => handleSelectDate(v)}
                    ranges={[]}
                  />
                </CustomProvider>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <DataGrideView
          rows={rows}
          columns={columns}
          callBack={callBack}
          isLoading={isLoading}
          autoHeight={true}
          sx={{
            height: "75vh",
          }}
        /> */}

        {isLoading ? (
          <CircularProgress />
        ) : (
          <Paper
            style={{
              width: "100%",
              height: "80vh",
              padding: "10px",
              marginTop: "20px",
            }}
          >
            <Calendar
              style={{ width: "100%", height: "100%" }}
              allDayAccessor
              views={["month", "day", "week"]}
              selectable
              localizer={localizer}
              // defaultDate={new Date()}
              defaultView="month"
              startAccessor="start"
              endAccessor="end"
              tooltipAccessor={(v) => {
                return "Click to open";
              }}
              
              eventPropGetter={(event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: "white",
                  border: "solid 1px #0000001b",
                  borderRadius: "15px",
                  boxShadow:" 3px 3px 5px 2px #0000001b"
                };

                return {
                  className: "",
                  style: newStyle,
                };
              }}
              titleAccessor={(v) => {
                // console.log("data", v);
                return (
                  <Box key={v.id} width="100%">
                    <Box display="flex" gap="5px" alignItems="center">
                      {/* <Avatar
                        src={
                          API_URL.baseUrl +
                          API_URL.userProfileImage +
                          v.photo_thumb
                        }
                        sx={{
                          width: "35px",
                          height: "35px",
                        }}
                      /> */}
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          sx={{
                            fontSize: "9px",
                          }}
                        >
                          {v.uname}
                        </Typography>
                        {/* <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "9px",
                              color: "black",
                            }}
                          >
                            {dateFormateHHMMA(v.start)}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "9px",
                              color: "black",
                            }}
                          >
                            TO
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "9px",
                              color: "black",
                            }}
                          >
                            {dateFormateHHMMA(v.end)}
                          </Typography>
                        </Box> */}
                        {/* <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "12px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Reason : {v.reason}
                        </Typography> */}
                      </Box>
                    </Box>
                  </Box>
                );
              }}
              doShowMoreDrillDown={true}
              popup={true}
              showAllEvents={true}
              // allDayMaxRows={1}
              // length={1}
              // max={1}

              date={selectedDate}
              events={rows.map((v, index) => ({
                id: index,
                title: v.uname,
                start: new Date(v.from_date_time),
                end: new Date(v.to_date_time),
                resourceId: v.id,
                uname: v.uname,
                photo_thumb: v.photo_thumb,
                reason: v.reason,
              }))}
              onNavigate={(d) => {
                // console.log(d);
                setSelectedDate(d);
                getData(d, cityId);
              }}
              
              onSelectEvent={(event, e) => handleClick(event, e)}
              // onSelectSlot={handleSelect}
            />
            
          </Paper>
        )}
      </Box>

      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box key={selectedData.id} width="100%" p={2}>
            <Box display="flex" gap="5px" alignItems="center">
              <Avatar
                src={
                  API_URL.baseUrl +
                  API_URL.userProfileImage +
                  selectedData.photo_thumb
                }
                sx={{
                  width: "35px",
                  height: "35px",
                }}
              />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  {selectedData.uname}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "9px",
                      color: "black",
                    }}
                  >
                    {dateFormateHHMMA(selectedData.start)}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "9px",
                      color: "black",
                    }}
                  >
                    TO
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "9px",
                      color: "black",
                    }}
                  >
                    {dateFormateHHMMA(selectedData.end)}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Reason : {selectedData.reason}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Popover>
      )}
    </>
  );
}

export default Unavailability;
