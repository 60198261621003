import { API_URL, GETAPI } from "./config";

export const GET_REPORT = async (roleId, cityId, dateRange) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.podianAvgRate, query + "&export=0");

  return res;
};

export const GET_REPORT_Agv_Work = async (roleId, cityId, dateRange) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.podianAvgWork, query + "&export=0");

  return res;
};

export const GET_REPORT_Total_Work = async (roleId, cityId, dateRange) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.podianTotalWork, query + "&export=0");

  return res;
};

export const GET_REPORT_Total_Earn = async (roleId, cityId, dateRange) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.podianTotalEarn, query + "&export=0");

  return res;
};

export const GET_REPORT_Total_Shoot = async (
  roleId,
  cityId,
  dateRange,
  categoryIds,
  shoots
) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (categoryIds) {
    query += "&category_ids=" + categoryIds;
  }
  if (shoots) {
    query += "&shoots=" + shoots;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.podianTotalShoot, query + "&export=0");

  return res;
};

export const GET_REPORT_Total_Shoot_By_Hours = async (
  roleId,
  cityId,
  dateRange,
  categoryIds,
  shoots,
  hours
) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (categoryIds) {
    query += "&category_ids=" + categoryIds;
  }

  if (hours) {
    query += "&hours=" + hours;
  }

  if (shoots) {
    query += "&shoots=" + shoots;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.podianTotalShootByHours, query + "&export=0");

  return res;
};

export const GET_RECIEVED_BY_CATEGORY = async (roleId, cityId, dateRange) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.imageReceivedByCategory, query + "&export=0");

  return res;
};


export const GET_DELIVERED_BY_CATEGORY = async (roleId, cityId, dateRange) => {
  let query = "";
  if (roleId) {
    query += "&role_ids=" + roleId;
  }

  if (cityId) {
    query += "&city_ids=" + cityId;
  }

  if (Object.keys(dateRange).length > 0) {
    query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
  }
  const res = await GETAPI(API_URL.imageDeliveredByCategory, query + "&export=0");

  return res;
};
