import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import ButtonView from "../../../Component/Button/ButtonView";
import { BsChevronDown } from "react-icons/bs";
import React, { useState } from "react";
import Compressor from "compressorjs";
import { Delete, PhotoCamera, VideoCameraFront } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ToastShow } from "../../../Component/Toast/Toast";
import { ADD_PORTFOLIO } from "../../../Apis/portfolio_api";
import { storage } from "../../../Service/FirebaseInit";
import { GrClose } from "react-icons/gr";

const AddPortfolioModal = ({
  open,
  handleOnClose,
  categories,
  categoriesW,
  id,
  src = "",
  sCat,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = React.useState([
    sCat ?? "",
  ]);
  const [selectMedia, setSelectMedia] = React.useState([]);
  const handleSelectCategories = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCategories([value]);
  };

  const handleOnSubmit = async () => {
    if (!selectMedia.length) {
      ToastShow("Please select the media");
    } else if (!selectedCategories?.length && !id) {
      ToastShow("Please select category");
    } else {
      const c = categoriesW?.filter((v) => v?.name == selectedCategories[0]);
      setIsLoading(true);
      for (let i of selectMedia) {
        const f = await uploadFile(i.media, c[0].id, i.type);
        // const t = await uploadFile(i.showMedia, c[0].id, i.type);
        await new Promise(async (resolve) => {
          new Compressor(i.type == 1 ? i.media : i.thumb, {
            quality: 0.5,
            success: async (compressedResult) => {
              const t = await uploadFile(compressedResult, c[0].id, i.type);
              if (f && t) {
                const d = await ADD_PORTFOLIO({
                  file: f,
                  thumb: t,
                  category_id: !id ? c[0].id : 0,
                  id: id,
                  type: i.type,
                });

                resolve(d);
              }
            },
          });
        });
      }

      setIsLoading(false);
      handleOnClose(1);
      // if (d && d.s) {
      //   ToastShow("Successfully added");
      //   handleOnClose(1);
      // } else {
      //   ToastShow("Something went wrong. Please try again.");
      // }
    }
  };

  const uploadFile = async (file, catID, type) => {
    return new Promise((resolve, reject) => {
      const folder = type == 1 ? "image" : "video";

      const storageRef = ref(
        storage,
        `/portfolio/${catID}/${folder}/${Date.now() + (file?.name ?? ".png")}`
      );

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => reject(null),
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(url);
        }
      );
    });
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        canvas.toBlob((blob) => {
          return resolve(blob);
        });
      };
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(4px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "6px",
            padding: "1.3rem",
            overflow: "auto",
            height: selectMedia.length > 2 ? "85%" : "auto",
          }}
        >
          <Box
            sx={{
              overfflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#F6F6F6",
                  width: "100%",
                  height: "44px",
                  padding: ".6rem",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "1.150rem",
                  fontWeight: "500",
                }}
              >
                Add Portfolio
                <GrClose
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOnClose(0)}
                />
              </Typography>
            </Box>

            <Box
              sx={{
                bgcolor: "#F6F6F6",
                width: "100%",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                padding: ".6rem",
                gap: ".6rem",
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  bgcolor: "white",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/*SELECT IMAGE*/}
                <Box
                  sx={{
                    width: "495px",
                    height: "70%",
                    display: "flex",
                    flexWrap: "wrap",
                    overflowY: "auto",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {/*Select*/}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography pl="0.6rem">Please select the media</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(event) => {
                            if (event.target.files.length) {
                              const d = {};
                              d.type = 1;
                              new Compressor(event.target.files[0], {
                                quality: 0.8,
                                success: (compressedResult) => {
                                  d.id = Date.now();
                                  d.media = compressedResult;

                                  d.showMedia =
                                    URL.createObjectURL(compressedResult);
                                  event.target.value = null;
                                  setSelectMedia((p) => [...p, d]);
                                },
                              });
                            }
                          }}
                        />
                        <PhotoCamera />
                      </IconButton>

                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="video/*"
                          type="file"
                          onChange={async (event) => {
                            if (event.target.files.length) {
                              if (
                                event.target.files[0].size / (1024 * 1024) >
                                20
                              ) {
                                ToastShow(
                                  "Please upload a file smaller than 20 MB"
                                );
                                return;
                              }
                              const d = {};
                              d.type = 2;
                              d.id = Date.now();
                              // console.log();
                              d.media = event.target.files[0];
                              const t = await generateVideoThumbnail(
                                event.target.files[0]
                              );
                              d.thumb = t;
                              d.showMedia = URL.createObjectURL(t);
                              event.target.value = null;

                              setSelectMedia((p) => [...p, d]);
                            }
                          }}
                        />
                        <VideoCameraFront />
                      </IconButton>
                    </Box>
                  </Box>

                  {selectMedia.length
                    ? selectMedia.map((v, i) => {
                        // console.log(v)
                        return (
                          <Box
                            key={v.id}
                            sx={{
                              height: "100%",
                              width: "300px",
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              objectFit: "fill",
                              m: "8px",
                              position: "relative",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              height="180px"
                              width="100%"
                              src={v.showMedia}
                              alt="media"
                              style={{ objectFit: "cover" }}
                            />

                            <Box
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              {v.type !== 1 && (
                                <VideoCameraFront
                                  sx={{
                                    backgroundColor: "#c0c0c06a",
                                    p: 0.5,
                                    borderRadius: "50%",
                                  }}
                                />
                              )}
                            </Box>

                            <Tooltip title="Remove">
                              <IconButton
                                onClick={() => {
                                  const d = selectMedia.filter(
                                    (t) => t.id != v.id
                                  );
                                  setSelectMedia(d);
                                }}
                                sx={{
                                  position: "absolute",
                                  bottom: "10px",
                                  right: "0px",
                                }}
                              >
                                <Delete
                                  sx={{
                                    backgroundColor: "#c0c0c06a",
                                    p: 0.5,
                                    borderRadius: "50%",
                                    color: "red",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        );
                      })
                    : null}
                </Box>
                {/*SELECT IMAGE*/}
              </Box>

              {/*Multi-Selection*/}
              {id == 0 ? (
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "6px",
                    width: "495px",
                    height: "80px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box width="95%" paddingTop=".3rem" paddingBottom=".2rem">
                    <Typography fontSize="14.8px">Categories</Typography>
                  </Box>

                  <Box width="95%">
                    <FormControl
                      sx={{
                        width: "100%",
                        borderRadius: "6px",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <Select
                        size="small"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple={false}
                        value={selectedCategories}
                        onChange={handleSelectCategories}
                        input={<OutlinedInput label={"Categories"} />}
                        renderValue={(select) => select.join(", ")}
                        style={{
                          height: "40px",
                          backgroundColor: "#F6F6F6",
                          "& fieldset": { border: "none" },
                          color: "#969696",
                          borderRadius: "6px",
                        }}
                        IconComponent={BsChevronDown}
                        // MenuProps={MenuProps}
                      >
                        {categories.map((name, i) => (
                          <MenuItem key={i} value={name}>
                            <Checkbox
                              icon={
                                <img
                                  src={require("../../../Assets/Unchecked_2.png")}
                                  alt=""
                                />
                              }
                              checkedIcon={
                                <img
                                  src={require("../../../Assets/CheckedIcon.png")}
                                  alt=""
                                />
                              }
                              checked={selectedCategories.indexOf(name) > -1}
                              style={{
                                transform: "scale(1.3)",
                              }}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              ) : null}
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
              }}
            >
              {isLoading ? (
                <Box
                  width="50%"
                  bgcolor="#F8AF41"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="5px"
                >
                  <CircularProgress size="2.2rem" sx={{ color: "green" }} />
                </Box>
              ) : (
                <ButtonView
                  onClick={handleOnSubmit}
                  lable="Add Portfolio"
                  sx={{
                    borderRadius: "6px",
                    height: "2.9rem",
                    flex: 1,
                  }}
                  fontSize="1.1rem"
                  fontWeight="500"
                />
              )}

              <ButtonView
                onClick={handleOnClose}
                lable="Cancel"
                sx={{ borderRadius: "6px", height: "2.9rem", flex: 1 }}
                fontSize="1.1rem"
                fontWeight="500"
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddPortfolioModal;
