import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import ConfirmationDailog from "../../../../../../Component/Dailog/ConfirmationDailog";
import {
  ADD_UPDATE_NEW_ON_SPOT_CITY,
} from "../../../../../../Apis/new_on_spot_api";
import { useState, useEffect } from "react";
import { ToastShow } from "../../../../../../Component/Toast/Toast";
import { GET_CITIE } from "../../../../../../Apis/city_api";
import AddNewOnSpotCity from "../../../../../../Component/Dailog/AddNewOnSpotCityDailog";

function CityOnSpot({ getData, id, row }) {
  const [open, setOpen] = React.useState(false);

  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);

  const handleOpen = (v = null) => {

    setOpen(true);
  };

  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOpen(false);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    setSelectedIdN(id);
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      // console.log(selectedId)
      const ids = row
        .filter((v) => v.city_id != selectedId)
        .map((v) => v.city_id)
        .join(",");
      const r = await ADD_UPDATE_NEW_ON_SPOT_CITY({
        city_ids: ids.length ? ids : "0",
        on_spot_id: id,
      });
      if (r && r.s) {
        getData();
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
    }
  }

  useEffect(() => {
    getCities();
  }, []);

  return (
    <>
      <Paper
        sx={{
          p: 1,
          mx:3
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">City</Typography>
          <Button onClick={handleOpen}>Add Update City</Button>
        </Box>
        <Divider />

        {row.length ? (
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              gap: "15px",
              p: 1,
              flexWrap: "wrap",
            }}
          >
            {row.map((v, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>{v.name}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                    }}
                  >
                    {/* <Button
                    onClick={()=>handleOpen(v)}
                      variant="outlined"
                      sx={{
                        borderRadius: "0px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        flex: 1,
                      }}
                    >
                      Edit
                    </Button> */}
                    <Button
                      onClick={() => onDelete(v.city_id)}
                      variant="outlined"
                      sx={{ flex: 1, borderRadius: "10px" }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography>
            Please add the city by the "ADD-UPDATE CITY" button
          </Typography>
        )}
      </Paper>

      {open ? (
        <AddNewOnSpotCity
          row={row}
          handleOnClose={handleOnClose}
          open={open}
          on_spot_id={id}
          cities={cities}
          citiesW={citiesW}
        />
      ) : null}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete City !"
          content="Are you sure, You want to delete the City?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default CityOnSpot;
