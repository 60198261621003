import { POSTAPI, API_URL } from "./config";

export const API_LOGIN = async (data) => {
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("password", data.password);
  // const res = await POSTAPI(API_URL.login, data);
  // return res;
};

export const API_SEND_OTP = async (data) => {
  const formData = new FormData();
  formData.append("mobile", data.mobile);
  const res = await POSTAPI(API_URL.send_otp, formData);
  return res;
};

export const API_VARIFY_OTP = async (data) => {
  const formData = new FormData();
  formData.append("otp_id", data.otp_id);
  formData.append("otp", data.otp);
  formData.append("type", 3);
  const res = await POSTAPI(API_URL.varify, formData);
  return res;
};

// GET_OTP
// mobile   string

// VARIFY_OTP
// otp_id   integer
// otp   integer
// type   integer  optional
// (1 : pod, 2: podian, 3 : admin & support)
