import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import ItemView from "./ItemView";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { rightsUser } from "./DrawerView";
import expand_more_down from "../../../Assets/Icons for pages/expand_more_down.png";

function ExpandView({ item, openD, expandedIndex, setExpandedIndex }) {
  const location = useLocation();

  const handleChange = () => {
    setExpandedIndex(expandedIndex === item.id ? null : item.id);
  };

  useEffect(() => {
    console.log(location.pathname);
  }, []);

  return (
    <Box>
      <Accordion
        expanded={expandedIndex === item.id}
        // defaultExpanded={location.pathname.includes(item.path)}
        disableGutters
        sx={{
          width: "100%",
          backgroundColor: "#1C1000",
          border: "none",
          outline: "none",
          color: "#BEBEBE",

          "&:hover": {
            bgcolor: "#f8af4122",
          },
          "& .css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root": {
            minHeight: "2.4rem",
          },
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={
            item.isExpand && <img src={expand_more_down} width="12px" />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ height: "1.6rem" }}
          onClick={handleChange}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Avatar
              sx={{
                height: "24px",
                width: "24px",
                bgcolor: "transparent",
              }}
            >
              <img src={item.icon} alt="" />
            </Avatar>

            {openD && (
              <Typography
                sx={{
                  width: "100%",
                  ml: 1,
                  color: "#BEBEBE",
                  fontSize: "15.7px",
                }}
              >
                {item.title}
              </Typography>
            )}
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          {item.children.map((v) => {
            // console.log(v.rights);
            return v.isExpand ? (
              <ExpandView item={v} key={v.id} width={"200px"} openD={openD} />
            ) : rightsUser.includes(v.rights) ? (
              <ItemView
                item={v}
                key={v.id}
                width={"200px"}
                bgColor={"secondary.dark"}
                openD={openD}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
              />
            ) : !v.rights ? (
              <ItemView
                item={v}
                key={v.id}
                width={"200px"}
                bgColor={"secondary.dark"}
                openD={openD}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
              />
            ) : null;
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default ExpandView;
