import React from 'react'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import CoupenAdd from './CoupenAdd';

function CoupenEdit() {
    const {state} = useLocation();
    const navigator = useNavigate();

    useEffect(()=>{
        if(!state){
            navigator("/coupon");
        }
    },[])
  return (
   <CoupenAdd row={state}/>
    
  )
}

export default CoupenEdit