import { API_URL, GETAPI, POSTAPI } from "./config";

export const CATEGORYADD = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("description", data.description);
  if (data.cId) {
    formData.append("id", data.cId);
  }
  if (data.photo) {
    formData.append("photo", data.photo, "photo.png");
  }

  const res = await POSTAPI(
    data.cId ? API_URL.categoryUpdate : API_URL.categoryAdd,
    formData,
    {
      "Content-Type": "multipart/form-data",
    }
  );

  return res;
};

export const CATEGORYSUBADD = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("description", data.description);

  if (data.cId) {
    formData.append("id", data.cId);
  }

  if (data.pId) {
    formData.append("parent_id", data.pId);
  }

  if (data.photo) {
    formData.append("photo", data.photo, "photo.png");
  }

  const res = await POSTAPI(
    data.cId ? API_URL.categoryUpdate : API_URL.categoryAdd,
    formData,
    {
      "Content-Type": "multipart/form-data",
    }
  );

  return res;
};

export const GETCATEGORY = async (id) => {
  let query = "";

  if (id) {
    query += "&parent_id=" + id;
  }
  const res = await GETAPI(API_URL.categoryGet, query);

  return res;
};

export const GETCATEGORYDETAILS = async (id) => {
  const res = await GETAPI(API_URL.categoryGetDetails, "&id=" + id);

  return res;
};

export const GETCATEGORYSERVICE = async (id) => {
  const res = await GETAPI(API_URL.serviceGet, "&category_id=" + id);
  return res;
};

export const GETCATEGORYSERVICETEMPLATE = async () => {
  const res = await GETAPI(API_URL.serviceGet, "&category_id=" + 0);
  return res;
};

export const DELETECATEGORYSERVICE = async (id) => {
  const res = await GETAPI(API_URL.serviceDelete, "&id=" + id);
  return res;
};

export const ADD_UPDATE_SERVICE = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("price", data.price);
  formData.append("category_id", data.cId);
  formData.append("isExtra", data.isExtra);
  formData.append("isFixed", data.isFixed);
  formData.append("isPerHour", data.isPerHour);
  formData.append("isPost", data.isPost);
  formData.append("isQty", data.isQty);
  formData.append("isOnSite", data.isOnSite);
  formData.append("isInventory", data.isInventory);
  formData.append("child_qty", data.child_qty);
  formData.append("isRequiredRowData", data.isRequiredRowData);
  formData.append("isRequiredEditedData", data.isRequiredEditedData);

  if (data.serviceType >= 0) {
    formData.append("type", data.serviceType);
  }

  if (data.roleIds) {
    formData.append("role_ids", data.roleIds);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updateService : API_URL.addService,
    formData
  );

  return res;
};

export const DELETECATEGORY = async (id, pId) => {
  let query = "&id=" + id;

  if (pId) {
    query += "&parent_id=" + pId;
  }

  const res = await GETAPI(API_URL.categoryDelete, query);
  return res;
};
