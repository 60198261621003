import React from "react";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import add_media from "../../../../../Assets/Icons for pages/add_media.png";
import message_send from "../../../../../Assets/Icons for pages/message_send.png";

const SendMessage = ({
  handleAttachmentClick,
  handleFileChange,
  fileInputRef,
  handleSend,
  value,
  setValue,
}) => {
  return (
    <Stack width="100%" justifyContent="space-between" direction="row">
      <Box
        sx={{
          width: "85%",
          bgcolor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "6px",
        }}
      >
        <TextField
          placeholder="Type something"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          sx={{
            height: "2.4rem",
            width: "88%",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            " .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              boxSizing: "border-box",
              fontSize: "0.9rem",
            },
            "& fieldset": { border: "none" },
          }}
        />

        <button
          onClick={handleAttachmentClick}
          style={{ width: "12%", backgroundColor: "transparent" }}
        >
          {/*Icon*/}
          <img
            src={add_media}
            style={{ width: "14px", height: "14px", objectFit: "fill" }}
            alt=""
          />
          {/* Hidden file input */}
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </button>
      </Box>
      <button
        style={{
          width: "12%",
          backgroundColor: "#F89400",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleSend}
      >
        <img
          src={message_send}
          style={{ width: "18px", height: "18px", objectFit: "fill" }}
          alt=""
        />
      </button>
    </Stack>
  );
};

export default SendMessage;
