import { useState, useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import Add_click from "../../../../Assets/Icons for pages/Add_click.png";
import CustomTable from "../CustomTable";
import LoadingOverlay from "react-loading-overlay";
import AddLableDailog from "../../../../Component/Dailog/AddLableDailog";
import { Edit } from "@mui/icons-material";

const DetailTab = ({
  title,
  services,
  hike,
  rowData,
  setRowData,
  categoryId,
  applyChanges,
}) => {
  const [mainHikePrice, setMainHikePrice] = useState(hike ? hike : "");

  const [rowsMain, setRowsMain] = useState([]);
  const [rowsPre, setRowsPre] = useState([]);
  const [rowsPost, setRowsPost] = useState([]);
  const [lableOpen, setLableOpen] = useState(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
  const [lableData,setLableData] = useState(null);

  function getData() {
    // console.log("change the service",services);

    if (services.length) {
      //Main Service
      const mainService = services.filter(
        (obj) => obj.isFixed === 1 && obj.isExtra === 0 && obj.isPost === 0
      );
      if (mainService.length > 0) {
        setRowsMain((prevState) => [...mainService]);
      }

      //Pre Add Ons
      const preService = services.filter(
        (obj) => obj.isExtra === 1 && obj.isFixed === 0 && obj.isPost === 0
      );
      if (preService.length > 0) {
        setRowsPre((prevState) => [...preService]);
      }

      //Post Add Ons
      const postService = services.filter(
        (obj) => obj.isExtra === 0 && obj.isFixed === 0
      );
      if (postService.length) {
        setRowsPost((prevState) => [...postService]);
      }
    }
  }

  const categoryServiceTextChange = async () => {
    const rowIndex = rowData.findIndex((item) => item.id === categoryId);

    if (rowIndex !== -1) {
      const updatedRowData = [...rowData];
      updatedRowData[rowIndex].price_hike = Number(mainHikePrice);
      //  const du =  updatedRowData[rowIndex].services.map(
      //     (v) => ({...v,price_hike : Number(mainHikePrice)})
      //   );
      //   console.log(du)
      // console.log("call -1")
      const duOne = updatedRowData.map((item, index) => {
        return {
          ...item,
          ordr: "" + index,
          services: item.services.map((service, i) => ({
            ...service,
            ordr: "" + i,
            price_hike:
              rowIndex == index ? Number(mainHikePrice) : service.price_hike,
          })),
        };
      });
      // console.log("call -2")
      //  console.log(updatedRowData)
      // setIsLoadingOverlay(true);
      setRowData([...duOne]);
      // await applyChanges(duOne);
      // window.location.reload();
      // setIsLoadingOverlay(false);
    }
  };

  const categoryServiceTextChangeNew = async (t) => {
    const rowIndex = rowData.findIndex((item) => item.id === categoryId);

    if (rowIndex !== -1) {
      const updatedRowData = [...rowData];
      updatedRowData[rowIndex].price_hike = Number(t);
      //  const du =  updatedRowData[rowIndex].services.map(
      //     (v) => ({...v,price_hike : Number(mainHikePrice)})
      //   );
      //   console.log(du)
      // console.log("call -1")
      const duOne = updatedRowData.map((item, index) => {
        return {
          ...item,
          ordr: "" + index,
          services: item.services.map((service, i) => ({
            ...service,
            ordr: "" + i,
            price_hike: rowIndex == index ? Number(t) : service.price_hike,
          })),
        };
      });
      // console.log("call -2")
      //  console.log(updatedRowData)
      // setIsLoadingOverlay(true);
      setRowData([...duOne]);
      // await applyChanges(duOne);
      // window.location.reload();
      // setIsLoadingOverlay(false);
    }
  };

  const categoryAddLable = async (t) => {
    const rowIndex = rowData.findIndex((item) => item.id === categoryId);

    if (rowIndex !== -1) {
      const updatedRowData = [...rowData];
      updatedRowData[rowIndex].labels = [
        {
          id: t.id,
          name: t.name,
          color: t.color,
        },
      ];

      const duOne = updatedRowData.map((item, index) => {
        return {
          ...item,
          ordr: "" + index,
          services: item.services.map((service, i) => ({
            ...service,
            ordr: "" + i,
          })),
        };
      });
      setRowData([...duOne]);
    }
  };

  
  const categoryDeleteLable = async () => {
    const rowIndex = rowData.findIndex((item) => item.id === categoryId);

    if (rowIndex !== -1) {
      const updatedRowData = [...rowData];
      updatedRowData[rowIndex].labels = [];
      const duOne = updatedRowData.map((item, index) => {
        return {
          ...item,
          ordr: "" + index,
          services: item.services.map((service, i) => ({
            ...service,
            ordr: "" + i,
          })),
        };
      });
      setRowData([...duOne]);
    }
  };

  useEffect(() => {
    getData();
  }, [services]);

  return (
    <>
      <LoadingOverlay active={isLoadingOverlay} spinner text="Updating">
        <Box p={1}>
          <Typography fontSize="17px" fontWeight="500" mb={0.8}>
            {title}
          </Typography>
          <Stack flexDirection="row" justifyContent="space-between">
            <Stack
              sx={{
                p: 0.7,
                width: "25%",
                alignItems: "center",
                bgcolor: "#EFEFEF",
                borderRadius: "6px",
              }}
            >
              <Box width="98%">
                <Typography
                  fontSize="11px"
                  alignSelf="flex-start"
                  style={{ userSelect: "none" }}
                >
                  Main Category Hike Price
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: "white",
                  width: "98%",
                  height: "1.8rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "1.2rem",
                  borderRadius: "6px",
                }}
              >
                <input
                  placeholder="Enter hike price"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    outline: "none",
                    color: "black",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                  value={mainHikePrice}
                  onChange={(e) => {
                    setMainHikePrice(e.target.value);
                    categoryServiceTextChangeNew(e.target.value);
                  }}
                  // onKeyDown={(e) => e.key === "enter" && setInputValue("")}
                />
                {/* <Button
              sx={{
                color: "black",
                bgcolor: "#F8AF41",
                borderRadius: "4px",
                height: "76%",
                px: "6px",
                mr: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  bgcolor: "#F8AF41",
                },
              }}
              style={{ minWidth: "0" }}
              onClick={categoryServiceTextChange}
            >
              <img
                src={Add_click}
                style={{ width: "13px", height: "13px", objectFit: "fill" }}
              />
            </Button> */}
              </Box>
            </Stack>

            <Stack>
              {
                rowData[rowData.findIndex((item) => item.id === categoryId)]
                  ?.labels?.length ? null:
                <Button
                variant="text"
                onClick={() => setLableOpen(true)}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#F8B03F",
                  borderRadius: "25px",
                  color: "black",
                  height: "25px",
                  "&:hover": {
                    backgroundColor: "#F8B03F",
                  },
                }}
              >
                Add label
              </Button>  
              }
              {rowData[rowData.findIndex((item) => item.id === categoryId)]
                ?.labels?.length ? (
                <Box 
                display="flex"
                alignItems="center"
                sx={{
                  mt: "5px",
                  gap:"5px"
                }}
                >
                  <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    border: "solid 0.5px #0000001d",
                    p: 1,
                    borderRadius: "25px",
                  }}
                >
                  <Typography>Label</Typography>
                  {rowData[
                    rowData.findIndex((item) => item.id === categoryId)
                  ]?.labels?.map((v) => {
                    return (
                      <Box
                        onClick={() => {
                          setLableData(v);
                          setLableOpen(true);
                        }}
                        sx={{
                          display: "flex",
                          bgcolor: "#"+v.color,
                          borderRadius: "25px",
                          px: 1,
                          alignItems: "center",
                          cursor:"pointer"
                        }}
                      >
                        <Typography>{v.name}</Typography>
                        <Box display="flex" alignItems="center">
                          <Edit fontSize="small" sx={{ fontSize: "14px" }} />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>

                {/* <Box 
                onClick={()=>{
                  categoryDeleteLable()
                }}
                sx={{
                  backgroundColor:"#ef4141ab",
                  borderRadius:"15px",
                  padding:"3px",
                  color:"white",
                  cursor:"pointer"
                }}>Delete</Box> */}

                </Box>
              ) : null}
            </Stack>
          </Stack>

          {/*Grids*/}
          <Stack gap={2} mt={2} mb={1}>
            <Stack gap="0.5rem">
              <Typography fontWeight="500">Main Servicse</Typography>

              <CustomTable
                rows={rowsMain}
                setRows={setRowsMain}
                rowData={rowData}
                services={services}
                categoryId={categoryId}
                setRowData={setRowData}
                applyChanges={applyChanges}
                pos="0"
                tempData={{
                  0: rowsMain,
                  1: rowsPre,
                  2: rowsPost,
                }}
              />
            </Stack>

            <Stack gap="0.5rem">
              <Typography fontWeight="500">Pre Add On's</Typography>

              <CustomTable
                rows={rowsPre}
                setRows={setRowsPre}
                rowData={rowData}
                services={services}
                categoryId={categoryId}
                setRowData={setRowData}
                applyChanges={applyChanges}
                pos="1"
                tempData={{
                  0: rowsMain,
                  1: rowsPre,
                  2: rowsPost,
                }}
              />
            </Stack>

            <Stack gap="0.5rem">
              <Typography fontWeight="500">Post Add On's</Typography>

              <CustomTable
                rows={rowsPost}
                setRows={setRowsPost}
                rowData={rowData}
                services={services}
                categoryId={categoryId}
                setRowData={setRowData}
                applyChanges={applyChanges}
                pos="2"
                tempData={{
                  0: rowsMain,
                  1: rowsPre,
                  2: rowsPost,
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </LoadingOverlay>

      {lableOpen && (
        <AddLableDailog
          row={lableData}
          open={lableOpen}
          handleOnClose={(v, data) => {
            if (v) {
              categoryAddLable(data);
            }
            setLableOpen(false);
          }}
        />
      )}
    </>
  );
};

export default DetailTab;
