import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import DataGrideView from "../DataGrideView/DataGrideView";
import { Dialog } from "@material-ui/core";
import DateMDYHMA from "../Date/DateMDYHMA";

function ShowInvoiceTransaction({ open, handleClose, data }) {
  const tColumn = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.type == 1 ? (
          <Typography>{`₹${params.value}`}</Typography>
        ) : (
          <Typography>{`₹${params.row.refund}`}</Typography>
        );
      },
    },
    {
      field: "method",
      headerName: "Method",
      type: "string",
      width: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "payment_id",
    //   headerName: "PaymentID",
    //   type: "string",
    //   width: 200,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.value
              ? params.value
              : params.row.cca_tracking_id
              ? params.row.cca_tracking_id
              : params.row.payment_id
              ? params.row.payment_id
              : params.row.cca_bank_ref_no}
          </Typography>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      type: "string",
      width: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {params.row.type == 1
            ? "Paid"
            : params.row.type == -1
            ? "Refund"
            : params.row.type == 0
            ? "On Hold"
            : "Rejected"}
        </Typography>
      ),
    },
  ];

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{
        width: "600px",
      }}
    >
      <DialogTitle>Transaction Details</DialogTitle>
      <DialogContent>
        <Box>
          <DataGrideView
            width="100%"
            rows={data}
            columns={tColumn}
            isVisible={1}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowInvoiceTransaction;
