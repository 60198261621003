import { API_URL, GETAPI, POSTAPI } from "./config"

export const GET_NOTIFICATION = async (count,type,readType)=>{

    let query = "";

    if(type){
        query ="&type="+type;
    }

    if(readType >=0){
        query +="&status="+readType; 
    }


    const res = await GETAPI(API_URL.getNotification,"&app=3&count="+count+query);
    return res;
}

export const ADD_NOTIFICATION = async (data)=>{

    const formData = new FormData();

    formData.append("title",data.title);
    formData.append("message",data.message);
    formData.append("date_time",data.date_time);
    formData.append("app",data.app);

    if(data.id){
        formData.append("id",data.id);
    }

    const res = await POSTAPI(data.id ? API_URL.updateNotification : API_URL.addNotification,formData);
    return res;
}


export const GET_NOTIFICATION_ADMIN = async (count,app)=>{

    const res = await GETAPI(API_URL.getNotificationAdmin,"&app="+app+"&count="+count);
    return res;
}

export const DELETE_NOTIFICATION_ADMIN = async (id)=>{

    const res = await GETAPI(API_URL.deleteNotificationAdmin,"&id="+id);
    return res;
}

export const MARKED_NOTIFICATION_ADMIN = async (type)=>{

    const res = await GETAPI(API_URL.notificationMarkRead,"&type="+type);
    return res;
}