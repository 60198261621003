import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

const RadioButton = ({
  label,
  name,
  value,
  onChange,
  options,
  containerBg = "#F6F6F6",
  checkBoxBg = "white",
  width = "19rem",
  height = "3.8rem",
}) => {
  return (
    <Box
      width={width}
      height={height}
      bgcolor={containerBg}
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY="4.2px"
      gap="4px"
    >
      <Typography width="95%" fontSize="11.8px">
        {label}
      </Typography>

      {/*Form Control*/}
      <Box width="95%">
        <RadioGroup
          row
          defaultValue={options[1]}
          name={name}
          sx={{
            width: "100%",
            height: "1.9rem",
            display: "flex",
            justifyContent: "space-between",
            "& .MuiFormControlLabel-root": {
              margin: "0",
            },
            "& .css-vxllfd-MuiFormControlLabel-root": {
              margin: "0",
            },
          }}
          onChange={onChange}
        >
          <FormControlLabel
            label={options[0]}
            name={name}
            value={options[0]}
            checked={value === options[0]}
            control={
              <Radio
                checkedIcon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_checked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
                icon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_unchecked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
              />
            }
            sx={{
              width: "48%",
              height: "100%",
              userSelect: "none",
              bgcolor: checkBoxBg,
              borderRadius: "6px",
              color: "red",
              ".MuiFormControlLabel-label": {
                color: value === options[0] ? "#F8AF41" : "#969696",
                fontWeight: value === options[0] && "500",
              },
              "& .css-7jdnmo-MuiTypography-root": {
                fontSize: "0.750rem",
              },
            }}
          />
          <FormControlLabel
            label={options[1]}
            name={name}
            value={options[1]}
            checked={value === options[1]}
            control={
              <Radio
                checkedIcon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_checked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
                icon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_unchecked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
              />
            }
            sx={{
              width: "48%",
              height: "100%",
              userSelect: "none",
              bgcolor: checkBoxBg,
              borderRadius: "6px",
              ".MuiFormControlLabel-label": {
                color: value === options[1] ? "#F8AF41" : "#969696",
                fontWeight: value === options[1] && "500",
              },
              "& .css-7jdnmo-MuiTypography-root": {
                fontSize: "0.750rem",
              },
            }}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default RadioButton;
