import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { GrClose } from "react-icons/gr";

const ShowCouponDetails = ({ open, handleClose, data }) => {
  return (
    <Popover
      open={open}
      onClose={handleClose}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        zIndex: 1,
      }}
    >
      <Stack gap={2} minWidth="22rem" minHeight="100vh" m={1.8}>
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            fontSize="20px"
            fontWeight="500"
            sx={{ userSelect: "none" }}
          >
            Coupons
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        {data.length ? (
          data.map((i, e) => (
            <Stack
              key={e}
              gap={2}
              padding={1}
              border="1px solid #b5b2b1"
              borderRadius="6px"
            >
              <Box display="flex" gap={2}>
                <Typography>Code:</Typography>
                <Typography>{i.code}</Typography>
              </Box>
              {i.nt_title ? (
                <Box display="flex" gap={2}>
                  <Typography>Title:</Typography>
                  <Typography>{i.nt_title}</Typography>
                </Box>
              ) : null}
              {i.nt_body ? (
                <Box display="flex" gap={2}>
                  <Typography>Message:</Typography>
                  <Typography>{i.nt_body}</Typography>
                </Box>
              ) : null}
              {i.discount ? (
                <Box display="flex" gap={2}>
                  <Typography>Discount:</Typography>
                  <Typography>{i.discount}</Typography>
                </Box>
              ) : null}
              {i.min_amount ? (
                <Box display="flex" gap={2}>
                  <Typography>Minimum Amount:</Typography>
                  <Typography>{i.min_amount}</Typography>
                </Box>
              ) : null}
              {i.max_amount ? (
                <Box display="flex" gap={2}>
                  <Typography>Maximum Amount:</Typography>
                  <Typography>{i.max_amount}</Typography>
                </Box>
              ) : null}
              {i.apply_limit ? (
                <Box display="flex" gap={2}>
                  <Typography>Apply Limit:</Typography>
                  <Typography>{i.apply_limit}</Typography>
                </Box>
              ) : null}
              {i.start_dt ? (
                <Box display="flex" gap={2}>
                  <Typography>Start Date:</Typography>
                  <Typography>{i.start_dt.split(" ")[0]}</Typography>
                </Box>
              ) : null}
              {i.end_dt ? (
                <Box display="flex" gap={2}>
                  <Typography>End Date:</Typography>
                  <Typography>{i.end_dt.split(" ")[0]}</Typography>
                </Box>
              ) : null}

              {/*Checkbox*/}
              <Box width="100%">
                <Typography>
                  {i.isPer === 1 ? "In Percentage" : "Not in Percentage"}
                </Typography>
              </Box>
            </Stack>
          ))
        ) : (
          <Typography textAlign="center">Coupons not available</Typography>
        )}
      </Stack>
    </Popover>
  );
};

export default ShowCouponDetails;
