import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_ON_SHOT = async (count, id, typeId) => {
  const res = await GETAPI(
    API_URL.getOnShot,
    "&city_id=" + id + "&count=" + count+"&filter="+typeId
  );
  return res;
};


export const ADD_ON_SHOT = async (data) => {
  const formData = new FormData();

  if (data.logo) {
    formData.append("logo", data.logo, "logo.png");
  }

  if(data.city_id){
    formData.append("city_id", data.city_id);
  }

  formData.append("title", data.title);

  if(data.description){
    formData.append("description", data.description);
  }
  
    if(data.date){
    formData.append("date", data.date);
  }

  if(data.from_time){
    formData.append("from_time", data.from_time);
  }
  
  if(data.to_time){
    formData.append("to_time", data.to_time);
  }

  if(data.address){
    formData.append("address", data.address);
  }

  if(data.latitude){
    formData.append("latitude", data.latitude);
  }

  if(data.longitude){
    formData.append("longitude", data.longitude);
  }


  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updateOnShot : API_URL.addOnShot,
    formData
  );
  return res;
};

export const DELETE_USER_STORY = async (id) => {
  const res = await GETAPI(API_URL.deleteUserStory, "&id=" + id);
  return res;
};

export const ADD_ON_SHOT_LINK = async (data) => {
  const formData = new FormData();

  if (data.expiry) {
    formData.append("expiry", data.expiry);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  if(data.link){
    formData.append("link", data.link);
  }

  const res = await POSTAPI(
   API_URL.addOnShotLink,
    formData
  );
  return res;
};
