import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TextFieldView from "../../../Component/TextField/TextFieldView";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastShow } from "../../../Component/Toast/Toast";
import { CATEGORYADD, GETCATEGORYDETAILS } from "../../../Apis/category_api";
import { API_URL } from "../../../Apis/config";

function CategoryAdd({ cId }) {
  // const [chips, setChips] = React.useState([]);
  const [name, setName] = React.useState("");
  // const [prHour, setPrHour] = React.useState("");
  // const [minHour, setMinHour] = React.useState("");
  const [value, setValue] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [netWorkImage, setNetWorkImage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [isSelectCategory, setIsSelectCategory] = React.useState(2);
  // const [status, setStatus] = React.useState(2);
  // const handleChange = (newChips) => {
  //   setChips(newChips);
  // };

  const handleSelectImage = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };
  const handleSubmit = async () => {
    if (!selectedImage && !netWorkImage) {
      ToastShow("Please select the image");
    } else if (!name) {
      ToastShow("Please fill name");
    }
    // else if (!value) {
    //   ToastShow("Please fill description");
    // }
    else {
      const res = await CATEGORYADD({
        cId: cId,
        name: name,
        description: value ?? "",
        photo: selectedImage,
      });

      if (res && res.s) {
        setValue("");
        setName("");
        setSelectedImage(null);
        if (cId) {
          getData();
        }
        ToastShow(
          cId ? "Category update successfully" : "Category added successfully"
        );
      } else {
        ToastShow("Opps! something went wrong. Please try again");
      }
    }
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  async function getData() {
    setIsLoading(true);
    const t = await GETCATEGORYDETAILS(cId);
    if (t && t.s == 1) {
      setName(t.r.name);
      setNetWorkImage(API_URL.baseUrl + API_URL.categoryImage + t.r.photo);
      setValue(t.r.description);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (cId) {
      getData();
    }
  }, []);

  return isLoading ? (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          // height: "80vh",
          width: "50%",
          p: 2,
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          // overflowY: "scroll",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          width="80%"
          mb="50px"
        >
          {/* TITLE */}
          <Typography variant="h6">
            {cId ? "Category Edit" : "Category Add"}
          </Typography>
          <Typography variant="subtitle2">
            Please fill all detail and {cId ? "update" : "add new"} Category in
            your Category directory
          </Typography>
        </Box>

        {/* FIELDS */}

        {/* Image */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "82%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              mb: 2.5,
            }}
          >
            <Typography variant="h6" mb={1}>
              Image
            </Typography>

            <Button
              sx={{ width: "150px", mb: 1 }}
              variant="contained"
              component="label"
            >
              <input
                hidden
                type="file"
                accept="image/*"
                id="select-image"
                onChange={handleSelectImage}
              />
              <Typography>Select Image</Typography>
            </Button>
            <Typography variant="subtitle2" mb={2.5}>
              Please select an a png,jpeg,jpg,gif file only.
            </Typography>
          </Box>

          {(selectedImage || netWorkImage) && (
            <Box
              sx={{
                height: "100px",
                width: "200px",
                objectFit: "contain",
              }}
            >
              <img
                height="100px"
                width="auto"
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : netWorkImage
                }
              />
            </Box>
          )}
        </Box>

        <TextFieldView
          label="Name"
          onchange={(v) => {
            setName(v);
          }}
          sx={{ width: "90%", mb: 2.5 }}
          value={name}
          type="text"
          variant="outlined"
          required={true}
        />
        {/* <TextFieldChip
            required={true}
            sx={{ width: "90%", mb: 2.5 }}
            label="Slug"
            chips={chips}
            handleChange={handleChange}
            helperText="Please enter Slug in English. Hit enter for add."
          /> */}

        <Box
          sx={{
            px: 2.5,
            width: "85%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            "& .ql-container": {
              height: "auto",
              resize: "both",
            },
          }}
        >
          {/* <Typography sx={{ textAlign: "start" }}>Description</Typography>
            <ReactQuill
              style={{ width: "100%" }}
              theme="snow"
              value={value}
              onChange={(e) => {
                setValue(e);
              }}
              formats={formats}
            /> */}
        </Box>

        <Button
          sx={{ width: "150px", mb: 1, mt: 8 }}
          variant="contained"
          onClick={handleSubmit}
        >
          <Typography>Submit</Typography>
        </Button>
      </Paper>
    </Box>
  );
}

export default CategoryAdd;

// let fareValue = 1;
// function AddFare({ categoryValue }) {
//   const mainCategory = ["Select main catergory"];
//   const [mainCat, setMainCat] = React.useState("");
//   const [numberOfFare, setNumberOfFare] = React.useState([{ id: 1 }]);

//   const handleNumberOfFare = () => {
//     const t = numberOfFare;
//     fareValue = 1 + fareValue;
//     t.push({ id: fareValue });
//     setNumberOfFare([...t]);
//   };

//   const handleDelete = (i) => {
//     const t = numberOfFare.filter((v) => v.id != i);

//     setNumberOfFare(t);
//   };

//   const handleChange = (event) => {
//     setMainCat(event.target.value);
//   };
//   return (
//     <Box
//       display="flex"
//       justifyContent="start"
//       alignItems="start"
//       flexDirection="column"
//       component="form"
//       sx={{
//         mt: 2.5,
//         "& .MuiTextField-root": { mt: 1.5, width: "25ch" },
//       }}
//       noValidate
//       autoComplete="off"
//     >
//       <Typography variant="h6">Select Main Category</Typography>
//       <TextField
//         select
//         placeholder="Select main category"
//         label="Select category"
//         value={mainCat}
//         variant="outlined"
//         onChange={handleChange}
//       >
//         {mainCategory.map((value) => {
//           return (
//             <MenuItem key={value} value={value}>
//               {value}
//             </MenuItem>
//           );
//         })}
//       </TextField>
//       {/* {numberOfFare} */}
//       {categoryValue == 2
//         ? numberOfFare.map((v) => {
//             return (
//               <AddFareMultiView
//                 key={`${v.id}`}
//                 handleDelete={handleDelete}
//                 index={v.id}
//               />
//             );
//           })
//         : null}

//       <Button
//         onClick={handleNumberOfFare}
//         size="small"
//         sx={{
//           flex: 1,
//           height: "40px",
//           mt: 1.5,
//           mb: 2.5,
//         }}
//         variant="contained"
//       >
//         Add Fare
//       </Button>
//     </Box>
//   );
// }

// function AddFareMultiView({ handleDelete, index }) {
//   const [city, setCity] = useState("");
//   const cities = ["Ahmedabad", "Rajkot", "Gandhinagar", "Vadodara"];

//   const handleCity = (e) => {
//     setCity(e.target.value);
//   };
//   return (
//     <Box
//       display="flex"
//       flexWrap="wrap"
//       width="100%"
//       justifyContent="space-between"
//       alignItems="center"
//     >
//       <Box sx={{}}>
//         <TextField
//           required
//           label="City"
//           placeholder="Select city"
//           onChange={handleCity}
//           value={city}
//           variant="outlined"
//           sx={{ width:"320px" }}
//           select
//           size="small"
//         >
//           {cities.map((v) => {
//             return (
//               <MenuItem key={v} value={v}>
//                 {v}
//               </MenuItem>
//             );
//           })}
//         </TextField>
//       </Box>
//       <Box mx={2}/>
//       <TextFieldView
//         label="Price/Hour"
//         // onchange={(v) => {
//         //   setName(v);
//         // }}
//         sx={{ flex:1}}
//         // value={name}
//         type="number"
//         variant="outlined"
//         required={true}
//       />
//       <Box mx={2}/>
//       <TextFieldView
//         label="Minimum/Hour"
//         // onchange={(v) => {
//         //   setName(v);
//         // }}

//         sx={{ flex:1}}
//         // value={name}
//         type="number"
//         variant="outlined"
//         required={true}
//       />
//       <Box mx={2}/>
//       <Button
//         size="small"
//         onClick={() => handleDelete(index)}
//         sx={{
//           color: "red",
//           borderColor: "red",
//           px: 0.3,
//           py: 0.3,
//           height: "40px",
//           width:"120px",
//           mt: 1,

//         }}
//         variant="outlined"
//       >
//         Delete
//       </Button>
//     </Box>
//   );
// }

//  {/* SELECT CATEGORY */}
//  <Typography variant="subtitle1">
//  {" "}
//  DO HAVE ANY CHILD CATEGORY OF THIS CATEGORY?*(DO HAVE ANY CHILD
//  CATEGORY OF THIS CATEGORY?)
// </Typography>
// <Typography variant="subtitle2">
//  Please select an a one status by default is inactive.
// </Typography>
// <FormControl sx={{ mb: 2.5 }}>
//  <RadioGroup
//    value={isSelectCategory}
//    row
//    aria-labelledby="demo-row-radio-buttons-group-label"
//    name="row-radio-buttons-group"
//    onChange={(e) => {
//      setIsSelectCategory(e.target.value);
//    }}
//  >
//    <FormControlLabel value={1} control={<Radio />} label="Yes" />
//    <FormControlLabel value={2} control={<Radio />} label="No" />
//  </RadioGroup>
// </FormControl>

// {/* SHOW CATEGORY */}
// <Typography variant="h6" mb={1.5}>
//  category data
// </Typography>
// <Divider sx={{ mb: 1 }} />
// {isSelectCategory == 2 ? (
//  <Box>
//    <TextFieldView
//      label="Price/Hour"
//      onchange={(v) => {
//        setPrHour(v);
//      }}
//      sx={{ width: "100%", mb: 2.5 }}
//      value={prHour}
//      type="number"
//      variant="outlined"
//      required={true}
//    />
//    <TextFieldView
//      label="Minimum/Hour"
//      onchange={(v) => {
//        setMinHour(v);
//      }}
//      sx={{ width: "100%", mb: 0.5 }}
//      value={minHour}
//      type="number"
//      variant="outlined"
//      required={true}
//    />
//    <Typography variant="subtitle2" sx={{ mb: 2.5 }}>
//      Please enter multiplier
//    </Typography>
//  </Box>
// ) : null}

// <AddFare categoryValue={isSelectCategory} />
// <Typography variant="h6">Status</Typography>
// <FormControl>
//  <RadioGroup
//   value={status}
//    onChange={(e) => setStatus(e.target.value)}
//    aria-labelledby="demo-row-radio-buttons-group-label"
//    name="row-radio-buttons-group"
//  >
//    <FormControlLabel value={1} control={<Radio />} label="Yes" />
//    <FormControlLabel value={2} control={<Radio />} label="No" />
//  </RadioGroup>
// </FormControl>
// <Typography variant="subtitle2" sx={{ mb: 2.5 }}>
//  Please select an a one status by default is inactive.
// </Typography>

// <Box display="flex" justifyContent="start">
//  <Button
//    size="small"
//    sx={{
//      height: "40px",
//      mt: 1.5,
//      mb: 2.5,
//      mr: 1,
//      width: "100px",
//    }}
//    variant="contained"
//  >
//    Submit
//  </Button>
//  <Button
//    size="small"
//    sx={{
//      width: "100px",
//      height: "40px",
//      mt: 1.5,
//      mb: 2.5,
//    }}
//    variant="contained"
//  >
//    Cancel
//  </Button>
// </Box>
