import { Box, Typography } from "@mui/material";
import React from "react";
import { dateFormateHHMMA, dateFormateMMDDYYY } from "../../Service/DateSevice";

function DateMDYHMA({
  date,
  isUtc = true,
  sxBox = {},
  fontSx = {
    justifyContent: "center",
  },
  fontSize = "11.6px",
  onlyDate,
  onlyTime,
}) {
  return (
    <Box
      sx={{
        ...{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        },
        ...sxBox,
      }}
    >
      <Typography sx={{ ...fontSx, fontSize, textAlign: "center" }}>
        {!onlyTime ? dateFormateMMDDYYY(date) : null}
      </Typography>
      <Typography sx={{ ...fontSx, fontSize, textAlign: "center" }}>
        {!onlyDate ? dateFormateHHMMA(date) : null}
      </Typography>
      {/* <Typography sx={{ ...fontSx }}>{dateFormateHHMMA(date,isUtc)}</Typography> */}
    </Box>
  );
}

export default DateMDYHMA;
