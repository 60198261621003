import { Box, Paper, Typography, InputLabel, Button } from "@mui/material";
import React from "react";
import LoaderButtonView from "../../../Component/Button/LoaderButton";
import { API_VARIFY_OTP } from "../../../Apis/auth_api";
import { useLocation, Navigate, Link } from "react-router-dom";
import { ToastShow } from "../../../Component/Toast/Toast";
import { UserContext } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { GET_MY_ROLES } from "../../../Apis/users_api";
import Styles from "../.././../Modules/Banner.module.css";
import OtpInput from "react-otp-input";
import { API_SEND_OTP } from "../../../Apis/auth_api";
import { useEffect } from "react";
import { useState } from "react";
import TimerOtp from "./TimerOtp";

function VarifyOtp() {
  const [otp, setOtp] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { state } = useLocation();
  const auth = UserContext();
  const navigator = useNavigate();

  const [timer, setTimer] = useState(120); // 120 seconds = 2 minutes (For OTP)
  const [isResending, setIsResending] = useState(true);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleOTP = async () => {
    if (!otp) {
      ToastShow("Please enter OTP");
    } else if (otp.length < 6) {
      ToastShow("All fields must be filled");
    } else {
      setIsLoading(true);
      const res = await API_VARIFY_OTP({ otp_id: state.otp_id, otp });
      setIsLoading(false);
      if (res && res.s == 1) {
        if (res.r) {
          auth.login(res, 1);
          const data = await GET_MY_ROLES();
          if (data && data.s) {
            navigator(
              state.path
                ? state.path
                : "/" + data.r.modules[0].name.toLowerCase().replace(" ", "-"),
              {
                // state: { otp_id: res.r.otp_id },
              }
            );
          } else {
            localStorage.removeItem("mobileNumber");
            navigator(state.path ? state.path : "/dashboard", {
              // state: { otp_id: res.r.otp_id },
            });
          }
          ToastShow("Welcome");
        } else {
          ToastShow("You are not autherised for the admin panel.");
        }
      } else {
        if (res) {
          ToastShow(res.m);
        } else {
          ToastShow("Something went wrong. Please try again.");
        }
      }
    }
  };

  /*NEW code*/
  const handleResendOTP = async () => {
    const mobile = localStorage.getItem("mobileNumber");
    // console.log(mobile);
    if (mobile.length === 10) {
      setIsLoading(true);
      setTimer(120);
      setIsResending(true);

      const res = await API_SEND_OTP({ mobile: "+91" + mobile });
      setIsLoading(false);
      if (res && res.s == 1) {
        navigator("/otp-verify", {
          state: { otp_id: res.r.otp_id, path: state ? state.path : null },
        });
      } else {
        ToastShow("Something went wrong. Please try again");
      }
    } else {
      ToastShow("Please enter valid mobile number");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleOTP();
    }
  };

  if (!state) {
    return <Navigate to={"/"} replace={true} />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100vw",
      }}
    >
      <Box className={Styles.banner}></Box>

      <Paper
        elevation={0}
        sx={{
          width: "40vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "1.5rem",
          alignItems: "center",
          borderColor: "background.paper",
        }}
      >
        <Box
          sx={{
            width: "548px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: "700", color: "#FAAF40", fontSize: "40px" }}
          >
            OTP
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "18px", letterSpacing: "0.02em", color: "#787878" }}
          >
            Please enter the OTP you've just received from POD!
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "548px",
            gap: ".4rem",
          }}
        >
          <InputLabel sx={{ fontSize: "1.1rem", color: "#AAAAAA" }}>
            Enter the OTP
          </InputLabel>

          <OtpInput
            numInputs={6}
            autoFocus
            onChange={handleChange}
            value={otp}
            inputType="number"
            renderSeparator={<span>-</span>}
            containerStyle={{
              display: "flex",
              gap: ".6rem",
              color: "#787878",
            }}
            inputStyle={{
              backgroundColor: "#F5F5F5",
              border: "none",
              padding: "1rem",
              fontSize: "1.5rem",
              border: "none",
              color: "#F8AF41",
              height: "3.7rem",
              width: "3.3rem",
              outline: "none",
              borderRadius: ".3rem",
            }}
            renderInput={(props) => (
              <input {...props} onKeyDown={handleKeyDown} />
            )}
          />
        </Box>

        <LoaderButtonView
          loading={isLoading}
          lable={"Log in"}
          sx={{ width: "548px", height: "50px" }}
          fontWeight="bold"
          fontSize="20px"
          onClick={handleOTP}
        />

        <Box
          sx={{
            width: "25rem",
            display: "flex",
            // gap: "1.5rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "6rem",
              display: "flex",
              justifyContent: timer >= 0 ? "flex-end" : "center",
            }}
          >
            <TimerOtp
              timer={timer}
              setTimer={setTimer}
              setIsResending={setIsResending}
            />
          </Box>

          <Button
            onClick={handleResendOTP}
            disabled={isResending}
            sx={{ textTransform: "none", color: "orange", fontWeight: "600" }}
          >
            Resend OTP
          </Button>

          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography color="#787878" fontWeight="600">
              Change Phone No.
            </Typography>
          </Link>
        </Box>
      </Paper>
    </Box>
  );
}

export default VarifyOtp;
