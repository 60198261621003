import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import tokens, { API_URL } from "../../../Apis/config";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { addDays, subDays, lastDayOfMonth, format } from "date-fns";
import { DateRangePicker, CustomProvider } from "rsuite";
import { useState } from "react";

function OrderExport() {
  const type = ["All", "Open", "Completed", "Canceled"];
  const [selectedType, setSelectedType] = React.useState(["All"]);
  const [typeId, setTypeId] = React.useState("0");

  const ptype = ["All", "Unpaid", "Paid", "Refund", "Refunded"];
  const [PaymentType, setPaymentType] = React.useState(["All"]);
  const [ptypeId, setPTypeId] = React.useState("0");

  const theme = useTheme();
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [dateRange, setDateRange] = useState({});

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
    } else {
      setDateRange(v);
    }
  };

  const geData = async () => {
    let query = "";

    if (typeId) {
      query += "&statusType=" + typeId;
    }
    if (ptypeId) {
      query += "&paymentType=" + ptypeId;
    }

    if (Object.keys(dateRange).length > 0) {
      query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
    }
    window.open(
      API_URL.baseUrl +
        API_URL.reportOrder +
        "?apikey=" +
        tokens.apikey +
        "&token=" +
        tokens.token +
        query
    );
  };

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
    setTypeId(getValue(value));
  };

  const handleSelectPType = async (e) => {
    const {
      target: { value },
    } = e;
    setPaymentType([value]);
    setPTypeId(getValuePayment(value));
  };

  const getValue = (v) => {
    let r = "0";

    if (v == "All") {
      r = "0";
    } else if (v == "Open") {
      r = "1";
    } else if (v == "Completed") {
      r = "2";
    } else if (v == "Canceled") {
      r = "3";
    } else {
      r = "-1";
    }

    return r;
  };

  const getValuePayment = (v) => {
    let r = 0;

    if (v == "All") {
      r = "0";
    } else if (v == "Unpaid") {
      r = "1";
    } else if (v == "Paid") {
      r = "2";
    } else if (v == "Refund") {
      r = "3";
    } else if (v == "Refunded") {
      r = "4";
    } else {
      r = "-1";
    }

    return r;
  };

  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Box display="flex" alignItems="center" gap="10px">
        <MultiSelection
          size="small"
          selected={selectedType}
          handleChange={handleSelectType}
          names={type}
          lable={"Order Type"}
          multiple={false}
        />
        <MultiSelection
          size="small"
          selected={PaymentType}
          handleChange={handleSelectPType}
          names={ptype}
          lable={"Payment Type"}
          multiple={false}
        />

        <CustomProvider theme={theme.palette.mode}>
          <DateRangePicker
            placement="bottomEnd"
            size="lg"
            onChange={(v) => handleSelectDate(v ?? {})}
            ranges={[
              {
                label: "Yesterday",
                value: [addDays(new Date(), -1), addDays(new Date(), -1)],
              },
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Tomorrow",
                value: [addDays(new Date(), 1), addDays(new Date(), 1)],
              },
              {
                label: "Last 7 days",
                value: [subDays(new Date(), 6), new Date()],
              },
              {
                label: "This week",
                value: [
                  subDays(
                    new Date(
                      `${firstdayW.getFullYear()}-${
                        firstdayW.getMonth() + 1
                      }-${firstdayW.getDate()}`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${lastdayW.getFullYear()}-${
                          lastdayW.getMonth() + 1
                        }-${lastdayW.getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This month",
                value: [
                  subDays(
                    new Date(
                      `${today.getFullYear()}-${today.getMonth() + 1}-01`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${today.getFullYear()}-${
                          today.getMonth() + 1
                        }-${lastDayOfMonth(today).getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This year",
                value: [
                  subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                  addDays(
                    new Date(new Date(`${today.getFullYear()}-12-31`)),
                    0
                  ),
                ],
              },
            ]}
          />
        </CustomProvider>
      </Box>
      <Button sx={{ ml: 1, mt: 2 }} variant="outlined" onClick={() => geData()}>
        Export
      </Button>
    </Box>
  );
}

export default OrderExport;
