import { API_URL, POSTAPI } from "./config";

export const CHAT_NOTIFY = async (data) => {
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("body", data.body);
  formData.append("to_user_id", data.to_user_id);
  formData.append("order_id", data.order_id);
  formData.append("to_app", data.to_app);

  const res = await POSTAPI(
     API_URL.chatNotify,
    formData
  );

  return res;
};
