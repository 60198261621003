import { useState } from "react";
import { Modal, Stack, Typography, Dialog } from "@mui/material";
import { GrClose } from "react-icons/gr";
import ButtonView from "../../../../../Component/Button/ButtonView";
import TextFieldSmall from "../../../../../Component/TextFields/TextFieldSmall";

const UpdateModal = ({ open, handleClose, updateValue, id }) => {
  const [value, setValue] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(2px)",
        bgcolor: "rgba(105, 55, 0, 0.230)",
      }}
    >
      <Stack gap="1rem" m={1.7}>
        <Stack
          width="100%"
          direction="row"
          bgcolor="#F6F6F6"
          borderRadius="6px"
          justifyContent="space-between"
          alignItems="center"
          py="0.4rem"
          px="0.5rem"
        >
          <Typography
            fontWeight="500"
            fontSize="15px"
            sx={{ userSelect: "none" }}
          >
            Update
          </Typography>
          <GrClose
            style={{ cursor: "pointer", fontSize: "0.9rem" }}
            onClick={handleClose}
          />
        </Stack>

        <Stack gap="1.2rem">
          <TextFieldSmall
            width="300px"
            type="number"
            label="Provider Cut-off"
            placeholder="Enter value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <TextFieldSmall
            width="300px"
            type="number"
            label="Minimum Amount"
            placeholder="Enter minimum amount"
            value={min}
            onChange={(e) => setMin(e.target.value)}
          />
          <TextFieldSmall
            width="300px"
            type="number"
            label="Maximum Amount"
            placeholder="Enter maximum amount"
            value={max}
            onChange={(e) => setMax(e.target.value)}
          />
        </Stack>

        <ButtonView
          lable="Submit"
          fontSize="14px"
          hoverBg="#ffb952"
          sx={{ width: "100%" }}
          onClick={() => {
            updateValue(value, min, max, id);
            handleClose();
          }}
        />
      </Stack>
    </Dialog>
  );
};

export default UpdateModal;
