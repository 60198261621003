import React from "react";
import CategoryAdd from "./CategoryAdd";
import { useParams } from "react-router-dom";

function CategoryEdit() {
  const { id } = useParams();
  return <CategoryAdd cId={id} />;
}

export default CategoryEdit;
