import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import { ADD_UPDATE_NEW_ON_SPOT_CITY } from "../../Apis/new_on_spot_api";
import MultiSelection from "../Multiselection/MultiSelection";
import { useEffect } from "react";

function AddNewOnSpotCity({
  open,
  handleOnClose,
  on_spot_id,
  row,
  cities,
  citiesW,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [cityId, setSelectedId] = React.useState([]);

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([...value]);
    const c = citiesW.filter((v) => value.includes(v.name));
    const i = [];

    for (let d of c) {
      i.push(d.id);
    }
    setSelectedId(i);
  };

  const handleOnSubmit = async () => {
    if (!cityId.length) {
      ToastShow("Please select the city");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_NEW_ON_SPOT_CITY({
        city_ids: cityId.map(v=>v).join(","),
        on_spot_id: on_spot_id,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {

    const name = row.map((v)=>v.name);
    const cId = row.map((v)=>v.city_id);

    setSelectedId(cId);
    setSelectedCities(name);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!row?.id ? "Add CITY" : "Update CITY"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MultiSelection
            multiple={true}
            names={cities}
            handleChange={handleSelectCity}
            selected={selectedCities}
            lable="Cities"
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddNewOnSpotCity;
