import { API_URL, GETAPI, POSTAPI } from "./config";

export const GETFAQDATA = async (id, typeId, roleId) => {
  const res = await GETAPI(
    API_URL.getFaq,
    "&city_id=" + id + "&type=" + typeId + "&role=" + roleId
  );
  return res;
};

export const GET_FAQ_DETAILS = async (id) => {
  const res = await GETAPI(API_URL.getFaqDetails, "&id=" + id);
  return res;
};

export const ADD_UPDATE_FAQ = async (data) => {
  const formData = new FormData();
  formData.append("question", data.question);
  formData.append("answer", data.answer);

  if (data.role != null) {
    formData.append("role", data.role);
  }

  if (data.city_id) {
    formData.append("city_id", data.city_id);
  }

  if (data.type != null) {
    formData.append("type", data.type);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.editFaq : API_URL.addFaq,
    formData
  );

  return res;
};

export const DELETEFAQ = async (id) => {
  const res = await GETAPI(API_URL.deleteFaq, "&id=" + id);
  return res;
};
