import { useState, useEffect } from "react";
import "./CustomSwitch.css";

function SwitchRadioButton({ name, value, onChange, id }) {
  const [s, setS] = useState(value ? true : false);

  const toggleSwitch = () => {
    setS(!s);
    onChange(id, s);
  };

  return (
    <div
      className={`custom-switch ${s ? "checked" : ""}`}
      onClick={toggleSwitch}
    >
      <div className="custom-switch-slider"></div>
    </div>
  );
}

export default SwitchRadioButton;
