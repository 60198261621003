import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_WEB_BRAND = async (data) => {
  const formData = new FormData();


  if (data?.logo) {
    formData.append("logo", data.logo, "logo.png");
  }

  if (data?.row) {
    formData.append("id", data.row.id);
  }

  formData.append("name", data?.name);

  const res = await POSTAPI(
    data?.row?.id ? API_URL.webUpdateBrand : API_URL.webAddBrand,
    formData
  );
  return res;
};

export const GET_WEB_BRAND = async () => {
  const res = await GETAPI(API_URL.webGetBrand);
  return res;
};

export const DELETE_WEB_BRAND = async (id) => {
  const res = await GETAPI(API_URL.webDeleteBrand, "&id=" + id);
  return res;
};
