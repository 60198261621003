import { Avatar, Button, Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Apis/config";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { confirmOrder, getForAdminNew } from "../../../Apis/order_api";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import SplitButton from "../../../Component/Button/SplitButton";
import { ToastShow } from "../../../Component/Toast/Toast";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import ButtonForGrid from "../../../Component/Button/ButtonForGrid";
import AddCoupon from "./AddCoupon";
import ShowCouponDetails from "./ShowCouponDetails";

function Lead() {
  const [openAddCoupon, setOpenAddCoupon] = useState(false);
  const [openCouponDetails, setOpenCouponDetails] = useState(false);

  const [couponData, setCouponData] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 70,
      minWidth: 70,
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "photo",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 120,
      minWidth: 110,
      renderCell: (params) => (
        <Avatar
          sx={{ width: "1.9rem", height: "1.9rem", objectFit: "fill" }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },

    {
      field: "someone_name",
      headerName: "Name/Mo.",
      width: 160,
      minWidth: 160,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({
        row: {
          someone_name,
          someone_mobile,
          is_for_someone,
          first_name,
          last_name,
          mobile,
        },
      }) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F6F6F6"
          >
            <Typography fontSize="12px" fontWeight="500" textAlign="center">
              {is_for_someone
                ? someone_name
                : first_name || last_name
                ? `${first_name} ${last_name}`
                : " "}
            </Typography>
            <Typography mx=".rem" fontSize="11px">
              {is_for_someone ? someone_mobile ?? " " : mobile ?? "-"}
            </Typography>
          </Box>
        );
      },
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterRenderedCellData: true,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      headerAlign: "center",
      align: "center",
      width: 160,
      minWidth: 160,
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="12px">{params.row.category_name}</Typography>
      ),
    },
    {
      field: "sub_category_name",
      headerName: "Subcategory Name",
      headerAlign: "center",
      align: "center",
      width: 180,
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="12px">{params.row.sub_category_name}</Typography>
      ),
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      width: 130,
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="12px">₹{params.value}</Typography>;
      },
    },
    {
      field: "created_at",
      headerName: "POD Date",
      width: 130,
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },

    {
      field: "city",
      headerName: "City",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography fontSize="12px">{params.value}</Typography>
      ),
    },

    {
      field: "date_time",
      headerName: "Sht-Date",
      width: 130,
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} isUtc={false} />;
      },
    },

    {
      field: "address",
      headerName: "Address",
      width: 200,
      minWidth: 200,
      flex: 2,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Typography fontSize="12px">{params.value}</Typography>
      ),
    },
    {
      field: "coupon",
      headerName: "Coupon",
      width: 170,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <ButtonForGrid
          label="Add Coupon"
          onClick={() => {
            setSelectedId(params.id);
            setOpenAddCoupon(true);
          }}
        />
      ),
    },
    {
      field: "assigned_coupons",
      headerName: "Assigned Coupons",
      width: 170,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <ButtonForGrid
          label="Coupon Details"
          onClick={() => {
            if (params.row.coupon_assign.length) {
              setCouponData(params.row.coupon_assign);
            }
            setOpenCouponDetails(true);
          }}
        />
      ),
    },
    {
      field: "orderaction",
      headerName: "Order Action",
      width: 170,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <ButtonForGrid
          label="Convert to Order"
          onClick={() => {
            setSelectedId(params.id);
            setOpenConfirm(true);
          }}
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 90,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          isView={1}
          dataId={params.id}
          isEdit={1}
          onEdit={onEdit}
          onView={viewData}
          onDelete={() => {}}
          deleteText={""}
          isDelete={null}
        />
      ),
    },
  ];

  const handleOnClose = () => {
    setCouponData([]);
    setOpenCouponDetails(false);
  };

  const viewData = (v) => {
    const route = getRoute();
    var t = rows.filter((e) => e.id === v);
    window.open(`/order/${route}/` + t[0].id);
    // navigator(`/order/${route}/` + t[0].id);
  };

  const onEdit = (v) => {
    window.open(`/order/all/${v}/edit`);
  };

  function getRoute() {
    return "all";
  }

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);

  const getData = async ({ c = 0 }) => {
    setIsLoading(true);

    const data = await getForAdminNew(c ? 0 : rows.length);

    if (c) {
      setIsCall(true);
      setRows([...(data ? data.r ?? [] : [])]);
    } else {
      setRows([...rows, ...(data ? data.r ?? [] : [])]);
    }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData({ c: 1 });
  }, []);

  const callBack = async (l) => {
    if (l >= rows.length && isCall) {
      await getData({ c: 0 });
    }
  };

  const handleConfirmClose = async (v) => {
    if (v) {
      const d = await confirmOrder(selectedId, 0, 1);
      if (d && d.s) {
        ToastShow(d?.m ?? "Success");
        getData({ c: 0 });
      } else {
        ToastShow(d?.m ?? "Opps! something went wrong.");
      }
    }
    setOpenConfirm(false);
    setSelectedId(0);
  };

  console.log("DATA", rows);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "3px 3px 7px 0px #00000040",
        paddingBottom: ".2rem",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
        borderTopLeftRadius: "7px",
        borderTopRightRadius: "7px",
      }}
    >
      <DataGrideView
        rows={rows ?? []}
        columns={columns}
        callBack={callBack}
        isLoading={isLoading}
        colHeight="5rem"
        sx={{ height: "86.5vh" }}
      />

      {openConfirm && (
        <ConfirmationDailog
          open={openConfirm}
          handleOnClose={handleConfirmClose}
          title="Convert to order!"
          content="Are you sure, You want to convert this into order?"
        />
      )}

      {openAddCoupon && (
        <AddCoupon
          open={openAddCoupon}
          orderId={selectedId}
          getData={getData}
          handleClose={() => setOpenAddCoupon(false)}
        />
      )}

      {openCouponDetails ? (
        <ShowCouponDetails
          open={openCouponDetails}
          handleClose={handleOnClose}
          data={couponData ?? null}
        />
      ) : null}
    </Box>
  );
}

export default Lead;
