import { Image, Send } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
  onSnapshot,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { CHAT_NOTIFY } from "../../Apis/chat_notify_api";
import { db, storage } from "../../Service/FirebaseInit";
import ChatSenderView from "./ChatSenderView";
import Compressor from "compressorjs";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ToastShow } from "../Toast/Toast";
import SendMessage from "../../Pages/Screens/Order/New/OrderChat/SendMessage";

function OrderChatPopAdmin({ id, open, anchorEl, handleClose, oId, uId, pId }) {
  // console.log("pId",pId)
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const divRef = useRef(null);

  //   useEffect(() => {
  //     // getChatData();
  //   }, []);

  useEffect(() => {
    onSnapshot(
      query(
        collection(
          db,
          "order_chat/" + oId,
          pId ? "conversation_" + pId + "_admin" : "conversation_admin"
        ),
        orderBy("created_at", "desc")
      ),
      (doc) => {
        // console.log("call");
        // console.log(doc.docs.length);
        const t = doc?.docs?.reverse();
        setData([...t]);
      }
    );
  }, []);

  useEffect(() => {
    if (data.length) {
      document.getElementById("chat").scrollTo({
        behavior: "smooth",
        top: document.getElementById("chat").scrollHeight + 105,
      });
    }
  }, [data]);

  const getChatData = async () => {
    setIsLoading(true);
    const c = doc(db, "order_chat", oId);
    const data = await getDoc(c);
    if (data.exists) {
      const value = data.data();
      // console.log("Special id - ", value.sp_id)
      // console.log("data -> chat", data.data());
      if (value) {
        const cc = collection(
          db,
          "order_chat/" + oId,
          pId ? "conversation_" + pId + "_admin" : "conversation_admin"
        );

        const d = await getDocs(query(cc, orderBy("created_at", "desc")));
        // console.log("data -> chat", d.docs[0].data());
        setData(d?.docs ?? []);
      }
    }
    setIsLoading(false);
  };

  const sendMessage = async (msg, type = 1) => {
    // await setDoc(doc(db, "order_chat/"+ oId), {
    //     ...(!data.length && { created_at: Timestamp.now() }),
    //     lastMessage:msg,
    //     reference_id:oId,
    //     sp_id:0,
    //     type:1,
    //     unread_count:0,
    //     updated_at:Timestamp.now(),
    //     updated_by:0,
    //     user_id:uId,
    //   },);
    await addDoc(
      collection(
        db,
        "order_chat/" + oId,
        pId ? "conversation_" + pId + "_admin" : "conversation_admin"
      ),
      {
        message: msg,
        additional_meta: "",
        created_at: Timestamp.now(),
        order_id: oId,
        role: 0,
        send_by: 0,
        sp_id: 0,

        user_id: uId,
        type: type,
      }
    );
    setMsg("");
    //  console.log(uId)
    await CHAT_NOTIFY({
      title: "Message From Admin On Order Id : " + oId,
      body: type == 1 ? msg : "Media",
      to_user_id: pId != 0 && pId != null ? pId : uId,
      order_id: oId,
      to_app: pId != 0 && pId != null ? "2" : "1",
    });

    if (type == 2) {
      ToastShow("Image uploaded");
    }
  };

  const uploadFile = async (file) => {
    const storageRef = ref(storage, `/chat/${Date.now() + file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const percent = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // );
        // update progress
        // setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          sendMessage(url, 2);
        });
      }
    );
  };

  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(null);

  const handleAttachmentClick = () => {
    fileInputRef.current.click(); // Trigger click event of the hidden file input
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    uploadFile(file);
    setSelectedFile(file);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" sx={{ fontSize: "20px" }}>
            Chat
          </Typography>
          {/* <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      ToastShow("Image uploading");
                      uploadFile(compressedResult);
                    },
                  });
                }
              }}
            />
            <Image />
          </IconButton> */}
        </Box>
        <Divider />
        <Box
          ref={divRef}
          id="chat"
          sx={{
            width: "300px",
            height: "500px",
            display: "flex",
            justifyContent: "start",
            overflowY: "auto",
            flexDirection: "column",
            mt: 2,
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ margin: "auto" }} />
          ) : data.length ? (
            data.map((v) => {
              // console.log(v.data());
              // console.log(v.id);
              return (
                <ChatSenderView
                  message={v.data().message}
                  userId={v.data().send_by}
                  key={v.id}
                  type={v.data().type}
                  createdAt={v.data().created_at}
                  role={v.data().role}
                />
              );
            })
          ) : (
            <Typography>Chat has not been started yet.</Typography>
          )}
        </Box>

        <SendMessage
          handleAttachmentClick={handleAttachmentClick}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          handleSend={() => {
            if (msg) {
              sendMessage(msg);
            } else ToastShow("Please enter the message.");
          }}
          value={msg}
          setValue={setMsg}
        />
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "end",
          }}
        >
          <TextField
            placeholder="Write your message..."
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            fullWidth
            multiline
            maxRows={4}
            size="small"
            sx={{ borderRadius: "25px" }}
            onKeyUp={(e) => {
              if (e.key == "Enter" && !e.shiftKey) {
                sendMessage(msg);
              }
            }}
          />
          {msg.length ? (
            <IconButton onClick={() => sendMessage(msg)}>
              <Send />
            </IconButton>
          ) : null}
        </Box> */}
      </Box>
    </Popover>
  );
}

export default OrderChatPopAdmin;
