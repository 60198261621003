import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { GET_APP_ISSUE } from "../../../Apis/appIssue";
import { API_URL } from "../../../Apis/config";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";

// const forWhome = ["Customer", "Podian"];
const ticketType = ["Customer", "Provider", "Web"];

function ApplicationIssue() {
  const [ticketTypeS, setTicketTypeS] = useState(["Customer"]);

  const handleTicketType = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setTicketTypeS([value]);
    getData(0, getTicketTypeS(value));
  };

  const getTicketTypeS = (v) => {
    if (v == "Customer") {
      return 1;
    } else if (v == "Provider") {
      return 2;
    } else {
      return 3;
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "photo_thumb",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Avatar
          sx={{ width: "32px", objectFit: "fill", my: "1rem" }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "uname",
      headerName: "User Name",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>{params.value ?? "-"}</Typography>;
      },
    },
    {
      field: "attachment",
      headerName: "Attachment",
      filterable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <Avatar
            onClick={() =>
              window.open(
                API_URL.baseUrl + API_URL.attachmentIssueFile + params.value
              )
            }
            sx={{ cursor: "pointer", width: 45, height: 45 }}
            src={API_URL.baseUrl + API_URL.attachmentIssueFile + params.value}
          />
        ) : null,
    },
    {
      field: "message",
      headerName: "Message",
      filterable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography my="0.5rem" fontSize="12.4px">
            {params.value ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date & Time",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  const getData = async (c, ticketT) => {
    if (!c) {
      setIsCall(true);
    }

    setIsLoading(true);

    const data = await GET_APP_ISSUE(c, ticketT);

    if (!c) {
      if (data.r) {
        setRows([...(data.r ?? [])]);
      } else {
        setRows([]);
      }
    } else {
      if (data.r) {
        setRows([...rows, ...(data.r ?? [])]);
      }
    }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData(0, getTicketTypeS(ticketTypeS));
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length, getTicketTypeS(ticketTypeS));
    }
  };

  return (
    <>
      <Box display="flex" alignItems={"flex-end"} flexDirection="column">
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              gap: 2,
              mb: 2,
            }}
          >
            <MultiSelection
              selected={ticketTypeS}
              handleChange={handleTicketType}
              names={ticketType}
              lable={"App"}
              multiple={false}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            boxShadow: "3px 3px 7px 0px #00000040",
          }}
        >
          <DataGrideView
            rows={rows}
            columns={columns}
            callBack={callBack}
            isLoading={isLoading}
            sx={{
              height: "76vh",
            }}
            colHeight={null}
          />
        </Box>
      </Box>
    </>
  );
}

export default ApplicationIssue;
