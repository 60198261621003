import { Box, Typography } from "@mui/material";

const CutOffLabel = ({ value, label }) => {
  return (
    <Box sx={{ bgcolor: "#eafbff", display: "flex", gap: "0.5rem" }}>
      <Typography fontSize="12px">{label}</Typography>
      <Typography fontSize="12px">{value ?? 0}</Typography>
    </Box>
  );
};

export default CutOffLabel;
