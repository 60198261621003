import { Button } from "@mui/material";
import React from "react";

const SecondaryButton = ({ label, onClick, width, height }) => {
  return (
    <Button
      variant="contained"
      disableElevation
      sx={{
        textAlign: "center",
        textTransform: "none",
        backgroundColor: "rgb(255,182,193)",
        color: "red",
        "&:hover": {
          backgroundColor: "crimson",
          color: "white",
        },
        height: height,
        borderRadius: "6px",
      }}
      width={width}
      onClick={() => onClick()}
    >
      {label}
    </Button>
  );
};

export default SecondaryButton;
