import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { APPROVE_REJECT, GETPROVIDERSDETAILS } from "../../../Apis/service_api";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../Apis/config";
import { ToastShow } from "../../../Component/Toast/Toast";
import {
  GET_BALANCE,
  GET_USER_DETAILS,
  GET_USER_ORDER,
  GET_USER_WALLET,
  GET_USER_WALLET_REF,
} from "../../../Apis/users_api";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { cancelOrder } from "../../../Apis/order_api";
import SplitButton from "../../../Component/Button/SplitButton";
import { getColor, getStatus } from "../../../Service/OrderService";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import { useNavigate } from "react-router-dom";
import RestrictUser from "../../../Component/Dailog/RestrictUser";
import { REMOVE_RESTRICT_USER } from "../../../Apis/restrict_users";
import { format } from "date-fns";

import referearn from "../../../Assets/Icons for pages/referEarn.png";
import money from "../../../Assets/Icons for pages/money.png";
import refund from "../../../Assets/Icons for pages/refund.png";
import coin from "../../../Assets/Icons for pages/coin.png";
import SecondaryButton from "../../../Component/Button/SecondaryButton";
import ButtonView from "../../../Component/Button/ButtonView";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import AddCoinDailog from "../../../Component/Dailog/AddCoinDailog";
import { rightsUser } from "../../Home/Drawer/DrawerView";

function UsersView() {
  const navigator = useNavigate();
  const { id } = useParams();
  const [isCall, setIsCall] = useState(true);
  const [isCallW, setIsCallW] = useState(true);
  const [isCallRef, setIsCallRef] = useState(true);
  const [isCallWallet, setIsCallWallet] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [data, setData] = useState({
    user: null,
    isLoading: true,
    orderData: [],
    orderIsLoading: true,
    orderCanceledOrder: [],
    orderCanceledIsLoading: true,
  });

  const [wallet, setDataWallet] = useState({
    isLoading: true,
    walletData: [],
  });

  const [walletRef, setDataWalletRef] = useState({
    isLoading: true,
    walletData: [],
  });

  const [balance, setBalance] = useState(null);

  const [changeStyle, setChangeStyle] = useState({ id: 1 });

  const getUserWallet = async (c) => {
    const r = await GET_USER_WALLET(id, c);

    if (r && r.s) {
      if (c) {
        setDataWallet((p) => ({
          walletData: [...p.walletData, ...(r?.r ?? [])],
          isLoading: false,
        }));
      } else {
        setDataWallet((p) => ({ walletData: r?.r ?? [], isLoading: false }));
      }
    } else {
      setDataWallet((p) => ({ ...p, isLoading: false }));
      setIsCallW(false);
    }
  };

  const getUserWalletREF = async (c) => {
    const r = await GET_USER_WALLET_REF(id, c);

    if (r && r.s) {
      if (c) {
        setDataWalletRef((p) => ({
          walletData: [...p.walletData, ...(r?.r ?? [])],
          isLoading: false,
        }));
      } else {
        setDataWalletRef((p) => ({ walletData: r?.r ?? [], isLoading: false }));
      }
    } else {
      setDataWalletRef((p) => ({ ...p, isLoading: false }));
      setIsCallRef(false);
    }
  };

  const getUserWalletBalance = async (c) => {
    const r = await GET_BALANCE(id);

    if (r && r.s) {
      setBalance(r?.r);
    }
  };

  const callBackWallet = (l) => {
    if (isCallW) {
      getUserWallet(wallet?.walletData?.length ?? 0);
    }
  };

  const callBackWalletRef = (l) => {
    if (isCallRef) {
      getUserWalletREF(walletRef?.walletData?.length ?? 0);
    }
  };

  const getUser = async () => {
    const r = await GET_USER_DETAILS(id);

    if (r && r.s) {
      const d = [];
      if (r.r) {
        // console.log(params.row.roles);
        for (const v of r.r.roles) {
          // console.log("role",v.name);
          d.push(" " + v.name);
        }
      }

      setData((p) => ({
        ...p,
        isLoading: false,
        user: r.r,
      }));
    } else {
      setData((p) => ({ ...p, isLoading: false }));
    }
  };

  const approveReject = async (status, id) => {
    const d = await APPROVE_REJECT(status, id);

    if (d && d.s) {
      getUser();
      ToastShow(`${status == 1 ? "Approved" : "Rejected"} successfully`);
    } else {
      ToastShow("Something went wrong. Please try agian.");
    }
  };

  const getUserOrder = async () => {
    setData((p) => ({
      ...p,
      orderIsLoading: true,
    }));
    const d = await GET_USER_ORDER(id, data?.orderData?.length ?? 0);

    if (d && d.s && d.r) {
      setData((p) => ({
        ...p,
        orderIsLoading: false,
        orderData: [...data.orderData, ...d.r],
      }));
    } else {
      setIsCall(false);
      setData((p) => ({
        ...p,
        orderIsLoading: false,
      }));
    }
  };

  const getUserCanceledOrder = async () => {
    setData((p) => ({
      ...p,
      orderCanceledIsLoading: true,
    }));
    const d = await GET_USER_ORDER(
      id,
      data?.orderCanceledOrder?.length ?? 0,
      -1
    );

    if (d && d.s && d.r) {
      setData((p) => ({
        ...p,
        orderCanceledIsLoading: false,
        orderCanceledOrder: [...data.orderCanceledOrder, ...d.r],
      }));
    } else {
      // setIsCall(false);
      setData((p) => ({
        ...p,
        orderCanceledIsLoading: false,
      }));
    }
  };

  const [restrctionOpen, setRestrctionOpen] = useState(false);
  const handleRestrictionOnClose = async (v) => {
    setRestrctionOpen(false);
    if (v) {
      getUser();
    }
  };

  const handleRestrictionOnOpen = async () => {
    setRestrctionOpen(true);
  };

  const handleUnRestriction = async () => {
    setData((p) => ({
      ...p,
      isLoading: true,
    }));
    const res = await REMOVE_RESTRICT_USER(data.user.restriction.id);
    if (res && res.s) {
      getUser();
    } else {
      setData((p) => ({
        ...p,
        isLoading: false,
      }));
      ToastShow("Oops! something went wrong. Please try again.");
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 60,
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "ids",
      headerName: "OrderID",
      minWidth: 60,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.row.id;
      },
    },
    {
      field: "created_at",
      headerName: "PODate",
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} onlyDate={true} />;
      },
    },
    {
      field: "someone_name",
      headerName: "Name / No.",
      minWidth: 170,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({
        row: { someone_name, someone_mobile, is_for_someone },
      }) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F6F6F6"
            sx={{ my: "13px" }}
          >
            <Typography fontSize="12px" fontWeight="500" textAlign="center">
              {is_for_someone
                ? someone_name
                : `${data.user.first_name + " " + data.user.last_name}`}
            </Typography>
            <Typography mx=".rem" fontSize="11px">
              {is_for_someone ? someone_mobile : data.user.mobile}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p style={{ textAlign: "start", whiteSpace: "normal" }}>
          {params.value}
        </p>
      ),
    },

    {
      field: "date_time",
      headerName: "Shoot Date",
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} onlyDate={true} />;
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 200,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <p style={{ textAlign: "start", whiteSpace: "normal" }}>
          {params.value}
        </p>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          style={{
            padding: "3px",
            borderRadius: "3px",
            backgroundColor: `${getColor(params.value)}`,
            width: "80px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", fontWeight: "500", color: "white" }}
          >
            {getStatus(params.value)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      minWidth: 130,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          isView={1}
          dataId={params.id}
          isEdit={1}
          onEdit={onEdit}
          onView={viewData}
          isDelete={null}
          onDelete={deleteData}
          deleteText="Cancel"
        />
      ),
    },
  ];

  const columnsCancled = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 60,
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "ids",
      headerName: "OrderID",
      minWidth: 60,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.row.id;
      },
    },
    {
      field: "created_at",
      headerName: "PODate",
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} onlyDate={true} />;
      },
    },
    {
      field: "someone_name",
      headerName: "Name / No.",
      minWidth: 170,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({
        row: { someone_name, someone_mobile, is_for_someone },
      }) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F6F6F6"
            sx={{ my: "13px" }}
          >
            <Typography fontSize="12px" fontWeight="500" textAlign="center">
              {is_for_someone
                ? someone_name
                : `${data.user.first_name + " " + data.user.last_name}`}
            </Typography>
            <Typography mx=".rem" fontSize="11px">
              {is_for_someone ? someone_mobile : data.user.mobile}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p style={{ textAlign: "start", whiteSpace: "normal" }}>
          {params.value}
        </p>
      ),
    },

    {
      field: "date_time",
      headerName: "Shoot Date",
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} onlyDate={true} />;
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 200,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <p style={{ textAlign: "start", whiteSpace: "normal" }}>
          {params.value}
        </p>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          style={{
            padding: "3px",
            borderRadius: "3px",
            backgroundColor: `${getColor(params.value)}`,
            width: "80px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "11px", fontWeight: "500", color: "white" }}
          >
            {getStatus(params.value)}
          </Typography>
        </Box>
      ),
    },
  ];

  const columnsWallet = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      width: 60,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "msg",
      headerName: "Name",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "start",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px", my: "10px" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Type",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px" }}>{params.value}</Typography>
      ),
    },
    {
      field: "coin",
      headerName: "Coin",
      width: 150,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ref_table",
      headerName: "OrderID",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        console.log(params.value);
        return (
          <Typography>
            {params.value == "orders" ? params.row.ref_id : "-"}
          </Typography>
        );
      },
    },
    {
      field: "t_type",
      headerName: "P.Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px" }}>
          {params.value == 1 ? "Credit" : "Debit"}
        </Typography>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px" }}>
          {params.value == 1
            ? "Success"
            : params.value == 0
            ? "Pending"
            : "Cancelled"}
        </Typography>
      ),
    },

    {
      field: "created_at",
      headerName: "Date",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} onlyDate={true} />;
      },
    },
  ];

  const columnsWalletRef = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 60,
      align: "center",
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "msg",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px" }}>{params.value}</Typography>
      ),
    },

    {
      field: "name",
      headerName: "Type",
      width: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px" }}>{params.value}</Typography>
      ),
    },

    {
      field: "coin",
      headerName: "Coin",
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "12px" }}>
          {params.value == 1 ? "Credit" : "Debit"}
        </Typography>
      ),
    },

    {
      field: "created_at",
      headerName: "Date",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} onlyDate={true} />;
      },
    },
  ];

  const callBack = (l) => {
    if (isCall) {
      getUserOrder();
    }
  };

  const viewData = (v) => {
    var t = data.orderData.filter((e) => e.id === v);
    navigator(`/order/` + t[0].id);
  };

  const onEdit = (v) => {
    var t = data.orderData.filter((e) => e.id === v);
    navigator(`/order/` + t[0].id + "/edit");
  };

  const deleteData = async (v) => {
    const d = await cancelOrder(v);

    if (d && d.s) {
      ToastShow("Deleted successfully");
      const t = data.orderData.filter((j) => j.id != v);
      setData((p) => ({ ...p, orderData: t }));
    } else {
      ToastShow("Opps! something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    getUser();
    getUserOrder();
    getUserWallet(0);
    getUserWalletBalance();
    getUserWalletREF(0);
    getUserCanceledOrder();
  }, []);

  return (
    <>
      {data.isLoading ? (
        <Box
          display="flex"
          height="100vh"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : data.user ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderRadius: "6px",
                p: 2,
                boxShadow: "3px 3px 7px 0px #00000040",
              }}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  sx={{
                    height: "60px",
                    width: "60px",
                    objectFit: "fill",
                  }}
                  src={
                    API_URL.baseUrl + API_URL.userProfileImage + data.user.photo
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    ml: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "#252525",
                      fontSize: "16.8px",
                    }}
                  >
                    {(data.user.first_name ?? "") +
                      " " +
                      (data.user.middle_name ?? "") +
                      " " +
                      (data.user.last_name ?? "") +
                      " "}
                    ({data.user.gender})
                  </Typography>
                  <Typography fontSize="12.5px">
                    {data.user.email ?? ""}
                  </Typography>
                  <Typography fontSize="12.5px">
                    {data.user.mobile ?? ""}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" gap={1}>
                <Button
                  disabled={data.user.provider_agreement_date ? false : true}
                  variant="contained"
                  disableElevation
                  onClick={
                    data.user.restriction == null
                      ? handleRestrictionOnOpen
                      : handleUnRestriction
                  }
                  sx={{
                    width: "10.3vw",
                    height: "45%",
                    border: "1px solid #BEBEBE",
                    textAlign: "start",
                    fontSize: "12px",
                    color: "black",
                    borderRadius: "6px",
                    backgroundColor:
                      data.user.restriction == null ? "#ededed" : "#BEBEBE",
                    "&:hover": {
                      backgroundColor: "#D3D3D3",
                    },
                    textTransform: "none",
                  }}
                >
                  {data.user.restriction == null ? "Disable" : "Enable"}
                </Button>
                <SecondaryButton
                  label="Delete"
                  width="10.4vw"
                  height="45%"
                  onClick={() => ToastShow("In Development")}
                />
              </Box>
            </Paper>

            {data.user.restriction ? (
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 0,
                }}
              >
                {data.user.restriction && (
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#373333",
                        mt: 2,
                      }}
                    >
                      Restriction Details
                    </Typography>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Note :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {data.user.restriction.note ?? "-"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Till Date :
                      </Typography>
                      <Typography>
                        {" "}
                        {data.user?.restriction?.till_date
                          ? format(
                              Date.parse(data.user.restriction.till_date),
                              "dd-MMM-yyyy"
                            )
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Paper>
            ) : null}

            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: 2.5,
              }}
            >
              <Paper
                sx={{
                  width: "100%",
                  padding: 1.1,
                  borderRadius: "6px",
                  boxShadow: "3px 3px 7px 0px #00000040",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFAB30",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    height: 40,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      pl: ".8rem",
                    }}
                  >
                    Details
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <ItemView title="ID" value={data.user.id} />
                  <ItemView
                    title="Created At"
                    value={
                      data.user?.created_at
                        ? format(
                            Date.parse(data.user.created_at),
                            "dd-MMM-yyyy"
                          )
                        : "-"
                    }
                  />
                </Box>

                <Box display="flex">
                  <ItemView
                    title="DOB"
                    value={data.user.dob ? data.user.dob : "No available"}
                    isLastInLeft={true}
                  />
                  <ItemView
                    title="Address"
                    value={
                      data.user.address ? data.user.address : "No available"
                    }
                    isLastInRight={true}
                  />
                </Box>
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "auto",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <CardView
                  title="Refer & Earn"
                  value={balance?.coin ?? "0"}
                  icon={referearn}
                />

                <CardViewCoin
                  title="Coin Refund"
                  value={"₹" + (balance?.coin_refunded ?? "0")}
                  icon={refund}
                  userId={id}
                  callApi={getUserWalletBalance}
                />
                <CardView
                  title="Money"
                  value={"₹" + (balance?.money ?? "0")}
                  icon={money}
                />

                <CardView
                  title="Coin"
                  value={balance?.coin ?? "0"}
                  icon={coin}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Paper
                sx={{
                  width: "375px",
                  height: "64vh",
                  boxShadow: "3px 3px 7px 0px #00000040",
                }}
              >
                <Box
                  sx={{
                    height: "10.5%",
                    bgcolor: "#F8AF41",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "12px",
                    borderTopLeftRadius: "6px",
                    borderTopRightRadius: "6px",
                  }}
                >
                  <Typography fontSize=".9rem" fontWeight="500">
                    Options
                  </Typography>
                </Box>
                <ListItem
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    pt: "0.6rem",
                  }}
                >
                  <ListItemButton
                    onClick={() => setSelectedIndex(0)}
                    sx={{
                      width: "100%",
                      height: "2.8rem",
                      color: "transparent",
                      borderBottom: "1px solid #ebe8e8",
                      bgcolor: selectedIndex == 0 && "#F4920026",
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedIndex == 0 ? "#F8B03F" : "black",
                        fontWeight: selectedIndex == 0 && "500",
                        fontSize: "15px",
                      }}
                    >
                      Order Details
                    </Typography>
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => setSelectedIndex(1)}
                    sx={{
                      width: "100%",
                      height: "2.8rem",
                      color: "transparent",
                      borderBottom: "1px solid #ebe8e8",
                      bgcolor: selectedIndex == 1 && "#F4920026",
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedIndex == 1 ? "#F8B03F" : "black",
                        fontWeight: selectedIndex == 1 && "500",
                        fontSize: "15px",
                      }}
                    >
                      Wallet History
                    </Typography>
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => setSelectedIndex(2)}
                    sx={{
                      width: "100%",
                      height: "2.8rem",
                      color: "transparent",
                      borderBottom: "1px solid #ebe8e8",
                      bgcolor: selectedIndex == 2 && "#F4920026",
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedIndex == 2 ? "#F8B03F" : "black",
                        fontWeight: selectedIndex == 2 && "500",
                        fontSize: "15px",
                      }}
                    >
                      Referral
                    </Typography>
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => setSelectedIndex(3)}
                    sx={{
                      width: "100%",
                      height: "2.8rem",
                      color: "transparent",

                      bgcolor: selectedIndex == 3 && "#F4920026",
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedIndex == 3 ? "#F8B03F" : "black",
                        fontWeight: selectedIndex == 3 && "500",
                        fontSize: "15px",
                      }}
                    >
                      Cancel Order
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </Paper>

              {selectedIndex == 0 ? (
                <DataGridCover
                  title="User Order Details"
                  component={
                    <DataGrideView
                      rows={data.orderData}
                      columns={columns}
                      callBack={callBack}
                      isLoading={data.orderIsLoading}
                      colHeight={null}
                    />
                  }
                />
              ) : null}

              {selectedIndex == 1 ? (
                <DataGridCover
                  title="User Wallet History"
                  component={
                    <DataGrideView
                      rows={wallet.walletData}
                      columns={columnsWallet}
                      callBack={callBackWallet}
                      isLoading={wallet.isLoading}
                      colHeight={null}
                    />
                  }
                />
              ) : null}

              {selectedIndex == 2 ? (
                <DataGridCover
                  title="User Referral"
                  component={
                    <DataGrideView
                      rows={walletRef.walletData}
                      columns={columnsWalletRef}
                      callBack={callBackWalletRef}
                      isLoading={walletRef.isLoading}
                      colHeight="3.4rem"
                    />
                  }
                />
              ) : null}

              {selectedIndex == 3 ? (
                <DataGridCover
                  title="Cancel Order"
                  component={
                    <DataGrideView
                      rows={data.orderCanceledOrder}
                      columns={columnsCancled}
                      callBack={callBack}
                      isLoading={data.orderCanceledIsLoading}
                      colHeight={null}
                    />
                  }
                />
              ) : null}
            </Box>

            {/* <Typography sx={{ mt: 3, mb: 1, fontWeight: "bold" }}>
              User order details
            </Typography>
            <DataGrideView
              rows={data.orderData}
              columns={columns}
              callBack={callBack}
              isLoading={data.orderIsLoading}
              sx={{ height: "500px", width: "100%", mt: 1 }}
            />

            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ mt: 3, mb: 1, fontWeight: "bold" }}>
                User wallet details
              </Typography>
            </Box>
            <DataGrideView
              rows={wallet.walletData}
              columns={columnsWallet}
              callBack={callBackWallet}
              isLoading={wallet.isLoading}
              sx={{ height: "500px", width: "100%", mt: 1 }}
            />

            <Typography sx={{ mt: 3, mb: 1, fontWeight: "bold" }}>
              User Refral{" "}
              <span style={{ color: "grey" }}>
                (Total : {walletRef.walletData.length})
              </span>
            </Typography>

            <DataGrideView
              rows={walletRef.walletData}
              columns={columnsWalletRef}
              callBack={callBackWalletRef}
              isLoading={walletRef.isLoading}
              sx={{ height: "500px", width: "100%", mt: 1 }}
            /> */}
          </Box>
        </>
      ) : (
        <Typography>No user available </Typography>
      )}

      {restrctionOpen && (
        <RestrictUser
          app={1}
          handleOnClose={handleRestrictionOnClose}
          open={restrctionOpen}
          id={id}
          row={data}
        />
      )}
    </>
  );
}

export default UsersView;

function ItemView({ title, value, isLastInLeft, isLastInRight }) {
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "row",
        display: "flex",
        borderBottom: "solid 1px #c0c0c07c",
        borderRight: "solid 1px #c0c0c07c",
        borderBottomLeftRadius: isLastInLeft && "6px",
        borderBottomRightRadius: isLastInRight && "6px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#EFEFEF",
          width: "40%",
          height: "35px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Typography fontSize="12px">{title}</Typography>
      </Box>
      <Box
        sx={{
          width: "60%",
          height: "35px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 1,
          overflow: "auto",
        }}
      >
        <Typography fontSize="13px" fontWeight="500">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function CardView({ icon, value, title }) {
  return (
    <Paper
      elevation={5}
      sx={{
        flex: 1,
        display: "flex",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "140px",
        minHeight: "100%",
        boxShadow: "3px 3px 7px 0px #00000040",
        borderRadius: "6px",
      }}
    >
      <Avatar src={icon} />

      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
        {title}
      </Typography>
      <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
        {value}
      </Typography>
    </Paper>
  );
}

function CardViewCoin({ icon, value, title, userId, callApi }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Paper
        elevation={5}
        sx={{
          flex: 1,
          display: "flex",
          gap: 0.8,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "140px",
          height: "100%",
          boxShadow: "3px 3px 7px 0px #00000040",
          borderRadius: "6px",
        }}
      >
        <Avatar src={icon} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography>{title}</Typography>

          <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
        </Box>
        {rightsUser.includes(19) && (
          <ButtonView
            lable="Add"
            sx={{
              width: "90%",
              height: "22px",
            }}
            fontSize="14px"
            onClick={() => setOpen(true)}
          />
        )}
      </Paper>

      {open && (
        <AddCoinDailog
          handleOnClose={(v) => {
            setOpen(false);
            if (v) {
              callApi();
            }
          }}
          open={open}
          userId={userId}
        />
      )}
    </>
  );
}
