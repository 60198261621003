import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import { API_URL } from "../../../../../Apis/config";
import ConfirmationDailog from "../../../../../Component/Dailog/ConfirmationDailog";
import { DELETEONSPOTNEWGALLERY } from "../../../../../Apis/new_on_spot_api";
import { useState } from "react";
import { ToastShow } from "../../../../../Component/Toast/Toast";
import AddUpdateNewGalleryDailog from "../../../../../Component/Dailog/AddUpdateNewOnSpotGallery";
import NewSponserGalleryMultiDailog from "../../../../../Component/Dailog/AddNewOnSpotMultGallery";

function Gallery({ getData, id, row }) {
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleOpen = (v = null) => {
    console.log(v)
    setSelectedRow(v);
    setOpen(true);
  };

  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOpen(false);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTNEWGALLERY(selectedId);
      if (r && r.s) {
        getData();
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  return (
    <>
      <Paper
        sx={{
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Gallery</Typography>
          <Button onClick={()=>handleOpen()}>Add Gallery</Button>
        </Box>
        <Divider />

        {row.length ? (
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              gap: "15px",
              p: 1,
              flexWrap: "wrap",
            }}
          >
            {row.map((v, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={API_URL.baseUrl + API_URL.onspot2GalleryLogo + v.file}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "contain",
                      borderRadius: "15px",
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      mt: 2,
                    }}
                  >
                    <Button
                      onClick={() => handleOpen(v)}
                      variant="outlined"
                      sx={{
                        borderRadius: "0px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        flex: 1,
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => onDelete(v.id)}
                      variant="outlined"
                      sx={{
                        flex: 1,
                        borderRadius: "0px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography>
            Please add the gallery by the "ADD GALLERY" button
          </Typography>
        )}
      </Paper>

      {open && selectedRow ? (
        <AddUpdateNewGalleryDailog
          row={selectedRow}
          handleOnClose={handleOnClose}
          open={open}
          on_spot_id={id}
        />
      ) : (
        open && <NewSponserGalleryMultiDailog
        handleOnClose={handleOnClose}
        open={open}
        on_spot_id={id}
      />
      )}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete gallery !"
          content="Are you sure, You want to delete the gallery?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default Gallery;
