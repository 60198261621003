import React, { useState } from "react";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import {
  GETCATEGORYSERVICETEMPLATE,
  ADD_UPDATE_SERVICE,
} from "../../../Apis/category_api";
import SplitButton from "../../../Component/Button/SplitButton";
import { useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { ToastShow } from "../../../Component/Toast/Toast";
import { useParams } from "react-router-dom";

const ImportService = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openToast, setOpenToast] = useState(false);

  const [rowsForPre, setRowsForPre] = useState([]);
  const [rowsForPost, setRowsForPost] = useState([]);

  const { id } = useParams();

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 240,
      minWidth: 240,
      flex: 1,
      headerAlign: "start",
      align: "start",
    },
    {
      field: "price",
      headerName: "Price",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 170,
      minWidth: 170,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <p>
            {params.row.isFixed
              ? "Main Service"
              : params.row.isExtra
              ? "Pre Add Ons"
              : "Post Add Ons"}
          </p>
        );
      },
    },
    {
      field: "Per/Hr",
      headerName: "Per Hours",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "Qty",
      headerName: "Per Qty",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isOnSite",
      headerName: "On Site",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isInventory",
      headerName: "Inventory",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#CEE5FF",
            color: "#CEE5FF",
            padding: "0.3rem 0.850rem",
            borderRadius: "3px",
            minWidth: "0",
            "&:hover": {
              bgcolor: "#9db5cf",
            },
          }}
          onClick={() => addService(params.row)}
        >
          <Typography
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              color: "black",
              textTransform: "none",
            }}
          >
            Add
          </Typography>
        </Button>
      ),
    },
  ];

  //Add service
  const addService = async (data) => {
    let ids = [];
    if (data.roles.length) {
      data.roles.map((v) => {
        if (v.status) {
          ids.push(v.id);
        }
      });
    }

    const res = await ADD_UPDATE_SERVICE({
      name: data.name,
      description: data.description,
      price: data.price,
      roleIds: ids.join(","),
      isExtra: data.isExtra,
      isFixed: data.isFixed,
      isPerHour: data.isPerHour,
      isPost: data.isPost,
      isQty: data.isQty,
      cId: id,
      isInventory: data.isInventory,
      isOnSite: data.isOnSite,
      serviceType:data.type
    });

    if (res && res.s) {
      ToastShow("Add successfully");
    } else {
      ToastShow("Something went wrong. Please try again.");
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const data = await GETCATEGORYSERVICETEMPLATE();
    if (data && data.r) {
      //Main Service
      const objectsWithIsFix = data.r.filter(
        (obj) => obj.isFixed === 1 && obj.isExtra === 0
      );
      if (objectsWithIsFix.length > 0) {
        setRows((prevState) => [...prevState, ...objectsWithIsFix]);
      }

      //Pre Add Ons
      const objectsWithIsExtra = data.r.filter(
        (obj) => obj.isExtra === 1 && obj.isFixed === 0
      );
      if (objectsWithIsExtra.length > 0) {
        setRowsForPre((prevState) => [...prevState, ...objectsWithIsExtra]);
      }

      //Post Add Ons
      const objectsWithIsPost = data.r.filter(
        (obj) => obj.isExtra === 0 && obj.isFixed === 0
      );
      if (objectsWithIsPost.length) {
        setRowsForPost((prevState) => [...prevState, ...objectsWithIsPost]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddClick = () => {
    setOpenToast(true);
  };

  return (
    <Stack gap="1rem">
      <DataGridCover
        title="Main Service Template"
        component={
          <DataGrideView
            rows={rows}
            columns={columns}
            colHeight={"2.8rem"}
            columnColor="#F6F6F6"
            sx={{ height: "60vh" }}
          />
        }
      />

      <DataGridCover
        title="Pre Add On's"
        component={
          <DataGrideView
            rows={rowsForPre}
            columns={columns}
            colHeight={"2.8rem"}
            columnColor="#F6F6F6"
            sx={{ height: "60vh" }}
          />
        }
      />

      <DataGridCover
        title="Post Add On's"
        component={
          <DataGrideView
            rows={rowsForPost}
            columns={columns}
            colHeight={"2.8rem"}
            columnColor="#F6F6F6"
            sx={{ height: "60vh" }}
          />
        }
      />
    </Stack>
  );
};

export default ImportService;
