import {
  Dialog,
  DialogActions,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ButtonView from "../../../Component/Button/ButtonView";
import { GrClose } from "react-icons/gr";
import TextFieldSmall from "../../../Component/TextFields/TextFieldSmall";
import { updateProviderPrice } from "../../../Apis/order_api";

function AmountAdjustModal({ open, handleClose, providerID, getData }) {
  const [price, setPrice] = useState("");

  const handleUpdatePrice = async () => {
    const res = await updateProviderPrice({
      providerID: providerID,
      pricePerHour: Number(price),
    });

    if (res && res.s === 1) {
      getData();
    } else {
      console.log("err");
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Stack gap="1rem" m={1}>
          <Stack
            width="100%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.4rem"
            px="0.5rem"
          >
            <Typography
              fontWeight="500"
              fontSize="15px"
              sx={{ userSelect: "none" }}
            >
              Adjust Amount
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={handleClose}
            />
          </Stack>

          <Stack>
            <TextFieldSmall
              width="250px"
              type="number"
              label="Amount"
              placeholder="Enter amount"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Stack>
        </Stack>

        <DialogActions>
          <ButtonView
            lable="Submit"
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={() => {
              handleUpdatePrice();
              handleClose();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AmountAdjustModal;
