import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { SOLVE_ISSUE } from "../../Apis/order_issue_api";
import { ToastShow } from "../Toast/Toast";

function SolveOrderIssueDailog({ open, handelClose, id }) {
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handelSubmit = async () => {
    if (!response) {
      ToastShow("Please fill response");
    } else {
      setIsLoading(true);
      const d = await SOLVE_ISSUE({
        id: id,
        status: 1,
        response: response,
      });

      if (d && d.s) {
        ToastShow("Solved successfully");
        handelClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
        handelClose(0);
      }
      setIsLoading(false);
      
    }
  };
  return (
    <Dialog open={open} onClose={handelClose}>
      <DialogTitle>Solve issue</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
          label="Response..."
            value={response}
            sx={{ my: 2 }}
            onChange={(e) => {
              setResponse(e.target.value);
            }}
          />
        </Box>
        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="outlined" onClick={handelSubmit}>
              Submit
            </Button>
          )}

          <Button variant="outlined" onClick={handelClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default SolveOrderIssueDailog;
