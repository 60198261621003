import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  memo,
  useRef,
} from "react";
import { Add, DragIndicator } from "@mui/icons-material";
import AddUpdatePagesDailog from "../../../Component/Dailog/AddPagesDailog";
import {
  CHANGE_ORDER_PAGE,
  CHANGE_ORDER_PAGE_QUESTION,
  GET_PAGE,
  GET_PAGE_DELETE,
  GET_PAGE_QUESTION,
  GET_PAGE_QUESTION_DELETE,
} from "../../../Apis/rolePage";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import { ToastShow } from "../../../Component/Toast/Toast";
import { dateFormateMMDDYYYHHMMSS } from "../../../Service/DateSevice";
import AddUpdatePagesQuestionDailog from "../../../Component/Dailog/AddQuestionPageDailog";
import ReactDragListView from "react-drag-listview/lib/index";
import { useParams } from "react-router-dom";

const RolesContext = createContext({
  selectedIndex: 0,
  pages: [],
  setPages: (v) => {
    this.pages = v;
  },
  setSelectedIndex: (index) => {},
  selectedRoleId: 0,
  setRoleId: (id) => {},
});

const useRoleContext = () => useContext(RolesContext);

function RolesDetails() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState([]);

  const setSelectedIndexF = (index) => {
    setSelectedIndex(index);
  };

  // async function getRoles() {
  //   setIsLoading(true);
  //   const d = await GETSROLE();
  //   if (d && d.s) {
  //     if (d.r) {
  //       setRows([...d.r]);
  //       setSelectedRole([d.r[0].name]);
  //     }
  //   }

  //   setIsLoading(false);
  // }

  useEffect(() => {
    //   getRoles();
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {/* ROLE selection */}
        <RolesContext.Provider
          value={{
            selectedIndex: selectedIndex,
            setSelectedIndex: setSelectedIndexF,
            selectedRoleId: id,
            pages: pages,
            setPages: (v) => {
              setPages(v);
            },
          }}
        >
          {/* PAGE and ROLES */}
          <Box
            sx={{
              display: "flex",
              height: "87vh",
              width: "100%",
              gap: "1rem",
            }}
          >
            <Pages />
            <Question />
          </Box>
        </RolesContext.Provider>
      </Box>
    </>
  );
}

export default RolesDetails;

const Pages = memo(function () {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const selectIdOperation = useRef(0);
  const selectedRow = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    // console.log(e.target);
    setAnchorEl(null);
  };

  const handleOpenClose = (v) => {
    if (v) getData();
    setOpen(false);
    selectIdOperation.current = 0;
    selectedRow.current = null;
  };

  const handleOpenCloseDelete = async (v) => {
    if (v) {
      const res = await GET_PAGE_DELETE(selectIdOperation.current);
      if (res && res.s) {
        const t = pages.filter((v) => v.id != selectIdOperation.current);
        await callDataChange(t);
        getData();
        ToastShow("Success");
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
      }
    }
    setOpenDelete(false);
    selectIdOperation.current = 0;
    selectedRow.current = null;
  };

  const { setSelectedIndex, selectedRoleId, setPages, pages, selectedIndex } =
    useRoleContext();

  const getData = async () => {
    setSelectedIndex(0);
    const data = await GET_PAGE(selectedRoleId);
    if (data && data.s) {
      setPages(data?.r ?? []);
    }
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      // console.log("call", fromIndex, toIndex);
      const data = [...pages];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      data.map((v, index) => (v.ordr = index));
      // console.log(data);
      setPages(data);
      callDataChange(data);

      // console.log("from",fromIndex,"to",toIndex);
    },
    nodeSelector: "li",
    handleSelector: "a",
  };

  const callDataChange = async (data) => {
    const t = data.map((v, i) => ({ id: v.id, ordr: i }));
    const res = await CHANGE_ORDER_PAGE(t);

    if (res && res.s) {
      getData();
    }
  };

  useEffect(() => {
    setPages([]);
    getData();
  }, [selectedRoleId]);
  return (
    <Box
      sx={{
        bgcolor: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "3px 3px 7px 0px #00000040",
      }}
    >
      <Box
        sx={{
          bgcolor: "#F8AF41",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1.8,
          py: 1.2,
        }}
      >
        <Typography>Pages</Typography>

        <Button
          style={{
            height: "80%",
            paddingLeft: "6px",
            paddingRight: "6px",
            borderRadius: "5px",
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            color: "#484848",
            textTransform: "none",
          }}
          onClick={() => setOpen(true)}
        >
          <img
            src={require("../../../Assets/Edit_pencil.png")}
            style={{ objectFit: "fill", width: "11px" }}
            alt=""
          />
          <p style={{ fontSize: "11px" }}>Add Page</p>
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        <ReactDragListView {...dragProps}>
          <ol style={{ width: "20rem" }}>
            {pages.map((v, index) => {
              return (
                <li
                  key={v.id + "" + index + "li"}
                  style={{
                    listStyle: "none",
                    width: "100%",
                  }}
                >
                  <a
                    style={{
                      cursor: "move",
                      color: "transparent",
                      textDecoration: "none",
                      width: "100%",
                    }}
                  >
                    <Box
                      key={index}
                      id="pageparent"
                      sx={{
                        width: "100%",
                        px: 1,
                        py: 0.3,
                        display: "flex",
                        alignItems: "center",
                        borderBottom:
                          pages.length - 1 !== index && "1px solid #ebe8e8",
                        cursor: "pointer",
                        bgcolor:
                          pages[selectedIndex].id == v.id
                            ? "#F4920026"
                            : "white",
                        "&:hover": { bgcolor: "#F8F8F8" },
                      }}
                      onClick={(e) => {
                        // console.log(e);
                        setSelectedIndex(index);
                      }}
                    >
                      <DragIndicator
                        color="primary"
                        fontSize="25px"
                        sx={{
                          cursor: "grab",
                          m: 1,
                        }}
                      />
                      <Typography
                        id="pageTitle"
                        sx={{
                          width: "100%",
                          fontSize: "14px",
                          color:
                            pages[selectedIndex].id == v.id
                              ? "#F49200"
                              : "black",
                        }}
                      >
                        {v.title}
                      </Typography>

                      <div>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu ? "long-menu" : undefined}
                          aria-expanded={openMenu ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            // console.log(e);
                            e.stopPropagation();
                            selectIdOperation.current = v.id;
                            handleClick(e);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </div>
                    </Box>
                  </a>
                </li>
              );
            })}
          </ol>
        </ReactDragListView>
      </Box>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 145 * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          key="Edit"
          value="Edit"
          onClick={(e) => {
            handleClose(e);
            selectedRow.current = pages.filter(
              (v) => v.id == selectIdOperation.current
            )[0];
            setOpen(true);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          key="Delete"
          value="Delete"
          onClick={(e) => {
            handleClose(e);
            setOpenDelete(true);
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      {open && (
        <AddUpdatePagesDailog
          open={open}
          handleOnClose={handleOpenClose}
          row={selectedRow.current}
          role={selectedRoleId}
          ordr={pages.length}
        />
      )}
      {openDelete && (
        <ConfirmationDailog
          title="Delete!"
          content="Are you sure, want to delete it?"
          open={openDelete}
          handleOnClose={handleOpenCloseDelete}
        />
      )}
    </Box>
  );
});

function Question() {
  const { selectedIndex, pages } = useRoleContext();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const selectIdOperation = useRef(0);
  const selectedRow = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    // console.log(e.target);
    setAnchorEl(null);
  };

  const handleOpenClose = (v) => {
    if (v) getData();
    setOpen(false);
    selectIdOperation.current = 0;
    selectedRow.current = null;
  };

  const [rows, setRows] = useState([]);

  const getData = async () => {
    const res = await GET_PAGE_QUESTION(pages[selectedIndex].id);
    if (res && res.s) {
      setRows(res?.r ?? []);
    }
  };

  const handleOpenCloseDelete = async (v) => {
    if (v) {
      const res = await GET_PAGE_QUESTION_DELETE(selectIdOperation.current);
      if (res && res.s) {
        const t = rows.filter((v) => v.id != selectIdOperation.current);
        await callDataChange(t);
        getData();
        ToastShow("Success");
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
      }
    }
    setOpenDelete(false);
    selectIdOperation.current = 0;
    selectedRow.current = null;
  };

  useEffect(() => {
    if (pages.length) {
      getData();
    } else {
      // free the row.
    }
  }, [selectedIndex, pages]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      // console.log("call", fromIndex, toIndex);
      const data = [...rows];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      data.map((v, index) => (v.ordr = index));
      // console.log(data);
      setRows(data);
      // console.log("from",fromIndex,"to",toIndex);
      callDataChange(data);
    },
    nodeSelector: "li",
    handleSelector: "a",
  };

  const callDataChange = async (data) => {
    const t = data.map((v, i) => ({ id: v.id, ordr: i }));
    const res = await CHANGE_ORDER_PAGE_QUESTION(t);

    if (res && res.s) {
      getData();
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        boxShadow: "3px 3px 7px 0px #00000040",
      }}
    >
      <Box
        sx={{
          bgcolor: "#F8AF41",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1.8,
          py: 1.2,
        }}
      >
        <Typography>
          Question - {pages.length ? pages[selectedIndex].title : ""}
        </Typography>

        <Button
          style={{
            height: "80%",
            paddingLeft: "6px",
            paddingRight: "6px",
            borderRadius: "5px",
            display: "flex",
            gap: "5px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            color: "#484848",
            textTransform: "none",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <img
            src={require("../../../Assets/Edit_pencil.png")}
            style={{ objectFit: "fill", width: "11px" }}
            alt=""
          />
          <p style={{ fontSize: "11px" }}>Add Question</p>
        </Button>
      </Box>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          overflowX: "hidden",
        }}
      >
        <ReactDragListView {...dragProps}>
          <ol>
            {rows.map((v, index) => {
              return (
                <li
                  key={v.id + "" + index + "li"}
                  style={{ listStyle: "none" }}
                >
                  <a
                    style={{
                      cursor: "grab",
                      color: "transparent",
                      textDecoration: "none",
                    }}
                  >
                    <Box
                      key={index}
                      sx={{
                        bgcolor: "#c0c0c019",
                        width: "100%",
                        mb: 1,
                        p: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <QuestionView title="Name" value={v.name} />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <Tooltip title="Hold and drag">
                            <DragIndicator
                              color="primary"
                              sx={{
                                cursor: "move",
                                m: 1,
                              }}
                            />
                          </Tooltip> */}
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={openMenu ? "long-menu" : undefined}
                            aria-expanded={openMenu ? "true" : undefined}
                            aria-haspopup="true"
                            sx={{
                              p: 0,
                            }}
                            onClick={(e) => {
                              // console.log(e);
                              e.stopPropagation();
                              selectIdOperation.current = v.id;
                              handleClick(e);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <QuestionView title="Hint" value={v.hint} />
                      <QuestionView title="Data-Type" value={v.data_type} />
                      <QuestionView title="Data-Type2" value={v.data_type2} />
                      <QuestionView
                        title="Is Approved"
                        value={v.is_approval ? "Yes" : "No"}
                      />
                      <QuestionView
                        title="Is Required"
                        value={v.is_required ? "Yes" : "No"}
                      />
                      <QuestionView
                        title="Created At"
                        value={dateFormateMMDDYYYHHMMSS(v.created_at)}
                      />
                      {v?.choices && JSON.parse(v?.choices ?? "[]").length ? (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100px",
                            }}
                          >
                            <Typography
                              sx={{
                                width: "100px",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              Choices
                            </Typography>
                          </Box>
                          <Typography sx={{ mr: 1 }}>:</Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {v?.choices
                              ? JSON.parse(v?.choices).map((v) => (
                                  <Chip
                                    size="small"
                                    sx={{ m: "2px" }}
                                    label={v}
                                    key={v}
                                  />
                                ))
                              : null}
                          </Box>
                        </Box>
                      ) : null}

                      {/* <Divider sx={{ my: 2 }} />
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  size="small"
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    width: "100%",
                  }}
                >
                  <Button>Edit</Button>
                  <Button>Delete</Button>
                </ButtonGroup> */}
                    </Box>
                  </a>
                </li>
              );
            })}
          </ol>
        </ReactDragListView>
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 145 * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          key="Edit"
          value="Edit"
          onClick={(e) => {
            handleClose(e);
            selectedRow.current = rows.filter(
              (v) => v.id == selectIdOperation.current
            )[0];
            setOpen(true);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          key="Delete"
          value="Delete"
          onClick={(e) => {
            handleClose(e);
            setOpenDelete(true);
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      {open && (
        <AddUpdatePagesQuestionDailog
          open={open}
          handleOnClose={handleOpenClose}
          row={selectedRow.current}
          pageId={pages[selectedIndex].id}
          ordr={rows.length}
        />
      )}

      {openDelete && (
        <ConfirmationDailog
          title="Delete!"
          content="Are you sure, want to delete it?"
          open={openDelete}
          handleOnClose={handleOpenCloseDelete}
        />
      )}
    </Box>
  );
}

function QuestionView({ title, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "3px",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          width: "100px",
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ mr: 1 }}>:</Typography>
      <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
        {value}
      </Typography>
    </Box>
  );
}
