import React, { useEffect } from "react";
import {
  DELETECATEGORY,
  DELETECATEGORYSERVICE,
  GETCATEGORY,
  GETCATEGORYDETAILS,
  GETCATEGORYSERVICE,
} from "../../../Apis/category_api";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Avatar, Box, Button, Fab, Tab, Tabs, Typography } from "@mui/material";
import { API_URL } from "../../../Apis/config";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import AddServiceDailog from "../../../Component/Dailog/AddService";
import SplitButton from "../../../Component/Button/SplitButton";
import { ToastShow } from "../../../Component/Toast/Toast";
import { useNavigate } from "react-router-dom";
import AddSubCategory from "../../../Component/Dailog/AddSubCategoryDailog";

//For Mui Tabs
import PropTypes from "prop-types";
import ButtonView from "../../../Component/Button/ButtonView";
import CategoryAddModal from "./CategoryAddModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function CategoryDetails() {
  const [data, setData] = useState(null);
  const [dataSC, setDataSC] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSC, setIsLoadingSC] = useState(true);
  const [selectdRowSC, setSelectedRowsSC] = React.useState(null);

  const [selectdRow, setSelectedRows] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [rowsForPre, setRowsForPre] = useState([]);
  const [rowsForPost, setRowsForPost] = useState([]);
  const [isLoadingS, setIsLoadingS] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [openSC, setOpenSC] = React.useState(false);
  const [openCategoryM, setOpenCategoryM] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleOnClose = (p) => {
    setOpen(false);
    if (p) {
      setRows([]);
      setRowsForPre([]);
      setRowsForPost([]);
      getDataService();
    }
  };

  const handleOpen = () => {
    setSelectedRows(null);
    setOpen(true);
  };

  const handleOpenT = () => {
    navigate("service-template");
  };

  const onEdit = (v) => {
    let t = null;
    if (value == 0) {
      t = rows.filter((j) => j.id == v);
    } else if (value == 1) {
      t = rowsForPre.filter((j) => j.id == v);
    } else {
      t = rowsForPost.filter((j) => j.id == v);
    }

    setSelectedRows(t[0]);

    setOpen(true);
  };

  const onDelete = async (id) => {
    const r = await DELETECATEGORYSERVICE(id);
    if (r && r.s) {
      const d = rows.filter((v) => v.id != id);
      const p = rowsForPre.filter((v) => v.id != id);
      const t = rowsForPost.filter((v) => v.id != id);

      setRows([...d]);
      setRowsForPre([...p]);
      setRowsForPost([...t]);

      ToastShow("Deleted successfully");
    } else {
      ToastShow("Opps something went wrong. Please try again.");
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography>{params.api.getRowIndex(params.row.id) + 1}</Typography>
        );
      },
    },
    // { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>{params.row.name}</Typography>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>{"₹" + params.value}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={onDelete}
          isView={null}
          title={params.value}
          isEdit={1}
          onEdit={onEdit}
        />
      ),
    },
  ];

  const columnsSC = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 250,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    // {
    //   field: "image",
    //   headerName: "Photo",
    //   width: 130,

    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       params.row.photo? <Avatar
    //         src={API_URL.baseUrl + API_URL.categoryImage + params.row.photo}
    //       />:<Typography>-</Typography>
    //     );
    //   },
    // },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          isEdit={1}
          onDelete={onDeleteSC}
          title={params.value}
          onEdit={onEditSC}
        />
      ),
    },
  ];

  const handleOnCloseSC = (p) => {
    setOpenSC(false);
    setSelectedRowsSC(null);
    if (p) {
      getSubCategoryData();
    }
  };

  const handleOpenSC = () => {
    setOpenSC(true);
  };

  const onEditSC = (id) => {
    const t = dataSC.filter((j) => j.id == id);
    setSelectedRowsSC(t[0]);
    handleOpenSC();
  };

  const onDeleteSC = async (ids) => {
    const r = await DELETECATEGORY(ids, id);
    if (r && r.s) {
      const d = dataSC.filter((v) => v.id != ids);
      setDataSC([...d]);
      ToastShow("Deleted successfully");
    } else {
      ToastShow("Opps something went wrong. Please try again.");
    }
  };

  async function getData() {
    setIsLoading(true);
    const t = await GETCATEGORYDETAILS(id);

    if (t && t.s == 1) {
      setData(t.r);
    }
    setIsLoading(false);
  }

  async function getSubCategoryData() {
    setIsLoadingSC(true);
    const t = await GETCATEGORY(id);

    if (t && t.s == 1) {
      setDataSC(t.r);
    }
    setIsLoadingSC(false);
  }

  async function getDataService() {
    setIsLoadingS(true);
    const t = await GETCATEGORYSERVICE(id);

    if (t && t.s == 1 && t.r) {
      // setRows([...t.r]);

      //Main Service
      const objectsWithIsFix = t.r.filter(
        (obj) => obj.isFixed === 1 && obj.isExtra === 0 && obj.isPost === 0
      );
      if (objectsWithIsFix.length > 0) {
        setRows((prevState) => [...prevState, ...objectsWithIsFix]);
      }

      //Pre Add Ons
      const objectsWithIsExtra = t.r.filter(
        (obj) => obj.isExtra === 1 && obj.isFixed === 0 && obj.isPost === 0
      );
      if (objectsWithIsExtra.length > 0) {
        setRowsForPre((prevState) => [...prevState, ...objectsWithIsExtra]);
      }

      //Post Add Ons
      const objectsWithIsPost = t.r.filter(
        (obj) => obj.isExtra === 0 && obj.isFixed === 0
      );
      if (objectsWithIsPost.length) {
        setRowsForPost((prevState) => [...prevState, ...objectsWithIsPost]);
      }
    }

    setIsLoadingS(false);
  }

  useEffect(() => {
    getData();
    getDataService();
    getSubCategoryData();
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return isLoading ? (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box
        height="100%"
        flexDirection="column"
        sx={{
          overflow: "visible",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          display="flex"
          width="100%"
          height="15%"
          justifyContent="space-between"
          alignItems="center"
          padding=".8rem"
          boxShadow="3px 3px 7px 0px rgba(0, 0, 0, 0.25)"
          backgroundColor="white"
          borderRadius="6px"
        >
          <Box display="flex" alignItems="center" gap="1rem">
            <Avatar
              sx={{
                width: "4rem",
                height: "4rem",
                objectFit: "contain",
              }}
              src={API_URL.baseUrl + API_URL.categoryImage + data.photo}
            />
            <Typography sx={{ fontSize: "1.1rem", fontWeight: "500" }}>
              {data.name}
            </Typography>
          </Box>

          <ButtonView lable="Edit" onClick={() => setOpenCategoryM(true)} />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            width: "100%",
          }}
        >
          {/*Links*/}
          <Box
            sx={{
              width: "25%",
              height: "100%",
              boxShadow: "3px 3px 7px 0px rgba(0, 0, 0, 0.25)",
              backgroundColor: "white",
              borderRadius: "6px",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                color: "black",
                borderColor: "divider",
                "& .css-lrtg2w-MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab
                label="Main Service"
                {...a11yProps(0)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  borderBottom: "1px solid #EFEFEF",
                  alignItems: "self-start",
                }}
              />
              <Tab
                label="Pre Add On's"
                {...a11yProps(1)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  borderBottom: "1px solid #EFEFEF",
                  alignItems: "self-start",
                }}
              />

              <Tab
                label="Post Add On's"
                {...a11yProps(2)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  borderBottom: "1px solid #EFEFEF",
                  alignItems: "self-start",
                }}
              />

              <Tab
                label="Sub Categories"
                {...a11yProps(3)}
                style={{
                  color: "black",
                  textTransform: "capitalize",
                  borderBottom: "1px solid #EFEFEF",
                  alignItems: "self-start",
                }}
              />
            </Tabs>
          </Box>
          {/*Different tabs*/}
          <Box
            sx={{
              width: "80%",
              height: "100%",
              backgroundColor: "white",
              boxShadow: "3px 3px 7px 0px rgba(0, 0, 0, 0.25)",
              "& .css-19kzrtu": {
                padding: "6px",
              },
            }}
            borderRadius="6px"
          >
            {/*Main service*/}
            <TabPanel value={value} index={0}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: ".350rem",
                }}
              >
                <Typography sx={{ fontWeight: "500" }} fontSize="1.2rem">
                  Main Service
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".8rem",
                  }}
                >
                  <Link
                    to="service-template"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      // onClick={handleOpenT}
                      disableElevation
                      size="large"
                      sx={{
                        height: "2.1rem",
                        backgroundColor: "#CEE5FF",
                        display: "flex",
                        gap: "0.4rem",
                        px: "1rem",
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: "gray",
                        },
                      }}
                    >
                      <img src={require("../../../Assets/import-icon.png")} />
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          color: "black",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Import Service
                      </Typography>
                    </Button>
                  </Link>

                  <Button
                    onClick={handleOpen}
                    disableElevation
                    size="large"
                    sx={{
                      height: "2.1rem",
                      bgcolor: "rgba(248, 175, 65, 1)",
                      display: "flex",
                      gap: "0.4rem",
                      px: "1rem",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    }}
                  >
                    <img
                      src={require("../../../Assets/add-button.png")}
                      style={{ objectFit: "fill", width: "15px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Add Service
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <DataGrideView
                key="4"
                colHeight="2.7rem"
                columnColor="#F6F6F6"
                isLoading={isLoadingS}
                rows={rows}
                columns={columns}
                sx={{ height: "67vh" }}
              />
            </TabPanel>

            {/*Pre add on's*/}
            <TabPanel value={value} index={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: ".350rem",
                }}
              >
                <Typography sx={{ fontWeight: "500" }} fontSize="1.2rem">
                  Pre Add On's
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".8rem",
                  }}
                >
                  <Button
                    onClick={handleOpenT}
                    disableElevation
                    size="large"
                    sx={{
                      height: "2.1rem",
                      backgroundColor: "#CEE5FF",
                      display: "flex",
                      gap: "0.4rem",
                      px: "1rem",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    }}
                  >
                    <img src={require("../../../Assets/import-icon.png")} />
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Import Service
                    </Typography>
                  </Button>

                  <Button
                    onClick={handleOpen}
                    disableElevation
                    size="large"
                    sx={{
                      height: "2.1rem",
                      bgcolor: "rgba(248, 175, 65, 1)",
                      display: "flex",
                      gap: "0.4rem",
                      px: "1rem",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    }}
                  >
                    <img
                      src={require("../../../Assets/add-button.png")}
                      style={{ objectFit: "fill", width: "15px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Add Service
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <DataGrideView
                key="1"
                colHeight="2.7rem"
                columnColor="#F6F6F6"
                isLoading={isLoadingS}
                rows={rowsForPre}
                columns={columns}
                sx={{ height: "67vh" }}
              />
            </TabPanel>

            {/*Post add on's*/}
            <TabPanel value={value} index={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: ".350rem",
                }}
              >
                <Typography sx={{ fontWeight: "500" }} fontSize="1.2rem">
                  Post Add On's
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".8rem",
                  }}
                >
                  <Button
                    onClick={handleOpenT}
                    disableElevation
                    size="large"
                    sx={{
                      height: "2.1rem",
                      backgroundColor: "#CEE5FF",
                      display: "flex",
                      gap: "0.4rem",
                      px: "1rem",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    }}
                  >
                    <img src={require("../../../Assets/import-icon.png")} />
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Import Service
                    </Typography>
                  </Button>

                  <Button
                    onClick={handleOpen}
                    disableElevation
                    size="large"
                    sx={{
                      height: "2.1rem",
                      bgcolor: "rgba(248, 175, 65, 1)",
                      display: "flex",
                      gap: "0.4rem",
                      px: "1rem",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    }}
                  >
                    <img
                      src={require("../../../Assets/add-button.png")}
                      style={{ objectFit: "fill", width: "15px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Add Service
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <DataGrideView
                key="2"
                colHeight="2.7rem"
                columnColor="#F6F6F6"
                isLoading={isLoadingS}
                rows={rowsForPost}
                columns={columns}
                sx={{ height: "67vh" }}
              />
            </TabPanel>

            {/*Sub categories*/}
            <TabPanel value={value} index={3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: ".350rem",
                }}
              >
                <Typography sx={{ fontWeight: "500" }} fontSize="1.2rem">
                  Sub Categories
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".8rem",
                  }}
                >
                  <Button
                    onClick={handleOpenSC}
                    disableElevation
                    size="large"
                    sx={{
                      height: "2.1rem",
                      bgcolor: "rgba(248, 175, 65, 1)",
                      display: "flex",
                      gap: "0.4rem",
                      px: "1rem",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    }}
                  >
                    <img
                      src={require("../../../Assets/add-button.png")}
                      style={{ objectFit: "fill", width: "15px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "0.9rem",
                        color: "black",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Add sub category
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <DataGrideView
                key="3"
                isLoading={isLoadingSC}
                rows={dataSC ?? []}
                columns={columnsSC}
                colHeight="2.7rem"
                columnColor="#F6F6F6"
                sx={{ height: "67vh" }}
              />
            </TabPanel>
          </Box>
        </Box>

        {open && (
          <AddServiceDailog
            service={value}
            open={open}
            handleOnClose={handleOnClose}
            row={selectdRow}
            cId={id}
          />
        )}

        {openCategoryM && (
          <CategoryAddModal
            open={openCategoryM}
            cid={data.id}
            fetchData={() => getData()}
            setOpen={setOpenCategoryM}
          />
        )}

        {openSC && (
          <AddSubCategory
            open={openSC}
            handleOnClose={handleOnCloseSC}
            row={selectdRowSC}
            pId={id}
          />
        )}
      </Box>
    </>
  );
}

export default CategoryDetails;
