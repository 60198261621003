import { Box, Button, colors, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DrawerView from "./Drawer/DrawerView";
import MainView from "./MainView";

function Home({ children, title, changeMode }) {
  const [openD, setOpenD] = React.useState(true);
  const [isNotifiaction, setIsNotification] = React.useState("denied");
  const [drawerValue, setDrawerValue] = React.useState(true);
  const handleDrawer = (isRelative = 0, value = false) => {
    if (isRelative === 1) {
      setOpenD(value);
    } else if (isRelative === 2 && drawerValue === false) {
      setOpenD(true);
      setDrawerValue(true);
    } else {
      setOpenD((privious) => !privious);
      setDrawerValue((privious) => !privious);
    }
  };

  useEffect(() => {
    setIsNotification(window.Notification.permission);
    window.Notification.requestPermission().then((r) => {
      // console.log(r);
    });
  }, [window.Notification.permission]);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <DrawerView
        handleDrawer={handleDrawer}
        openD={openD}
        drawerValue={drawerValue}
      />
      <MainView title={title} changeMode={changeMode} drawerValue={drawerValue}>
        {/* {isNotifiaction != "granted" ? (      For notification text
          <Box
            sx={{
              display: "flex",
              backgroundColor: colors.grey[100],
              justifyContent: "end",
              alignItems: "center",
              mt: -1,
              mb: 1,
              px: 2,
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>
              Turn on notifcation so you don't miss any update. Click on icon
              before the URL.{" "}
            </Typography>
            <Button
              size="small"
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => setIsNotification("granted")}
            >
              Got it
            </Button>
          </Box>
        ) : null} */}
        {children}
      </MainView>
    </Box>
  );
}

export default Home;
