import React, { useEffect } from "react";
import SplitButton from "../../../Component/Button/SplitButton";
import {
  GET_SUPPORT_CALLBACK,
  GET_SUPPORT_CALLBACK_DONE,
  GET_SUPPORT_CATEGORY,
} from "../../../Apis/support_api";
import { Box, Button, Typography } from "@mui/material";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import { ToastShow } from "../../../Component/Toast/Toast";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import ButtonForGrid from "../../../Component/Button/ButtonForGrid";
import ButtonView from "../../../Component/Button/ButtonView";

export default function PodianCallbackList() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  // const onDelete = async (id) => {
  //   const row = rows.filter((e) => e.id == id);
  //   setSelectedRow(row[0]);
  //   setOpen(true);
  // };

  const handleOnClose = async (v) => {
    setOpen(false);
    if (v) {
      const d = await GET_SUPPORT_CALLBACK_DONE(selectedRow.id);
      if (d && d.s) {
        ToastShow("Success");
      } else {
        ToastShow(d?.m ?? "Opps! something went wrong. Please try again.");
      }
      getData(0);
    }
    setSelectedRow(null);
  };

  const columns = [
    {
      field: "support_ticket_id",
      headerName: "Ticket Id",
      filterable: false,
      width: 80,
      headerAlign: "center",
      align: "center",
      // renderCell: (params) => {
      //   return params.api.getRowIndex(params.row.id) + 1;
      // },
    },

    {
      field: "uname",
      headerName: "User Name",
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "details",
      headerName: "Details",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography style={{ padding: "1.3rem 0", fontSize: "12.4px" }}>
            {params.row.details}
          </Typography>
        );
      },
    },
    {
      field: "date_time",
      headerName: "DateTime",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} isUtc={false} />;
      },
    },
    {
      field: "s",
      headerName: "Tickets",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <ButtonForGrid
            label="Go to Tickets"
            onClick={() => window.open(`/tickets/podian/` + params.row.support_ticket_id)}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => (
        <ButtonForGrid
          label="Done"
          onClick={() => {
            setSelectedRow(params.row);
            setOpen(true);
          }}
        />
      ),
    },
  ];

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length);
    }
  };

  async function getData(count) {
    if (!count) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GET_SUPPORT_CALLBACK(2, count);

    if (t && t.s == 1 && t.r) {
      if (count == 0) {
        setRows([...t.r]);
      } else {
        setRows((p) => [...p, ...t.r]);
      }
    } else {
      if (count == 0) {
        setRows([]);
      }
      setIsCall(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData(0);
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
        <DataGrideView
          callBack={callBack}
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          sx={{ height: "87vh" }}
          colHeight={null}
        />
      </Box>
      <ConfirmationDailog
        open={open}
        handleOnClose={handleOnClose}
        content="Are you sure want to done this callback?"
        title="Done Callback!"
       />
    </Box>
  );
}
