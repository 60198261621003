import { useEffect, useState } from "react";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import { Box, Button, Rating, Typography } from "@mui/material";
import { GETRATEREVIEWS } from "../../../../../Apis/service_api";
import DateMDYHMA from "../../../../../Component/Date/DateMDYHMA";

const RatingsReview = ({ userID }) => {
  const [data, setData] = useState([]);

  const rows = data?.map((row, index) => ({
    ...row,
    rowId: index + 1,
  }));

  const cols = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.row.rowId}</Typography>;
      },
    },
    {
      field: "order_id",
      headerName: "Order ID",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.order_id}
          </Typography>
        );
      },
    },
    {
      field: "date_time",
      headerName: "Shoot Date",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.row.date_time} onlyDate={true} />;
      },
    },
    {
      field: "first_name",
      headerName: "Username",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.first_name}
          </Typography>
        );
      },
    },
    {
      field: "rate",
      headerName: "Ratings",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Rating value={params.row.rate} readOnly size="small" />
      ),
    },
  ];

  const getData = async () => {
    const res = await GETRATEREVIEWS(userID); //2 to check

    if (res && res.s === 1) {
      setData(res.r);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("RateReview", data);

  return (
    <Box width="100%" boxShadow="3px 3px 7px 0px #00000040">
      <DataGrideView columns={cols} rows={rows ?? []} />
    </Box>
  );
};

export default RatingsReview;
