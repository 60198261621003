import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import ConfirmationDailog from "../../../../../../Component/Dailog/ConfirmationDailog";
import { DELETEONSPOTNEWLOCATIONs } from "../../../../../../Apis/new_on_spot_api";
import { useState } from "react";
import { ToastShow } from "../../../../../../Component/Toast/Toast";
import ZoneLocationOnSpot from "./Zone";
import AddUpdateNewZoneDailog from "../../../../../../Component/Dailog/AddNewOnSpotZoneDialog";
import AddOnShotLocationDailog from "../../../../../../Component/Dailog/AddNewOnSpotLocationDailog";
import EditedLinkDetails from "./EditedLinkDetails";

function LocationOnSpot({ getData, id, row, city_id,role }) {
  const [open, setOpen] = React.useState(false);
  const [openZone, setOpenZone] = React.useState(false);
  const [onSpotCityLocationId, setOnSpotCityLocationId] = React.useState(0);
  const [onSpotCityLocationRow, setOnSpotCityLocationRow] = React.useState(null);

  const handleOpen = (v = null) => {
    setOnSpotCityLocationRow(v);
    setOpen(true);
  };
  const handleOpenZone = (v = null) => {
    setOnSpotCityLocationId(v);
    setOpenZone(true);
  };

  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOnSpotCityLocationRow(null);
    setOpen(false);
  };

  const handleOnCloseZone = (v) => {
    if (v) {
      getData();
    }
    setOnSpotCityLocationId(0);
    setOpenZone(false);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    setSelectedIdN(id);
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTNEWLOCATIONs({
        id: selectedId,
      });
      if (r && r.s) {
        getData();
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  return (
    <>
      <Box
        sx={{
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Button onClick={handleOpen}>Add Location</Button> */}
        </Box>

        {row.length ? (
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              gap: "15px",
              p: 1,
              flexWrap: "wrap",
            }}
          >
            {row.map((v, index) => {
              return (
                <Paper
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    border: "1px solid #c0c0c0",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5">Location</Typography>
                    <Button
                      onClick={() => {
                        handleOpenZone(v.id);
                      }}
                    >
                      Add Zone
                    </Button>
                  </Box>
                  <Divider />
                  <FlexView title="Address" des={v.address} />
                  <FlexView title="Date" des={v.date} />
                  <FlexView title="From Time" des={v.from_time} />
                  <FlexView title="To Time" des={v.to_time} />

                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                    }}
                  >
                    <Button
                      onClick={() => handleOpen(v)}
                      variant="outlined"
                      sx={{
                        borderRadius: "0px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        flex: 1,
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => onDelete(v.id)}
                      variant="outlined"
                      sx={{
                        flex: 1,
                        borderRadius: "0px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      my: 1,
                    }}
                  >
                    {/* <Typography variant="h5">Zone</Typography> */}
                    {/* <Button onClick={() => {}}>Add Zone</Button> */}
                  </Box>
                  <EditedLinkDetails on_spot_id={id} row={v} getData={getData}/>
                  <ZoneLocationOnSpot
                    getData={getData}
                    row={v?.zone ?? []}
                    on_spot_city_location_id={v.id}
                    role={role}
                    id={id}
                  />
                </Paper>
              );
            })}
          </Box>
        ) : (
          <Typography>
            Please add the location by the "ADD-LOCATION" button
          </Typography>
        )}
      </Box>

      {open ? (
        <AddOnShotLocationDailog
          row={onSpotCityLocationRow}
          handleOnClose={handleOnClose}
          open={open}
          on_spot_id={city_id}
        />
      ) : null}

      {openZone ? (
        <AddUpdateNewZoneDailog
          row={null}
          handleOnClose={handleOnCloseZone}
          open={openZone}
          on_spot_city_location_id={onSpotCityLocationId}
        />
      ) : null}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete Location !"
          content="Are you sure, You want to delete the location?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default LocationOnSpot;

function FlexView({ title, des }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          width: "100px",
          fontWeight: "bold",
        }}
      >
        {title} :{" "}
      </Typography>
      <Typography>{des}</Typography>
    </Box>
  );
}
