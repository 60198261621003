import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../Context/AuthContext";

function AuthService({ children }) {
  const location = useLocation();
  const auth = UserContext();

  try {
    // console.log("auth.isLogin",auth.isLogin);
    if (auth && auth.isLogin != 1) {
      return (
        <Navigate to="/" replace={true} state={{ path: location.pathname }} />
      );
    } else if (auth.u == null) {
      localStorage.clear();
      return (
        <Navigate to="/" replace={true} state={{ path: location.pathname }} />
      );
    }
    return children;
  } catch (e) {
    localStorage.clear();
    return (
      <Navigate to="/" replace={true} state={{ path: location.pathname }} />
    );
  }
}

export default AuthService;
