import { Stack, Typography } from "@mui/material";
import React from "react";

const DetailField = ({ label, value, values, isArr = false }) => {
  return (
    <Stack direction="row" width="33.3%" height="2rem">
      <Stack
        bgcolor="#EFEFEF"
        width="35%"
        justifyContent="center"
        pl="0.6rem"
        borderBottom="1px solid white"
      >
        <Typography fontSize="11.2px">{label}</Typography>
      </Stack>
      <Stack
        width="65%"
        pl="0.6rem"
        alignItems="center"
        borderBottom="1px solid #EFEFEF"
        direction="row"
        gap="0.5rem"
        overflow={isArr && values.length > 3 && "scroll"}
      >
        {isArr && values.length ? (
          values.map((item, i) => (
            <Typography fontSize="14px" fontWeight="500" key={i}>
              {values.length === i + 1 ? item : item + ","}
            </Typography>
          ))
        ) : (
          <Typography fontSize="14px" fontWeight="500">
            {value}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default DetailField;
