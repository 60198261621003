import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataGrideView from "../../../../Component/DataGrideView/DataGrideView";
import SplitButton from "../../../../Component/Button/SplitButton";
import AddUpdateWebPodianProcessDailog from "../../../../Component/Dailog/web/PodianProcess";
import { GET_WEB_PODIAN_OF_MONTH } from "../../../../Apis/web/podian_of_month_api";
import AddUpdateWebPodianOfMonthDailog from "../../../../Component/Dailog/web/AddUpdatePodianOfMonth";

function WebPodianOfMonth() {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, SetIsLoading] = useState(true);

  const getData = async () => {
    SetIsLoading(true);
    const res = await GET_WEB_PODIAN_OF_MONTH();

    if (res && res?.s) {
      setRow(res?.r ?? []);
    } else {
      setRow([]);
    }
    SetIsLoading(false);
  };

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setSelectedRow(null);
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "SR.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
        field: "podian",
        headerName: "Podian Name",
        filterable: false,
        maxWidth: 150,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          // console.log(params);
          return (
            <Typography sx={{ py: 1, fontSize: "13px" }}>
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: "month",
        headerName: "Month",
        filterable: false,
        maxWidth: 100,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
          // console.log(params);
          return (
            <Typography sx={{ py: 1, fontSize: "13px" }}>
              {params.value.split("-").reverse().join("-")}
            </Typography>
          );
        },
      },
    {
      field: "score",
      headerName: "Score",
      filterable: false,
      maxWidth: 150,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "review",
      headerName: "Review",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      minWidth: 70,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          // isView={rightsUser.includes(4) ? 1 : null}
          dataId={params.row.id}
          isDelete={null}
          isEdit={1}
          onEdit={onEdit}
          onView={null}
          //   onDelete={onDelete}
          // deleteText="Delete"
        />
      ),
    },
  ];

  const onEdit = (id) => {
    // console.log(id, "id");
    setSelectedRow(() => row.find((v) => v.id == id));
    setOpen(true);
  };

  const handleOpen = ()=>{
    setSelectedRow(null);
    setOpen(true);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "225px",
            mb: 1,
          }}
          onClick={handleOpen}
        >
          <Typography sx={{ color: "primary.main" }}>
            Add Podian Of Month
          </Typography>
        </Button>
        <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
          <DataGrideView
            rows={row}
            columns={columns}
            // callBack={callBack}
            isLoading={isLoading}
            sx={{
              height: "75vh",
            }}
            autoHeight={true}
          />
        </Box>
      </Box>
      {open && (
        <AddUpdateWebPodianOfMonthDailog
          open={open}
          handleOnClose={handleClose}
          row={selectedRow}
        />
      )}
    </>
  );
}

export default WebPodianOfMonth;
