import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  APPROVEAUTO,
  APPROVEREJECTSERVICEPROVIDER,
  APPROVE_REJECT,
  APPROVE_REJECT_KYC,
  GETPROVIDERSDETAILS,
  GETPROVIDERSFORMDETAILS,
  GETPROVIDERSKYC,
  UPDATEPROVIDERHIKE,
} from "../../../Apis/service_api";
import { useParams } from "react-router-dom";
import tokens, { API_URL } from "../../../Apis/config";
import { ToastShow } from "../../../Component/Toast/Toast";
import ReasonDailog from "../../../Component/Dailog/ReasonDailog";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import axios from "axios";
import ApproveRejectDailog from "../../../Component/Dailog/ApproveRejectDailog";
import { Add, History, PlusOne } from "@mui/icons-material";
import ShowHistoryDailog from "../../../Component/Dailog/ShowHistoryDailog";
import heic2any from "heic2any";
import RestrictUser from "../../../Component/Dailog/RestrictUser";
import { REMOVE_RESTRICT_USER } from "../../../Apis/restrict_users";
import { format } from "date-fns";
import { GET_CITIE } from "../../../Apis/city_api";
import AddCityServiceArea from "../../../Component/Dailog/AddCityToServiceArea";
import {
  GET_CATEGORIES_FOR_PROVIDER,
  SET_CATEGORIES_FOR_PROVIDER,
} from "../../../Apis/categories_for_provider_api";
import { rightsUser } from "../../Home/Drawer/DrawerView";
import file from "../../../Assets/Icons for pages/file.png";
import Rate from "../../../Assets/Rate.png";
import CitiesCutOff from "./Options/CitiesCutOff/CitiesCutOff";
import KycDetails from "./Options/KycDetails/KycDetails";
import Roles from "./Options/Roles/Roles";
import QuestingAnswer from "./Options/QuestionAnswer/QuestingAnswer";
import OrderErning from "./Options/OrderErning/OrderErning";
import BankingDetails from "./Options/Banking/Banking";
import RatingsReview from "./Options/RatingsReview/RatingsReview";
import Unavailability from "./Options/Unavalibility/Unavailability";
import ProviderEdit from "../../../Component/Dailog/ProviderEdit";
import { getRoles } from "@testing-library/react";

function ServiceProviderView() {
  const { id } = useParams();
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [data, setData] = useState({
    user: null,
    isLoading: true,
    roles: "",
    form: [],
    kyc: [],
    openReason: false,
    selectedReasonId: 0,
    categoriesForProvider: [],
    catgegoriesName: [],
    selectedCategories: [],
  });

  const [selectedRole, setSelectedRole] = React.useState([]);

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
    }
  }

  const getUser = async () => {
    const r = await GETPROVIDERSDETAILS(id);

    if (r && r.s) {
      const d = [];
      if (r.r) {
        // console.log(params.row.roles);
        for (const v of r.r.sroles) {
          // console.log("role",v.name);
          d.push(" " + v.name);
        }
      }

      setData((p) => ({
        ...p,
        isLoading: false,
        user: r.r,
        roles: d.join(","),
      }));
      if (r.r && r.r.sroles.length) {
        if (!selectedRole.length) {
          setSelectedRole([r.r.sroles[0].name]);
          getFillForm(r.r.sroles[0].id);
        } else {
          console.log(selectedRole[0]);
          console.log(r.r.sroles);
          const c = r.r.sroles.filter(
            (j) => j.name.replace(" ", "") == selectedRole[0].replace(" ", "")
          );
          // console.log(c)
          getFillForm(c[0].id);
        }
      }
    } else {
      setData((p) => ({ ...p, isLoading: false }));
    }
  };

  const getFillForm = async (role) => {
    const res = await GETPROVIDERSFORMDETAILS(id, role);

    if (res && res.s && res.r) {
      setData((p) => ({ ...p, form: res.r }));
    }
  };

  const getKYC = async () => {
    const res = await GETPROVIDERSKYC(id);

    if (res && res.s && res.r) {
      setData((p) => ({ ...p, kyc: res.r }));
    }
  };

  const handleOnClose = async (p, status, id, reaosn) => {
    if (p) {
      const d = await APPROVE_REJECT(status, id, reaosn);

      if (d && d.s) {
        getUser();
        ToastShow(`${status == 1 ? "Approved" : "Rejected"} successfully`);
      } else {
        ToastShow("Something went wrong. Please try agian.");
      }
    }

    setData((p) => ({ ...p, openReason: false }));
  };

  const downloadAggrement = async () => {
    //window.open(API_URL.baseUrl+API_URL.providerAggrementLink+"?apikey="+tokens.apikey+"&token="+tokens.token+"&user_id="+id);

    axios
      .get(
        API_URL.baseUrl +
          API_URL.providerAggrementLink +
          "?apikey=" +
          tokens.apikey +
          "&token=" +
          tokens.token +
          "&user_id=" +
          id,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `agreement_${id}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
    // byteToFile.byteDownload();
  };

  useEffect(() => {
    getUser();
    getKYC();
    getCities();
  }, []);

  const [approveRejectData, setApproveRejectData] = useState({
    id: 1,
    status: 1,
  });

  const [approveOpen, setApproveOpen] = useState(false);
  const [openCities, setOpenCities] = useState(false);
  const [restrctionOpen, setRestrctionOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyId, setHistoryId] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);

  const handleEditClose = (v) => {
    setOpenEdit(false);
    if (v) {
      getUser();
    }
  };

  const handleOnCloseCities = (v) => {
    setOpenCities(false);
    if (v) {
      getUser();
      getKYC();
    }
  };

  const handleApproveOnClose = async (v, status, id, reason) => {
    setApproveOpen(false);
    if (v) {
      await APPROVEREJECTSERVICEPROVIDER(id, status, reason);
      getUser();
      getKYC();
    }
  };

  const handleRestrictionOnClose = async (v) => {
    setRestrctionOpen(false);
    if (v) {
      getUser();
      getKYC();
    }
  };

  const handleRestrictionOnOpen = async () => {
    setRestrctionOpen(true);
  };

  const handleUnRestriction = async () => {
    setData((p) => ({
      ...p,
      isLoading: true,
    }));
    const res = await REMOVE_RESTRICT_USER(data.user.restriction.id);
    if (res && res.s) {
      getUser();
      getKYC();
    } else {
      setData((p) => ({
        ...p,
        isLoading: false,
      }));
      ToastShow("Oops! something went wrong. Please try again.");
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
      {data.isLoading ? (
        <Box
          display="flex"
          width="100%"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : data.user ? (
        <>
          {/*First container*/}
          <Paper
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "6px",
              p: 2,
              boxShadow: "3px 3px 7px 0px #00000040",
            }}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{
                  height: "60px",
                  width: "60px",
                  objectFit: "fill",
                }}
                src={
                  API_URL.baseUrl + API_URL.userProfileImage + data.user.photo
                }
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  ml: 2,
                  gap: "3px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "#252525",
                    fontSize: "16.8px",
                  }}
                >
                  {(data.user.first_name ?? "") +
                    " " +
                    (data.user.middle_name ?? "") +
                    " " +
                    (data.user.last_name ?? "") +
                    " "}
                  ({data.user.gender})
                </Typography>

                <Stack direction="row" gap="1.5rem"></Stack>

                <Typography fontSize="12.5px">
                  {data.user.email ?? ""}
                </Typography>
                <Typography fontSize="12.5px">
                  {data.user.mobile ?? ""}
                </Typography>
                <Typography fontSize="12.5px">
                  {data.user.address ?? ""}
                </Typography>

                <Button
                  sx={{
                    bgcolor: "#FFEACC",
                    gap: "0.5rem",
                    px: "0.8rem",
                    borderRadius: "5px",
                  }}
                  disabled={data.user.provider_agreement_date ? false : true}
                  onClick={downloadAggrement}
                >
                  <img
                    src={file}
                    style={{ objectFit: "fill", width: "14px" }}
                    alt=""
                  />
                  <Typography
                    textTransform="none"
                    fontSize="13.5px"
                    color="#E38800"
                  >
                    Agreement
                  </Typography>
                </Button>
              </Box>
            </Box>

            {/*Button*/}
            <Box display="flex" flexDirection="column" gap={1}>
              <Button
                disabled={data.user.provider_agreement_date ? false : true}
                variant="contained"
                disableElevation
                onClick={
                  data.user.restriction == null
                    ? handleRestrictionOnOpen
                    : handleUnRestriction
                }
                sx={{
                  width: "10.3vw",
                  height: "30%",
                  border: "1px solid #BEBEBE",
                  textAlign: "start",
                  fontSize: "12px",
                  color: "black",
                  borderRadius: "6px",
                  backgroundColor:
                    data.user.restriction == null ? "#ededed" : "#BEBEBE",
                  "&:hover": {
                    backgroundColor: "#D3D3D3",
                  },
                  textTransform: "none",
                }}
              >
                {data.user.restriction == null ? "Disable" : "Enable"}
              </Button>

              {rightsUser.includes(18) ? (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setOpenEdit(true);
                  }}
                  sx={{
                    width: "10.3vw",
                    height: "30%",
                    bgcolor: "#F8B03F",
                    textAlign: "start",
                    fontSize: "12px",
                    borderRadius: "6px",
                    textTransform: "none",
                    color: "black",
                    ":hover": {
                      bgcolor: "#d9a14a",
                    },
                  }}
                >
                  Edit
                </Button>
              ) : null}
            </Box>
          </Paper>

          {/*Details*/}
          <Paper
            sx={{
              width: "100%",
              padding: 1.1,
              borderRadius: "6px",
              boxShadow: "3px 3px 7px 0px #00000040",
              mt: "1rem",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFAB30",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                height: 40,
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                  pl: ".8rem",
                }}
              >
                Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <ItemView key={1} title="ID" value={"#" + data.user.id} />
              <ItemView
                key={2}
                title="Service Area"
                citiesData={data.user.cities ?? []}
                isServiceArea={true}
                setOpenCities={setOpenCities}
              />
            </Box>

            <Box display="flex">
              <ItemView
                key={3}
                title="DOB"
                value={data.user.dob ? data.user.dob : "-"}
                isLastInLeft={true}
              />
              <ItemView
                key={4}
                title="Created At"
                value={
                  data.user.created_at
                    ? new Date(
                        Date.parse(data.user.created_at + " UTC")
                      ).toLocaleString()
                    : "-"
                }
                isLastInRight={true}
              />
            </Box>
          </Paper>

          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              mt: "1rem",
            }}
          >
            <Paper
              sx={{
                width: "25%",
                boxShadow: "3px 3px 7px 0px #00000040",
              }}
            >
              <Box
                sx={{
                  height: "3rem",
                  bgcolor: "#F8AF41",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "12px",
                  borderTopLeftRadius: "6px",
                  borderTopRightRadius: "6px",
                }}
              >
                <Typography fontSize=".9rem" fontWeight="500">
                  Options
                </Typography>
              </Box>
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  pt: "0.6rem",
                }}
              >
                <ListItemButton
                  key={0}
                  onClick={() => setSelectedIndex(0)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 0 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 0 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 0 && "500",
                      fontSize: "15px",
                    }}
                  >
                    Cities Cut-Off
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  key={1}
                  onClick={() => setSelectedIndex(1)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 1 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 1 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 1 && "500",
                      fontSize: "15px",
                    }}
                  >
                    KYC Details
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  key={2}
                  onClick={() => setSelectedIndex(2)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 2 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 2 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 2 && "500",
                      fontSize: "15px",
                    }}
                  >
                    Roles
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  key={3}
                  onClick={() => setSelectedIndex(3)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 3 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 3 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 3 && "500",
                      fontSize: "15px",
                    }}
                  >
                    Question/Answer
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  key={4}
                  onClick={() => setSelectedIndex(4)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 4 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 4 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 4 && "500",
                      fontSize: "15px",
                    }}
                  >
                    Orders & Earnings
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  key={5}
                  onClick={() => setSelectedIndex(5)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 5 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 5 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 5 && "500",
                      fontSize: "15px",
                    }}
                  >
                    Ratings & Review
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  key={6}
                  onClick={() => setSelectedIndex(6)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 6 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 6 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 6 && "500",
                      fontSize: "15px",
                    }}
                  >
                    Unavailability
                  </Typography>
                </ListItemButton>

                <ListItemButton
                  key={7}
                  onClick={() => setSelectedIndex(7)}
                  sx={{
                    width: "100%",
                    height: "2.8rem",
                    color: "transparent",
                    borderBottom: "1px solid #ebe8e8",
                    bgcolor: selectedIndex == 7 && "#F4920026",
                  }}
                >
                  <Typography
                    sx={{
                      color: selectedIndex == 7 ? "#F8B03F" : "black",
                      fontWeight: selectedIndex == 7 && "500",
                      fontSize: "15px",
                    }}
                  >
                    Bank Details
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Paper>

            <Box width="75%" alignSelf="flex-start">
              {selectedIndex == 0 && rightsUser.includes(10) ? (
                <CitiesCutOff city={data.user.cities} citiesW={citiesW} />
              ) : null}

              {selectedIndex == 1 && rightsUser.includes(11) ? (
                <KycDetails kyc={data.kyc} getKYC={getKYC} />
              ) : null}

              {selectedIndex == 2 && rightsUser.includes(12) ? (
                <Roles data={data.user?.roles} id={id} getUser={getUser} />
              ) : null}

              {selectedIndex == 3 && rightsUser.includes(13) ? (
                <QuestingAnswer data={data} id={id} setData={setData} />
              ) : null}

              {selectedIndex == 4 ? (
                <OrderErning userID={data.user.id} />
              ) : null}

              {selectedIndex == 5 ? (
                <RatingsReview userID={data.user.id} />
              ) : null}

              {selectedIndex == 6 ? (
                <Unavailability userID={data.user.id} />
              ) : null}

              {selectedIndex == 7 ? (
                <BankingDetails userID={data.user.id} />
              ) : null}
            </Box>
          </Box>
        </>
      ) : (
        <Typography>No user available </Typography>
      )}
      {data.openReason && (
        <ReasonDailog
          handleOnClose={handleOnClose}
          id={data.selectedReasonId}
          status={-1}
          open={data.openReason}
        />
      )}

      {approveOpen && (
        <ApproveRejectDailog
          open={approveOpen}
          handleOnClose={handleApproveOnClose}
          id={approveRejectData.id}
          status={approveRejectData.status}
        />
      )}

      {historyOpen && (
        <ShowHistoryDailog
          open={historyOpen}
          handleClose={() => {
            setHistoryId(0);
            setHistoryOpen(false);
          }}
          id={historyId}
        />
      )}

      {restrctionOpen && (
        <RestrictUser
          app={2}
          handleOnClose={handleRestrictionOnClose}
          open={restrctionOpen}
          id={id}
          row={data}
        />
      )}

      {openCities ? (
        <AddCityServiceArea
          c={data.user.cities}
          cities={cities}
          citiesW={citiesW}
          open={openCities}
          id={data.user.users_role_ids}
          handleOnClose={handleOnCloseCities}
        />
      ) : null}

      {openEdit && (
        <ProviderEdit
          open={openEdit}
          handleOpenClose={handleEditClose}
          userId={id}
          data={data?.user}
        />
      )}
    </>
  );
}

export default ServiceProviderView;

function ItemView({
  title,
  value,
  isLastInLeft,
  isLastInRight,
  isServiceArea = false,
  setOpenCities = null,
  citiesData = [],
}) {
  return (
    <Box
      sx={{
        width: "100%",
        flexDirection: "row",
        display: "flex",
        borderBottom: "solid 1px #c0c0c07c",
        borderRight: "solid 1px #c0c0c07c",
        borderBottomLeftRadius: isLastInLeft && "6px",
        borderBottomRightRadius: isLastInRight && "6px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#EFEFEF",
          width: "40%",
          height: "35px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Typography fontSize="12px">{title}</Typography>
      </Box>
      <Box
        sx={{
          width: "60%",
          height: "35px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 1,
        }}
      >
        {!isServiceArea ? (
          <Typography fontSize="13px" fontWeight="500">
            {value}
          </Typography>
        ) : null}

        {isServiceArea ? (
          <Box display="flex" width="100%" alignItems="center">
            <Box
              display="flex"
              gap="0.2rem"
              overflow="auto"
              alignItems="center"
            >
              {citiesData.length
                ? citiesData.map((i) => (
                    <Typography
                      fontSize="12px"
                      px="0.2rem"
                      py="0.1rem"
                      fontWeight="500"
                      bgcolor="#CEE5FF"
                      borderRadius="3px"
                      whiteSpace="nowrap"
                    >
                      {i.name}
                    </Typography>
                  ))
                : "Not Available"}
            </Box>

            <IconButton
              onClick={() => setOpenCities(true)}
              sx={{
                ml: "0.2rem",
              }}
              size="small"
            >
              <Add />
            </IconButton>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
