import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { CATEGORYSUBADD } from "../../Apis/category_api";
import { API_URL } from "../../Apis/config";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";
import TextFieldSmall from "../TextFields/TextFieldSmall";
import ButtonView from "../Button/ButtonView";
import { GrClose } from "react-icons/gr";
import TextFieldLarge from "../TextFields/TextFieldLarge";

function AddSubCategory({ open, handleOnClose, row, pId }) {
  const [name, setName] = React.useState(row ? row.name : "");
  const [value, setValue] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [netWorkImage, setNetWorkImage] = React.useState(
    row ? API_URL.baseUrl + API_URL.categoryImage + row.photo : null
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSelectImage = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleOnSubmit = async () => {
    // if (!selectedImage && !netWorkImage) {
    //   ToastShow("Please select the image");
    // } else
    if (!name) {
      ToastShow("Please fill name");
    } else {
      const res = await CATEGORYSUBADD({
        cId: row ? row.id : 0,
        name: name,
        description: value ?? "",
        photo: selectedImage,
        pId: pId,
      });

      if (res && res.s) {
        handleOnClose(1);
        ToastShow("Sub Category added successfully");
      } else {
        ToastShow("Opps! something went wrong. Please try again");
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "6px",
            display: "flex",
            padding: "1rem",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.7rem",
          }}
        >
          <Stack
            width="20rem"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.4rem"
            px="0.7rem"
          >
            <Typography fontWeight="500" sx={{ userSelect: "none" }}>
              Add Sub Categories
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={() => handleOnClose(0)}
            />
          </Stack>
          {/* FIELDS */}

          {/* Image */}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                mb: 1.5,
                mt: 3,
              }}
            >
              {(selectedImage || netWorkImage) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100px",
                    width: "200px",
                    objectFit: "contain",
                    mb: 2,
                  }}
                >
                  <img
                    height="100px"
                    width="auto"
                    src={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : netWorkImage
                    }
                  />
                </Box>
              )}
              <Button
                size="small"
                sx={{ width: "150px", mb: 1 }}
                variant="contained"
                component="label"
              >
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  id="select-image"
                  onChange={handleSelectImage}
                />
                <Typography>Select Image</Typography>
              </Button>
              <Typography variant="subtitle2" mb={2.5}>
                Please select an a png,jpeg,jpg,gif file only.
              </Typography>
            </Box>
          </Box> */}
          <Box
            bgcolor="#F6F6F6"
            width="20rem"
            borderRadius="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py="0.5rem"
          >
            <TextFieldLarge
              label="Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              name="name"
              value={name}
              type="text"
              variant="outlined"
              width="95%"
              placeholder="Enter Sub Categories Name"
            />
          </Box>
          <Stack width="100%" direction="row" gap="0.5rem">
            <ButtonView
              lable="Cancel"
              color="#E50000"
              bgcolor="#E5000033"
              fontSize="14px"
              sx={{ width: "100%" }}
              hoverBg="pink"
              onClick={() => handleOnClose(0)}
            />

            <ButtonView
              lable="Submit"
              fontSize="14px"
              hoverBg="#ffb952"
              sx={{ width: "100%" }}
              onClick={handleOnSubmit}
            />
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}

export default AddSubCategory;
