import React from "react";
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { BsChevronDown } from "react-icons/bs";

function MultiSelection({
  selected,
  handleChange,
  names,
  lable,
  multiple = false,
  size = "small",
  disabled = false,
  width = "100%",
  icon = false,
  containerWidth = "11rem",
  subContainerWidth = "95%",
  disableBoxShadow = false,
  bgcolor = "white",
  noIcon = false,
  paddingY = ".230rem",
}) {
  return (
    <Box
      sx={{
        bgcolor: bgcolor,
        boxShadow: disableBoxShadow ? null : "3px 3px 7px 0px #00000040",
        borderRadius: "5px",
        width: containerWidth,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingY: paddingY,
        gap: ".1rem",
      }}
    >
      <Box width="95%">
        <Box
          sx={{
            display: "flex",
            gap: ".3rem",
            alignItems: "center",
          }}
        >
          {noIcon ? null : !icon ? (
            <IconButton
              sx={{
                borderRadius: "0",
                padding: "0",
              }}
            >
              <img
                src={require("../../Assets/User-selection-input.png")}
                style={{ objectFit: "fill" }}
                alt=""
              />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                borderRadius: "0",
                padding: "0",
              }}
            >
              <img
                src={icon}
                style={{ objectFit: "fill", width: "11px" }}
                alt=""
              />
            </IconButton>
          )}
          <Typography fontSize="10px">{lable}</Typography>
        </Box>
      </Box>

      <Box width={subContainerWidth}>
        <FormControl
          sx={{
            width: width,
            borderRadius: "5px",
            "& fieldset": { border: "none" },
            "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                fontSize: "0.720rem",
              },
            "& .css-3qbkez-MuiSelect-icon": {
              width: "0.688rem",
            },
            "& .css-10q54uo-MuiSelect-icon": {
              width: "0.688rem",
            },
            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                fontSize: "0.720rem",
              },
          }}
        >
          {/* <InputLabel id="demo-multiple-checkbox-label">{lable}</InputLabel> */}
          <Select
            size={size}
            disabled={disabled}
            defaultValue=""
            multiple={multiple}
            value={selected}
            onChange={handleChange}
            input={<OutlinedInput label={lable} />}
            renderValue={(select) => select.join(", ")}
            style={{
              backgroundColor: bgcolor === "white" ? "#F6F6F6" : "white",
              color: "#969696",
              borderRadius: "6px",
              height: "1.7rem",
            }}
            MenuProps={{ MenuListProps: { disablePadding: true } }}
            IconComponent={BsChevronDown}
          >
            {names.map((name, i) => (
              <MenuItem
                key={i}
                value={name}
                sx={{
                  boxShadow: "0px 0.3px 0px 0px #00000040",
                }}
              >
                <Checkbox
                  sx={{ padding: "0", marginRight: ".4rem" }}
                  icon={
                    <IconButton
                      sx={{
                        border: "1px solid black",
                        padding: "0",
                        border: "0",
                      }}
                    >
                      <img
                        src={require("../../Assets/Unchecked_2.png")}
                        style={{ width: "13px", objectFit: "fill" }}
                        alt=""
                      />
                    </IconButton>
                  }
                  checkedIcon={
                    <IconButton
                      sx={{
                        border: "1px solid black",
                        padding: "0",
                        border: "0",
                      }}
                    >
                      <img
                        src={require("../../Assets/CheckedIcon.png")}
                        style={{ width: "13px", objectFit: "fill" }}
                        alt=""
                      />
                    </IconButton>
                  }
                  checked={selected.indexOf(name) > -1}
                />
                <Typography fontSize="14px">{name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default MultiSelection;
