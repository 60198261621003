import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import ToastView, { ToastShow } from "../Toast/Toast";
  import Compressor from "compressorjs";
  import { PhotoCamera } from "@mui/icons-material";
  import { API_URL } from "../../Apis/config";
  import { ADD_UPDATE_NEW_ON_SPOT_GALLERY } from "../../Apis/new_on_spot_api";
  
  function AddUpdateNewGalleryDailog({ open, handleOnClose, on_spot_id, row }) {
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [selectedImageURL, setSelectedImageURL] = React.useState(row?.file ??null);
    const [selectedImageCover, setSelectedImageCover] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleOnSubmit = async () => {
      if (!selectedImage && !selectedImageURL ) {
        ToastShow("Please select image");
      } else {
        setIsLoading(true);
        const d = await ADD_UPDATE_NEW_ON_SPOT_GALLERY({
          file: selectedImage,
          id: row?.id ?? 0,
          on_spot_id:on_spot_id,
        });
        setIsLoading(false);
        if (d && d.s) {
          ToastShow("Successfully added");
          handleOnClose(1);
        } else {
          ToastShow("Something went wrong. Please try again.");
          handleOnClose(0);
        }
      }
    };
  
    return (
      <>
        <Dialog open={open} onClose={() => handleOnClose(0)}>
          <DialogTitle>{!row?.id ? "Add Gallery" : "Update Gallery"}</DialogTitle>
          <DialogContent
            sx={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "150px",
                width: "150px",
                border: "dotted .3px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                objectFit: "fill",
                p: "1px",
              }}
            >
              
              {(selectedImage || selectedImageURL) ? (
                <img
                  style={{ borderRadius: "5px" }}
                  height="150px"
                  width="150px"
                  src={
                  selectedImage
                     ? URL.createObjectURL(selectedImage)
                     : API_URL.baseUrl + API_URL.onspot2GalleryLogo + selectedImageURL
  
                  }
                  alt="banner image"
                />
              ) : (
                <Typography>Select Image</Typography>
              )}
            </Box>
  
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(event) => {
                  if (event.target.files) {
                    new Compressor(event.target.files[0], {
                      quality: 0.8,
                      success: (compressedResult) => {
                        setSelectedImage(compressedResult);
                      },
                    });
                    new Compressor(event.target.files[0], {
                      quality: 0.5,
                      success: (compressedResult) => {
                        setSelectedImageCover(compressedResult);
                      },
                    });
  
                    setSelectedImageURL(null);
                  }
                }}
              />
              <PhotoCamera />
            </IconButton>
  
          </DialogContent>
  
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleOnSubmit} variant="text">
                Submit
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastView />
      </>
    );
  }
  
  export default AddUpdateNewGalleryDailog;
  