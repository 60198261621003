import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import ToastView, { ToastShow } from "../Toast/Toast";
  import Compressor from "compressorjs";
  import { Delete, PhotoCamera } from "@mui/icons-material";
import { ADD_UPDATE_NEW_ON_SPOT_GALLERY } from "../../Apis/new_on_spot_api";
  
  function NewSponserGalleryMultiDailog({
    open,
    handleOnClose,
    on_spot_id,
  }) {
    const [isLoading, setIsLoading] = useState(false);
  
    const [selectMedia, setSelectMedia] = React.useState([]);
  
  
    const handleOnSubmit = async () => {
      if (!selectMedia.length) {
        ToastShow("Please select the media");
      } else {   
        setIsLoading(true);
  
        for (let i of selectMedia) {
            const d = await ADD_UPDATE_NEW_ON_SPOT_GALLERY({
                file: i.media,
                id:0,
                on_spot_id:on_spot_id,
              });
        }
  
        setIsLoading(false);
        handleOnClose(1);
       
      }
    };

  
   
  
    return (
      <>
        <Dialog open={open} onClose={() => handleOnClose(0)}>
          <DialogContent
            sx={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <DialogTitle>
                Add Gallery
              </DialogTitle>
  
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      if (event.target.files.length) {
                        const d = {};
                        d.type = 1;
                        new Compressor(event.target.files[0], {
                          quality: 0.8,
                          success: (compressedResult) => {
                            d.id = Date.now();
                            d.media = compressedResult;
                            d.showMedia = URL.createObjectURL(compressedResult);
                            event.target.value = null;
                            setSelectMedia((p) => [...p, d]);
                          },
                        });
                      }
                    }}
                  />
                  <PhotoCamera />
                </IconButton>
              </Box>
            </Box>
  
            <Box
              sx={{
                width: "400px",
                height: "70%",
                display: "flex",
                flexWrap: "wrap",
                overflowY: "auto",
                flexDirection: "row",
                justifyContent: "start",
                maxHeight: "450px",
              }}
            >
              {selectMedia.length ? (
                selectMedia.map((v, i) => {
                  // console.log(v)
                  return (
                    <Box
                      key={v.id}
                      sx={{
                        height: "100%",
                        width: "150px",
                        borderRadius: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        objectFit: "fill",
                        m: "8px",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <img
                        height="150px"
                        width="150px"
                        src={v.showMedia}
                        alt="media"
                      />
  
                      <IconButton
                        onClick={() => {
                          const d = selectMedia.filter((t) => t.id != v.id);
                          setSelectMedia(d);
                        }}
                        sx={{
                          position: "absolute",
                          bottom: "10px",
                          right: "0px",
                        }}
                      >
                        <Delete
                          sx={{
                            backgroundColor: "#c0c0c06a",
                            p: 0.5,
                            borderRadius: "50%",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </Box>
                  );
                })
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ textAlign: "center", alignSelf: "center" }}>
                    Please select the media
                  </Typography>
                </Box>
              )}
            </Box>

          </DialogContent>
  
          <DialogActions>
            {isLoading ? (
              <CircularProgress  size="15px"/>
            ) : (
              <Button onClick={handleOnSubmit} variant="text">
                Submit
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastView />
      </>
    );
  }
  
  export default NewSponserGalleryMultiDailog;
  