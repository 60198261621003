import { Box, Tooltip, Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import React from "react";

const ChipsField = ({
  chips,
  handleChange,
  label,
  sx = {},
  required = false,
  helperText = "",
  width,
}) => {
  return (
    <Tooltip title="You can add multiple names on Enter">
      <Box
        sx={{
          width: { width },
          bgcolor: "white",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          pt: "0.2rem",
          pb: "0.350rem",
        }}
      >
        <Box width="95%">
          <Typography
            fontSize="0.8rem"
            mb="0.2rem"
            style={{ userSelect: "none" }}
          >
            Synonyms name
          </Typography>
        </Box>
        <MuiChipsInput
          aria-multiline
          required={required}
          size="small"
          sx={{
            ...sx,
          }}
          label={label}
          placeholder={label}
          value={chips}
          onChange={handleChange}
          helperText={helperText}
          color="divider"
          FormHelperTextProps={{ color: "divider" }}
        />
      </Box>
    </Tooltip>
  );
};

export default ChipsField;
