import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_WEB_PODIAN_PROCESS = async (data) => {
  const formData = new FormData();

  if (data?.row) {
    formData.append("id", data.row.id);
  }
  formData.append("name", data?.name);
  formData.append("details", data?.details);
  formData.append("sr", data?.sr);
  

  const res = await POSTAPI(
     API_URL.updateJoinProcessPodian,
    formData
  );
  return res;
};

export const GET_WEB_PODIAN_PROCESS = async () => {
  const res = await GETAPI(API_URL.getJoinProcessPodian);
  return res;
};
