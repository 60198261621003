import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ADD_ON_SHOT } from "../../Apis/on_shot_api";
import { format } from "date-fns";
import { TimePicker } from "@mui/x-date-pickers";
import { ADD_UPDATES_ROLE } from "../../Apis/podian_api";
import ButtonView from "../Button/ButtonView";
import { GrClose } from "react-icons/gr";
import TextFieldLarge from "../TextFields/TextFieldLarge";

function AddUpdateRoleDailog({ open, handleOnClose, row }) {
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = React.useState(row ? row.name : "");

  const [reportTime, setReportTime] = React.useState(
    row
      ? Date.parse("2021-01-01" + " " + row.reporting_time)
      : Date.parse("2021-01-01" + " " + "00:15:00")
  );

  const handleOnSubmit = async () => {
    if (!name.length) {
      ToastShow("Please enter name");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATES_ROLE({
        id: row?.id ?? 0,
        name: name,
        report_time: reportTime ? format(reportTime, "HH:mm:ss") : null,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Success");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Stack gap={1.5} m={1}>
          <Stack
            width="100%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.8rem"
            px="0.8rem"
          >
            <Typography
              fontWeight="500"
              fontSize="15px"
              sx={{ userSelect: "none" }}
            >
              {!row?.id ? "Add Roles" : "Update Roles"}
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={() => handleOnClose(0)}
            />
          </Stack>

          <TextFieldLarge
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Title"
            placeholder="Enter title"
            width="300px"
          />

          <Box width="300px" bgcolor="#F6F6F6" pt={1.5} pb={0.6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                inputFormat="HH:mm:ss"
                label="Report Time"
                value={reportTime}
                onChange={(v) => {
                  setReportTime(v);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "98%",
                      height: "2.3rem",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    fullWidth
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <ButtonView
                lable={row ? "Update" : "Submit"}
                fontSize="14px"
                hoverBg="#ffb952"
                sx={{ width: "100%" }}
                onClick={handleOnSubmit}
              />
            )}
          </DialogActions>
        </Stack>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddUpdateRoleDailog;
