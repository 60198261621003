import { Button, Typography } from "@mui/material";
import React from "react";

const IconButtomSmall = ({
  buttonStyle,
  icon,
  imgStyle,
  label,
  fontSize = "10.5px",
  onClick,
}) => {
  return (
    <Button
      sx={{
        ...buttonStyle,
        display: "flex",
        gap: "0.2rem",
        alignItems: "center",
        textTransform: "capitalize",
        "&:hover": {
          bgcolor: "#c2a276",
        },
      }}
      onClick={onClick}
    >
      <img src={icon} style={{ objectFit: "fill", ...imgStyle }} />
      <Typography fontSize={fontSize}>{label}</Typography>
    </Button>
  );
};

export default IconButtomSmall;
