import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_URL } from "../../Apis/config";
import { GET_USER_BASIC_DETAILS } from "../../Apis/users_api";
import ReactLinkify from "react-linkify";

function ChatSenderView({ userId, message, createdAt, role, type }) {
  const [user, setUser] = useState(null);

  const getUserDetails = async () => {
    if (userId) {
      const d = await GET_USER_BASIC_DETAILS(userId);
      if (d && d.s) {
        setUser(d.r);
      }
    }
  };

  const formatDate = (dateString) => {
    // console.log("date",dateString)
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="start"
      mt={0.5}
      p={1}
      sx={{
        borderBottom: "solid .3px",
        borderColor: "divider",
        borderRadius: "4px",
      }}
    >
      {(user || !userId) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            src={
              userId == 0
                ? null
                : API_URL.baseUrl + API_URL.userProfileImage + user.photo
            }
            sx={{ mr: 1, height: 30, width: 30 }}
          />
          <Typography sx={{ fontWeight: "600" }}>
            {userId == 0 ? "You" : user.first_name + " " + user.last_name}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "12px", ml: 0.4 }}>
            ({userId == 0 ? "Admin" : role == 1 ? "User" : "Provider"})
          </Typography>
        </Box>
      )}
      {type == 1 ? (
        <ReactLinkify>
          <Typography variant="subtitle2" sx={{ ml: 5 }}>
            {message}
          </Typography>
        </ReactLinkify>
      ) : (
        <Box
          onClick={() => window.open(message)}
          sx={{
            cursor: "pointer",
          }}
        >
          <img
            src={message}
            style={{
              height: "100%",
              width: "100%",
              marginBlock: "15px",
            }}
          />
        </Box>
      )}

      <Typography
        variant="subtitle2"
        sx={{
          ml: 5,
          textAlign: "right",
          fontSize: "10px",
        }}
      >
        {formatDate(createdAt.toDate().toString())}
      </Typography>
    </Box>
  );
}

export default ChatSenderView;
