import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ExpandView from "./ExpandView";
import { MenuItems } from "../MenuList";
import ItemView from "./ItemView";
import logo from "../../../Assets/favicon-96x96.png";
import { motion } from "framer-motion";
import { GET_MY_ROLES } from "../../../Apis/users_api";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "../../../Modules/drawer.module.css";
import equitysoft_logo from "../../../Assets/Icons for pages/equitysoft_logo.png";

export const rightsUser = [];

function DrawerView({ handleDrawer, openD, drawerValue }) {
  const [modules, setModule] = useState([]);
  const navigator = useNavigate();

  const { pathname } = useLocation();

  const getData = async () => {
    const data = await GET_MY_ROLES();

    if (data && data.s) {
      const t = [];
      for (const v of data.r.modules) {
        t.push(v.name);
      }

      const r = [];
      for (const v of data.r.rights) {
        r.push(v.id);
      }
      rightsUser.push(...r);

      setModule([...t]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const checkAuthentication = async (path) => {
    try {
      const data = await GET_MY_ROLES();

      if (data && data.s) {
        const t = [];
        for (const v of data.r.modules) {
          t.push(v.name);
        }

        let c =
          path.toLowerCase() == "notifications" ||
          path.toLowerCase() == "orderissue" ||
          path.toLowerCase() == "tickets" ||
          path.toLowerCase() == "service-template" ||
          path.toLowerCase() == "master" ||
          path.toLowerCase() == "create-order"
            ? 1
            : 0;

        for (const check of t) {
          // console.log("data",path.toLowerCase(),"-",check.toLowerCase().replace(" ", "-"));
          if (
            path.toLowerCase() != "notifications" ||
            path.toLowerCase() != "orderissue" ||
            path.toLowerCase() != "tickets" ||
            path.toLowerCase() != "service-template" ||
            path.toLowerCase() != "master" ||
            path.toLowerCase() != "create-order"
          ) {
            if (path.toLowerCase() == check.toLowerCase().replace(" ", "-")) {
              c = 1;
            }
          }
        }
        //  console.log("counter",c,path.toLowerCase());
        if (c == 0) {
          localStorage.clear();
          navigator("/", { replace: true });
        }
      } else {
        localStorage.clear();
        navigator("/", { replace: true });
      }
    } catch (e) {
      localStorage.clear();
      navigator("/", { replace: true });
    }
  };

  useEffect(() => {
    if (pathname !== "/" && pathname != "/otp-verify") {
      // console.log("path", pathname.split("/")[1]);
      checkAuthentication(pathname.split("/")[1]);
    }
  }, [pathname]);

  // MATERIAL UI SETUP
  return (
    <div
      style={{ zIndex: "1" }}
      onMouseLeave={() => {
        if (!drawerValue) {
          handleDrawer(1, false);
        }
      }}
      onMouseEnter={() => {
        if (!drawerValue) {
          handleDrawer(1, true);
        }
      }}
    >
      <Paper
        component={motion.div}
        animate={{
          width: openD ? "270px" : "80px",
        }}
        elevation={!drawerValue && openD ? 10 : 0}
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          transition: "all .05 ease-in-out",
          ...(!drawerValue && { position: "absolute" }),
        }}
      >
        <BrandView handleDrawer={handleDrawer} openD={openD} />
        <BottomListView openD={openD} modules={modules} />
      </Paper>
    </div>
  );
}

export default DrawerView;

function BrandView({ handleDrawer, openD }) {
  return (
    <Box
      sx={{
        height: "4rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
        bgcolor: "#1C1000",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          objectFit: "fill",
          gap: ".5rem",
        }}
      >
        <Avatar alt="logo" src={logo} sx={{ width: 32, height: 32 }} />
        <Typography
          sx={{
            color: "whitesmoke",
            fontWeight: "bold",
            fontSize: "1.4rem",
          }}
        >
          POD
        </Typography>
      </Box>
    </Box>
  );
}

function BottomListView({ openD, modules }) {
  const [expandedIndex, setExpandedIndex] = useState(null);

  return (
    <Box
      className={Styles.drawer}
      sx={{
        flex: "1",
        width: openD ? "230px" : "80px",
        pt: 0.8,
        overflowY: "auto",
        width: "100%",
        bgcolor: "#1C1000",
      }}
    >
      {MenuItems.map((v) => {
        if (
          !modules.includes(v.title) &&
          v.title != "Order Issue" &&
          v.title != "Master"
        ) {
          return null;
        }
        return v.isExpand ? (
          <ExpandView
            item={v}
            key={v.id}
            openD={openD}
            expandedIndex={expandedIndex}
            setExpandedIndex={setExpandedIndex}
          />
        ) : (
          <ItemView
            item={v}
            key={v.id}
            openD={openD}
            changeParent={false}
            expandedIndex={expandedIndex}
            setExpandedIndex={setExpandedIndex}
          />
        );
      })}

      <Stack
        sx={{
          mt: "3rem",
          mb: "1.3rem",
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.6rem",
        }}
      >
        <Typography color="#dbd9d9" fontSize="0.9rem">
          Managed by
        </Typography>
        <img
          src={equitysoft_logo}
          style={{ objectFit: "fill", width: "4.8rem" }}
        />
      </Stack>
    </Box>
  );
}
