import "./CustomSwitch.css";

function SwitchRadioButton3({
  isChecked,
  handleChange,
  serviceID,
  applyChanges = () => {},
}) {
  const toggleSwitch = () => {
    handleChange(!isChecked);
    applyChanges();
  };

  return (
    <div
      className={`custom-switch ${isChecked ? "checked" : ""}`}
      onClick={toggleSwitch}
    >
      <div className="custom-switch-slider"></div>
    </div>
  );
}

export default SwitchRadioButton3;
