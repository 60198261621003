import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../../Toast/Toast";
import Compressor from "compressorjs";
import { API_URL } from "../../../Apis/config";
import { ADD_UPDATE_WEB_CITY } from "../../../Apis/web/web_city_api";

function AddUpdateWebCityDailog({ open, handleOnClose, row }) {
  // console.log(row)
  const [photo, setPhoto] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [name, setName] = React.useState(row ? row.name : "");
  const [minShoots, setMinShoots] = React.useState(row ? row.min_shoots : "");

  const handleOnSubmit = async () => {
    if (!row && !photo) {
      ToastShow("Please select photo");
    } 
    // else if (!name) {
    //   ToastShow("Please enter name");
    // } 
    else if (!minShoots) {
      ToastShow("Please enter min shoots");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_WEB_CITY({
        photo: photo,
        row: row,
        // name: name,
        min_shoots: minShoots,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!row ? "Add City" : "Update City"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* photo one */}
          <label
          htmlFor="photoone"
          style={{
            width:"100%"
          }}
          >
          <Box
            sx={{
              height: "150px",
              width:"85%",
              border: "dotted .3px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "fill",
              p: "1px",
              mx:"auto"
            }}
          
          >
            {row?.photo || photo ? (
              <img
                style={{ borderRadius: "5px" }}
                height="150px"
                width="100%"
                src={
                  photo
                    ? URL.createObjectURL(photo)
                    : API_URL.baseUrl + API_URL.webCityImage + row?.photo
                }
                alt="photo image"
              />
            ) : (
              <Typography sx={{width:"85%"}}>Click here to select the photo</Typography>
            )}
          </Box>
          </label>

          <input
              hidden
              id="photoone"
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      setPhoto(compressedResult);
                    },
                  });
                }
              }}
            />

          {/* photo two */}

          {/* <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          /> */}

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            type="number"
            size="small"
            fullWidth
            label="Minimum Shoots"
            value={minShoots}
            onChange={(e) => setMinShoots(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text" sx={{color:"#F8B03F"}}>
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text" sx={{color:"#F8B03F"}}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddUpdateWebCityDailog;
