import { useState } from "react";
import { ImPause } from "react-icons/im";
import play_icon from "../../../../Assets/Icons for pages/play_icon.png";
import { useRef } from "react";
import { API_URL } from "../../../../Apis/config";

const PlayVoice = ({ audioUrl }) => {

  console.log("audio ==> ",)

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const toggleAudio = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio( audioUrl);
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <button
      onClick={toggleAudio}
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        userSelect: "none",
      }}
    >
      {isPlaying ? (
        <ImPause fontSize="16px" color="#5C5C5C" />
      ) : (
        <img
          src={play_icon}
          alt=""
          style={{ width: "16px", objectFit: "fill" }}
        />
      )}
    </button>
  );
};

export default PlayVoice;
