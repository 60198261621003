import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { API_URL } from "../../../../Apis/config";

const Details = ({
  data,
  showBalance = false,
  showCoin = false,
  showWallet = false,
  showOrder = false,
}) => {
  return (
    <Box
      width="100%"
      bgcolor="white"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFAB30",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          {showBalance && "Profile Info"}
          {showCoin && "Coin Details"}
          {showWallet && "Wallet Details"}
          {showOrder && "Order Details"}
        </Typography>
      </Box>

      {/*Rows*/}
      {showBalance && (
        <>
          <Row label="Photo" prop={data.photo} isPhoto={true} />
          <Row
            label="Full Name"
            prop={data.first_name + " " + data.last_name}
          />
          <Row label="Mobile No." prop={data.mobile} />
        </>
      )}

      {showCoin && (
        <>
          <Row label="Coin" prop={data.coin} />
          <Row label="Coin Refunded" prop={data.coin_refunded} />
          <Row label="Referred" prop={data.referred} />
        </>
      )}

      {showWallet && (
        <>
          <Row label="Balance" prop={data.money} />
          <Row
            label="Money Unlocked"
            prop={
              data.money_anlocked
                ? parseFloat(data.money_anlocked.toString()).toFixed(2)
                : "-"
            }
          />
          <Row
            label="Money Available"
            prop={
              data.money_available
                ? parseFloat(data.money_available.toString()).toFixed(2)
                : "-"
            }
          />
          <Row
            label="Money Withdraw"
            prop={
              data.money_widthow
                ? parseFloat(data.money_widthow.toString()).toFixed(2)
                : "-"
            }
            isLastRow={true}
          />
          <Row
            label="Total Credit"
            prop={
              data.total_credit
                ? parseFloat(data.total_credit.toString()).toFixed(2)
                : "-"
            }
          />
          <Row
            label="Total Debit"
            prop={
              data.total_debit
                ? parseFloat(data.total_debit.toString()).toFixed(2)
                : "-"
            }
          />
          <Row
            label="Total Lock"
            prop={
              data.total_lock
                ? parseFloat(data.total_lock.toString()).toFixed(2)
                : "-"
            }
            isLastRow={true}
          />
        </>
      )}

      {showOrder && (
        <>
          <Row label="Cancelled Orders" prop={data.canceled_orders} />
          <Row label="Future Orders" prop={data.future_orders} />
          <Row label="Past Orders" prop={data.past_orders} />
          <Row label="Total Orders" prop={data.total_orders} isLastRow={true} />
        </>
      )}
    </Box>
  );
};

export default Details;

function Row({ label, prop, isLastRow = false, isPhoto = false }) {
  return (
    <Stack width="100%" direction="row">
      <Box
        width="30%"
        bgcolor="#EFEFEF"
        borderBottom="1px solid white"
        display="flex"
        alignItems="center"
        paddingY="0.4rem"
        pl="0.8rem"
        sx={{ borderBottomLeftRadius: isLastRow && "5px" }}
      >
        <Typography fontSize="13px">{label}</Typography>
      </Box>
      <Box
        width="70%"
        bgcolor="white"
        borderBottom="1px solid #EFEFEF"
        display="flex"
        alignItems="center"
        paddingY="0.4rem"
        pl="1.1rem"
        sx={{
          borderBottomRightRadius: isLastRow && "5px",
        }}
      >
        {!isPhoto ? (
          <Typography
            fontSize="13px"
            fontWeight="500"
            sx={{
              color: "black",
              flex: 1,
            }}
          >
            {prop === null ? "-" : prop}
          </Typography>
        ) : (
          <Avatar
            sx={{ width: "1.9rem", height: "1.9rem", objectFit: "fill" }}
            src={API_URL.baseUrl + API_URL.userProfileImage + prop}
          />
        )}
      </Box>
    </Stack>
  );
}
