import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, {memo, useEffect, useState } from "react";
import { GET_WEB_PODIANIENS } from "../../../Apis/web/podian_of_month_api";

function PodianSelection({ open, handleOnClose }) {
  const [search, setSearch] = useState("");
  const [row, setRow] = useState([]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (search.length) {
        const data = await GET_WEB_PODIANIENS({ search });
        if (data && data.s) {
          setRow(data?.r ?? []);
        } else {
          setRow([]);
        }
      } else {
        setRow([]);
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [search]);
  return (
    <Dialog open={open} onClose={() => handleOnClose(0)}>
      <DialogTitle>Select Podian</DialogTitle>
      <DialogContent
        sx={{
          width: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{
            width: "100%",
            mt: 2,
          }}
          type="text"
          size="small"
          fullWidth
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {row.length ? (
          <Box
          sx={{
            maxWidth:"500px",
            overflowY:"auto",
            display:"flex",
            flexDirection:"column",
            alignItems:'flex-start',
            width:"100%",
            paddingBlock:"10px"
          }}
          >
            {row.map((v, i) => {
              return <Typography 
              onClick={()=>{handleOnClose({id:v.id,name:`${v.first_name} - ${v.last_name}`})}}
              sx={{
                border:"solid 1px #c0c0c0",
                p:"6px",
                width:"100%",
                borderRadius:"5px",
                cursor:"pointer"
              }}
              key={i}>{v.first_name} - {v.last_name}</Typography>;
            })}
          </Box>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => handleOnClose(0)}
          variant="text"
          sx={{ color: "#F8B03F" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PodianSelection;
