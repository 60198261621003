import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_WEB_OUR_VALUE = async (data) => {
  const formData = new FormData();

  if (data?.logo) {
    formData.append("logo", data.logo, "logo.png");
  }

  if (data?.row) {
    formData.append("id", data.row.id);
  }

  formData.append("name", data?.name);
  formData.append("description", data?.description);

  const res = await POSTAPI(
    data?.row?.id ? API_URL.webUpdateValue : API_URL.webAddValue,
    formData
  );
  return res;
};

export const GET_WEB_OUR_VALUE = async () => {
  const res = await GETAPI(API_URL.webGetValue);
  return res;
};

export const DELETE_WEB_OUR_VALUE = async (id) => {
    const res = await GETAPI(API_URL.webDeleteValue,"&id="+id);
    return res;
  };
