import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";

function DataGrideView({
  rows,
  columns,
  width = "100%",
  isDisplayFooter = "block",
  callBack = () => {},
  isLoading = false,
  sx = {},
  columnColor = "white",
  colHeight = "3.8rem",
  hideFooter = false,
  autoHeight = false,
  rowsLimit = 10,
  getRowId,
  cc = 0,
  cleanRows = true,
}) {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(rowsLimit);

  return (
    // <SplitButton title={"a"} />
    <Box
      sx={{
        width: width,
      }}
    >
      {/* //declare type GridNativeColTypes = 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect'; */}
      {/* declare type GridAlignment = 'left' | 'right' | 'center'; */}

      {cc ? (
        <DataGrid
          rowCount={cc}
          loading={isLoading}
          // getRowId={(row) => row.statId}
          rows={rows}
          columns={columns}
          onPageChange={(np) => {
            callBack((np + 1) * pageSize);
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          rowsPerPageOptions={[10, 15, 25]}
          componentsProps={{
            pagination: { classes: null },
          }}
          headerHeight={40}
          getRowId={getRowId}
          getRowHeight={() => "auto"}
          hideFooter={hideFooter}
          autoHeight={autoHeight}
          disableSelectionOnClick
          sx={{
            height: "100vh",
            ...sx,
            overflow: "visible",
            fontSize: "12.4px",
            border: "none",
            bgcolor: "white",
            "& .MuiDataGrid-cell.MuiDataGrid-cell:hover, .MuiDataGrid-cellText":
              {
                color: "black !important", // Change the text color on hover (optional)
              },
            "& .css-7jdnmo-MuiTypography-root": {
              fontSize: "12px",
            },
            "& .MuiDataGrid-main, .css-204u17-MuiDataGrid-main": {
              borderLeft: "1px solid #f0f0f0",
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
            },
            "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
              borderRight: `0px solid ${
                theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
              }`,
              height: autoHeight ? null : colHeight,
            },
            "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
              borderBottom: `1px solid ${
                theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
              }`,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F8AF41",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
              textAlign: "center",
            },
            "& .MuiDataGrid-footerContainer": {
              display: isDisplayFooter,
              backgroundColor: "white",
            },
            "& .MuiDataGrid-cell": {
              borderColor: "divider",
              overflow: "visible",
              backgroundColor: columnColor,
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-columnHeader": {
              borderColor: "divider",
            },
            "& .MuiDataGrid-columnsContainer": {
              borderColor: "divider",
            },
            /*For data grid status texts*/
            "& .MuiBox-root, .css-1ky2z5w": {
              border: "none",
              padding: 0,
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: ".3rem",
            },
            "& .css-cizlsw-MuiDataGrid-root, .MuiDataGrid-columnHeader": {
              position: "unset",
            },
          }}
        />
      ) : (
        <DataGrid
          loading={isLoading}
          rows={
            cleanRows
              ? rows
              : rows.map((row, index) => ({ ...row, id: index + 1 }))
          }
          columns={columns}
          onPageChange={(np) => {
            callBack((np + 1) * pageSize);
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          rowsPerPageOptions={[10, 15, 25]}
          componentsProps={{
            pagination: { classes: null },
          }}
          headerHeight={40}
          getRowId={getRowId}
          getRowHeight={() => "auto"}
          hideFooter={hideFooter}
          autoHeight={autoHeight}
          disableSelectionOnClick
          sx={{
            height: "100vh",
            ...sx,
            overflow: "visible",
            fontSize: "12.4px",
            border: "none",
            bgcolor: "white",
            "& .MuiDataGrid-cell.MuiDataGrid-cell:hover, .MuiDataGrid-cellText":
              {
                color: "black !important", // Change the text color on hover (optional)
              },
            "& .css-7jdnmo-MuiTypography-root": {
              fontSize: "12px",
            },
            "& .MuiDataGrid-main, .css-204u17-MuiDataGrid-main": {
              borderLeft: "1px solid #f0f0f0",
              borderRight: "1px solid #f0f0f0",
              borderBottom: "1px solid #f0f0f0",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
            },
            "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
              borderRight: `0px solid ${
                theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
              }`,
              height: autoHeight ? null : colHeight,
            },
            "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
              borderBottom: `1px solid ${
                theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
              }`,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F8AF41",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
              textAlign: "center",
            },
            "& .MuiDataGrid-footerContainer": {
              display: isDisplayFooter,
              backgroundColor: "white",
            },
            "& .MuiDataGrid-cell": {
              borderColor: "divider",
              overflow: "visible",
              backgroundColor: columnColor,
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-columnHeader": {
              borderColor: "divider",
            },
            "& .MuiDataGrid-columnsContainer": {
              borderColor: "divider",
            },
            /*For data grid status texts*/
            "& .MuiBox-root, .css-1ky2z5w": {
              border: "none",
              padding: 0,
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: ".3rem",
            },
            "& .css-cizlsw-MuiDataGrid-root, .MuiDataGrid-columnHeader": {
              position: "unset",
            },
          }}
        />
      )}
    </Box>
  );
}

export default DataGrideView;
