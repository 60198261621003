import { Box } from "@mui/system";
import { MuiChipsInput } from "mui-chips-input";
import React from "react";

function TextFieldChip({
  chips,
  handleChange,
  label,
  sx = {},
  required = false,
  helperText = "",
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        ...sx,
        // "& .MuiFormHelperText-root": {
        //   color: "subtitle1",
        //   fontSize: "15px",
        // },
      }}
    >
      <MuiChipsInput
        aria-multiline
        required={required}
        size="small"
        sx={{ ...sx }}
        label={label}
        placeholder={label}
        value={chips}
        onChange={handleChange}
        helperText={helperText}
        color="divider"
        FormHelperTextProps={{ color: "divider" }}
      />
    </Box>
  );
}

export default TextFieldChip;
