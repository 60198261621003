import { API_URL, GETAPI, POSTAPI } from "../config";

export const UPDATE_MASTER = async (data) => {
  const formData = new FormData();

  if (data?.photo) {
    formData.append("value", data.photo, "photo.png");
  } else {
    if (data.row.data_type.toLowerCase() != "photo") {
      formData.append("value", data.value);
    }
  }

  if (data?.row) {
    formData.append("id", data.row.id);
  }

  // formData.append("name", data?.name);
  formData.append("name", data?.name);

  const res = await POSTAPI(API_URL.masterUpdate, formData);
  return res;
};

export const GET_MASTER = async () => {
  const res = await GETAPI(API_URL.masterGetList);
  return res;
};
