import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_VIDEO_STORIES = async (count, id) => {
  const res = await GETAPI(
    API_URL.getVideoStories,
    "&city_id=" + id + "&count=" + count
  );
  return res;
};

export const ADD_VIDEO_STORY = async (data) => {
  const formData = new FormData();

  if (data.file) {
    formData.append("file", data.file, "file.mp4");
  }

  if (data.url) {
    formData.append("file", data.url);
  }

  if (data.thumb) {
    formData.append("thumb", data.thumb, "file.png");
  }

  formData.append("city_id", data.city_id);
  formData.append("title", data.title);

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updateVideoStory : API_URL.addVideoStory,
    formData
  );
  return res;
};

export const DELETE_VIDEO_STORY = async (id) => {
  const res = await GETAPI(API_URL.deleteVideoStory, "&id=" + id);
  return res;
};
