import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const BookingDetailsTable = ({
  id,
  name,
  phone,
  booking_time,
  gst_no,
  business_name,
  for_someone_name,
  for_someone_num,
}) => {
  return (
    <Box
      width="29vw"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFAB30",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          Booking Details
        </Typography>
      </Box>
      {/*Rows*/}
      <Row
        label="Name"
        prop={name}
        isClick={true}
        callback={() => {
          window.open("/users/" + id);
        }}
      />
      <Row label="Phone" prop={phone} />
      <Row label="Some One Name" prop={for_someone_name} />
      <Row label="Some One Phone" prop={for_someone_num} />
      <Row label="Business Name" prop={business_name} />
      <Row label="GST Number" prop={gst_no} />
      <Row label="Booking Date Time" prop={booking_time} isLastRow={true} />
      {/*isLastRow should be true as it's last row and we want borderRadius there*/}
    </Box>
  );
};

export default BookingDetailsTable;

function Row({ label, prop, isLastRow, isClick = false, callback = null }) {
  return (
    <Stack width="100%" direction="row">
      <Box
        width="30%"
        bgcolor="#EFEFEF"
        borderBottom="1px solid white"
        // height="2.1rem"
        display="flex"
        alignItems="center"
        paddingY="0.4rem"
        pl="0.8rem"
        sx={{ borderBottomLeftRadius: isLastRow && "5px" }}
      >
        <Typography fontSize="13px">{label}</Typography>
      </Box>
      <Box
        width="70%"
        bgcolor="white"
        borderBottom="1px solid #EFEFEF"
        display="flex"
        alignItems="center"
        paddingY="0.4rem"
        pl="0.8rem"
        sx={{
          borderBottomRightRadius: isLastRow && "5px",
        }}
      >
        <Typography
          fontSize="13px"
          fontWeight="500"
          sx={{
            color: "black",
            flex: 1,
          }}
        >
          {prop ? prop : "-"}
        </Typography>
        {isClick ? (
          <Box
            onClick={isClick ? callback : null}
            sx={{
              cursor: isClick ? "pointer" : "default",
              mr: 1.1,
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: "500",
                color: "#FFAB30",
                textDecoration: "underline",
              }}
            >
              Details
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Stack>
  );
}
