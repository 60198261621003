import CategoryIcon from "@mui/icons-material/Category";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddIcon from "@mui/icons-material/Add";
import ViewListIcon from "@mui/icons-material/ViewList";
import MonochromePhotosIcon from "@mui/icons-material/MonochromePhotos";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PaymentIcon from "@mui/icons-material/Payment";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import BookIcon from "@mui/icons-material/Book";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import QuizIcon from "@mui/icons-material/Quiz";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import PolicyIcon from "@mui/icons-material/Policy";
import GroupIcon from "@mui/icons-material/Group";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  ContactPage,
  Fullscreen,
  Groups2,
  Money,
  Payment,
  Quiz,
  Subtitles,
  Support,
  Wallpaper,
} from "@mui/icons-material";

import Dashboard from "../../Assets/Drawer Icons/Dashboard.png";
import User from "../../Assets/Drawer Icons/User.png";
import Role from "../../Assets/Drawer Icons/Role.png";
import Notifications from "../../Assets/Drawer Icons/Notifications.png";
import Stories from "../../Assets/Drawer Icons/Stories.png";
import Faq from "../../Assets/Drawer Icons/Faq.png";
import Portfolio from "../../Assets/Drawer Icons/Portfolio.png";
import Policy from "../../Assets/Drawer Icons/Policy.png";
import Export from "../../Assets/Drawer Icons/Export.png";
import onSpot from "../../Assets/Drawer Icons/onSpot.png";
import Order from "../../Assets/Drawer Icons/Order.png";
import Categories from "../../Assets/Drawer Icons/Categories.png";
import Cities from "../../Assets/Drawer Icons/Cities.png";
import Services from "../../Assets/Drawer Icons/Services.png";
import SubAdmin from "../../Assets/Drawer Icons/SubAdmin.png";
import Coupon from "../../Assets/Drawer Icons/Coupon.png";
import OrderIssues from "../../Assets/Drawer Icons/OrderIssues.png";
import List from "../../Assets/Drawer Icons/List.png";
import Topic from "../../Assets/Drawer Icons/Topic.png";
import AddButton from "../../Assets/Drawer Icons/AddButton.png";
import Lead from "../../Assets/Drawer Icons/Lead.png";
import promotionalBanner from "../../Assets/Drawer Icons/promotionalBanner.png";
import PodianSupport from "../../Assets/Drawer Icons/podianSupport.png";
import Unavailability from "../../Assets/Drawer Icons/Unavailability.png";
import AppIssue from "../../Assets/Drawer Icons/AppIssue.png";

export const MenuItems = [
  {
    id: "1",
    path: "/dashboard",
    isExpand: false,
    icon: Dashboard,
    title: "Dashboard",
  },

  {
    id: "4",
    path: "/order",
    isExpand: true,
    icon: Order,
    title: "Order",
    children: [
      {
        id: "4.1",
        path: "/order/all",
        isExpand: false,
        icon: List,
        title: "List",
      },
      {
        id: "35",
        path: "/orderissue",
        isExpand: false,
        icon: OrderIssues,
        title: "Issues",
      },
      // {
      //   id: "4.2",
      //   path: "/order/booking",
      //   isExpand: false,
      //   icon: Topic,
      //   title: "Booking",
      //   rights: 1,
      // },
    ],
  },
  {
    id: "36",
    path: "/lead",
    isExpand: false,
    icon: Lead,
    title: "Lead",
  },
  {
    id: "2",
    path: "/category",
    isExpand: true,
    icon: Categories,
    title: "Category",
    children: [
      {
        id: "2.1",
        path: "/category/list",
        isExpand: false,
        icon: List,
        title: "List",
      },
      {
        id: "2.3",
        path: "/service-template",
        isExpand: false,
        icon: Topic,
        title: "Service Template",
      },
    ],
  },
  // {
  //   id: "3",
  //   path: "/inquiry",
  //   isExpand: true,
  //   icon: <HelpOutlineIcon />,
  //   title: "Inquiry",
  //   children: [
  //     {
  //       id: "3.1",
  //       path: "/inquiry/list",
  //       isExpand: false,
  //       icon: <FormatListBulletedIcon />,
  //       title: "List",
  //     },
  //   ],
  // },
  {
    id: "6",
    path: "/cities/list",
    isExpand: false,
    icon: Cities,
    title: "Cities",
    children: [
      {
        id: "6.1",
        path: "/cities/list",
        isExpand: false,
        icon: List,
        title: "List",
      },
      // {
      //   id: "6.2",
      //   path: "/cities/add",
      //   isExpand: false,
      //   icon: AddButton,
      //   title: "Add",
      // },
    ],
  },

  {
    id: "5",
    path: "/service-provider/list",
    isExpand: false,
    icon: Services,
    title: "Service Provider",
    children: [
      {
        id: "5.1",
        path: "/service-provider/list",
        isExpand: false,
        icon: List,
        title: "List",
      },
      // {
      //   id: "5.2",
      //   path: "/service-provider/leave",
      //   isExpand: false,
      //   icon: <HolidayVillageIcon />,
      //   title: "Leave",
      // },
    ],
  },

  {
    id: "30",
    path: "/users",
    isExpand: false,
    icon: User,
    title: "Users",
  },

  // {
  //   id: "7",
  //   path: "/booking",
  //   isExpand: true,
  //   icon: <BookmarkIcon />,
  //   title: "Booking",
  //   children: [],
  // },
  // {
  //   id: "8",
  //   path: "/payment",
  //   isExpand: true,
  //   icon: <PaymentIcon />,
  //   title: "Payment",
  //   children: [],
  // },
  // {
  //   id: "9",
  //   path: "/photo-delivery",
  //   isExpand: true,
  //   icon: <BurstModeIcon />,
  //   title: "Photo Delivery",
  //   children: [
  //     {
  //       id: "9.1",
  //       path: "/pending",
  //       isExpand: false,
  //       icon: <Pending color="#ebe1e1" />,
  //       title: "Pending",
  //     },
  //     {
  //       id: "9.2",
  //       path: "/sorting-out",
  //       isExpand: false,
  //       icon: <Sort color="#ebe1e1" />,
  //       title: "Sorting Out",
  //     },
  //     {
  //       id: "9.3",
  //       path: "/retouching",
  //       isExpand: false,
  //       icon: <TouchAppIcon color="#ebe1e1" />,
  //       title: "Retouching",
  //     },
  //     {
  //       id: "9.5",
  //       path: "/quality-check",
  //       isExpand: false,
  //       icon: <Equalizer color="#ebe1e1" />,
  //       title: "Quality Check",
  //     },
  //     {
  //       id: "9.6",
  //       path: "/uploading",
  //       isExpand: false,
  //       icon: <Upload color="#ebe1e1" />,
  //       title: "Uploading",
  //     },
  //     {
  //       id: "9.7",
  //       path: "/delivered",
  //       isExpand: false,
  //       icon: <AirportShuttle color="#ebe1e1" />,
  //       title: "Delivered",
  //     },
  //   ],
  // },

  // {
  //   id: "10",
  //   path: "/service-template",
  //   isExpand: false,
  //   icon: <DescriptionIcon color="#ebe1e1" />,
  //   title: "Service Template",
  // },
  // {
  //   id: "8",
  //   path: "/contact-setting",
  //   isExpand: true,
  //   icon: <MonochromePhotosIcon />,
  //   title: "Contact Setting",
  //   children: [
  //   ],
  // },
  // {
  //   id: "9",
  //   path: "/social-setting",
  //   isExpand: true,
  //   icon: <MonochromePhotosIcon />,
  //   title: "Social Setting",
  //   children: [
  //   ],
  // },

  {
    id: "21",
    path: "/roles",
    isExpand: false,
    icon: Role,
    title: "Roles",
  },
  // {
  //   id: "22",
  //   path: "/notifications",
  //   isExpand: false,
  //   icon: <NotificationsIcon color="#ebe1e1" />,
  //   title: "Notifications",
  // },
  {
    id: "27",
    path: "/create-notification",
    isExpand: false,
    icon: Notifications,
    title: "Create Notification",
  },
  {
    id: "23",
    path: "/promotional-banner",
    isExpand: false,
    icon: promotionalBanner,
    title: "Promotional Banner",
  },
  {
    id: "24",
    path: "/users-stories",
    isExpand: false,
    icon: Stories,
    title: "Users Stories",
  },
  {
    id: "25",
    path: "/video-stories",
    isExpand: false,
    icon: Stories,
    title: "Video Stories",
  },
  {
    id: "26",
    path: "/faq",
    isExpand: false,
    icon: Faq,
    title: "FAQ",
  },
  {
    id: "28",
    path: "/portfolio",
    isExpand: false,
    icon: Portfolio,
    title: "Portfolio",
  },
  {
    id: "29",
    path: "/policy",
    isExpand: false,
    icon: Policy,
    title: "Policy",
  },
  {
    id: "31",
    path: "/export",
    isExpand: false,
    icon: Export,
    title: "Export",
  },
  {
    id: "32",
    path: "/sub-admin/list",
    isExpand: false,
    icon: SubAdmin,
    title: "Sub Admin",
    children: [
      {
        id: "32.1",
        path: "/sub-admin/list",
        isExpand: false,
        icon: List,
        title: "List",
      },
      // {
      //   id: "32.2",
      //   path: "/sub-admin/add",
      //   isExpand: false,
      //   icon: AddButton,
      //   title: "Add",
      // },
    ],
  },

  {
    id: "33",
    path: "/coupon",
    isExpand: false,
    icon: Coupon,
    title: "Coupon",
    children: [
      {
        id: "33.1",
        path: "/coupon/list",
        isExpand: false,
        icon: List,
        title: "List",
      },
      // {
      //   id: "33.2",
      //   path: "/coupon/add",
      //   isExpand: false,
      //   icon: AddButton,
      //   title: "Add",
      // },
    ],
  },

  {
    id: "34",
    path: "/onSpot",
    isExpand: false,
    icon: onSpot,
    title: "OnSpot",
  },
  {
    id: "50",
    path: "/new-onSpot",
    isExpand: true,
    icon: onSpot,
    title: "New OnSpot",
    children: [
      {
        id: "50.1",
        path: "/new-onSpot/category",
        isExpand: false,
        icon: CategoryIcon,
        title: "Category",
      },
      {
        id: "50.2",
        path: "/new-onSpot/create",
        isExpand: false,
        icon: AddButton,
        title: "Add On-Spot",
        rights: 1,
      },
      // {
      //   id: "50.3",
      //   path: "/new-onSpot/edit",
      //   isExpand: false,
      //   icon: AddButton,
      //   title: "Add On-Spot",
      //   rights: 1,
      // },
      {
        id: "50.3",
        path: "/new-onSpot/list",
        isExpand: false,
        icon: List,
        title: "On Spot List",
        rights: 1,
      },
    ],
  },
  {
    id: "51",
    path: "/tickets/customer",
    isExpand: false,
    icon: (
      <img
        src={Notifications}
        style={{ width: "1.3rem", objectFit: "fill" }}
        alt=""
      />
    ),
    title: "Customer Support",
    isImage: false,
  },
  {
    id: "52",
    path: "/tickets/podian",
    isExpand: false,
    icon: (
      <img
        src={PodianSupport}
        style={{ width: "1rem", height: "1rem", objectFit: "fill" }}
        alt=""
      />
    ),
    title: "Podian Support",
    isImage: false,
  },

  {
    id: "54",
    path: "/unavailability",
    isExpand: false,
    icon: (
      <img
        src={Unavailability}
        style={{ width: "1rem", height: "1rem", objectFit: "fill" }}
        alt=""
      />
    ),
    title: "Unavailability",
    isImage: false,
  },

  {
    id: "55",
    path: "/application-issue",
    isExpand: false,
    icon: (
      <img
        src={AppIssue}
        style={{ width: "1rem", height: "1rem", objectFit: "fill" }}
        alt=""
      />
    ),
    title: "Application issue",
    isImage: false,
  },
  {
    id: "56",
    path: "/report",
    isExpand: false,
    icon: (
      <img
        src={AppIssue}
        style={{ width: "1rem", height: "1rem", objectFit: "fill" }}
        alt=""
      />
    ),
    title: "Report",
    isImage: false,
  },

  {
    id: "57",
    path: "/payout",
    isExpand: false,
    icon: Coupon,
    title: "Payout",
  },

  {
    id: "58",
    path: "/web",
    isExpand: true,
    icon: onSpot,
    title: "Web",
    isImage: false,
    children: [
      {
        id: "58.1",
        path: "/master",
        isExpand: false,
        icon: List,
        title: "Master",
      },
      {
        id: "58.2",
        path: "/web/announcement",
        isExpand: false,
        icon: List,
        title: "Announcement",
      },
      {
        id: "58.3",
        path: "/web/city",
        isExpand: false,
        icon: List,
        title: "City",
      },
      {
        id: "58.4",
        path: "/web/brand",
        isExpand: false,
        icon: List,
        title: "Brand",
      },
      {
        id: "58.5",
        path: "/web/feature",
        isExpand: false,
        icon: List,
        title: "Feature",
      },
      {
        id: "58.6",
        path: "/web/for-podian",
        isExpand: false,
        icon: List,
        title: "For Podian",
      },
      {
        id: "58.7",
        path: "/web/podian-process",
        isExpand: false,
        icon: List,
        title: "Podian Process",
      },
      {
        id: "58.8",
        path: "/web/podian-of-month",
        isExpand: false,
        icon: List,
        title: "Podian Of Month",
      },
      {
        id: "58.9",
        path: "/web/our-value",
        isExpand: false,
        icon: List,
        title: "Our Value",
      },
    ],
  },
];

export const DrawerMenuItems = [
  {
    id: "7",
    path: "/banner-setting",
    isExpand: true,
    icon: <Wallpaper />,
    title: "Banner Setting",
    children: [],
  },

  {
    id: "8",
    path: "/contact-setting",
    isExpand: true,
    icon: <ContactPage />,
    title: "Contact Setting",
    children: [],
  },
  {
    id: "9",
    path: "/social-setting",
    isExpand: true,
    icon: <Groups2 />,
    title: "Social Setting",
    children: [],
  },
  {
    id: "10",
    path: "/site-setting",
    isExpand: true,
    icon: <Fullscreen />,
    title: "Site Setting",
    children: [],
  },
  {
    id: "11",
    path: "/slot-hours-setting",
    isExpand: true,
    icon: <Subtitles />,
    title: "Slot/Hours Setting",
    children: [],
  },
  {
    id: "12",
    path: "/rate-hours-setting",
    isExpand: true,
    icon: <Money />,
    title: "Rate/Hours Setting",
    children: [],
  },
  {
    id: "13",
    path: "/payment-setting",
    isExpand: true,
    icon: <Payment />,
    title: "Payment Setting",
    children: [],
  },
  {
    id: "14",
    path: "/support-setting",
    isExpand: true,
    icon: <Support />,
    title: "Support Setting",
    children: [],
  },
  {
    id: "15",
    path: "/faq-setting",
    isExpand: true,
    icon: <Quiz />,
    title: "FAQ Setting",
    children: [],
  },
];
