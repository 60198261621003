import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";
import { ADD_PAGE } from "../../Apis/rolePage";
import { GrClose } from "react-icons/gr";
import ButtonView from "../Button/ButtonView";
import TextFieldLarge from "../TextFields/TextFieldLarge";

function AddUpdatePagesDailog({ open, handleOnClose, row, role, ordr }) {
  const [title, setTitle] = useState(row ? row?.title ?? "" : "");
  const [description, setDescription] = useState(
    row ? row?.description ?? "" : ""
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleTitle = (v) => {
    setTitle(v);
  };

  const handleDescription = (v) => {
    setDescription(v);
  };

  const handleOnSubmit = async () => {
    if (!role) {
      ToastShow("Please select the role");
    } else if (!title) {
      ToastShow("Please fill the title");
    } else if (!description) {
      ToastShow("Please fill the description");
    } else {
      const res = await ADD_PAGE(role, title, description, row, ordr);

      if (res && res.s) {
        ToastShow("Success");
        handleOnClose(1);
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose(0)}
      sx={{
        backdropFilter: "blur(2px)",
        bgcolor: "rgba(105, 55, 0, 0.230)",
      }}
    >
      <Stack gap={1.5} m={1}>
        <Stack
          width="100%"
          direction="row"
          bgcolor="#F6F6F6"
          borderRadius="6px"
          justifyContent="space-between"
          alignItems="center"
          py="0.8rem"
          px="0.8rem"
        >
          <Typography
            fontWeight="500"
            fontSize="15px"
            sx={{ userSelect: "none" }}
          >
            {row ? "Update Page" : "Add Page"}
          </Typography>
          <GrClose
            style={{ cursor: "pointer", fontSize: "0.9rem" }}
            onClick={() => handleOnClose(0)}
          />
        </Stack>
        <TextFieldLarge
          value={title}
          onChange={(e) => handleTitle(e.target.value)}
          label="Title"
          placeholder="Enter title"
          width="300px"
        />
        <TextFieldLarge
          onChange={(e) => handleDescription(e.target.value)}
          value={description}
          label="Description"
          placeholder="Enter description"
          width="300px"
        />
      </Stack>

      <DialogActions>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ButtonView
            lable={row ? "Update" : "Submit"}
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={handleOnSubmit}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AddUpdatePagesDailog;
