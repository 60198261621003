import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function Support() {
  const { pathname } = useLocation();
  const navigator = useNavigate();
  return (
    <Box>
      <Box display="flex" gap={3} my={2}>
        <Box
          onClick={() => navigator("/support/customer-support")}
          sx={{
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              textTransform: "none",
              fontWeight:pathname.includes("customer-support") ? "bold" :"none"
            }}
          >
            User App
          </Typography>
          <Box
            sx={{
              width: "30px",
              height: "2px",
              backgroundColor: pathname.includes("customer-support")
                ? "#F8B03F"
                : "transparent",
            }}
          />
        </Box>
        <Box
          onClick={() => navigator("/support/podian-support")}
          sx={{
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              textTransform: "none",
              fontWeight:pathname.includes("podian-support") ? "bold" :"none"
            }}
          >
            Podian App
          </Typography>
          <Box
            sx={{
              width: "30px",
              height: "2px",
              backgroundColor: pathname.includes("podian-support")
                ? "#F8B03F"
                : "transparent",
            }}
          />
        </Box>
      </Box>

      <Outlet />
    </Box>
  );
}

export default Support;
