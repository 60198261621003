import { API_URL, GETAPI, POSTAPI } from "./config";

export const GETROLE = async () => {
  const res = await GETAPI(API_URL.getRoleForPodion);
  return res;
};

export const GETSROLE = async () => {
  const res = await GETAPI(API_URL.getsPodion, "&status=1,2,0");
  return res;
};

export const ADD_UPDATES_ROLE = async (data) => {
  const formData = new FormData();
  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.name) {
    formData.append("name", data.name);
  }

  if (data.report_time) {
    formData.append("reporting_time", data.report_time);
  }

  const res = await POSTAPI(data.id ? API_URL.roleUpdate:API_URL.roleAdd, formData);
  return res;
};

export const ACTIVE_INACTIVE_ROLE = async (id,status) => {
    const res = await GETAPI(status == 1 ? API_URL.roleDeactivate : API_URL.roleActivate, "&id="+id);
    return res;
  };