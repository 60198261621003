import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CREATEUSER, GEBYMOBILENO } from "../../../Apis/booking_order_api";
import {
  GET_SUB_ADMIN_DETAILS,
  SAVE_SUB_ADMIN_DEYAILS,
} from "../../../Apis/subadmin_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import ButtonView from "../../../Component/Button/ButtonView";
import SubAdminAddNew from "./SubAdminAddNew";
import { m } from "framer-motion";

function SubAdminAdd() {
  const [mobileNu, setMobileNu] = useState("");
  const [user, setUser] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [isUserExist, setUserExist] = useState(-1);
  const [canCreateSubAdmin, setCanCreateSubAdmin] = useState(1);
  const [firstName, setFName] = useState("");
  const [lastName, setLName] = useState("");
  const [middleName, setMName] = useState("");
  const [gender, setGender] = useState(2);
  const [email, setEmail] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedRights, setSelectedRights] = useState([]);
  const [allSelectModule, setAllSelectModule] = useState(false);
  const [allSelectCity, setAllSelectCity] = useState(false);

  const { id } = useParams();

  const selectCities = (id) => {
    if (!selectedCity.includes(id)) {
      const t = selectedCity.length + 1 == userDetails.cities.length;
      setAllSelectCity(t);
      setSelectedCity((p) => [...p, id]);
    } else {
      setAllSelectCity(false);
      setSelectedCity((p) => p.filter((v) => v != id));
    }
  };

  const selectModules = (id, index) => {
    if (!selectedModules.includes(id)) {
      const t = selectedModules.length + 1 == userDetails.modules.length;
      let sr = 0;
      for (const m of userDetails.modules) {
        sr += m.access_rights.length;
      }
      const r = sr == selectedRights.length;
      setAllSelectModule(t && r);
      setSelectedModules((p) => [...p, id]);
    } else {
      setAllSelectModule(false);
      setSelectedModules((p) => p.filter((v) => v != id));
      if (userDetails.modules[index].access_rights.length) {
        let temp = selectedRights;
        for (const i of userDetails.modules[index].access_rights) {
          if (temp.includes(i.id)) {
            temp = temp.filter((v) => v != i.id);
          }
        }
        setSelectedRights([...temp]);
      }
    }
  };

  const selectRights = (id, index) => {
    if (!selectedRights.includes(id)) {


      const t = selectedModules.length == userDetails.modules.length;
      let sr = 0;
      for (const m of userDetails.modules) {
        sr += m.access_rights.length;
      }
      const r = sr == selectedRights.length + 1;
      setAllSelectModule(t && r);
      
      setSelectedRights((p) => [...p, id]);
      if (!selectedModules.includes(userDetails.modules[index].id)) {
        setSelectedModules((p) => [...p, userDetails.modules[index].id]);
      }
    } else {
      setAllSelectModule(false);
      setSelectedRights((p) => p.filter((v) => v != id));
    }
  };

  const handleFName = (e) => {
    setFName(e.target.value);
  };

  const handleMName = (e) => {
    setMName(e.target.value);
  };

  const handleLName = (e) => {
    setLName(e.target.value);
  };

  const handleMobileNum = (e) => {
    setMobileNu(e.target.value);
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const getUser = async () => {
    if (!mobileNu) {
      ToastShow("Please fill mobile number");
    } else {
      setUser(null);
      setUserDetails(null);
      setFName("");
      setLName("");
      setEmail("");
      setGender(2);
      setMName("");
      setCanCreateSubAdmin(1);
      const res = await GEBYMOBILENO(mobileNu);

      if (res && res.s) {
        setUserExist(1);
        setUser(res.r);

        setCanCreateSubAdmin(1);
        getUserDetails(res.r.id);
        ToastShow("Success");
      } else {
        setUserExist(0);
        setCanCreateSubAdmin(0);
      }
    }
  };

  const handleUserRag = async () => {
    if (!firstName) {
      ToastShow("Please fill first name");
    } else if (!middleName) {
      ToastShow("Please fill middle name");
    } else if (!lastName) {
      ToastShow("Please fill last name");
    } else if (!email) {
      ToastShow("Please fill email");
    } else if (gender == 2) {
      ToastShow("Please select gender");
    } else {
      const res = await CREATEUSER({
        firstName,
        lastName,
        middleName,
        email,
        mobile: mobileNu,
        gender: gender == 0 ? "Male" : "Female",
      });

      if (res && res.s) {
        setUser(res.r);
        setUserExist(1);
        setCanCreateSubAdmin(1);
        getUserDetails(res.r.id);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  const getUserDetails = async (id) => {
    const userD = await GET_SUB_ADMIN_DETAILS(id);
    if (userD && userD.s) {
      setUserDetails(userD.r);

      const mT = [];
      const rT = [];
      const cT = [];

      let sr = 0;
      let sm = 0;

      for (const m of userD.r.modules) {
        sm += 1;
        sr += m.access_rights.length;
        if (m.status == 1) {
          mT.push(m.id);
          for (const r of m.access_rights) {
            if (r.status == 1) {
              rT.push(r.id);
            }
          }
        }
      }

      for (const c of userD.r.cities) {
        if (c.status == 1) {
          cT.push(c.id);
        }
      }

      // console.log(sr,rT.length, "-----", sm, mT.length);

      const t = sr == rT.length && sm == mT.length;
      const c = userD.r.cities.length == cT.length;

      setAllSelectModule(t);
      setAllSelectCity(c);
      // console.log("city all",c)

      setSelectedCity([...new Set(cT)]);
      setSelectedModules([...new Set(mT)]);
      setSelectedRights([...new Set(rT)]);

      setMobileNu(userD.r.mobile);
      setEmail(userD.r.email);
      setFName(userD.r.first_name);
      setLName(userD.r.last_name);
      setMName(userD.r.middle_name);
      setGender(userD.r.gender == "Male" ? 0 : 1);
      setUserExist(1);
    }
  };

  const onSubmit = async () => {
    // console.log(`city - ${selectedCity}`);
    // console.log(`module - ${selectedModules}`);
    // console.log(`rights - ${selectedRights}`);

    const data = await SAVE_SUB_ADMIN_DEYAILS(
      selectedCity,
      selectedModules,
      selectedRights,
      userDetails.id
    );

    if (data && data.s) {
      ToastShow("Successfully added");
    } else {
      ToastShow("Something went wonrg. Please try again.");
    }
  };

  useEffect(() => {
    if (id) {
      setUserExist(0);
      getUserDetails(id);
    }
  }, []);

  return (
    /* Add Sub Admin new code as of Figma by Rohit */
    // <>
    //   <SubAdminAddNew props={""} />
    // </>

    <>
      <Box
        sx={{
          bgcolor: "white",
          p: 5,
          overflowY: "auto",
          height: "87vh",
          borderRadius: "5px",
          boxShadow: "3px 3px 7px 0px #00000040",
        }}
      >
        {/*  AUTHENTICATION */}
        <Authentication
          id={id}
          handleMobileNum={handleMobileNum}
          mobileNu={mobileNu}
          getUser={getUser}
          canCreateOrder={canCreateSubAdmin}
        />
        <Divider />
        {/* USER REGISTRATION */}
        <UserRagistration
          id={id}
          isUserExist={isUserExist}
          lastName={lastName}
          firstName={firstName}
          middleName={middleName}
          handleFName={handleFName}
          handleLName={handleLName}
          handleMName={handleMName}
          handleUserRag={handleUserRag}
          handleGender={handleGender}
          gender={gender}
          email={email}
          handleEmail={handleEmail}
          canCreateSubAdmin={canCreateSubAdmin}
        />

        <Divider />

        {userDetails ? (
          <Box>
            <Box
              sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Modules</Typography>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={allSelectModule}
                        size="small"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedModules((p) => {
                              return userDetails.modules.map((v) => v.id);
                            });
                            setSelectedRights((p) => {
                              return userDetails.modules
                                .map((v) => {
                                  return v.access_rights.map((j) => j.id);
                                })
                                .flat();
                            });
                          } else {
                            setSelectedModules([]);
                            setSelectedRights([]);
                          }
                          setAllSelectModule(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "15px" }} variant="body">
                        Select All
                      </Typography>
                    }
                  />
                </Box>

                <ShowModule
                  userDetails={userDetails}
                  selectModules={selectModules}
                  selectRights={selectRights}
                  selectedModules={selectedModules}
                  selectedRights={selectedRights}
                />
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Cities</Typography>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={allSelectCity}
                        size="small"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCity((p) => {
                              return userDetails.cities.map((v) => v.id);
                            });
                          } else {
                            setSelectedCity([]);
                          }

                          setAllSelectCity(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "15px" }} variant="body">
                        Select All
                      </Typography>
                    }
                  />
                </Box>

                <ShowCities
                  userDetails={userDetails}
                  selectCities={selectCities}
                  selectedCity={selectedCity}
                />
              </Box>
            </Box>
            <ButtonView
              lable="SUBMIT"
              onClick={onSubmit}
              sx={{ px: "2.5rem" }}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
}

function ShowModule({
  userDetails,
  selectModules,
  selectRights,
  selectedModules,
  selectedRights,
}) {
  return userDetails.modules.map((val, index) => {
    return (
      <Box key={index}>
        <FormControl>
          <FormControlLabel
            label={<Typography>{val.name}</Typography>}
            control={
              <Checkbox
                checked={selectedModules.includes(val.id)}
                onChange={() => selectModules(val.id, index)}
              />
            }
          />
          {val.access_rights.length
            ? val.access_rights.map((v, i) => {
                return (
                  <FormControlLabel
                    sx={{
                      ml: 3,
                    }}
                    key={i}
                    label={v.name}
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedRights.includes(v.id)}
                        onChange={() => selectRights(v.id, index)}
                      />
                    }
                  />
                );
              })
            : null}
        </FormControl>
      </Box>
    );
  });
}

function ShowCities({ userDetails, selectCities, selectedCity }) {
  return userDetails.cities.map((val, index) => {
    return (
      <Box key={index}>
        <FormControl>
          <FormControlLabel
            label={<Typography>{val.name}</Typography>}
            control={
              <Checkbox
                checked={selectedCity.includes(val.id)}
                onChange={() => selectCities(val.id)}
              />
            }
          />
        </FormControl>
      </Box>
    );
  });
}

export default SubAdminAdd;

function Authentication({
  handleMobileNum,
  mobileNu,
  getUser,
  canCreateSubAdmin,
  id,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        backgroundColor: "#c0c0c01d",
        p: 1,
      }}
    >
      <Typography variant="h5">Sub Admin</Typography>

      <Box
        sx={{
          width: "22.5rem",
          height: "37px",
          bgcolor: "white",
          borderRadius: "5px",
          border: "none",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          pl: 0.5,
          mt: 2,
        }}
      >
        <input
          disabled
          label="Country Code"
          autoFocus
          // className={Styles.in}
          value="91"
          // onChange={handleCountryCode}
          maxLength={5}
          placeholder="91"
          style={{
            width: "25px",
            height: "21px",
            fontSize: "14.3px",
            fontWeight: "500",
            border: "none",
            outline: "none",
            color: "black",
          }}
        />
        <Box width="5px" height="100%" bgcolor="#c0c0c035" />
        <input
          id="mobileNumber"
          label="Mobile Number"
          autoFocus
          // className={Styles.in}
          value={mobileNu}
          onChange={handleMobileNum}
          maxLength={15}
          placeholder="Enter number"
          style={{
            width: "76.5%",
            height: "21px",
            fontSize: "14.3px",
            fontWeight: "500",
            border: "none",
            outline: "none",
            color: "black",
          }}
        />
        {id != null ? null : (
          <ButtonView
            onClick={getUser}
            lable="Submit"
            sx={{ width: "20px", height: "31px" }}
            fontSize="11.9px"
          />
        )}
      </Box>

      {/* <Box
        sx={{
          mt: 3,
          mb: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          label="Mobile number"
          size="small"
          value={mobileNu}
          onChange={handleMobileNum}
        />{" "}
        {id != null ? null : (
          <Button variant="outlined" onClick={getUser} sx={{ ml: 2 }}>
            Submit
          </Button>
        )}
      </Box> */}
    </Box>
  );
}

function TextFieldView({ lable, value, callBack, disabled }) {
  return (
    <TextField
      size="small"
      sx={{ mr: 1, mt: 1 }}
      label={lable}
      value={value}
      onChange={callBack}
      disabled={disabled}
    />
  );
}

function UserRagistration({
  id,
  isUserExist,
  firstName,
  lastName,
  middleName,
  handleFName,
  handleLName,
  handleMName,
  handleUserRag,
  handleGender,
  gender,
  email,
  handleEmail,
  canCreateSubAdmin,
}) {
  return isUserExist == -1 ? null : (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Typography sx={{ fontWeight: "700" }}>
        {id != null ? "" : "Create"} User
      </Typography>
      <Box
        sx={{
          display: "flex",
          my: 2,
        }}
      >
        <TextFieldView
          lable="First name"
          value={firstName}
          callBack={handleFName}
          disabled={isUserExist == 1}
        />
        <TextFieldView
          lable="Middle name"
          value={middleName}
          callBack={handleMName}
          disabled={isUserExist == 1}
        />
        <TextFieldView
          lable="Last name"
          value={lastName}
          callBack={handleLName}
          disabled={isUserExist == 1}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          my: 2,
          alignItems: "center",
        }}
      >
        <TextFieldView
          lable="Email"
          value={email}
          callBack={handleEmail}
          disabled={isUserExist == 1}
        />
        <Typography sx={{ ml: 1, mr: 1 }}>Gender</Typography>
        <RadioGroup value={gender} row>
          <FormControlLabel
            onChange={handleGender}
            label="Male"
            value={0}
            control={<Radio disabled={isUserExist == 1} />}
          />
          <FormControlLabel
            onChange={handleGender}
            label="Female"
            value={1}
            control={<Radio disabled={isUserExist == 1} />}
          />
        </RadioGroup>
      </Box>

      {!canCreateSubAdmin ? (
        <Button variant="outlined" onClick={handleUserRag}>
          Register
        </Button>
      ) : null}
    </Box>
  );
}
