import { Avatar, Box, Button, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ConfirmationDailog from "../../../../../../Component/Dailog/ConfirmationDailog";
import {
  DELETEONSPOTNEWPROVIDER,
  DELETEONSPOTNEWZONE,
  GETONSPOTNEWPROVIDERGETS,
} from "../../../../../../Apis/new_on_spot_api";
import { useState } from "react";
import { ToastShow } from "../../../../../../Component/Toast/Toast";
import AddUpdateNewZoneDailog from "../../../../../../Component/Dailog/AddNewOnSpotZoneDialog";
import AddNewOnSpotProvider from "../../../../../../Component/Dailog/AddNewOnSpotProvider";
import { API_URL } from "../../../../../../Apis/config";
import { useNavigate, useNavigation } from "react-router-dom";
import { format } from "date-fns";

function ZoneLocationOnSpot({
  getData,
  row,
  on_spot_city_location_id,
  role,
  id,
}) {
  const [openZone, setOpenZone] = React.useState(false);
  const [onSpotLocationRow, setOnSpotLocationRow] = React.useState(null);
  const [onOpenId, setOnId] = React.useState(null);
  const [count, setCount] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (v) => {
    setOnId(v);
    setOpen(true);
  };

  const handleClose = (v) => {
    if (v) {
      setCount((p) => p + 1);
    }
    setOnId(0);
    setOpen(false);
  };

  const handleOpenZone = (v = null) => {
    setOnSpotLocationRow(v);
    setOpenZone(true);
  };

  const handleOnCloseZone = (v) => {
    if (v) {
      getData();
    }
    setOnSpotLocationRow(null);
    setOpenZone(false);
  };

  
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    setSelectedIdN(id);
    handleOpenConfirmation(id);
  };
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTNEWZONE({
        id: selectedId,
      });
      if (r && r.s) {
        getData();
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {row.length ? (
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              gap: "15px",

              flexWrap: "wrap",
            }}
          >
            {row.map((v, index) => {
              return (
                <Paper
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    border: "1px solid #c0c0c0",
                    p: 3,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5">Zone</Typography>
                    <Button onClick={() => handleOpen(v.id)}>
                      Add Provider
                    </Button>
                  </Box>
                  <Divider />
                  <ZoneDetails
                    id={id}
                    on_spot_city_location_id={on_spot_city_location_id}
                    handleOpenZone={handleOpenZone}
                    onDelete={onDelete}
                    v={v}
                    count={count}
                    role={role}
                    setCount={setCount}
                  />
                </Paper>
              );
            })}
          </Box>
        ) : (
          <Typography>Please add the zone by the "ADD-ZONE" button</Typography>
        )}
      </Box>

      {openZone ? (
        <AddUpdateNewZoneDailog
          row={onSpotLocationRow}
          handleOnClose={handleOnCloseZone}
          open={openZone}
          on_spot_city_location_id={on_spot_city_location_id}
        />
      ) : null}

      {open ? (
        <AddNewOnSpotProvider
          row={onSpotLocationRow}
          handleOnClose={handleClose}
          open={open}
          role={role?.map((v) => v.name)}
          rolesW={role}
          on_spot_city_location_zone_id={onOpenId}
          on_spot_city_location_id={on_spot_city_location_id}
        />
      ) : null}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete Zone !"
          content="Are you sure, You want to delete the Zone?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default ZoneLocationOnSpot;

function ZoneDetails({
  onDelete,
  v,
  handleOpenZone,
  on_spot_city_location_id,
  id,
  count,
  role,
  setCount
}) {
  const [provider, setProvider] = useState([]);

  const getData = async () => {
    const data = await GETONSPOTNEWPROVIDERGETS({
      on_spot_city_location_id: on_spot_city_location_id,
      on_spot_city_location_zone_id: v.id,
      on_spot_id: id,
    });

    if (data && data.s ) {
      setProvider(data.r ? data.r: []);
    }
  };
  useEffect(() => {
    getData();
  }, [count]);

  return (
    <Box>
      <FlexView title="Name" des={v.name} />

      <Box
        sx={{
          display: "flex",
          mt: 1,
          width:"150px"
        }}
      >
        <Button
          onClick={() => handleOpenZone(v)}
          variant="outlined"
          sx={{
            borderRadius: "0px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            flex: 1,
          }}
        >
          Edit
        </Button>
        <Button
          onClick={() => onDelete(v.id)}
          variant="outlined"
          sx={{
            flex: 1,
            borderRadius: "0px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          Delete
        </Button>
      </Box>

      <Box
        sx={{
          mt: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 0.5,
          }}
        >
          Provider
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          {provider.map((v) => {
            return <ItemView key={v.id} id={id} v={v}  role={role} setCount = {setCount} getData={getData} />;
          })}
        </Box>
      </Box>
    </Box>
  );
}

function FlexView({ title, des }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          width: "60px",
          fontWeight: "bold",
        }}
      >
        {title} :{" "}
      </Typography>
      <Typography>{des}</Typography>
    </Box>
  );
}

function ItemView({ v, id, role,setCount }) {

  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const navigations = useNavigate();
  
  const handleOpenConfirmation = (id) => {
    setSelectedRow(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTNEWPROVIDER({
        id: selectedRow,
      });
      if (r && r.s) {
        setCount((p) => p + 1);
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedRow(null);
    setOpenConfirmation(false);
  };

  const handleOpen = (v) => {
    setSelectedRow(v);
    setOpen(true);
  };

  const handleClose = (v) => {
    if (v) {
      setSelectedRow(null);
      setCount((p) => p + 1);
    }
    // setOnId(0);
    setOpen(false);
  };


  return (
    <Box
    sx={{
      displa:"flex",
      flexDirection:"column",
      alignItems:"center",
      border: "solid",
      borderWidth: "1px",
      borderColor: "divider",
      boxShadow:
        "7px 8px 21px -10px rgba(0,0,0,0.1); -10px -12px 21px -10px rgba(0,0,0,0.1)",
      px:2
    }}
   
    >

      <Button
       
        elevation={0}
        onClick={() => {
          // console.log("call")
          navigations(`/newonSpot/edit/${id}/details/`+v.id);
          // handleSelected(v);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          alignItems: "center",
          height:"140px",
          width:"100%"
        }}
      >
        <Avatar
          sx={{ height: "40px", width: "40px" }}
          src={`${API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb}`}
        />
        <Typography
          variant="body1"
          sx={{ fontSize: "14px", textAlign: "center", wordWrap: "break-word" }}
        >
          {(v.first_name ?? "") + " " + (v.last_name ?? "")}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "10px", wordWrap: "break-word" }}
        >
          {v.mobile}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "10px", wordWrap: "break-word" }}
        >
          {v.role}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "13px", wordWrap: "break-word" }}
        >
          Earn : ₹{v.earn}
        </Typography>
      </Button>

      <Box
        sx={{
          display: "flex",
          mt: 1,
        }}
      >
        <Button
          onClick={() => handleOpen(v)}
          variant="outlined"
          sx={{
            borderRadius: "0px",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            flex: 1,
          }}
        >
          Edit
        </Button>
        <Button
          onClick={() => handleOpenConfirmation(v.id)}
          variant="outlined"
          sx={{
            flex: 1,
            borderRadius: "0px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          Delete
        </Button>
      </Box>

      {v.linkRow.length
          ? v.linkRow.map((c) => {
              return <LinkView key={c.id} i={c.id} v={c} 
              //getData={getData} 
              on_spot_id={id}/>;
            })
          : null}

      {open ? (
        <AddNewOnSpotProvider
          row={selectedRow}
          handleOnClose={handleClose}
          open={open}
          role={role?.map((v) => v.name)}
          rolesW={role}
          on_spot_city_location_zone_id={0}
          on_spot_city_location_id={0}
          on_spot_city_location_zone_provider_id={selectedRow.id}
          provider_user_id={selectedRow.provider_user_id}
        />
      ) : null}

        {openConfirmation ? (
        <ConfirmationDailog
          title="Delete Provider !"
          content="Are you sure, You want to delete the provider?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </Box>
  );
}


function LinkView({getData, v, i, on_spot_id, id,on_spot_city_location_zone_provider_id }) {
  //const [openConfirmation, setOpenConfirmation] = useState(false);

  // const onDelete = async () => {
  //   setOpenConfirmation(true);
  // };
  // const handleOnCloseConfirmation = async (v) => {
  //   if (v) {
  //     const r = await DELETEONSPOTNEWLINK({
  //       id: i,
  //     });
  //     if (r && r.s) {
  //       ToastShow("Deleted successfully");
  //       getData();
  //     } else {
  //       ToastShow("Opps something went wrong. Please try again.");
  //     }
  //   }
  //   setOpenConfirmation(false);
  // };

  // const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = (v) => {
  //   if(v){
  //     getData();
  //   }
  //   setOpen(false);
  // };

  return (
    <>
      <Box
        key={i}
        sx={{
          borderRadius: 1,
          p: 1,
          my: 2,
          WebkitBoxShadow: " -5px -8px 57px -24px rgba(145,136,145,0.48)",
          boxShadow: "-5px -8px 57px -24px rgba(145,136,145,0.48)",
        }}
      >
        <Box display="flex">
          <Typography>Link : </Typography>
          <Box
            onClick={() => window.open(v.link)}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                ml: "3px",
                color: "blue",
                opacity: 0.7,
              }}
            >
              {v.link}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }} />
        <Typography sx={{ fontSize: "14px" }}>
          Expiry :{" "}
          {!v?.expiry
            ? "-"
            : format(Date.parse(v.expiry), "yyyy-MMM-dd hh:mm a")}
        </Typography>
        <Box sx={{ mt: 1 }} />
        <Typography sx={{ fontSize: "14px" }}>
          Editor : {v?.editor ?? "-"}
        </Typography>
        <Box sx={{ mt: 1 }} />
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Box display="flex">
            <Typography sx={{ fontSize: "14px" }}>
              Clicks : {v.clicks}
            </Typography>
            <Typography sx={{ ml: "10px", fontSize: "14px" }}>
              Clips : {v.clips}
            </Typography>
          </Box>
        </Box>

        <Box display="flex">
          <Typography sx={{ fontSize: "14px" }}>
            Created At :{" "}
            {format(Date.parse(v.created_at), "yyyy-MMM-dd hh:mm a")}
          </Typography>
        </Box>
        {/* <Box display="flex">
          <IconButton onClick={() => handleOpen()}>
            <EditOutlined color="primary" />
          </IconButton>

          <IconButton onClick={() => onDelete()}>
            <DeleteOutline color="error" />
          </IconButton>
        </Box> */}
      </Box>

      {/* {open ? (
        <AddNewOnSpotLinkD
          row={v}
          open={open}
          handleOnClose={handleClose}
          on_spot_city_location_zone_provider_id={on_spot_city_location_zone_provider_id}
          on_spot_city_location_zone_provider_customer_id={id}
          id={v.id}
          on_spot_id={on_spot_id}
        />
      ) : null} */}

      {/* {openConfirmation ? (
        <ConfirmationDailog
          title="Delete Link !"
          content="Are you sure, You want to delete the link?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null} */}
    </>
  );
}