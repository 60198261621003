import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import SplitButton from "../../../Component/Button/SplitButton";
import AddUpdateWebCityDailog from "../../../Component/Dailog/web/AddUpdateWebCity";
import { GET_MASTER } from "../../../Apis/master/master_api";
import UpdateMasterDailog from "../../../Component/Dailog/master/MasterDailog";
import { API_URL } from "../../../Apis/config";

function Master() {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, SetIsLoading] = useState(true);

  const getData = async () => {
    SetIsLoading(true);
    const res = await GET_MASTER();

    if (res && res?.s) {
      setRow(res?.r ?? []);
    } else {
      setRow([]);
    }
    SetIsLoading(false);
  };

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setSelectedRow(null);
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "SR.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      minWidth: 300,
      headerAlign: "start",
      align: "start",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },

    {
      field: "value",
      headerName: "Value",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "start",
      align: "start",
      renderCell: (params) => {
        // console.log(params);

        if (params.row.data_type.toLowerCase() == "photo") {
          return (
            <Avatar
              src={API_URL.baseUrl + API_URL.masterPhotoUrl + params.value}
              sx={{ my: 1.5 }}
            />
          );
        }
        return (
          <Typography sx={{ py: 1.5, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 70,
      width: 70,
      maxWidth: 70,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          dataId={params.row.id}
          isDelete={null}
          isEdit={1}
          onEdit={onEdit}
          onView={null}

          // deleteText="Delete"
        />
      ),
    },
  ];

  const onEdit = (id) => {
    // console.log(id, "id");
    setSelectedRow(() => row.find((v) => v.id == id));
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
          <DataGrideView
            rows={row}
            columns={columns}
            isLoading={isLoading}
            sx={{
              height: "87.75vh",
            }}
            autoHeight={false}
            colHeight={null}
          />
        </Box>
      </Box>
      {open && (
        <UpdateMasterDailog
          open={open}
          handleOnClose={handleClose}
          row={selectedRow}
        />
      )}
    </>
  );
}

export default Master;
