import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import LocationOnSpot from "./Location";
import AddOnShotLocationDailog from "../../../../../../Component/Dailog/AddNewOnSpotLocationDailog";

function CityDetails({ getData, id, row, role }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (v = null) => {
    setOpen(true);
  };

  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOpen(false);
  };

  return (
    <>
      <Paper
        sx={{
          p: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            gap: "15px",
            p: 1,
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h5">{row.name}</Typography>
            <Button onClick={handleOpen}>Add Location</Button>
          </Box>
          <Divider />
          <LocationOnSpot
            getData={getData}
            id={id}
            row={row?.location}
            city_id={row.city_id}
            role={role}
          />
        </Box>
      </Paper>

      {open ? (
        <AddOnShotLocationDailog
          row={null}
          handleOnClose={handleOnClose}
          open={open}
          on_spot_id={row?.id}
        />
      ) : null}
    </>
  );
}

export default CityDetails;
