import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  import ToastView, { ToastShow } from "../Toast/Toast";
  import { addInvoicePaymentRecord } from "../../Apis/order_api";
  
  function AddInvoicePayment({ open, handleOnClose, id }) {
    const [loading, setLoading] = useState(false);
    const [isRefund, setIsRefund] = useState(false);
    const [amount, setAmount] = useState("");
    const [method, setMethod] = useState("");
    const [note, setNote] = useState("");
    const [transactionId, setTransactionId] = useState("");
  
    const handleSubmit = async () => {
  
      if(!amount){
          ToastShow("Please enter amount");
      }else if(!method){
          ToastShow("Please enter method");
      }else if(!note){
          ToastShow("Please enter note");
      }else{
          setLoading(true);
          const data = await addInvoicePaymentRecord(id,amount,isRefund,method,note,transactionId);
          setLoading(false);
          if(data && data.s){
              ToastShow("Success");
              handleOnClose(1);
          }else {
              ToastShow( data?.m ?? "Oops! something went wrong. Please try again.");
          }
         
      }
    };
  
    return (
      <>
        <Dialog open={open} onClose={() => handleOnClose(0)}>
          <DialogTitle>Add Payment Record</DialogTitle>
          <DialogContent
            sx={{
              width: "400px",
              display: "flex",
              padding: "15px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
            sx={{
              mt:1
            }}
            type="number"
              fullWidth
              variant="outlined"
              label="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
  
            <TextField
              fullWidth
              variant="outlined"
              label="Method"
              sx={{ my: 2 }}
              value={method}
              onChange={(e) => setMethod(e.target.value)}
            />
  
            <TextField
              fullWidth
              variant="outlined"
              label="Note"
              value={note}
              multiline
              rows={3}
              onChange={(e) => setNote(e.target.value)}
            />
            <TextField
              sx={{ my: 2 }}
              fullWidth
              variant="outlined"
              label="Transaction Id"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />
            <FormControlLabel
            sx={{
              width:"95%"
            }}
              label="Is refund"
              control={
                <Checkbox
                  checked={isRefund}
                  onChange={(e) => setIsRefund(e.target.checked)}
                />
              }
            />
          </DialogContent>
  
          <DialogActions>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleSubmit} variant="text">
                Submit
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastView />
      </>
    );
  }
  
  export default AddInvoicePayment;
  