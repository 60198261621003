import { CoronavirusOutlined, Edit } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_POLICY, GET_POLICY_DETALS } from "../../../Apis/policy_api";
import UpdatePolicy from "../../../Component/Dailog/UpdatePolicyDailog";

function Policy() {
  const [data, setData] = useState({ isLoading: true, row: [], details: {} });
  const [open, setOpen] = React.useState(false);

  const [changeStyle, setChangeStyle] = useState({ id: 1 });

  const handleOnClose = async (p) => {
    setOpen(false);
    if (p) {
      await getDetails(data.details.id);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getData = async () => {
    setData((p) => ({ ...p, isLoading: true }));
    const data = await GET_POLICY();
    if (data && data.s) {
      setData((p) => ({ ...p, isLoading: true, row: data.r ?? [] }));
    }
    setData((p) => ({ ...p, isLoading: false }));

    // const d = await GET_POLICY_DETALS(1);
  };

  const getDetails = async (id = 1) => {
    const d = await GET_POLICY_DETALS(id);

    if (d && d.s) {
      setData((p) => ({ ...p, details: d.r ?? {} }));
    }
  };

  useEffect(() => {
    getDetails();
    getData();
  }, []);

  const handleChange = (itemID) => {
    setChangeStyle({ ...changeStyle, id: itemID });
    getDetails(itemID);
  };

  if (data.isLoading) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "none",
        height: "100vh",
      }}
    >
      {/*Tabs*/}
      <Box
        sx={{
          width: "19.2rem",
          height: "75%",
          bgcolor: "white",
          boxShadow: "3px 3px 7px 0px #00000040",
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
        }}
      >
        {/*Customers*/}
        <Box sx={{ height: "45%" }}>
          <Box
            sx={{
              height: "16.66%",
              bgcolor: "#F8AF41",
              display: "flex",
              alignItems: "center",
              paddingLeft: "12px",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
            }}
          >
            <Typography fontSize=".9rem" fontWeight="500">
              Customer
            </Typography>
          </Box>
          <Box height="83.3%" paddingTop=".4rem">
            {data.row.map((v) => {
              if (v.id <= 4) {
                return (
                  <Box
                    key={v.id}
                    component={"div"}
                    onClick={() => {
                      handleChange(v.id);
                      // getDetails(v.id);
                    }}
                    sx={{
                      cursor: "pointer",
                      height: "25%",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "12px",
                      borderBottom: "1px solid #ebe8e8",
                      bgcolor: changeStyle.id == v.id ? "#F4920026" : "white",
                      "&:hover": { bgcolor: "#F8F8F8" },
                    }}
                  >
                    <Typography
                      sx={{
                        color: changeStyle.id == v.id ? "#F49200" : "black",
                        fontWeight: changeStyle.id == v.id && "500",
                        fontSize: ".9rem",
                      }}
                    >
                      {v.id === 4
                        ? "Privacy policy"
                        : v.id === 1
                        ? "Terms of service"
                        : v.name}
                    </Typography>
                  </Box>
                );
              }
            })}
          </Box>
        </Box>

        {/*PODIAN*/}
        <Box sx={{ height: "55%", marginTop: ".4rem", paddingBottom: ".5rem" }}>
          <Box
            sx={{
              height: "15%",
              bgcolor: "#F8AF41",
              display: "flex",
              alignItems: "center",
              paddingLeft: "12px",
            }}
          >
            <Typography fontSize=".9rem" fontWeight="500">
              PODIAN
            </Typography>
          </Box>
          <Box height="85%" paddingTop="0.4rem">
            {data.row.map((v) => {
              if (v.id > 4 && v.id !== 8) {
                return (
                  <Box
                    key={v.id}
                    component={"div"}
                    onClick={() => {
                      handleChange(v.id);
                      // getDetails(v.id);
                    }}
                    sx={{
                      cursor: "pointer",
                      height: "20%",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "12px",
                      borderBottom: v.id !== 10 && "1px solid #ebe8e8",
                      bgcolor: changeStyle.id == v.id ? "#F4920026" : "white",
                      borderBottomLeftRadius: v.id === 10 && "6px",
                      borderBottomRightRadius: v.id === 10 && "6px",
                      "&:hover": { bgcolor: "#F8F8F8" },
                    }}
                  >
                    <Typography
                      sx={{
                        color: changeStyle.id == v.id ? "#F49200" : "black",
                        fontSize: ".9rem",
                      }}
                    >
                      {v.id === 5 && "Terms of service"}
                      {v.id === 6 && "About us"}
                      {v.id === 7 && "legal info"}
                      {v.id === 9 && "Agreement"}
                      {v.id === 10 && "Guidelines"}
                    </Typography>
                  </Box>
                );
              }
            })}
          </Box>
        </Box>
      </Box>

      {/*For middle gap*/}
      <Box width="1.5%"></Box>

      {/*Right side*/}
      <Box
        sx={{
          borderRadius: "5px",
          boxShadow: "3px 3px 7px 0px #00000040",
          bgcolor: "white",
          width: "80%",
          padding: ".7rem",
          overflow: "auto",
        }}
      >
        {Object.keys(data.details).length ? (
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Box
              component={"div"}
              onClick={handleOpen}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <IconButton>
                <Edit />
              </IconButton>
            </Box>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              Name
            </Typography>
            <Typography fontWeight="600">{data.details.name}</Typography>
            <Typography
              sx={{
                mt: 3,
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Value
            </Typography>
            <div
              dangerouslySetInnerHTML={{ __html: data.details.value }}
              style={{ overflow: "auto" }}
            />
          </Paper>
        ) : null}

        {open && (
          <UpdatePolicy
            data={data.details}
            open={open}
            handleOnClose={handleOnClose}
          />
        )}
      </Box>
    </Box>
  );
}

export default Policy;
