import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

function ShowLinkDetailsOrder({ open, handleClose, row }) {
  return (
    <Dialog fullWidth open={open} onClose={() => handleClose(0)}>
      <DialogTitle>Link</DialogTitle>
      <DialogContent
        sx={{
          overflow: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        
      </DialogContent>
    </Dialog>
  );
}

export default ShowLinkDetailsOrder;
