import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_WEB_FOR_PODIAN = async (data) => {
  const formData = new FormData();


  if (data?.photo) {
    formData.append("photo", data.photo, "photo.png");
  }

  if (data?.row) {
    formData.append("id", data.row.id);
  }

  formData.append("name", data?.name);
  formData.append("label", data?.label);
  formData.append("url", data?.url);

  const res = await POSTAPI(
     API_URL.updateForPodian,
    formData
  );
  return res;
};

export const GET_WEB_FOR_PODIAN = async () => {
  const res = await GETAPI(API_URL.getForPodians);
  return res;
};
