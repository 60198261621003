import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { GrClose } from "react-icons/gr";
import RadioButtonNew from "../../../../Component/RadioButton/RadioButtonNew";
import TextFieldSmall from "../../../../Component/TextFields/TextFieldSmall";
import ButtonView from "../../../../Component/Button/ButtonView";

const DiscountModal = ({ open, handleClose, row, handleSubmit }) => {
  const [type, setType] = useState(row ? row.type : 2);
  const [applyOn, setApplyOn] = useState(row ? row.on : "");
  const [applicable, setApplicable] = useState(row ? row.apply : "");
  const [maxAmount, setMaxAmount] = useState(row ? row.maxAmount : "");
  const [isPercentage, setIsPercentage] = useState(row ? row.isPercentage : 0);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(4px)",
        bgcolor: "rgba(105, 55, 0, 0.4)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "6px",
          display: "flex",
          padding: "1.3rem",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "540px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#F6F6F6",
              height: "44px",
              width: "540px",
              padding: ".6rem",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "1.150rem",
              fontWeight: "500",
            }}
          >
            {!row ? "Add Discount" : "Edit Discount"}
            <GrClose style={{ cursor: "pointer" }} onClick={handleClose} />
          </Typography>

          <RadioButtonNew
            labelA="Hour"
            labelB="Amount"
            altTheme={true}
            value={type}
            handleChange={setType}
          />
          <TextFieldSmall
            label="Apply On"
            width="100%"
            type="number"
            placeholder="0"
            value={applyOn}
            onChange={(e) => setApplyOn(e.target.value)}
          />
          <TextFieldSmall
            label="Applicable"
            width="100%"
            type="number"
            placeholder="0"
            value={applicable}
            onChange={(e) => setApplicable(e.target.value)}
          />
          <TextFieldSmall
            label="Max Amount"
            width="100%"
            type="number"
            placeholder="0"
            value={maxAmount}
            onChange={(e) => setMaxAmount(e.target.value)}
          />
          {/*Is percentage*/}
          <Box
            width="100%"
            pl="0.6rem"
            borderRadius="6px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <FormControlLabel
              label="Percentage"
              required
              style={{ margin: "0px" }}
              control={
                <Checkbox
                  icon={
                    <img
                      style={{ width: ".9rem", objectFit: "fill" }}
                      src={require("../../../../Assets/UnCheckedIcon.png")}
                    />
                  }
                  checkedIcon={
                    <img
                      style={{ width: ".9rem", objectFit: "fill" }}
                      src={require("../../../../Assets/Icons for pages/checked_icon.png")}
                    />
                  }
                />
              }
              sx={{
                "& .css-1x2jons-MuiFormControlLabel-asterisk": {
                  display: "none",
                },
                "& .css-fbhpqu-MuiButtonBase-root-MuiCheckbox-root": {
                  padding: "0px",
                  marginRight: "0.3rem",
                },
                "& .css-7jdnmo-MuiTypography-root": {
                  fontSize: "0.750rem",
                  color: false ? "#F8AF41" : "inherit",
                  userSelect: "none",
                },
              }}
              name="percentage"
              checked={isPercentage}
              onChange={() => setIsPercentage(!isPercentage)}
            />
          </Box>

          <ButtonView
            lable="Submit"
            onClick={() => {
              handleSubmit({
                type,
                applyOn,
                applicable,
                maxAmount,
                isPercentage,
                isClose: 1,
              });
              handleClose(0);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default DiscountModal;
