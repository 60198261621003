import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { RESTRICT_USER } from "../../Apis/restrict_users";
import ButtonView from "../Button/ButtonView";
import { GrClose } from "react-icons/gr";

function RestrictUser({ open, handleOnClose, row, id = 0, app }) {
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);

  const handleOnSubmit = async () => {
    setIsLoading(true);
    const d = await RESTRICT_USER(
      startDate ? format(startDate, "yyyy-MM-dd HH:mm:ss") : null,
      id,
      note ?? null,
      app
    );
    setIsLoading(false);
    if (d && d.s) {
      ToastShow("Successfully added");
      handleOnClose(1);
    } else {
      ToastShow("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        <DialogTitle sx={{ padding: "1rem" }}>
          <Stack
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.6rem"
            px="0.7rem"
          >
            <Typography
              fontWeight="500"
              sx={{ userSelect: "none", fontSize: "17px" }}
            >
              Add Restriction Reason
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={() => handleOnClose(0)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Stack
            gap={1.5}
            py={1.5}
            alignItems="center"
            width="100%"
            bgcolor="#F6F6F6"
          >
            <Box
              width="96%"
              display="flex"
              flexDirection="column"
              gap=".3rem"
              height="100%"
              paddingTop=".4rem"
              bgcolor="white"
              padding="0.6rem"
              borderRadius="6px"
            >
              <Typography fontSize="12px">Note</Typography>

              <TextField
                multiline
                rows={4}
                maxRows={4}
                placeholder="Enter Note"
                type="text"
                size="small"
                sx={{
                  width: "500px",
                  height: "110px",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "6px",
                  width: "100%",
                  "& fieldset": { border: "none" },
                  "& .MuiFormLabel-root": {
                    color: "#969696",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input": {
                    fontSize: "0.8rem",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                name="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Box>

            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Till Date"
              value={startDate}
              onChange={(v) => {
                setStartDate(v);
              }}
              renderInput={(params) => (
                <TextField fullWidth sx={{ mt: 1.4 }} {...params} />
              )}
            />
          </LocalizationProvider> */}

            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "6px",
                width: "96%",
                height: "4.3rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                py: "8px",
              }}
            >
              <Box width="96%">
                <Box
                  display="flex"
                  gap=".5rem"
                  alignItems="center"
                  mb=".1rem"
                  width="90%"
                  ml=".2rem"
                >
                  <img
                    src={require("../../Assets/calendar-icon.png")}
                    alt=""
                    style={{ width: "10px", objectFit: "fill" }}
                  />
                  <Typography fontSize="12px">Till Date</Typography>
                </Box>

                <Box sx={{ backgroundColor: "#F6F6F6", borderRadius: "6px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={startDate}
                      onChange={(v) => {
                        setStartDate(v);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            height: "2.3rem",
                            display: "flex",
                            justifyContent: "center",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                fontSize: "0.8rem",
                              },
                          }}
                          size="small"
                          {...params}
                        />
                      )}
                      
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
          </Stack>

          {isLoading ? (
            <CircularProgress />
          ) : (
            <ButtonView
              lable="Submit"
              sx={{ width: "100%", mt: 2 }}
              onClick={handleOnSubmit}
            />
          )}
        </DialogContent>
      </Dialog>
      <ToastView />
    </>
  );
}

export default RestrictUser;
