import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  GET_SUGGESTED_PROVIDER,
  getDetailsOfOtherProviderToOrderItems,
  getDetailsOfProviderToOrderItems,
  getDetailsOfProviderUnavailable,
} from "../../Apis/order_api";
import { useState } from "react";
import { Box } from "@mui/system";
import { API_URL } from "../../Apis/config";
import { ToastShow } from "../Toast/Toast";
import { motion } from "framer-motion";
import { GrClose } from "react-icons/gr";
import ButtonView from "../Button/ButtonView";
import SelectIcon from "../../Assets/Icons for pages/SelectIcon.png";
import UnavailableDailog from "./UnavailableDailog";

let count = 0;
export default function OrderDailog({
  status = 0,
  open,
  handleClose,
  orderItemId,
  handleSubmit,
  qty,
  service,
}) {
  const [data, setData] = useState(null); //Intereseted providers
  const [dataNot, setDataNot] = useState(null);
  const [dataUnavailable, setDataUnavailable] = useState([]);
  const [dataOther, setOtherData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [openUnavailable, setOpenUnavailable] = useState(false);
  const [selected, setSelected] = useState([]);
  const [approve, setApprove] = useState([]);
  const [disApprove, setDisApprove] = useState([]);
  const [search, setSearch] = useState();

  const [suggData, setSuggData] = useState([]);

  function handleSelected(v, t = 1) {
    if (selected.length < qty) {
      const i = selected.indexOf(v);
      if (!(i > -1)) {
        setSelected((p) => [...p, v]);
        if (t) {
          ToastShow(`Selected succssfully!`);
        }
      }
    } else {
      ToastShow(`Required quantity is just : ${qty}.`);
    }
  }

  function deleteHandleSelected(v) {
    if (!(v.status > 2)) {
      const i = selected.indexOf(v);
      if (i > -1) {
        const d = selected.filter((e) => e.user_id != v.user_id);
        setSelected((p) => [...d]);
      }
    } else {
      ToastShow("Order has been started. So you can not remove.");
    }
  }

  const getData = async () => {
    setIsLoading(true);
    const d = await getDetailsOfProviderToOrderItems(orderItemId);
    setData(d.r ?? []);
    await getOtherDetails();
    if ((d.r ?? []).length) {
      for (let v of d.r) {
        if (v.status == 2) {
          handleSelected(v, 0);
        }
      }
    }
    setIsLoading(false);
  };

  const getDataNoInterested = async () => {
    const d = await getDetailsOfProviderToOrderItems(orderItemId, 0);
    setDataNot(d?.r ?? []);
  };

  const getDataNoUnavaialble = async (count) => {
    const d = await getDetailsOfProviderUnavailable(orderItemId, count);
    setDataUnavailable((p) => [...p, ...(d?.r ?? [])]);

    if ((d.r ?? []).length) {
      await getDataNoUnavaialble((d.r ?? []).length);
    }
  };

  async function getOtherDetails(search = "", flag = 0) {
    if (flag) {
      const d = [];
      setOtherData([...d]);
    }

    // console.log(dataOther.length,"count");
    const d = await getDetailsOfOtherProviderToOrderItems(
      orderItemId,
      search,
      count
    );

    count += (d.r ?? []).length;
    setOtherData((p) => [...p, ...(d.r ?? [])]);

    if ((d.r ?? []).length) {
      await getOtherDetails(search);
    }
    return;
  }

  // const handleOnChange = (v, value) => {
  //   const checkQty = data.filter((v) => v.status != 1);

  //   // console.log(checkQty.length, "checkQty");
  //   // console.log(qty,"qty");

  //   if (+checkQty.length < +qty || !v) {
  //     if (v) {
  //       if (!approve.includes(value.id) && value.status == 1) {
  //         approve.push(value.id);
  //         setApprove([...approve]);
  //       }
  //       if (disApprove.includes(value.id)) {
  //         const t = disApprove.filter((id) => id != value.id);
  //         setDisApprove([...t]);
  //       }
  //     } else {
  //       if (!disApprove.includes(value.id) && value.status != 1) {
  //         disApprove.push(value.id);
  //         setDisApprove([...disApprove]);
  //       }
  //       if (approve.includes(value.id)) {
  //         const t = approve.filter((id) => id != value.id);
  //         setApprove([...t]);
  //       }
  //     }
  //     const index = data.indexOf(value);
  //     data[index].status = v ? 0 : 1;
  //     const t = data;
  //     setData([...data]);
  //   } else {
  //     ToastShow(`Required quantity is just - ${qty}.`);
  //   }
  // };

  const onChangeHandle = (e) => {
    if (!e) {
      getOtherDetails(e, 0);
    }
    setSearch(e);
  };

  const getSuggestedData = async () => {
    const res = await GET_SUGGESTED_PROVIDER(orderItemId); //orders_items_id
    if (res && res.s) {
      setSuggData(res.r ?? []);
    } else {
      ToastShow(res.m);
    }
  };

  useEffect(() => {
    count = 0;
    getData();
    getDataNoInterested();
    getDataNoUnavaialble();
    getSuggestedData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          width: "100vw",
          backdropFilter: "blur(3px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClose={handleClose}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          mx="1rem"
          mt="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="18px" fontWeight="500">
            {service}
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Box mx="50rem">
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <DialogContent sx={{ height: "auto", paddingTop: "0px" }}>
            <Box display="flex" alignItems="center" overflow="hidden">
              <TextField
                placeholder="Search other provider"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alingItems: "center",
                  padding: "0.7rem 0",
                }}
                sx={{
                  width: "97%",
                  my: "0.9rem",
                  ml: "0.4rem",
                  backgroundColor: "white",
                  boxShadow: "3px 3px 16px 0px #00000026",
                  borderRadius: "5px",
                  "& fieldset": { border: "none" },
                  "& .MuiFormLabel-root": {
                    color: "#969696",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .css-j43tob-MuiInputBase-root-MuiOutlinedInput-root": {
                    fontSize: "14px",
                  },
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0",
                    paddingLeft: ".5rem",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          count = 0;
                          getOtherDetails(search, 1);
                        }}
                      >
                        <img
                          style={{
                            width: "13px",
                            objectFit: "fill",
                            cursor: "default",
                          }}
                          src={require("../../Assets/Search_icon.png")}
                          alt=""
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onChangeHandle(e.target.value);
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {selected.length ? (
                <ChipView
                  data={selected}
                  deleteHandleSelected={deleteHandleSelected}
                />
              ) : null}
              <Button
                onClick={() => setOpenUnavailable(true)}
                variant="contained"
                sx={{
                  height: "35px",
                }}
              >
                Unavailability
              </Button>
            </Box>

            {data.length ||
            dataOther.length ||
            dataNot.length ||
            suggData.length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SuggestedView
                  data={suggData}
                  handleSelected={handleSelected}
                />
                <InterestedView data={data} handleSelected={handleSelected} />
                <NotInterestedView
                  data={dataNot}
                  handleSelected={handleSelected}
                />
                <OtherView
                  dataOther={dataOther}
                  handleSelected={handleSelected}
                />
                {/* <UnavailableView
                  data={dataUnavailable}
                  handleSelected={handleSelected}
                /> */}
              </Box>
            ) : (
              <Box>
                <Typography>No data available</Typography>
              </Box>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <ButtonView
            lable="Submit"
            fontSize="1.130rem"
            fontWeight="500"
            sx={{ py: "0.6rem", width: "100%" }}
            onClick={() => {
              // handleSubmit(approve, disApprove);
              const d = [];
              for (let v of selected) {
                d.push(v.user_id);
              }
              handleSubmit(d, orderItemId);
            }}
          />
        </DialogActions>
      </Dialog>

      {openUnavailable ? (
        <UnavailableDailog
          data={dataUnavailable}
          handleClose={() => setOpenUnavailable(false)}
          open={openUnavailable}
        />
      ) : null}
    </>
  );
}

function ChipView({ data, deleteHandleSelected }) {
  return (
    <Box display="flex" flexWrap="wrap" flex={1} width="100%">
      {data.map((v) => {
        return (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            key={v.user_id}
          >
            <Chip
              sx={{ m: 0.3 }}
              avatar={
                <Avatar
                  alt="Natacha"
                  src={`${
                    API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb
                  }`}
                />
              }
              label={v.first_name}
              variant="outlined"
              onDelete={() => deleteHandleSelected(v)}
            />
          </motion.div>
        );
      })}
    </Box>
  );
}

function SuggestedView({ data, handleSelected }) {
  return !data.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 1, fontSize: "1.1rem" }}>
        Suggested Providers
      </Typography>

      <Grid container columns={3} columnGap={1.5} rowGap={1.5}>
        {data?.map((v, i) => {
          return (
            <SuggestedItemView
              key={v.user_id}
              v={v}
              handleSelected={handleSelected}
            />
          );
        })}
      </Grid>
    </Box>
  );
}

function InterestedView({ data, handleSelected }) {
  return !data.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 1, fontSize: "1.1rem" }}>
        Interested Providers
      </Typography>
      <DetailsView data={data} handleSelected={handleSelected} />
    </Box>
  );
}

function NotInterestedView({ data, handleSelected }) {
  return !data.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 1, fontSize: "1.1rem" }}>
        Not-Interested Providers
      </Typography>
      <DetailsView data={data} handleSelected={handleSelected} />
    </Box>
  );
}

function UnavailableView({ data, handleSelected }) {
  return !data.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 1, fontSize: "1.1rem" }}>
        Unavailable Providers
      </Typography>
      <DetailsView data={data} handleSelected={handleSelected} />
    </Box>
  );
}

function OtherView({ dataOther, handleSelected }) {
  return !dataOther.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 2, fontSize: "1.1rem" }}>
        Other Providers
      </Typography>
      <DetailsView data={dataOther} handleSelected={handleSelected} />
    </Box>
  );
}

function DetailsView({ data, handleSelected }) {
  return (
    <Grid container columns={3} columnGap={1.5} rowGap={1.5}>
      {data.map((v, i) => {
        return (
          <ItemView key={v.user_id} v={v} handleSelected={handleSelected} />
        );
      })}
    </Grid>
  );
}

function ItemView({ v, handleSelected }) {
  return (
    <Paper
      component={"div"}
      elevation={0}
      onClick={() => {
        handleSelected(v);
      }}
      sx={{
        overflow: "hidden",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        width: "8rem",
        p: 2,
        transition: "all",
        transitionDuration: "300ms",
        boxShadow: "3px 3px 9px 0px #00000040",
        "&:hover": {
          backgroundColor: "#fcebcf",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        },
        backgroundColor: false && "#ffeac9",
      }}
    >
      <Stack width="100%" flexDirection="row" justifyContent="center">
        <Box width="90%" display="flex" justifyContent="center">
          <Avatar
            sx={{ height: "40px", width: "40px" }}
            src={`${
              API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb
            }`}
          />
        </Box>

        <Box width="10%">
          {false && (
            <Avatar
              sx={{
                width: "1rem",
                height: "1rem",
                objectFit: "fill",
              }}
              src={SelectIcon}
            />
          )}
        </Box>
      </Stack>

      <Typography
        sx={{ fontSize: "13.5px", wordWrap: "break-word", mt: "0.3rem" }}
      >
        {(v.first_name ?? "") + " " + (v.last_name ?? "")}
      </Typography>
      <Typography sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
        {v.mobile}
      </Typography>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
          {v.cities}
        </Typography>
      </Box>
    </Paper>
  );
}

// function SuggestedDetails({ data, handleSelected }) {
//   return (
//     <Grid container columns={3} columnGap={1.5} rowGap={1.5}>
//       {data?.map((v, i) => {
//         return (
//           <SuggestedItemView
//             key={v.user_id}
//             v={v}
//             handleSelected={handleSelected}
//           />
//         );
//       })}
//     </Grid>
//   );
// }

function SuggestedItemView({ v, handleSelected }) {
  return (
    <Paper
      component={"div"}
      elevation={0}
      onClick={() => {
        v.is_provider_available === null && handleSelected(v);
      }}
      sx={{
        overflow: "hidden",
        cursor: v.is_provider_available === null ? "pointer" : "not-allowed",
        display: "flex",
        flexDirection: "column",
        width: "8rem",
        p: 2,
        transition: "all",
        transitionDuration: "300ms",
        boxShadow: "3px 3px 9px 0px #00000040",
        "&:hover": {
          backgroundColor: v.is_provider_available === null && "#fcebcf",
          boxShadow:
            v.is_provider_available === null &&
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        },
        backgroundColor: v.is_provider_available !== null && "#D3D3D3",
      }}
    >
      <Stack width="100%" flexDirection="row" justifyContent="center">
        <Box width="90%" display="flex" justifyContent="center">
          <Avatar
            sx={{ height: "40px", width: "40px" }}
            src={`${
              API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb
            }`}
          />
        </Box>

        <Box width="10%">
          {false && (
            <Avatar
              sx={{
                width: "1rem",
                height: "1rem",
                objectFit: "fill",
              }}
              src={SelectIcon}
            />
          )}
        </Box>
      </Stack>

      <Typography
        sx={{ fontSize: "13.5px", wordWrap: "break-word", mt: "0.3rem" }}
      >
        {v.name ?? ""}
      </Typography>
      <Typography sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
        {v?.mobile && v?.mobile}
      </Typography>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
          {v?.cities && v?.cities}
        </Typography>
      </Box>

      {v.is_provider_available !== null && (
        <Typography
          bgcolor="crimson"
          color="white"
          px="0.4rem"
          borderRadius="1rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="15px"
        >
          Unavailable
        </Typography>
      )}
    </Paper>
  );
}
