import { Button, Chip, Fab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  addOrder,
  getIssueOrder,
  getOrderDetailsById,
} from "../../../Apis/order_api";
import SplitButton from "../../../Component/Button/SplitButton";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";

import { rightsUser } from "../../Home/Drawer/DrawerView";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import { format } from "date-fns";
import { ToastShow } from "../../../Component/Toast/Toast";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import OrderIssueTopics from "../../../Component/Dailog/OrderIssueTopic";

const paidStatus = ["All", "Paid", "Unpaid"];

function OrderIssue({ status = 1 }) {
  // console.log(status)

  const [paidS, setPaidS] = useState(["All"]);

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setPaidS([value]);
    getData();
  };

  const navigation = useNavigate();

  const columns = [
    // {
    //   field: "id",
    //   headerName: "Sr.",
    //   filterable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     // console.log(params);
    //     return params.api.getRowIndex(params.row.id) + 1;
    //   },
    // },
    {
      field: "orders_id",
      headerName: "Order ID",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 70,
      minWidth: 70,
      flex: 1,
    },

    {
      field: "customer_name",
      headerName: "Name",
      width: 130,
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell(params) {
        return (
          <Typography
            sx={{ fontSize: "12.4px", textAlign: "center", py: "4px" }}
          >
            {params.row.customer_name}
          </Typography>
        );
      },
    },

    {
      field: "title",
      headerName: "Title",
      width: 190,
      minWidth: 190,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell(params) {
        return (
          <Typography sx={{ fontSize: "12.4px", py: "4px" }}>
            {params.row.title}
          </Typography>
        );
      },
    },

    {
      field: "message",
      headerName: "Message",
      width: 210,
      minWidth: 210,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell(params) {
        return (
          <Typography sx={{ fontSize: "12.4px", py: "4px" }}>
            {params.row.message}
          </Typography>
        );
      },
    },

    {
      field: "response",
      headerName: "Response",
      width: 200,
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell(params) {
        return (
          <Typography sx={{ fontSize: "12.4px", py: "4px" }}>
            {params.row.response}
          </Typography>
        );
      },
    },

    {
      field: "reported_dt",
      headerName: "Reported Date",
      width: 105,
      minWidth: 105,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        !params.value ? (
          "-"
        ) : (
          <DateMDYHMA date={params.value} fontSize="11.8px" isUtc={false} />
        ),
    },
    {
      field: "done_dt",
      headerName: "Solved Date",
      width: 110,
      minWidth: 110,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        !params.value ? (
          "-"
        ) : (
          <DateMDYHMA date={params.value} fontSize="11.8px" isUtc={false} />
        ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          sx={{
            bgcolor: params.value === 0 ? "#FFC7C7" : "#FFE6C1",
            color: "#252525",
            borderRadius: "5px",
            textTransform: "none",
            fontSize: ".7rem",
            fontWeight: "500",
            height: "1.8rem",
            width: "4.8rem",
            cursor: "default",
            "&:hover": {
              backgroundColor: params.value === 0 ? "#FFC7C7" : "#FFE6C1",
            },
          }}
        >
          {params.value === 0 ? "Unsolved" : "Solved"}
        </Button>
      ),
    },

    {
      field: "action",
      headerName: "Action",
      width: 70,
      minWidth: 70,
      flex: 1,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          isView={rightsUser.includes(4) ? 1 : null}
          dataId={params.row.orders_id}
          isDelete={null}
          isEdit={null}
          // onEdit={onEdit}
          onView={viewData}
          // onDelete={deleteData}
          // deleteText="Delete"
        />
      ),
    },
  ];

  const onEdit = async (id) => {
    const data = await getOrderDetailsById(id);

    if (data) {
      const d = {
        user_id: data.r.user_id,
        city_id: data.r.city_id,
        date_time: data.r.date_time,
        remarks: data.r.remar ?? "",
        shoot_area: data.r.shoot_area,
        address: data.r.address,
        near_by_address: data.r.near_by_address,
        latitude: data.r.latitude,
        longitude: data.r.longitude,
        gst_no: data.r.gst_no,
        address1: data.r.address1,
        business_name: data.r.business_name,
        billing_address: data.r.billing_address,
        is_for_someone: data.r.is_for_someone,
        someone_name: data.r.someone_name,
        someone_mobile: data.r.someone_mobile,
        is_pod_can_use: data.r.is_pod_can_use,
        category_id: data.r.category_id,
        sub_category_id: data.r.sub_category_id,
        sub_category_name: data.r.sub_category_name,
        isCollaboration: data.r.isCollab,
        isNotify: 1,
      };

      const res = await addOrder(d);

      if (res && res.s) {
        ToastShow("Order created successfully");
        navigation("/order/" + res.r.orders_id + "/draft");
      } else {
        ToastShow("Oops! something went wrong.");
      }
    }
  };

  const viewData = (v) => {
    window.open(`/order/canceled/` + v);
  };

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  const getData = async (c) => {
    setIsLoading(true);

    const data = await getIssueOrder(c);

    if (data.r) {
      if (!c) {
        setIsCall(true);
        setRows(data?.r ?? []);
      } else {
        setRows((p) => [...p, ...data.r]);
      }
    } else {
      if (!c) {
        setIsCall(true);
        setRows([]);
      } else {
        setIsCall(false);
      }
    }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    // const d = [];
    // setRows(p=>[...d]);
    getData(0);
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length);
    }
  };

  const [open, setOpen] = useState(false);

  const handleOnClose = (v) => {
    if (v) {
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        overflow="auto"
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        bgcolor="white"
        boxShadow="3px 3px 7px 0px #00000040"
        borderRadius="6px"
      >
        <Box display="flex" width="100%" justifyContent="space-between">
          {/* <Box
            sx={{
              display: "flex",
            }}
          >
            <MultiSelection
              selected={paidS}
              handleChange={handleStatus}
              names={paidStatus}
              lable={"Status"}
              multiple={false}
            />
          </Box> */}
        </Box>
        <DataGrideView
          rows={rows}
          columns={columns}
          callBack={callBack}
          isLoading={isLoading}
          sx={{ height: "100vh" }}
          colHeight={null}
        />
      </Box>

      {/* <Fab
        onClick={() => setOpen(true)}
        sx={{
          position: "absolute",
          right: 30,
          bottom: 30,
        }}
      >
        <Add />
      </Fab> */}

      {open && <OrderIssueTopics handleOnClose={handleOnClose} open={open} />}
    </>
  );
}

export default OrderIssue;
