import { API_URL,POSTAPI } from "./config";

export const ADD_UPDATE_FCM = async (data) => {
  const formData = new FormData();
  formData.append("app", 3);
  formData.append("fcm_token", data);
  formData.append("device_id", data);

  const res = await POSTAPI(API_URL.addFCM , formData
  );

  return res;
};