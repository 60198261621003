import { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import HikePriceInput from "./Cities Details Comps/HikePriceInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddDiscountViewModal from "./Cities Details Comps/AddDiscountViewModal";
import pipe_lines from "../../../Assets/Icons for pages/pipe_lines.png";
import SwitchRadioButton3 from "./Cities Details Comps/SwitchRadioButton3";

const CustomTable = ({
  rows,
  setRows,
  rowData,
  services,
  categoryId,
  setRowData,
  applyChanges,
  tempData,
  pos,
}) => {
  const [characters, setupdateCharacters] = useState([]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const servicess = Array.from(rows); // MainRows / PreRows / PostRows

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const [reorderedService] = servicess.splice(sourceIndex, 1);
    servicess.splice(destinationIndex, 0, reorderedService);

    servicess.forEach((service, index) => (service.ordr = index));
    setRows(servicess);

    tempData[pos] = servicess;

    // console.log("tempData",tempData)

    const s = [tempData["0"], tempData["1"], tempData["2"]].flat();

    const updatedData = rowData.map((item, index) => {
      return {
        ...item,
        ordr: "" + index,
        services:
          item.id == categoryId
            ? s.map((service, i) => ({
                ...service,
                ordr: "" + i,
              }))
            : item.services.map((service, i) => ({
                ...service,
                ordr: "" + i,
              })),
      };
    });

    setRowData(updatedData);
    // console.log(updatedData);
    // console.log("rowData$$$$$",rowData);
    // console.log("rowData$$$$$",services);
    // console.log("rowData$$$$$",updatedData);
    // applyChanges(updatedData);
  }

  useEffect(() => {
    // console.log("change the row");
    // console.log("change row",rows);
    setupdateCharacters(rows);
  }, [rows]);

  const categoryServiceTextChange = (serviceID, input) => {
    // console.log("Clicked on category:", categoryId);
    // console.log("Clicked on serviceID:", serviceID);

    const categoryToUpdate = rowData.find(
      (category) => category.id === categoryId
    );

    if (categoryToUpdate) {
      const serviceToUpdate = categoryToUpdate.services.find(
        (service) => service.id === serviceID
      );

      if (serviceToUpdate) {
        serviceToUpdate.price_hike = Number(input);
        setRowData([...rowData]);
        // applyChanges(rowData);
      }
    } else {
      console.log("error");
    }
  };

  const categoryServiceSwitchChange = (serviceID, value) => {
    // console.log("Clicked on category:", value);
    // console.log("Clicked on serviceID:", serviceID);

    const categoryToUpdate = rowData.find(
      (category) => category.id === categoryId
    );

    if (categoryToUpdate) {
      const serviceToUpdate = categoryToUpdate.services.find(
        (service) => service.id === serviceID
      );

      if (serviceToUpdate) {
        serviceToUpdate.status = value;
        setRowData([...rowData]);
        // applyChanges(rowData);
      }
    } else {
      console.log("error");
    }
  };

  const categoryServiceStatus = (serviceID) => {
    const categoryToUpdate = rowData.find(
      (category) => category.id === categoryId
    );

    if (categoryToUpdate) {
      const serviceToUpdate = categoryToUpdate.services.find(
        (service) => service.id === serviceID
      );

      if (serviceToUpdate) {
        serviceToUpdate.status = serviceToUpdate.status === 1 ? 0 : 1;

        setRowData([...rowData]);

        const servicess = Array.from(rows);
        servicess.forEach((service, index) => (service.ordr = index));
        setRows(servicess);

        tempData[pos] = servicess;

        // console.log("tempData",tempData)

        const s = [tempData["0"], tempData["1"], tempData["2"]].flat();

        const updatedData = rowData.map((item, index) => {
          return {
            ...item,
            ordr: "" + index,
            services:
              item.id == categoryId
                ? s.map((service, i) => ({
                    ...service,
                    ordr: "" + i,
                  }))
                : item.services.map((service, i) => ({
                    ...service,
                    ordr: "" + i,
                  })),
          };
        });

        setRowData(updatedData);
        // applyChanges(updatedData);

        console.log(serviceToUpdate);
      }
    } else {
      console.log("error");
    }
  };

  return (
    <Stack sx={{ borderRadius: "6px" }}>
      {/*Header*/}
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          bgcolor: "rgba(248, 175, 65, 1)",
          py: "0.6rem",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "30%",
            textAlign: "center",
          }}
        >
          Name
        </Typography>
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "20%",
            textAlign: "center",
          }}
        >
          Base-Price
        </Typography>
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "20%",
            textAlign: "center",
          }}
        >
          Hike Price
        </Typography>
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "20%",
            textAlign: "center",
          }}
        >
          Total Price
        </Typography>
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "20%",
            textAlign: "center",
          }}
        >
          Discount
        </Typography>
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "20%",
            textAlign: "center",
          }}
        >
          Status
        </Typography>
      </Stack>

      {/*Draggable*/}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {characters.length ? (
          <Droppable droppableId="characters">
            {(provided) => (
              <Stack
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {characters
                  .slice()
                  .sort((a, b) => a.ordr - b.ordr)
                  .map(
                    (
                      {
                        id,
                        name,
                        services_id,
                        price_hike,
                        price,

                        status,
                        discount,
                      },
                      i
                    ) => (
                      <Draggable
                        key={String(id)}
                        draggableId={String(id)}
                        index={i}
                      >
                        {(provided) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row
                              name={name}
                              price={price}
                              serviceID={id}
                              services_id={services_id}
                              priceHike={price_hike}
                              status={status}
                              rowData={rowData}
                              setRowData={setRowData}
                              applyChanges={applyChanges}
                              categoryId={categoryId}
                              totalprice = {+price_hike <= 0 ? price : (((+price*+price_hike)/100) + +price).toFixed(2)}
                              categoryServiceTextChange={
                                categoryServiceTextChange
                              }
                              categoryServiceStatus={categoryServiceStatus}
                              categoryServiceSwitchChange={categoryServiceSwitchChange}
                            />
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        ) : (
          <Stack height="20rem" justifyContent="center" alignItems="center">
            <Typography>No Rows</Typography>
          </Stack>
        )}
      </DragDropContext>
    </Stack>
  );
};

export default CustomTable;

const Row = ({
  name,
  price,
  categoryServiceTextChange,
  serviceID,
  priceHike,
  status,
  categoryServiceStatus,
  rowData,
  services_id,
  totalprice,
  categoryServiceSwitchChange
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [serviceStatus, setServiceStatus] = useState(status ?? 0);

  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // console.log("price",priceHike)

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        height="3rem"
        bgcolor="rgba(246, 246, 246, 1)"
        borderBottom="1px solid rgba(220, 220, 220, 1)"
      >
        <Stack direction="row" width="30%" gap="0.4rem" pl="0.4rem">
          <img src={pipe_lines} alt="" />
          <Typography
            style={{
              userSelect: "none",
              fontSize: "12.4px",
              textAlign: "start",
            }}
          >
            {name}
          </Typography>
        </Stack>
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "20%",
            textAlign: "center",
          }}
        >
          {price}
        </Typography>

        <Box width="20%" display="flex" justifyContent="center">
          <HikePriceInput
            value={priceHike}
            serviceID={serviceID}
            handleAddHike={categoryServiceTextChange}
          />
        </Box>
        <Typography
          style={{
            userSelect: "none",
            fontSize: "12.4px",
            width: "20%",
            textAlign: "center",
          }}
        >
          {totalprice}
        </Typography>

        <Box width="20%" display="flex" justifyContent="center">
          <Button
            sx={{
              bgcolor: "#BEBEBE",
              "&:hover": { bgcolor: "#a6a4a4" },
              color: "#BEBEBE",
            }}
            style={{
              minWidth: "0",
              padding: "0",
              paddingTop: "4px",
              paddingBottom: "4px",
              width: "6rem",
            }}
            onClick={handleOpenPopOver}
          >
            <Typography fontSize="11.8px" textTransform="none">
              Add Discount
            </Typography>
          </Button>
        </Box>

        <Box width="20%" display="flex" justifyContent="center">
          <SwitchRadioButton3
            isChecked={serviceStatus}
            handleChange={setServiceStatus}
            applyChanges={()=>categoryServiceSwitchChange(serviceID,serviceStatus ? 0 : 1)}
            serviceID={serviceID}
          />
        </Box>
      </Stack>

      {/*Add Discount View Modal*/}
      {open && (
        <AddDiscountViewModal
          open={open}
          handleClose={handleClosePopOver}
          anchorEl={anchorEl}
          service_name={name}
          serviceID={serviceID}
        />
      )}
    </>
  );
};
