import { useState } from "react";
import "./CustomSwitch.css";

function SwitchRadioButton({
  isChecked,
  handleChange,
  serviceID,
  rowStatus,
  applyChanges = () => {},
}) {
  const toggleSwitch = () => {
    setS(p=>s?0:1);

    handleChange(isChecked ? 0 : 1);
    applyChanges();
  };

  const [s, setS] = useState(isChecked ? 1 : 0);

  console.log("status=>",isChecked,"rowStatus=>",rowStatus)

  return (
    <div
      className={`custom-switch ${s ? "checked" : ""}`}
      onClick={toggleSwitch}
    >
      <div className="custom-switch-slider"></div>
    </div>
  );
}

export default SwitchRadioButton;

