import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { useEffect } from "react";
import SplitButton from "../../../Component/Button/SplitButton";
import { useNavigate, useParams } from "react-router-dom";
import { ToastShow } from "../../../Component/Toast/Toast";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import {
  DELETESUPPORTCATEGORY,
  GET_SUPPORT_CATEGORY,
} from "../../../Apis/support_api";
import AddUpdateSupportCategory from "../../../Component/Dailog/AddUpdateSupportCategory";
import { rightsUser } from "../../Home/Drawer/DrawerView";
import { Add } from "@mui/icons-material";

export default function CustomerSupportList() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [openS, setOpenS] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [chain, setChain] = React.useState("");

  const navigate = useNavigate();

  const { parentId } = useParams();

  const onView = (id) => {
    navigate("/customer-support/" + id);
  };

  const onEdit = (id) => {
    const row = rows.filter((e) => e.id == id);
    setSelectedRow(row[0]);
    setOpenS(true);
  };

  const onDelete = async (id) => {
    const row = rows.filter((e) => e.id == id);
    setSelectedRow(row[0]);
    setOpen(true);
  };

  const handleOnClose = async (v) => {
    setOpen(false);

    if (v) {
      const r = await DELETESUPPORTCATEGORY(selectedRow.id);
      if (r && r.s) {
        const d = rows.filter((v) => v.id != selectedRow.id);
        setRows([...d]);
        ToastShow(r.m);
      } else {
        ToastShow(r.m);
      }
    }
    setSelectedRow(null);
  };

  const handleOnCloseS = async (v) => {
    setOpenS(false);

    if (v) {
      getData(0);
    }
    setOpenS(false);
    setSelectedRow(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "name",
      headerName: "Topic",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "related_to",
      headerName: "Related To",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>{getReletedTo(params.value)}</Typography>;
      },
    },
    {
      field: "admin",
      headerName: "Admin",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            onClick={
              !rightsUser.includes(7)
                ? null
                : () =>
                    navigate("/customer-support/" + params.row.id + "/admin")
            }
          >
            {!rightsUser.includes(7) ? "-" : "View Admin"}
          </Button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={onDelete}
          onView={onView}
          isView={1}
          title={params.value}
          onEdit={onEdit}
          isEdit={1}
        />
      ),
    },
  ];

  function getReletedTo(value) {
    let v = "";

    switch (value) {
      case 0:
        v = "General";
        break;

      case 1:
        v = "Order";
        break;

      case 2:
        v = "On Spot";
        break;
    }

    return v;
  }

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length);
    }
  };

  async function getData(count) {
    if (!count) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GET_SUPPORT_CATEGORY(1, parentId ?? 0, count);

    if (t && t.s == 1 && t.r) {
      if (count == 0) {
        setRows([...t.r]);
      } else {
        setRows((p) => [...p, ...t.r]);
      }

      if (t.m != "Success") {
        setChain(t.m);
      } else {
        setChain("");
      }
    } else {
      if (count == 0) {
        setRows([]);
      }
      setIsCall(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData(0);
  }, [parentId]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{chain}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{ my: 1, alignSelf: "flex-end" }}
            onClick={() => setOpenS(true)}
          >
            <Add /> Add Main Category
          </Button>

          {/* <Button
            variant="contained"
            sx={{ my: 1, alignSelf: "flex-end" }}
            onClick={() => navigate("/customer-support/callback")}
          >
            Callback
          </Button> */}
        </Box>
      </Box>

      <Paper
        sx={{
          p: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 1,
          }}
        >
          User App Topics
        </Typography>
        <DataGrideView
          callBack={callBack}
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          sx={{
            height: "70vh",
          }}
        />
      </Paper>

      <ConfirmationDailog
        open={open}
        handleOnClose={handleOnClose}
        content="Are you sure want to delete this category?"
        title="Delete category!"
      />

      {openS && (
        <AddUpdateSupportCategory
          open={openS}
          handleOnClose={handleOnCloseS}
          parentId={parentId ?? 0}
          type={1}
          row={selectedRow}
        />
      )}
    </Box>
  );
}
