import { useState } from "react";
import { Dialog, Stack, Typography } from "@mui/material";
import ButtonView from "../../../../../Component/Button/ButtonView";
import { GrClose } from "react-icons/gr";
import TextFieldSmall from "../../../../../Component/TextFields/TextFieldSmall";
import { ToastShow } from "../../../../../Component/Toast/Toast";

function UpdateStatusDialog({
  open,
  handleClose,
  id,
  handleApproveRejectStatus,
}) {
  const [reason, setReason] = useState("");

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Stack gap={2} p={1}>
          <Stack
            width="100%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.4rem"
            px="0.5rem"
          >
            <Typography
              fontWeight="500"
              fontSize="15px"
              sx={{ userSelect: "none" }}
            >
              Update Status
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={handleClose}
            />
          </Stack>

          <Stack gap={0.7} alignItems="center">
            <ButtonView
              lable="Approve"
              fontSize="14px"
              hoverBg="#ffb952"
              sx={{ width: "100%" }}
              isDisable={!id}
              onClick={() => {
                handleApproveRejectStatus(1, id, "");
                handleClose();
              }}
            />

            <Typography my="0.5rem">OR</Typography>

            <TextFieldSmall
              label="Enter a reason for reject"
              placeholder="Write here..."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              width="20rem"
            />

            <ButtonView
              lable="Reject"
              color="#E50000"
              bgcolor="#E5000033"
              fontSize="14px"
              sx={{ width: "100%" }}
              hoverBg="pink"
              isDisable={!id}
              onClick={() => {
                if (reason !== "") {
                  handleApproveRejectStatus(-1, id, reason);
                  handleClose();
                } else {
                  ToastShow("Please add a valid Reason!");
                }
              }}
            />
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}

export default UpdateStatusDialog;
