import React from "react";
import { Box, Popover, Stack, Typography } from "@mui/material";
import { GrClose } from "react-icons/gr";
import { API_URL } from "../../../../../Apis/config";

const KycHistoryModal = ({ open, setOpen, data }) => {
  return (
    <Popover
      open={open}
      onClose={() => setOpen(false)}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        zIndex: 1,
      }}
    >
      <Stack gap={2} width="22vw" height="99vh" m={1.8}>
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            fontSize="20px"
            fontWeight="500"
            sx={{ userSelect: "none" }}
          >
            History
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        </Stack>

        {data ? (
          data.map((i, e) => (
            <Stack
              key={i.id}
              gap={2}
              borderBottom={e !== data.length - 1 && "1px solid #D7D7D7"}
              paddingBottom="1rem"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="#FAAF40" fontWeight="500">
                  {i.name}
                </Typography>
                <Typography color={i.status ? "#039600" : "red"}>
                  {i.status ? "Approved" : "Rejected"}
                </Typography>
              </Box>
              {/* <Typography>19-Apr-2023 2:59 PM</Typography> */}

              {i.front || i.back ? (
                <Box display="flex" gap={1} alignItems="center">
                  {i.front && (
                    <img
                      src={API_URL.baseUrl + API_URL.KYCImage + i.front}
                      style={{
                        objectFit: "fill",
                        width: "4rem",
                        borderRadius: "5px",
                      }}
                      alt=""
                    />
                  )}
                  {i.back && (
                    <img
                      src={API_URL.baseUrl + API_URL.KYCImage + i.back}
                      style={{
                        objectFit: "fill",
                        width: "4rem",
                        borderRadius: "5px",
                      }}
                      alt=""
                    />
                  )}
                </Box>
              ) : null}
            </Stack>
          ))
        ) : (
          <Typography>No History</Typography>
        )}
      </Stack>
    </Popover>
  );
};

export default KycHistoryModal;
