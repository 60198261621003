import React from 'react'
import OrderList from './OrderList'

function Confirmed({status}) {
  console.log("call Confirmed")
  return (
    <OrderList status={status}/>
  )
}

export default Confirmed