import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  COUPENCanAPPLYTOORDER,
  GETCOUPENCUSTOMER,
} from "../../Apis/coupen_api";
import { ToastShow } from "../Toast/Toast";
import ButtonView from "../Button/ButtonView";
import { GrClose } from "react-icons/gr";

function GetCouponDailog({ userId, cityId, open, handleClose, orderId }) {
  // console.log(orderId,"orderId");
  const [data, setData] = useState({
    isLoading: true,
    row: [],
    selectedCoupon: null,
  });
  const [code, setCode] = useState("");

  const getData = async () => {
    setData((p) => ({ ...p, isLoading: true }));
    const res = await GETCOUPENCUSTOMER(cityId, userId);
    if (res && res.s && res.r) {
      setData((p) => ({ isLoading: false, row: res.r }));
    }
    setData((p) => ({ ...p, isLoading: false }));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog open={open} onClose={() => handleClose(0, null)}>
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "#F6F6F6",
          height: "40px",
          width: "100%",
          padding: ".6rem",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "1.080rem",
          fontWeight: "500",
        }}
      >
        Coupon
        <GrClose
          style={{ cursor: "pointer" }}
          onClick={() => handleClose(0, null)}
        />
      </Typography>
      <DialogContent>
        {data.isLoading ? (
          <CircularProgress />
        ) : (
          <Box>
            <TextField
              fullWidth
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Search"
              style={{
                height: "2.2rem",
                display: "flex",
                justifyContent: "center",
                alingItems: "center",
              }}
              sx={{
                mb:1.5,
                backgroundColor: "#EFEFEF",
                borderRadius: "5px",
                "& fieldset": { border: "none" },
                "& .MuiFormLabel-root": {
                  color: "#969696",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .css-j43tob-MuiInputBase-root-MuiOutlinedInput-root": {
                  fontSize: "14px",
                },
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "0",
                  paddingLeft: ".5rem",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      style={{
                        cursor: "pointer",
                        width: "12.6px",
                        objectFit: "fill",
                      }}
                      src={require("../../Assets/Search_icon.png")}
                      alt=""
                      onClick={(e) => {
                        // console.log("call");
                        const coupon = data.row.map((k) => ({
                          ...k,
                          isSelect: false,
                        }));

                        setData((p) => ({
                          ...p,
                          selectedCoupon: null,
                          row: coupon,
                        }));
                        setCode(e.target.value);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            {/* <Divider sx={{ my: 1 }} /> */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              
                overflowY: "auto",
              }}
            >
              {data.row.length ? (
                data.row.map((v) => {
                  return (
                    <ViewOfCoupon
                      key={v.id}
                      data={v}
                      handleOnClick={async (j) => {
                        // console.log(code, j.code,"code");
                        if(code == j.code){
                          setCode("");
                          v.isSelect = false;
                          return;
                        }
                        setCode(j.code);
                        const coupon = data.row.map((k) => ({
                          ...k,
                          isSelect: j.id == k.id,
                        }));

                        setData((p) => ({
                          ...p,
                          selectedCoupon: j.code,
                          row: coupon,
                        }));
                      }}
                    />
                  );
                })
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ m: 3, textAlign: "center" }}>
                    No coupon available
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.540rem",
          }}
        >
          <ButtonView
            lable="Cancel"
            color="#E50000"
            bgcolor="#E5000033"
            fontSize="13px"
            sx={{ width: "10rem", height: "2.2rem" }}
            onClick={() => handleClose(0, null)}
          />

          <ButtonView
            lable="Apply"
            fontSize="13px"
            sx={{ width: "10rem", height: "2.2rem" }}
            onClick={async () => {
              if (code) {
                const res = await COUPENCanAPPLYTOORDER(
                  orderId,
                  code,
                  cityId,
                  userId
                );
                if (!res || !res.s) {
                  ToastShow(res?.m ?? "You can not apply this coupon.");
                  return;
                }
                handleClose(1, code);
              } else {
                ToastShow("Please select coupon or enter the code");
              }
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default GetCouponDailog;

function ViewOfCoupon({ data, handleOnClick }) {
  return (
    <Box
      bgcolor={!data.isSelect ? "#F6F6F6" : "#f895002f"}
      border={!data.isSelect ? "1px solid #EFEFEF" : "1px solid #F89400"}
      width="17.1rem"
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      paddingLeft="0.4rem"
      paddingY="0.2rem"
      gap="0.4rem"
      marginBottom="0.5rem"
      onClick={() => handleOnClick(data)}
    >
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            color={!data.isSelect ? "#969696" : "#F49200"}
            fontWeight="500"
          >
            Couponcode
          </Typography>

          {data.isSelect && (
            <Typography
              color={!data.isSelect ? "#969696" : "#F49200"}
              fontSize="11.7px"
              fontWeight="500"
              paddingRight="0.4rem"
            >
              Applied
            </Typography>
          )}
        </Stack>
        <Typography
          color={!data.isSelect ? "#969696" : "#595959"}
          fontSize="14px"
        >
          {data.code}
        </Typography>
      </Box>

      <Typography color="#969696" fontSize="11px">
        The point of using Loram Ipsum is that it has a more-o
      </Typography>
    </Box>
  );
}
