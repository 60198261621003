import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateMDYHMA from "../../../../Component/Date/DateMDYHMA";
import DataGrideView from "../../../../Component/DataGrideView/DataGrideView";
import { API_URL } from "../../../../Apis/config";
import SplitButton from "../../../../Component/Button/SplitButton";
import ConfirmationDailog from "../../../../Component/Dailog/ConfirmationDailog";
import {
  DELETE_WEB_CITY,
  GET_WEB_CITY,
} from "../../../../Apis/web/web_city_api";
import AddUpdateWebCityDailog from "../../../../Component/Dailog/web/AddUpdateWebCity";

function WebCity() {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isLoading, SetIsLoading] = useState(true);

  const getData = async () => {
    SetIsLoading(true);
    const res = await GET_WEB_CITY();

    if (res && res?.s) {
      setRow(res?.r ?? []);
    } else {
      setRow([]);
    }
    SetIsLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setSelectedRow(null);
    setOpen(false);
  };

  const handleCloseDelete = async (v) => {
    if (v) {
      await DELETE_WEB_CITY(selectedRow.id);
      getData();
    }
    setSelectedRow(null);
    setDeleteOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "SR.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      minWidth: 210,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "min_shoots",
      headerName: "Min-Shoots",
      filterable: false,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "photo",
      headerName: "Photo",
      filterable: false,
      maxWidth: 80,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return params.value ? (
          <Avatar
            onClick={() =>
              window.open(
                API_URL.baseUrl + API_URL.webCityImage + params.value,
                "_blank"
              )
            }
            sx={{ height: "30px", width: "30px", cursor: "pointer" }}
            src={API_URL.baseUrl + API_URL.webCityImage + params.value}
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 70,
      flex: 1,
      type: "number",
      headerAlign: "right",
      align: "right",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          // isView={rightsUser.includes(4) ? 1 : null}
          dataId={params.row.id}
          isDelete={1}
          isEdit={1}
          onEdit={onEdit}
          onView={null}
          onDelete={onDelete}
          // deleteText="Delete"
        />
      ),
    },
  ];

  const onEdit = (id) => {
    // console.log(id, "id");
    setSelectedRow(() => row.find((v) => v.id == id));
    setOpen(true);
  };

  const onDelete = (id) => {
    setSelectedRow(() => row.find((v) => v.id == id));
    setDeleteOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        {/* <Button
          variant="outlined"
          sx={{
            width: "115px",
            mb: 1,
          }}
          onClick={handleOpen}
        >
          <Typography sx={{ color: "primary.main" }}>Add City</Typography>
        </Button> */}

        <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
          <DataGrideView
            rows={row}
            columns={columns}
            // callBack={callBack}
            isLoading={isLoading}
            sx={{
              height: "75vh",
            }}
            autoHeight={true}
          />
        </Box>
      </Box>
      {open && (
        <AddUpdateWebCityDailog
          open={open}
          handleOnClose={handleClose}
          row={selectedRow}
        />
      )}

      {deleteOpen && (
        <ConfirmationDailog
          handleOnClose={handleCloseDelete}
          open={deleteOpen}
          content="Are you sure, You want to delete this city?"
          title="Delete City!"
        />
      )}
    </>
  );
}

export default WebCity;
