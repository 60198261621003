import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import AddOnShotLocationDailog from "../../../../../Component/Dailog/AddNewOnSpotLocationDailog";
import PackageOnSpot from "./Package";
import AddNewOnSpotPackage from "../../../../../Component/Dailog/AddNewOnSpotPackageDailog";

function RoleList({ getData, id, row,is_paid }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (v = null) => {
    setOpen(true);
  };

  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOpen(false);
  };

  return (
    <>
      <Paper
        sx={{
          p: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            gap: "15px",
            p: 1,
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h5">Role : {row.name}</Typography>
            <Button onClick={handleOpen}>Add Package</Button>
          </Box>
          <Divider />
          <PackageOnSpot
            getData={getData}
            id={id}
            row={row?.package}
            on_spot_roles_id={row?.id}
          />
        </Box>
      </Paper>

      {open ? (
        <AddNewOnSpotPackage
        is_paid={is_paid}
          row={null}
          handleOnClose={handleOnClose}
          open={open}
          on_spot_roles_id={row?.id}
        />
      ) : null}
    </>
  );
}

export default RoleList;
