import { useState } from "react";
import {
  Modal,
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GrClose } from "react-icons/gr";
import TextFieldSmall from "../../../../../Component/TextFields/TextFieldSmall";
import DateTime from "../../../../../Component/DateTime/DateTime";
import ButtonView from "../../../../../Component/Button/ButtonView";
import add_link from "../../../../../Assets/Icons for pages/add_link.png";
import { chnagePaymentRecord } from "../../../../../Apis/order_api";
import { ToastShow } from "../../../../../Component/Toast/Toast";

const AddPaymentRecord = ({ open, id, handleOnClose }) => {
  const [loading, setLoading] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  const [amount, setAmount] = useState("");
  const [method, setMethod] = useState("");
  const [note, setNote] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const handleSubmit = async () => {
    if (!amount) {
      ToastShow("Please enter amount");
    } else if (!method) {
      ToastShow("Please enter method");
    } else if (!note) {
      ToastShow("Please enter note");
    } else {
      setLoading(true);
      const res = await chnagePaymentRecord(
        id,
        amount,
        isRefund,
        method,
        note,
        transactionId
      );
      setLoading(false);
      if (res && res.s) {
        ToastShow("Success");
        handleOnClose(1);
      } else {
        ToastShow("Oops! something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleOnClose(0)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(4px)",
          bgcolor: "rgba(105, 55, 0, 0.4)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "6px",
            display: "flex",
            padding: "0.580rem",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Stack
            width="100%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.4rem"
            px="0.5rem"
          >
            <Typography
              fontWeight="500"
              fontSize="15px"
              sx={{ userSelect: "none" }}
            >
              Add Payment Record
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={() => handleOnClose(0)}
            />
          </Stack>

          <Stack
            width="100%"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="center"
            alignItems="center"
            py="0.5rem"
            px="0.580rem"
            gap="0.7rem"
          >
            <TextFieldSmall
              label="Amount"
              placeholder="Enter Amount`"
              bgColor="white"
              type="number"
              width="400px"
              height="4.250rem"
              inputHeight="2.180rem"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />

            <TextFieldSmall
              label="Method"
              placeholder="Enter Method"
              bgColor="white"
              width="400px"
              height="4.250rem"
              inputHeight="2.180rem"
              name="method"
              value={method}
              onChange={(e) => setMethod(e.target.value)}
            />

            <TextFieldSmall
              label="Transaction ID"
              placeholder="Enter Transaction ID"
              bgColor="white"
              width="400px"
              height="4.250rem"
              inputHeight="2.180rem"
              name="transactionID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />

            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "6px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                pb: "0.550rem",
              }}
            >
              <Box width="95%" height="100%">
                <Typography
                  pl=".2rem"
                  py=".2rem"
                  fontSize="13.5px"
                  sx={{ userSelect: "none" }}
                >
                  Note
                </Typography>

                <TextField
                  multiline
                  rows={3}
                  maxRows={3}
                  placeholder="Write Note"
                  type="text"
                  size="small"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    borderRadius: "6px",
                    width: "100%",

                    "& fieldset": { border: "none" },
                    "& .MuiFormLabel-root": {
                      color: "#969696",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        fontSize: "0.820rem",
                      },
                    "& .css-yqj9ow-MuiInputBase-root-MuiOutlinedInput-root": {
                      padding: "9px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Box>
            </Box>

            <Box width="100%" pl="0.6rem">
              <FormControlLabel
                label="Is Refund"
                required
                style={{ margin: "0px" }}
                control={
                  <Checkbox
                    icon={
                      <img
                        style={{ width: ".9rem", objectFit: "fill" }}
                        src={require("../../../../../Assets/UnCheckedIcon.png")}
                      />
                    }
                    checkedIcon={
                      <img
                        style={{ width: ".9rem", objectFit: "fill" }}
                        src={require("../../../../../Assets/Icons for pages/checked_icon.png")}
                      />
                    }
                    name="isRefund"
                    checked={isRefund}
                    onChange={(e) => setIsRefund(e.target.checked)}
                  />
                }
                sx={{
                  "& .css-1x2jons-MuiFormControlLabel-asterisk": {
                    display: "none",
                  },
                  "& .css-fbhpqu-MuiButtonBase-root-MuiCheckbox-root": {
                    padding: "0px",
                    marginRight: "0.3rem",
                  },
                  "& .css-7jdnmo-MuiTypography-root": {
                    fontSize: "0.750rem",
                    color: isRefund ? "#F8AF41" : "inherit",
                    userSelect: "none",
                  },
                }}
                name="isRefund"
                checked={isRefund}
                onChange={(e) => setIsRefund(e.target.checked)}
              />
            </Box>
          </Stack>

          <Stack width="100%" direction="row" gap="0.5rem">
            <ButtonView
              lable="Cancel"
              color="#E50000"
              bgcolor="#E5000033"
              fontSize="14px"
              sx={{ width: "100%" }}
              hoverBg="pink"
              onClick={() => handleOnClose(0)}
            />

            <ButtonView
              lable="Add Payment Record"
              fontSize="14px"
              hoverBg="#ffb952"
              sx={{ width: "100%" }}
              onClick={handleSubmit}
            />
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AddPaymentRecord;
