import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";
import { ADD_PAGE, ADD_PAGE_QUESTION } from "../../Apis/rolePage";
import paymentIcon from "../../Assets/paymentIcon.png";
import MultiSelection from "../Multiselection/MultiSelection";
import { Add, DeleteOutline } from "@mui/icons-material";
import { GrClose } from "react-icons/gr";
import ButtonView from "../Button/ButtonView";
import TextFieldLarge from "../TextFields/TextFieldLarge";
import RadioButtonNew from "../RadioButton/RadioButtonNew";
import RadioButtonSmall from "../RadioButton/RadioButtonSmall";
import TextFieldSmall from "../TextFields/TextFieldSmall";

function AddUpdatePagesQuestionDailog({
  open,
  handleOnClose,
  row,
  pageId,
  ordr,
}) {
  const [name, setName] = useState(row ? row?.name ?? "" : "");
  const [hint, setHint] = useState(row ? row?.hint ?? "" : "");
  const [choices, setChoices] = useState(
    row?.choices ? JSON.parse(row?.choices) : []
  );
  const [delimiter, setDelimiter] = useState(row ? row?.delimiter ?? "" : "");
  const [isRequird, setIsRequird] = useState(row ? row?.is_required ?? 0 : 0);
  const [isApproval, setIsApproval] = useState(row ? row?.is_approval ?? 0 : 0);
  const [isSeeCustomer, setIsSeeCustomer] = useState(
    row ? row?.is_see_customer ?? 0 : 0
  );
  const [selectedDataType, setSelectedDataType] = useState(
    row?.data_type ? [row?.data_type] : []
  );
  const [selectedDataType2, setSelectedDataType2] = useState(
    row?.data_type2 ? [row?.data_type2] : ["none"]
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleName = (v) => {
    setName(v);
  };

  const handleIsRequired = (v) => {
    setIsRequird(v);
  };

  const handleIsApproval = (v) => {
    setIsApproval(v);
  };
  const handleIsSeeCustomer = (v) => {
    setIsSeeCustomer(v);
  };

  const handleDelimiter = (v) => {
    setDelimiter(v);
  };

  const handleHint = (v) => {
    setHint(v);
  };

  const handleOnSubmit = async () => {
    if (!pageId) {
      ToastShow("Please select the page id");
    } else if (!name) {
      ToastShow("Please fill the name");
    } else if (!hint) {
      ToastShow("Please fill the hint");
    } else if (!delimiter) {
      ToastShow("Please fill the delimiter");
    } else if (isRequird == 2) {
      ToastShow("Please select the is required");
    } else if (isApproval == 2) {
      ToastShow("Please select the is approval");
    } else if (isSeeCustomer == 2) {
      ToastShow("Please select the is see customer");
    } else if (!selectedDataType.length) {
      ToastShow("Please select the is data type");
    } else if (!selectedDataType2.length) {
      ToastShow("Please select the is data type 2");
    } else if (
      (selectedDataType[0] == "singlechoice" ||
        selectedDataType[0] == "multichoice") &&
      !choices.filter((v) => v.trim().length).length
    ) {
      ToastShow("Please fill the is choice");
    } else {
      const res = await ADD_PAGE_QUESTION({
        role_form_pages_id: pageId,
        choices: choices,
        is_required: isRequird,
        is_approval: isApproval,
        hint: hint,
        name: name,
        data_type: selectedDataType[0],
        data_type2: selectedDataType2[0],
        delimiter: delimiter,
        id: row?.id ?? 0,
        ordr: ordr,
        is_see_customer: isSeeCustomer,
      });

      if (res && res.s) {
        ToastShow("Success");
        handleOnClose(1);
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
      }
    }
  };

  const handleDataTypeSelection = (e) => {
    const {
      target: { value },
    } = e;

    setSelectedDataType([value]);

    if (value == "singlechoice" || value == "multichoice") {
      setChoices([""]);
    } else {
      setChoices([]);
    }
  };
  const handleDataType2Selection = (e) => {
    const {
      target: { value },
    } = e;

    setSelectedDataType2([value]);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose(0)}
      sx={{
        backdropFilter: "blur(2px)",
        bgcolor: "rgba(105, 55, 0, 0.230)",
      }}
    >
      <Stack gap={1.5} m={1.7}>
        <Stack
          width="100%"
          direction="row"
          bgcolor="#F6F6F6"
          borderRadius="6px"
          justifyContent="space-between"
          alignItems="center"
          py="0.8rem"
          px="0.8rem"
        >
          <Typography
            fontWeight="500"
            fontSize="15px"
            sx={{ userSelect: "none" }}
          >
            {row ? "Update Question" : "Add Question"}
          </Typography>
          <GrClose
            style={{ cursor: "pointer", fontSize: "0.9rem" }}
            onClick={() => handleOnClose(0)}
          />
        </Stack>

        <TextFieldSmall
          value={name}
          onChange={(e) => handleName(e.target.value)}
          label="Name"
          placeholder="Enter name"
          width="350px"
        />

        <TextFieldSmall
          onChange={(e) => handleHint(e.target.value)}
          type="text"
          value={hint}
          label="Hint"
          placeholder="Enter a hint"
          width="350px"
        />

        <MultiSelection
          selected={selectedDataType}
          handleChange={handleDataTypeSelection}
          names={[
            "text",
            "datetime",
            "photo",
            "instagramid",
            "url",
            "multichoice",
            "integer",
            "boolean",
            "float",
            "photos",
           
            "fromtotime",
          ]}
          // "location",
          lable={"Data Type"}
          multiple={false}
          icon={paymentIcon}
          width="100%"
          containerWidth="100%"
          disableBoxShadow={true}
          bgcolor="#F6F6F6"
          noIcon={true}
        />
        {choices.map((v, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <TextFieldView
                key={index}
                onchange={(t) =>
                  setChoices((p) => {
                    return p.map((j, i) => (i == index ? t : j));
                  })
                }
                value={choices[index]}
                label={`Choice-(${index})`}
                type="text"
                sx={{ width: "100%", mt: 2, flex: 1 }}
                size="small"
              />
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Tooltip title="Add Choice">
                  <IconButton
                    sx={{
                      p: 0,
                      border: "solid 1px gray",
                    }}
                    onClick={() => {
                      setChoices((p) => [...p, ""]);
                    }}
                  >
                    <Add fontSize="12px" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete Choice">
                  <IconButton
                    sx={{
                      p: 0,
                      border: "solid 1px gray",
                    }}
                    onClick={() => {
                      setChoices((p) => p.filter((v, i) => i != index));
                    }}
                  >
                    <DeleteOutline fontSize="12px" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          );
        })}

        <MultiSelection
          selected={selectedDataType2}
          handleChange={handleDataType2Selection}
          names={[
            "none",    
            "photo",
            "photos"      
          ]}
          lable={"Data Type2"}
          multiple={false}
          width="100%"
          containerWidth="100%"
          icon={paymentIcon}
          disableBoxShadow={true}
          bgcolor="#F6F6F6"
          noIcon={true}
        />

        <TextFieldSmall
          onChange={(e) => handleDelimiter(e.target.value)}
          value={delimiter}
          label="Delimiter"
          placeholder="Enter delimiter"
          width="350px"
        />

        <Stack>
          <Typography>Is required ?</Typography>
          <RadioButtonSmall value={isRequird} handleChange={handleIsRequired} />
        </Stack>

        <Stack>
          <Typography>Is approval ?</Typography>
          <RadioButtonSmall
            value={isApproval}
            handleChange={handleIsApproval}
          />
        </Stack>

        <Stack>
          <Typography>Is see customer ?</Typography>
          <RadioButtonSmall
            value={isSeeCustomer}
            handleChange={handleIsSeeCustomer}
          />
        </Stack>
      </Stack>

      <DialogActions>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ButtonView
            lable={row ? "Update" : "Submit"}
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={handleOnSubmit}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AddUpdatePagesQuestionDailog;
