import { Box, TextField, Typography } from "@mui/material";
import React from "react";

const TextFieldLarge = ({
  label,
  width = "520px",
  type = "text",
  placeholder,
  name,
  value,
  onChange,
  maxLength,
  fieldWidth = "96%",
  mr = "0",
  alternateBg = false,
  fieldHeight = "2.3rem",
}) => {
  return (
    <Box
      width={width}
      height="4.3rem"
      bgcolor={alternateBg ? "white" : "#F6F6F6"}
      borderRadius="5px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mr={mr}
    >
      <Box width={fieldWidth}>
        <Typography fontSize="11.8px" mb=".3rem" style={{ userSelect: "none" }}>
          {label}
        </Typography>

        <TextField
          name={name}
          placeholder={placeholder}
          type={type}
          size="small"
          sx={{
            width: "100%",
            height: fieldHeight,
            display: "flex",
            justifyContent: "center",
            backgroundColor: alternateBg ? "#F6F6F6" : "white",
            borderRadius: "5px",
            "& fieldset": { border: "none" },
            "& .MuiFormLabel-root": {
              color: "#969696",
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "primary.main",
            },
            "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "0.8rem",
            },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: maxLength }}
          value={value}
          onChange={onChange}
          onWheel={(e) => e.target.blur()}
        />
      </Box>
    </Box>
  );
};

export default TextFieldLarge;
