import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";

function ButtonView({
  lable,
  onClick = () => {},
  sx = {},
  variant = "contained",
  startIcon,
  fontSize,
  fontWeight = "500",
  color = "#101010",
  bgcolor = "#F8AF41",
  hoverBg = "#cf9b4c",
  isDisable = false,
  isLoading = false,
}) {
  return (
    <Button
      disableElevation
      size="large"
      onClick={() => onClick()}
      sx={{
        ...sx,
        bgcolor: bgcolor,
        textTransform: "none",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: hoverBg,
        },
      }}
      variant={variant}
      startIcon={startIcon}
      disabled={isDisable}
    >
      {!isLoading && (
        <Typography
          sx={{ color: isDisable ? "grey" : color, fontSize, fontWeight }}
        >
          {lable}
        </Typography>
      )}
      {isLoading && (
        <Box py="0.1rem">
          <CircularProgress />
        </Box>
      )}
    </Button>
  );
}

export default ButtonView;
