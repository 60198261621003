import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
  onSnapshot,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db, storage } from "../../Service/FirebaseInit";
import ChatSenderView from "./ChatSenderView";
import message_send from "../../Assets/Icons for pages/message_send.png";
import { ToastShow } from "../Toast/Toast";
import { CHAT_NOTIFY } from "../../Apis/chat_notify_api";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import add_media from "../../Assets/Icons for pages/add_media.png";

function OrderChatPop({ id, open, anchorEl, handleClose, oId, uId, pIds }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getChatData();
  }, []);

  const getChatData = async () => {
    setIsLoading(true);
    const c = doc(db, "order_chat", oId);
    const data = await getDoc(c);

    if (data.exists) {
      const value = data?.data();
      if (value) {
        const cc = collection(db, "order_chat/" + oId, "conversation");

        const d = await getDocs(query(cc, orderBy("created_at", "desc")));
        console.log("data -> chat", d.docs[0]?.data());
        setData(d?.docs ?? []);
      }
    }
    setIsLoading(false);
  };

  const sendMessage = async (msg, type = 1) => {
    const c = doc(db, "order_chat", oId);
    const data = await getDoc(c);

    if (!data.exists()) {
      await setDoc(
        c,
        {
          created_at: Timestamp.now(),
          lastMessage: msg,
          reference_id: oId,
        },
        { merge: true }
      );
    }

    await addDoc(collection(db, "order_chat/" + oId, "conversation"), {
      userName: "Admin",
      profilePicture: "",
      message: msg,
      additional_meta: "",
      created_at: Timestamp.now(),
      order_id: oId,
      role: 0,
      send_by: 0,
      sp_id: 0,
      user_id: 0,
      type: type,
    });
    getChatData();

    await CHAT_NOTIFY({
      title: "Message From Admin On Order ID: " + oId,
      body: type == 1 ? msg : "Media",
      to_user_id: uId,
      order_id: oId,
      to_app: "1",
    });

    {
      pIds.length &&
        (await CHAT_NOTIFY({
          title: "Message From Admin On Order ID: " + oId,
          body: type == 1 ? msg : "Media",
          to_user_id: pIds.length > 1 ? pIds.join(",") : pIds[0],
          order_id: oId,
          to_app: "2",
        }));
    }

    if (type == 2) {
      ToastShow("Image uploaded!");
    }
  };

  //For image selection
  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(null);

  const uploadFile = async (file) => {
    const storageRef = ref(storage, `/chat/${Date.now() + file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          sendMessage(url, 2);
        });
      }
    );
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    uploadFile(file);
    setSelectedFile(file);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" sx={{ fontSize: "20px" }}>
            Chat
          </Typography>
        </Box>

        <Divider />
        <Box
          sx={{
            width: "300px",
            height: "500px",
            display: "flex",
            justifyContent: "start",
            overflowY: "auto",
            flexDirection: "column",
            mt: 2,
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ margin: "auto" }} />
          ) : data.length ? (
            data.map((v) => {
              return (
                <ChatSenderView
                  message={v.data().message}
                  userId={v.data().send_by}
                  type={v.data().type}
                  key={v.id}
                  createdAt={v.data().created_at}
                  role={v.data().role}
                />
              );
            })
          ) : (
            <Typography>Chat has not been started yet.</Typography>
          )}
        </Box>
        <SendMsg
          sendMessage={sendMessage}
          handleAttachmentClick={handleAttachmentClick}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
        />
      </Box>
    </Popover>
  );
}

export default OrderChatPop;

const SendMsg = ({
  sendMessage,
  handleAttachmentClick,
  handleFileChange,
  fileInputRef,
}) => {
  const [msg, setMsg] = useState("");

  return (
    <Stack width="100%" justifyContent="space-between" direction="row">
      <Box
        sx={{
          width: "85%",
          bgcolor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "6px",
        }}
      >
        <TextField
          placeholder="Type something..."
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();

              if (msg !== "") {
                sendMessage(msg);
                setMsg("");
              } else {
                ToastShow("Write something!");
              }
            }
          }}
          sx={{
            height: "2.4rem",
            width: "88%",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            " .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              boxSizing: "border-box",
              fontSize: "0.9rem",
            },
            "& fieldset": { border: "none" },
          }}
        />
        <button
          onClick={handleAttachmentClick}
          style={{ width: "12%", backgroundColor: "transparent" }}
        >
          {/*Icon*/}
          <img
            src={add_media}
            style={{ width: "14px", height: "14px", objectFit: "fill" }}
            alt=""
          />
          {/* Hidden file input */}
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </button>
      </Box>
      <button
        style={{
          width: "12%",
          backgroundColor: "#F89400",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          if (msg !== "") {
            sendMessage(msg);
            setMsg("");
          } else {
            ToastShow("Write something!");
          }
        }}
      >
        <img
          src={message_send}
          style={{ width: "18px", height: "18px", objectFit: "fill" }}
          alt=""
        />
      </button>
    </Stack>
  );
};
