import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";

function MultiSelectOld({
  selected,
  handleChange,
  names,
  lable,
  multiple = true,
  size = "small",
  disabled = false,
  width = 300,
}) {
  return (
    <div>
      <FormControl sx={{ m: 1, width: width }}>
        <InputLabel id="demo-multiple-checkbox-label">{lable}</InputLabel>
        <Select
          size={size}
          disabled={disabled}
          defaultValue=""
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={multiple}
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label={lable} />}
          renderValue={(select) => select.join(", ")}
          style={{ backgroundColor: "#FFFFFF" }}
          // MenuProps={MenuProps}
        >
          {names.map((name, i) => (
            <MenuItem key={i} value={name}>
              <Checkbox checked={selected.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiSelectOld;
