import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const MuiSelect = ({
  names,
  handleChange,
  selected,
  multiple = false,
  margin = 1,
  width = 300,
  label = "Please select City",
}) => {
  return (
    <div>
      <FormControl sx={{ m: margin, width: width }}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple={multiple}
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          <MenuItem value="All">All</MenuItem>
          {names.map((name, i) => (
            <MenuItem key={i} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MuiSelect;
