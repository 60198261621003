import { format } from "date-fns" ;
import moment from "moment/moment";

export const dateFormateMMDDYYY = (data)=>{
    const options = { year: "numeric", month: "long", day: "numeric", hour:"numeric",minute:"numeric"}
   try{
    const d = new Date(data).toISOString()
    return format(Date.parse(d),"dd-MMM-yyyy");
   }catch(e){
    return "";
   }
}

const formatDate = (dateString) => {
    // console.log("date",dateString)
    const options = { year: "numeric", month: "long", day: "numeric", hour:"numeric",minute:"numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

export const dateFormateHHMMA = (data,isUtc)=>{
    try {
        const date = new Date( moment(data).utc(false));
       // var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000 );
       // return moment(date).utc().format("YYYY-MM-DD, hh-mm-a");
         return format(date,"hh:mm a");
    }catch(e){
        return "";
    }
    
}

export const dateFormateMMDDYYYHHMMSS = (data)=>{
   try{
    const d = new Date(data).toISOString();
    return format(Date.parse(d),"dd-MMM-yyyy hh:mm a");
   }catch(e){
    return "";
   }
}