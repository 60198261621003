import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import Compressor from "compressorjs";
import { PhotoCamera } from "@mui/icons-material";
import MultiSelection from "../Multiselection/MultiSelection";
import {
  ADD_PRAMOTIONAL_BANNER,
  GET_PRAMOTIONAL_BANNER_DETAILS,
} from "../../Apis/pramotional_banner_api";
import { API_URL } from "../../Apis/config";
import TextFieldView from "../TextField/TextFieldView";
import TextFieldSmall from "../TextFields/TextFieldSmall";
import Selection from "../Multiselection/Selection";
import MuiSelect from "../Multiselection/MuiSelect";

function AddBanner({
  open,
  handleOnClose,
  cities,
  citiesW,
  id,
  src = "",
  urls = "",
  cityId,
}) {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImageCover, setSelectedImageCover] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [url, setUrl] = React.useState(urls);

  const handleSelectCity = (event) => {
    const {
      target: { value },
    } = event;

    if (value[0] === "All") {
      setSelectedCities([...cities]);
    } else if (value.includes("All")) {
      setSelectedCities([]);
    } else {
      setSelectedCities(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleOnSubmit = async () => {
    if (!selectedImage && !src) {
      ToastShow("Please select image");
    } else if (!selectedCities.length && !id) {
      ToastShow("Please select city");
    } else {
      let c = [];
      if (selectedCities.length === 1) {
        c = citiesW.filter((v) => v.name == selectedCities[0]);
      } else {
        for (let i = 0; i < selectedCities.length; i++) {
          let n = citiesW.filter((v) => v.name == selectedCities[i]);
          c.push(n[0].id);
        }
      }
      console.log(c.length === 1 ? `${c[0].id}` : c.join(","));

      setIsLoading(true);
      const d = await ADD_PRAMOTIONAL_BANNER({
        file: selectedImage,
        city_id: !id
          ? c.length === 1
            ? c[0].id
            : c.join(",")
          : bannerData?.cities?.map((e) => e.city_id).join(","),
        id: id,
        url: url,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow(d.m);
        handleOnClose(1);
      } else {
        ToastShow(d.m);
      }
    }
  };

  const [bannerData, setBannerData] = useState({});
  const getBannerDetails = async () => {
    const res = await GET_PRAMOTIONAL_BANNER_DETAILS(id);
    if (res && res.s) {
      setBannerData(res?.r);
    } else {
      ToastShow(res.m);
    }
  };

  useEffect(() => {
    getBannerDetails();
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!id ? "Add Banner" : "Update Banner"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "150px",
              width: "150px",
              border: "dotted .3px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "fill",
              p: "1px",
            }}
          >
            {src || selectedImage ? (
              <img
                style={{ borderRadius: "5px" }}
                height="150px"
                width="150px"
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : API_URL.baseUrl + API_URL.bannerImage + src
                }
                alt="banner image"
              />
            ) : (
              <Typography>Select Image</Typography>
            )}
          </Box>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      setSelectedImage(compressedResult);
                    },
                  });
                  new Compressor(event.target.files[0], {
                    quality: 0.5,
                    success: (compressedResult) => {
                      setSelectedImageCover(compressedResult);
                    },
                  });
                }
              }}
            />
            <PhotoCamera />
          </IconButton>

          <Box mb={2} width="100%">
            <TextFieldSmall
              label="Url"
              placeholder="Enter url"
              name="Url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              width="100%"
            />
          </Box>

          {id == 0 ? (
            <MuiSelect
              multiple={true}
              names={cities}
              handleChange={handleSelectCity}
              selected={selectedCities}
            />
          ) : null}
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddBanner;
