import React from "react";
import { Box, TextField, Typography } from "@mui/material";

const TextFieldSmall = ({
  label,
  width = "14rem",
  height = "3.8rem",
  type = "text",
  placeholder,
  name,
  value,
  onChange,
  bgColor,
  inputHeight = "1.9rem",
  disabled = false,
  inputFieldWidth = "95%",
}) => {
  return (
    <Box
      width={width}
      height={height}
      bgcolor={bgColor ? bgColor : "#F6F6F6"}
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY="4.2px"
      gap="4px"
    >
      <Typography width="97%" fontSize="11.8px" sx={{ userSelect: "none" }}>
        {label}
      </Typography>

      <TextField
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        type={type}
        size="small"
        sx={{
          width: inputFieldWidth,
          height: inputHeight,
          backgroundColor: bgColor ? "#EFEFEF" : "white",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          "& fieldset": { border: "none" },
          "& .MuiFormLabel-root": {
            color: "#969696",
          },
          "& .MuiFormLabel-root.Mui-focused": {
            color: "primary.main",
          },
          "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
            fontSize: "0.750rem",
            paddingX: "9px",
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        onChange={onChange}
        onWheel={(e) => e.target.blur()}
      />
    </Box>
  );
};

export default TextFieldSmall;
