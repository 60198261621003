import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_PODIANS_PAYOUT = async (data) => {
  let query = "";

  if (data.city_ids) {
    query += "&city_ids=" + data.city_ids;
  }

  if (data.filter) {
    query += "&filter=" + data.filter;
  }

  if (data.order) {
    query += "&order=" + data.order;
  }

  if (data.count) {
    query += "&count=" + data.count;
  }

  if (data.search) {
    query += "&search=" + data.search;
  }

  const res = await GETAPI(API_URL.getPodians, query);
  return res;
};

export const GET_BALANCE_PAYOUT = async (id) => {
  let query = "";
  query += "&user_id=" + id;

  const res = await GETAPI(API_URL.getBalance, query);
  return res;
};

export const GET_MONEY_TRANS = async (data) => {
  let query = "";
  query += "&provider_user_id=" + data.user_id;

  if (data.from_type) {
    query += "&from_type=" + data.from_type;
  }
  if (data.type) {
    query += "&type=" + data.type;
  }
  if (data.count) {
    query += "&count=" + data.count;
  }

  const res = await GETAPI(API_URL.getMoneyTrans, query);
  return res;
};

export const ADD_PAYOUT = async (data) => {
  const form = new FormData();

  form.append("provider_user_id", data.userId);
  form.append("amount", data.amount);

  const res = await POSTAPI(API_URL.addPayout, form);
  return res;
};

export const ADD_RAZOR_PAYOUT = async (data) => {
  const form = new FormData();

  form.append("provider_user_id", data.userId);
  form.append("amount", data.amount);

  const res = await POSTAPI(API_URL.addRazorPayout, form);
  return res;
};

export const DELETE_PAYOUT = async (id) => {
  let query = "";
  query += "&wallet_id=" + id;

  const res = await GETAPI(API_URL.deletePayout, query);
  return res;
};
