import React, { createContext, useContext, useEffect, useState } from "react";
import tokens from "../Apis/config";
import { GET_UNREAD_COUNT } from "../Apis/unreadData";

const AuthProvider = createContext();
function AuthContext({ children }) {
  const [user, setUser] = useState({ s: 0, m: "", r: null });

  const login = (data, isRemember) => {
    setUser(data);
    // console.log("apikey", JSON.stringify(data.r));

    // console.log("apikey", JSON.stringify(tokens));
    // console.log("data", data);
    if (data) {
      try {
        tokens.apikey = data.r.auth.apikey;
        tokens.token = data.r.auth.token;
      } catch (e) {}
    }
    if (isRemember) {
      localStorage.setItem("user", JSON.stringify(data));
    }
  };

  // const getUnreadData = async () => {
  //   const d = await GET_UNREAD_COUNT();
  // };

  // useEffect(() => {
  //   try {
  //     const data = localStorage.getItem("user");
  //     if (data) {
  //       const d = JSON.parse(data);
  //       tokens.apikey = d.r.auth.apikey;
  //       tokens.token = d.r.auth.token;
  //       getUnreadData();
  //     }
  //   } catch (e) {}
  // }, []);

  const logout = (data) => {
    localStorage.clear();
    setUser(data);
  };

  return (
    <AuthProvider.Provider
      value={{
        u: user.r,
        login,
        logout,
        isLogin:
          user &&
          user.r &&
          user.r.auth &&
          user.r.auth.apikey &&
          user.r.auth.token
            ? 1
            : 0,
      }}
    >
      {children}
    </AuthProvider.Provider>
  );
}

export default AuthContext;

export const UserContext = () => {
  const auth = useContext(AuthProvider);
  return auth;
};
