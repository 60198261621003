import * as React from "react";
import { Settings, Delete, Edit, Visibility } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import Edit_pencil from "../../Assets/Edit_pencil.png";
import Delete_icon from "../../Assets/Delete_icon.png";
import View from "../../Assets/View.png";
import history from "../../Assets/Icons for pages/history.png";
import add_primary from "../../Assets/Icons for pages/add_primary.png";

export default function SplitButton({
  dataId,
  isDelete = false,
  isEdit = false,
  isView,
  isHistory,
  isClear,
  isAdd,
  onView,
  onEdit,
  onDelete,
  onHistory,
  onAdd,
  onClear,
  viewText = "View",
  editText = "Edit",
  deleteText = "Delete",
  hasBoxShadow = false,
  change = false,
  clearText,
  clearIcon,
  deleteIcon,
  visible = 1,
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      {!change && (
        <Avatar
          variant="square"
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          sx={{
            width: "1.5rem",
            height: "1.7rem",
            cursor: "pointer",
            bgcolor: "#F6F6F6",
            borderRadius: "4px",
          }}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          color="inherit"
        >
          <img
            src={require("../.././Assets/Menu Dots.png")}
            style={{
              objectFit: "fill",
              width: "100%",
            }}
            alt=""
          />
        </Avatar>
      )}

      {change && (
        <Button onClick={handleClick} style={{ minWidth: "0", padding: "0" }}>
          <img
            src={require("../../Assets/Icons for pages/three_dots.png")}
            style={{ objectFit: "fill", width: "1.1rem", cursor: "pointer" }}
            alt=""
          />
        </Button>
      )}

      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                borderRadius: "6px",
                boxShadow: 2,
                marginRight: "2rem",
                // paddingY: ".4rem",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ paddingX: ".4rem" }}>
                  {isView && (
                    <ListItemButtonView
                      icon={<img src={View} alt="" />}
                      // icon={<Visibility sx={{ height: "20px" }} />}
                      text={viewText}
                      onClick={() => {
                        onView(dataId);
                        handleClose();
                      }}
                    />
                  )}
                  {isEdit && visible != 0 && (
                    <ListItemButtonView
                      icon={<img src={Edit_pencil} alt="" />}
                      text={editText}
                      onClick={() => {
                        handleClose();
                        console.log(dataId);
                        onEdit(dataId);
                      }}
                    />
                  )}
                  {isAdd && (
                    <ListItemButtonView
                      icon={<img src={add_primary} alt="" />}
                      // icon={<Visibility sx={{ height: "20px" }} />}
                      text={"Add New Link"}
                      onClick={onAdd}
                    />
                  )}
                  {isHistory && (
                    <ListItemButtonView
                      icon={<img src={history} alt="" />}
                      // icon={<Visibility sx={{ height: "20px" }} />}
                      text={"History"}
                      onClick={onHistory}
                    />
                  )}
                  {isDelete && visible != 0 && (
                    <ListItemButtonView
                      icon={deleteIcon ?? <img src={Delete_icon} alt="" />}
                      // icon={<Delete sx={{ height: "20px" }} />}
                      text={deleteText}
                      onClick={() => {
                        onDelete(dataId);
                        handleClose();
                      }}
                    />
                  )}

                  {isClear && (
                    <ListItemButtonView
                      icon={clearIcon ?? <HighlightOffIcon />}
                      // icon={<Delete sx={{ height: "20px" }} />}
                      text={clearText ?? ""}
                      onClick={() => {
                        onClear();
                        handleClose();
                      }}
                    />
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

function ListItemButtonView({ icon, text, onClick }) {
  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        width: "9rem",
        boxShadow: "0px 12px 8px -15px #00000040",
        paddingY: ".5rem",
      }}
    >
      <ListItemIcon
        sx={{
          marginRight: "-1.8rem",
          marginLeft: "-0.6rem",
          marginBottom: "-0.3rem",
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        sx={{ marginBottom: "-0.1rem" }}
        primary={<Typography fontSize="12px">{text}</Typography>}
      />
    </ListItemButton>
  );
}
