import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { GETONSPOTPROVIDERBOOKING } from "../../../../../../Apis/new_on_spot_api";
import MultiSelection from "../../../../../../Component/Multiselection/MultiSelection";
import ConfirmationDailog from "../../../../../../Component/Dailog/ConfirmationDailog";
import DataGrideView from "../../../../../../Component/DataGrideView/DataGrideView";
import DateMDYHMA from "../../../../../../Component/Date/DateMDYHMA";
import NewOnSpotProviderLink from "../../../../../../Component/Dailog/NewOnSpotProviderLinkDailog";

function NewOnSpotProviderBookingList() {
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const type = ["All", "Upcomming", "Completed"];
  const [selectedType, setSelectedType] = React.useState(["All"]);
  const [typeId, setTypeId] = React.useState("0");
  const [seletedRow, setSelectedRow] = React.useState(null);
  const navigation = useNavigate();
  const { providerId } = useParams();

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
    setTypeId(getValue(value));
    getData(getValue(value), 0);
  };

  const getValue = (v) => {
    let r = 0;

    if (v == "All") {
      r = "0";
    } else if (v == "Upcomming") {
      r = "1";
    } else {
      return "2";
    }

    return r;
  };

  const getData = async (s, count) => {
    setIsLoading(true);
    const t = await GETONSPOTPROVIDERBOOKING(s, providerId, count);

    if (t && t.s == 1) {
      if (t.r) {
        setRows([...t.r]);
      }
    }
    setIsLoading(false);
  };

  const handleOnClose = (p) => {
    setOpen(false);
    if (p) {
      setRows([]);
      getData(typeId, 0);
    }
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    // if (v) {
    //   const r = await DELETEONSPOTNEW(selectedId);
    //   if (r && r.s) {
    //     const d = rows.filter((v) => v.id != selectedId);
    //     setRows([...d]);
    //     ToastShow("Deleted successfully");
    //   } else {
    //     ToastShow("Opps something went wrong. Please try again.");
    //   }
    // }
    // setSelectedIdN(0);
    // setOpenConfirmation(false);
  };

  useEffect(() => {
    getData(typeId, 0);
  }, []);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "Sr.",
    //   filterable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return params.api.getRowIndex(params.row.id) + 1;
    //   },
    // },
    {
      field: "id",
      headerName: "ID",
      filterable: false,
      headerAlign: "center",
      align: "center",
      // renderCell: (params) => {
      //   return params.api.getRowIndex(params.row.id) + 1;
      // },
    },

    {
      field: "mobile",
      headerName: "Mobile",
      width: 190,
      headerAlign: "center",
      align: "center",
      // flex: 1,
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 190,
      headerAlign: "center",
      align: "center",
      // flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      headerAlign: "center",
      align: "center",
      // flex: 1,
    },
    {
      field: "minutes",
      headerName: "Minutes",
      width: 100,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "finalAmount",
      headerName: "Final Amount",
      width: 190,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>₹{params.value}</Typography>;
      },
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      width: 190,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>₹{params.value}</Typography>;
      },
    },
    {
      field: "paid_amount",
      headerName: "Paid Amount",
      width: 190,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>₹{params.value}</Typography>;
      },
    },
    {
      field: "created_at",
      headerName: "C.Date",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} isUtc={false} />;
      },
    },

    {
      field: "linkEdited",
      headerName: "Link",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box>
            <Button variant="text" onClick={()=>{
                setSelectedRow(params.row);
                setOpen(true);
            }}>Link</Button>
          </Box>
        );
      },
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerAlign: "center",
    //   align: "center",
    //   width: 150,
    //   renderCell: (params) => (
    //     <SplitButton
    //       key={params.id}
    //       dataId={params.id}
    //       isDelete={1}
    //       onDelete={onDelete}
    //       title={params.value}
    //       onEdit={onEdit}
    //       isEdit={1}
    //       viewText="Publish"
    //       isView={1}
    //     />
    //   ),
    // },
  ];

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(typeId, rows.length);
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "80vh",
      }}
    >
      <MultiSelection
        size="small"
        selected={selectedType}
        handleChange={handleSelectType}
        names={type}
        lable={"Type"}
        multiple={false}
      />
      <DataGrideView
        callBack={callBack}
        isLoading={isLoading}
        rows={rows}
        columns={columns}
        sx={{ height: "70vh" }}
      />

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete on-spot !"
          content="Are you sure, You want to delete the on-spot?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
      {open ? <NewOnSpotProviderLink handleOnClose={handleOnClose} id={seletedRow.id} linkEdited={seletedRow.linkEdited} providerId={providerId} on_spot_id={seletedRow.on_spot_id} openD={open} /> : null}
    </Box>
  );
}

export default NewOnSpotProviderBookingList;
