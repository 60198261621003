import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { RESTRICT_USER } from "../../Apis/restrict_users";
import { ADD_UPDATE_LINK } from "../../Apis/add_link_order";
import { GrClose } from "react-icons/gr";
import TextFieldSmall from "../TextFields/TextFieldSmall";
import DateTime from "../DateTime/DateTime";
import RadioButtonNew from "../RadioButton/RadioButtonNew";

function AddLinkOrder({ open, handleOnClose, row, id, order_ids }) {
  const d = new Date();
  const dd = d.setDate(d.getDate() + 7);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = React.useState(row ? row.link : "");
  const [clicks, setClicks] = React.useState(row ? row.clicks : "");
  const [type, setType] = React.useState(row ? row.type : 2);
  const [clips, setClips] = React.useState(row ? row.clips : "");
  const [editor, setEditor] = React.useState(row ? row.editor : "");
  const [startDate, setStartDate] = React.useState(
    row ? (row.expiry ? Date.parse(row.expiry) : dd) : dd
  );

  const handleOnSubmit = async () => {
    if (!link.length) {
      ToastShow("Please enter the link");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_LINK(
        link ?? null,
        startDate ? format(startDate, "yyyy-MM-dd HH:mm:ss") : null,
        editor,
        clips,
        clicks,
        id,
        order_ids,
        type
      );
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  const handleType = (e) => {
    setType(e);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Stack width="430px" justifyContent="center" alignItems="center">
          <Stack
            width="95%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            my="0.7rem"
            py="0.4rem"
            px="1rem"
          >
            <Typography fontWeight="500" sx={{ userSelect: "none" }}>
              Customer Submit Link
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={() => handleOnClose(0)}
            />
          </Stack>
          <Stack
            width="95%"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="center"
            alignItems="center"
            py="0.5rem"
            px="0.6rem"
            gap="0.7rem"
          >
            {id == 0 ? (
              <TextFieldSmall
                label="Editor Name"
                placeholder="Enter Editor Name"
                bgColor="white"
                width="100%"
                height="4.3rem"
                inputHeight="2.2rem"
                name="editor_name"
                value={editor}
                onChange={(e) => setEditor(e.target.value)}
              />
            ) : null}

            <TextFieldSmall
              label="Number of Clicks"
              placeholder="Enter Number of Clicks"
              bgColor="white"
              width="100%"
              height="4.3rem"
              inputHeight="2.2rem"
              name="no_of_clicks"
              value={clicks}
              onChange={(e) => setClicks(e.target.value)}
            />

            <TextFieldSmall
              label="Number of Clips"
              placeholder="Enter Number of Clips"
              bgColor="white"
              width="100%"
              height="4.3rem"
              inputHeight="2.2rem"
              value={clips}
              onChange={(e) => setClips(e.target.value)}
            />

            <DateTime
              label="Expiry Date/Time"
              width="100%"
              height="4.3rem"
              bgColor="white"
              inputHeight="2.2rem"
              name="dateTime"
              value={startDate}
              onChange={(v) => {
                setStartDate(v);
              }}
              minDateTime={Date.now()}
            />

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                mt: 1,
              }}
            >
              <Typography textAlign="start" fontSize={12}>
                Select the type
              </Typography>
              <RadioGroup row value={type} sx={{ width: "100%" }}>
                <FormControlLabel
                  value={1}
                  onClick={(e) => handleType(e.target.value)}
                  control={<Radio />}
                  label={<Typography variant="subtitle2">Raw</Typography>}
                />
                <FormControlLabel
                  value={2}
                  onClick={(e) => handleType(e.target.value)}
                  control={<Radio />}
                  label={<Typography variant="subtitle2">Edited</Typography>}
                />
              </RadioGroup>
            </Box>  */}

            <RadioButtonNew
              value={type}
              handleChange={handleType}
              width="100%"
              btnWidth="40%"
              justifyContent="none"
              gapInButtons="1rem"
              labelA="RAW"
              labelB="Edited"
              placeholder="Add type of link"
            />

            <TextFieldSmall
              label="Link"
              placeholder="Enter Link"
              bgColor="white"
              width="100%"
              height="4.3rem"
              inputHeight="2.2rem"
              name="no_of_clicks"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Stack>

          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              sx={{
                my: "0.8rem",
                width: "95%",
                bgcolor: "#FFAB30",
                color: "black",
                textTransform: "capitalize",
                fontSize: "15px",
                "& .css-1b8c02c-MuiButtonBase-root-MuiButton-root": {
                  minWidth: "0px",
                },
                "&:hover": {
                  bgcolor: "#a87d3b",
                },
              }}
              onClick={handleOnSubmit}
            >
              Customer Submit Link
            </Button>
          )}
        </Stack>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddLinkOrder;
