import { Button, Typography } from "@mui/material";
import React from "react";

const ButtonIcon = ({
  height = "2.2rem",
  label,
  icon,
  bgcolor,
  onClick,
  imageSize = "13px",
  fontSize = "15px",
  width = "100%",
  gap = " 0.8",
  boxShadow = false,
}) => {
  return (
    <Button
      sx={{
        width: width,
        height: height,
        bgcolor: bgcolor,
        paddingX: "0.9rem",
        display: "flex",
        gap: gap,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px",
        boxShadow: boxShadow && "3px 3px 7px 0px #00000040",
        "&:hover": {
          bgcolor: "#a87d3b",
        },
      }}
      onClick={onClick}
    >
      <img
        src={icon}
        alt=""
        style={{ objectFit: "fill", width: imageSize, height: imageSize }}
      />
      <Typography
        textTransform="capitalize"
        fontSize={fontSize}
        fontWeight="500"
      >
        {label}
      </Typography>
    </Button>
  );
};

export default ButtonIcon;
