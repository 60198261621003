import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_CITY = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("status", data.status);
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("address", data.address);
  formData.append("free_distance", data.freeDistance);
  formData.append("charge_per_km", data.chargePerKm);
  formData.append("alternate_names", JSON.stringify(data.alternateNames));
  formData.append("fixed_distance_charges", data.fixedDistanceCharges);
  formData.append("price_hike", data.priceHike);
  formData.append("canEditBefore", data.canEditBefore ?? 0);

  if (data?.categories?.length) {
    formData.append("categories", JSON.stringify(data.categories));
  }

  console.log(JSON.stringify(data.categories));

  if (data.bufferTime) {
    formData.append("bufferTime", data.bufferTime);
  }

  if (data.orderCanAfterTime) {
    formData.append("orderCanAfterTime", data.orderCanAfterTime);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.sgstLabel) {
    formData.append("sgst_label", data.sgstLabel);
  }

  if (data.sgst) {
    formData.append("sgst", data.sgst);
  }
  // if (data.cgstLabel) {
  //   formData.append("cgst_label", data.cgstLabel);
  // }

  if (data.cgst) {
    formData.append("cgst", data.cgst);
  }

  if (data.cgstLabel) {
    formData.append("cgst_label", data.cgstLabel);
  }

  // if (data.sgst) {
  //   formData.append("cgst", data.cgst);
  // }

  formData.append("provider_support_mobile", data.providerSM);

  const res = await POSTAPI(
    data.id ? API_URL.updateCity : API_URL.addCity,
    formData
  );

  return res;
};

export const ADD_UPDATE_CITY_STATUS = (data) => {
  const f = new FormData();

  f.append("status", data.status);
  f.append("id", data.id);

  return POSTAPI(API_URL.updateCity, f);
};

export const GET_CITIES = async ({ count = 0, status = "1", search = "" }) => {
  let s = "";
  if ((search?.length ?? 0) > 0) {
    s = "&search=" + search;
  }
  const res = await GETAPI(
    API_URL.getCity,
    "&count=" + count + "&status=" + status + s
  );

  return res;
};

export const GET_CITIE = async () => {
  const res = await GETAPI(API_URL.getCity);
  return res;
};

export const GET_CITIES_DETAILS = async (id) => {
  const res = await GETAPI(API_URL.getDetailsCity, "&id=" + id);
  return res;
};

export const ADD_UPDATE_CITY_DISCOUNT = async (data) => {
  const formData = new FormData();
  formData.append("city_services_id", data.cityServicesId);
  if (data.id) {
    formData.append("id", data.id);
  }
  formData.append("type", data.type);
  formData.append("apply", data.applicable);
  formData.append("on", data.apply);
  formData.append("isPercentage", data.isPercentage);
  formData.append("maxAmount", data.maxAmount);

  const res = await POSTAPI(API_URL.addDiscountOnService, formData);
  return res;
};

export const GET_CITY_DISCOUNT = async (id) => {
  const res = await GETAPI(
    API_URL.getDiscountOnService,
    "&city_services_id=" + id
  );
  return res;
};

export const DELETE_CITY_DISCOUNT = async (id) => {
  const res = await GETAPI(
    API_URL.deleteDiscountOnService,
    "&city_services_discount_id=" + id
  );
  return res;
};
