import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import AddNewOnSpotLinkD from "./NewOnD.jsx";
import { format } from "date-fns";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import ConfirmationDailog from "../../../../../../Component/Dailog/ConfirmationDailog.jsx";
import {
  DELETEONSPOTNEWLINK,
} from "../../../../../../Apis/new_on_spot_api.js";
import { ToastShow } from "../../../../../../Component/Toast/Toast.jsx";

function EditedLinkDetails({ row, on_spot_id, getData }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setOpen(false);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        p:2,my:1
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography variant="h5">Link</Typography>
        <Button
          onClick={() => {
            handleOpen();
          }}
        >
          Add Link
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {row.linkEdited.length
          ? row.linkEdited.map((v) => {
              return <LinkView key={v.id} i={v.id} v={v} getData={getData} on_spot_id={on_spot_id}/>;
            })
          : null}
      </Box>

      {open ? (
        <AddNewOnSpotLinkD
          row={null}
          open={open}
          handleOnClose={handleClose}
          on_spot_city_location_id={row.id}
          id={0}
          on_spot_id={on_spot_id}
        />
      ) : null}
    </Paper>
  );
}

export default EditedLinkDetails;

function LinkView({ v, i, on_spot_id, id, getData }) {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const onDelete = async () => {
    setOpenConfirmation(true);
  };
  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTNEWLINK({
        id: i,
      });
      if (r && r.s) {
        getData();
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setOpenConfirmation(false);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (v) => {
    if(v){
      getData();
    }
    setOpen(false);
  };

  return (
    <>
      <Box
        key={i}
        sx={{
          borderRadius: 1,
          p: 1,
          my: 2,
          WebkitBoxShadow: " -5px -8px 57px -24px rgba(145,136,145,0.48)",
          boxShadow: "-5px -8px 57px -24px rgba(145,136,145,0.48)",
        }}
      >
        <Box display="flex">
          <Typography>Link : </Typography>
          <Box
            onClick={() => window.open(v.link)}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                ml: "3px",
                color: "blue",
                opacity: 0.7,
              }}
            >
              {v.link}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 1 }} />
        <Typography sx={{ fontSize: "14px" }}>
          Expiry :{" "}
          {!v?.expiry
            ? "-"
            : format(Date.parse(v.expiry), "yyyy-MMM-dd hh:mm a")}
        </Typography>
        <Box sx={{ mt: 1 }} />
        <Typography sx={{ fontSize: "14px" }}>
          Editor : {v?.editor ?? "-"}
        </Typography>
        <Box sx={{ mt: 1 }} />
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Box display="flex">
            <Typography sx={{ fontSize: "14px" }}>
              Clicks : {v.clicks}
            </Typography>
            <Typography sx={{ ml: "10px", fontSize: "14px" }}>
              Clips : {v.clips}
            </Typography>
          </Box>
        </Box>

        <Box display="flex">
          <Typography sx={{ fontSize: "14px" }}>
            Created At :{" "}
            {format(Date.parse(v.created_at), "yyyy-MMM-dd hh:mm a")}
          </Typography>
        </Box>
        <Box display="flex">
          <IconButton onClick={() => handleOpen()}>
            <EditOutlined color="primary" />
          </IconButton>

          <IconButton onClick={() => onDelete()}>
            <DeleteOutline color="error" />
          </IconButton>
        </Box>
      </Box>

      {open ? (
        <AddNewOnSpotLinkD
          row={v}
          open={open}
          handleOnClose={handleClose}
          on_spot_city_location_id={id}
          id={v.id}
          on_spot_id={on_spot_id}
        />
      ) : null}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete Link !"
          content="Are you sure, You want to delete the link?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}
