import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { ToastShow } from "../../../Component/Toast/Toast";
import SplitButton from "../../../Component/Button/SplitButton";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import AddOnSpotCategoryDailog from "../../../Component/Dailog/AddOnSpotCategory";
import {
  DELETEONSPOTNEW,
  GETONSPOTNEW,
  PUBLISHONSPOTNEW,
  UnPUBLISHONSPOTNEW,
} from "../../../Apis/new_on_spot_api";
import { Avatar, Typography } from "@mui/material";
import { API_URL } from "../../../Apis/config";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import { useNavigate } from "react-router-dom";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { BoxShadow } from "@shopify/react-native-skia";

function NewOnSpotList() {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectdRow, setSelectedRows] = React.useState(null);
  const type = ["Published", "Draft"];
  const [selectedType, setSelectedType] = React.useState(["Published"]);
  const [typeId, setTypeId] = React.useState("1");
  const navigation = useNavigate();
  const [isCall, setIsCall] = useState(true);

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
    setTypeId(getValue(value));
    getData(getValue(value), 0);
  };

  const getValue = (v) => {
    let r = 0;

    if (v == "Published") {
      r = "1";
    } else {
      r = "0";
    }

    return r;
  };

  const getData = async (s, count) => {
    setIsLoading(true);
    const t = await GETONSPOTNEW(s, count);

    if (t && t.s == 1) {
      if (t.r) {
        setRows([...t.r]);
      }
    }
    setIsLoading(false);
  };

  const handleOnClose = (p) => {
    setOpen(false);
    if (p) {
      setRows([]);
      getData(typeId, 0);
    }
  };

  const onEdit = (v) => {
    // console.log(v);
    console.log('v', v);
    const t = rows.filter((j) => j.id == v);
    console.log('t', t);
    navigation("/new-onSpot/edit/" + t[0].id, "_blank");

    // console.log(t.length);
    // setSelectedRows(t[0]);
    // setOpen(true);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openConfirmationP, setOpenConfirmationP] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTNEW(selectedId);
      if (r && r.s) {
        const d = rows.filter((v) => v.id != selectedId);
        setRows([...d]);
        ToastShow("Deleted successfully");
      } else {
        ToastShow(r?.m ?? "Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  const onPublish = async (id) => {
    handleOpenConfirmationP(id);
  };

  const handleOpenConfirmationP = (id) => {
    setSelectedIdN(id);
    setOpenConfirmationP(true);
  };

  const handleOnCloseConfirmationP = async (v) => {
    if (v) {
      if (typeId == 1) {
        const r = await UnPUBLISHONSPOTNEW(selectedId);
        if (r && r.s) {
          ToastShow("Un-Published successfully");
          getData(typeId, 0);
        } else {
          ToastShow(r?.m ?? "Opps something went wrong. Please try again.");
        }
      } else {
        const r = await PUBLISHONSPOTNEW(selectedId);
        if (r && r.s) {
          ToastShow("Published successfully");
          getData(typeId, 0);
        } else {
          ToastShow(r?.m ?? "Opps something went wrong. Please try again.");
        }
      }
    }
    setSelectedIdN(0);
    setOpenConfirmationP(false);
  };

  useEffect(() => {
    getData(typeId, 0);
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "logo",
      headerName: "logo",
      width: 190,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Avatar
            sx={{
              cursor: "pointer",
            }}
            onClick={() =>
              window.open(
                API_URL.baseUrl + API_URL.onspot2LogoURL + "/" + params.value,
                "_blank"
              )
            }
            src={API_URL.baseUrl + API_URL.onspot2LogoURL + "/" + params.value}
          />
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 190,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "category",
      headerName: "Category",
      width: 190,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "isPaid",
      headerName: "Is-Paid",
      width: 190,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.value ? "Yes" : "No"}</Typography>;
      },
    },

    {
      field: "created_at",
      headerName: "C.Date",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} isUtc={false} />;
      },
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={onDelete}
          title={params.value}
          onEdit={onEdit}
          isEdit={1}
          viewText={typeId == 1 ? "Un-Publish" : "Publish"}
          onView={onPublish}
          isView={1}
        />
      ),
    },
  ];

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(typeId, rows.length);
    }
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        gap: "1.6rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <MultiSelection
        size="small"
        selected={selectedType}
        handleChange={handleSelectType}
        names={type}
        lable={"Type"}
        multiple={false}
      />
      <DataGrideView
        callBack={callBack}
        isLoading={isLoading}
        rows={rows}
        columns={columns}
        sx={{ height: "75vh" }}
      />

      {open && (
        <AddOnSpotCategoryDailog
          open={open}
          handleOnClose={handleOnClose}
          row={selectdRow}
        />
      )}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete on-spot !"
          content="Are you sure, You want to delete the on-spot?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}

      {openConfirmationP ? (
        <ConfirmationDailog
          title={typeId == 1 ? "Un publish" : "Publish !"}
          content={`Are you sure, You want to ${
            typeId == 1 ? "un publish" : "Publish !"
          } this?`}
          open={openConfirmationP}
          handleOnClose={handleOnCloseConfirmationP}
        />
      ) : null}
    </Box>
  );
}

export default NewOnSpotList;
