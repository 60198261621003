import {
  Avatar,
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { useEffect, useState } from "react";
import SplitButton from "../../../Component/Button/SplitButton";
import { DELETECATEGORY, GETCATEGORY } from "../../../Apis/category_api";
import { API_URL } from "../../../Apis/config";
import { useNavigate } from "react-router-dom";
import { ToastShow } from "../../../Component/Toast/Toast";
import CategoryAddModal from "./CategoryAddModal";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import SearchBox from "../../../Component/SearchBox/SearchBox";
import ButtonIcon from "../../../Component/Button/ButtonIcon";
import add_button from "../../../Assets/add_button.png";

export default function CategoryList() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  //For CategoryAdd modal
  const [open, setOpen] = useState(false);
  const [cId, setCId] = useState(false);

  const handleClickOnModal = (id) => {
    setCId(id);
    setOpen(true);
  };

  //CategoryList code
  const navigate = useNavigate();

  const onView = (id) => {
    // navigate(id+"/");
    window.open("/category/list/" + id);
  };

  const onEdit = (id) => {
    // navigate("/category/edit/"+id);
    // window.open("/category/edit/" + id);
    handleClickOnModal(id);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const onDelete = async (id) => {
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedId(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETECATEGORY(selectedId);
      if (r && r.s) {
        const d = rows.filter((v) => v.id != selectedId);
        setRows([...d]);
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedId(0);
    setOpenConfirmation(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography>{params.api.getRowIndex(params.row.id) + 1}</Typography>
        );
      },
    },
    // { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.name}</Typography>;
      },
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   minWidth: 130,
    //   flex:1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return  <Typography ><div dangerouslySetInnerHTML={{ __html: params.value }} style={{height:"70px", overflow:"hidden"}}/></Typography>
    //   },
    // },
    {
      field: "image",
      headerName: "Image",
      width: 130,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Avatar
            src={API_URL.baseUrl + API_URL.categoryImage + params.row.photo}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={onDelete}
          onView={onView}
          isView={1}
          title={params.value}
          isEdit={1}
          onEdit={() => onEdit(params.id)}
        />
      ),
    },
  ];

  async function getData() {
    setIsLoading(true);
    const t = await GETCATEGORY();

    if (t && t.s == 1) {
      setRows([...t.r]);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [open]);

  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        marginBottom="16px"
      >
        {/* <SearchBox search="" onClick={} cursor="default" /> */}

        {/*This code is for the AddCategory button modal*/}
        <ButtonIcon
          label="Add Categories"
          icon={add_button}
          bgcolor="#F8AF41"
          width="11.8rem"
          height="100%"
          imageSize="0.9rem"
          gap="0.4rem"
          fontSize="1.180rem"
          boxShadow={true}
          onClick={() => handleClickOnModal(null)}
        />
      </Box>
      <CategoryAddModal open={open} setOpen={setOpen} cid={cId} />

      <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
        <DataGrideView
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          colHeight={"60px"}
          sx={{ height: "79vh" }}
        />
      </Box>

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete category !"
          content="Are you sure, You want to delete the category?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </Box>
  );
}
