import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_WEB_PODIAN_OF_MONTH = async (data) => {
  const formData = new FormData();

  if (data?.row) {
    formData.append("id", data.row.id);
  }
  formData.append("month", data?.month);
  formData.append("podian_user_id", data?.podian_user_id);
  formData.append("score", data?.score);
  formData.append("review", data?.review);
  
  const res = await POSTAPI(API_URL.addUpdatePodianOfMonth, formData);
  return res;
};

export const GET_WEB_PODIAN_OF_MONTH = async () => {
  const res = await GETAPI(API_URL.getPodiansOfMonth);
  return res;
};

export const GET_WEB_PODIANIENS = async ({ search }) => {
  const res = await GETAPI(API_URL.providersGet , "&search=" + search);
  return res;
};
