import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const RadioButtonNew = ({
  value,
  handleChange,
  width = "100%",
  btnWidth = "48%",
  justifyContent = "space-between",
  alignItems = "center",
  gapInButtons,
  param,
  labelA = "Yes",
  labelB = "No",
  altTheme = false,
  placeholder = "",
}) => {
  const [selected, setSelected] = useState(value ?? 2);

  return (
    <Box
      width={width}
      bgcolor={altTheme ? "#F6F6F6" : "white"}
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      alignItems={alignItems}
      paddingY="6px"
      gap="4px"
    >
      {/*Form Control*/}
      <Box width="95%">
        {placeholder !== "" && (
          <Typography fontSize="11.8px" mb="0.3rem">
            {placeholder}
          </Typography>
        )}
        <RadioGroup
          row
          value={selected}
          defaultValue={selected}
          sx={{
            width: "100%",
            height: "1.9rem",
            display: "flex",
            justifyContent: justifyContent,
            gap: gapInButtons,
            "& .MuiFormControlLabel-root": {
              margin: "0",
            },
            "& .css-vxllfd-MuiFormControlLabel-root": {
              margin: "0",
            },
          }}
        >
          <FormControlLabel
            label={labelA}
            value={1}
            onClick={(e) => {
              handleChange(e.target.value, param);
              setSelected(1);
            }}
            control={
              <Radio
                checkedIcon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_checked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
                icon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_unchecked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
              />
            }
            sx={{
              width: btnWidth,
              height: "100%",
              userSelect: "none",
              bgcolor: altTheme ? "white" : "#F6F6F6",
              borderRadius: "6px",
              color: "red",
              ".MuiFormControlLabel-label": {
                color: 1 === selected ? "#FFAB30" : "#969696",
                fontWeight: "500",
              },
              "& .css-7jdnmo-MuiTypography-root": {
                fontSize: "0.750rem",
              },
            }}
          />
          <FormControlLabel
            label={labelB}
            value={2}
            onClick={(e) => {
              handleChange(e.target.value, param);
              setSelected(2);
            }}
            control={
              <Radio
                checkedIcon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_checked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
                icon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_unchecked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
              />
            }
            sx={{
              width: btnWidth,
              height: "100%",
              userSelect: "none",
              bgcolor: altTheme ? "white" : "#F6F6F6",
              borderRadius: "6px",
              ".MuiFormControlLabel-label": {
                color: 2 === selected ? "#FFAB30" : "#969696",
                fontWeight: "500",
              },
              "& .css-7jdnmo-MuiTypography-root": {
                fontSize: "0.750rem",
              },
            }}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default RadioButtonNew;
