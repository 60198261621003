import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import Compressor from "compressorjs";
import { PhotoCamera } from "@mui/icons-material";
import { API_URL } from "../../Apis/config";
import { ADD_UPDATE_NEW_ON_SPOT_SPONSER } from "../../Apis/new_on_spot_api";

function AddUpdateNewSponserDailog({ open, handleOnClose, on_spot_id, row }) {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImageURL, setSelectedImageURL] = React.useState(row?.logo ??null);
  const [selectedImageCover, setSelectedImageCover] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = React.useState(row?.name ?? "");

  const handleOnSubmit = async () => {
    if (!selectedImage && !selectedImageURL ) {
      ToastShow("Please select image");
    } else if (!name) {
      ToastShow("Please select name");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_NEW_ON_SPOT_SPONSER({
        logo: selectedImage,
        id: row?.id ?? 0,
        on_spot_id:on_spot_id,
        name: name,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!row?.id ? "Add Sponsor" : "Update Sponsor"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "150px",
              width: "150px",
              border: "dotted .3px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "fill",
              p: "1px",
            }}
          >
            
            {(selectedImage || selectedImageURL) ? (
              <img
                style={{ borderRadius: "5px" }}
                height="150px"
                width="150px"
                src={
                selectedImage
                   ? URL.createObjectURL(selectedImage)
                   : API_URL.baseUrl + API_URL.onspot2SponsorLogo + selectedImageURL

                }
                alt="banner image"
              />
            ) : (
              <Typography>Select Image</Typography>
            )}
          </Box>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      setSelectedImage(compressedResult);
                    },
                  });
                  new Compressor(event.target.files[0], {
                    quality: 0.5,
                    success: (compressedResult) => {
                      setSelectedImageCover(compressedResult);
                    },
                  });

                  setSelectedImageURL(null);
                }
              }}
            />
            <PhotoCamera />
          </IconButton>

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddUpdateNewSponserDailog;
