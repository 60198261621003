import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CREATEUSER, GEBYMOBILENO } from "../../../../Apis/booking_order_api";
import { GET_CITIE } from "../../../../Apis/city_api";
import MultiSelection from "../../../../Component/Multiselection/MultiSelection";
import { ToastShow } from "../../../../Component/Toast/Toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import LocationSearchInput from "../../../../Component/AutoComplate/AddressAutoComplate";
import { GETCATEGORY } from "../../../../Apis/category_api";
import { ADD_SERVICE, GET_SERVICE } from "../../../../Apis/service_api";
import PriceHour from "../../../../Component/GroupButton/PriceHour";
import { format } from "date-fns";
import {
  PAY_BY_COIN_WALLET,
  addOrder,
  confirmOrder,
  getDetailsByGst,
} from "../../../../Apis/order_api";
import GetCouponDailog from "../../../../Component/Dailog/GetCouponDailog";
import { COUPENAPPLYTOORDER } from "../../../../Apis/coupen_api";
import { useNavigate } from "react-router-dom";
import ButtonView from "../../../../Component/Button/ButtonView";
import Styles from "./createOrder.module.css";
import TextFieldSmall from "../../../../Component/TextFields/TextFieldSmall";
import RadioButton from "../../../../Component/RadioButton/RadioButton";
import SelectSmall from "../../../../Component/SelectComponent/SelectSmall";
import DateTime from "../../../../Component/DateTime/DateTime";
import { GET_BALANCE_PAYOUT } from "../../../../Apis/payout_api";

function Booking() {
  const [mobileNu, setMobileNu] = useState("");
  const [remarks, setRemarks] = useState("");
  const [user, setUser] = useState(null);
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [extraServiceW, setExtraServiceW] = React.useState([]);
  const [postServiceW, setPostServiceW] = React.useState([]);
  const [serviceW, setServiceW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [category, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategories] = React.useState([]);
  const [categoriesW, setCategoriesW] = React.useState([]);
  const [subCategory, setSubCategories] = React.useState([]);
  const [selectedSubCategory, setSelectedSubCategories] = React.useState([]);
  const [subCategoriesW, setSubCategoriesW] = React.useState([]);
  const [isUserExist, setUserExist] = useState(-1);
  const [canCreateOrder, setCanCreateOrder] = useState(0);
  const [address, setAddress] = React.useState("");
  const [latLng, setLatLng] = React.useState({});
  const [addressNr, setAddressNr] = React.useState("");
  const [addressFlat, setAddressFlat] = React.useState("");
  const [latLngNr, setLatLngNr] = React.useState({});
  const [firstName, setFName] = useState("");
  const [lastName, setLName] = useState("");
  const [middleName, setMName] = useState("");
  const [gender, setGender] = useState(2);
  const [email, setEmail] = useState("");
  const [dateTime, setDateTime] = useState(new Date());
  const [gst, setGst] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [someName, setSomeName] = useState("");
  const [someMobile, setSomeMobile] = useState("");
  const [someOneRadio, setSomeOneRadio] = useState(2);
  const [isPodRadio, setIsPodRadio] = useState(2);
  const [shootRadio, setShootRadio] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [orderId, setOrderId] = useState(0);
  const [isApplyCoupon, setIsApplyCoupon] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [isCollaboration, setIsCollaboration] = useState(false);
  const [isRepeatPreviousProvider, setIsRepeatPreviousProvider] =
    useState(false);
  const [area, setArea] = useState();
  const [coutryCode, setCoutryCode] = useState(91);
  const [isCheck, setIsCheck] = useState(false);

  const handleValidation = (e) => {
    const reg = new RegExp("[0-9]");
    if (reg.test(e.target.value)) {
      setMobileNu(e.target.value);
    }
  };

  const navigation = useNavigate();

  const handleClose = async (v, code) => {
    //  console.log(v,code);
    if (v == 1 && code) {
      const res = await COUPENAPPLYTOORDER(orderId, code, user.id);
      if (res && res.s) {
        ToastShow("Applied successfully");
      } else {
        ToastShow("Opps! something went wrong");
      }
      setSelectedCoupon(selectedCoupon);
    } else {
      setSelectedCoupon(null);
    }
    refresh();
  };

  const updateArea = (results) => {
    try {
      console.log(results, "0");

      const r = Array.from(
        new Set(
          results[0].address_components
            .filter(
              (v) =>
                v.types.includes("administrative_area_level_3") ||
                v.types.includes("sublocality_level_1") ||
                v.types.includes("sublocality") ||
                v.types.includes("administrative_area_level_1") ||
                v.types.includes("postal_code") ||
                v.types.includes("country")
            )
            .map((v) => v.long_name)
        )
      ).join(",");
      // console.log(r,"sdadsf")
      setArea(r ?? "");
    } catch (e) {
      console.log("err", e);
    }
  };

  const refresh = () => {
    setRemarks("");
    setIsApplyCoupon(false);
    setOpen(false);
    setOrderId(0);
    setUser(null);
    setMobileNu("");
    setFName("");
    setLName("");
    setMName("");
    setEmail("");
    setSubCategoryName(null);
    setGender(2);
    setUserExist(-1);
    setCanCreateOrder(0);
  };

  const submitOrder = async () => {
    if (!selectedCities.length) {
      ToastShow("Please select city");
    } else if (!selectedCategory.length) {
      ToastShow("Please select category");
    } else if (!(selectedSubCategory.length || subCategoryName)) {
      ToastShow("Please select sub category");
    } else if (!address) {
      ToastShow("Please select address");
    } else if (!addressNr) {
      ToastShow("Please fill near address");
    } else {
      setIsLoading(true);
      const c = citiesW.filter((v) => v.name == selectedCities[0]);
      const cat = categoriesW.filter((v) => v.name == selectedCategory[0]);
      const sCat = subCategoriesW.filter(
        (v) => v.name == selectedSubCategory[0]
      );

      const data = {
        user_id: user.id,
        city_id: c[0].id,
        date_time: format(dateTime, "yyyy-MM-dd HH:mm:ss"),
        remarks: remarks ?? "",
        shoot_area: shootRadio == 1 ? 1 : 0,
        address: address,
        near_by_address: addressNr,
        latitude: latLng.lat,
        longitude: latLng.lng,
        gst_no: gst,
        address1: addressFlat,
        business_name: businessName,
        billing_address: billingAddress,
        is_for_someone: someOneRadio == 1 ? 1 : 0,
        someone_name: someOneRadio == 1 ? someName : "",
        someone_mobile: someOneRadio == 1 ? someMobile : "",
        is_pod_can_use: isPodRadio == 1 ? 1 : 0,
        category_id: cat[0].id,
        sub_category_id:
          subCategoryName == null || subCategoryName == ""
            ? sCat.length
              ? sCat[0].id
              : 0
            : 0,
        sub_category_name: subCategoryName,
        isCollaboration: isCollaboration,
        isRepeatPreviousProvider: isRepeatPreviousProvider,
        area: area,
      };

      const res = await addOrder(data);
      if (res && res.s) {
        ToastShow("Order created successfully");
        navigation("/order/" + res.r.orders_id + "/draft");
      } else {
        ToastShow(res.m);
      }
    }
    setIsLoading(false);
  };

  const handleSomeOneRadio = (e) => {
    // console.log(e.target);
    const v = e.target.value == "Yes" ? 1 : 0;
    setSomeOneRadio(v);
  };

  const handleIsPosRadio = (e) => {
    const v = e.target.value == "Yes" ? 1 : 0;
    setIsPodRadio(v);
  };

  const handleShootRadio = (e) => {
    console.log("ShootRadio", e.target.value);
    const v = e.target.value == "Outdoor" ? 1 : 0;
    setShootRadio(v);
  };

  const handleGst = async (e) => {
    setGst(e.target.value);

    if (e.target.value.length >= 12) {
      // call gst api
      const d = await getDetailsByGst(e.target.value);
      if (d && d.s) {
        setBusinessName(d.r.name);
        setBillingAddress(d.r.address);
      } else {
        ToastShow(d?.m ?? "Invalid gst number");
        setBusinessName("");
        setBillingAddress("");
      }
    } else {
      setBusinessName("");
      setBillingAddress("");
    }
  };

  const handleBusinessName = (e) => {
    setBusinessName(e.target.value);
  };

  const handleBillingAddress = (e) => {
    setBillingAddress(e.target.value);
  };

  const handleSomeName = (e) => {
    setSomeName(e.target.value);
  };
  const handleSomeMobile = (e) => {
    setSomeMobile(e.target.value);
  };

  const handleFName = (e) => {
    setFName(e.target.value);
  };

  const handleMName = (e) => {
    setMName(e.target.value);
  };

  const handleLName = (e) => {
    setLName(e.target.value);
  };

  const handleMobileNum = (e) => {
    try {
      if (parseInt(e.target.value)) {
        setMobileNu(parseInt(e.target.value.replace(".", "")));
      } else {
        setMobileNu("");
      }
    } catch (e) {
      setMobileNu("");
    }
  };

  const handleCountryCode = (e) => {
    setCoutryCode(e.target.value);
  };

  const handleGender = (e) => {
    setGender(e.target.value == "Male" ? 0 : 1);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleSubCategoryName = (e) => {
    setSubCategoryName(e.target.value);
    setSelectedSubCategories([]);
  };

  const handleDateTime = (v) => {
    setDateTime(v);
  };

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    const c = citiesW.filter((v) => v.name == value);
    // console.log("city", c);
    const cat = categoriesW.filter(
      (v) => v.name == (selectedCategory.length ? selectedCategory[0] : "")
    );
    // await getService(c.length ? c[0].id : 0, cat.length ? cat[0].id : 0);
  };

  const handleSelectedCategory = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedCategories([value]);
    const d = categoriesW.filter((v) => v.name == value);

    await getSubCategory(d[0].id);

    const cat = categoriesW.filter((v) => v.name == value);
    const c = citiesW.filter(
      (v) => v.name == (selectedCities.length ? selectedCities[0] : "")
    );
    // await getService(c.length ? c[0].id : 0, cat.length ? cat[0].id : 0);
  };

  const handleServiceChecked = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = serviceW;
    data[i].checked = v;
    setServiceW([...data]);
  };

  const handleServiceCheckedE = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = extraServiceW;
    data[i].checked = v;
    setExtraServiceW([...data]);
  };

  const handleServiceCheckedP = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = postServiceW;
    data[i].checked = v;
    setPostServiceW([...data]);
  };

  const handleServiceQty = (v, i) => {
    // console.log("qty value", v, "index", i);
    const data = serviceW;
    data[i].qty = v + (data[i].qty ?? 1);
    setServiceW([...data]);
  };

  const handleServiceHour = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = serviceW;
    data[i].hour = v + (data[i].hour ?? 1);
    setServiceW([...data]);
  };

  const handleServiceQtyE = (v, i) => {
    // console.log("qty value", v, "index", i);
    const data = extraServiceW;
    data[i].qty = v + (data[i].qty ?? 1);
    setExtraServiceW([...data]);
  };
  const handleServiceRemark = (v, i) => {
    // console.log("qty value", v, "index", i);
    const data = extraServiceW;
    data[i].remarks = v;
    setExtraServiceW([...data]);
  };

  const handleServiceHourE = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = extraServiceW;
    data[i].hour = v + (data[i].hour ?? 1);
    setExtraServiceW([...data]);
  };

  const handleServiceQtyP = (v, i) => {
    // console.log("qty value", v, "index", i);
    const data = postServiceW;
    data[i].qty = v + (data[i].qty ?? 1);
    setPostServiceW([...data]);
  };

  const handleServiceHourP = (v, i) => {
    // console.log("isChecked", v, "index",i);
    const data = postServiceW;
    data[i].hour = v + (data[i].hour ?? 1);
    setPostServiceW([...data]);
  };

  const handleSubSelectedCategory = async (e) => {
    const {
      target: { value },
    } = e;

    setSubCategoryName("");

    setSelectedSubCategories([value]);
  };

  const handleAddress = (address) => {
    setAddress(address);
  };

  const updateLatLng = (latLngs) => {
    setLatLng(latLngs);
  };

  const handleAddressNr = (address) => {
    setAddressNr(address.target.value);
  };

  const handleAddressFlat = (address) => {
    setAddressFlat(address.target.value);
  };

  const updateLatLngNr = (latLngs) => {
    setLatLngNr(latLngs);
  };

  const getUser = async () => {
    // console.log((""+mobileNu).length,"len",mobileNu);
    if (!parseInt(mobileNu)) {
      setMobileNu(undefined);
      ToastShow("Please enter valid mobile number");
    } else if (!mobileNu) {
      ToastShow("Please fill mobile number");
    } else if (("" + mobileNu).length < 10) {
      ToastShow("Please fill valid mobile number");
    } else {
      const res = await GEBYMOBILENO(mobileNu);

      if (res && res.s) {
        setFName(res.r.first_name);
        setLName(res.r.last_name);
        setMName(res.r.middle_name);
        setUserExist(1);
        setUser(res.r);
        setSelectedCategories([]);
        setSelectedCities([]);
        setSelectedSubCategories([]);
        setAddress("");
        setAddressNr("");
        setAddressFlat("");
        setIsPodRadio(2);
        setShootRadio(2);
        setSomeOneRadio(2);
        setSomeName("");
        setSomeMobile("");
        setServiceW([]);
        setExtraServiceW([]);
        setPostServiceW([]);
        setDateTime(new Date());
        if (res.r.businesses.length) {
          setBusinessName(res.r.businesses[0].business_name);
          setGst(res.r.businesses[0].gst_no);
          setBillingAddress(res.r.businesses[0].billing_address);
        } else {
          setBusinessName("");
          setGst("");
          setBillingAddress("");
        }
        setCanCreateOrder(1);

        getWalletBalance(res?.r.id);
        ToastShow("Success");
      } else {
        setCanCreateOrder(0);
        setServiceW([]);
        setExtraServiceW([]);
        setPostServiceW([]);
        setSelectedCategories([]);
        setSelectedCities([]);
        setSelectedSubCategories([]);
        setAddress("");
        setAddressNr("");
        setAddressFlat("");
        setIsPodRadio(2);
        setShootRadio(2);
        setSomeOneRadio(2);
        setSomeName("");
        setSomeMobile("");
        setDateTime(new Date());
        setBusinessName("");
        setGst("");
        setBillingAddress("");
        setUserExist(0);
      }
    }
  };

  const handleUserRag = async () => {
    if (!firstName) {
      ToastShow("Please fill first name");
    } else if (!lastName) {
      ToastShow("Please fill last name");
    } else if (!email) {
      ToastShow("Please fill email address");
    } else if (gender == 2) {
      ToastShow("Please select gender");
    } else {
      const res = await CREATEUSER({
        firstName,
        lastName,
        middleName,
        email,
        mobile: mobileNu,
        gender: gender == 0 ? "Male" : "Female",
      });

      if (res && res.s) {
        setUser(res.r);
        setCanCreateOrder(1);
      } else {
        ToastShow(res.m ?? "Something went wrong!");
      }
    }
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
    }
  }

  async function getService(cityId = 0, categoryId = 0) {
    const t = await GET_SERVICE(cityId, categoryId, 1, 0, 0);
    if (t && t.s == 1) {
      if (t.r) {
        setServiceW([...t.r]);
      } else {
        setServiceW([]);
      }
    }

    const e = await GET_SERVICE(cityId, categoryId, 0, 1, 0);
    if (e && e.s == 1) {
      if (e.r) {
        setExtraServiceW([...e.r]);
      } else {
        setExtraServiceW([]);
      }
    }

    const p = await GET_SERVICE(cityId, categoryId, 0, 0, 1);
    if (p && p.s == 1) {
      if (p.r) {
        setPostServiceW([...p.r]);
      } else {
        setPostServiceW([]);
      }
    }
  }

  async function getCategory() {
    const t = await GETCATEGORY();

    if (t && t.s == 1) {
      setCategoriesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCategories([...c]);
    }
  }

  async function getSubCategory(id) {
    setSelectedSubCategories([]);
    const t = await GETCATEGORY(id);

    if (t && t.s == 1) {
      if (t.r) {
        setSubCategoriesW([...t.r]);
        const c = [];
        for (const v of t.r) {
          c.push(v.name);
        }
        setSubCategories([...c]);
      } else {
        setSubCategoriesW([]);
        setSubCategories([]);
      }
    }
  }

  const handleRemarkName = (e) => {
    setRemarks(e.target.value);
  };

  const [walletBalance, setWalletBalance] = useState({});
  const getWalletBalance = async (userID) => {
    const res = await GET_BALANCE_PAYOUT(userID);
    if (res && res.s) {
      setWalletBalance(res?.r);
    } else {
      ToastShow(res.m);
    }
  };

  const payByCoinWallet = async (userID) => {
    const res = await PAY_BY_COIN_WALLET({ userID });
    if (res && res.s) {
      ToastShow(res.m);
      window.open("/order/all", "_self");
    } else {
      ToastShow(res.m);
    }
  };

  useEffect(() => {
    getCities();
    getCategory();
  }, []);

  return (
    <>
      <Box
        sx={{
          overflowY: "auto",
          height: "80vh",
        }}
      >
        {/*  AUTHENTICATION */}
        <Authentication
          handleMobileNum={handleMobileNum}
          mobileNu={mobileNu}
          getUser={getUser}
          canCreateOrder={canCreateOrder}
          coutryCode={coutryCode}
          handleCountryCode={handleCountryCode}
        />
        <Divider />
        {/* USER REGISTRATION */}

        {isUserExist != -1 ? (
          <Paper
            sx={{
              mt: 2,
              p: 2,
            }}
          >
            <UserRagistration
              isUserExist={isUserExist}
              lastName={lastName}
              firstName={firstName}
              middleName={middleName}
              handleFName={handleFName}
              handleLName={handleLName}
              handleMName={handleMName}
              handleUserRag={handleUserRag}
              handleGender={handleGender}
              gender={gender}
              email={email}
              handleEmail={handleEmail}
              canCreateOrder={canCreateOrder}
            />
          </Paper>
        ) : null}

        {canCreateOrder ? (
          <Box
            sx={{
              width: "100%",

              boxShadow: "5px 2px 8px 0px #00000040",
              bgcolor: "white",
              borderRadius: "6px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              p: "1rem",
              mt: "2rem",
            }}
          >
            <Divider />

            {/* CREATE ORDER */}
            {canCreateOrder ? (
              <CreateOrderView
                handleRemarkName={handleRemarkName}
                remarks={remarks}
                handleServiceRemark={handleServiceRemark}
                selectedCities={selectedCities}
                handleSelectCity={handleSelectCity}
                cities={cities}
                dateTime={dateTime}
                handleDateTime={handleDateTime}
                address={address}
                handleAddress={handleAddress}
                updateLatLng={updateLatLng}
                addressNr={addressNr}
                addressFlat={addressFlat}
                handleAddressNr={handleAddressNr}
                handleAddressFlat={handleAddressFlat}
                updateLatLngNr={updateLatLngNr}
                selectedCategory={selectedCategory}
                handleSelectCategory={handleSelectedCategory}
                selectedSubCategory={selectedSubCategory}
                handleSubCategory={handleSubSelectedCategory}
                category={category}
                subCategory={subCategory}
                handleGst={handleGst}
                gst={gst}
                handleBusinessName={handleBusinessName}
                businessName={businessName}
                handleBillingAddress={handleBillingAddress}
                billingAddress={billingAddress}
                handleSomeName={handleSomeName}
                handleSomeMobile={handleSomeMobile}
                someName={someName}
                someMobile={someMobile}
                services={serviceW}
                servicesE={extraServiceW}
                servicesP={postServiceW}
                handleServiceChecked={handleServiceChecked}
                handleServiceQty={handleServiceQty}
                handleServiceHour={handleServiceHour}
                handleServiceCheckedE={handleServiceCheckedE}
                handleServiceQtyE={handleServiceQtyE}
                handleServiceHourE={handleServiceHourE}
                handleServiceCheckedP={handleServiceCheckedP}
                handleServiceQtyP={handleServiceQtyP}
                handleServiceHourP={handleServiceHourP}
                someOneRadio={someOneRadio}
                handleSomeOneRadio={handleSomeOneRadio}
                isPodRadio={isPodRadio}
                handleIsPosRadio={handleIsPosRadio}
                shootRadio={shootRadio}
                handleShootRadio={handleShootRadio}
                submitOrder={submitOrder}
                subCategoryName={subCategoryName}
                handleSubCategoryName={handleSubCategoryName}
                updateArea={updateArea}
                area={area}
              />
            ) : null}

            {canCreateOrder ? (
              <>
                <FormControlLabel
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  label="Is Collaboration ?"
                  control={
                    <Checkbox
                      checked={isCollaboration}
                      onChange={(e) => setIsCollaboration(e.target.checked)}
                    />
                  }
                />

                {/* <FormControlLabel
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  label="Is Repeat Previous Provider ?"
                  control={
                    <Checkbox
                      checked={isRepeatPreviousProvider}
                      onChange={(e) => setIsRepeatPreviousProvider(e.target.checked)}
                    />
                  }
                /> */}
              </>
            ) : null}

            {/* {walletBalance?.coin_refunded > 0 ? (
              <FormControlLabel
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                label={`Make payment by Coin Wallet (Available: ${walletBalance.coin_refunded})`}
                control={
                  <Checkbox
                    checked={isCheck}
                    onChange={(e) => setIsCheck(e.target.checked)}
                  />
                }
              />
            ) : null} */}

            {canCreateOrder ? (
              isLoading ? (
                <CircularProgress />
              ) : (
                <ButtonView
                  lable="Submit"
                  sx={{ width: "17rem", height: "2.4rem" }}
                  fontSize="14px"
                  fontWeight="500"
                  onClick={() => {
                    isCheck
                      ? payByCoinWallet(walletBalance?.user_id)
                      : submitOrder();
                  }}
                >
                  Continue
                </ButtonView>
              )
            ) : null}

            {open && (
              <GetCouponDailog
                orderId={orderId}
                open={open}
                handleClose={handleClose}
                cityId={
                  citiesW.filter((v) => v.name == selectedCities[0])[0].id
                }
                userId={user.id}
              />
            )}
          </Box>
        ) : null}

        {/* {canCreateOrder ? (
          <Box>
            <FormControlLabel
              label="Is want to coupon apply?"
              control={
                <Checkbox
                  checked={isApplyCoupon}
                  onChange={(e) => setIsApplyCoupon(e.target.checked)}
                />
              }
            />
          </Box>
        ) : null} */}
      </Box>
    </>
  );
}

export default Booking;

function Authentication({
  handleMobileNum,
  mobileNu,
  getUser,
  coutryCode,
  handleCountryCode,
}) {
  return (
    <>
      <Box
        sx={{
          height: "11.7rem",
          boxShadow: "5px 2px 8px 0px #00000040",
          bgcolor: "white",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "97.8%",
            display: "flex",
            flexDirection: "column",
            gap: "0.8rem",
          }}
        >
          {/*Upper Texts*/}
          <Box
            height="60px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              style={{
                color: "black",
                fontSize: "1.4rem",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              Verify existence of users
            </Typography>
            <Typography
              style={{
                color: "black",
                fontSize: "14.5px",
              }}
            >
              Enter mobile number for the future process
            </Typography>
          </Box>

          {/*Enter mobile number*/}
          <Box
            sx={{
              width: "22.5rem",
              height: "82px",
              bgcolor: "#F6F6F6",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                gap: ".3rem",
              }}
            >
              <Typography
                style={{
                  fontSize: "14.2px",
                  color: "#252525",
                }}
              >
                Mobile Number
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  height: "37px",
                  bgcolor: "white",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  pl: 0.5,
                }}
              >
                <input
                  disabled
                  label="Country Code"
                  autoFocus
                  className={Styles.in}
                  value={coutryCode}
                  onChange={handleCountryCode}
                  maxLength={5}
                  placeholder="91"
                  style={{
                    width: "25px",
                    height: "21px",
                    fontSize: "14.3px",
                    fontWeight: "500",
                    border: "none",
                    outline: "none",
                    color: "black",
                    paddingLeft: "0.2rem",
                  }}
                />
                <Box width="5px" height="100%" bgcolor="#c0c0c035" />
                <input
                  id="mobileNumber"
                  label="Mobile Number"
                  autoFocus
                  autoComplete="off"
                  className={Styles.in}
                  value={mobileNu}
                  onChange={handleMobileNum}
                  maxLength={15}
                  placeholder="Enter number"
                  onKeyDown={(e) => (e.key === "Enter" ? getUser() : null)}
                  style={{
                    width: "76.5%",
                    height: "21px",
                    fontSize: "14.3px",
                    fontWeight: "500",
                    border: "none",
                    outline: "none",
                    color: "black",
                    paddingLeft: "0.5rem",
                  }}
                />
                <ButtonView
                  onClick={getUser}
                  lable="Submit"
                  sx={{ width: "20px", height: "31px" }}
                  fontSize="11.9px"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function UserRagistration({
  isUserExist,
  firstName,
  lastName,
  middleName,
  handleFName,
  handleLName,
  handleMName,
  handleUserRag,
  handleGender,
  gender,
  email,
  handleEmail,
  canCreateOrder,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "DM Sans",
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        Create User
      </Typography>
      {isUserExist == -1 ? null : !isUserExist ? (
        <Box
          sx={{
            width: "96.50%",
            // height: "235px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <Box display="flex" gap="10px" flexWrap="wrap">
              <TextFieldSmall
                label="Enter Name"
                placeholder="Enter text"
                name="firstName"
                value={firstName}
                onChange={handleFName}
              />

              <TextFieldSmall
                label="Middle Name"
                placeholder="Enter text"
                name="midName"
                value={middleName}
                onChange={handleMName}
              />

              <TextFieldSmall
                label="Last Name"
                placeholder="Enter text"
                name="lastName"
                value={lastName}
                onChange={handleLName}
              />
            </Box>

            <Box display="flex" gap="10px">
              <TextFieldSmall
                label="Email"
                placeholder="Enter email"
                name="email"
                value={email}
                onChange={handleEmail}
              />
              <RadioButton
                label="Gender"
                name="gender"
                value={gender == 0 ? "Male" : "Female"}
                onChange={handleGender}
                options={["Male", "Female"]}
              />
            </Box>
            {!canCreateOrder ? (
              <ButtonView
                lable="Submit"
                sx={{ width: "14rem", height: "2.170rem" }}
                fontSize="14px"
                fontWeight="500"
                onClick={handleUserRag}
              />
            ) : null}
          </Box>
        </Box>
      ) : firstName || middleName || lastName ? (
        <Box
          sx={{
            display: "flex",
            my: 2,
            gap: "10px",
          }}
        >
          <TextFieldSmall
            disabled={true}
            label="First name"
            placeholder="First name"
            name="First name"
            value={firstName}
            onChange={handleFName}
          />
          <TextFieldSmall
            disabled={true}
            label="Middle name"
            placeholder="Middle name"
            name="Middle name"
            value={middleName}
            onChange={handleMName}
          />

          <TextFieldSmall
            disabled={true}
            label="Last name"
            placeholder="Last name"
            name="Last name"
            value={lastName}
            onChange={handleLName}
          />
        </Box>
      ) : null}
      {/* {isUserExist == -1 ? null : !isUserExist ? <CreateOrderView /> : null} */}
    </Box>
  );
}

function CreateOrderView({
  selectedCities,
  handleSelectCity,
  cities,
  dateTime,
  handleDateTime,
  address,
  handleAddress,
  updateLatLng,
  addressNr,
  addressFlat,
  handleAddressNr,
  handleAddressFlat,
  updateLatLngNr,
  selectedCategory,
  handleSelectCategory,
  selectedSubCategory,
  handleSubCategory,
  category,
  subCategory,
  handleGst,
  gst,
  handleBusinessName,
  businessName,
  handleBillingAddress,
  billingAddress,
  handleSomeName,
  handleSomeMobile,
  someName,
  someMobile,
  services,
  handleServiceChecked,
  handleServiceQty,
  handleServiceHour,

  servicesE,
  handleServiceCheckedE,
  handleServiceQtyE,
  handleServiceHourE,
  handleServiceRemark,

  servicesP,
  handleServiceCheckedP,
  handleServiceQtyP,
  handleServiceHourP,

  someOneRadio,
  handleSomeOneRadio,
  isPodRadio,
  handleIsPosRadio,
  shootRadio,
  handleShootRadio,

  subCategoryName,
  handleSubCategoryName,

  handleRemarkName,
  remarks,
  updateArea,
  area,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          width: "96.50%",
          // height: "235px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Create Order
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box display="flex" gap="10px" flexWrap="wrap">
            <SelectSmall
              label="City"
              name="city"
              value={selectedCities}
              onChange={handleSelectCity}
              menuItems={cities}
              defaultValue="Select city"
            />
            <DateTime
              value={dateTime}
              onChange={handleDateTime}
              minDateTime={Date.now()}
              width="14rem"
              height="3.8rem"
              label="Date - Time"
            />
            <SelectSmall
              label="Category"
              name="category"
              value={selectedCategory}
              onChange={handleSelectCategory}
              menuItems={category}
              defaultValue="Select category"
            />

            <SelectSmall
              label="Sub Category"
              name="subCategory"
              value={selectedSubCategory}
              onChange={handleSubCategory}
              menuItems={subCategory}
              defaultValue="Select Sub category"
            />

            <TextFieldSmall
              label="Subcategory name"
              placeholder="Sub category name"
              name="subcategory-name"
              value={subCategoryName}
              onChange={handleSubCategoryName}
            />
          </Box>

          <Box display="flex" gap="10px" flexWrap="wrap">
            <TextFieldSmall
              label="Remark for the order"
              placeholder="Enter remark"
              name="remarkForTheOrder"
              value={remarks}
              onChange={handleRemarkName}
            />

            <RadioButton
              label="Booking for some one"
              name="booking_for_some_one"
              value={someOneRadio == 1 ? "Yes" : "No"}
              onChange={handleSomeOneRadio}
              options={["Yes", "No"]}
            />
            {someOneRadio == 1 ? (
              <Box
                width="14rem"
                height="3.8rem"
                bgcolor="#F6F6F6"
                borderRadius="6px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingY="4.2px"
                gap="4px"
              >
                <Typography width="95%" fontSize="11.8px">
                  Some one name and number
                </Typography>

                <Box width="95%" display="flex" justifyContent="space-between">
                  <TextField
                    placeholder="Enter name"
                    size="small"
                    sx={{
                      width: "49%",
                      height: "1.9rem",
                      backgroundColor: "white",
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "center",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.750rem",
                          paddingX: "9px",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="someoneNameAndNumber_name"
                    value={someName}
                    onChange={handleSomeName}
                  />
                  <TextField
                    placeholder="Enter number"
                    size="small"
                    sx={{
                      width: "49%",
                      height: "1.9rem",
                      backgroundColor: "white",
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "center",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.750rem",
                          paddingX: "9px",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="someoneNameAndNumber_number"
                    value={someMobile}
                    onChange={handleSomeMobile}
                  />
                </Box>
              </Box>
            ) : null}
            <RadioButton
              label="Permission for social"
              name="permission_for_social"
              value={isPodRadio == 1 ? "Yes" : "No"}
              onChange={handleIsPosRadio}
              options={["Yes", "No"]}
            />
            <RadioButton
              label="Shoot area"
              name="shoot_area"
              value={
                shootRadio === 1 ? "Outdoor" : shootRadio === 0 ? "Indoor" : ""
              }
              onChange={handleShootRadio}
              options={["Outdoor", "Indoor"]}
            />
          </Box>

          <Box display="flex" gap="10px" flexWrap="wrap">
            <TextFieldSmall
              label="GST No."
              placeholder="Enter No."
              name="GST_No"
              value={gst}
              onChange={handleGst}
            />
            <TextFieldSmall
              label="Business name"
              placeholder="Enter business name"
              name="businessName"
              value={businessName}
              onChange={handleBusinessName}
            />

            <TextFieldSmall
              label="Billing Address"
              placeholder="Enter Billing address"
              name="billingAddress"
              value={billingAddress}
              onChange={handleBillingAddress}
            />
          </Box>

          <Box display="flex" gap="10px" flexWrap="wrap">
            <Box sx={{ mr: 1, my: 1 }}>
              <LocationSearchInput
                updateArea={updateArea}
                label="Address"
                address={address}
                handleAddress={handleAddress}
                updateLatLng={updateLatLng}
              />
            </Box>
            <TextFieldSmall
              label="Nr. Address"
              placeholder="Enter near address"
              name="nr_address"
              value={addressNr}
              onChange={handleAddressNr}
            />

            <TextFieldSmall
              label="Flat no./Floor/Society..."
              placeholder="Enter Flat no./Floor/Society..."
              name="flatNo_floor_society"
              value={addressFlat}
              onChange={handleAddressFlat}
            />
          </Box>
        </Box>
        {area?.length ?? 0 ? <Typography>Area : {area}</Typography> : null}
      </Box>
    </Box>
  );
}

function ServiceView({
  services,
  handleServiceChecked,
  handleServiceHour,
  handleServiceQty,
  showTextField,
  handleServiceRemark,
}) {
  return (
    <Box>
      <Grid container>
        {services.map((v, i) => {
          return (
            <ServiceItemView
              handleServiceRemark={handleServiceRemark}
              showTextField={showTextField}
              key={v.id}
              data={v}
              i={i}
              handleServiceChecked={handleServiceChecked}
              handleServiceQty={handleServiceQty}
              handleServiceHour={handleServiceHour}
            />
          );
        })}
      </Grid>
    </Box>
  );
}

function ServiceItemView({
  data,
  i,
  handleServiceChecked,
  handleServiceHour,
  handleServiceQty,
  showTextField,
  handleServiceRemark,
}) {
  return (
    <Box
      sx={{
        border: "solid 1px",
        borderColor: "divider",
        p: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Box display="flex" flexDirection="column" justifyContent="start">
          <Typography variant="body1" fontWeight="500">
            {data.name}
          </Typography>
          <Typography variant="subtitle1">₹{data.price}</Typography>
        </Box>
        <Switch
          checked={data.checked ?? false}
          onChange={(e) => handleServiceChecked(e.target.checked, i)}
          sx={{ mt: -1 }}
        />
      </Box>
      <Box display="flex">
        {data.isQty && data.checked ? (
          <PriceHour
            counter={data.qty}
            index={i}
            isHour={0}
            handleCounter={(value, i) => handleServiceQty(value, i)}
          />
        ) : null}
        {data.isPerHour && data.checked ? (
          <PriceHour
            counter={data.hour}
            index={i}
            isHour={1}
            handleCounter={(value, i) => handleServiceHour(value, i)}
          />
        ) : null}
      </Box>

      {showTextField && (
        <TextFieldView
          lable="Remark"
          value={data.remarks}
          callBack={(e) => handleServiceRemark(e.target.value, i)}
        />
      )}
    </Box>
  );
}

function TextFieldView({
  lable,
  value,
  callBack,

  size = "small",
  disableds = false,
}) {
  return (
    <TextField
      focused
      placeholder={lable}
      disabled={disableds}
      size={size}
      sx={{ mr: 1, mt: 1 }}
      label={lable}
      value={value}
      onChange={callBack}
    />
  );
}
