import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { GET_SUPPORT_TICKETS_DETAILS_REPLIES } from "../../Apis/support_api";
import { API_URL } from "../../Apis/config";
import { Message } from "@mui/icons-material";
import SupportAddRepliesDailog from "./SupportAddReplies";

function SupportRepliesDailog({ open, handleOnClose, id }) {
  const [row, setRow] = useState([]);
  const [openR, setOpenR] = useState(false);
  const scrollRef = useRef();

  const handleOnCloseR = (v) => {
    if (v) {
      getData();
    }
    setOpenR(false);
  };

  const getData = async () => {
    const data = await GET_SUPPORT_TICKETS_DETAILS_REPLIES({ id });
    if (data && data.s && data.r) {
      setRow(data.r);
    }
    
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(()=>{
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth'});
  },[row])

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogContent
        sx={{
          height:"100%",
          display:"flex",
          flexDirection:"column",
          justifyContent:"flex-start"
        }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Replies</Typography>
            <IconButton size="small" onClick={() => setOpenR(true)}>
              <Message fontSize="15px" />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box
          
          sx={{
            flex:1,
            height:"80%",
            overflowY:"auto",

          }}
          >
          {row.map((v) => {
            return (
              <Box
                key={v.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: "#c0c0c0",
                    }}
                    src={
                      API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb
                    }
                  />
                  <Box
                    sx={{
                      ml: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                      {v.user_name}
                    </Typography>
                    <Typography sx={{ fontSize: "10px" }}>
                      {v.date_time}
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  sx={{
                    fontSize: "12px",
                    ml: 6,
                  }}
                >
                  {v.message}
                </Typography>
                {v.attachments.length ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      ml: 6,
                    }}
                  >
                    {v.attachments.map((v) => {
                      return (
                        <Box
                          key={v.id}
                          sx={{
                            width: "60px",
                            height: "60px",
                            overflow: "hidden",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(
                              API_URL.baseUrl +
                                API_URL.supportTicketAttachmentURL +
                                v.file
                            )
                          }
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={
                              API_URL.baseUrl +
                              API_URL.supportTicketAttachmentURL +
                              v.file
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                ) : null}

                <Divider sx={{ my: 1 }} />
              </Box>
            );
          })}

          {!row.length ? (
            <DialogContentText>No replies added.</DialogContentText>
          ) : null}
           <div ref={scrollRef} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOnClose(0)} variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {openR && (
        <SupportAddRepliesDailog
          handleOnClose={handleOnCloseR}
          open={openR}
          id={id}
        />
      )}
    </>
  );
}

export default SupportRepliesDailog;
