import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { BsChevronDown } from "react-icons/bs";

const SelectComponent = ({
  multiple = false,
  label,
  name,
  value,
  onChange,
  menuItems,
  defaultValue,
  width,
  inputWidth = "97.5%",
  height = "4.3rem",
  inputHeight = "2.3rem",
  labelSize = "12px",
  py = "8px",
}) => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        width: width,
        height: height,
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: py,
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "6px",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box width={inputWidth}>
          <Typography
            width="100%"
            fontSize={labelSize}
            mb=".1rem"
            sx={{ userSelect: "none" }}
          >
            {label}
          </Typography>
        </Box>

        <Box width={inputWidth}>
          <FormControl
            sx={{
              width: "100%",
              borderRadius: "5px",
              "& fieldset": { border: "none" },
            }}
          >
            <Select
              name={name}
              labelId="demo-multiple-checkbox-label"
              size="small"
              id="demo-multiple-checkbox"
              label="Select City"
              displayEmpty
              multiple={multiple}
              value={value}
              onChange={onChange}
              input={<OutlinedInput label={"Categories"} />}
              style={{
                height: inputHeight,
                backgroundColor: "#F6F6F6",
                "& fieldset": { border: "none" },
                color: "#969696",
                borderRadius: "6px",
              }}
              sx={{
                "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    fontSize: "0.850rem",
                  },
              }}
              IconComponent={BsChevronDown}
            >
              <MenuItem value="" disabled>
                <p>{defaultValue}</p>
              </MenuItem>
              {menuItems.map((item) => (
                <MenuItem key={item} value={item}>
                  <Typography sx={{ color: "black", fontSize: "0.850rem" }}>
                    {item}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectComponent;
