import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import DateMDYHMA from "../../../../../Component/Date/DateMDYHMA";
import { GETUNAVAILABILITY } from "../../../../../Apis/service_api";

function Unavailability({ userID }) {
  const [data, setData] = useState([]);

  const rows = data?.map((row, index) => ({
    ...row,
    rowId: index + 1,
  }));

  const cols = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.row.rowId}</Typography>;
      },
    },
    {
      field: "from_date_time",
      headerName: "Date",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.row.from_date_time} onlyDate={true} />;
      },
    },
    {
      field: "to_date_time",
      headerName: "Time",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Box>
            <DateMDYHMA date={params.row.from_date_time} onlyTime={true} />
            <Typography fontSize="12.4px">to</Typography>
            <DateMDYHMA date={params.row.to_date_time} onlyTime={true} />
          </Box>
        );
      },
    },
  ];

  const getData = async () => {
    const res = await GETUNAVAILABILITY(userID); //Try id 10

    if (res && res.s === 1) {
      setData(res.r);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("Unavailability data", data);

  return (
    <Box width="100%" boxShadow="3px 3px 7px 0px #00000040">
      <DataGrideView columns={cols} rows={rows ?? []} />
    </Box>
  );
}

export default Unavailability;
