import { GETAPI } from "./config";


export const GETORDERSTATICS = async (data)=>{

    let query = "";

    if (data.search) {
        query += "&count=" + data.count;
      }

    if (data.group_by) {
        query += "&group_by=" + data.group_by;
      }

    if (data.city_id) {
        query += "&city_id=" + data.city_id;
      }

    if (Object.keys(data.dateRange).length) {
        query += "&from_date=" + data.dateRange.start;
        query += "&to_date=" + data.dateRange.end;
      }

   

      const res = await GETAPI(data.api,query);
      return res;
}