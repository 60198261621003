import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../../Toast/Toast";
import Compressor from "compressorjs";
import { API_URL } from "../../../Apis/config";
import ReactQuill from "react-quill";
import { UPDATE_MASTER } from "../../../Apis/master/master_api";

const dataType = {
  integer: "number",
  float: "number",
  text: "text",
  email: "email",
  url: "url",
};

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "code-block",
  "indent",
  "list",
  "direction",
  "align",
  "link",
  "image",
  "video",
  "formula",
];

function UpdateMasterDailog({ open, handleOnClose, row }) {
  // console.log(row)
  const [photo, setPhoto] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = React.useState(row ? row.name : "");
  const [value, setValue] = React.useState(row ? row.value : "");

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please enter name");
    } else {
      setIsLoading(true);
      const d = await UPDATE_MASTER({
        row: row,
        value: value,
        name: name,
        photo: photo,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{"Update Data"}</DialogTitle>
        <DialogContent
          sx={{
            width: "440px",
            overflow: "auto",
          }}
        >
          {/* photo one */}
          {row.data_type == "Photo" && (
            <>
              <label
                htmlFor="photoone"
                style={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "150px",
                    width: "85%",
                    border: "dotted .3px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    objectFit: "fill",
                    p: "1px",
                    mx: "auto",
                  }}
                >
                  {(row?.value && row.value.langth) || photo ? (
                    <img
                      style={{ borderRadius: "5px" }}
                      height="150px"
                      width="100%"
                      src={
                        photo
                          ? URL.createObjectURL(photo)
                          : API_URL.baseUrl + API_URL.webCityImage + row?.photo
                      }
                      alt="photo image"
                    />
                  ) : (
                    <Typography sx={{ width: "85%" }}>
                      Click here to select the photo
                    </Typography>
                  )}
                </Box>
              </label>

              <input
                hidden
                id="photoone"
                accept="image/*"
                type="file"
                onChange={(event) => {
                  if (event.target.files) {
                    new Compressor(event.target.files[0], {
                      quality: 0.8,
                      success: (compressedResult) => {
                        setPhoto(compressedResult);
                      },
                    });
                  }
                }}
              />
            </>
          )}

          <TextField
            sx={{
              width: "100%",
              mt: 1,
              mb: 2.5,
            }}
            size="small"
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          {Object.keys(dataType).includes(row.data_type.toLowerCase()) && (
            <TextField
              sx={{
                width: "100%",
              }}
              type={dataType[row.data_type]}
              size="small"
              fullWidth
              label="Enter value here."
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          )}

          {row.data_type.toLowerCase() == "html" && (
            <ReactQuill
              style={{ width: "100%", marginBottom: "10px" }}
              theme="snow"
              value={value}
              onChange={(e) => {
                setValue(e);
              }}
              formats={formats}
            />
          )}
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default UpdateMasterDailog;
