import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import Compressor from "compressorjs";
import { PhotoCamera } from "@mui/icons-material";
import MultiSelection from "../Multiselection/MultiSelection";
import { API_URL } from "../../Apis/config";
import { ADD_USER_STORY } from "../../Apis/users_stories_api";


function UserStoryDailog({
  open,
  handleOnClose,
  cities,
  citiesW,
  id,
  src = "",
  r="",
  re="",
  n = ""
}) {
   
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImageCover, setSelectedImageCover] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [rating, setRating] = React.useState(r);
  const [name, setName] = React.useState(n);
  const [review, setReview] = React.useState(re);

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
  };

  const handleOnSubmit = async () => {
    if (!selectedImage && !id) {
      ToastShow("Please select image");
    } else if (!selectedCities.length && !id) {
      ToastShow("Please select city");
    } else {
      const c = citiesW.filter((v) => v.name == selectedCities[0]);
      setIsLoading(true);
      const d = await ADD_USER_STORY({
        file: selectedImage,
        city_id: !id ? c[0].id : 0,
        id: id,
        name: name,
        rate: rating,
        review: review,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!id ? "Add Story" : "Update Story"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "150px",
              width: "150px",
              border: "dotted .3px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "fill",
              p: "1px",
            }}
          >
            {src || selectedImage ? (
              <img
                style={{ borderRadius: "5px" }}
                height="150px"
                width="150px"
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : API_URL.baseUrl + API_URL.userStoryImage + src
                }
                alt="banner image"
              />
            ) : (
              <Typography>Select Image</Typography>
            )}
          </Box>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      setSelectedImage(compressedResult);
                    },
                  });
                  new Compressor(event.target.files[0], {
                    quality: 0.5,
                    success: (compressedResult) => {
                      setSelectedImageCover(compressedResult);
                    },
                  });
                }
              }}
            />
            <PhotoCamera />
          </IconButton>

          {id == 0 ? (
            <MultiSelection
              multiple={false}
              names={cities}
              handleChange={handleSelectCity}
              selected={selectedCities}
              lable="Cities"
            />
          ) : null}

          <TextField
            sx={{
              width: "85%",
              mt:2
            }}
            size="small"
            fullWidth
            label="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            sx={{
              width: "85%",
              mt:3
            }}
            value={review}
            fullWidth
            label="review"
            multiline={true}
            rows={3}
            onChange={(e)=>setReview(e.target.value)}
          />
          <Rating
            sx={{
              my: 2,
            }}
            name="simple-controlled"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default UserStoryDailog;
