import { GETAPI, API_URL, POSTAPI } from "./config";

export const getOrderTopics = async () => {
  const data = await GETAPI(API_URL.getIssueTitle);
  return data;
};

export const addUpdateOrderTopicsIssue = async (name, id) => {
  const formData = new FormData();
  formData.append("name", name);

  if(id){
    formData.append("id", id);
  }
  const data = await POSTAPI(
    id ? API_URL.updateIssueTitle : API_URL.addIssueTitle,
    formData
  );
  return data;
};

export const deleteOrderTopics = async (id) => {
  const data = await GETAPI(API_URL.deleteIssueTitle,"&id="+id);
  return data;
};
