import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { API_URL } from "../../../Apis/config";
import { Delete, Edit, Image, Launch, VideoLibrary } from "@mui/icons-material";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { ToastShow } from "../../../Component/Toast/Toast";
import { GETCATEGORY } from "../../../Apis/category_api";
import { DELETE_PORTFOLIO, GET_PORTFOLIO } from "../../../Apis/portfolio_api";
import PortfolioDailog from "../../../Component/Dailog/AddPortfolioDailog";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AddPortfolioModal from "./AddPortfolioModal";
import Styles from "../../../Modules/Portfolio.module.css";

function Portfolio() {
  const [categories, setCategories] = React.useState([]);
  const [categoriesW, setCategoriesW] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [categoryId, setSelectedId] = React.useState(0);
  const [portfolioId, setPortfolio] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [src, setSrc] = React.useState("");

  const handleOpen = (v = 0, src = "") => {
    if (v) {
      setSrc(src);
      setPortfolio(v);
    }
    setOpen(true);
  };

  const handleOpenConfirmation = (id) => {
    setPortfolio(id);
    setOpenConfirmation(true);
  };

  const handleOnClose = (v) => {
    setSrc("");
    setPortfolio(0);
    if (v) {
      getData(categoryId, 0);
    }
    setOpen(false);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const d = await DELETE_PORTFOLIO(portfolioId);
      if (d && d.s) {
        ToastShow("Deleted successfully");
      }
      getData(categoryId, 0);
    }
    setPortfolio(0);
    setOpenConfirmation(false);
  };

  async function getCategories() {
    const t = await GETCATEGORY();

    if (t && t.s == 1) {
      setCategoriesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCategories([...c]);
      setSelectedId(t.r[0].id);
      setSelectedCategories([t.r[0].name]);
      getData(t.r[0].id, 0);
    }
  }

  const handleSelectCategories = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCategories([value]);
    const c = categoriesW.filter((v) => v.name == value);
    setSelectedId(c.length ? c[0].id : 0);
    await getData(c.length ? c[0].id : 0, 0);
  };

  async function getData(id, v) {
    if (!v) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GET_PORTFOLIO(v ? rows.length : 0, id);

    if (t && t.s == 1) {
      if (v) {
        setRows([...rows, ...t.r]);
      } else {
        if (t.r == null) {
          setRows([]);
        } else {
          setRows([...t.r]);
        }
      }
    }

    if ((t.r ?? []).length == 0) {
      setIsCall(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box width="100%">
      <Box
        sx={{
          height: "63px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "16px",
        }}
      >
        {/*Multi-Selection*/}
        <MultiSelection
          multiple={false}
          names={categories}
          handleChange={handleSelectCategories}
          selected={selectedCategories}
          lable="Categories"
        />

        {/*Add Button*/}
        <Button
          onClick={() => handleOpen()}
          startIcon={
            <img
              src={require("../../../Assets/add-button.png")}
              style={{ width: "14px", objectFit: "fill" }}
              alt=""
            />
          }
          sx={{
            height: "2.6rem",
            borderRadius: "5px",
            textTransform: "none",
            bgcolor: "#F8AF41",
            color: "black",
            "&:hover": {
              bgcolor: "rgb(173, 123, 44)",
            },
            fontSize: ".8rem",
            paddingX: "1.5rem",
          }}
        >
          <Typography fontWeight="500">Add</Typography>
        </Button>
      </Box>

      {!rows.length ? (
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>No Images</h5>
        </div>
      ) : (
        <div
          style={{
            overflowY: "auto",
            mb: "1rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            boxShadow: "3px 3px 7px 0px #00000040",
          }}
        >
          <div className={Styles.grid}>
            {rows.map((v) => (
              <div
                className={Styles.gridItem}
                key={v.id}
                // onClick={() =>
                //   window.open(v.file.includes("https")
                //   ? v.thumb
                //   : API_URL.baseUrl + API_URL.portfolioImage + v.file)
                // }
              >
                <LazyLoadImage
                  src={
                    v.file.includes("https")
                      ? v.thumb
                      : API_URL.baseUrl + API_URL.portfolioImage + v.file
                  }
                  width="100%"
                  height="100%"
                  effect="blur"
                  style={{
                    objectFit: "cover",
                    borderRadius: "6px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                  }}
                  alt=""
                />

                <Box
                  sx={{
                    top: 5,
                    right: 10,
                    position: "absolute",
                  }}
                >
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      onClick={() => handleOpenConfirmation(v.id)}
                      sx={{
                        mr: 1,
                        padding: "3px",
                        backgroundColor: "#ffffffc5",
                        "&:hover": {
                          backgroundColor: "#ffffffc5",
                        },
                      }}
                    >
                      <Delete fontSize="0.8rem" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="View">
                    <IconButton
                      size="small"
                      onClick={() =>
                        window.open(
                          v.file.includes("https")
                            ? v.file
                            : API_URL.baseUrl + API_URL.portfolioImage + v.file
                        )
                      }
                      sx={{
                        padding: "3px",
                        backgroundColor: "#ffffffc5",
                        "&:hover": {
                          backgroundColor: "#ffffffc5",
                        },
                      }}
                    >
                      <Launch fontSize="0.8rem" />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box
                  sx={{
                    bottom: 5,
                    right: 10,
                    position: "absolute",
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      cursor: "default",
                      padding: "3px",
                      backgroundColor: "#ffffffc5",
                      "&:hover": {
                        backgroundColor: "#ffffffc5",
                      },
                    }}
                  >
                    {(v?.type ?? 1) == 1 ? (
                      <Image color="primary" fontSize="0.8rem" />
                    ) : (
                      <VideoLibrary color="primary" fontSize="0.8rem" />
                    )}
                  </IconButton>
                </Box>
              </div>
            ))}
          </div>
        </div>
      )}

      {/*Add Button Code (NEW)*/}
      {open && (
        <AddPortfolioModal
          sCat={selectedCategories[0]}
          id={portfolioId}
          categories={categories}
          categoriesW={categoriesW}
          open={open}
          handleOnClose={handleOnClose}
          src={src}
        />
      )}

      {/*DELETE PORTFOLIO*/}
      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete!"
          content={`Are you sure want to delete it?`}
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </Box>
  );
}

export default Portfolio;
