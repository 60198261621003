import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ACTIVE_INACTIVE_ROLE, GETSROLE } from "../../../Apis/podian_api";

import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import SplitButton from "../../../Component/Button/SplitButton";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import AddUpdateRoleDailog from "../../../Component/Dailog/AddUpdateRoles";
import { useNavigate } from "react-router-dom";
import { Fullscreen } from "@mui/icons-material";
import ButtonView from "../../../Component/Button/ButtonView";

function Roles() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoleRows, setSelectedRoleRows] = useState(null);
  const [openRole, setOpenRole] = useState(false);
  const [openDeleteRole, setOpenDeleteRole] = useState(false);
  const navigation = useNavigate();

  const handleCloseRole = (v) => {
    if (v) {
      getRoles();
    }
    setSelectedRoleRows(null);
    setOpenRole(false);
  };

  async function getRoles() {
    setIsLoading(true);
    const d = await GETSROLE();
    if (d && d.s) {
      if (d.r) {
        setRows([...d.r]);
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getRoles();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px">
            {params.api.getRowIndex(params.row.id) + 1}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.row.name}</Typography>;
      },
    },
    {
      field: "reporting_time",
      headerName: "Reporting Time",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px">{params.row.reporting_time}</Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            color={params.value ? "#0DA800" : "#E50000"}
            fontSize="12.4px"
          >
            {params.value ? "Active" : "Inactive"}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 130,
      renderCell: (params) => (
        <Box sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          <SplitButton
            key={params.id}
            dataId={params.id}
            isDelete={1}
            onDelete={onDelete}
            deleteText={params.row.status == 1 ? "Inactive" : "Active"}
            title={params.value}
            onEdit={onEdit}
            isEdit={1}
            viewText="View"
            onView={(v) => navigation("/roles/" + v)}
            isView={1}
            deleteIcon={<Fullscreen />}
            visible={params.row.id}
          />
        </Box>
      ),
    },
  ];

  const onEdit = (v) => {
    // console.log(v);
    const t = rows.filter((j) => j.id == v);
    // // console.log(t.length);
    setSelectedRoleRows(t[0]);
    setOpenRole(true);
  };

  const onDelete = async (id) => {
    const t = rows.filter((j) => j.id == id);
    setSelectedRoleRows(t[0]);
    setOpenDeleteRole(true);
  };

  const handleOpenCloseDeleteRole = async (v) => {
    if (v) {
      const res = await ACTIVE_INACTIVE_ROLE(
        selectedRoleRows.id,
        selectedRoleRows.status
      );

      if (res && res.s) {
        getRoles();
      }
    }
    setSelectedRoleRows(null);
    setOpenDeleteRole(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        <DataGridCover
          component={
            <DataGrideView
              isLoading={isLoading}
              rows={rows}
              columns={columns}
              colHeight={null}
              sx={{ height: "78vh" }}
            />
          }
          enableButton={true}
          title="Roles List"
          buttonLabel="Add Role"
          buttonWidth="9rem"
          onClick={() => {
            setOpenRole(true);
          }}
        />

        {openRole && (
          <AddUpdateRoleDailog
            handleOnClose={handleCloseRole}
            open={openRole}
            row={selectedRoleRows}
          />
        )}
      </Box>

      {openDeleteRole && (
        <ConfirmationDailog
          title="Change role status"
          content={`Are you sure, want to ${
            selectedRoleRows.status == 1 ? "Inactive" : "Active"
          } it?`}
          open={openDeleteRole}
          handleOnClose={handleOpenCloseDeleteRole}
        />
      )}
    </>
  );
}

export default Roles;
