import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { ToastShow } from "../../../../../../Component/Toast/Toast";
import { ADD_ON_SHOT_LINK_NEW } from "../../../../../../Apis/new_on_spot_api";

function AddNewOnSpotLinkD({on_spot_city_location_zone_provider_id, open, handleOnClose, row, id, on_spot_id,on_spot_city_location_id,on_spot_city_location_zone_provider_customer_id }) {
  const d = new Date();
  const dd = d.setDate(d.getDate() + 7);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = React.useState(row ? row.link : "");
  const [clicks, setClicks] = React.useState(row ? row.clicks : "");
  const [clips, setClips] = React.useState(row ? row.clips : "");
  const [editor, setEditor] = React.useState(row ? row.editor : "");
  const [remark, setRemark] = React.useState(row ? row.remarks : "");
  const [startDate, setStartDate] = React.useState(
    row ? (row.expiry ? Date.parse(row.expiry) : null) : dd
  );

  const handleOnSubmit = async () => {
    if (!link.length) {
      ToastShow("Please enter the link");
    } else {
      setIsLoading(true);
      const d = await ADD_ON_SHOT_LINK_NEW({
        link: link ?? null,
        expiry: startDate ? format(startDate, "yyyy-MM-dd HH:mm:ss") : null,
        editor,
        clips,
        clicks,
        id,
        on_spot_id,
        remarks:remark,
        on_spot_city_location_id,
        on_spot_city_location_zone_provider_customer_id,
        on_spot_city_location_zone_provider_id
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{id == 0 ? "Add" : "Update"} Link</DialogTitle>
        <DialogContent
          sx={{
            overflow: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            fullWidth
            sx={{
              mt: 2,
            }}
            size="small"
            label="Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />

          <TextField
            fullWidth
            sx={{
              mt: 2,
            }}
            size="small"
            label="Editor"
            value={editor}
            onChange={(e) => setEditor(e.target.value)}
          />

          <TextField
            fullWidth
            sx={{
              mt: 2,
            }}
            type="number"
            size="small"
            label="Number of clicks"
            value={clicks}
            onChange={(e) => setClicks(e.target.value)}
          />

          <TextField
            fullWidth
            type="number"
            sx={{
              mt: 2,
            }}
            size="small"
            label="Number of Clips"
            value={clips}
            onChange={(e) => setClips(e.target.value)}
          />

          <TextField
            fullWidth
            type="text"
            sx={{
              mt: 2,
            }}
            size="small"
            label="Remarks"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              minDate={Date.now()}
              label="Expiry DateTime"
              value={startDate}
              onChange={(v) => {
                setStartDate(v);
              }}
              renderInput={(params) => (
                <TextField fullWidth sx={{ mt: 1.4 }} {...params} />
              )}
            />
          </LocalizationProvider>
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddNewOnSpotLinkD;
