import React from "react";
import { ToastShow } from "../Toast/Toast";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";

function PriceHour({ counter, handleCounter, isHour, index }) {
  return (
    <Box flex={1} sx={{
      display:"flex",
      justifyContent:"flex-end"
    }}>
      <Box
        sx={{
          width: "100px",
          bgcolor: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
         
        }}
      >
        <button
          style={{
            backgroundColor: "#CEE5FF",
            color: "black",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "3px",
            fontWeight: "bold",
          }}
          onClick={() => {
            if ((counter ?? 1) == 1) {
              ToastShow("Zero is not allowed");
              return;
            } else {
              handleCounter(-1, index);
            }
          }}
        >
          -
        </button>
        <Box sx={{}}>
          <Typography fontSize="12px">{counter ?? 1}</Typography>
        </Box>
        <button
          style={{
            backgroundColor: "#CEE5FF",
            color: "black",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "3px",
            fontWeight: "bold",
          }}
          onClick={() => handleCounter(1, index)}
        >
          +
        </button>
      </Box>
    </Box>
  );
}

export default PriceHour;
