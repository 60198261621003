import { createTheme } from "@mui/material/styles";
import { devlopment_mode } from "../Service/Constant";

export const theme = (mode) => {
  return createTheme({
    typography: {
      ...(mode === "light"
        ? {
            allVariants: {
              color: "#000",
              fontSize: "15px",
            },
            subtitle2: {
              color: "#575353",
            },
            subtitle1: {
              color: "#746e6e",
            },
            fontFamily: ["DM Sans", "sans-serif"].join(","),
          }
        : {
            allVariants: {
              color: "#fff",
              fontSize: "13px",
            },
            subtitle2: {
              color: "#e6dada",
            },
            subtitle1: {
              color: "#e6dada",
            },
            fontFamily: ["DM Sans", "sans-serif"].join(","),
          }),
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            common: {
              black: "#dbd5d5",
            },
            primary: {
              main: devlopment_mode ? "#2E8B57" : "#F8B03F",
            },
            secondary: {
              main: devlopment_mode ? "#064f444e" : "#ece2e267",
              dark: "#ecece2",
            },
            background: {
              default: "#F5F8FA",
              paper: "#fff",
            },

            divider: "#ebe1e1",
            info: {
              main: "#000",
            },
          }
        : {
            primary: {
              main: devlopment_mode ? "#064f44" : "#F8B03F",
            },
            secondary: {
              main: devlopment_mode ? "#1399854e" : "#151521",
              dark: "#c5aa7d8f",
            },
            background: {
              default: "#151521",
              paper: "#1E1E2D",
            },
            divider: "#5a5a6e65",
            common: {
              black: "#0000000",
            },
            info: {
              main: "#fff",
            },
          }),
    },
  });
};
