import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function LocationSearchField({
  address,
  handleAddress,
  updateLatLng,
  label = "Address",
  size = "small",
  disabled = false,
  width,
}) {
  const handleSelect = (address) => {
    handleAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => updateLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box
          width={width}
          bgcolor="white"
          borderRadius="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py="6px"
        >
          <Box width="96%">
            <Typography fontSize="12px" style={{ userSelect: "none" }}>
              Address
            </Typography>

            <TextField
              size={size}
              sx={{
                width: "100%",
                height: "2.3rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F6F6F6",
                borderRadius: "5px",
                "& fieldset": { border: "none" },
                "& .MuiFormLabel-root": {
                  color: "#969696",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
                  fontSize: "0.8rem",
                },
              }}
              {...getInputProps({
                disabled: disabled,
                placeholder: "Enter Address",
                className: "location-search-input",
              })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Box className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                //   const style = suggestion.active
                //     ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                //     : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <Box
                    sx={{
                      cursor: "pointer",
                      mb: 1,
                      borderBottom: "solid 0.01px",
                      borderBottomColor: "divider",
                    }}
                    width={"250px"}
                    key={suggestion.description}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      // style,
                    })}
                  >
                    <Typography
                      sx={{
                        color: `${
                          suggestion.active ? "primary.main" : "info.main"
                        }`,
                      }}
                      variant="subtitle2"
                    >
                      {suggestion.description}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}

export default LocationSearchField;
