import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_SUPPORT_CATEGORY = async (type = 1, parentId = 0, count) => {
  let query = "";

  if (type) {
    query += "&type=" + type;
  }

  if (parentId) {
    query += "&parent_id=" + parentId;
  }

  if (count) {
    query += "&count=" + count;
  }

  const res = await GETAPI(API_URL.supportCategoryGet, query);
  return res;
};

export const ADD_UPDATE_SUPPORT_CATEGORY = async (data) => {
  const formData = new FormData();

  if (data.type) {
    formData.append("type", data.type);
  }

  if (data.parentId) {
    formData.append("parent_id", data.parentId);
  } else {
    formData.append("parent_id", 0);
  }

  if (data.name) {
    formData.append("name", data.name);
  }

  formData.append("related_to", data?.relatedTo ?? 0);

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.supportCategoryUpdate : API_URL.supportCategoryAdd,
    formData
  );
  return res;
};

export const DELETESUPPORTCATEGORY = async (id) => {
  let query = "";

  if (id) {
    query += "&id=" + id;
  }
  const res = await GETAPI(API_URL.supportCategoryDelete, query);

  return res;
};

export const GET_SUPPORT_CATEGORY_ADMIN = async (categoryId, count) => {
  let query = "";

  if (categoryId) {
    query += "&category_id=" + categoryId;
  }

  if (count) {
    query += "&count=" + count;
  }

  const res = await GETAPI(API_URL.supportGetAdminUser, query);
  return res;
};

export const ADD_UPDATE_SUPPORT_CATEGORY_ADMIN = async (data) => {
  const formData = new FormData();

  formData.append("category_id", data.categoryId);

  formData.append("admin_user_ids", data.adminUserIds);

  const res = await POSTAPI(API_URL.supportAddAdminUser, formData);
  return res;
};

export const GET_SUPPORT_ADMIN_TO_ADD = async (categoryId) => {
  let query = "";

  if (categoryId) {
    query += "&category_id=" + categoryId;
  }

  const res = await GETAPI(API_URL.supportGetAdminUserToAdd, query);
  return res;
};

export const DELETESUPPORTCATEGORYADMIN = async (id) => {
  const formData = new FormData();

  formData.append("support_categories_admins_id", id);

  const res = await POSTAPI(API_URL.supportRemoveAdminUser, formData);

  return res;
};

export const GET_SUPPORT_TICKETS = async (data) => {
  let query = "";

  if (data.forWhome) {
    query += "&for=" + data.forWhome;
  }

  if (data.ticketType) {
    query += "&type=" + data.ticketType;
  }

  if (data.satisfaction) {
    query += "&satisfaction=" + data.satisfaction;
  }

  if (data.search) {
    query += "&search=" + data.search;
  }

  if (data.dateRange && Object.keys(data.dateRange).length) {
    query += "&from_date=" + data.dateRange.start;
    query += "&to_date=" + data.dateRange.end;
  }

  if (data.count) {
    query += "&count=" + data.count;
  }

  const res = await GETAPI(API_URL.supportForAdmin, query);
  return res;
};

export const GET_SUPPORT_TICKETS_DETAILS = async (data) => {
  let query = "";

  if (data.id) {
    query += "&id=" + data.id;
  }

  const res = await GETAPI(API_URL.supportGetDetails, query);
  return res;
};

export const GET_SUPPORT_TICKETS_DETAILS_REPLIES = async (data) => {
  let query = "";

  if (data.id) {
    query += "&id=" + data.id;
  }

  const res = await GETAPI(API_URL.supportGetReplies, query);
  return res;
};

export const SUPPORTADDREPLY = async ({ id, message, selectMedia }) => {
  const formData = new FormData();

  formData.append("support_ticket_id", id);
  formData.append("message", message);

  if (selectMedia.length) {
    for (let [index, v] of selectMedia.entries()) {
      formData.append(`attachments[${index}]`, v.media, Date.now() + ".png");
    }
  }

  const res = await POSTAPI(API_URL.supportAddReply, formData);

  return res;
};

export const GET_SUPPORT_TICKETS_CLOSE = async (id, reason) => {
  const formData = new FormData();

  if (id) {
    formData.append("id", id);
  }
  if (reason) {
    formData.append("note", reason);
  }

  const res = await POSTAPI(API_URL.supportCloseTicket, formData);
  return res;
};

export const GET_SUPPORT_TICKETS_REOPEN = async (id) => {
  let query = "";

  if (id) {
    query += "&id=" + id;
  }

  const res = await GETAPI(API_URL.supportReOpenTicket, query);
  return res;
};

export const GET_SUPPORT_CALLBACK = async (forV, parentId, count) => {
  let query = "";

  if (forV) {
    query += "&for=" + forV;
  }

  if (count) {
    query += "&count=" + count;
  }

  const res = await GETAPI(API_URL.supportCallBack, query);
  return res;
};

export const GET_SUPPORT_CALLBACK_DONE = async (support_ticket_callback_id) => {
  let query = "";

  if (support_ticket_callback_id) {
    query += "&support_ticket_callback_id=" + support_ticket_callback_id;
  }

  const res = await GETAPI(API_URL.supportCallBackDone, query);
  return res;
};

export const GET_PENDDING_CALL_BACK_COUNT = async (forType) => {
  let query = "";

  if (forType) {
    query += "&for=" + forType;
  }

  const res = await GETAPI(API_URL.getPendingCallBackCount, query);
  return res;
};

export const CREATE_TICKET = async (data) => {
  const formData = new FormData();

  //For update
  if (data.id) {
    formData.append("support_ticket_id", data.id);
  }

  formData.append("category_id", data.categoryId);

  formData.append("details", data.details);

  if (data.ordersId) {
    formData.append("orders_id", data.ordersId);
  }

  if (data.on_spot_city_location_zone_provider_customer_id) {
    formData.append(
      "on_spot_city_location_zone_provider_customer_id",
      data.on_spot_city_location_zone_provider_customer_id
    );
  }

  if (data.attachments?.length) {
    data.attachments.forEach((f) => {
      console.log(f);
      formData.append("attachments", f);
    });
  }

  if (data.userId) {
    formData.append("user_id", data.userId);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updateTicket : API_URL.createTicket,
    formData
  );
  return res;
};
