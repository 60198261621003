import { API_URL, GETAPI, POSTAPI } from "./config";

export const RESTRICT_USER = async (till_date, id, note,app) => {
    let query = "";

    if(till_date){
        query += "&till_date="+till_date;
    }

    if(note){
        query += "&note="+note;
    }

    if(app){
        query += "&app="+app;
    }

  const res = await GETAPI(
    API_URL.addRestriction,
    "&user_id=" + id + query
  );
  return res;
};

export const REMOVE_RESTRICT_USER = async ( id) => {
  const res = await GETAPI(
    API_URL.removeRestriction,
    "&id=" + id 
  );
  return res;
};


