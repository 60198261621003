import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState } from "react";
import { format } from "date-fns";
import ButtonView from "../Button/ButtonView";
import AddAddressDailog from "./ChangeAddressDailog";

function OrderEditDateTime({ open, handleOnClose, data }) {
  const [dateTime, setDateTime] = useState(Date.parse(data.date_time));
  const [openAdd,setOpenAdd] = useState(false);

  const handleDateTime = (v) => {
    setDateTime(v);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(3px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        <DialogTitle>Reschedule Order</DialogTitle>

        <DialogContent
        sx={{
          display:"flex",
          flexDirection:"column",
          alignItems:"start"
        }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              // disabled={!(status == 1)}
              // ampm={false}
              label="DateTime"
              value={dateTime}
              onChange={handleDateTime}
              renderInput={(params) => (
                <TextField
                  sx={{
                    marginTop: "0.4rem",
                  }}
                  focused
                  {...params}
                />
              )}
            />
          </LocalizationProvider>

          <Button 
          onClick={()=>setOpenAdd(true)}
          variant="text" sx={{mt:2,fontSize:"12px",textTransform:"none"}}>
            <Typography>Change Address ?</Typography>
          </Button>

        </DialogContent>

        <DialogActions>
          <ButtonView
            lable="Cancel"
            color="#E50000"
            bgcolor="#E5000033"
            fontSize="14px"
            sx={{ width: "100%" }}
            hoverBg="pink"
            onClick={() => handleOnClose(0)}
          />

          <ButtonView
            lable="Yes"
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={() =>
              handleOnClose(1, format(dateTime, "yyyy-MM-dd HH:mm:ss"))
            }
          />
        </DialogActions>
      </Dialog>

      {
        openAdd && <AddAddressDailog handleOnClose={()=>setOpenAdd(false)} open={openAdd} row={data}/>
      }
    </>
  );
}

export default OrderEditDateTime;
