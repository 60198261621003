import { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import {
  DELETE_PAYOUT,
  GET_BALANCE_PAYOUT,
  GET_MONEY_TRANS,
} from "../../../Apis/payout_api";
import { useParams } from "react-router-dom";
import { ToastShow } from "../../../Component/Toast/Toast";
import Details from "./comps/Details";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import AddModal from "./comps/AddModal";
import add_button from "../../../Assets/add_button.png";
import ButtonIcon from "../../../Component/Button/ButtonIcon";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import SplitButton from "../../../Component/Button/SplitButton";

const PayoutView = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [balanceData, setBalanceData] = useState({});
  const [openAddPayout, setOpenAddPayout] = useState(false);
  const [openAddRazorpay, setOpenAddRazorpay] = useState(false);

  const { id } = useParams();

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      width: 60,
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>
            {params.api.getRowIndex(params.row.id) + 1}
          </Typography>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      width: 140,
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>
            {params.row.added_by ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>
            {params.row.amount}
          </Typography>
        );
      },
    },
    {
      field: "from_type",
      headerName: "From Type",
      width: 130,
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>
            {params.row.from_type}
          </Typography>
        );
      },
    },
    {
      field: "unlock_on",
      headerName: "Unlock On",
      width: 140,
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row.unlock_on ? (
              <DateMDYHMA date={params.row.unlock_on} onlyDate={true} />
            ) : (
              <Typography sx={{ fontSize: "11.8px" }}>-</Typography>
            )}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 140,
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.row.created_at} />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          isEdit={null}
          isDelete={1}
          isView={null}
          title={params.value}
          onDelete={() => deleteAPayout(params.row.id)}
        />
      ),
    },
  ];

  const getData = async () => {
    setLoading(true);
    const res = await GET_MONEY_TRANS({ user_id: id });
    if (res && res.s) {
      setData(res.r ?? []);
    } else {
      ToastShow(res.m);
    }
    setLoading(false);
  };

  const getBalanceData = async () => {
    setLoading(true);
    const res = await GET_BALANCE_PAYOUT(id);
    if (res && res.s) {
      setBalanceData(res.r ?? []);
    } else {
      ToastShow(res.m);
    }
    setLoading(false);
  };

  const deleteAPayout = async (id) => {
    const res = await DELETE_PAYOUT(id);
    if (res && res.s) {
      ToastShow(res.m);
      fetchData();
    } else {
      ToastShow(res.m);
    }
  };

  const fetchData = () => {
    getData();
    getBalanceData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack gap="2rem">
          <Details data={balanceData} showBalance={true} />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "1.2rem",
            }}
          >
            <Details data={balanceData} showCoin={true} />
            <Details data={balanceData} showOrder={true} />
          </Box>

          <Details data={balanceData} showWallet={true} />

          {/*DataGrid*/}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "3px 3px 7px 0px #00000040",
              paddingTop: "14px",
              gap: "7px",
            }}
          >
            <Box
              sx={{
                width: "97.5%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: ".1rem",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                Money Transactions
              </Typography>

              <Box display="flex" gap="1rem">
                <ButtonIcon
                  width="13rem"
                  gap="0.5rem"
                  bgcolor="#F8AF41"
                  icon={add_button}
                  label="Add Payout"
                  onClick={() => setOpenAddPayout(true)}
                />

                <ButtonIcon
                  width="13rem"
                  gap="0.5rem"
                  bgcolor="#F8AF41"
                  icon={add_button}
                  label="Add RazorPay Payout"
                  onClick={() => setOpenAddRazorpay(true)}
                />
              </Box>
            </Box>

            <Box width="97.5%">
              <DataGrideView
                rows={data ?? []}
                columns={columns}
                colHeight={"3.4rem"}
                columnColor="#F6F6F6"
                sx={{ height: "83.8vh" }}
              />
            </Box>
          </Box>
        </Stack>
      )}

      {openAddPayout && (
        <AddModal
          open={openAddPayout}
          fetchData={fetchData}
          userId={id}
          handleOnClose={() => setOpenAddPayout(false)}
          title="Add Payout"
        />
      )}

      {openAddRazorpay && (
        <AddModal
          open={openAddRazorpay}
          fetchData={fetchData}
          userId={id}
          handleOnClose={() => setOpenAddRazorpay(false)}
          title="Add RazorPay Payout"
        />
      )}
    </>
  );
};

export default PayoutView;
