import { useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import Link from "../../../../../Assets/Link.png";
import { ToastShow } from "../../../../../Component/Toast/Toast";

const SubmittedLink = ({ link }) => {
  return (
    <Box display="flex" gap="5px">
      <Button
        sx={{
          bgcolor: "#CEE5FF",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          width: "6rem",
          color: "#CEE5FF",
          "&:hover": {
            bgcolor: "#87a3c4",
          },
        }}
        style={{ minWidth: "0" }}
        onClick={() => {
          if (!link.toLocaleLowerCase().trim().startsWith("http")) {
            window.open(`https://${link}`, "_blank");
            return;
          }
          window.open(`${link}`, "_blank");
        }}
      >
        <Typography fontSize="11px" fontWeight="500">
          OPEN LINK
        </Typography>
      </Button>
      <Tooltip title="Copy">
        <Button
          sx={{
            bgcolor: "#CEE5FF",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",

            color: "#CEE5FF",
            "&:hover": {
              bgcolor: "#87a3c4",
            },
          }}
          style={{ minWidth: "0" }}
          onClick={() => {
            navigator.clipboard.writeText(link);
            ToastShow("Copied");
          }}
        >
          <img
            src={Link}
            style={{ objectFit: "fill", width: "10.8px" }}
            alt=""
          />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default SubmittedLink;
