import React, { useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { Paper, Typography, Modal, Box, Button, Tooltip } from "@mui/material";
import TextFieldView from "../../../Component/TextField/TextFieldView";
import "react-quill/dist/quill.snow.css";
import { CATEGORYADD, GETCATEGORYDETAILS } from "../../../Apis/category_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import { API_URL } from "../../../Apis/config";
import TextFieldView2 from "../../../Component/TextField/TextFieldView2";
import InfoIcon from "../../../Assets/InfoIcon.png";

export default function CategoryAddModal({
  cid,
  open,
  setOpen,
  fetchData = null,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false);
  };
  const [name, setName] = React.useState("");
  const [value, setValue] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [netWorkImage, setNetWorkImage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSelectImage = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (!selectedImage && !netWorkImage) {
      ToastShow("Please select the image");
    } else if (!name) {
      ToastShow("Please fill name");
    } else {
      const res = await CATEGORYADD({
        cId: cid,
        name: name,
        // description: value ?? "",
        photo: selectedImage,
      });

      if (res && res.s) {
        setValue("");
        setName("");
        setSelectedImage(null);
        if (cid) {
          getData();
        }
        if (fetchData) {
          fetchData();
        }
        ToastShow(
          cid ? "Category update successfully" : "Category added successfully"
        );
      } else {
        ToastShow("Opps! something went wrong. Please try again");
      }
    }
    handleClose();
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "vcIdeo",
    "formula",
  ];

  async function getData() {
    setIsLoading(true);
    const t = await GETCATEGORYDETAILS(cid);
    if (t && t.s == 1) {
      setName(t.r.name);
      setNetWorkImage(API_URL.baseUrl + API_URL.categoryImage + t.r.photo);
      setValue(t.r.description);
    }
  }

  useEffect(() => {
    if (cid) {
      getData();
    } else {
      setName("");
      setNetWorkImage(null);
    }
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(4px)",
          bgcolor: "rgba(105, 55, 0, 0.4)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "37vw",
            bgcolor: "background.paper",
            borderRadius: "6px",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                py: ".2rem",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="start"
                width="94%"
                mt=".8rem"
              >
                {/* TITLE */}
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    padding: ".6rem",
                    borderRadius: ".3rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {cid !== null ? "Category Edit" : "Category Add"}
                  <GrClose
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </Typography>
                <Typography
                  py=".4rem"
                  ml="1.2rem"
                  fontSize=".830rem"
                  color="#595959"
                >
                  Please Fill All Details And{" "}
                  {cid !== "" ? "Update" : "Add New"} Category In Your Category
                  Directory
                </Typography>
              </Box>

              {/* Image */}
              <Box
                sx={{
                  backgroundColor: "#F6F6F6",
                  width: "94%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.4rem",
                  padding: "1rem",
                  borderRadius: ".3rem",
                }}
              >
                {/* Name Input */}
                <Box
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    height: "6rem",
                    paddingX: "1rem",
                    paddingBottom: ".4rem",
                    borderRadius: ".3rem",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pr="0.5rem"
                  >
                    <Typography fontWeight="400">Name</Typography>
                    <Tooltip title="Characters limit is 20!">
                      <img
                        src={InfoIcon}
                        style={{
                          width: "1rem",
                          height: "1rem",
                          objectFit: "fill",
                        }}
                        alt="infoIcon"
                      />
                    </Tooltip>
                  </Box>
                  <TextFieldView2
                    placeholder="Enter Categories Name"
                    onChange={(e) => {
                      if (e.target.value.length <= 20) {
                        setName(e.target.value);
                      }
                    }}
                    sx={{ width: "100%", height: "3.4rem" }}
                    value={name}
                    type="text"
                    variant="outlined"
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      height: "20vh",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "start",
                        flexDirection: "column",
                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                        borderRadius: ".5rem",
                      }}
                    >
                      {/*SELECT IMAGE*/}
                      <Box
                        sx={{
                          height: "100%",
                          width: "9rem",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexDirection: "column",
                          backgroundColor: "white",
                          borderRadius: 2,
                        }}
                      >
                        <Typography color="#595959">Select Image</Typography>

                        <Button
                          sx={{
                            width: "95px",
                            height: "5.8rem",
                            boxShadow: 0,
                            borderRadius: 3,
                            backgroundColor: "#F6F6F6",
                            "&:hover": {
                              backgroundColor: "#D3D3D3",
                            },
                          }}
                          variant="contained"
                          component="label"
                        >
                          <input
                            hidden
                            type="file"
                            accept="image/*"
                            cId="select-image"
                            onChange={handleSelectImage}
                          />
                          <img
                            src={require("../../../Assets/img-placeholder.png")}
                            alt="Select"
                            width="35px"
                          />
                        </Button>
                      </Box>
                      {/*SELECT IMAGE*/}
                    </Box>

                    {selectedImage || netWorkImage ? (
                      <Box
                        sx={{
                          height: "100%",
                          width: "13rem",
                          objectFit: "contain",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: ".8rem",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          width="100%"
                          height="auto"
                          objectFit="cover"
                          src={
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : netWorkImage
                          }
                          alt=""
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: "100%",
                          width: "9rem",
                          objectFit: "contain",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #D3D3D3",
                          borderRadius: 2,
                          backgroundColor: "white",
                        }}
                      >
                        <Typography color="#595959">Image preview</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Typography fontSize=".830rem" color="#595959">
                  Please Select An Png, Jpeg, Jpg, Gif File Only.
                </Typography>
              </Box>

              <Button
                sx={{
                  width: "14rem",
                  height: "50px",
                  py: 1.2,
                  mt: 1.3,
                  mb: 1,
                  pt: "-1rem",
                  paddingBottom: "-1rem",
                  borderRadius: "6px",
                }}
                variant="contained"
                onClick={handleSubmit}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontWeight: "500",
                    fontSize: "1.3rem",
                  }}
                >
                  {cid !== null ? "Update Category" : "Create Category"}
                </Typography>
              </Button>
            </Paper>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
