import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
  Popover,
  Stack,
  IconButton
} from "@mui/material";
import { addDays, format, lastDayOfMonth, subDays } from "date-fns";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomProvider, DateRangePicker } from "rsuite";
import { GETPROVIDERSAVL } from "../../Apis/service_api";
import DataGrideView from "../DataGrideView/DataGrideView";
import DateMDYHMA from "../Date/DateMDYHMA";
import { GrClose } from "react-icons/gr";
import "../../Pages/Screens/Order/Order.css";
import ButtonForGrid from "../Button/ButtonForGrid";

function DateRangeSelection({ open, handelOnClose, userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [rows, setRows] = useState([]);
  const [isCall, setIsCall] = useState(true);
  const [dateRange, setDateRange] = useState({});

  const navigate = useNavigate();

  const callBack = async (l) => {
    if (l >= rows.length && isCall) {
      await getData(dateRange);
    }
  };

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
      getData(d, 1);
    } else {
      setDateRange(v);
      setRows([]);
      // getData(v, 1);
    }
  };

  const getData = async (d = {}, v = 0) => {
    setIsLoading(true);

    const data = await GETPROVIDERSAVL({
      count: v ? 0 : rows.length,
      dateRange: d,
      userId: userId,
    });

    if (data && data.s && !v) {
      setRows([...rows, ...(data ? data.r ?? [] : [])]);
    } else {
      setRows([...(data ? data.r ?? [] : [])]);
    }

    setIsLoading(false);
    if (v) {
      setIsCall(true);
    }
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "start",
      align: "start",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1
    }, {
      field: "order_id",
      headerName: "Order ID",
      headerAlign: "center",
      align: "center",
      minWidth: 140,
      flex: 1
    },
    {
      field: "from_dt",
      headerName: "Start Time",
      headerAlign: "center",
      align: "center",
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "to_dt",
      headerName: "End Time",
      headerAlign: "center",
      align: "center",
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "navigate",
      headerName: "Navigate",
      headerAlign: "center",
      align: "center",
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return params.row.type == 1 ? <ButtonForGrid label='View' onClick={() => navigate("/order/" + params.row.ref_id)} /> : null;
      },
    },
  ];

  useEffect(() => {
    handleSelectDate([new Date(), new Date()]);
  }, [])

  return (
    <Popover
      open={open}
      onClose={handelOnClose}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        zIndex: 1
      }}
    >
      <Stack gap={2} width='63vw' m={2.2}>
        <Stack gap={2}>
          <Stack
            direction="row"
            bgcolor="#F6F6F6"
            paddingY="0.450rem"
            paddingX="0.6rem"
            borderRadius="5px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontSize="20px"
              fontWeight="500"
              sx={{ userSelect: "none" }}
            >
              Select date to know schedule
            </Typography>
            <GrClose
              style={{ fontSize: "1rem", cursor: "pointer" }}
              onClick={handelOnClose}
            />
          </Stack>
          <Stack
            direction="row"
            bgcolor="#F6F6F6"
            paddingY="0.450rem"
            paddingX="0.6rem"
            borderRadius="5px"
            justifyContent="space-between"
            alignItems="center"
          >
            {/*Calendar new*/}
            <Box
              sx={{
                bgcolor: "white",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingY: ".245rem",
                gap: ".1rem",
              }}
            >
              <Box width="95%">
                <Box
                  sx={{
                    display: "flex",
                    gap: ".3rem",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "0",
                      padding: "0",
                    }}
                  >
                    <img
                      src={require("../../Assets/calendar-icon.png")}
                      style={{ objectFit: "fill", width: "11px" }}
                      alt=""
                    />
                  </IconButton>
                  <Typography fontSize="10px">Date Select</Typography>
                </Box>
              </Box>
              <Box width="95%">
                <CustomProvider theme={theme.palette.mode}>
                  <DateRangePicker
                    // defaultValue={[new Date(), new Date()]}
                    className="date"
                    placeholder="dd/mm/yyyy ~ dd/mm/yyyy"
                    appearance="subtle"
                    placement="bottomEnd"
                    caretAs="a"
                    size="sm"
                    style={{
                      backgroundColor: "#F6F6F6",
                      width: "11rem",
                      height: "1.7rem",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onChange={(v) => handleSelectDate(v ?? {})}
                    ranges={[
                      {
                        label: "Yesterday",
                        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                      },
                      {
                        label: "Today",
                        value: [new Date(), new Date()],
                      },
                      {
                        label: "Tomorrow",
                        value: [addDays(new Date(), 1), addDays(new Date(), 1)],
                      },
                      {
                        label: "Last 7 days",
                        value: [subDays(new Date(), 6), new Date()],
                      },
                      {
                        label: "This week",
                        value: [
                          subDays(
                            new Date(
                              `${firstdayW.getFullYear()}-${firstdayW.getMonth() + 1
                              }-${firstdayW.getDate()}`
                            ),
                            0
                          ),
                          addDays(
                            new Date(
                              new Date(
                                `${lastdayW.getFullYear()}-${lastdayW.getMonth() + 1
                                }-${lastdayW.getDate()}`
                              )
                            ),
                            0
                          ),
                        ],
                      },
                      {
                        label: "This month",
                        value: [
                          subDays(
                            new Date(
                              `${today.getFullYear()}-${today.getMonth() + 1}-01`
                            ),
                            0
                          ),
                          addDays(
                            new Date(
                              new Date(
                                `${today.getFullYear()}-${today.getMonth() + 1
                                }-${lastDayOfMonth(today).getDate()}`
                              )
                            ),
                            0
                          ),
                        ],
                      },
                      {
                        label: "This year",
                        value: [
                          subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                          addDays(
                            new Date(new Date(`${today.getFullYear()}-12-31`)),
                            0
                          ),
                        ],
                      },
                    ]}
                  />
                </CustomProvider>
              </Box>
            </Box>

            {/*Calendar old*/}
            {/* <CustomProvider theme={theme.palette.mode}>
              <DateRangePicker
                defaultValue={[new Date(), new Date()]}

                placement="bottomEnd"
                style={{ marginBottom: "10px", zIndex: 10000000000 }}
                size="lg"
                onChange={(v) => handleSelectDate(v ?? {})}
                ranges={[
                  {
                    label: "Yesterday",
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: "Today",
                    value: [new Date(), new Date()],
                  },
                  {
                    label: "Tomorrow",
                    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
                  },
                  {
                    label: "Last 7 days",
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: "This week",
                    value: [
                      subDays(
                        new Date(
                          `${firstdayW.getFullYear()}-${firstdayW.getMonth() + 1
                          }-${firstdayW.getDate()}`
                        ),
                        0
                      ),
                      addDays(
                        new Date(
                          new Date(
                            `${lastdayW.getFullYear()}-${lastdayW.getMonth() + 1
                            }-${lastdayW.getDate()}`
                          )
                        ),
                        0
                      ),
                    ],
                  },
                  {
                    label: "This month",
                    value: [
                      subDays(
                        new Date(
                          `${today.getFullYear()}-${today.getMonth() + 1}-01`
                        ),
                        0
                      ),
                      addDays(
                        new Date(
                          new Date(
                            `${today.getFullYear()}-${today.getMonth() + 1
                            }-${lastDayOfMonth(today).getDate()}`
                          )
                        ),
                        0
                      ),
                    ],
                  },
                  {
                    label: "This year",
                    value: [
                      subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                      addDays(
                        new Date(new Date(`${today.getFullYear()}-12-31`)),
                        0
                      ),
                    ],
                  },
                ]}
              />
            </CustomProvider> */}
          </Stack>
        </Stack>

        <DataGrideView
          rows={rows}
          columns={columns}
          callBack={callBack}
          isLoading={isLoading}
          hideFooter={true}
          sx={{ height: '70vh' }}
        />
      </Stack>

    </Popover>
  );
}

export default DateRangeSelection;
