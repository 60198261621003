import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import { PhotoCamera } from "@mui/icons-material";
import MultiSelection from "../Multiselection/MultiSelection";
import { API_URL } from "../../Apis/config";
import { ADD_VIDEO_STORY } from "../../Apis/video_api";

function VideoStoryDailog({
  open,
  handleOnClose,
  cities,
  citiesW,
  id,
  src = "",
  n = "",
  u = "",
}) {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImageCover, setSelectedImageCover] = React.useState(null);
  const [selectedImageThumb, setSelectedImageThumb] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [title, setTitle] = React.useState(n);
  const [url, setUrl] = React.useState(u);

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        canvas.toBlob((blob) => {
          setUrl("");
          return resolve(blob);
        });
      };
    });
  };

  const handleOnSubmit = async () => {
    if (!selectedImageCover && !id && !url) {
      ToastShow("Please select image or url");
    } else if (!selectedImageThumb && !id) {
      ToastShow("Please select thumb image");
    } else if (!selectedCities.length && !id) {
      ToastShow("Please select city");
    } else {
      const c = citiesW.filter((v) => v.name == selectedCities[0]);
      setIsLoading(true);
      const d = await ADD_VIDEO_STORY({
        file: selectedImage,
        thumb: selectedImageThumb,
        city_id: !id ? c[0].id : 0,
        id: id,
        url: url,
        title: title,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!id ? "Add Story" : "Update Story"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              component: "label",
              height: "150px",
              width: "150px",
              border: "dotted .3px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "fill",
              p: "1px",
            }}
          >
            {(src || selectedImageCover) && !url ? (
              <img
                style={{ borderRadius: "5px" }}
                height="150px"
                width="150px"
                src={
                  selectedImageCover
                    ? URL.createObjectURL(selectedImageCover)
                    : API_URL.baseUrl + API_URL.videoStoryImage + src
                }
                alt="video Story"
              />
            ) : (
              <Typography>Select Video</Typography>
            )}
            <input
              hidden
              accept="video/*"
              type="file"
              onChange={async (event) => {
                if (event.target.files) {
                  setSelectedImage(event.target.files[0]);
                  const d = await generateVideoThumbnail(event.target.files[0]);
                  setSelectedImageCover(d);
                }
              }}
            />
          </Box>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="video/*"
              type="file"
              onChange={async (event) => {
                if (event.target.files) {
                  setSelectedImage(event.target.files[0]);
                  const d = await generateVideoThumbnail(event.target.files[0]);
                  setSelectedImageCover(d);
                }
              }}
            />
            <PhotoCamera />
          </IconButton>

          <Typography>OR</Typography>

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Url"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              if (selectedImage) {
                setSelectedImage(null);
                setSelectedImageCover(null);
              }
            }}
          />

          <Box
            sx={{
              width: "100%",
              height: "1px",
              my: 2,
              backgroundColor: "divider",
            }}
          ></Box>
          <Box
            sx={{
              mt: 2,
              height: "150px",
              width: "150px",
              border: "dotted .3px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              objectFit: "fill",
              p: "1px",
            }}
          >
            {src || selectedImageThumb ? (
              <img
                style={{ borderRadius: "5px" }}
                height="150px"
                width="150px"
                src={
                  selectedImageThumb
                    ? URL.createObjectURL(selectedImageThumb)
                    : API_URL.baseUrl + API_URL.videoStoryImage + src
                }
                alt="video Story"
              />
            ) : (
              <Typography>Select Thumb</Typography>
            )}
          </Box>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={async (event) => {
                if (event.target.files) {
                  setSelectedImageThumb(event.target.files[0]);
                }
              }}
            />
            <PhotoCamera />
          </IconButton>

          {id == 0 ? (
            <MultiSelection
              multiple={false}
              names={cities}
              handleChange={handleSelectCity}
              selected={selectedCities}
              lable="Cities"
            />
          ) : null}

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default VideoStoryDailog;
