import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataGrideView from "../../../../Component/DataGrideView/DataGrideView";
import { API_URL } from "../../../../Apis/config";
import SplitButton from "../../../../Component/Button/SplitButton";
import ConfirmationDailog from "../../../../Component/Dailog/ConfirmationDailog";
import { DELETE_WEB_FEATURE, GET_WEB_FEATURE } from "../../../../Apis/web/feature_api";
import AddUpdateWebFeatureDailog from "../../../../Component/Dailog/web/AddUpdateFeature";
import { GET_WEB_FOR_PODIAN } from "../../../../Apis/web/forPodian_api";
import AddUpdateWebForPodianDailog from "../../../../Component/Dailog/web/AddUpdateForPodian";

function WebForPodian() {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isLoading, SetIsLoading] = useState(true);

  const getData = async () => {
    SetIsLoading(true);
    const res = await GET_WEB_FOR_PODIAN();

    if (res && res?.s) {
      setRow(res?.r ?? []);
    } else {
      setRow([]);
    }
    SetIsLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (v) => {
    if (v) {
      getData();
    }
    setSelectedRow(null);
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "SR.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 100,
      minWidth: 100,
      renderCell: (params) => {
        // console.log(params);
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "label",
      headerName: "label",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "url",
      headerName: "Url",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Typography sx={{ py: 1, fontSize: "13px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "photo",
      headerName: "Photo",
      filterable: false,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value ? (
          <Avatar
            onClick={() =>
              window.open(
                API_URL.baseUrl + "uploads/web/forpodian/" + params.value,
                "_blank"
              )
            }
            sx={{ height: "30px", width: "30px", cursor: "pointer" }}
            src={API_URL.baseUrl + "uploads/web/forpodian/" + params.value}
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 70,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          // isView={rightsUser.includes(4) ? 1 : null}
          dataId={params.row.id}
          isDelete={null}
          isEdit={1}
          onEdit={onEdit}
          onView={null}
        //   onDelete={onDelete}
          // deleteText="Delete"
        />
      ),
    },
  ];

  const onEdit = (id) => {
    // console.log(id, "id");
    setSelectedRow(() => row.find((v) => v.id == id));
    setOpen(true);
  };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
       

        <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
          <DataGrideView
            rows={row}
            columns={columns}
            // callBack={callBack}
            isLoading={isLoading}
            sx={{
              height: "75vh",
            }}
            autoHeight={true}
          />
        </Box>
      </Box>
      {open && (
        <AddUpdateWebForPodianDailog
          open={open}
          handleOnClose={handleClose}
          row={selectedRow}
        />
      )}

      
    </>
  );
}

export default WebForPodian;
