import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import MultiSelection from "../Multiselection/MultiSelection";
import { useEffect } from "react";
import { notifyAgain } from "../../Apis/order_api";

function NotifyAgainDailog({ open, handleOnClose, data,id }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [itemsName, setItemsName] = React.useState([]);

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities(value);
  };

  const handleOnSubmit = async () => {
    if (!selectedCities.length) {
      ToastShow("Please select service");
    } else {
      const c = data
        .filter((v) => selectedCities.includes(v.name))
        .map((v) => v.id);
      setIsLoading(true);
      console.log(c.join(","));
      const res = await notifyAgain(id,c.join(","));
      if (res && res) {
        ToastShow("Successfully notify");
        handleOnClose(0);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // console.log(data);
    const t = [];
    if (data.length) {
      for (let v of data) {
        t.push(v.name);
      }
    }
    setItemsName(t);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>Notify Again</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MultiSelection
            multiple={true}
            names={itemsName}
            handleChange={handleSelectCity}
            selected={selectedCities}
            lable="Service"
            width="100%"
            containerWidth="100%"
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default NotifyAgainDailog;
