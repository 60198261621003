import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_NEW_ON_SPOT = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("on_spot_category_id", data.on_spot_category_id);

  formData.append("description", data.description);
  formData.append("term_condition", data.term_condition);
  formData.append("isPaid", data.isPaid);

  if (data.id) {
    formData.append("id", data.id);
  }
  if (data.logo) {
    formData.append("logo", data.logo, "logo.png");
  }

  const res = await POSTAPI(
    data.id ? API_URL.onspot2Update : API_URL.onspot2Add,
    formData
  );

  return res;
};

export const GETONSPOTNEW = async (status, count) => {
  const res = await GETAPI(
    API_URL.onspot2Get,
    "&status=" + status + "&count=" + count
  );
  return res;
};

export const DELETEONSPOTNEW = async (id) => {
  const res = await GETAPI(API_URL.onspot2Delete, "&id=" + id);
  return res;
};

export const GETONSPOTNEWDETAILS = async (id) => {
  const res = await GETAPI(API_URL.onspot2GetDetails, "&id=" + id);
  return res;
};

// SPONSER

export const ADD_UPDATE_NEW_ON_SPOT_SPONSER = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("on_spot_id", data.on_spot_id);

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.logo) {
    formData.append("logo", data.logo, "logo.png");
  }

  const res = await POSTAPI(
    data.id ? API_URL.onspot2UpdateSponser : API_URL.onspot2AddSponser,
    formData
  );

  return res;
};

export const DELETEONSPOTNEWSPONSER = async (id) => {
  const res = await GETAPI(API_URL.onspot2DeleteSponser, "&id=" + id);
  return res;
};

// GALLERY

export const DELETEONSPOTNEWGALLERY = async (id) => {
  const res = await GETAPI(API_URL.onspot2DeleteGallery, "&id=" + id);
  return res;
};

export const ADD_UPDATE_NEW_ON_SPOT_GALLERY = async (data) => {
  const formData = new FormData();
  formData.append("on_spot_id", data.on_spot_id);

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.file) {
    formData.append("file", data.file, Date.now()+"file.png");
  }

  const res = await POSTAPI(
    data.id ? API_URL.onspot2UpdateGallery : API_URL.onspot2AddGallery,
    formData
  );

  return res;
};

// FAQ

export const DELETEONSPOTNEWFAQ = async (id) => {
  const res = await GETAPI(API_URL.onspot2DeleteFaq, "&id=" + id);
  return res;
};

export const ADD_UPDATE_NEW_ON_SPOT_FAQ = async (data) => {
  const formData = new FormData();
  formData.append("answer", data.answer);
  formData.append("questions", data.questions);
  formData.append("on_spot_id", data.on_spot_id);

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.onspot2UpdateFaq : API_URL.onspot2AddFaq,
    formData
  );

  return res;
};

// CITY

export const ADD_UPDATE_NEW_ON_SPOT_CITY = async (data) => {
  const formData = new FormData();
  formData.append("city_ids", data.city_ids);
  formData.append("on_spot_id", data.on_spot_id);

  const res = await POSTAPI(API_URL.onspot2aUDCity, formData);

  return res;
};

// LOCATION
export const ADD_UPDATE_NEW_ON_SPOT_LOCATION = async (data) => {
  const formData = new FormData();
  formData.append("on_spot_city_id", data.on_spot_city_id);
  formData.append("date", data.date);
  formData.append("address", data.address);
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("from_time", data.from_time);
  formData.append("to_time", data.to_time);

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id
      ? API_URL.onspot2UpdateCityLocation
      : API_URL.onspot2AddCityLocation,
    formData
  );

  return res;
};

export const DELETEONSPOTNEWLOCATIONs = async ({ id }) => {
  const res = await GETAPI(API_URL.onspot2DeleteCityLocation, "&id=" + id);
  return res;
};

// ZONE

export const ADD_UPDATE_NEW_ON_SPOT_ZONE = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("on_spot_city_location_id", data.on_spot_city_location_id);

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id
      ? API_URL.onspot2UpdateCityLocationZone
      : API_URL.onspot2AddCityLocationZone,
    formData
  );

  return res;
};

export const DELETEONSPOTNEWZONE = async ({ id }) => {
  const res = await GETAPI(API_URL.onspot2DeleteCityLocationZone, "&id=" + id);
  return res;
};

// ROLE

export const ADD_UPDATE_NEW_ON_SPOT_ROLE = async (data) => {
  const formData = new FormData();
  formData.append("role_ids", data.role_ids);
  formData.append("on_spot_id", data.on_spot_id);

  const res = await POSTAPI(API_URL.onSpot2AUDRole, formData);

  return res;
};

// PACKAGES
export const ADD_UPDATE_NEW_ON_SPOT_PACKAGES = async (data) => {
  const formData = new FormData();
  formData.append("price", data.price);
  formData.append("qty", data.qty);
  formData.append("on_spot_roles_id", data.on_spot_roles_id);

  if (data.minutes) {
    formData.append("minutes", data.minutes);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.onSpot2UdatePackage : API_URL.onSpot2AddPackage,
    formData
  );

  return res;
};

export const DELETEONSPOTNEWROLEPACKAGE = async ({ id }) => {
  const res = await GETAPI(API_URL.onSpot2DeletePackage, "&id=" + id);
  return res;
};

// PROVIDER

export const GETONSPOTNEWPROVIDER = async (data) => {
  const res = await GETAPI(
    API_URL.onSpot2GetProviderToAdd,
    "&on_spot_city_location_id=" +
      data.on_spot_city_location_id +
      "&on_spot_roles_id=" +
      data.on_spot_roles_id +
      "&count=" +
      data.count
  );
  return res;
};

export const ADD_UPDATE_NEW_ON_SPOT_PROVIDER = async (data) => {
  const formData = new FormData();
  formData.append(
    "on_spot_city_location_zone_id",
    data.on_spot_city_location_zone_id
  );
  formData.append("on_spot_roles_id", data.on_spot_roles_id);
  formData.append("provider_user_id", data.provider_user_id);
  formData.append("earn", data.earn);

  const res = await POSTAPI(API_URL.onSpot2AddProvider, formData);

  return res;
};

export const ADD_UPDATE_NEW_ON_SPOT_PROVIDER_UPDATE = async (data) => {
  const formData = new FormData();
  formData.append(
    "on_spot_city_location_zone_provider_id",
    data.on_spot_city_location_zone_provider_id
  );
  formData.append("provider_user_id", data.provider_user_id);
  formData.append("earn", data.earn);

  const res = await POSTAPI(API_URL.onSpot2UpdateProvider, formData);

  return res;
};

export const GETONSPOTNEWPROVIDERGETS = async (data) => {
  const res = await GETAPI(
    API_URL.onSpot2GetProvider,
    "&on_spot_city_location_id=" +
      data.on_spot_city_location_id +
      "&on_spot_city_location_zone_id=" +
      data.on_spot_city_location_zone_id +
      "&on_spot_id=" +
      data.on_spot_id
  );
  return res;
};

export const DELETEONSPOTNEWPROVIDER = async ({ id }) => {
  const d = new FormData();
  d.append("on_spot_city_location_zone_provider_id", id);
  const res = await POSTAPI(API_URL.onSpot2DeleteProvider, d);
  return res;
};

// PUBLISH
export const PUBLISHONSPOTNEW = async (id) => {
  const res = await GETAPI(API_URL.onSpot2Publish, "&on_spot_id=" + id);
  return res;
};

export const UnPUBLISHONSPOTNEW = async (id) => {
  const res = await GETAPI(API_URL.onSpot2UnPublish, "&on_spot_id=" + id);
  return res;
};

// LINK

export const ADD_ON_SHOT_LINK_NEW = async (data) => {
  const formData = new FormData();

  formData.append("on_spot_id", data.on_spot_id);

  if (data.expiry) {
    formData.append("expiry", data.expiry);
  }

  if (data.on_spot_city_location_zone_provider_id) {
    formData.append("on_spot_city_location_zone_provider_id", data.on_spot_city_location_zone_provider_id);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.link) {
    formData.append("link", data.link);
  }

  if (data.on_spot_city_location_id) {
    formData.append("on_spot_city_location_id", data.on_spot_city_location_id);
  }

  if (data.on_spot_city_location_zone_provider_customer_id) {
    formData.append(
      "on_spot_city_location_zone_provider_customer_id",
      data.on_spot_city_location_zone_provider_customer_id
    );
  }

  formData.append("type", 2);

  if (data.editor) {
    formData.append("editor", data.editor);
  }

  if (data.remarks) {
    formData.append("remarks", data.remarks);
  }

  if (data.clicks) {
    formData.append("clicks", data.clicks);
  }

  if (data.clips) {
    formData.append("clips", data.clips);
  }

  const res = await POSTAPI(
    data.id ? API_URL.onSpot2UpdateLink : API_URL.onSpot2AddLink,
    formData
  );
  return res;
};

export const DELETEONSPOTNEWLINK = async ({ id }) => {
  const res = await GETAPI(API_URL.onSpot2DeleteLink, "&id=" + id);
  return res;
};

// PROVIDER_BOOKING

export const GETONSPOTPROVIDERBOOKING = async (
  type,
  on_spot_city_location_zone_provider_id,
  count
) => {
  const res = await GETAPI(
    API_URL.onSpot2ProviderBooking,
    "&type=" +
      type +
      "&on_spot_city_location_zone_provider_id=" +
      on_spot_city_location_zone_provider_id +
      "&count=" +
      count
  );
  return res;
};

export const ONSPOTNEWGETLINK = async ({
  on_spot_id,
  on_spot_city_location_zone_provider_id,
  on_spot_city_location_zone_provider_customer_id,
}) => {
  const res = await GETAPI(
    API_URL.onSpot2GetLink,
    "&on_spot_id=" +
      on_spot_id +
      "&on_spot_city_location_zone_provider_id=" +
      on_spot_city_location_zone_provider_id +
      "&on_spot_city_location_zone_provider_customer_id=" +
      on_spot_city_location_zone_provider_customer_id
  );
  return res;
};
