import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DetailField from "./Cities Details Comps/DetailField";
import DetailTab from "./Cities Details Comps/DetailTab";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import pipe_lines from "../../../Assets/Icons for pages/pipe_lines.png";
import AddCitiesModal from "./AddCitiesModal";
import SwitchRadioButton from "./Cities Details Comps/SwitchRadioButton";
import { ADD_UPDATE_CITY } from "../../../Apis/city_api";
import SwitchRadioButton2 from "./Cities Details Comps/SwitchRadioButton2";
import LoadingOverlay from "react-loading-overlay";
import { ToastShow } from "../../../Component/Toast/Toast";

function CitiesDetails({ row, chip, getData, loadOnUpdate }) {
  const [openEdit, setOpenEdit] = useState(0);

  const { id } = useParams(); //City id

  const [chips, setChips] = React.useState(chip ?? []);
  const [name, setName] = React.useState(row ? row.name : "");
  const [address, setAddress] = React.useState(row ? row.address : "");
  const [freeDistance, setFreeDistance] = React.useState(
    row ? row.free_distance : ""
  );
  const [charges, setCharges] = React.useState(row ? row.charge_per_km : "");
  const [status, setStatus] = React.useState(row ? row.status : 0);
  //console.log(row.status, "row.status")
  const [distanceCharge, setDistanceCharge] = React.useState(
    row ? row.fixed_distance_charges : ""
  );
  const [priceHike, setPriceHike] = React.useState(row ? row.price_hike : "");
  const [canEdit, setCanEdit] = React.useState(row ? row.canEditBefore : 0);
  const [sgstLabel, setSgstLabel] = React.useState(
    row ? row.sgst_label : "SGST"
  );
  const [sgst, setSgst] = React.useState(row ? row.sgst : 9);
  const [providerSM, setProviderSM] = React.useState(
    row ? row.provider_support_mobile : ""
  );
  const [latLng, setLatLng] = React.useState(
    row
      ? {
          lat: row.latitude,

          lng: row.longitude,
        }
      : {}
  );

  const [rowData, setRowData] = useState(row ? row.categories : []);

  const [bufferTime, setBufferTime] = React.useState(row ? row.bufferTime : "");
  const [orderCanAfterTime, setOrderCanAfterTime] = React.useState(
    row ? row.orderCanAfterTime : ""
  );

  const [characters, updateCharacters] = useState(rowData);
  const [search, setSearch] = useState("");

  const [selectedItem, setselectedItem] = useState(
    rowData ? rowData[0].name : ""
  );
  const [categoryServices, setCategoryServices] = useState(
    rowData ? rowData[0].services : []
  );
  const [categoryHike, setCategoryHike] = useState(
    rowData ? rowData[0].price_hike : ""
  );
  const [categoryId, setCategoryId] = useState(rowData[0].id);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // items.map((v, index) => (v.ordr = index));

    const updatedData = items.map((item, index) => ({
      ...item,
      ordr: "" + index,
    }));

    setRowData(updatedData);
    updateCharacters(updatedData);
    // applyChanges(updatedData);
  }

  const handleInputChange = (e) => {
    setSearch(e.target.value);

    const filtered = rowData.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );

    if (e.target.value === "") {
      updateCharacters(rowData);
    } else {
      updateCharacters(filtered);
    }
  };

  const applyChanges = async (d) => {
    setIsLoading(true);
    const i = d.map((v, index) => ({
      ...v,
      ordr: "" + index,
      services: v.services.map((s, i) => ({ ...s, ordr: "" + i })),
    }));
    const res = await ADD_UPDATE_CITY({
      name: name,
      status: status ? 1 : 0,
      latitude: latLng.lat,
      longitude: latLng.lng,
      address: address,
      freeDistance: "0.0",
      fixedDistanceCharges: distanceCharge ?? 0,
      priceHike: priceHike ?? 0,
      // freeDistance,
      chargePerKm: charges ?? 0,
      alternateNames: chips,
      categories: i,
      row: row,
      bufferTime: bufferTime,
      orderCanAfterTime: orderCanAfterTime,
      canEditBefore: canEdit,
      sgstLabel: sgstLabel,
      sgst: sgst,
      id: id ?? 0,
      providerSM: providerSM,
    });
    setIsLoading(false);
    if (res && res.s === 1) {
      ToastShow("Updated");
      getData();
    } else {
      console.log(res?.m ?? "Error");
    }
  };

  const updateCityStatus = async () => {
    const res = await ADD_UPDATE_CITY({
      name: name,
      status: status ? 0 : 1,
      latitude: latLng.lat,
      longitude: latLng.lng,
      address: address,
      freeDistance: "0.0",
      fixedDistanceCharges: distanceCharge ?? 0,
      priceHike: priceHike ?? 0,
      // freeDistance,
      chargePerKm: charges ?? 0,
      alternateNames: chips,
      categories: null,
      row: row,
      bufferTime: bufferTime,
      orderCanAfterTime: orderCanAfterTime,
      canEditBefore: canEdit,
      sgstLabel: sgstLabel,
      sgst: sgst,
      id: id ?? 0,
      providerSM: providerSM,
    });

    if (res && res.s === 1) {
      ToastShow("Success");
    } else {
      console.log(res?.m ?? "Opps! something went wrong. Please try again.");
    }
  };

  const handleChangeStatus = async (categoryID, status) => {
    let updatedData = rowData.map((category) => {
      if (category.id === categoryID) {
        return {
          ...category,
          status: status ? 0 : 1,
        };
      }
      return category;
    });

    setRowData(updatedData);

    // updatedData = updatedData.map((item, index) => ({
    //   ...item,
    //   ordr: "" + index,
    //   services: item.services.map((service, i) => ({
    //     ...service,
    //     ordr: "" + i,
    //   })),
    // }));

    // const res = await ADD_UPDATE_CITY({
    //   name: name,
    //   status: status ? 0 : 1,
    //   latitude: latLng.lat,
    //   longitude: latLng.lng,
    //   address: address,
    //   freeDistance: "0.0",
    //   fixedDistanceCharges: distanceCharge ?? 0,
    //   priceHike: priceHike ?? 0,
    //   // freeDistance,
    //   chargePerKm: charges ?? 0,
    //   alternateNames: chips,
    //   categories: updatedData,
    //   row: row,
    //   bufferTime: bufferTime,
    //   orderCanAfterTime: orderCanAfterTime,
    //   canEditBefore: canEdit,
    //   sgstLabel: sgstLabel,
    //   sgst: sgst,
    //   id: id ?? 0,
    //   providerSM: providerSM,
    // });
  };

  const handleClose = (isCityUpdated) => {
    if (!isCityUpdated) {
      setOpenEdit(0);
      getData(1);
    } else {
      setOpenEdit(0);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // console.log("call");
    // console.log(rowData);
    const d = rowData.filter((v) => v.id == categoryId);
    // console.log(d);
    if (d?.length) {
      // console.log("call service")
      setCategoryServices(d[0].services);
    }
  }, [rowData]);

  const [isLoading, setIsLoading] = useState(false);

  const enableAll = async () => {
    // setIsLoading(true);
    const d = rowData.map((v, index) => ({
      ...v,
      ordr: "" + index,
      status: 1,
      services: v.services.map((s, index) => ({
        ...s,
        ordr: index,
        status: 1,
      })),
    }));
    setRowData(d);
    await applyChanges(d);
    setIsLoading(false);
    window.location.reload();
  };

  if (loadOnUpdate) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <LoadingOverlay active={isLoading} spinner text="Updating...">
      <Stack gap="1rem">
        {/*1st Container*/}
        <Stack
          sx={{
            width: "100%",
            bgcolor: "white",
            borderRadius: "6px",
            padding: "0.4rem",
            boxShadow: "3px 3px 7px 0px #00000040",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              px: "0.6rem",
              py: "0.1rem",
              justifyContent: "space-between",
              bgcolor: "#FFAB30",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
            direction="row"
          >
            {/*Left side*/}
            <Stack>
              <Typography fontSize="14.1px" fontWeight="500">
                {row.name}
              </Typography>
              <Typography fontSize="10px">{row.address}</Typography>
            </Stack>
            {/*Right side*/}
            <Stack
              direction="row"
              alignItems="center"
              gap="1.5rem"
              justifyContent="space-between"
            >
              <Button
                style={{
                  height: "68%",
                  paddingLeft: "9px",
                  paddingRight: "9px",
                  borderRadius: "5px",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  color: "#484848",
                  textTransform: "none",
                }}
                onClick={() => setOpenEdit(true)}
              >
                <img
                  src={require("../../../Assets/Edit_pencil.png")}
                  style={{ objectFit: "fill", width: "11px" }}
                  alt=""
                />
                <p style={{ fontSize: "11px" }}>Edit</p>
              </Button>
              {/*active/inactive category*/}
              <SwitchRadioButton
                isChecked={status}
                rowStatus={status}
                handleChange={setStatus}
                applyChanges={updateCityStatus}
              />
              <Button
                style={{
                  height: "68%",
                  paddingLeft: "9px",
                  paddingRight: "9px",
                  borderRadius: "5px",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  color: "#484848",
                  textTransform: "none",
                }}
                onClick={() => enableAll()}
              >
                <p style={{ fontSize: "11px" }}>Enable All</p>
              </Button>

              <Button
                style={{
                  height: "68%",
                  paddingLeft: "9px",
                  paddingRight: "9px",
                  borderRadius: "5px",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  color: "#484848",
                  textTransform: "none",
                  
                }}
                onClick={async () => {
                  await applyChanges(rowData);
                  window.location.reload();
                }}
              >
                <p style={{ fontSize: "11px" }}>Update</p>
              </Button>
            </Stack>
          </Stack>

          <Box
            sx={{
              border: "1px solid #EFEFEF",
              pb: "0.5rem",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <Stack direction="row">
              <DetailField
                label="Charge per km"
                value={row.charge_per_km}
                key={0}
              />
              <DetailField label="Buffer time" value={row.bufferTime} key={1} />
              <DetailField label="SGST Lable" value={row.sgst_label} key={2} />
            </Stack>
            <Stack direction="row">
              <DetailField
                label="Fix distance charge"
                value={row.fixed_distance_charges}
                key={3}
              />
              <DetailField
                label="Hike price %"
                value={row.price_hike}
                key={4}
              />
              <DetailField label="Capital SGST" value={row.sgst} key={5} />
            </Stack>
            <Stack direction="row">
              <DetailField
                label="Order can after time"
                value={row.orderCanAfterTime}
                key={6}
              />
              <DetailField
                label="Can edit before ?"
                value={row.canEditBefore}
                key={7}
              />
              <DetailField
                label="Synonyms Name"
                value={chip[0]}
                values={chip}
                isArr={true}
                key={8}
              />
            </Stack>

            <Stack direction="row">
              <DetailField label="Capital CGST" value={row.cgst} key={6} />
            </Stack>
          </Box>
        </Stack>

        {/*2nd Container*/}
        <Stack direction="row" width="100%" gap="1rem">
          {/*Tabs links*/}
          <Box
            sx={{
              width: "25%",
              boxShadow: "3px 3px 7px 0px rgba(0, 0, 0, 0.25)",
              backgroundColor: "white",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                bgcolor: "#F8AF41",
                py: "0.7rem",
                pl: "0.6rem",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
              }}
            >
              <Typography fontWeight="500">Categories</Typography>
            </Box>

            {/*Search*/}
            <Stack height="3.2rem" alignItems="center" justifyContent="center">
              <TextField
                value={search}
                onChange={handleInputChange}
                placeholder="Search"
                style={{
                  height: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alingItems: "center",
                }}
                sx={{
                  width: "17rem",
                  backgroundColor: "#EFEFEF",
                  borderRadius: "5px",
                  "& fieldset": { border: "none" },
                  "& .MuiFormLabel-root": {
                    color: "#969696",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .css-j43tob-MuiInputBase-root-MuiOutlinedInput-root": {
                    fontSize: "14px",
                  },
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0",
                    paddingLeft: ".5rem",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        style={{
                          width: "12.6px",
                          objectFit: "fill",
                        }}
                        src={require("../../../Assets/Search_icon.png")}
                        alt=""
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            {/*Draggable*/}
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <Stack
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    gap="0.5rem"
                  >
                    {characters
                      .slice()
                      .sort((a, b) => a.ordr - b.ordr)
                      .map(
                        ({ id, name, services, price_hike, status }, index) => {
                          return (
                            <Draggable
                              key={name}
                              draggableId={name}
                              index={index}
                            >
                              {(provided) => (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  py="0.7rem"
                                  bgcolor={
                                    selectedItem === name &&
                                    "rgba(244, 146, 0, 0.15)"
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClick={() => {
                                    setselectedItem(name);
                                    setCategoryServices(services);
                                    setCategoryHike(price_hike);
                                    setCategoryId(id);
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pl: "1rem",
                                      pr: "0.8rem",
                                    }}
                                  >
                                    <img
                                      src={pipe_lines}
                                      style={{ userSelect: "none" }}
                                    />
                                  </Box>
                                  <Stack
                                    direction="row"
                                    width="100%"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    pr="1rem"
                                    sx={{ cursor: "default" }}
                                  >
                                    <Typography
                                      sx={{
                                        userSelect: "none",
                                        fontSize: "14px",
                                        color:
                                          selectedItem === name && "#F49200",
                                      }}
                                    >
                                      {name}
                                    </Typography>

                                    <SwitchRadioButton2
                                      value={status}
                                      onChange={handleChangeStatus}
                                      id={id}
                                      key={id}
                                      name={name}
                                    />
                                  </Stack>
                                </Box>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          </Box>

          {/*Different link*/}
          <Stack
            sx={{
              width: "75%",
              bgcolor: "white",
              borderRadius: "6px",
              boxShadow: "3px 3px 7px 0px #00000040",
            }}
          >
            <DetailTab
              key={selectedItem}
              title={selectedItem}
              services={categoryServices}
              hike={categoryHike}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              rowData={rowData}
              setRowData={setRowData}
              applyChanges={applyChanges}
            />
          </Stack>
        </Stack>
      </Stack>

      {/*Edit City Modal*/}
      {openEdit ? (
        <AddCitiesModal
          open={openEdit}
          handleClose={handleClose}
          id={id}
          row={row}
          chip={chip}
          getData={getData}
          statusV={status}
          s={status}
        />
      ) : null}
    </LoadingOverlay>
  );
}

export default CitiesDetails;
