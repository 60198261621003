import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import {
  cancelOrder,
  getForAdmin,
  resudleOrderOrder,
} from "../../../Apis/order_api";
import SplitButton from "../../../Component/Button/SplitButton";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { Link, useNavigate } from "react-router-dom";
import { getColor, getStatus } from "../../../Service/OrderService";
import OrderMenuButton from "../../../Component/GroupButton/OrderMenuButton";
import { DateRangePicker, CustomProvider } from "rsuite";
import { useTheme } from "@mui/material";
import { addDays, subDays, lastDayOfMonth, format } from "date-fns";
import { ToastShow } from "../../../Component/Toast/Toast";
import { Message, Search } from "@mui/icons-material";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import OrderChatPopAdmin from "../../../Component/PopOver/OrderAdminChat";
import { rightsUser } from "../../Home/Drawer/DrawerView";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import { BsChevronDown } from "react-icons/bs";
import multiselection_city from "../../../Assets/multiselection_city.png";
import paymentIcon from "../../../Assets/paymentIcon.png";
import OrderEditDateTime from "../../../Component/Dailog/OrderEditDateTime";
import SearchBox from "../../../Component/SearchBox/SearchBox";
import "./Order.css";
import OrderChat from "./New/OrderChat/OrderChat";

const paidStatus = ["All", "Paid", "Unpaid"];
const orderOptions = [
  "All",
  "Received/New Order",
  "PODIAN Interested",
  "Confirmed",
  "Order Started",
  "Finished",
  "RAW Received",
  "Delivery Successful",
  "Canceled",
];
const citiesOptions = [
  "Ahemdabad",
  "Mumbai",
  "Bangalore",
  "Jaipur",
  "Rajkot",
  "Delhi",
  "Baroda",
];

function OrderList({ status }) {
  // console.log(status)
  const navigator = useNavigate();
  const [paidS, setPaidS] = useState(["All"]);
  const [serviceType, setServiceType] = useState(["Normal"]);
  const serviceTypes = ["Normal", "Regenerate link"];
  const [orderS, setOrderS] = useState(["All"]);
  const [citieS, setCitieS] = useState(["All"]);
  const theme = useTheme();
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [dateRange, setDateRange] = useState({});
  const [search, setSearch] = useState("");
  const [cc, setCc] = useState(0);

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (!e.target.value) {
      getData(
        "",
        dateRange,
        0,
        getpaymentStatus(paidS),
        getServiceType(serviceType)
      );
    }
  };

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setPaidS([value]);
    getData(
      search,
      dateRange,
      0,
      getpaymentStatus(value),
      getServiceType(serviceType)
    );
  };

  const handleServiceType = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setServiceType([value]);
    getData(
      search,
      dateRange,
      0,
      getpaymentStatus(paidS),
      getServiceType(value)
    );
  };

  const handleOrders = (e) => {
    const { value } = e.target;
    setOrderS([value]);
  };
  const handleCities = (e) => {
    const { value } = e.target;
    setCitieS([value]);
  };

  const getpaymentStatus = (v) => {
    if (v == "All") {
      return 0;
    } else if (v == "Paid") {
      return 1;
    } else {
      return 2;
    }
  };
  const getServiceType = (v) => {
    if (v == "Normal") {
      return 0;
    } else if (v == "Regenerate link") {
      return 1;
    } else {
      return 0;
    }
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "Sr.",
    //   filterable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     // console.log(params);
    //     return params.api.getRowIndex(params.row.id) + 1;
    //   },
    // },
    {
      field: "ids",
      headerName: "Order ID",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "12.8px" }}>{params.row.id}</Typography>
        );
      },
    },
    {
      field: "someone_name",
      headerName: "Name/No.",
      width: 160,
      minWidth: 160,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: ({
        row: {
          someone_name,
          someone_mobile,
          is_for_someone,
          first_name,
          last_name,
          mobile,
        },
      }) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F6F6F6"
            style={{ margin: "8px 0" }}
          >
            <Typography fontSize="12px" fontWeight="500" textAlign="center">
              {is_for_someone
                ? `${someone_name ?? first_name + " " + last_name} `
                : `${first_name + " " + last_name}`}
            </Typography>
            <Typography mx=".rem" fontSize="11px">
              {is_for_someone ? someone_mobile ?? mobile : mobile}
            </Typography>
          </Box>
        );
      },
      sortComparator: (v1, v2) => v1?.name.localeCompare(v2?.name),
      filterRenderedCellData: true,
    },
    {
      field: "created_at",
      headerName: "Booking Date",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "date_time",
      headerName: "Shoot Date",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} isUtc={false} />;
      },
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography
          style={{
            textAlign: "start",
            whiteSpace: "normal",
            fontSize: "12px",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    // {
    //   field: "hours",
    //   headerName: "Hrs",
    //   width: 90,
    //   type: "number",
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "address",
      headerName: "Address",
      width: 230,
      minWidth: 230,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Typography style={{ fontSize: "12px", padding: "5px 0" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "post_services",
      headerName: "Others",
      width: 230,
      minWidth: 230,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography style={{ fontSize: "12px", padding: "5px 0" }}>
          {params?.value?.length
            ? params?.value?.map((v) => v.name).join(",")
            : "-"}
        </Typography>
      ),
    },
    // {
    //   field: "payment",
    //   headerName: "Payment",
    //   width: 100,
    //   minWidth: 100,
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => (
    //     <Typography
    //       style={{
    //         textAlign: "start",
    //         whiteSpace: "normal",
    //         fontWeight: "500",
    //         fontSize: "12.3px",
    //       }}
    //       color={
    //         params.value == 1 ? "rgba(13, 168, 0, 1)" : "rgba(229, 0, 0, 1)"
    //       }
    //     >
    //       {params.value == 1 ? "PAID" : "UNPAID"}
    //     </Typography>
    //   ),
    // },
    {
      field: "status",
      headerName: "Order Status",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            borderRadius: "3px",
            backgroundColor: `${getColor(params.value)}`,
            width: "5.5rem",
            height: "1.6rem",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "11.7px",
              fontWeight: "500",
              color: "white",
            }}
          >
            {getStatus(params.value)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "user_id",
      headerName: "Chat",
      width: 90,
      minWidth: 90,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            bgcolor: "#F6F6F6",
            padding: "7px",
          }}
        >
          <IconButton
            anchorEl={anchorElMsg}
            size="small"
            onClick={(e) => {
              handleClickPopMsg(e, params.value, params.row.id);
            }}
            style={{ border: 0, padding: 0 }}
          >
            <img
              src={require("../../../Assets/Chat.png")}
              width="16px"
              height="16px"
              alt=""
            />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 90,
      minWidth: 90,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          isView={rightsUser.includes(4) ? 1 : null}
          dataId={params.id}
          isEdit={rightsUser.includes(2) ? 1 : null}
          onEdit={onEdit}
          onView={viewData}
          onDelete={() => ""}
          // onDelete={deleteData}
          // deleteText="Delete"
          deleteText={"Delete"}
          isDelete={false}
        />
      ),
    },
  ];

  const [anchorElMsg, setAnchorElMsg] = React.useState();

  const handleClickPopMsg = (event, id, oIds) => {
    uId.current = id;
    oId.current = oIds;
    setAnchorElMsg(event.currentTarget);
  };

  const openPopMsg = Boolean(anchorElMsg);
  const idPopMsg = openPopMsg ? "simple-popover-msg" : undefined;

  const viewData = (v) => {
    const route = getRoute(status);
    var t = rows.filter((e) => e.id === v);
    window.open(`/order/${route}/` + t[0].id);
    // navigator(`/order/${route}/` + t[0].id);
  };

  const [openRs, setOpenRs] = useState(false);
  const [rsData, setRsData] = useState(null);

  const handleCloseRs = async (v, d) => {
    // console.log(v, "v");
    setOpenRs(false);
    if (v) {
      const res = await resudleOrderOrder({
        date_time: d,
        orders_id: rsData.id,
      });
      if (res && res.s) {
        ToastShow(res.m);
        await getData(
          search,
          dateRange,
          0,
          getpaymentStatus(paidS),
          getServiceType(serviceType)
        );
      } else {
        ToastShow(res.m);
      }
    }
  };

  const onEdit = (v) => {
    const route = getRoute(status);
    var t = rows.filter((e) => e.id === v);

    // navigator(`/order/${route}/` + t[0].id + "/edit");
    // console.log("amount==>",t[0].paid_amount)
    if (t[0].paid_amount > 0) {
      setRsData(t[0]);
      setOpenRs(true);
    } else {
      setRsData(null);
      window.open(`/order/${route}/` + t[0].id + "/edit");
    }
  };

  function getRoute(s) {
    if (s == 0) {
      return "all";
    } else if (s == 1) {
      return "confirmed";
    } else if (s == 2) {
      return "applied";
    } else if (s == 3) {
      return "approved";
    } else if (s == 4) {
      return "started";
    } else if (s == 5) {
      return "finished";
    } else if (s == 6) {
      return "submitted";
    } else if (s == 7) {
      return "delivered";
    } else if (s == -1) {
      return "canceled";
    } else {
      return "all";
    }
  }

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmID, setOpenConfirmID] = useState(0);

  const handleClose = async (v) => {
    setOpenConfirm(false);

    if (v) {
      const data = await cancelOrder(openConfirmID);

      if (data && data.s) {
        ToastShow("Deleted successfully");
        const t = rows.filter((j) => j.id != openConfirmID);
        setRows([...t]);
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
      }
    }
    setOpenConfirmID(0);
  };

  const deleteData = async (v) => {
    setOpenConfirmID(v);
    setOpenConfirm(true);
  };

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  const getData = async (s, d, c, p = 0, serviceType) => {
    if (!c) {
      setIsCall(true);
    }

    setIsLoading(true);

    const data = await getForAdmin(c, status, d, s, p, serviceType);

    if (!c) {
      if (data?.r) {
        setRows([...(data.r ?? [])]);
      } else {
        setRows([]);
      }
    } else {
      if (data?.r) {
        setRows([...rows, ...(data.r ?? [])]);
      }
    }

    if (data && data.s) {
      setCc(data?.c ?? 0);
    } else {
      setCc(0);
    }

    setIsLoading(false);
    if ((data?.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
      getData(
        search,
        d,
        0,
        getpaymentStatus(paidS),
        getServiceType(serviceType)
      );
    } else {
      setDateRange(v);
      getData(
        search,
        v,
        0,
        getpaymentStatus(paidS),
        getServiceType(serviceType)
      );
    }
  };

  useEffect(() => {
    // const d = [];
    // setRows(p=>[...d]);
    getData(
      search,
      dateRange,
      0,
      getpaymentStatus(paidS),
      getServiceType(serviceType)
    );
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(
        search,
        dateRange,
        rows.length,
        getpaymentStatus(paidS),
        getServiceType(serviceType)
      );
    }
  };

  const handleClosePopMsg = () => {
    uId.current = 0;
    oId.current = 0;
    setAnchorElMsg(null);
  };

  const uId = useRef(0);
  const oId = useRef(0);

  const handleClick = () => {
    if (search) {
      getData(
        search,
        dateRange,
        0,
        getpaymentStatus(paidS),
        getServiceType(serviceType)
      );
    } else {
      ToastShow("Please fill some value in the search");
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <SearchBox
              search={search}
              onChange={handleSearch}
              onClick={handleClick}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: ".9rem",
            }}
          >
            {/*Select City*/}
            {/* <MultiSelection
              selected={citieS}
              handleChange={handleCities}
              names={citiesOptions}
              lable={"City"}
              multiple={false}
              icon={multiselection_city}
            /> */}
            {/*Payment*/}
            <MultiSelection
              selected={paidS}
              handleChange={handleStatus}
              names={paidStatus}
              lable={"Payment"}
              multiple={false}
              icon={paymentIcon}
            />
            <MultiSelection
              selected={serviceType}
              handleChange={handleServiceType}
              names={serviceTypes}
              lable={"Service Type"}
              multiple={false}
              icon={paymentIcon}
            />
            {/*OrderMenu*/}
            <OrderMenuButton sx={{}} />
            {/* <MultiSelection
              selected={orderS}
              handleChange={handleOrders}
              names={orderOptions}
              lable={"Order Status"}
              multiple={false}
            /> */}
            {/*Calendar*/}
            <Box
              sx={{
                bgcolor: "white",
                boxShadow: "3px 3px 7px 0px #00000040",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingY: ".245rem",
                gap: ".1rem",
              }}
            >
              <Box width="95%">
                <Box
                  sx={{
                    display: "flex",
                    gap: ".3rem",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: "0",
                      padding: "0",
                    }}
                  >
                    <img
                      src={require("../../../Assets/calendar-icon.png")}
                      style={{ objectFit: "fill", width: "11px" }}
                      alt=""
                    />
                  </IconButton>
                  <Typography fontSize="10px">Date Select</Typography>
                </Box>
              </Box>

              <Box width="95%">
                <CustomProvider theme={theme.palette.mode}>
                  <DateRangePicker
                    className="date"
                    placeholder="dd/mm/yyyy ~ dd/mm/yyyy"
                    appearance="subtle"
                    placement="bottomEnd"
                    caretAs="a"
                    style={{
                      backgroundColor: "#F6F6F6",
                      width: "11rem",
                      height: "1.7rem",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    size="sm"
                    onChange={(v) => handleSelectDate(v ?? {})}
                    ranges={[
                      {
                        label: "Yesterday",
                        value: [
                          addDays(new Date(), -1),
                          addDays(new Date(), -1),
                        ],
                      },
                      {
                        label: "Today",
                        value: [new Date(), new Date()],
                      },
                      {
                        label: "Tomorrow",
                        value: [addDays(new Date(), 1), addDays(new Date(), 1)],
                      },
                      {
                        label: "Last 7 days",
                        value: [subDays(new Date(), 6), new Date()],
                      },
                      {
                        label: "This week",
                        value: [
                          subDays(
                            new Date(
                              `${firstdayW.getFullYear()}-${
                                firstdayW.getMonth() + 1
                              }-${firstdayW.getDate()}`
                            ),
                            0
                          ),
                          addDays(
                            new Date(
                              new Date(
                                `${lastdayW.getFullYear()}-${
                                  lastdayW.getMonth() + 1
                                }-${lastdayW.getDate()}`
                              )
                            ),
                            0
                          ),
                        ],
                      },
                      {
                        label: "This month",
                        value: [
                          subDays(
                            new Date(
                              `${today.getFullYear()}-${
                                today.getMonth() + 1
                              }-01`
                            ),
                            0
                          ),
                          addDays(
                            new Date(
                              new Date(
                                `${today.getFullYear()}-${
                                  today.getMonth() + 1
                                }-${lastDayOfMonth(today).getDate()}`
                              )
                            ),
                            0
                          ),
                        ],
                      },
                      {
                        label: "This year",
                        value: [
                          subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                          addDays(
                            new Date(new Date(`${today.getFullYear()}-12-31`)),
                            0
                          ),
                        ],
                      },
                    ]}
                  />
                </CustomProvider>
              </Box>
            </Box>

            {rightsUser.includes(1) ? (
              <Link to="/create-order">
                <Button
                  variant="contained"
                  startIcon={
                    <img
                      src={require("../.././../Assets/add-button.png")}
                      style={{ objectFit: "fill", width: "14px" }}
                    />
                  }
                  sx={{
                    height: "2.6rem",
                    textTransform: "none",
                    boxShadow: "3px 3px 7px 0px #00000040",
                    borderRadius: "5px",
                    flex: 1,
                  }}
                >
                  <Typography sx={{ fontSize: "17.3px", fontWeight: "500" }}>
                    Create Order
                  </Typography>
                </Button>
              </Link>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            boxShadow: "3px 3px 7px 0px #00000040",
            paddingBottom: ".2rem",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
          }}
        >
          <DataGrideView
            rows={rows}
            columns={columns}
            callBack={callBack}
            isLoading={isLoading}
            colHeight="false"
            sx={{ height: "78vh" }}
            cc={cc}
          />
        </Box>
      </Box>

      {openPopMsg && (
        // OLD CODE
        <OrderChatPopAdmin
          id={idPopMsg}
          anchorEl={anchorElMsg}
          handleClose={handleClosePopMsg}
          open={openPopMsg}
          oId={oId.current}
          pId={0}
          uId={uId.current}
        />

        // // NEW
        // <OrderChat
        //   id={idPopMsg}
        //   anchorEl={anchorElMsg}
        //   handleClose={handleClosePopMsg}
        //   open={openPopMsg}
        // />
      )}

      {openConfirm && (
        <ConfirmationDailog
          handleOnClose={handleClose}
          open={openConfirm}
          content="Are you sure, You want to delete this order?"
          title="Delete Order!"
        />
      )}

      {openRs && (
        <OrderEditDateTime
          handleOnClose={handleCloseRs}
          open={openRs}
          data={rsData}
        />
      )}
    </>
  );
}

export default OrderList;
