import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonView from "../Button/ButtonView";

function ConfirmationDailog({
  open,
  handleOnClose,
  title = "Delete Story?",
  content = "Are you sure, You want to delete this banner.",
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(3px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        <Typography sx={{ mx: 1.8, mt: 1.3, fontSize: "20px" }}>
          {title}
        </Typography>
        <DialogContentText sx={{ width: "350px", px: 1.8, mb: 0.8, mt: 0.5 }}>
          {content}
        </DialogContentText>
        <DialogActions>
          <ButtonView
            lable="Cancel"
            color="#E50000"
            bgcolor="#E5000033"
            fontSize="14px"
            sx={{ width: "100%" }}
            hoverBg="pink"
            onClick={() => handleOnClose(0)}
          />

          <ButtonView
            lable="Yes"
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={() => handleOnClose(1)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmationDailog;
