import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import chartData from "../../../Service/ChartData";
import { ADD_UPDATE_FCM } from "../../../Apis/fcm_token.api";
import { getTokens, onMessageListener } from "../../../Service/FirebaseInit";
import { GETORDERSTATICS } from "../../../Apis/statics_apis";
import { DateRangePicker, CustomProvider } from "rsuite";
import { addDays, subDays, lastDayOfMonth, format } from "date-fns";
import { useTheme } from "@mui/material";
import { GET_CITIE } from "../../../Apis/city_api";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { API_URL } from "../../../Apis/config";
function Dashboard() {
  const [data, setData] = useState({ data: [], categories: [] });
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [selectedDate, setSelectedDate] = useState([Date.now(), Date.now()]);
  const [dateRange, setDateRange] = useState({});
  const [citiesW, setCitiesW] = React.useState([]);
  const [cityId, setSelectedId] = React.useState(0);
  const [cities, setCities] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState(["None"]);
  const theme = useTheme();
  const chatTiming = ["None", "Daily", "Weekly", "Monthly", "Yearly"];
  const [dataForValue, setDataFor] = useState(["None"]);
  const chartApis = [
    "Orders",
    "Clicks",
    "Clips",
    "Order Amount",
    "Customer",
    "Providers",
  ];
  const [chartApi, setChartApi] = useState(["Orders"]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStaticsData();
    getCities();
    getData();
    onMessageListener();
  }, []);

  useEffect(() => {
    getStaticsData();
  }, [dateRange, cityId, dataForValue, chartApi]);

  const getStaticsData = async () => {
    setIsLoading(true);
    const res = await GETORDERSTATICS({
      dateRange,
      city_id: cityId,
      group_by: getChartStatus(dataForValue),
      api: getAPIStatus(chartApi),
    });

    if (res && res.s && res.r) {
      const data = [];
      const categories = [];
      for (const v of res.r) {
        if (v.date != null && v.v != null) {
          categories.push(v.date);
          data.push(v.v);
        }
      }

      setData({ data: data, categories: categories });
    } else {
      setData({ data: [], categories: [] });
    }
    setIsLoading(false);
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      c.push("None");
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
    }
  }

  const getData = async () => {
    const s = await getTokens();

    if (s) {
      await ADD_UPDATE_FCM(s);
    }
  };

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      setSelectedDate([v[0], v[1]]);
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
      //getData(search, d, 0);
    } else {
      setDateRange(v);
      //getData(search, v, 0);
    }
  };

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    let c = [];
    if (value != "None") {
      c = citiesW.filter((v) => v.name == value);
      setSelectedId(c.length ? c[0].id : 0);
    } else {
      setSelectedId(0);
    }
  };

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    setDataFor([value]);
  };

  const handleApiStatus = (e) => {
    const {
      target: { value },
    } = e;

    setChartApi([value]);
  };

  const getChartStatus = (v) => {
    if (v == "None") {
      return 0;
    } else if (v == "Daily") {
      return 1;
    } else if (v == "Weekly") {
      return 2;
    } else if (v == "Monthly") {
      return 3;
    } else {
      return 4;
    }
  };

  const getAPIStatus = (v) => {
    if (v == "Orders") {
      return API_URL.staticsOrders;
    } else if (v == "Clicks") {
      return API_URL.staticsClick;
    } else if (v == "Clips") {
      return API_URL.staticsclips;
    } else if (v == "Order Amount") {
      return API_URL.staticsorderAmount;
    } else if (v == "Customer") {
      return API_URL.staticscustomer;
    } else if (v == "Providers") {
      return API_URL.staticsproviders;
    } else {
      return API_URL.staticsOrders;
    }
  };

  return isLoading ? (
    <Box
      sx={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: 5,
        height: "90vh",
        overflowY: "scroll",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomProvider theme={theme.palette.mode}>
          <DateRangePicker
            placement="bottomEnd"
            value={selectedDate}
            size="lg"
            onChange={(v) => handleSelectDate(v ?? {})}
            onClean={() => setSelectedDate([Date.now(), Date.now()])}
            ranges={[
              {
                label: "Yesterday",
                value: [addDays(new Date(), -1), addDays(new Date(), -1)],
              },
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Tomorrow",
                value: [addDays(new Date(), 1), addDays(new Date(), 1)],
              },
              {
                label: "Last 7 days",
                value: [subDays(new Date(), 6), new Date()],
              },
              {
                label: "This week",
                value: [
                  subDays(
                    new Date(
                      `${firstdayW.getFullYear()}-${
                        firstdayW.getMonth() + 1
                      }-${firstdayW.getDate()}`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${lastdayW.getFullYear()}-${
                          lastdayW.getMonth() + 1
                        }-${lastdayW.getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This month",
                value: [
                  subDays(
                    new Date(
                      `${today.getFullYear()}-${today.getMonth() + 1}-01`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${today.getFullYear()}-${
                          today.getMonth() + 1
                        }-${lastDayOfMonth(today).getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This year",
                value: [
                  subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                  addDays(
                    new Date(new Date(`${today.getFullYear()}-12-31`)),
                    0
                  ),
                ],
              },
            ]}
          />
        </CustomProvider>

        <Box display="flex" alignItems="center" gap=".7rem">
          {chartApi != "Customer" ? (
            <MultiSelection
              multiple={false}
              names={cities}
              handleChange={handleSelectCity}
              selected={selectedCities}
              lable="Cities"
            />
          ) : null}

          <MultiSelection
            selected={dataForValue}
            handleChange={handleStatus}
            names={chatTiming}
            lable={"Data For"}
            multiple={false}
          />

          <MultiSelection
            selected={chartApi}
            handleChange={handleApiStatus}
            names={chartApis}
            lable={"Chart of"}
            multiple={false}
          />
        </Box>
      </Box>
      <Box sx={{ p: 5, flex: 1 }}>
        <Chart
          options={{
            ...chartData(data.categories, data.data).options,
            plotOptions: {
              bar: {
                dataLabels: {
                  orientation: "vertical",
                },
              },
            },
          }}
          series={chartData(data.categories, data.data).series}
          type="bar"
          height={480}
        />
      </Box>
    </Box>
  );
}

export default Dashboard;

function DataView() {
  return (
    <Paper
      elevation={0}
      sx={{
        m: 1,
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        mx: 5,
      }}
    >
      <Typography variant="h5" sx={{ mb: 1 }}>
        Total Units
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <CardView title="Total Category" unites="250" />
        <CardView title="Total Booking Inquiry" unites="250" />
        <CardView title="Total SubAdmin" unites="250" />
        <CardView title="Total Customer" unites="250" />
        <CardView title="Total Photographer" unites="250" />
        <CardView title="Total Booking" unites="250" />
        <CardView title="Total Online Booking" unites="250" />
        <CardView title="Total Paid" unites="250" />
        <CardView title="Total UnPaid" unites="250" />
        <CardView title="Total Refunded" unites="250" />
      </Box>
    </Paper>
  );
}

function CardView({ title, unites }) {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        border: "dotted",
        borderRadius: "10px",
        borderColor: "divider",
        borderWidth: "2px",
        p: 1,
        py: 1.3,
        m: 1,
        display: "flex",
        flexDirection: "Column",
      }}
    >
      <Typography variant="subtitle1" sx={{ color: "primary.main" }}>
        {title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            mr: 0.4,
            backgroundColor: "primary.main",
          }}
        />
        <Typography variant="body2">{unites} Units</Typography>
      </Box>
    </Box>
  );
}
