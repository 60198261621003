import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Popper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { Stack } from "@mui/system";
import React from "react";
import { UserContext } from "../../Context/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BiChevronDown } from "react-icons/bi";
import { BookOnline } from "@mui/icons-material";
import { useEffect } from "react";
import { GET_UNREAD_COUNT } from "../../Apis/unreadData";

function MainView({ children, title, changeMode, drawerValue }) {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        gap: "1rem",
      }}
    >
      {/* APPBAR */}
      <Box>
        <AppBar title={title} changeMode={changeMode} />
      </Box>

      {/* CHILDREN */}
      {children.type.name !== "CreateOrder" &&
      children.type.name !== "UsersList" ? (
        <Box
          sx={{
            paddingX: "1rem",
            overflow: "auto",
            paddingBottom: "1rem",
          }}
        >
          {children}
        </Box>
      ) : (
        <Box sx={{ overflow: "auto" }}>{children}</Box>
      )}
    </Box>
  );
}

export default MainView;

function AppBar({ title, changeMode }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const [anchorElU, setAnchorElU] = React.useState(null);

  const handlePopoverOpenU = (event) => {
    setAnchorElU(event.currentTarget);
  };

  const handlePopoverCloseU = () => {
    setAnchorElU(null);
  };

  const openU = Boolean(anchorElU);

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [state, setState] = React.useState(false);
  const [data, setData] = React.useState([]);

  const toggleDrawer = () => {
    setState((p) => !p);
  };

  const theme = useTheme();
  const user = UserContext();
  const navigator = useNavigate();

  const location = useLocation();
  const param = useParams();

  const handleLogout = () => {
    try {
      user.logout({ s: 0, m: "", r: null });
    } catch (e) {}

    navigator("/", { replace: true });
  };

  const getUnreadData = async () => {
    const d = await GET_UNREAD_COUNT();
    if (d && d.s) {
      setData(d?.r ?? []);
    }
  };

  useEffect(() => {
    try {
      getUnreadData();
    } catch (e) {}
  }, []);

  console.log("Location", location.pathname);
  console.log("Param", param);

  return (
    <Paper
      elevation={0}
      sx={{
        height: "4rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "0px",
        width: "100%",
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {!param.id ? (
          <IconButton color="inherit" onClick={() => navigator(-1)}>
            <img src={require("../../Assets/arrow_back.png")} alt="" />
          </IconButton>
        ) : null}

        <Typography fontSize="1.1rem" fontWeight="500">
          {title}
        </Typography>
      </Box>
      {/* PROFILE VIEW */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={openU}
          anchorEl={anchorElU}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverCloseU}
          disableRestoreFocus
        >
          <Box>
            {data.map((v) => (
              <Box
                key={v.id}
                sx={{
                  p: 1,
                  borderBottom: "solid 1px #c0c0c0",
                  display:"flex",
                  gap:"3px"
                }}
              >
                <Typography>{v.name}</Typography>
                <Typography>:</Typography>
                <Typography fontWeight="bold" color="red">{v.count}</Typography>
              </Box>
            ))}
          </Box>
        </Popover>
        <Box
          onMouseEnter={handlePopoverOpenU}
          onMouseLeave={handlePopoverCloseU}
          sx={{
           
            alignSelf: "flex-end",
            px:"5px",
            backgroundColor: "#f88181",
            borderRadius: "25px",
            textAlign: "center",
            mb:0.5
          }}
        >
          <Typography sx={{ color: "#fff" }}>Un-Read</Typography>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: ".4rem",
            marginRight: ".9rem",
          }}
        >
          <Avatar
            sx={{
              height: "28px",
              width: "28px",
            }}
          />

          <Typography
            component="span"
            sx={{ fontSize: ".850rem", color: "#5C6173" }}
          >
            {(user?.u?.first_name ?? "") +
              " " +
              (user?.u?.middle_name ?? "") +
              " " +
              (user?.u?.last_name ?? "")}
            {/*IT IS STATIC FOR NOW*/}
          </Typography>

          {/* <IconButton color="inherit"> */}
          <BiChevronDown
            onClick={handleClick}
            color="gray"
            aria-describedby={id}
            type="button"
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            cursor="pointer"
            fontSize="1.1rem"
          />
          {/* </IconButton> */}

          <Popper
            sx={{ zIndex: 1000000 }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
          >
            {({ TransitionProps }) => (
              <Fade
                {...TransitionProps}
                timeout={350}
                sx={{
                  zIndex: 1000000,
                  marginRight: "1rem",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box sx={{ p: 2 }}>
                      <Stack>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h6">Hi,</Typography>
                          <Typography
                            component="span"
                            variant="h6"
                            sx={{ fontWeight: 400 }}
                          >
                            Admin {/*IT IS STATIC FOR NOW*/}
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle2">
                          Take your action
                        </Typography>
                      </Stack>
                      <Divider sx={{ my: 1 }} />
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        // selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <LogoutIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Logout</Typography>
                          }
                        />
                      </ListItemButton>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>

        <IconButton color="inherit" onClick={() => navigator("/notifications")}>
          <img
            src={require("../../Assets/notification.png")}
            width="19px"
            height="19px"
            alt="someicon"
          />
        </IconButton>

        {/* <Tooltip title="Tickets">
          <IconButton
            color="inherit"
            onClick={() => navigator("/tickets")}
          >
            <BookOnline color="primary" />
          </IconButton>
        </Tooltip> */}
      </Box>
    </Paper>
  );
}
