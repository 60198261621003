import { Avatar, TextField, Typography, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../Apis/config";
import { GETPROVIDERS } from "../../../Apis/service_api";
import { GET_USER_LIST } from "../../../Apis/users_api";
import SplitButton from "../../../Component/Button/SplitButton";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import BOD from "../../../Component/Date/BOD";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import SearchBox from "../../../Component/SearchBox/SearchBox";

const status = ["All", "Active", "Disabled", "Deleted"];

function UsersList() {
  const navigator = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "start",
      align: "center",
      width: 70,
      minWidth: 70,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "photo",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 170,
      minWidth: 170,
      renderCell: (params) => (
        <Avatar
          sx={{ width: "1.9rem", height: "1.9rem", objectFit: "fill" }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 220,
      minWidth: 220,
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="13.4px">
          {params.row.first_name + " " + params.row.last_name}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
      minWidth: 280,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="13.4px">{params.row.email}</Typography>
      ),
    },
    {
      field: "mobile",
      headerName: "Phone",
      width: 180,
      minWidth: 180,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="13.2px">{params.row.mobile}</Typography>
      ),
    },
    {
      field: "dob",
      headerName: "DOB",
      width: 180,
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <BOD date={params.value} fontSx={{ fontSize: "13.1px" }} />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      minWidth: 80,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <SplitButton
          key={params.id}
          isEdit={null}
          isDelete={null}
          isView={1}
          onView={() =>
            //navigator("/users/" + params.row.id)
            window.open("/users/" + params.row.id)
          }
          title={params.value}
        />
      ),
    },
  ];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [statusValue, setStatus] = useState(["All"]);

  const getData = async ({ statusV = "All", c = 0, search = "" }) => {
    setIsLoading(true);

    const data = await GET_USER_LIST({
      count: c ? 0 : rows.length,
      status: getStatusByValue(statusV),
      search: search,
    });

    if (c) {
      setIsCall(true);
      setRows([...(data ? data.r ?? [] : [])]);
    } else {
      setRows([...rows, ...(data ? data.r ?? [] : [])]);
    }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  function getStatusByValue(v) {
    if (v === "Disabled") {
      return 0;
    } else if (v === "Active") {
      return 1;
    } else if (v === "Deleted") {
      return -1;
    } else {
      return null;
    }
  }

  useEffect(() => {
    getData({ statusV: "All", c: 1 });
  }, []);

  const callBack = async (l) => {
    if (l >= rows.length && isCall) {
      await getData({ c: 0, statusV: statusValue[0], search });
    }
  };

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setStatus([value]);
    getData({ c: 1, statusV: value });
  };

  const [search, setSearch] = useState("");

  return (
    <Box
      overflow="auto"
      display="flex"
      flexDirection="column"
      alignItems="start"
      paddingX="1rem"
      paddingBottom="1rem"
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="1rem"
      >
        <SearchBox
          search={search}
          onChange={async (e) => {
            setSearch(e.target.value);
            await getData({
              c: 1,
              statusV: statusValue[0],
              search: e.target.value,
            });
          }}
          cursor={false}
        />

        {/*MultiSelection*/}
        <MultiSelection
          selected={statusValue}
          handleChange={handleStatus}
          names={status}
          lable={"Status"}
          multiple={false}
          width="100%"
        />
      </Box>

      <DataGridCover
        component={
          <DataGrideView
            rows={rows ?? []}
            columns={columns}
            callBack={callBack}
            isLoading={isLoading}
            sx={{ height: "71vh" }}
            colHeight="3.110rem"
          />
        }
      />
    </Box>
  );
}

export default UsersList;
