import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { useEffect } from "react";
import SplitButton from "../../../Component/Button/SplitButton";
import { useNavigate, useParams } from "react-router-dom";
import ToastView, { ToastShow } from "../../../Component/Toast/Toast";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import {
  DELETESUPPORTCATEGORYADMIN,
  GET_SUPPORT_CATEGORY_ADMIN,
} from "../../../Apis/support_api";
import AddUpdateSupportCategoryAdmin from "../../../Component/Dailog/AddUpdateSupportCategoryAdmin";
import { API_URL } from "../../../Apis/config";

export default function PodianAdmin() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [openS, setOpenS] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const navigate = useNavigate();

  const { categoryId } = useParams();

  const onView = (id) => {
    navigate("/support/podian-support/" + id);
  };

  const onEdit = (id) => {
    const row = rows.filter((e) => e.id == id);
    setSelectedRow(row[0]);
    setOpenS(true);
  };

  const onDelete = async (id) => {
    const row = rows.filter((e) => e.id == id);
    if (row[0].support_categories_admins_id == 0) {
      ToastShow("You can not delete it from here.");
      setSelectedRow(null);
      setOpen(false);
    } else {
      setSelectedRow(row[0]);
      setOpen(true);
    }
  };

  const handleOnClose = async (v) => {
    setOpen(false);

    if (v) {
      const r = await DELETESUPPORTCATEGORYADMIN(
        selectedRow.support_categories_admins_id
      );
      if (r && r.s) {
        const d = rows.filter((v) => v.id != selectedRow.id);
        setRows([...d]);
        ToastShow("Deleted successfully");
        // getData(0);
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedRow(null);
  };

  const handleOnCloseS = async (v) => {
    setOpenS(false);

    if (v) {
      getData(0);
    }
    setOpenS(false);
    setSelectedRow(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "photo",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 130,
      renderCell: (params) => (
        <Avatar
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={onDelete}
          onView={onView}
          isView={null}
          title={params.value}
          isEdit={null}
          onEdit={onEdit}
        />
      ),
    },
  ];

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length);
    }
  };

  async function getData(count) {
    if (!count) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GET_SUPPORT_CATEGORY_ADMIN(categoryId ?? 0, count);

    if (t && t.s == 1 && t.r) {
      if (count == 0) {
        setRows([...t.r]);
      } else {
        setRows((p) => [...p, ...t.r]);
      }
    } else {
      if (count == 0) {
        setRows([]);
      }
      setIsCall(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData(0);
  }, [categoryId]);

  return (
    <Box display="flex" flexDirection="column">
      <Button
        variant="contained"
        sx={{ my: 1, alignSelf: "flex-end" }}
        onClick={() => setOpenS(true)}
      >
        Add Admin
      </Button>
      <DataGrideView
        callBack={callBack}
        isLoading={isLoading}
        rows={rows}
        columns={columns}
        sx={{
          height:"75vh"
        }}
      />
      <ConfirmationDailog
        open={open}
        handleOnClose={handleOnClose}
        content="Are you sure want to delete the admin?"
        title="Delete admin!"
      />

      {openS && (
        <AddUpdateSupportCategoryAdmin
          open={openS}
          handleOnClose={handleOnCloseS}
          categoryId={categoryId ?? 0}
        />
      )}
    </Box>
  );
}
