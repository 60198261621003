import {
  Badge,
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import SplitButton from "../../../Component/Button/SplitButton";
import { addDays, format, lastDayOfMonth, subDays } from "date-fns";
import { useEffect } from "react";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { CustomProvider, DateRangePicker } from "rsuite";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import {
  GET_PENDDING_CALL_BACK_COUNT,
  GET_SUPPORT_CATEGORY,
  GET_SUPPORT_TICKETS,
} from "../../../Apis/support_api";
import { rightsUser } from "../../Home/Drawer/DrawerView";
import SearchBox from "../../../Component/SearchBox/SearchBox";
import { ToastShow } from "../../../Component/Toast/Toast";
import PhoneEnabledRoundedIcon from "@mui/icons-material/PhoneEnabledRounded";
import AddComplain from "../CustomerSupport/AddComplain";
import ButtonView from "../../../Component/Button/ButtonView";

// const forWhome = ["Customer", "Podian"];
const ticketType = ["All", "Open", "Close"];
const satisfationType = [
  "All",
  "Excellent",
  "Good",
  "Medium",
  "Poor",
  "Very Bad",
];
function TicketsList({ forWhome, type }) {
  // console.log(status)
  const navigator = useNavigate();
  // const [forWhomeS, setforWhomeS] = useState(["Customer"]);
  const [ticketTypeS, setTicketTypeS] = useState(["All"]);
  const [satisfationTypeS, setSatisfationTypeS] = useState(["All"]);
  const theme = useTheme();
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [dateRange, setDateRange] = useState({});
  const [search, setSearch] = useState("");
  const [callBackCount, setCallBackCount] = useState(0);
  const [openAddComplain, setOpenAddComplain] = useState(false);
  const [categories, setCategories] = useState([]);
  const [row, setRow] = useState(null);

  const handleTicketType = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setTicketTypeS([value]);
    getData(
      dateRange,
      0,
      forWhome,
      getTicketTypeS(value),
      getSatisfactionTypeS(satisfationTypeS),
      search
    );
  };

  const handleSatisfactionType = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setSatisfationTypeS([value]);
    getData(
      dateRange,
      0,
      forWhome,
      getTicketTypeS(ticketTypeS),
      getSatisfactionTypeS(value),
      search
    );
  };

  const getTicketTypeS = (v) => {
    if (v == "Open") {
      return 1;
    } else if (v == "Close") {
      return 2;
    } else if (v == "All") {
      return 0;
    } else {
      return 1;
    }
  };

  const getSatisfactionTypeS = (v) => {
    if (v == "Excellent") {
      return 1;
    } else if (v == "Good") {
      return 2;
    } else if (v == "Medium") {
      return 3;
    } else if (v == "Poor") {
      return 4;
    } else if (v == "Very Bad") {
      return 5;
    } else if (v == "All") {
      return 0;
    } else {
      return 1;
    }
  };

  const getSatifactionValue = (v) => {
    if (v == 1) {
      return "Excellent";
    } else if (v == 2) {
      return "Good";
    } else if (v == 3) {
      return "Medium";
    } else if (v == 4) {
      return "Poor";
    } else if (v == 5) {
      return "Very Bad";
    } else if (v == "All") {
      return "-";
    } else {
      return "-";
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Ticket Id",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 100,
      minWidth: 100,
      // renderCell: (params) => {
      //   // console.log(params);
      //   return params.api.getRowIndex(params.row.id) + 1;
      // },
    },
    {
      field: "uname",
      headerName: "User Name",
      filterable: false,
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "category",
      headerName: "Category",
      filterable: false,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "details",
      headerName: "Details",
      filterable: false,
      minWidth: 210,
      flex: 1,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography style={{ padding: "1.3rem 0", fontSize: "12.4px" }}>
            {params.row.details}
          </Typography>
        );
      },
    },
    {
      field: "done_at",
      headerName: "Resolved Date",
      minWidth: 110,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.row.done_at === null ? (
          <p>-</p>
        ) : (
          <DateMDYHMA date={params.value} />
        );
      },
    },
    {
      field: "date_time",
      headerName: "Issued Date",
      minWidth: 110,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "isCall",
      headerName: "Need Call",
      width: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value > 0 ? (
          <PhoneEnabledRoundedIcon fontSize="small" />
        ) : (
          <p>-</p>
        );
      },
    },
    {
      field: "satisfaction",
      headerName: "Satisfaction",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography>{getSatifactionValue(params.value)}</Typography>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              color: params.value == 1 ? "green" : "red",
              fontSize: "12.4px",
            }}
          >
            {params.value == 1 ? "Open" : "Close"}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      minWidth: 100,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          isView={1}
          dataId={params.id}
          onView={viewData}
          onDelete={null}
          deleteText={null}
          isDelete={null}
          onEdit={() => {
            setRow(params.row);
            setOpenAddComplain(true);
          }}
          isEdit={1}
        />
      ),
    },
  ];

  const viewData = (v) => {
    var t = rows.filter((e) => e.id === v);
    window.open(`/tickets/${type}/` + t[0].id);
    // navigator(`/order/${route}/` + t[0].id);
  };

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  const getData = async (d, c, forW, ticketT, satisfaction, ser) => {
    if (!c) {
      setIsCall(true);
    }

    setIsLoading(true);

    const data = await GET_SUPPORT_TICKETS({
      count: c,
      forWhome: forW,
      dateRange: d,
      ticketType: ticketT,
      satisfaction,
      search: ser,
    });

    if (!c) {
      if (data.r) {
        setRows([...(data.r ?? [])]);
      } else {
        setRows([]);
      }
    } else {
      if (data.r) {
        setRows([...rows, ...(data.r ?? [])]);
      }
    }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
      getData(
        d,
        0,
        forWhome,
        getTicketTypeS(ticketTypeS),
        getSatisfactionTypeS(satisfationTypeS),
        search
      );
    } else {
      setDateRange(v);
      getData(
        v,
        0,
        forWhome,
        getTicketTypeS(ticketTypeS),
        getSatisfactionTypeS(satisfationTypeS),
        search
      );
    }
  };

  const getDataCount = async () => {
    const d = await GET_PENDDING_CALL_BACK_COUNT(forWhome);
    if (d && d.s) {
      setCallBackCount(d?.r ?? 0);
    }
  };

  useEffect(() => {
    // const d = [];
    // setRows(p=>[...d]);
    getData(
      dateRange,
      0,
      forWhome,
      getTicketTypeS(ticketTypeS),
      getSatisfactionTypeS(satisfationTypeS),
      search
    );
    getDataCount();
  }, [forWhome]);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(
        dateRange,
        rows.length,
        forWhome,
        getTicketTypeS(ticketTypeS),
        getSatisfactionTypeS(satisfationTypeS),
        search
      );
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (!e.target.value) {
      getData(
        dateRange,
        0,
        forWhome,
        getTicketTypeS(ticketTypeS),
        getSatisfactionTypeS(satisfationTypeS),
        e.target.value
      );
    }
  };

  const handleClick = () => {
    if (search) {
      getData(
        dateRange,
        0,
        forWhome,
        getTicketTypeS(ticketTypeS),
        getSatisfactionTypeS(satisfationTypeS),
        search
      );
    } else {
      ToastShow("Please fill some value in the search");
    }
  };

  const getCategories = async () => {
    const res = await GET_SUPPORT_CATEGORY(1, 0);
    if (res && res.s) {
      setCategories(res.r ?? []);
    } else {
      ToastShow(res.m);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Box display="flex" alignItems={"flex-end"} flexDirection="column">
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
              mb: 2,
              overflow: "visible",
              mt: 2,
            }}
          >
            <SearchBox
              search={search}
              onChange={handleSearch}
              onClick={handleClick}
              cursor={true}
            />

            <Box display="flex" gap={2} overflow="visible">
              {rightsUser.includes(7) && type == "customer" && (
                <Button
                  onClick={() => navigator(`/${type}-support`)}
                  variant="contained"
                  sx={{ py: 1.5 }}
                >
                  Manage Head Accounts
                </Button>
              )}

              {rightsUser.includes(8) && type == "podian" && (
                <Button
                  onClick={() => navigator(`/${type}-support`)}
                  variant="contained"
                  sx={{ py: 1.5 }}
                >
                  Manage Head Accounts
                </Button>
              )}
              <Badge
                badgeContent={callBackCount}
                color="error"
                style={{
                  overflow: "visible",
                }}
                showZero
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Button
                  onClick={() => navigator(`/tickets/${type}/callback`)}
                  variant="contained"
                  sx={{ py: 1.5 }}
                >
                  Call back
                </Button>
              </Badge>

              <MultiSelection
                selected={ticketTypeS}
                handleChange={handleTicketType}
                names={ticketType}
                lable={"Ticket type"}
                multiple={false}
              />
              <MultiSelection
                selected={satisfationTypeS}
                handleChange={handleSatisfactionType}
                names={satisfationType}
                lable={"Satisfaction type"}
                multiple={false}
              />

              <Box
                sx={{
                  bgcolor: "white",
                  boxShadow: "3px 3px 7px 0px #00000040",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingY: ".245rem",
                  gap: ".1rem",
                }}
              >
                <Box width="95%">
                  <Box
                    sx={{
                      display: "flex",
                      gap: ".3rem",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: "0",
                        padding: "0",
                      }}
                    >
                      <img
                        src={require("../../../Assets/calendar-icon.png")}
                        style={{ objectFit: "fill", width: "11px" }}
                        alt=""
                      />
                    </IconButton>
                    <Typography fontSize="10px">Date Select</Typography>
                  </Box>
                </Box>

                <Box width="95%">
                  <CustomProvider theme={theme.palette.mode}>
                    <DateRangePicker
                      className="date"
                      placeholder="dd/mm/yyyy ~ dd/mm/yyyy"
                      appearance="subtle"
                      placement="bottomEnd"
                      caretAs="a"
                      style={{
                        backgroundColor: "#F6F6F6",
                        width: "11rem",
                        height: "1.7rem",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      size="sm"
                      onChange={(v) => handleSelectDate(v ?? {})}
                      ranges={[
                        {
                          label: "Yesterday",
                          value: [
                            addDays(new Date(), -1),
                            addDays(new Date(), -1),
                          ],
                        },
                        {
                          label: "Today",
                          value: [new Date(), new Date()],
                        },
                        {
                          label: "Tomorrow",
                          value: [
                            addDays(new Date(), 1),
                            addDays(new Date(), 1),
                          ],
                        },
                        {
                          label: "Last 7 days",
                          value: [subDays(new Date(), 6), new Date()],
                        },
                        {
                          label: "This week",
                          value: [
                            subDays(
                              new Date(
                                `${firstdayW.getFullYear()}-${
                                  firstdayW.getMonth() + 1
                                }-${firstdayW.getDate()}`
                              ),
                              0
                            ),
                            addDays(
                              new Date(
                                new Date(
                                  `${lastdayW.getFullYear()}-${
                                    lastdayW.getMonth() + 1
                                  }-${lastdayW.getDate()}`
                                )
                              ),
                              0
                            ),
                          ],
                        },
                        {
                          label: "This month",
                          value: [
                            subDays(
                              new Date(
                                `${today.getFullYear()}-${
                                  today.getMonth() + 1
                                }-01`
                              ),
                              0
                            ),
                            addDays(
                              new Date(
                                new Date(
                                  `${today.getFullYear()}-${
                                    today.getMonth() + 1
                                  }-${lastDayOfMonth(today).getDate()}`
                                )
                              ),
                              0
                            ),
                          ],
                        },
                        {
                          label: "This year",
                          value: [
                            subDays(
                              new Date(`${today.getFullYear()}-01-01`),
                              0
                            ),
                            addDays(
                              new Date(
                                new Date(`${today.getFullYear()}-12-31`)
                              ),
                              0
                            ),
                          ],
                        },
                      ]}
                    />
                  </CustomProvider>
                </Box>
              </Box>

              <ButtonView
                lable="Add Complain"
                onClick={() => setOpenAddComplain(true)}
                sx={{ flex: 1, height: "3.4rem" }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ boxShadow: "3px 3px 7px 0px #00000040", width: "100%" }}>
          <DataGrideView
            rows={rows}
            columns={columns}
            callBack={callBack}
            isLoading={isLoading}
            sx={{
              height: "75vh",
            }}
            colHeight={null}
          />
        </Box>
      </Box>

      {openAddComplain && (
        <AddComplain
          open={openAddComplain}
          handleClose={() => {
            setRow(null);
            setOpenAddComplain(false);
          }}
          row={row}
          categories={categories}
          getData={getData}
        />
      )}
    </>
  );
}

export default TicketsList;
