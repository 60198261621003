import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ToastShow } from "../Toast/Toast";
import { ADD_UPDATE_SUPPORT_CATEGORY } from "../../Apis/support_api";
import TextFieldSmall from "../TextFields/TextFieldSmall";
import SelectSmall from "../SelectComponent/SelectSmall";

function AddUpdateSupportCategory({
  row,
  type,
  parentId = 0,
  open,
  handleOnClose,
}) {

  const getCodeString = (s) => {
    let c = "General";
    switch (s) {
      case 0:
        c = "General";
        break;
      case 1:
        c = "Order";
        break;
      case 2:
        c = "On Spot";
        break;
    }
    return c;
  };

  const [name, setName] = useState(row ? row.name : "");
  const [relatedTo, setReleatedTo] = useState([
    [row ? getCodeString(row.related_to) : "General"],
  ]);

  const relatedToList = ["General", "Order", "On Spot"];

  const getCode = (s) => {
    let c = 0;
    switch (s) {
      case "General":
        c = 0;
        break;
      case "Order":
        c = 1;
        break;
      case "On Spot":
        c = 2;
        break;
    }
    return c;
  };

  


  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please select the name.");
    } else {
      const data = await ADD_UPDATE_SUPPORT_CATEGORY({
        name,
        relatedTo: getCode(relatedTo[0]),
        parentId: parentId,
        type: type,
        id: row ? row.id : 0,
      });

      if (data && data.s) {
        ToastShow("Success");
        handleOnClose(1);
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
        handleOnClose(0);
      }
    }
  };

  const handleSelectReletdTo = async (e) => {
    const {
      target: { value },
    } = e;

    setReleatedTo([value]);
    // const c = relatedToList.filter((v) => v == value);
    // setReleatedTo(c[0]);
  };

  return (
    <Dialog open={open} onClose={() => handleOnClose(0)}>
      <DialogTitle>{row?.id ? "Update" : "Add"} Category</DialogTitle>
      <DialogContent
        sx={{
          width: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <TextFieldSmall
          width="100%"
          label="Name"
          placeholder="Name"
          name="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <SelectSmall
          label="Related To"
          name="Related To"
          value={relatedTo}
          onChange={handleSelectReletdTo}
          menuItems={relatedToList}
          defaultValue="Select Releted To"
          sx={{ width: "100%", mt: 1.5 }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleOnSubmit} variant="text">
          Submit
        </Button>
        <Button onClick={() => handleOnClose(0)} variant="text">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddUpdateSupportCategory;
