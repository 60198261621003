import { API_URL, POSTAPI } from "./config";

export const ADD_COIN = async (userId, coin, note) => {
  const formData = new FormData();

  // console.log("order_ids",order_ids)

  if (userId) {
    formData.append("user_id", userId);
  }

  if (coin) {
    formData.append("coin", coin);
  }

  if (note) {
    formData.append("note", note);
  }

  const res = await POSTAPI(API_URL.addCoin, formData);
  return res;
};
