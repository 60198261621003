import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  import ToastView, { ToastShow } from "../Toast/Toast";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
  import { DateTimePicker } from "@mui/x-date-pickers";
  import { format } from "date-fns";
  import { ADD_ORDER_UPDATE_LINK } from "../../Apis/add_link_order";
  
  function AddInvoiceLinkOrder({ open, handleOnClose, row, id, order_ids }) {
  
    const d = new Date();
    const dd =  d.setDate(d.getDate() + 7);
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = React.useState(row?.linkEdited?.length ? row?.linkEdited[0].link : "");
    const [clicks, setClicks] = React.useState(row?.linkEdited?.length ? row?.linkEdited[0].clicks : "");
    const [clips, setClips] = React.useState(row?.linkEdited?.length ? row?.linkEdited[0].clips : "");
    const [editor, setEditor] = React.useState(row?.linkEdited?.length ?row?.linkEdited[0].editor : "");
    const [startDate, setStartDate] = React.useState(
      row?.linkEdited?.length ? (row?.linkEdited[0].expiry ? Date.parse(row?.linkEdited[0].expiry) : null) :dd
    );
  
    const handleOnSubmit = async () => {
      if (!link.length) {
        ToastShow("Please enter the link");
      } else {
        setIsLoading(true);
        const d = await ADD_ORDER_UPDATE_LINK(
          link ?? null,
          startDate ? format(startDate, "yyyy-MM-dd HH:mm:ss") : null,
          editor,
          clips,
          clicks,
          id,
          order_ids
        );
        setIsLoading(false);
        if (d && d.s) {
          ToastShow("Successfully added");
          handleOnClose(1);
        } else {
          ToastShow("Something went wrong. Please try again.");
        }
      }
    };
  
    return (
      <>
        <Dialog fullWidth open={open} onClose={() => handleOnClose(0)}>
          <DialogTitle>{id ? "Update Link" : "Add Link"}</DialogTitle>
          <DialogContent
            sx={{
              overflow: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              sx={{
                mt: 2,
              }}
              size="small"
              label="Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
  
           
              <TextField
                fullWidth
                sx={{
                  mt: 2,
                }}
                size="small"
                label="Editor"
                value={editor}
                onChange={(e) => setEditor(e.target.value)}
              />
            
  
            <TextField
              fullWidth
              sx={{
                mt: 2,
              }}
              type="number"
              size="small"
              label="Number of clicks"
              value={clicks}
              onChange={(e) => setClicks(e.target.value)}
            />
  
            <TextField
              fullWidth
              type="number"
              sx={{
                mt: 2,
              }}
              size="small"
              label="Number of Clips"
              value={clips}
              onChange={(e) => setClips(e.target.value)}
            />
  
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                minDate={Date.now()}
                label="Expiry DateTime"
                value={startDate}
                onChange={(v) => {
                  setStartDate(v);
                }}
                renderInput={(params) => (
                  <TextField fullWidth sx={{ mt: 1.4 }} {...params} />
                )}
              />
            </LocalizationProvider>
          </DialogContent>
  
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleOnSubmit} variant="text">
                Submit
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastView />
      </>
    );
  }
  
  export default AddInvoiceLinkOrder;
  