import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";

const RadioButtonSmall = ({
  label,
  width = "19rem",
  height = "3.8rem",
  labelA = "Yes",
  labelB = "No",
  value,
  handleChange,
}) => {
  console.log(value);
  const [selected, setSelected] = useState(value === 2 ? 0 : value);

  return (
    <Box
      minWidth="16rem"
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="1px"
    >
      <Typography width="95%" fontSize="11.8px">
        {label}
      </Typography>

      {/*Form Control*/}
      <Box width="100%">
        <RadioGroup
          row
          value={selected}
          defaultValue={selected}
          sx={{
            width: "100%",
            height: "1.9rem",
            display: "flex",
            justifyContent: "space-between",
            "& .MuiFormControlLabel-root": {
              margin: "0",
            },
            "& .css-vxllfd-MuiFormControlLabel-root": {
              margin: "0",
            },
          }}
        >
          <FormControlLabel
            label={labelA}
            value={1}
            onClick={(e) => {
              handleChange(e.target.value);
              setSelected(1);
            }}
            control={
              <Radio
                checkedIcon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_checked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
                icon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_unchecked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
              />
            }
            sx={{
              width: "49%",
              height: "100%",
              userSelect: "none",
              bgcolor: "#EFEFEF",
              borderRadius: "6px",
              color: "red",
              ".MuiFormControlLabel-label": {
                color: 1 === selected ? "#FFAB30" : "#969696",
                fontWeight: "500",
              },
              "& .css-7jdnmo-MuiTypography-root": {
                fontSize: "0.750rem",
              },
            }}
          />
          <FormControlLabel
            label={labelB}
            value={0}
            onClick={(e) => {
              handleChange(e.target.value);
              setSelected(0);
            }}
            control={
              <Radio
                checkedIcon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_checked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
                icon={
                  <img
                    src={require("../../Assets/Icons for pages/Radio_unchecked.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  />
                }
              />
            }
            sx={{
              width: "49%",
              height: "100%",
              userSelect: "none",
              bgcolor: "#EFEFEF",
              borderRadius: "6px",
              ".MuiFormControlLabel-label": {
                color: 0 === selected ? "#FFAB30" : "#969696",
                fontWeight: "500",
              },
              "& .css-7jdnmo-MuiTypography-root": {
                fontSize: "0.750rem",
              },
            }}
          />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default RadioButtonSmall;
