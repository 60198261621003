import { Box, Button, Typography } from "@mui/material";
import React from "react";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import { GETORDERSANDEARNINGS } from "../../../../../Apis/service_api";
import { useEffect } from "react";
import SplitButton from "../../../../../Component/Button/SplitButton";
import { useState } from "react";
import ButtonForGrid from "../../../../../Component/Button/ButtonForGrid";
import DateMDYHMA from "../../../../../Component/Date/DateMDYHMA";

function OrderErning({ userID }) {
  const [data, setData] = useState([]);

  const rows = data?.map((row, index) => ({
    ...row,
    rowId: index + 1,
  }));

  const cols = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.row.rowId}</Typography>;
      },
    },
    {
      field: "oid",
      headerName: "Order ID",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            #{params.row.id}
          </Typography>
        );
      },
    },
    {
      field: "date_time",
      headerName: "Shoot Date",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.row.date_time} onlyDate={true} />;
      },
    },
    {
      field: "my_earn",
      headerName: "Earnings",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            ₹{params.row.my_earn}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <Button
          sx={{
            bgcolor: "#CEE5FF",
            textTransform: "none",
            px: "1.8rem",
            borderRadius: "6px",
            fontSize: "12.4px",
            "&:hover": {
              bgcolor: "#a5b6c9",
            },
          }}
          onClick={() => {
            window.open("/order/all/" + params.row.id);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const getData = async () => {
    const res = await GETORDERSANDEARNINGS(userID); //Try id 11

    if (res && res.s === 1) {
      setData(res.r);
    } else {
      console.log("error");
    }
  };

  console.log("OrderEarnings", rows);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box width="100%" boxShadow="3px 3px 7px 0px #00000040">
      <DataGrideView columns={cols} rows={rows ?? []} />
    </Box>
  );
}

export default OrderErning;
