import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";
import { ADD_UPDATE_SPOT_CATEGORY } from "../../Apis/on_sport_category_api";
import { GrClose } from "react-icons/gr";
import TextFieldLarge from "../TextFields/TextFieldLarge";
import ButtonView from "../Button/ButtonView";

// sx={{backdropFilter: "blur(1px)",}}

function AddOnSpotCategoryDailog({ open, handleOnClose, row }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState(row ? row.name : "");

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please fill name");
    } else {
      setIsLoading(true);

      const res = await ADD_UPDATE_SPOT_CATEGORY({
        name: name,
        id: row ? row.id : null,
      });

      setIsLoading(false);
      if (res && res.s == 1) {
        handleOnClose(1);
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent sx={{ paddingX: "15px", paddingY: "0px" }}>
            <Stack
              width="400px"
              direction="row"
              bgcolor="#F6F6F6"
              borderRadius="6px"
              justifyContent="space-between"
              alignItems="center"
              py="0.4rem"
              px="0.4rem"
              mb="0.7rem"
              mt="0.6rem"
            >
              <Typography fontWeight="500" sx={{ userSelect: "none" }}>
                {row ? "Update Category" : "Add Category"}
              </Typography>
              <GrClose
                style={{ cursor: "pointer", fontSize: "0.9rem" }}
                onClick={() => handleOnClose(0)}
              />
            </Stack>
            <TextFieldLarge
              label="Name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              width="400px"
            />
            <DialogActions>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <ButtonView
                  lable={row ? "Update" : "Submit"}
                  onClick={handleOnSubmit}
                />
              )}
              <ButtonView lable={"Cancel"} onClick={() => handleOnClose(0)} />
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

export default AddOnSpotCategoryDailog;
