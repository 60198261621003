import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_UPDATE_LINK = async (
  link,
  expiry,
  editor,
  clips,
  clicks,
  id,
  order_ids,
  type=0
) => {
  const formData = new FormData();

  // console.log("order_ids",order_ids)

  if (link) {
    formData.append("link", link);
  }

  if (type) {
    formData.append("type", type);
  }

  if (order_ids) {
    formData.append("orders_id", order_ids);
  }

  if (expiry) {
    formData.append("expiry", expiry);
  }

  if (editor) {
    formData.append("editor", editor);
  }

  if (clips) {
    formData.append("clips", clips);
  }

  if (clicks) {
    formData.append("clicks", clicks);
  }

  if (id) {
    formData.append("link_id", id);
  }

  const res = await POSTAPI(
    id ? API_URL.updateOrderLink : API_URL.addOrderLink,
    formData
  );
  return res;
};

export const DELETE_LINK = async (id) => {
  const res = await GETAPI(API_URL.deleteOrderLink, "&link_id=" + id);
  return res;
};

export const ADD_ORDER_UPDATE_LINK = async (
  link,
  expiry,
  editor,
  clips,
  clicks,
  id,
  order_ids
) => {
  const formData = new FormData();

  // console.log("order_ids",order_ids)

  if (link) {
    formData.append("link", link);
  }

  if (order_ids) {
    formData.append("orders_invoice_id", order_ids);
  }

  if (expiry) {
    formData.append("expiry", expiry);
  }

  if (editor) {
    formData.append("editor", editor);
  }

  if (clips) {
    formData.append("clips", clips);
  }

  if (clicks) {
    formData.append("clicks", clicks);
  }

  if (id) {
    formData.append("link_id", id);
  }

  const res = await POSTAPI(
    id ? API_URL.updateOrderLink : API_URL.addOrderInvoiceLink,
    formData
  );
  return res;
};
