import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import TextFieldView from "../../../Component/TextField/TextFieldView";
import "react-quill/dist/quill.snow.css";

function ServiceProviderAdd() {
  const [name, setName] = React.useState("");
  const [status, setStatus] = React.useState(2);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          height: "80vh",
          width: "90%",
          p: 2,
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          overflowY: "scroll",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="start"
          width="80%"
          mb="50px"
        >
          {/* TITLE */}
          <Typography variant="h6">Photographer Add</Typography>
          <Typography variant="subtitle2">
            Please fill all detail and add new Photographer in your Photographer
            directory
          </Typography>
        </Box>

        {/* FIELDS */}

        <TextFieldView
          label="Name"
          onchange={(v) => {
            setName(v);
          }}
          sx={{ width: "90%", mb: 2.5 }}
          value={name}
          type="text"
          variant="outlined"
          required={true}
        />

        <TextFieldView
          label="Email"
          onchange={(v) => {
            setName(v);
          }}
          sx={{ width: "90%", mb: 2.5 }}
          value={name}
          type="email"
          variant="outlined"
          required={true}
        />

        <TextFieldView
          label="Phone"
          onchange={(v) => {
            setName(v);
          }}
          sx={{ width: "90%", mb: 2.5 }}
          value={name}
          type="tel"
          variant="outlined"
          required={true}
        />
        <TextFieldView
          label="Address"
          onchange={(v) => {
            setName(v);
          }}
          sx={{ width: "90%", mb: 2.5 }}
          value={name}
          type="address"
          variant="outlined"
          required={true}
        />
        <TextFieldView
          label="Password"
          onchange={(v) => {
            setName(v);
          }}
          sx={{ width: "90%", mb: 2.5 }}
          value={name}
          type="password"
          variant="outlined"
          required={true}
        />
        <TextFieldView
          label="Confirm Password"
          onchange={(v) => {
            setName(v);
          }}
          sx={{ width: "90%", mb: 2.5 }}
          value={name}
          type="password"
          variant="outlined"
          required={true}
        />
        {/* Image */}
        <Box
          sx={{
            width: "82%",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            
            mb: 2.5,
          }}
        >
          <Typography variant="h6" mb={1}>
            Image
          </Typography>

          <Button
            sx={{ width: "150px", mb: 1 }}
            variant="contained"
            component="label"
          >
            <input hidden type="file" accept="image/*" id="select-image" />
            <Typography>Select Image</Typography>
          </Button>
          <Typography variant="subtitle2" mb={2.5}>
            Please select an a png,jpeg,jpg,gif file only.
          </Typography>

          <Typography variant="h6">Status</Typography>
          <FormControl>
            <RadioGroup
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value={1} control={<Radio />} label="Yes" />
              <FormControlLabel value={2} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          <Typography variant="subtitle2" sx={{ mb: 2.5 }}>
            Please select an a one status by default is inactive.
          </Typography>

          <Box display="flex" justifyContent="start">
            <Button
              size="small"
              sx={{
                height: "40px",
                mt: 1.5,
                mb: 2.5,
                mr: 1,
                width: "100px",
              }}
              variant="contained"
            >
              Submit
            </Button>
            <Button
              size="small"
              sx={{
                width: "100px",
                height: "40px",
                mt: 1.5,
                mb: 2.5,
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default ServiceProviderAdd;
