import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { theme } from "./Utils/theme";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/system";
import Home from "./Pages/Home/Home";
import Category from "./Pages/Screens/Category/Category";
import Dashboard from "./Pages/Screens/Dashboard/Dashboard";
import Order from "./Pages/Screens/Order/Order";
import Inquery from "./Pages/Screens/Inquery/Inquery";
import ServiceProvider from "./Pages/Screens/ServiceProvider/ServiceProvider";
import CategoryList from "./Pages/Screens/Category/CategoryList";
import AuthContext from "./Context/AuthContext";
import AuthService from "./Service/AuthService";
import MobileAuth from "./Pages/Auth/Otp/MobileAuth";
import VarifyOtp from "./Pages/Auth/Otp/VarifyOtp";
import InqueryList from "./Pages/Screens/Inquery/inqueryList";
import CategoryAdd from "./Pages/Screens/Category/CategoryAdd";
import ServiceProviderList from "./Pages/Screens/ServiceProvider/ServiceProviderList";
import ServiceProviderAdd from "./Pages/Screens/ServiceProvider/ServiceProviderAdd";
import Cities from "./Pages/Screens/Cities/Cities";
import Approved from "./Pages/Screens/Order/Approved";
import Applied from "./Pages/Screens/Order/Applied";
import Confirmed from "./Pages/Screens/Order/Confirmed";
import Started from "./Pages/Screens/Order/Started";
import Finished from "./Pages/Screens/Order/Finished";
import Submitted from "./Pages/Screens/Order/Submitted";
import CategoryDetails from "./Pages/Screens/Category/CategoryDetails";
import CategoryEdit from "./Pages/Screens/Category/CategoryEdit";
import CitiesList from "./Pages/Screens/Cities/CitiesList";
import CitiesAdd from "./Pages/Screens/Cities/CitiesAdd";
import CitiesEdit from "./Pages/Screens/Cities/CitiesEdit";
import CitiesDetails from "./Pages/Screens/Cities/CitiesDetails";
import Delivered from "./Pages/Screens/Order/Delivered";
import All from "./Pages/Screens/Order/All";
import Booking from "./Pages/Screens/Order/Booking/Booking";
import Roles from "./Pages/Screens/Roles/Roles";
import ServiceProviderView from "./Pages/Screens/ServiceProvider/ServiceProviderView";
//import ServiceProviderView from "./Pages/Screens/ServiceProvider/ServiceProviderViewOld";
import ToastView from "./Component/Toast/Toast";
import ServiceTemplate from "./Pages/Screens/ServiceTemplate/ServiceTemplate";
import Notification from "./Pages/Screens/Notification/Notification";
import PamotionalBanner from "./Pages/Screens/PramotionalBanner/PamotionalBanner";
import VideoStories from "./Pages/Screens/VideoStories/VideoStories";
import UsersStoriesOld from "./Pages/Screens/UsersStories/UsersStoriesOld.jsx";
import GetOrderToEdit from "./Pages/Screens/Order/Booking/GetOrderToEdit";
import Faq from "./Pages/Screens/Faq/Faq";
import ParentNotification from "./Pages/Screens/Notification/ParentNotification";
import Portfolio from "./Pages/Screens/Portfolio/Portfolio";
import Policy from "./Pages/Screens/Policy/Policy";
import UsersList from "./Pages/Screens/Users/UsersList";
import UsersView from "./Pages/Screens/Users/UsersView";
import Leave from "./Pages/Screens/ServiceProvider/Leave";
import { Notifications } from "react-push-notification";
import ExportHome from "./Pages/Screens/Export/ExportHome";
import SubAdmin from "./Pages/Screens/SubAdmin/SubAdmin";
import SubAdminList from "./Pages/Screens/SubAdmin/SubAdminList";
import SubAdminAdd from "./Pages/Screens/SubAdmin/SubAdminAdd";
import Coupen from "./Pages/Screens/Coupen/Coupen";
import CoupenList from "./Pages/Screens/Coupen/CoupenList";
import CoupenAdd from "./Pages/Screens/Coupen/CoupenAdd";
import CoupenEdit from "./Pages/Screens/Coupen/CoupenEdit";
import DraftOrder from "./Pages/Screens/Order/Booking/DraftOrder";
import OnShot from "./Pages/Screens/OnShot/OnShot";
import OrderIssue from "./Pages/Screens/OrderIssue/OrderIssue";
import { devlopment_mode } from "./Service/Constant";
import Lead from "./Pages/Screens/Lead/Lead";
import OnSpotCategory from "./Pages/Screens/NewOnSpot/OnSpotCategory";
import CreateOnSpot from "./Pages/Screens/NewOnSpot/CreateOnSpot";
import NewOnSpot from "./Pages/Screens/NewOnSpot/NewOnSpot";
import NewOnSpotList from "./Pages/Screens/NewOnSpot/NewOnSpotList";
import EditOnSpotNew from "./Pages/Screens/NewOnSpot/EditOnSpot";
import DetailsOnSpotNew from "./Pages/Screens/NewOnSpot/DetailsOnSpotNew";
import NewOnSpotProviderBookingList from "./Pages/Screens/NewOnSpot/DetailsComponent/Cities/Details/ProviderBookingList";
import ImportService from "./Pages/Screens/Category/ImportService";
import CustomerSupport from "./Pages/Screens/CustomerSupport/CustomerSupport";
import CustomerSupportList from "./Pages/Screens/CustomerSupport/CustomerSupportList";
import PodianSupport from "./Pages/Screens/PodianSupport/PodianSupport";
import PodianSupportList from "./Pages/Screens/PodianSupport/PodianSupportList";
import CustomerAdmin from "./Pages/Screens/CustomerSupport/CustomerAdmin";
import PodianAdmin from "./Pages/Screens/PodianSupport/PodianAdmin";
import TicketsList from "./Pages/Screens/Tickets/TicketsList";
import TicketsDetails from "./Pages/Screens/Tickets/TicketsDetails";
import CustomerCallbackList from "./Pages/Screens/CustomerSupport/CustomerCallbackList";
import PodianCallbackList from "./Pages/Screens/PodianSupport/PodianCallbackList";
import Support from "./Pages/Screens/Settings/Support";
import ApplicationIssue from "./Pages/Screens/ApplicationIssue/ApplicationIssue";
import Unavailability from "./Pages/Screens/Unavailability/Unavailability";
// import { POSTAPIT } from "./Apis/config";
// import OrderView from "./Pages/Screens/Order/OrderViewOld";
import OrderView from "./Pages/Screens/Order/OrderView";
import Reports from "./Pages/Screens/Report/Report";
import RolesDetails from "./Pages/Screens/Roles/RoleDetails";
import Web from "./Pages/Screens/Web/Web";
import Announcement from "./Pages/Screens/Web/Announcement/Announcement";
import WebCity from "./Pages/Screens/Web/WebCity/WebCity";
import Master from "./Pages/Screens/Master/Master.jsx";
import WebBrand from "./Pages/Screens/Web/Brand/WebBrand.jsx";
import WebFeature from "./Pages/Screens/Web/Feature/Feature.jsx";
import WebForPodian from "./Pages/Screens/Web/ForPodian/ForPodian.jsx";
import WebPodianProcess from "./Pages/Screens/Web/PodianProcess/PodianProcess.jsx";
import WebPodianOfMonth from "./Pages/Screens/Web/PodianOfMonth/PodianOfMonth.jsx";
import WebOurValue from "./Pages/Screens/Web/OurValue/OurValue.jsx";
import PayoutList from "./Pages/Screens/Payout/PayoutList.jsx";
import PayoutView from "./Pages/Screens/Payout/PayoutView.jsx";

function App() {
  const [mode, setMode] = useState("light");

  // CHNAGE THEME MODE
  const changeMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  useEffect(() => {
    if (devlopment_mode) {
      document.title = "DEV";
    }
  }, [devlopment_mode]);

  return (
    <>
      <AuthContext>
        <ThemeProvider theme={theme(mode)}>
          <>
            <Box
              sx={{
                overflow: "hidden",
                bgcolor: "#F6F3EE",
                height: "100vh",
                width: "100vw",
                overflowX: "hidden",
              }}
            >
              <Notifications />
              {/* ROUTES */}
              <Routes>
                <Route path="/" element={<MobileAuth />} />
                <Route path="/otp-verify" element={<VarifyOtp />} />
                <Route
                  path="dashboard"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Dashboard"
                        children={<Dashboard />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="order"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Order"
                        children={<Order />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<All status={0} />} />
                  {/* <Route index path="list" element={<OrderList />} /> */}
                  <Route path="all" element={<All status={0} />} />
                  <Route path="canceled" element={<Confirmed status={-1} />} />
                  <Route path="confirmed" element={<Confirmed status={1} />} />
                  <Route path="applied" element={<Applied status={2} />} />
                  <Route path="approved" element={<Approved status={3} />} />
                  <Route path="started" element={<Started status={4} />} />
                  <Route path="finished" element={<Finished status={5} />} />
                  <Route path="submitted" element={<Submitted status={6} />} />
                  <Route path="delivered" element={<Delivered status={7} />} />

                  <Route path="all/:id" element={<OrderView />} />
                  <Route path="canceled/:id" element={<OrderView />} />
                  <Route path="confirmed/:id" element={<OrderView />} />
                  <Route path="applied/:id" element={<OrderView />} />
                  <Route path="approved/:id" element={<OrderView />} />
                  <Route path="started/:id" element={<OrderView />} />
                  <Route path="finished/:id" element={<OrderView />} />
                  <Route path="submitted/:id" element={<OrderView />} />
                  <Route path="delivered/:id" element={<OrderView />} />
                  <Route path=":id/draft" element={<DraftOrder />} />
                  <Route path="all/:id/edit" element={<GetOrderToEdit />} />
                  <Route
                    path="confirmed/:id/edit"
                    element={<GetOrderToEdit />}
                  />
                  <Route
                    path="canceled/:id/edit"
                    element={<GetOrderToEdit />}
                  />
                  <Route path="applied/:id/edit" element={<GetOrderToEdit />} />
                  <Route
                    path="approved/:id/edit"
                    element={<GetOrderToEdit />}
                  />
                  <Route path="started/:id/edit" element={<GetOrderToEdit />} />
                  <Route
                    path="finished/:id/edit"
                    element={<GetOrderToEdit />}
                  />
                  <Route
                    path="submitted/:id/edit"
                    element={<GetOrderToEdit />}
                  />
                  <Route
                    path="delivered/:id/edit"
                    element={<GetOrderToEdit />}
                  />
                  <Route path=":id" element={<OrderView />} />
                  <Route path=":id/edit" element={<GetOrderToEdit />} />
                  <Route path="booking" element={<Booking />} />
                </Route>

                <Route
                  path="/create-order"
                  element={
                    <Home
                      changeMode={changeMode}
                      title="Create Order"
                      children={<Booking />}
                    />
                  }
                />
                <Route
                  path="category"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Category"
                        children={<Category />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<CategoryList />} />{" "}
                  <Route path="list/:id" element={<CategoryDetails />} />
                  <Route path="list" element={<CategoryList />} />
                  <Route path="add" element={<CategoryAdd />} />
                  <Route
                    index
                    path="service-template"
                    element={<ServiceTemplate />}
                  />
                  <Route path="edit/:id" element={<CategoryEdit />} />
                  <Route
                    path="list/:id/service-template"
                    element={<ImportService />}
                  />
                </Route>

                {/* <Route
                  path="/import-service"
                  element={
                    <Home
                      changeMode={changeMode}
                      title="Import Service"
                      children={<ImportService />}
                    />
                  }
                /> */}

                <Route
                  path="cities"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Cities"
                        children={<Cities />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<CitiesList />} />
                  <Route index path="list" element={<CitiesList />} />
                  <Route index path="list/:id" element={<CitiesDetails />} />
                  <Route index path="list/edit/:id" element={<CitiesEdit />} />
                  <Route index path="add" element={<CitiesAdd />} />
                </Route>

                <Route
                  path="inquiry"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Inquery"
                        children={<Inquery />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<InqueryList />} />
                  <Route index path="list" element={<InqueryList />} />
                </Route>

                <Route
                  path="service-provider"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Service Provider"
                        children={<ServiceProvider />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<ServiceProviderList />} />
                  <Route index path="list" element={<ServiceProviderList />} />
                  <Route
                    index
                    path="list/:id"
                    element={<ServiceProviderView />}
                  />
                  <Route index path="add" element={<ServiceProviderAdd />} />
                  <Route index path="leave" element={<Leave />} />
                </Route>

                <Route
                  path="service-template"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Service Template"
                        children={<ServiceTemplate />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="roles"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Roles"
                        children={<Roles />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="roles/:id"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Roles Details"
                        children={<RolesDetails />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="notifications"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Notifications"
                        children={<Notification />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="promotional-banner"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Promotional Banner"
                        children={<PamotionalBanner />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="users-stories"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="User Stories"
                        children={<UsersStoriesOld />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="video-stories"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Video Stories"
                        children={<VideoStories />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="faq"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="FAQ"
                        children={<Faq />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="create-notification"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Custom Notification"
                        children={<ParentNotification />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="portfolio"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Portfolio"
                        children={<Portfolio />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="policy"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Policy"
                        children={<Policy />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="users"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Users"
                        children={<UsersList />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="users/:id"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Users Details"
                        children={<UsersView />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="export"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Export"
                        children={<ExportHome />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="sub-admin"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="SubAdmin"
                        children={<SubAdmin />}
                      />
                    </AuthService>
                  }
                >
                  <Route index path="list" element={<SubAdminList />} />
                  <Route path="add" element={<SubAdminAdd />} />
                  <Route path="list/edit/:id" element={<SubAdminAdd />} />
                </Route>

                <Route
                  path="coupon"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Coupon"
                        children={<Coupen />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<CoupenList />} />
                  <Route path="list" element={<CoupenList />} />
                  <Route path="list/:id" element={<CategoryDetails />} />
                  <Route path="add" element={<CoupenAdd />} />
                  <Route path="edit" element={<CoupenEdit />} />
                </Route>

                <Route
                  path="onSpot"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="OnSpot"
                        children={<OnShot />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="orderissue"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="OrderIssue"
                        children={<OrderIssue />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="lead"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Lead"
                        children={<Lead />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="new-onSpot"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="On Spot"
                        children={<NewOnSpot />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<OnSpotCategory />} />
                  <Route path="category" element={<OnSpotCategory />} />
                  <Route path="create" element={<CreateOnSpot />} />
                  <Route path="list" element={<NewOnSpotList />} />
                  <Route path="edit/:id" element={<EditOnSpotNew />} />
                  <Route
                    path="edit/:id/details"
                    element={<DetailsOnSpotNew />}
                  />
                  <Route
                    path="create/:id/details"
                    element={<DetailsOnSpotNew />}
                  />
                  <Route
                    path="edit/:id/details/:providerId"
                    element={<NewOnSpotProviderBookingList />}
                  />
                </Route>

                <Route
                  path="customer-support"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Customer Support"
                        children={<CustomerSupport />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<CustomerSupportList />} />
                  <Route path=":parentId" element={<CustomerSupportList />} />
                  <Route path=":categoryId/admin" element={<CustomerAdmin />} />
                  <Route path="callback" element={<CustomerCallbackList />} />
                </Route>

                <Route
                  path="podian-support"
                  element={
                    <AuthService>
                      {" "}
                      <Home
                        changeMode={changeMode}
                        title="Podian Support"
                        children={<PodianSupport />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<PodianSupportList />} />
                  <Route path=":parentId" element={<PodianSupportList />} />
                  <Route path=":categoryId/admin" element={<PodianAdmin />} />
                  <Route path="callback" element={<PodianCallbackList />} />
                </Route>

                <Route
                  path="tickets/customer"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Tickets"
                        children={<TicketsList forWhome={1} type="customer" />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="tickets/customer/callback"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Customer Callback"
                        children={<CustomerCallbackList />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="tickets/podian"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Tickets"
                        children={<TicketsList forWhome={2} type="podian" />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="tickets/podian/callback"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Podian Callback"
                        children={<PodianCallbackList />}
                      />
                    </AuthService>
                  }
                />

                {/* <Route
                  path="support"
                  element={
                    <AuthService>
                   
                      <Home
                        changeMode={changeMode}
                        title="Support"
                        children={<Support />}
                      />
                    </AuthService>
                  }
                >
                  <Route path="customer-support" index element={<CustomerSupportList />} />
                  <Route path="customer-support/:parentId" element={<CustomerSupportList />} />
                  <Route path="customer-support/:categoryId/admin" element={<CustomerAdmin />} />
                  <Route path="customer-support/callback" element={<CustomerCallbackList />} />

                  <Route path="podian-support" element={<PodianSupportList />} />
                  <Route path="podian-support/:parentId" element={<PodianSupportList />} />
                  <Route path="podian-support/:categoryId/admin" element={<PodianAdmin />} />
                  <Route path="podian-support/callback" element={<PodianCallbackList />} />
                  </Route> */}

                <Route
                  path="tickets/customer/:id"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Tickets Details"
                        children={<TicketsDetails />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="tickets/podian/:id"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Tickets Details"
                        children={<TicketsDetails />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="application-issue"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Application Issue"
                        children={<ApplicationIssue />}
                      />
                    </AuthService>
                  }
                />
                <Route
                  path="unavailability"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Unavailability"
                        children={<Unavailability />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="report"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Report"
                        children={<Reports />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="payout"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Payout"
                        children={<PayoutList />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="payout/:id"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Payout Details"
                        children={<PayoutView />}
                      />
                    </AuthService>
                  }
                />

                <Route
                  path="web"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Web"
                        children={<Web />}
                      />
                    </AuthService>
                  }
                >
                  <Route index element={<Announcement />} />
                  <Route path="announcement" element={<Announcement />} />
                  <Route path="city" element={<WebCity />} />
                  <Route path="brand" element={<WebBrand />} />
                  <Route path="feature" element={<WebFeature />} />
                  <Route path="for-podian" element={<WebForPodian />} />
                  <Route path="podian-process" element={<WebPodianProcess />} />
                  <Route
                    path="podian-of-month"
                    element={<WebPodianOfMonth />}
                  />
                  <Route path="our-value" element={<WebOurValue />} />
                </Route>
                <Route
                  path="master"
                  element={
                    <AuthService>
                      <Home
                        changeMode={changeMode}
                        title="Master"
                        children={<Master />}
                      />
                    </AuthService>
                  }
                />
              </Routes>
            </Box>
          </>
        </ThemeProvider>
      </AuthContext>
      <ToastView />
    </>
  );
}

export default App;
