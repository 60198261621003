import { Box, Fab, IconButton, Rating, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { GET_CITIE } from "../../../Apis/city_api";
import { API_URL } from "../../../Apis/config";
import { Delete, Edit } from "@mui/icons-material";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { ToastShow } from "../../../Component/Toast/Toast";
import {
  DELETE_USER_STORY,
  GET_USERS_STORIES,
} from "../../../Apis/users_stories_api";
import UserStoryDailog from "../../../Component/Dailog/UserStoryDailog";

function UsersStories() {
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [cityId, setSelectedId] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [bannerId, setBannerId] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [review, setReview] = React.useState("");
  const [name, setName] = React.useState("");
  const [src, setSrc] = React.useState("");

  const handleOpen = (v = 0, src = "", r = 0, re = "", n) => {
    if (v) {
      setSrc(src);
      setBannerId(v);
      setRate(r);
      setReview(re);
      setName(n);
    }
    setOpen(true);
  };

  const handleOpenConfirmation = (id) => {
    setBannerId(id);
    setOpenConfirmation(true);
  };

  const handleOnClose = (v) => {
    setSrc("");
    setRate(0);
    setReview("");
    setName("");
    setBannerId(0);
    if (v) {
      getData(cityId, 0);
    }
    setOpen(0);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const d = await DELETE_USER_STORY(bannerId);
      if (d && d.s) {
        ToastShow("Deleted successfully");
      }
      setBannerId(0);
      getData(cityId, 0);
    }
    setOpenConfirmation(0);
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
      setSelectedId(t.r[0].id);
      setSelectedCities([t.r[0].name]);
      getData(t.r[0].id, 0);
    }
  }

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    const c = citiesW.filter((v) => v.name == value);
    setSelectedId(c.length ? c[0].id : 0);
    await getData(c.length ? c[0].id : 0, 0);
  };

  async function getData(id, v) {
    if (!v) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GET_USERS_STORIES(v ? rows.length : 0, id);

    if (t && t.s == 1) {
      if (v) {
        setRows([...rows, ...t.r]);
      } else {
        if (t.r == null) {
          setRows([]);
        } else {
          setRows([...t.r]);
        }
      }
    }

    if ((t.r ?? []).length == 0) {
      setIsCall(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getCities();
  }, []);

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "80vh",
      }}
    >
      <MultiSelection
        multiple={false}
        names={cities}
        handleChange={handleSelectCity}
        selected={selectedCities}
        lable="Cities"
      />
      <Box display="flex" flexWrap="wrap">
        {rows.map((v) => {
          return (
            <Box
              key={v.id}
              sx={{
                position: "relative",
                m: 3,
                height: "200px",
                width: "300px",
                objectFit: "cover",
                transition: "all",
                transitionDuration: "1000ms",
                boxShadow: " 2px 3px 15px 2px #754f4f7c",
                "&::before": {
                  transition: "all",
                  transitionDuration: "300ms",
                  content: '""',
                  position: "absolute",
                  width: "100%",
                  height: "0%",
                  bottom: 0,
                  background: "rgb(190,177,156)",
                  background:
                    "linear-gradient(0deg, rgba(190,177,156,1) 0%, rgba(248,176,63,1) 100%)",
                  transformOrigin: "bottom",
                },

                "&:hover::before": {
                  width: "100%",
                  height: "100%",
                  bottom: 0,
                  transformOrigin: "top",
                },
                "& div": {
                  display: "none",
                  transform: "scale(0)",
                  transition: "all",
                  transitionDuration: "1000ms",
                },
                "&:hover div": {
                  display: "flex",
                  transform: "scale(1)",
                },
              }}
            >
              <img
                height="200px"
                width="300px"
                src={API_URL.baseUrl + API_URL.userStoryImage + v.photo}
              />

              <Box
                sx={{
                  top: 5,
                  right: 10,
                  position: "absolute",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    handleOpen(v.id, v.photo, v.rate, v.review, v.name)
                  }
                  sx={{
                    mr: 1,
                    backgroundColor: "#ffffff63",
                  }}
                >
                  <Edit />
                </IconButton>

                <IconButton
                  size="small"
                  onClick={() => handleOpenConfirmation(v.id)}
                  sx={{
                    backgroundColor: "#ffffff63",
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>

              <Box
                sx={{
                  bottom: 5,
                  left: 10,
                  width: "90%",
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: "#fff" }}>
                  {v.review}
                </Typography>
                <Rating value={v.rate} />
              </Box>
            </Box>
          );
        })}

        <Fab
          onClick={() => handleOpen()}
          sx={{
            position: "absolute",
            bottom: 30,
            right: 30,
          }}
        >
          <AddIcon />
        </Fab>

        {open ? (
          <UserStoryDailog
            cities={cities}
            citiesW={citiesW}
            open={open}
            id={bannerId}
            handleOnClose={handleOnClose}
            src={src}
            r={rate}
            re={review}
            n={name}
          />
        ) : null}

        {openConfirmation ? (
          <ConfirmationDailog
            open={openConfirmation}
            handleOnClose={handleOnCloseConfirmation}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default UsersStories;
