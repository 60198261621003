import { Button, Popover, Stack, Typography } from "@mui/material";
import { GrClose } from "react-icons/gr";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import DateMDYHMA from "../../../../../Component/Date/DateMDYHMA";

const LogsModal = ({ open, handleClose, data, loading }) => {
  console.log(data);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      width: 60,
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ fontSize: "11.8px" }}>
          {params.api.getRowIndex(params.row.id) + 1}.
        </Typography>
      ),
    },
    {
      field: "by",
      headerName: "By",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "11.8px" }}>{params.row.by}</Typography>
        );
      },
    },
    {
      field: "provider_cut",
      headerName: "Provider Cut",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="11.8px">{params.row.provider_cut}</Typography>
        );
      },
    },
    {
      field: "min_amount",
      headerName: "Min Amount",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="11.8px">{params.row.min_amount}</Typography>
        );
      },
    },
    {
      field: "max_amount",
      headerName: "Max Amount",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="11.8px">{params.row.max_amount}</Typography>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 120,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.row.created_at} />;
      },
    },
  ];

  return (
    <Popover
      open={open}
      onClose={handleClose}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        height: "100vh",
      }}
    >
      <Stack
        sx={{
          width: "63vw",
          p: 1.9,
          gap: 2,
          bgcolor: "white",
          borderRadius: "5px",
        }}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="18px" fontWeight="500">
            Cut-Off Logs
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        <DataGrideView
          rows={data ?? []}
          columns={columns}
          isLoading={loading}
          colHeight={"3.4rem"}
          columnColor="#F6F6F6"
          hideFooter={true}
          sx={{ height: "83.8vh" }}
        />
      </Stack>
    </Popover>
  );
};

export default LogsModal;
