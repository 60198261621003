import { API_URL, GETAPI } from "./config";


export const GET_APP_ISSUE = async (count,app) => {
    const res = await GETAPI(API_URL.getAppIssue, "&count=" + count+"&app=" + app);
    return res;
  };

  //export const GET_Unavalability = async (count,dateRange,city_id) => {
  export const GET_Unavalability = async (month,city_id) => {
    console.log(city_id)
    // let query = "";
    // if (dateRange && Object.keys(dateRange).length) {
    //     query += "&from_date=" + dateRange.start;
    //     query += "&to_date=" + dateRange.end;
    //   }
    // const res = await GETAPI(API_URL.getUnavailability, "&count=" + count+"&city_id=" + city_id+query);
     const res = await GETAPI(API_URL.getUnavailability, "&month=" + month+"&city_id=" + city_id);
    return res;
  };