import React from "react";
// import { ToastContainer, toast } from "material-react-toastify";
// import "material-react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ToastView() {
  return (
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

    // <ToastContainer
    //   position="bottom-left"
    //   autoClose={3000}
    //   hideProgressBar
    //   newestOnTop={false}
    //   closeOnClick
    //   rtl={false}
    //   pauseOnFocusLoss
    //   draggable
    //   pauseOnHover
    // />
  );
}

export default ToastView;

export function ToastShow(text) {
  toast(text);
  // toast.dark(text, {
  //   position: "bottom-left",
  //   autoClose: 3000,
  //   hideProgressBar: true,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  // });
}
