import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrderDetailsById } from "../../../../Apis/order_api";
import EditBooking from "./EditOrder";

function DraftOrder() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
  
    const getData = async () => {
      setIsLoading(true);
      const data = await getOrderDetailsById(id);
      setData(data.r);
      setIsLoading(false);
    };
  
    useEffect(() => {
      getData();
    }, []);
  
    return isLoading ? (
      <Box sx={{
          display:"flex",
          justifyContent:"center",
          alignItems:"center"
      }}>
        <CircularProgress />
      </Box>
    ) : (
      <EditBooking data={data} isDraft={1}/>
    );
}

export default DraftOrder