import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";

function LoadingButtonView({
  lable,
  onClick,
  sx = {},
  variant = "contained",
  loading = false,
  fontWeight,
  fontSize,
}) {
  return (
    <LoadingButton
      loading={loading}
      disableElevation
      size="small"
      onClick={() => onClick()}
      sx={{ ...sx, borderRadius: 2 }}
      variant={variant}
    >
      <Typography
        sx={{
          color: "black",
          textTransform: "none",
          fontSize,
          fontWeight,
        }}
      >
        {lable}
      </Typography>
    </LoadingButton>
  );
}

export default LoadingButtonView;
