import { Box } from "@mui/system";
import React, { useState } from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { useEffect } from "react";
import { GET_CITIES } from "../../../Apis/city_api";
import SplitButton from "../../../Component/Button/SplitButton";
import { Link, useNavigate } from "react-router-dom";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { ToastShow } from "../../../Component/Toast/Toast";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import multiselectionIcon from "../../../Assets/multiselectionIcon.png";
import AddCitiesModal from "./AddCitiesModal";
import SearchBox from "../../../Component/SearchBox/SearchBox";

const status = ["All", "Active", "Inactive"];

function CitiesList() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [statusValue, setStatus] = useState(["All"]);
  const [charValue, setCharValue] = useState(["All"]);
  const [isCall, setIsCall] = useState(true);
  const [search, setSearch] = useState("");
  const navigation = useNavigate();

  const [open, setOpen] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (!e.target.value) {
      getData({ c: 1, status: statusValue, search: e.target.value });
    }
  };

  const onEdit = async (id) => {
    // navigation("edit/" + id);

    window.open("list/edit/" + id);

    // const data = await GET_CITIES_DETAILS(id);

    // // console.log(id)
    // const t = rows.filter((v) => v.id == id);

    // // console.log(JSON.stringify(t));
    // const d = [];

    // for (const v of t[0].alternate_names) {
    //   d.push(v.name);
    // }

    // // console.log("d",d);
    // // console.log(t[0].alternate_names)

    // setSelectedRows(t[0]);
    // setSelectedChip([...d]);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    // { field: "id", headerName: "ID", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      minWith: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "charge_per_km",
      headerName: "Char. per(KM)",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "podian_counts",
    //   headerName: "PODIAN counts",
    //   width: 120,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "address",
      headerName: "Address",
      width: 500,
      minWidth: 500,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <p
          style={{
            textAlign: "start",
            whiteSpace: "normal",
            color: params.value == 1 ? "#0DA800" : "#E50000",
          }}
        >
          {params.value == 1 ? "Active" : "Inactive"}
        </p>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => (
        // OLD CODE
        // <SplitButton
        //   key={params.id}
        //   dataId={params.id}
        //   isDelete={null}
        //   title={params.value}
        //   onEdit={onEdit}
        // />
        <Button
          sx={{
            bgcolor: "#FFD393",
            color: "#101010",
            borderRadius: "6px",
            textTransform: "none",
            "&:hover": {
              bgcolor: "#c2a276",
            },
          }}
          style={{
            padding: "0",
            minWidth: "0",
            paddingTop: "0.1rem",
            paddingBottom: "0.1rem",
            paddingLeft: "0.8rem",
            paddingRight: "0.8rem",
          }}
          onClick={() => onEdit(params.row.id)}
        >
          View
        </Button>
      ),
    },
    // { field: "synonym_name", headerName: "Synonym name", width: 130 },
  ];

  async function getData({ c, status = "All", search = "" }) {
    setIsLoading(true);

    const t = await GET_CITIES({
      count: c ? 0 : rows.length,
      status: getStatusV(status),
      search: search,
    });

    if (t && t.s == 1) {
      if (t.r) {
        if (c) {
          setRows([...t.r]);
        } else {
          setRows((p) => [...p, ...t.r]);
        }
      } else if (c) {
        setRows([]);
      }
    }

    if (c) {
      setIsCall(true);
    }
    setIsLoading(false);
  }

  // const callBack = async (l) => {
  //   if (l >= rows.length && isCall) {
  //     await getData({ c: 0, status: statusValue });
  //   }
  // };

  const getStatusV = (s) => {
    switch (s) {
      case "All":
        return "1,0";
      case "Active":
        return "1";
      case "Inactive":
        return "0";
      default:
        return "0,1";
    }
  };

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setStatus([value]);
    getData({ c: 1, status: value, search: search });
  };

  const handleCharPerkm = (e) => {
    const { value } = e.target;
    setCharValue([value]);
  };

  useEffect(() => {
    getData({ c: 0, status: "All", search: "" });
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <SearchBox
          search={search}
          onChange={handleSearch}
          onClick={() => {
            if (search) {
              getData({ c: 1, status: statusValue, search: search });
            } else {
              ToastShow("Please fill some value in the search");
            }
          }}
          cursor="pointer"
        />

        <Box sx={{ display: "flex", gap: ".8rem" }}>
          {/* <MultiSelection
            selected={charValue}
            handleChange={handleCharPerkm}
            names={charPerKmOptions}
            lable="Char.per(km)"
            multiple={false}
            icon={multiselectionIcon}
            containerWidth="8rem"
          /> */}
          <MultiSelection
            selected={statusValue}
            handleChange={handleStatus}
            names={status}
            lable={"Status"}
            multiple={false}
            containerWidth="8rem"
          />
        </Box>
      </Box>

      <Box
        sx={{
          overflow: "auto",
          width: "100%",
          backgroundColor: "white",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "3px 3px 7px 0px #00000040",
        }}
      >
        <Box
          width="97%"
          marginY=".8rem"
          sx={{
            fontFamily: "DM Sans,sans-serif",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: "#454545", fontSize: "1.2rem", fontWeight: "500" }}
          >
            Cities
          </Typography>

          <Button
            startIcon={
              <img
                src={require("../../../Assets/add-button.png")}
                width="14px"
                alt=""
              />
            }
            onClick={() => setOpen(true)}
            sx={{
              borderRadius: "6px",
              textTransform: "none",
              bgcolor: "#F8AF41",
              color: "black",
              fontSize: "15.3px",
              "&:hover": {
                bgcolor: "rgb(173, 123, 44)",
              },
            }}
            style={{
              minWidth: "0px",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: "0.3rem",
              paddingBottom: "0.3rem",
            }}
          >
            Add Cities
          </Button>
        </Box>

        <AddCitiesModal
          open={open}
          handleClose={(v) => {
            if (v) {
              getData({ c: 0, status: "All", search: "" });
            }

            setOpen(false);
          }}
        />

        <Box width="98%">
          <DataGrideView
            isLoading={isLoading}
            rows={rows}
            columns={columns}
            colHeight="3rem"
            sx={{ height: "70vh" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CitiesList;
