import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import IconButtomSmall from "../../../../Component/Button/IconButtomSmall";
import map_icon from "../../../../Assets/Icons for pages/map_icon.png";

const ShootDetailsTable = ({
  categories,
  subCategories,
  date,
  time,
  shooting,
  city,
  address,
  landmark,
  flatNo_floor_society,
  latitude,
  longitude,
}) => {
  return (
    <Box
      width="30vw"
      height="100%"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFAB30",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          Shoot Details
        </Typography>
      </Box>

      {/*Rows*/}
      <Row label="Categories" prop={categories} />
      <Row label="Sub Categories" prop={subCategories} />
      <Row label="Date" prop={date} />
      <Row label="Time" prop={time} />
      <Row label="Shooting" prop={shooting} />
      <Row label="City" prop={city} />
      <Row label="Landmark" prop={landmark} />
      <Row
        label="Address"
        prop={address}
        isLarge={true}
        hasMap={true}
        latitude={latitude}
        longitude={longitude}
      />
      {/*The Last row*/}
      <Stack width="100%" direction="row">
        <Box
          width="30%"
          bgcolor="#EFEFEF"
          // height="2.1rem"
          display="flex"
          flexDirection="column"
          paddingY="0.4rem"
          pl="0.8rem"
          sx={{ borderBottomLeftRadius: "5px" }}
        >
          <Typography fontSize="13px">Flat No./Floor/</Typography>
          <Typography fontSize="13px">Society Name</Typography>
        </Box>
        <Box
          width="70%"
          bgcolor="white"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          paddingY="0.4rem"
          pl="0.8rem"
          pr="0.8rem"
          sx={{ borderBottomRightRadius: "5px" }}
        >
          <Typography fontSize="13px" fontWeight="500">
            {flatNo_floor_society ?? "-"}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default ShootDetailsTable;

function Row({ label, prop, isLastRow, isLarge, hasMap, latitude, longitude }) {
  return (
    <Stack width="100%" direction="row">
      <Box
        width="30%"
        bgcolor="#EFEFEF"
        borderBottom="1px solid white"
        // height="2.1rem"
        display="flex"
        alignItems="flex-start"
        paddingY="0.4rem"
        pl="0.8rem"
        sx={{ borderBottomLeftRadius: isLastRow && "5px" }}
      >
        <Typography fontSize="13px">{label}</Typography>
      </Box>
      <Box
        width="70%"
        bgcolor="white"
        borderBottom="1px solid #EFEFEF"
        height={isLarge && "8.6rem"}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        paddingY="0.4rem"
        pl="0.8rem"
        pr="0.8rem"
        sx={{ borderBottomRightRadius: isLastRow && "5px" }}
      >
        <Typography fontSize="13px" fontWeight="500">
          {prop ? prop : "-"}
        </Typography>

        {isLarge && (
          <Box ml="0.2rem">
            {latitude && longitude ? (
              <IconButtomSmall
                label="Map"
                buttonStyle={{
                  backgroundColor: "#FFE0B2",
                  padding: "2.6px 7.2px",
                  borderRadius: "4px",
                }}
                imgStyle={{ width: "10px", height: "10px" }}
                icon={map_icon}
                onClick={() =>
                  window.open(
                    "https://maps.google.com?q=" + latitude + "," + longitude
                  )
                }
              />
            ) : null}
          </Box>
        )}
      </Box>
    </Stack>
  );
}
