import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MultiSelection from "../Multiselection/MultiSelection";

const options = [
  "All",
  "Received/New Order",
  "PODIAN Interested",
  "Confirmed",
  "Order Started",
  "Finished",
  "RAW Received",
  "Delivery Successful",
  "Canceled",
];

// const options = [
//   "None",
//   "Confirmed",
//   "Applied",
//   "Approved",
//   "Started",
//   "Finished",
//   "Submitted",
// ];

export default function OrderMenuButton({ sx = {} }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (e) => {
    const { value } = e.target;

    const index = options.indexOf(value);
    setSelectedIndex(index);
    navigate("/order/" + getRouteName(index));
    // window.location.reload();

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  React.useEffect(() => {
    const { pathname } = location;
    const i = getIndex(pathname);
    setSelectedIndex(i);
  }, [location]);

  function getIndex(p) {
    // console.log("path name", p);
    if (p == "/order/confirmed") {
      return 1;
    } else if (p == "/order/applied") {
      return 2;
    } else if (p == "/order/approved") {
      return 3;
    } else if (p == "/order/started") {
      return 4;
    } else if (p == "/order/finished") {
      return 5;
    } else if (p == "/order/submitted") {
      return 6;
    } else if (p == "/order/delivered") {
      return 7;
    }
    if (p == "/order/canceled") {
      return 8;
    } else {
      return 0;
    }
  }

  function getRouteName(p) {
    // console.log("path name", p);
    if (p == 0) {
      return "all";
    } else if (p == 8) {
      return "canceled";
    } else if (p == 1) {
      return "confirmed";
    } else if (p == 2) {
      return "applied";
    } else if (p == 3) {
      return "approved";
    } else if (p == 4) {
      return "started";
    } else if (p == 5) {
      return "finished";
    } else if (p == 6) {
      return "submitted";
    } else {
      return "delivered";
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <MultiSelection
        selected={[options[selectedIndex]]}
        handleChange={handleMenuItemClick}
        names={options}
        lable={"Order Status"}
        multiple={false}
      />
      {/* <ButtonGroup
        variant="text"
        ref={anchorRef}
        aria-label="split button"
        sx={{ ...sx }}
      >
        <Button variant="outlined" onClick={handleClick}>
          <Typography>{options[selectedIndex]}</Typography>
        </Button>
        <Button
          size="small"
          variant="outlined"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 100,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      // disabled={index == 0}
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </React.Fragment>
  );
}
