import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../../Toast/Toast";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import PodianSelection from "./PodianSelection";
import { ADD_UPDATE_WEB_PODIAN_OF_MONTH } from "../../../Apis/web/podian_of_month_api";

function AddUpdateWebPodianOfMonthDailog({ open, handleOnClose, row }) {
  // console.log(row)
  const [isLoading, setIsLoading] = useState(false);
  const [month, setMonth] = React.useState(
    row ? (row.month ? new Date(row.month) : null) : null
  );
  const [score, setScore] = React.useState(row ? row.score : "");
  const [openP, setOpenP] = React.useState(false);
  const [podian, setPodian] = React.useState(
    row ? { name: row.podian, id: row.podian_user_id } : {}
  );
  const [review, setReview] = React.useState(row ? row.review : "");

  const handleOnSubmit = async () => {
    if (!Object.keys(podian).length) {
      ToastShow("Please select podian");
    } else if (!month) {
      ToastShow("Please select month");
    } else if (!score) {
      ToastShow("Please enter score");
    } else if (!review) {
      ToastShow("Please enter review");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_WEB_PODIAN_OF_MONTH({
        row: row,
        score: score,
        review: review,
        month: `${month.getFullYear()}-${month.getMonth() + 1 <= 9 ? 0 : ""}${
          month.getMonth() + 1
        }-${month.getDate() + 1 <= 9 ? 0 : ""}${month.getDate()}`,
        podian_user_id: podian.id,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>
          {!row ? "Add Podian Of Month" : "Update Podian Of Month"}
        </DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              alignSelf: "flex-start",
            }}
          >
            Podian
          </Typography>
          <Box
            onClick={() => setOpenP(true)}
            sx={{
              width: "100%",
              p: 0.8,
              borderRadius: "5px",
              border: "solid 1px #c0c0c0",
              cursor: "pointer",
            }}
          >
            {Object.keys(podian).length ? (
              <Typography>{podian.name}</Typography>
            ) : (
              <Typography>Select podian on click here.</Typography>
            )}
          </Box>
          <TextField
            sx={{
              width: "100%",
              mt: 2,
            }}
            type="number"
            size="small"
            fullWidth
            label="Score"
            value={score}
            onChange={(e) => setScore(e.target.value)}
          />
          <TextField
            sx={{
              width: "100%",
              mt: 2,
            }}
            multiline
            rows={3}
            type="text"
            size="small"
            fullWidth
            label="Review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Month"
              width="100%"
              // height="3.8rem"
              noHeight={true}
              paddingY="0.4rem"
              bgColor="#c0c0c02c"
              fieldBgColor="white"
              value={month}
              inputFormat="dd-MM-yyyy"
              onChange={(v) => {
                setMonth(v);
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ mt: 2, width: "100%" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleOnSubmit}
              variant="text"
              sx={{ color: "#F8B03F" }}
            >
              Submit
            </Button>
          )}
          <Button
            onClick={() => handleOnClose(0)}
            variant="text"
            sx={{ color: "#F8B03F" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
      <PodianSelection
        open={openP}
        handleOnClose={(v) => {
          // console.log(v)
          if (v) setPodian(v);
          setOpenP(false);
        }}
      />
    </>
  );
}

export default AddUpdateWebPodianOfMonthDailog;
