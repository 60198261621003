import { Add } from "@mui/icons-material";
import { Avatar, Box, Button, Fab, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AddOnShotDailog from "../../../Component/Dailog/AddOnShotDailog";
import { GET_CITIE } from "../../../Apis/city_api";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import SplitButton from "../../../Component/Button/SplitButton";
import { GET_ON_SHOT } from "../../../Apis/on_shot_api";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { API_URL } from "../../../Apis/config";
import AddOnShotLinkDailog from "../../../Component/Dailog/AddOnShotLink";
import { format } from "date-fns";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import AddOnSpotModal from "./AddOnSpotModal";
import { dateFormateHHMMA } from "../../../Service/DateSevice";

function OnShot() {
  const [open, setOpen] = React.useState(false);
  const [openLink, setOpenLink] = React.useState(false);
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [cityId, setSelectedId] = React.useState(0);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isCall, setIsCall] = React.useState(true);
  const type = ["All", "Up Coming", "Past"];
  const [selectedType, setSelectedType] = React.useState(["All"]);
  const [typeId, setTypeId] = React.useState("0");

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
    setTypeId(getValue(value));

    getData(cityId, 0, getValue(value));
  };

  const getValue = (v) => {
    let r = "0";

    if (v == "All") {
      r = "0";
    } else if (v == "Up Coming") {
      r = "1";
    } else {
      r = "2";
    }

    return r;
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
      setSelectedId(t.r[0].id);
      setSelectedCities([t.r[0].name]);
      getData(t.r[0].id, 0, typeId);
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const callBack = async (l) => {
    if (l >= rows.length && isCall) {
      await getData(cityId, rows.length, typeId);
    }
  };

  const handleOnClose = (v) => {
    if (v) {
      getData(cityId, 0, typeId);
    }
    setSelectedRow(null);
    setOpen(false);
    getData(cityId, 0, typeId);
  };

  const getData = async (cityID, count, typeId) => {
    setIsLoading(true);

    const t = await GET_ON_SHOT(count, cityID, typeId);

    if (t && t.s) {
      // console.log("tttt",t);
      if (t.r) {
        if (count > 0) {
          setRows([...rows, ...t.r]);
        } else {
          setRows([...t.r]);
        }
      } else {
        if (count == 0) {
          setRows([]);
        } else {
          setIsCall(false);
        }
      }
    }

    if (count == 0) {
      setIsCall(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // getData();
    getCities();
  }, []);

  const onEdit = async (id) => {
    const d = rows.filter((v) => v.id == id);
    setSelectedRow(d[0]);
    handleOpen();
  };

  const handleLinkOpen = async (id) => {
    const d = rows.filter((v) => v.id == id);
    setSelectedRow(d[0]);
    setOpenLink(true);
  };

  const handleLinkClose = (v) => {
    if (v) {
      getData(cityId, 0, typeId);
    }
    setSelectedRow(null);
    setOpenLink(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "start",
      width: 60,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography>{params.api.getRowIndex(params.row.id) + 1}</Typography>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "logo",
      headerName: "Logo",
      width: 80,
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Avatar
            sx={{ width: "1.8rem", height: "1.8rem" }}
            src={API_URL.baseUrl + API_URL.onShotImage + params.value}
          />
        );
      },
    },

    {
      field: "city_name",
      headerName: "City Name",
      width: 120,
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },

    {
      field: "address",
      headerName: "Address",
      width: 260,
      flex: 1,
      minWidth: 260,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              maxLines: 3,
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },

    {
      field: "link",
      headerName: "Link",
      width: 90,
      flex: 1,
      minWidth: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box
            bgcolor="#CEE5FF"
            onClick={() => window.open(params.value)}
            sx={{
              cursor: "pointer",
              gap: ".4rem",
              width: "66px",
              height: "28px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <img
              src={require("../../../Assets/Link.png")}
              style={{
                objectFit: "fill",
                width: ".6rem",
              }}
              alt=""
            />
            <Typography fontSize="11px" fontWeight="500">
              LINK
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "link_expiry",
      headerName: "Link Date",
      width: 120,
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        try {
          return !params.value ? (
            "-"
          ) : (
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                {format(Date.parse(params.value), "dd-MMM-yyyy")}
              </Typography>
            </Box>
          );
        } catch (e) {
          return "-";
        }
      },
    },
    {
      field: "date",
      headerName: "OnSpot Date",
      width: 120,
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            {format(Date.parse(params.value), "dd-MMM-yyyy")}
          </Typography>
        );
      },
    },
    {
      field: "from_time",
      headerName: "From Time",
      width: 100,
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            {format(Date.parse("2021-02-02" + " " + params.value), "hh:mm a")}
          </Typography>
        );
      },
    },
    {
      field: "to_time",
      headerName: "To Time",
      width: 100,
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            {format(Date.parse("2021-02-02" + " " + params.value), "hh:mm a")}
          </Typography>
        );
      },
    },
    {
      field: "add_link",
      headerName: "Add Link",
      width: 100,
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            variant="text"
            sx={{
              width: "68px",
              height: "29px",
              bgcolor: "#FFD393",
              color: "black",
              "&:hover": {
                bgcolor: "#FFD393",
              },
              borderRadius: "6px",
            }}
            onClick={() => handleLinkOpen(params.id)}
          >
            <Typography fontSize="12.2px" textTransform="none">
              Add Link
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={null}
          title={params.value}
          onEdit={() => ""}
          // onEdit={onEdit}
        />
      ),
    },
  ];

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    const c = citiesW.filter((v) => v.name == value);
    setSelectedId(c.length ? c[0].id : 0);
    await getData(c.length ? c[0].id : 0, 0, typeId);
  };

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: ".8rem",
          marginBottom: "16px",
        }}
      >
        {/*Multi-Selection 1*/}
        <MultiSelection
          size="small"
          selected={selectedType}
          handleChange={handleSelectType}
          names={type}
          lable="Filter"
          multiple={false}
        />
        {/*Multi-Selection 2*/}
        <MultiSelection
          multiple={false}
          names={cities}
          handleChange={handleSelectCity}
          selected={selectedCities}
          lable="Cities"
        />

        <Button
          startIcon={
            <img
              src={require("../../../Assets/add-button.png")}
              width="13px"
              alt=""
            />
          }
          onClick={() => setOpen(true)}
          sx={{
            height: "2.3rem",
            borderRadius: "5px",
            textTransform: "none",
            bgcolor: "#F8AF41",
            color: "black",
            "&:hover": {
              bgcolor: "rgb(173, 123, 44)",
            },
            fontSize: ".8rem",
            paddingX: "1rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
            }}
          >
            Add
          </Typography>
        </Button>
      </Box>
      <AddOnSpotModal open={open} setOpen={setOpen} />
      <DataGridCover
        title="OnSpot Free List"
        component={
          <DataGrideView
            callBack={callBack}
            isLoading={isLoading}
            rows={rows}
            columns={columns}
            colHeight="4rem"
            sx={{ height: "85vh" }}
          />
        }
      />
      {/* <Fab
        style={{
          bottom: 80,
          right: 50,
          position: "absolute",
        }}
        onClick={handleOpen}
      >
        <Add />
      </Fab>
      {open ? (
        <AddOnShotDailog
          row={selectedRow}
          cities={cities}
          citiesW={citiesW}
          open={open}
          id={selectedRow ? selectedRow.id : 0}
          src={selectedRow ? selectedRow?.logo ?? "" : ""}
          handleOnClose={handleOnClose}
        />
      ) : null}
      {openLink ? (
        <AddOnShotLinkDailog
          handleOnClose={handleLinkClose}
          row={selectedRow}
          id={selectedRow.id}
          open={openLink}
        />
      ) : null} */}
    </Box>
  );
}

export default OnShot;
