import React from "react";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
 
} from "@mui/material";
import { BsChevronDown } from "react-icons/bs";

const SelectSmall = ({
  multiple = false,
  label,
  name,
  value,
  onChange,
  menuItems,
  defaultValue,
  sx
}) => {
  return (
    <Box
      width="14rem"
      height="3.8rem"
      bgcolor="#F6F6F6"
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY="4.2px"
      gap="4px"
      sx={sx}
    >
      <Typography width="95%" fontSize="11.8px">
        {label}
      </Typography>

      <Box sx={{ width: "95%", height: "36px" }}>
        <FormControl
          sx={{
            width: "100%",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            "& fieldset": { border: "none" },
            "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
              {
                fontSize: "0.750rem",
              },
            "& .css-3qbkez-MuiSelect-icon": {
              width: "0.688rem",
            },
            "& .css-10q54uo-MuiSelect-icon": {
              width: "0.688rem",
            },
            "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                paddingX: "9px",
              },
          }}
        >
          <Select
            name={name}
            labelId="demo-multiple-checkbox-label"
            size="small"
            id="demo-multiple-checkbox"
            label="Select City"
            displayEmpty
            multiple={multiple}
            value={value}
            onChange={onChange}
            input={<OutlinedInput label={"Categories"} />}
            style={{
              height: "1.9rem",
              backgroundColor: "white",
              "& fieldset": { border: "none" },
              color: "#c2c0c0",
              borderRadius: "6px",
            }}
            IconComponent={BsChevronDown}
          >
            <MenuItem value="" disabled>
              <p>{defaultValue}</p>
            </MenuItem>
            {menuItems.map((item) => (
              <MenuItem key={item} value={item}>
                <Typography fontSize="0.750rem">{item}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SelectSmall;
