import { API_URL, GETAPI, POSTAPI } from "../config";

export const ADD_UPDATE_ANNOUNCEMENT = async (data) => {
  const formData = new FormData();

  // console.log("order_ids",order_ids)

  if (data.photoOne) {
    formData.append("photo_1", data.photoOne, "photo_1.png");
  }

  if (data.photoTwo) {
    formData.append("photo_2", data.photoTwo, "photo_2.png");
  }

  if (data.row) {
    formData.append("id", data.row.id);
  }

  formData.append("name", data.name);
  formData.append("description", data.description);

  const res = await POSTAPI(
    data.row?.id ? API_URL.webUpdateAnnouncement : API_URL.webAnnouncement,
    formData
  );
  return res;
};

export const GET_ANNOUNCEMENT = async () => {
  const res = await GETAPI(API_URL.webGetAnnouncements);
  return res;
};

export const DELETE_ANNOUNCEMENT = async (id) => {
  const res = await GETAPI(API_URL.webDeleteAnnouncements, "&id=" + id);
  return res;
};
