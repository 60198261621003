import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AvgRate from "./AvgRate";
import AvgWork from "./AvgWork";
import TotalWork from "./TotalWork";
import TotalEarn from "./TotalEarn";
import TotalShoots from "./Shoots";
import TotalShootsByHours from "./ShootByHours";
import RecievedByCategory from "./RecievedByCategory";
import DeliveredByCategory from "./DeliveredByCategory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Reports() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexGrow: 1,
        bgcolor: "background.paper",
        boxShadow: "3px 3px 7px 0px #00000040",
      }}
    >
      <Tabs
        orientation="vertical"
        // variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        textColor="inherit"
        centered={false}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          backgroundColor: "#fff",
          width: "250px",
        }}
      >
        <Tab
          label="Average Rate"
          sx={{
            color: value == 0 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            textAlign: "start",

            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(0)}
        />
        <Tab
          label="Average Work"
          sx={{
            color: value == 1 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            textAlign: "start",

            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(1)}
        />
        <Tab
          label="Total Work"
          sx={{
            color: value == 2 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            textAlign: "start",

            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(2)}
        />
        <Tab
          label="Total Earn"
          sx={{
            color: value == 3 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(3)}
        />
         <Tab
          label="Total Shoots"
          sx={{
            color: value == 4 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(4)}
        />
         <Tab
          label="Shoot By Hours"
          sx={{
            color: value == 5 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(5)}
        />

        <Tab
          label="Recieved By Category"
          sx={{
            color: value == 6 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(6)}
        />
         <Tab
          label="Delivered By Category"
          sx={{
            color: value == 7 ? "#F8B03F" : "#000000de",
            fontWeight: "bold",
            textTransform: "none",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
          {...a11yProps(7)}
        />
      </Tabs>
      
      <TabPanel value={value} index={0}>
        <AvgRate />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AvgWork />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TotalWork />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TotalEarn />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TotalShoots />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TotalShootsByHours />
      </TabPanel>

      <TabPanel value={value} index={6}>
        <RecievedByCategory />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <DeliveredByCategory />
      </TabPanel>
    </Box>
  );
}
