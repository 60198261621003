import { Box, Paper, Typography, InputLabel, TextField } from "@mui/material";
import React from "react";
import TextFieldView from "../../../Component/TextField/TextFieldView";
import LoaderButtonView from "../../../Component/Button/LoaderButton";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { API_SEND_OTP } from "../../../Apis/auth_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import { useEffect } from "react";
import { UserContext } from "../../../Context/AuthContext";
import Styles from "../.././../Modules/Banner.module.css";

function MobileAuth() {
  const [mobile, setMobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();
  const { state } = useLocation();
  const auth = UserContext();

  useEffect(() => {
    const data = localStorage.getItem("user");

    if (data) {
      // console.log(JSON.parse(data));
      auth.login(JSON.parse(data));
      if (auth.isLogin) {
        auth.logout();
        // console.log(JSON.parse(data));
        navigator("/", { replace: true });
      } else {
        // console.log(JSON.parse(data));
        navigator(state ? state.path : "/dashboard", { replace: true });
      }
    }
  }, []);

  const handleOTP = async () => {
    if (!mobile) {
      ToastShow("Please enter mobile number");
    } else {
      if (mobile.length === 10) {
        localStorage.setItem("mobileNumber", mobile);
        setIsLoading(true);
        const res = await API_SEND_OTP({ mobile: "+91" + mobile });
        setIsLoading(false);
        if (res && res.s == 1) {
          navigator("/otp-verify", {
            state: { otp_id: res.r.otp_id, path: state ? state.path : null },
          });
        } else {
          ToastShow("Something went wrong. Please try again");
        }
      } else {
        ToastShow("Please enter valid mobile number");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box className={Styles.banner}></Box>

      <Paper
        elevation={0}
        sx={{
          width: "40vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "600",
                color: "#FAAF40",
                fontSize: "40px",
              }}
            >
              Login
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "18px",
                letterSpacing: "0.02em",
                color: "#787878",
              }}
            >
              Enter your phone number to Login
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              my: "1.8rem",
              width: "30rem",
            }}
          >
            <InputLabel
              sx={{
                marginBottom: ".7rem",
                fontSize: "18px",
                color: "#AAAAAA",
              }}
            >
              Phone no.
            </InputLabel>

            <TextFieldView
              width="548px"
              value={mobile}
              onchange={(v) => setMobileNumber(v)}
              type="number"
              placeholder="Enter Phone No."
              InputLabelProps={{
                shrink: false,
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleOTP();
                }
              }}
            />
          </Box>
          <LoaderButtonView
            loading={isLoading}
            sx={{ width: "548px", height: "50px" }}
            lable={"Send OTP"}
            fontWeight="bold"
            fontSize="19px"
            onClick={handleOTP}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default MobileAuth;
