import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const TimerOtp = ({ timer, setTimer, setIsResending }) => {
  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setIsResending(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  // Helper function to format time as double digits (e.g., 09 instead of 9)
  const formatTime = (seconds) => {
    return seconds < 10 ? `0${seconds}` : seconds;
  };

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  return (
    <Typography color="#AAAAAA" fontSize="16px">
      {timer > 0 ? `0${minutes}:${formatTime(seconds)}` : `OTP expired`}
    </Typography>
  );
};

export default TimerOtp;
