// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { ToastShow } from "../Component/Toast/Toast";
import { getFirestore } from "firebase/firestore";
import addNotification from "react-push-notification";
import { devlopment_mode } from "./Constant";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = devlopment_mode
  ? {
      apiKey: "AIzaSyDzq5BdzlsMUGHdku73eaniaFVy8cpnmAQ",
      authDomain: "pod-dev-b2783.firebaseapp.com",
      projectId: "pod-dev-b2783",
      storageBucket: "pod-dev-b2783.appspot.com",
      messagingSenderId: "68936025712",
      appId: "1:68936025712:web:0e7649977735b6e2d5d6b5",
    }
  : {
      apiKey: "AIzaSyBhFHd1hoHhh64-q74CJophVibJza2Q3LM",
      authDomain: "pod-equity.firebaseapp.com",
      projectId: "pod-equity",
      storageBucket: "pod-equity.appspot.com",
      messagingSenderId: "674118162952",
      appId: "1:674118162952:web:1529ab6cd13b6bc4bb8fbd",
      measurementId: "G-CFH3D2YJHC",
    };

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

const messaging = getMessaging(app);

export const storage = getStorage(app);
const publicKey =
  "BMSnbB4ednUDX4shmpVBJJfNINkHkBejINW0-ry0jSwLZLnqqvvSlLBqlm6QB89-wSLFSaSRz3Sv1zj7eLF_yCE";

//"yveOvu9AebFV6o4Vw-lG2e509Y2iJjNibo1ApsLlbf8"

export const getTokens = async () => {
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, {
      vapidKey: publicKey,
    });
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
    return null;
  }
  // console.log("Token", currentToken);
  return currentToken;
};

export const onMessageListener = () =>
  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    addNotification({
      title: "Notification",
      subtitle: payload.notification.title,
      message: payload.notification.body,
      theme: "light",
      native: true, // when using native, your OS will handle theming.
    });
    // ToastShow(
    //   "Title : " +
    //     payload.notification.title +
    //     ", Body : " +
    //     payload.notification.body
    // );
  });
