import { Box, Typography } from "@mui/material";
import React from "react";
import { dateFormateMMDDYYY } from "../../Service/DateSevice";

function BOD({
  date,
  sxBox = {},
  fontSx = {
    fontSize: "12.8px",
    textAlign: "center",
  },
}) {
  return (
    <Box
      sx={{
        ...{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        },
        ...sxBox,
      }}
    >
      <Typography sx={{ ...fontSx }}>
        {date ? dateFormateMMDDYYY(date) : "-"}
      </Typography>
    </Box>
  );
}

export default BOD;
