import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Fab, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import {
  ADD_UPDATE_SERVICE,
  DELETECATEGORYSERVICE,
  GETCATEGORYDETAILS,
  GETCATEGORYSERVICETEMPLATE,
} from "../../../Apis/category_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import SplitButton from "../../../Component/Button/SplitButton";
import AddServiceDailog from "../../../Component/Dailog/AddService";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import AlertToast from "../../../Component/Toast/AlertToast";
import AddServiceModal from "../Category/AddServiceModal";

function ServiceTemplate() {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [selectdRow, setSelectedRows] = React.useState(null);

  const [rowsForPre, setRowsForPre] = useState([]);
  const [rowsForPost, setRowsForPost] = useState([]);
  const [value, setValue] = React.useState(0);

  const [openToast, setOpenToast] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalCid, setModalCid] = useState(false);

  const addService = async (data) => {
    let ids = [];
    if (data.roles.length) {
      data.roles.map((v) => {
        if (v.status) {
          ids.push(v.id);
        }
      });
    }

    const res = await ADD_UPDATE_SERVICE({
      name: data.name,
      description: data.description,
      price: data.price,
      roleIds: ids.join(","),
      isExtra: data.isExtra,
      isFixed: data.isFixed,
      isPerHour: data.isPerHour,
      isPost: data.isPost,
      isQty: data.isQty,
      cId: id,
      isInventory: data.isInventory,
      isOnSite: data.isOnSite,
    });

    if (res && res.s) {
      ToastShow("Add successfully");
    } else {
      ToastShow("Something went wrong. Please try again.");
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const data = await GETCATEGORYSERVICETEMPLATE();
    if (data && data.r) {
      // setRows([...data.r]);

      //Main Service
      const objectsWithIsFix = data.r.filter(
        (obj) => obj.isFixed === 1 && obj.isExtra === 0
      );
      if (objectsWithIsFix.length > 0) {
        setRows((prevState) => [...prevState, ...objectsWithIsFix]);
      }

      //Pre Add Ons
      const objectsWithIsExtra = data.r.filter(
        (obj) => obj.isExtra === 1 && obj.isFixed === 0
      );
      if (objectsWithIsExtra.length > 0) {
        setRowsForPre((prevState) => [...prevState, ...objectsWithIsExtra]);
      }

      //Post Add Ons
      const objectsWithIsPost = data.r.filter(
        (obj) => obj.isExtra === 0 && obj.isFixed === 0
      );
      if (objectsWithIsPost.length) {
        setRowsForPost((prevState) => [...prevState, ...objectsWithIsPost]);
      }
    }
    setIsLoading(false);
  };

  const handleOnClose = (p) => {
    setOpen(false);
    if (p) {
      setRows([]);
      setRowsForPre([]);
      setRowsForPost([]);
      getData();
    }
  };

  const onEdit = (v) => {
    setValue(0);
    let t = rows.filter((j) => j.id == v);

    setSelectedRows(t[0]);
    setOpen(true);
  };
  const onEditPre = (v) => {
    setValue(1);
    let t = rowsForPre.filter((j) => j.id == v);

    setSelectedRows(t[0]);
    setOpen(true);
  };
  const onEditPost = (v) => {
    setValue(2);

    let t = rowsForPost.filter((j) => j.id == v);

    setSelectedRows(t[0]);
    setOpen(true);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const onDelete = async (id) => {
    const r = await DELETECATEGORYSERVICE(id);
    if (r && r.s) {
      const d = rows.filter((v) => v.id != id);
      const p = rowsForPre.filter((v) => v.id != id);
      const t = rowsForPost.filter((v) => v.id != id);

      setRows([...d]);
      setRowsForPre([...p]);
      setRowsForPost([...t]);
      ToastShow("Deleted successfully");
    } else {
      ToastShow("Opps something went wrong. Please try again.");
    }
  };

  const handleOpenConfirmation = (id) => {
    setSelectedId(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETECATEGORYSERVICE(selectedId);
      if (r && r.s) {
        const d = rows.filter((v) => v.id != id);
        const p = rowsForPre.filter((v) => v.id != id);
        const t = rowsForPost.filter((v) => v.id != id);

        setRows([...d]);
        setRowsForPre([...p]);
        setRowsForPost([...t]);
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedId(0);
    setOpenConfirmation(false);
  };

  const handleOpen = (newValue) => {
    setValue(newValue);
    setSelectedRows(null);
    setOpen(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const columnsForRows = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 240,
      minWidth: 240,
      flex: 1,
      headerAlign: "start",
      align: "start",
    },
    {
      field: "price",
      headerName: "Price",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 170,
      minWidth: 170,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <p>
            {params.row.isFixed
              ? "Main Service"
              : params.row.isExtra
              ? "Pre Add Ons"
              : "Post Add Ons"}
          </p>
        );
      },
    },
    {
      field: "Per/Hr",
      headerName: "Per Hours",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "Qty",
      headerName: "Per Qty",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isOnSite",
      headerName: "On Site",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isInventory",
      headerName: "Inventory",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        return (
          <SplitButton
            key={params.id}
            dataId={params.id}
            isDelete={1}
            onDelete={() => {
              onDelete(params.id);
            }}
            isView={null}
            title={params.value}
            onEdit={onEdit}
            isEdit={1}
          />
        );
      },
      /*OLD CODE*/
      // id ? (
      //   <Button
      //     onClick={() => {
      //       addService(params.row);
      //     }}
      //   >
      //     Add
      //   </Button>
      // ) : (
      //   <SplitButton
      //     key={params.id}
      //     dataId={params.id}
      //     isDelete={1}
      //     onDelete={onDelete}
      //     isView={null}
      //     title={params.value}
      //     onEdit={onEdit}
      //   />
      // ),
    },
  ];
  const columnsForPre = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 240,
      minWidth: 240,
      flex: 1,
      headerAlign: "start",
      align: "start",
    },
    {
      field: "price",
      headerName: "Price",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 170,
      minWidth: 170,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <p>
            {params.row.isFixed
              ? "Main Service"
              : params.row.isExtra
              ? "Pre Add Ons"
              : "Post Add Ons"}
          </p>
        );
      },
    },
    {
      field: "Per/Hr",
      headerName: "Per Hours",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "Qty",
      headerName: "Per Qty",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isOnSite",
      headerName: "On Site",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isInventory",
      headerName: "Inventory",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        return (
          <SplitButton
            key={params.id}
            dataId={params.id}
            isDelete={1}
            onDelete={() => {
              onDelete(params.id);
            }}
            isView={null}
            title={params.value}
            onEdit={onEditPre}
            isEdit={1}
          />
        );
      },
    },
  ];

  const columnsForPost = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 240,
      minWidth: 240,
      flex: 1,
      headerAlign: "start",
      align: "start",
    },
    {
      field: "price",
      headerName: "Price",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 170,
      minWidth: 170,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <p>
            {params.row.isFixed
              ? "Main Service"
              : params.row.isExtra
              ? "Pre Add Ons"
              : "Post Add Ons"}
          </p>
        );
      },
    },
    {
      field: "Per/Hr",
      headerName: "Per Hours",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "Qty",
      headerName: "Per Qty",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isOnSite",
      headerName: "On Site",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "isInventory",
      headerName: "Inventory",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <p>{params.value ? "Yes" : "No"}</p>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        return (
          <SplitButton
            key={params.id}
            dataId={params.id}
            isDelete={1}
            onDelete={() => {
              onDelete(params.id);
            }}
            isView={null}
            isEdit={1}
            title={params.value}
            onEdit={onEditPost}
          />
        );
      },
    },
  ];

  return (
    <>
      <Stack gap="1rem">
        <DataGridCover
          title="Main Service Templates"
          component={
            <DataGrideView
              key="0"
              rows={rows}
              columns={columnsForRows}
              colHeight={"2.8rem"}
              columnColor="#F6F6F6"
              sx={{ height: "60vh" }}
            />
          }
          enableButton={true}
          buttonLabel="Add Main Service"
          onClick={() => handleOpen(0)}
        />

        <DataGridCover
          title="Pre Add On's"
          component={
            <DataGrideView
              key="1"
              rows={rowsForPre}
              columns={columnsForPre}
              colHeight={"2.8rem"}
              columnColor="#F6F6F6"
              sx={{ height: "60vh" }}
            />
          }
          enableButton={true}
          buttonLabel="Add Pre Add Ons"
          onClick={() => handleOpen(1)}
        />

        <DataGridCover
          title="Post Add On's"
          component={
            <DataGrideView
              key="2"
              rows={rowsForPost}
              columns={columnsForPost}
              colHeight={"2.8rem"}
              columnColor="#F6F6F6"
              sx={{ height: "60vh" }}
            />
          }
          enableButton={true}
          buttonLabel="Add Post Add Ons"
          onClick={() => handleOpen(2)}
        />
      </Stack>

      {open && (
        <AddServiceDailog
          service={value}
          open={open}
          handleOnClose={handleOnClose}
          row={selectdRow}
          cId={id}
        />
      )}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete service !"
          content="Are you sure, You want to delete the service?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default ServiceTemplate;
