import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Box,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Datetimepicker_calendar from "../../Assets/Icons for pages/Datetimepicker_calendar.png";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";

const DateTime = ({
  onlyDate = false,
  label,
  width = "280px",
  height = "69px",
  bgColor,
  inputHeight = "1.9rem",
  value = null,
  onChange = (v) => {},
  minDateTime = Date.now(),
  fieldBgColor = "#F6F6F6",
  noHeight = false,
  paddingY = "4.2px",
  isPlaceholder = true,
}) => {
  const CustomOpenPickerButton = React.forwardRef(({ value, onClick }, ref) => (
    <img
      src={Datetimepicker_calendar}
      alt=""
      onClick={onClick}
      ref={ref}
      style={{ cursor: "pointer", width: "13px", objectFit: "fill" }}
    />
  ));

  // Function to format the date in "dd/MM/yyyy" format
  const formatDate = (date) => {
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  };

  return (
    <Box
      width={width}
      height={noHeight ? null : height}
      bgcolor={bgColor}
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY={paddingY}
      gap="4px"
    >
      <Typography width="95%" fontSize="11.8px" sx={{ userSelect: "none" }}>
        {label}
      </Typography>

      <Box sx={{ width: "96%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {!onlyDate ? (
            <DateTimePicker
              sx={{
                height: "1.9rem",
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label={isPlaceholder ? "Select Date - Time" : ""}
                  sx={{
                    overflow: "none",
                    width: "100%",
                    bgcolor: fieldBgColor,
                    borderRadius: "5px",
                    "& .css-j43tob-MuiInputBase-root-MuiOutlinedInput-root": {
                      height: inputHeight,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        fontSize: "0.750rem",
                        paddingX: "9px",
                      },
                  }}
                  size="small"
                  InputLabelProps={{
                    style: {
                      color: "#c2c0c0",
                      fontSize: "0.780rem",
                    },
                  }}
                />
              )}
              value={value}
              onChange={onChange}
              minDateTime={minDateTime}
              OpenPickerButtonProps={{
                component: CustomOpenPickerButton,
              }}
            />
          ) : (
            <DatePicker
              sx={{
                height: "1.9rem",
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label="Select date - time"
                  sx={{
                    overflow: "none",
                    width: "100%",
                    bgcolor: fieldBgColor,
                    borderRadius: "5px",
                    "& .css-j43tob-MuiInputBase-root-MuiOutlinedInput-root": {
                      height: inputHeight,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        fontSize: "0.750rem",
                        paddingX: "9px",
                      },
                  }}
                  size="small"
                  InputLabelProps={{
                    style: {
                      color: "#c2c0c0",
                      fontSize: "0.780rem",
                    },
                  }}
                />
              )}
              value={value}
              onChange={onChange}
              minDateTime={minDateTime}
              OpenPickerButtonProps={{
                component: CustomOpenPickerButton,
              }}
            />
          )}
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default DateTime;
