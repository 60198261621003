import React, { useEffect, useState } from "react";
import Add_click from "../../../../Assets/Icons for pages/Add_click.png";
import { Box, Button } from "@mui/material";

const HikePriceInput = ({ value, serviceID, handleAddHike }) => {
  const [input, setInput] = useState(value ?? "");

  // console.log(value,"price hike");

  useEffect(()=>{
    if(value){
      setInput(value);
    }
  },[value]);

  return (
    <Box
      sx={{
        bgcolor: "white",
        width: "10rem",
        py: "0.2rem",
        display: "flex",
        gap: "1.2rem",
      }}
    >
      <input
        placeholder="Enter hike price"
        style={{
          width: "80%",
          height: "100%",
          border: "none",
          outline: "none",
          color: "black",
          paddingLeft: "2px",
        }}
        value={input}
        onChange={(e) =>{
          setInput(e.target.value);
          handleAddHike(serviceID, e.target.value);
        } }
        // onKeyDown={(e) => {
        //   if (e.key === " ") {
        //     e.stopPropagation();
        //   } else if (e.key === "Enter") {
        //     setInput("");
        //   }
        // }}
      />
      {/* <Button
        sx={{
          // padding: "4px",
          color: "black",
          bgcolor: "#F8AF41",
          borderRadius: "4px",
          "&:hover": {
            bgcolor: "#F8AF41",
          },
          mr: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{
          minWidth: "0",
          padding: "0",
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
        onClick={() => handleAddHike(serviceID, input)}
      >
        <img
          src={Add_click}
          style={{ width: "13px", height: "12px", objectFit: "fill" }}
        />
      </Button> */}
    </Box>
  );
};

export default HikePriceInput;
