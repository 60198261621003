import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import CustomerExport from './CustomerExport';
import OrderExport from './OrderExport';
import ProviderExport from './ProviderExport';

function ExportHome() {
    const [alignment, setAlignment] = React.useState('customer');
    const components= {
        customer:<CustomerExport/> ,
        order:<OrderExport/>, 
        provider:<ProviderExport/> 
    }

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <Box>
        <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        >
        <ToggleButton value="customer">Customer</ToggleButton>
        <ToggleButton value="order">Order</ToggleButton>
        <ToggleButton value="provider">Provider</ToggleButton>
        </ToggleButtonGroup>

        {
            components[alignment]
        }


    </Box>
  )
}

export default ExportHome