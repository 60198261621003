import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_USERS_STORIES = async (count, id) => {
  const res = await GETAPI(
    API_URL.getUsersStories,
    "&city_id=" + id + "&count=" + count
  );
  return res;
};


export const ADD_USER_STORY = async (data) => {
  const formData = new FormData();

  if (data.file) {
    formData.append("photo", data.file, "file.png");
  }

  formData.append("city_id", data.city_id);
  formData.append("review", data.review);
  formData.append("name", data.name);
  formData.append("rate", data.rate);
  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updateUserStory : API_URL.addUserStory,
    formData
  );
  return res;
};

export const DELETE_USER_STORY = async (id) => {
  const res = await GETAPI(API_URL.deleteUserStory, "&id=" + id);
  return res;
};
