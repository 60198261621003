import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import tokens, { API_URL } from "../../../Apis/config";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { addDays, subDays, lastDayOfMonth, format } from "date-fns";
import { DateRangePicker, CustomProvider } from "rsuite";
import { useState } from "react";
import { useEffect } from "react";
import { GETROLE } from "../../../Apis/podian_api";
import { GET_CITIE } from "../../../Apis/city_api";

let names = [];
let podianRole = { r: [] };
function ProviderExport() {
  const type = ["All", "Active", "Pending", "Rejected"];
  const [selectedType, setSelectedType] = React.useState(["All"]);
  const [typeId, setTypeId] = React.useState("0,1");

  const [isLoadig, setIsLoading] = React.useState(true);

  const theme = useTheme();
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [dateRange, setDateRange] = useState({});
  const [selected, setSelected] = useState([]);

  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [cityId, setSelectedId] = React.useState("");


  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW(t.r);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
      setSelectedId(t.r[0].id);
      setSelectedCities([t.r[0].name]);
    }
  }

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([...value]);
    const c = citiesW.filter((v) => value.includes(v.name));
    const i = [];

    for(let d of c){
      i.push(d.id);
    }

    const r = i.join(",");
    setSelectedId(r);
  };

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
    } else {
      setDateRange(v);
    }
  };

  const geData = async () => {
    let query = "";

    let dd = "";

    if (selected.length) {
      dd = getRoleByValue(selected, podianRole);
    }

    if (dd) {
      query += "&roles=" + dd;
    }

    if (typeId) {
      query += "&status=" + typeId;
    }

    if (cityId) {
      query += "&city_id=" + cityId;
    }

    if (Object.keys(dateRange).length > 0) {
      query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
    }

    // console.log(query);


    window.open(
      API_URL.baseUrl +
        API_URL.reportProvider +
        "?apikey=" +
        tokens.apikey +
        "&token=" +
        tokens.token +
        query
    );
  };

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
    setTypeId(getValue(value));
  };

  const handleChange = async (e) => {
    const {
      target: { value },
    } = e;
    setSelected([...value]);
  };

  function getRoleByValue(v, t) {
    let q = "";
    for (let d of t.r) {
      if (v.includes(d.name)) {
        q += d.id + ",";
      }
    }

    if (q.length) {
      q = q.substring(0, q.length - 1);
    }

    return q;
  }

  const getValue = (v) => {
    let r = "1,0";
    if (v == "All") {
      r = "1,0";
    } else if (v == "Active") {
      r = "1";
    } else if (v == "Pending") {
      r = "0";
    } else {
      r = "-1";
    }

    return r;
  };

  async function getRoles() {
    setIsLoading(true);
    const d = await GETROLE();
    podianRole = d;
    names = [];

    for (let v of d.r) {
      names.push(v.name);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getRoles();
    getCities();
  }, []);

  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Box display="flex" alignItems="center" flexWrap="wrap" gap="10px">
        <MultiSelection
          size="small"
          selected={selectedType}
          handleChange={handleSelectType}
          names={type}
          lable={"Status"}
          multiple={false}
        />
        <MultiSelection
          selected={selected}
          handleChange={handleChange}
          names={names}
          lable={"Role"}
          multiple={true}
        />

      <MultiSelection
       
        names={cities}
        handleChange={handleSelectCity}
        selected={selectedCities}
        lable="Cities"
        multiple={true}
      />

        <CustomProvider theme={theme.palette.mode}>
          <DateRangePicker
            placement="bottomEnd"
            size="lg"
            onChange={(v) => handleSelectDate(v ?? {})}
            ranges={[
              {
                label: "Yesterday",
                value: [addDays(new Date(), -1), addDays(new Date(), -1)],
              },
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Tomorrow",
                value: [addDays(new Date(), 1), addDays(new Date(), 1)],
              },
              {
                label: "Last 7 days",
                value: [subDays(new Date(), 6), new Date()],
              },
              {
                label: "This week",
                value: [
                  subDays(
                    new Date(
                      `${firstdayW.getFullYear()}-${
                        firstdayW.getMonth() + 1
                      }-${firstdayW.getDate()}`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${lastdayW.getFullYear()}-${
                          lastdayW.getMonth() + 1
                        }-${lastdayW.getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This month",
                value: [
                  subDays(
                    new Date(
                      `${today.getFullYear()}-${today.getMonth() + 1}-01`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${today.getFullYear()}-${
                          today.getMonth() + 1
                        }-${lastDayOfMonth(today).getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This year",
                value: [
                  subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                  addDays(
                    new Date(new Date(`${today.getFullYear()}-12-31`)),
                    0
                  ),
                ],
              },
            ]}
          />
        </CustomProvider>
      </Box>
      <Button sx={{ ml: 1, mt: 2 }} variant="outlined" onClick={() => geData()}>
        Export
      </Button>
    </Box>
  );
}

export default ProviderExport;
