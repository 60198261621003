import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import ConfirmationDailog from "../../../../../Component/Dailog/ConfirmationDailog";
import { ToastShow } from "../../../../../Component/Toast/Toast";
import AddNewOnSpotPackage from "../../../../../Component/Dailog/AddNewOnSpotPackageDailog";
import { DELETEONSPOTNEWROLEPACKAGE } from "../../../../../Apis/new_on_spot_api";

function PackageOnSpot({ getData, id, row, on_spot_roles_id }) {
  const [open, setOpen] = React.useState(false);
  const [onSpotRolePackage, setOnSpotRolePackage] = React.useState(null);

  const handleOpen = (v = null) => {
    setOnSpotRolePackage(v);
    setOpen(true);
  };


  const handleOnClose = (v) => {
    if (v) {
      getData();
    }
    setOnSpotRolePackage(null);
    setOpen(false);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    setSelectedIdN(id);
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTNEWROLEPACKAGE({
        id: selectedId,
      });
      if (r && r.s) {
        getData();
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  return (
    <>
      <Box
        sx={{
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Button onClick={handleOpen}>Add Location</Button> */}
        </Box>

        {row?.length ? (
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              gap: "15px",
              p: 1,
              flexWrap: "wrap",
            }}
          >
            {row.map((v, index) => {
              return (
                <Paper
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    border: "1px solid #c0c0c0",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5">Package</Typography>
                    
                  </Box>
                  <Divider />
                  <FlexView title="Price" des={ `₹${v.price}`} />
                  <FlexView title="Qty" des={v.qty} />
                  

                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                    }}
                  >
                    <Button
                      onClick={() => handleOpen(v)}
                      variant="outlined"
                      sx={{
                        borderRadius: "0px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        flex: 1,
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => onDelete(v.id)}
                      variant="outlined"
                      sx={{
                        flex: 1,
                        borderRadius: "0px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      my: 1,
                    }}
                  >
                    {/* <Typography variant="h5">Zone</Typography> */}
                    {/* <Button onClick={() => {}}>Add Zone</Button> */}
                  </Box>
                 
                </Paper>
              );
            })}
          </Box>
        ) : (
          <Typography>
            Please add the package by the "ADD-PACKAGE" button
          </Typography>
        )}
      </Box>

      {open ? (
       <AddNewOnSpotPackage
       row={onSpotRolePackage}
       handleOnClose={handleOnClose}
       open={open}
       on_spot_roles_id={on_spot_roles_id}
     />
      ) : null}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete Package !"
          content="Are you sure, You want to delete the package?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default PackageOnSpot;

function FlexView({ title, des }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      <Typography
        sx={{
          width: "60px",
          fontWeight: "bold",
        }}
      >
        {title} :{" "}
      </Typography>
      <Typography>{des}</Typography>
    </Box>
  );
}
