import { Button, Typography } from "@mui/material";
import React from "react";

const ButtonIcon2 = ({
  label,
  height = "2.2rem",
  width = "auto",
  paddingX = "0.9rem",
  bgcolor = "#F8AF41",
  fontSize = "15px",
  onClick,
  icon,
  boxShadow = false,
  imgSize = "14px",
}) => {
  return (
    <Button
      sx={{
        width: width,
        height: height,
        backgroundColor: bgcolor,
        paddingX: paddingX,
        boxShadow: boxShadow && "3px 3px 8px 0px #00000040",
        gap: "0.5rem",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          bgcolor: "#a87d3b",
        },
      }}
      onClick={onClick}
    >
      <img
        src={icon}
        alt=""
        style={{ objectFit: "fill", width: imgSize, height: "13px" }}
      />
      <Typography
        textTransform="capitalize"
        fontSize={fontSize}
        fontWeight="500"
      >
        {label}
      </Typography>
    </Button>
  );
};

export default ButtonIcon2;
