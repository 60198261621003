import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_POLICY = async () => {
  const res = await GETAPI(API_URL.getPolicy);
  return res;
};

export const GET_POLICY_DETALS = async (id) => {
  const res = await GETAPI(API_URL.getPolicyDetails, "&id=" + id);
  return res;
};

export const UPDATE_POLICY_DETAILS = async (data) => {
  const formData = new FormData();
  formData.append("value", data.value);
  formData.append("id", data.id);
  const res = await POSTAPI(API_URL.updatePolicyDetails, formData);
  return res;
};
