import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { ADD_UPDATE_CITY } from "../../Apis/city_api";
import LocationSearchInput from "../AutoComplate/AddressAutoComplate";

import { ToastShow } from "../Toast/Toast";
import TextFieldSmall from "../TextFields/TextFieldSmall";
import { UPDATE_ORDER_ADDRESS } from "../../Apis/order_api";
// sx={{backdropFilter: "blur(1px)",}}
function AddAddressDailog({ open, handleOnClose, row }) {
  // console.log(chip)
  const [isLoading, setIsLoading] = React.useState(false);
  const [address, setAddress] = React.useState(row ? row.address : "");
  const [addressNr, setAddressNr] = React.useState(row.near_by_address ?? "");
  const [addressFlat, setAddressFlat] = React.useState(row.address1 ?? "");
  const [area, setArea] = React.useState(row?.area);
  const [latLng, setLatLng] = React.useState(
    row
      ? {
          lat: row.latitude,
          lng: row.longitude,
        }
      : {}
  );

  const updateArea = (results) => {
    try {
      const r = results[0].address_components
        .filter(
          (v) => v.types.includes("postal_code") || v.types.includes("locality")
        )
        .map((v) => v.long_name)
        .join(",");
      setArea(r ?? "");
    } catch (e) {}
  };

  const handleOnSubmit = async () => {
    if (!address) {
      ToastShow("Please fill address");
    } else {
      setIsLoading(true);
      const res = await UPDATE_ORDER_ADDRESS({
        latitude: latLng.lat,
        longitude: latLng.lng,
        address: address,
        orders_id: row.id,
        area: area,
        near_by_address: addressNr,
        address1: addressFlat,
      });
      setIsLoading(false);
      if (res && res.s == 1) {
        ToastShow("Success");
        handleOnClose(1);
      }else {
        ToastShow(res?.m ?? "Opps something went wrong");
      }
    }
  };

  const handleAddress = (address) => {
    // console.log(address);
    setAddress(address);
  };

  const updateLatLng = (latLngs) => {
    setLatLng(latLngs);
    // console.log("lat",latLng.lat, "lng",latLng.lng);
  };

  const handleAddressNr = (address) => {
    setAddressNr(address.target.value);
  };

  const handleAddressFlat = (address) => {
    setAddressFlat(address.target.value);
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>Update Address</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "350px", mt: 1 }}>
            <LocationSearchInput
              updateArea={updateArea}
              address={address}
              handleAddress={handleAddress}
              updateLatLng={updateLatLng}
            />
            <Box
              sx={{
                mt: 1,
              }}
            >
              <TextFieldSmall
                width="100%"
                label="Nr. Address"
                placeholder="Enter nr. address"
                name="nr_address"
                value={addressNr}
                onChange={handleAddressNr}
              />
            </Box>

            <Box
              sx={{
                my: 1,
              }}
            >
              <TextFieldSmall
                width="100%"
                label="Flat no./Floor/Society..."
                placeholder="Enter Flat no./Floor/Society..."
                name="flatNo_floor_society"
                value={addressFlat}
                onChange={handleAddressFlat}
              />
            </Box>
            {area?.length ?? 0 ? (
              <TextFieldSmall
                width="100%"
                label="Area"
                placeholder="Area"
                name="Area"
                // disableds
                // callBack={}
                value={area}
              />
            ) : null}
          </Box>
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleOnSubmit} variant="text">
                {row ? "Update" : "Submit"}
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddAddressDailog;
