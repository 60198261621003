import { API_URL, GETAPI, POSTAPI } from "./config";

export const ADD_PAGE = async (role, title, description, row, ordr) => {
  const formData = new FormData();
  formData.append("role", role);

  formData.append("title", title);

  formData.append("description", description);

  if (row) {
    formData.append("id", row.id);
  } else {
    formData.append("ordr", ordr);
  }

  const res = await POSTAPI(
    row ? API_URL.updatePage : API_URL.addPage,
    formData
  );
  return res;
};

export const GET_PAGE = async (role) => {
  const res = await GETAPI(API_URL.getPages, "&role=" + role);
  return res;
};

export const GET_PAGE_DELETE = async (id) => {
  const res = await GETAPI(API_URL.deletePage, "&id=" + id);
  return res;
};

export const CHANGE_ORDER_PAGE = async (data) => {
  const formData = new FormData();
  formData.append("pages", JSON.stringify(data));

  const res = await POSTAPI(API_URL.orderPages, formData);
  return res;
};

// -------------------------Question------------------------------

export const GET_PAGE_QUESTION = async (role_form_pages_id) => {
  const res = await GETAPI(
    API_URL.getQuestions,
    "&role_form_pages_id=" + role_form_pages_id
  );
  return res;
};

export const ADD_PAGE_QUESTION = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("hint", data.hint);
  formData.append("data_type", data.data_type);
  formData.append("data_type2", data.data_type2);
  formData.append("choices", JSON.stringify(data.choices));
  formData.append("is_required", data.is_required);
  formData.append("is_approval", data.is_approval);
  formData.append("is_see_customer", data.is_see_customer);
  formData.append("delimiter", data.delimiter);
  formData.append("role_form_pages_id", data.role_form_pages_id);

  if (data.id) {
    formData.append("id", data.id);
  } else {
    formData.append("ordr", data.ordr);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updateQuestions : API_URL.addQuestions,
    formData
  );
  return res;
};

export const GET_PAGE_QUESTION_DELETE = async (id) => {
  const res = await GETAPI(API_URL.deleteQuestions, "&id=" + id);
  return res;
};

export const CHANGE_ORDER_PAGE_QUESTION = async (data) => {
  const formData = new FormData();
  formData.append("questions", JSON.stringify(data));

  const res = await POSTAPI(API_URL.orderQuestions, formData);
  return res;
};
