import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import {
  getDetailsOfProviderToOrderItems,
} from "../../Apis/order_api";
import { useState } from "react";
import { Box } from "@mui/system";
import { API_URL } from "../../Apis/config";

let count = 0;
export default function OrderDailogPodian({
  open,
  handleClose,
  orderItemId,
}) {

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    setIsLoading(true);
    const d = await getDetailsOfProviderToOrderItems(orderItemId);
    setData(d.r ?? []);
    // await getOtherDetails();
    // if ((d.r ?? []).length) {
    //   for (let v of d.r) {
    //     if (v.status == 2) {
    //       handleSelected(v);
    //     }
    //   }
    // }
    setIsLoading(false);
  };


//   async function getOtherDetails(search = "", flag = 0) {
//     if (flag) {
//       const d = [];
//       setOtherData([...d]);
//     }

//     // console.log(dataOther.length,"count");
//     const d = await getDetailsOfOtherProviderToOrderItems(
//       orderItemId,
//       search,
//       count
//     );

//     count += (d.r ?? []).length;
//     setOtherData((p) => [...p, ...(d.r ?? [])]);

//     if ((d.r ?? []).length) {
//       await getOtherDetails(search);
//     }
//     return;
//   }



  useEffect(() => {
    count = 0;
    getData();
  }, []);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
       sx={{
        width:"100vw"
       }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems:"center"
          }}
        >
         <Box>
         {"Interested Provider"}
         {/* <Typography variant="subtitle2" sx={{fontSize:"12px"}}>{service}</Typography> */}
         </Box>
        
        </DialogTitle>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent
            sx={{
              height: "auto",
             
            }}
          >
            
            {data.length  ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InterestedView data={data} />
                
              </Box>
            ) : (
              <Box>
                <Typography>No data available</Typography>
              </Box>
            )}
          </DialogContent>
        )}
        <DialogActions>
         
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

   
    </>
  );
}


function InterestedView({ data }) {
  return !data.length ? null : (
    <Box>
     
      <DetailsView data={data}  />
    </Box>
  );
}

function DetailsView({ data }) {
  // console.log("data",data);
  return (
    <Grid container columns={3} columnGap={1} rowGap={1}>
      {data.map((v) => {
        return (
          <ItemView key={v.user_id} v={v}  />
        );
      })}
    </Grid>
  );
}

function ItemView({ v,  }) {
  return (
    <Paper
      component={"div"}
      elevation={0}
     
      sx={{
        overflow:"hidden",
        border: "solid",
        borderWidth: "1px",
        borderColor: "divider",
        display: "flex",
        flexDirection: "column",
        width: "100px",
        p: 2,
        alignItems: "center",
      }}
    >
      <Avatar
        sx={{ height: "40px", width: "40px" }}
        src={`${API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb}`}
      />
      <Typography
        variant="body1"
        sx={{ fontSize: "14px", textAlign: "center", wordWrap: "break-word" }}
      >
        {(v.first_name ?? "") + " " + (v.last_name ?? "")}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ fontSize: "10px", wordWrap: "break-word" }}
      >
        {v.mobile}
      </Typography>
     <Box
     sx={{
      width:"100%"
     }}
     >
     <Typography
        variant="subtitle2"
        sx={{ fontSize: "10px", wordWrap: "break-word", fontWeight:"bold" }}
      >
        {v.cities}
      </Typography>
     </Box>
    </Paper>
  );
}

