import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_PORTFOLIO = async (count, id) => {
  const res = await GETAPI(
    API_URL.getPortfolio,
    "&category_id=" + id + "&count=" + count
  );
  return res;
};


export const ADD_PORTFOLIO = async (data) => {
  const formData = new FormData();

  if (data.file) {
    formData.append("file", data.file,);
    formData.append("thumb", data.thumb);
  }

  formData.append("type", data.type,);
  formData.append("category_id", data.category_id);

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.updatePortfolio : API_URL.addPortfolio,
    formData
  );
  return res;
};

export const DELETE_PORTFOLIO = async (id) => {
  const res = await GETAPI(API_URL.deletePortfolio, "&id=" + id);
  return res;
};
