import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import ButtonForGrid from "../../../../../Component/Button/ButtonForGrid";
import {
  GETCUTOFFLOGS,
  UPDATEPROVIDERHIKE,
} from "../../../../../Apis/service_api";
import { ToastShow } from "../../../../../Component/Toast/Toast";
import UpdateModal from "./UpdateModal.jsx";
import CutOffLabel from "./CutOffLabel.jsx";
import LogsModal from "./LogsModal.jsx";

const CitiesCutOff = ({ city, citiesW }) => {
  const [selected, setSelected] = useState(city[0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [providerCityId, setProviderCityId] = useState(null);

  const [open, setOpen] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLogs = async (id) => {
    setLoading(true);
    const res = await GETCUTOFFLOGS(id);
    if (res && res.s) {
      setLogsData(res.r);
    } else {
      ToastShow(res.m);
    }
    setLoading(false);
  };

  const rows = selected?.roles?.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  const updateValue = async (v, min, max, id) => {
    if (!v) {
      ToastShow("Please enter providers cut-off!");
    } else if (v > 100) {
      ToastShow("Value cannot be greater than 100!");
    } else {
      const res = await UPDATEPROVIDERHIKE({
        provider_cities_id: id,
        provider_cut: v,
        min_amount: min,
        max_amount: max,
      });

      if (res && res.s) {
        window.location.reload();
      } else {
        ToastShow(res.m);
      }
    }
  };

  const cols = [
    {
      field: "id",
      headerName: "Id",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
    },
    {
      field: "city",
      headerName: "City",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{selected?.name}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.row.name}</Typography>;
      },
    },
    {
      field: "provider_cut",
      headerName: "Provider Cut-off",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Stack py="1rem">
            <CutOffLabel value={params.row.provider_cut} label="Cut-Off:" />
            <CutOffLabel value={params.row.min_amount} label="Min Amount:" />
            <CutOffLabel value={params.row.max_amount} label="Max Amount:" />
          </Stack>
        );
      },
    },
    {
      headerName: "Cut-Off Logs",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <ButtonForGrid
            label="View Log"
            onClick={() => {
              getLogs(params.row.provider_cities_id);
              setOpen(true);
            }}
          />
        );
      },
    },
    {
      field: "update",
      headerName: "Update",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <ButtonForGrid
            label="Update"
            onClick={() => {
              setProviderCityId(params.row.provider_cities_id);
              setOpenUpdate(true);
            }}
          />
        );
      },
    },
  ];

  const handleClose = () => {
    setOpenUpdate(false);
  };

  return (
    <>
      <Stack width="100%" gap={1}>
        {city.length ? (
          <Box
            width="35%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SelectCity
              city={city}
              value={selected}
              setSelected={setSelected}
            />
            <Box display="flex">
              <Typography fontSize="14px">Default Cut-Off - </Typography>
              <Typography fontSize="14px" fontWeight="600">
                {selected?.provider_cut}%
              </Typography>
            </Box>
          </Box>
        ) : null}

        <Box width="100%" boxShadow="3px 3px 7px 0px #00000040">
          <DataGrideView
            columns={cols}
            rows={rows ?? []}
            getRowId={(row) => row.name}
            autoHeight={true}
            colHeight="false"
          />
        </Box>
      </Stack>

      {openUpdate ? (
        <UpdateModal
          open={openUpdate}
          handleClose={handleClose}
          updateValue={updateValue}
          id={providerCityId}
        />
      ) : null}

      {open ? (
        <LogsModal
          open={open}
          handleClose={() => setOpen(false)}
          data={logsData}
          loading={loading}
        />
      ) : null}
    </>
  );
};

export default CitiesCutOff;

function SelectCity({ city, value, setSelected }) {
  const handleChange = (e) => {
    const newValue = e.target.value;
    setSelected(newValue);
  };

  return (
    <Box sx={{ minWidth: "20%" }}>
      <FormControl
        sx={{
          "& fieldset": { border: "none" },
          ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
            { padding: "0" },
          ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
            {
              fontSize: "14px",
              fontWeight: "500",
            },
        }}
      >
        <Select
          value={value}
          label="city"
          onChange={handleChange}
          renderValue={(value) => value.name}
        >
          {city.map((i) => (
            <MenuItem key={i.id} value={i}>
              <p style={{ fontSize: "14px" }}>{i.name}</p>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
