import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { CREATEUSER, GEBYMOBILENO } from "../../../Apis/booking_order_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import { CREATECOUPON } from "../../../Apis/coupen_api";
import { GET_CITIE } from "../../../Apis/city_api";
import { useEffect } from "react";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { format } from "date-fns";
import { Clear } from "@mui/icons-material";
import DateTime from "../../../Component/DateTime/DateTime";
import ButtonView from "../../../Component/Button/ButtonView";
import { GrClose } from "react-icons/gr";

function CoupenAdd({ row, handleOnClose, open }) {
  const initData = {
    isLoading: false,
    code: row ? row.code : "",
    minAmount: row ? row.min_amount : "",
    discount: row ? row.discount : "",
    maxAmount: row ? row.max_amount : "",
    applyLimit: row ? row.apply_limit : "",
    affluencerEarn: row ? row.affluence_earn : "",
    isaffluencerEarnPer: row ? row.is_affluence_earn_per : 2,
    isPer: row ? row.isPer : 2,
    startDate: row ? (row.start_dt ? new Date(row.start_dt) : null) : null,
    endDate: row ? (row.end_dt ? new Date(row.end_dt) : null) : null,
    isAffluenceUser: row ? (row.affluence_user_id ? 1 : 2) : 2,
    isAssignUser: row ? (row.assigned_user_id ? 1 : 2) : 2,

    idAffluenceUser: row ? row.affluence_user_id : 0,
    mobileAffluenceUser: "",
    firstNameAffluenceUser: "",
    lastNameAffluenceUser: "",
    middleNameAffluenceUser: "",
    emailAffluenceUser: "",

    idAssignUser: row ? row.assigned_user_id : 0,
    mobileAssignUser: "",
    firstNameAssignUser: "",
    lastNameAssignUser: "",
    middleNameAssignUser: "",
    emailAssignUser: "",

    cities: [],
    citiesW: [],
    cityId: "",
    selectedCities: ["None"],

    affluenceUser: null,
    assignUser: null,

    assignedUname: row ? (row.assigned_uname ? row.assigned_uname : "") : "",
    affluenceUname: row ? (row.affluence_uname ? row.affluence_uname : "") : "",
  };
  const [data, setData] = useState(initData);

  useEffect(() => {
    if (row) {
      const ids = [];
      const name = [];
      for (const id of row.cities) {
        if (id.status == 1) {
          ids.push(id.id);
          name.push(id.name);
        }
      }
      setData((p) => ({
        ...p,
        cityId: ids.length ? ids.join(",") : "",
        selectedCities: name.length ? name : ["None"],
      }));
    }
    getCities();
  }, []);

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      const c = [];
      c.push("None");
      for (const v of t.r) {
        c.push(v.name);
      }
      setData((p) => ({ ...p, cities: [...c], citiesW: [...t.r] }));
    }
  }

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    let c = [];
    let ids = [];
    if (value.length) {
      c = data.citiesW.filter((v) => value.some((j) => j == v.name));
      for (const i of c) {
        ids.push(i.id);
      }
      setData((p) => ({
        ...p,
        cityId: ids.join(","),
        selectedCities: value.filter((j) => j != "None"),
      }));
    } else {
      setData((p) => ({
        ...p,
        cityId: "",
        selectedCities: ["None"],
      }));
    }
    // if (value != "None") {
    //   c = data.citiesW.filter((v) => v.name == value);
    //   setData((p) => ({
    //     ...p,
    //     cityId: c.length ? c[0].id : 0,
    //     selectedCities: [value],
    //   }));
    // } else {
    //   setData((p) => ({ ...p, cityId: 0, selectedCities: [value] }));
    // }
  };

  const onChangeHandle = (e) => {
    //console.table(e.target.name,e.target.checked);

    if (
      e.target.name == "mobileAffluenceUser" ||
      e.target.name == "mobileAssignUser"
    ) {
      let v = "";
      try {
        if (parseInt(e.target.value)) {
          //  console.log(e.target.value.replace(".",""))
          v = parseInt(e.target.value.replace(".", ""));
        } else {
          // console.log(e.target.value.replace(".",""))
          v = "";
        }
      } catch (e) {
        //console.log("calkrr",e.target.value.replace(".",""))
        v = "";
      }

      setData((p) => ({ ...p, [e.target.name]: v }));
    } else {
      setData((p) => ({ ...p, [e.target.name]: e.target.value }));
    }
  };

  const onChackedHandle = (e) => {
    if (e.target.name == "isAffluenceUser" && !e.target.checked) {
      setData((p) => ({
        ...p,
        idAffluenceUser: 0,
        firstNameAffluenceUser: "",
        middleNameAffluenceUser: "",
        lastNameAffluenceUser: "",
        emailAffluenceUser: "",
        mobileAffluenceUser: "",
        affluenceUname: "",
      }));
    } else if (e.target.name == "isAssignUser" && !e.target.checked) {
      setData((p) => ({
        ...p,
        idAssignUser: 0,
        firstNameAssignUser: "",
        middleNameAssignUser: "",
        lastNameAssignUser: "",
        emailAssignUser: "",
        mobileAssignUser: "",
        assignedUname: "",
      }));
    }
    //console.table(e.target.name,e.target.checked);
    setData((p) => ({ ...p, [e.target.name]: e.target.checked ? 1 : 0 }));
  };

  const handleUserRag = async () => {
    if (!data.firstNameAffluenceUser) {
      ToastShow("Please fill first name");
    } else if (!data.middleNameAffluenceUser) {
      ToastShow("Please fill middle name");
    } else if (!data.lastNameAffluenceUser) {
      ToastShow("Please fill last name");
    } else if (!data.emailAffluenceUser) {
      ToastShow("Please fill email");
    } else {
      const res = await CREATEUSER({
        firstName: data.firstNameAffluenceUser,
        lastName: data.lastNameAffluenceUser,
        middleName: data.middleNameAffluenceUser,
        email: data.emailAffluenceUser,
        mobile: data.mobileAffluenceUser,
        gender: "Male",
      });

      if (res && res.s) {
        setData((p) => ({
          ...p,
          idAffluenceUser: res.r.id,
          affluenceUname: `${res.r.first_name} ${res.r.middle_name} ${res.r.last_name}`,
        }));
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  const getUser = async () => {
    if (!data.mobileAffluenceUser) {
      ToastShow("Please fill mobile number");
    } else {
      const res = await GEBYMOBILENO(data.mobileAffluenceUser);

      if (res && res.s) {
        setData((p) => ({
          ...p,
          idAffluenceUser: res.r.id,
          affluenceUname: `${res.r.first_name} ${res.r.middle_name} ${res.r.last_name}`,
        }));
        ToastShow("Success");
      } else {
        setData((p) => ({ ...p, idAffluenceUser: -1, affluenceUname: "" }));
      }
    }
  };

  const handleUserRagAssign = async () => {
    if (!data.firstNameAssignUser) {
      ToastShow("Please fill first name");
    } else if (!data.middleNameAssignUser) {
      ToastShow("Please fill middle name");
    } else if (!data.lastNameAssignUser) {
      ToastShow("Please fill last name");
    } else if (!data.emailAssignUser) {
      ToastShow("Please fill email");
    } else {
      const res = await CREATEUSER({
        firstName: data.firstNameAssignUser,
        lastName: data.lastNameAssignUser,
        middleName: data.middleNameAssignUser,
        email: data.emailAssignUser,
        mobile: data.mobileAssignUser,
        gender: "Male",
      });

      if (res && res.s) {
        setData((p) => ({
          ...p,
          idAssignUser: res.r.id,
          assignedUname: `${res.r.first_name} ${res.r.middle_name} ${res.r.last_name}`,
        }));
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  const getUserAssign = async () => {
    if (!data.mobileAssignUser) {
      ToastShow("Please fill mobile number");
    } else {
      const res = await GEBYMOBILENO(data.mobileAssignUser);

      if (res && res.s) {
        setData((p) => ({
          ...p,
          idAssignUser: res.r.id,
          assignedUname: `${res.r.first_name} ${res.r.middle_name} ${res.r.last_name}`,
        }));
        ToastShow("Success");
      } else {
        setData((p) => ({ ...p, idAssignUser: -1, assignedUname: "" }));
      }
    }
  };

  const submit = async () => {
    if (!data.code) {
      ToastShow("Please fill code");
      return;
    } else if (!data.discount) {
      ToastShow("Please fill discount");
      return;
    }

    const res = await CREATECOUPON({
      id: row ? row.id : 0,
      code: data.code,
      city_id: data.cityId,
      affluence_user_id: data.idAffluenceUser,
      assigned_user_id: data.idAssignUser,
      affluence_earn: data.affluencerEarn,
      is_affluence_earn_per: data.isaffluencerEarnPer,
      min_amount: data.minAmount,
      discount: data.discount,
      isPer: data.isPer,
      max_amount: data.maxAmount,
      start_dt: data.startDate ? format(data.startDate, "yyyy-MM-dd") : null,
      end_dt: data.endDate ? format(data.endDate, "yyyy-MM-dd") : null,
      apply_limit: data.applyLimit,
    });

    if (res && res.s) {
      ToastShow(res.m);

      setData(initData);
      handleOnClose();
    } else {
      ToastShow(res ? res.m : "Opps! something went wrong. Please try again.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setData(initData);
        handleOnClose(0);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(4px)",
        bgcolor: "rgba(105, 55, 0, 0.4)",
      }}
    >
      {data.isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            height: "80vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              height: "80vh",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: "10px",
              width: "400px",
              backgroundColor: "#fff",
              pt: 2.3,
              px: 3,
              borderRadius: "5px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                backgroundColor: "#F6F6F6",
                height: "44px",
                width: "100%",
                padding: ".6rem",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.150rem",
                fontWeight: "500",
              }}
            >
              Create Coupon
              <GrClose
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setData(initData);
                  handleOnClose(0);
                }}
              />
            </Typography>
            <TextFieldV
              name="code"
              label="Code*"
              onChange={onChangeHandle}
              value={data.code}
              type="text"
            />
            <TextFieldV
              name="affluencerEarn"
              label="Affluencer Earn"
              onChange={onChangeHandle}
              value={data.affluencerEarn}
              type="number"
            />
            <TextFieldV
              name="minAmount"
              label="Min Amount"
              onChange={onChangeHandle}
              value={data.minAmount}
              type="number"
            />
            <TextFieldV
              name="maxAmount"
              label="Max Amount"
              onChange={onChangeHandle}
              value={data.maxAmount}
              type="number"
            />
            <TextFieldV
              name="discount"
              label="Discount*"
              onChange={onChangeHandle}
              value={data.discount}
              type="number"
            />
            <TextFieldV
              name="applyLimit"
              label="Apply Limit"
              onChange={onChangeHandle}
              value={data.applyLimit}
              type="number"
            />

            <FormControlLabel
              label=" Is affluence user earn in percentage ?"
              control={
                <Checkbox
                  name="isaffluencerEarnPer"
                  checked={data.isaffluencerEarnPer == 1 ? true : false}
                  onChange={onChackedHandle}
                />
              }
            />
            <FormControlLabel
              label="Is this in percentage ?"
              control={
                <Checkbox
                  name="isPer"
                  checked={data.isPer == 1 ? true : false}
                  onChange={onChackedHandle}
                />
              }
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTime
                label="Start Date"
                width="22rem"
                // height="3.8rem"
                noHeight={true}
                paddingY="0.4rem"
                bgColor="#c0c0c02c"
                fieldBgColor="white"
                value={data.startDate}
                onChange={(v) => {
                  if (
                    data.endDate != null &&
                    v.getTime() > data.endDate.getTime()
                  ) {
                    ToastShow("Start date bigger than end date is not valid");
                    return;
                  }
                  setData((p) => ({ ...p, startDate: v }));
                }}
                renderInput={(params) => (
                  <TextField sx={{ m: 1.4 }} {...params} />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTime
                label="End Date"
                width="22rem"
                // height="3.8rem"
                value={data.endDate}
                bgColor="#c0c0c02c"
                fieldBgColor="white"
                noHeight={true}
                paddingY="0.4rem"
                onChange={(v) => {
                  if (
                    data.startDate != null &&
                    v.getTime() < data.startDate.getTime()
                  ) {
                    ToastShow("Start date bigger than end date is not valid");
                    return;
                  }
                  setData((p) => ({ ...p, endDate: v }));
                }}
              />
            </LocalizationProvider>
            <MultiSelection
              size="large"
              width={"100%"}
              multiple={true}
              names={data.cities}
              handleChange={handleSelectCity}
              selected={data.selectedCities}
              noIcon={true}
              lable="Cities"
              containerWidth="100%"
              subContainerWidth="95%"
              disableBoxShadow={true}
              bgcolor="#F6F6F6"
              paddingY="0.4rem"
            />

            <FormControlLabel
              label="Is this for affluence user?"
              control={
                <Checkbox
                  name="isAffluenceUser"
                  checked={data.isAffluenceUser == 1 ? true : false}
                  onChange={onChackedHandle}
                />
              }
            />

            {data.isAffluenceUser == 1 ? (
              <AffluenceUser
                onChangeHandle={onChangeHandle}
                data={data}
                getUser={getUser}
                handleUserRag={handleUserRag}
              />
            ) : null}

            <FormControlLabel
              label="is this assign to specific user?"
              control={
                <Checkbox
                  name="isAssignUser"
                  checked={data.isAssignUser == 1 ? true : false}
                  onChange={onChackedHandle}
                />
              }
            />
            {data.isAssignUser == 1 ? (
              <AssignUser
                onChangeHandle={onChangeHandle}
                data={data}
                getUser={getUserAssign}
                handleUserRag={handleUserRagAssign}
              />
            ) : null}

            <ButtonView
              lable={row ? "Update Coupon" : "Create Coupon"}
              fontSize="14px"
              fontWeight="500"
              sx={{ mt: 2, mb: 2, width: "100%" }}
              onClick={submit}
            />
          </Box>
        </Box>
      )}
    </Modal>
  );
}

export default CoupenAdd;

const TextFieldV = ({
  width = "14rem",
  height = "3.8rem",
  name,
  label,
  value,
  onChange,
  type,
  inputFieldWidth = "95%",
  bgColor,
  inputHeight = "1.9rem",
  isMobile = false,
}) => {
  return (
    <Box
      width="100%"
      // height={height}
      bgcolor={bgColor ? bgColor : "#F6F6F6"}
      borderRadius="6px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY="0.4rem"
      gap="4px"
    >
      <Typography width="95%" fontSize="11.8px" sx={{ userSelect: "none" }}>
        {label}
      </Typography>

      <Box display="flex" width="100%" alignItems="center" pl={1}>
        {isMobile ? (
          <input
            disabled
            label="Country Code"
            autoFocus
            // className={Styles.in}
            value="91"
            // onChange={handleCountryCode}
            maxLength={5}
            placeholder="91"
            style={{
              width: "20px",
              height: "21px",
              fontSize: "14.3px",
              fontWeight: "500",
              border: "none",
              outline: "none",
              color: "black",
              height: inputHeight,
              backgroundColor: bgColor ? "#EFEFEF" : "white",
              borderRadius: "5px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              paddingLeft: "5px",
            }}
          />
        ) : null}

        <TextField
          fullWidth
          name={name}
          placeholder={label}
          value={value}
          onChange={onChange}
          variant="outlined"
          type={type}
          sx={{
            width: inputFieldWidth,
            height: inputHeight,
            backgroundColor: bgColor ? "#EFEFEF" : "white",
            borderRadius: "5px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            "& fieldset": { border: "none" },
            "& .MuiFormLabel-root": {
              color: "#969696",
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "primary.main",
            },
            "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "0.750rem",
              paddingX: "9px",
            },
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "0.8rem",
            },
            // input: { color: "#969696" },
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </Box>
  );
};

function BoxWrap({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "0.6rem",
        mt: "0.7rem",
      }}
    >
      {children}
    </Box>
  );
}

function AffluenceUser({ data, onChangeHandle, getUser, handleUserRag }) {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TextFieldV
          isMobile={true}
          label="Mobile number"
          name="mobileAffluenceUser"
          value={data.mobileAffluenceUser}
          onChange={onChangeHandle}
        />

        <ButtonView
          lable="Submit"
          sx={{ width: "100%", mt: 1, height: "2.170rem" }}
          fontSize="14px"
          fontWeight="500"
          onClick={getUser}
        />
        {data.affluenceUname != "" ? (
          <Typography sx={{ mx: 0.5 }}>({data.affluenceUname})</Typography>
        ) : null}
      </Box>
      {data.idAffluenceUser == -1 ? (
        <BoxWrap>
          <TextFieldV
            label="First name"
            name="firstNameAffluenceUser"
            value={data.firstNameAffluenceUser}
            onChange={onChangeHandle}
          />
          <TextFieldV
            label="Last name"
            name="lastNameAffluenceUser"
            value={data.lastNameAffluenceUser}
            onChange={onChangeHandle}
          />
          <TextFieldV
            label="Middle name"
            name="middleNameAffluenceUser"
            value={data.middleNameAffluenceUser}
            onChange={onChangeHandle}
          />

          <TextFieldV
            label="Email"
            name="emailAffluenceUser"
            value={data.emailAffluenceUser}
            onChange={onChangeHandle}
          />
          <Button
            variant="outlined"
            size="medium"
            sx={{ height: "40px" }}
            onClick={handleUserRag}
          >
            Create User
          </Button>
        </BoxWrap>
      ) : null}
    </Box>
  );
}

function AssignUser({ data, onChangeHandle, getUser, handleUserRag }) {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TextFieldV
          isMobile={true}
          label="Mobile number"
          name="mobileAssignUser"
          value={data.mobileAssignUser}
          onChange={onChangeHandle}
        />
        <ButtonView
          lable="Submit"
          sx={{ width: "100%", mt: 1, height: "2.170rem" }}
          fontSize="14px"
          fontWeight="500"
          onClick={getUser}
        />

        {data.assignedUname != "" ? (
          <Typography sx={{ mx: 0.5 }}>({data.assignedUname})</Typography>
        ) : null}
      </Box>
      {data.idAssignUser == -1 ? (
        <BoxWrap>
          <TextFieldV
            label="First name"
            name="firstNameAssignUser"
            value={data.firstNameAssignUser}
            onChange={onChangeHandle}
          />
          <TextFieldV
            label="Last name"
            name="lastNameAssignUser"
            value={data.lastNameAssignUser}
            onChange={onChangeHandle}
          />
          <TextFieldV
            label="Middle name"
            name="middleNameAssignUser"
            value={data.middleNameAssignUser}
            onChange={onChangeHandle}
          />

          <TextFieldV
            label="Email"
            name="emailAssignUser"
            value={data.emailAssignUser}
            onChange={onChangeHandle}
          />
          <Button
            variant="outlined"
            size="medium"
            sx={{ height: "40px" }}
            onClick={handleUserRag}
          >
            Create User
          </Button>
        </BoxWrap>
      ) : null}
    </Box>
  );
}
