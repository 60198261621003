import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { useEffect } from "react";
import SplitButton from "../../../Component/Button/SplitButton";
import { useNavigate } from "react-router-dom";
import { ToastShow } from "../../../Component/Toast/Toast";
import {
  ACTIVATEDEACTIVATECOUPEN,
  DELETECOUPEN,
  GETCOUPEN,
} from "../../../Apis/coupen_api";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import AddCoupenModal from "./AddCoupenModal";
import SearchBox from "../../../Component/SearchBox/SearchBox";

export default function CoupenList() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const idRef = React.useRef(0);

  const [openModal, setOpenModal] = React.useState(false);

  const navigate = useNavigate();

  const onEdit = (id) => {
    const row = rows.filter((e) => e.id == id);
    navigate("/coupon/edit", { state: row[0] });
  };

  const onDelete = async (id) => {
    idRef.current = id;
    setOpen(true);
  };

  const handleOnClose = async (v) => {
    setOpen(false);

    if (v) {
      const r = await DELETECOUPEN(idRef.current);
      if (r && r.s) {
        const d = rows.filter((v) => v.id != idRef.current);
        setRows([...d]);
        ToastShow("Deleted successfully");
        // getData(0);
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    idRef.current = 0;
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
      flex: 1,
      minWidth: 60,
      maxWidth: 70,
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    // { field: "id", headerName: "ID", width: 50 },
    {
      field: "code",
      headerName: "Code",
      width: 100,
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "city_name",
      headerName: "City Name",
      width: 100,
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 90,
      flex: 1,
      minWidth: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "affluence_uname",
      headerName: "Influencer name",
      width: 120,
      flex: 1,
      midWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "12.5px",
            }}
          >
            {params.row.affluence_uname}
          </Typography>
        );
      },
    },
    {
      field: "assigned_uname",
      headerName: "Assigned name",
      width: 120,
      flex: 1,
      midWidth: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontSize: "12.5px",
            }}
          >
            {params.row.assigned_uname}
          </Typography>
        );
      },
    },
    // {
    //   field: "affluence_earn",
    //   headerName: "Influencer Earn",
    //   width: 100,
    //   midWidth: 100,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "apply_limit",
    //   headerName: "Apply limit",
    //   width: 100,
    //   midWidth: 100,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "start_dt",
      headerName: "Start Date",
      width: 110,
      flex: 1,
      midWidth: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    {
      field: "end_dt",
      headerName: "End Date",
      width: 110,
      flex: 1,
      midWidth: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <DateMDYHMA date={params.value} />;
      },
    },
    // {
    //   field: "isPer",
    //   headerName: "Is percentage?",
    //   width: 90,
    //   midWidth: 90,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return <Typography>{params.value == 1 ? "Yes" : "No"}</Typography>;
    //   },
    // },
    // {
    //   field: "is_affluence_earn_per",
    //   headerName: "Is affluence earn per.?",
    //   width: 90,
    //   midWidth: 90,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return <Typography>{params.value == 1 ? "Yes" : "No"}</Typography>;
    //   },
    // },
    // {
    //   field: "max_amount",
    //   headerName: "Max Amount",
    //   width: 90,
    //   midWidth: 90,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "min_amount",
    //   headerName: "Min Amount",
    //   width: 90,
    //   midWidth: 90,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 80,
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        // <Button
        //   onClick={() =>
        //     OnActivateDeactivate(
        //       params.row.id,
        //       params.row.status,
        //       params.api.getRowIndex(params.row.id)
        //     )
        //   }
        // >
        <Typography
          sx={{
            color: !params.row.status ? "#E50000" : "#0DA800",
            textTransform: "none",
            fontSize: "12px",
          }}
        >
          {!params.row.status ? "Inactive" : "Activate"}{" "}
        </Typography>
        // </Button>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 60,
      flex: 1,
      minWidth: 60,
      maxWidth: 70,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={onDelete}
          // onView={onView}
          isView={null}
          title={params.value}
          onEdit={onEdit}
          isEdit={1}
        />
      ),
    },

    {
      field: "make",
      headerName: "Change Status",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            OnActivateDeactivate(
              params.row.id,
              params.row.status,
              params.api.getRowIndex(params.row.id)
            )
          }
          sx={{
            borderColor: params.row.status ? "Red" : "Green",
            color: params.row.status ? "Red" : "Green",
          }}
        >
          {" "}
          {params.row.status ? "Inactive" : "Activate"}{" "}
        </Button>
      ),
    },
  ];

  const OnActivateDeactivate = async (id, status, index) => {
    const t = rows;
    t[index].status = status == 1 ? 0 : 1;
    setRows([...t]);
    await ACTIVATEDEACTIVATECOUPEN(status == 1 ? 0 : 1, id);
  };

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData(rows.length);
    }
  };

  async function getData(search, count) {
    if (!count) {
      setIsCall(true);
    }
    setIsLoading(true);
    const t = await GETCOUPEN(search, count);

    if (t && t.s == 1 && t.r) {
      // if (count == 0) {
      //   setRows([...t.r]);
      // } else {
      //   setRows((p) => [...p, ...t.r]);
      // }
      setRows(t?.r);
    } else {
      setIsCall(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData(search, 0);
  }, []);

  const handleOnCloseModal = () => {
    setOpenModal(false);
    getData(search, 0);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (!value) {
      getData(value, 0);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1rem"
      justifyContent="center"
    >
      <SearchBox
        search={search}
        onChange={handleSearch}
        onClick={() => {
          if (search) {
            getData(search, 0);
          } else {
            ToastShow("Please write something to search...");
          }
        }}
        cursor="pointer"
      />
      <Box
        sx={{
          overflow: "auto",
          width: "100%",
          backgroundColor: "white",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "3px 3px 7px 0px #00000040",
          paddingTop: "13.2px",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            width: "97.5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ color: "#454545", fontWeight: "500" }}>
            Coupon List
          </Typography>

          <Button
            startIcon={
              <img
                src={require("../../../Assets/add-button.png")}
                style={{ objectFit: "fill", width: "12px" }}
                alt=""
              />
            }
            onClick={() => setOpenModal(true)}
            sx={{
              height: "2rem",
              borderRadius: "5px",
              textTransform: "none",
              bgcolor: "#F8AF41",
              color: "black",
              "&:hover": {
                bgcolor: "rgb(173, 123, 44)",
              },
              paddingX: "1rem",
            }}
          >
            <Typography sx={{ fontWeight: "500", fontSize: ".8rem" }}>
              Create Coupons
            </Typography>
          </Button>
        </Box>

        <AddCoupenModal
          open={openModal}
          handleOnClose={handleOnCloseModal}
          row={null}
        />

        <Box width="97.5%">
          <DataGrideView
            callBack={callBack}
            isLoading={isLoading}
            rows={rows}
            columns={columns}
            colHeight="3.5rem"
            sx={{ height: "80vh" }}
          />
        </Box>
      </Box>

      <ConfirmationDailog
        open={open}
        handleOnClose={handleOnClose}
        content="Are you sure want to delete this coupon?"
        title="Delete coupon!"
      />
    </Box>
  );
}
