import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../../Toast/Toast";
import { ADD_UPDATE_WEB_PODIAN_PROCESS } from "../../../Apis/web/processPodian_api";

function AddUpdateWebPodianProcessDailog({ open, handleOnClose, row }) {
  // console.log(row)
  const [photo, setPhoto] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = React.useState(row ? row.name : "");
  const [details, setDetails] = React.useState(row ? row.details : "");
  const [sr, setSr] = React.useState(row ? row.sr : "");

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please enter name");
    } else if (!details) {
      ToastShow("Please enter details");
    } else if (!sr) {
      ToastShow("Please enter sr");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_WEB_PODIAN_PROCESS({
        row: row,
        name: name,
        details: details,
        sr: sr,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>
          {!row ? "Add Podian-Process" : "Update Podian-Process"}
        </DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            type="text"
            size="small"
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            type="text"
            size="small"
            fullWidth
            label="Details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            type="number"
            size="small"
            fullWidth
            label="Step"
            value={sr}
            onChange={(e) => setSr(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleOnSubmit}
              variant="text"
              sx={{ color: "#F8B03F" }}
            >
              Submit
            </Button>
          )}
          <Button
            onClick={() => handleOnClose(0)}
            variant="text"
            sx={{ color: "#F8B03F" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddUpdateWebPodianProcessDailog;
