import {
  Avatar,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ItemView({
  item,
  openD,
  changeParent = true,
  expandedIndex,
  setExpandedIndex,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  let addMore = {
    pl: 1.9,
  };

  if (!openD && changeParent == true) {
    addMore = {
      height: "30px",
      width: "25px",
      p: 0,
      ...(location.pathname == item.path && {
        justifyContent: "center",
        width: "40px",
      }),
    };
  }

  const handleChange = () => {
    setExpandedIndex(expandedIndex === item.id ? null : item.id);
  };
  return (
    <Box
      sx={{
        cursor: "pointer",
        height: "2.4rem",
        display: "flex",
        justifyContent: "center",
        "&:hover": {
          bgcolor: "#f8af4122",
        },
      }}
      onClick={() => {
        navigate(item.path);
        if (item.id.split(".").length === 1) {
          handleChange();
        }
      }}
      // expanded={expandedIndex === item.id}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          color: "#BEBEBE",
          border: "none",
          outline: "none",
          ...addMore,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Avatar
            sx={{
              height: "24px",
              width: "24px",
              bgcolor: "transparent",
            }}
          >
            {item.isImage == false ? item.icon : <img src={item.icon} alt="" />}
          </Avatar>

          {openD && (
            <Typography
              sx={{
                width: "100%",
                ml: 1,
                color: "#BEBEBE",
                fontSize: "15.7px",
              }}
            >
              {item.title}
            </Typography>
          )}
        </Box>

        {location.pathname == item.path && (
          <Box
            sx={{
              borderRadius: "50%",
              width: "8px",
              height: "8px",
              backgroundColor: "primary.main",
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export default ItemView;
