import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { GETHISTORY } from "../../Apis/service_api";
import { API_URL } from "../../Apis/config";
import { GrClose } from "react-icons/gr";

function ShowHistoryDailog({ handleClose, open, id }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    setIsLoading(true);
    const d = await GETHISTORY(id);

    if (d && d.s == 1 && d.r) {
      setData(d.r);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  //console.log("QA History", data);

  return (
    <Popover
      open={open}
      onClose={handleClose}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        zIndex: 1,
      }}
    >
      <Stack gap={2} width="22vw" height="99vh" m={2}>
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            sx={{ userSelect: "none" }}
          >
            History
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : data == null ? (
          <Box
            sx={{
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography text>No History available</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.2,
            }}
          >
            {data.map((v) => {
              let j;
              try {
                j =
                  v.answer2 != null && v.answer2 !== ""
                    ? JSON.parse(v.answer2)
                    : [];
              } catch (error) {
                console.error("Error parsing JSON:", error);
                j = [];
              }
              return (
                <Box
                  key={v.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 0.9,
                    }}
                  >
                    <Typography sx={{ fontWeight: "w700", color: "#FAAF40" }}>
                      {v?.name}
                    </Typography>
                  </Box>

                  <Stack
                    borderTop="0.7px solid #D7D7D7"
                    borderBottom="0.7px solid #D7D7D7"
                    py={1}
                  >
                    <Typography color="#101010CC" fontSize="15px">
                      {v?.created_at}
                    </Typography>

                    <Typography
                      sx={{
                        fontWeight: "500",
                        textTransform: "capitalize",
                        my: 0.6,
                      }}
                    >
                      {v?.answer}
                    </Typography>

                    <Box display="flex" gap={1} flexWrap="wrap" key={v}>
                      {j?.map((v) => {
                        //console.log("v", v);
                        return (
                          <img
                            onClick={() =>
                              window.open(
                                API_URL.baseUrl + "uploads/provider/forms/" + v
                              )
                            }
                            style={{
                              objectFit: "fill",
                              width: "4rem",
                              height: "4rem",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            src={
                              API_URL.baseUrl + "uploads/provider/forms/" + v
                            }
                          />
                        );
                      })}

                      {!j?.length ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            ml: 1.5,
                          }}
                        >
                          <Typography>No equipment photos available</Typography>
                        </Box>
                      ) : null}
                    </Box>

                    <Typography color={v.status ? "#039600" : "red"} mt={0.7}>
                      {v.status ? "Approved" : "Rejected"}
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
          </Box>
        )}
      </Stack>
    </Popover>
  );
}

export default ShowHistoryDailog;
