import { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import { API_URL } from "../../../../../Apis/config";
import SplitButton from "../../../../../Component/Button/SplitButton";
import KycHistoryModal from "./KycHistoryModal";
import ButtonView from "../../../../../Component/Button/ButtonView";
import UpdateStatusDialog from "./UpdateStatusDialog";
import { APPROVE_REJECT_KYC } from "../../../../../Apis/service_api.jsx";

const KycDetails = ({ kyc, getKYC }) => {
  const [open, setOpen] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [id, setId] = useState(null);

  const rows = kyc.map((row, index) => ({ ...row, renderId: index + 1 }));

  const handleApproveRejectStatus = async (status, id, reason) => {
    const res = await APPROVE_REJECT_KYC(status, id, reason);
    if (res && res.s === 1) {
      getKYC();
      console.log(res);
    } else {
      console.log("error");
    }
  };

  const cols = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.renderId}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Document Name",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: "document_image",
      headerName: "Document Image",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return params.row.front || params.row.back ? (
          <Box display="flex" gap={1} alignItems="center">
            {params.row.front ? (
              <img
                src={API_URL.baseUrl + API_URL.KYCImage + params.row.front}
                alt=""
                style={{
                  width: "4rem",
                  objectFit: "fill",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    API_URL.baseUrl + API_URL.KYCImage + params.row.front,
                    "_blank"
                  )
                }
              />
            ) : null}
            {params.row.back ? (
              <img
                src={API_URL.baseUrl + API_URL.KYCImage + params.row.back}
                alt=""
                style={{
                  width: "4rem",
                  objectFit: "fill",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    API_URL.baseUrl + API_URL.KYCImage + params.row.back,
                    "_blank"
                  )
                }
              />
            ) : null}
          </Box>
        ) : (
          <Typography fontSize="12.4px">N/A</Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            fontSize="12.4px"
            color={
              params.row.status === 1
                ? "#0DA800"
                : params.row.status === 0
                ? "blue"
                : "red"
            }
          >
            {params.row.id !== null
              ? params.row.status === 1
                ? "Approved"
                : params.row.status === 0
                ? "Pending"
                : "Rejected"
              : "Not Submitted"}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isEdit={true}
          editText="Update Status"
          onEdit={() => {
            setId(params.row.id);
            setOpenUpdateStatus(true);
          }}
        />
      ),
    },
  ];

  //console.log("kyc rows", rows);
  return (
    <Stack width="100%" gap={1.5}>
      <ButtonView
        lable="History"
        onClick={() => setOpen(true)}
        sx={{ alignSelf: "flex-end", width: "20%" }}
      />

      <Box width="100%" boxShadow="3px 3px 7px 0px #00000040">
        <DataGrideView
          columns={cols}
          rows={rows ?? []}
          colHeight={null}
          getRowId={(row) => row.renderId}
        />
      </Box>

      {open ? (
        <KycHistoryModal open={open} setOpen={setOpen} data={kyc} />
      ) : null}

      {openUpdateStatus ? (
        <UpdateStatusDialog
          open={openUpdateStatus}
          id={id}
          handleApproveRejectStatus={handleApproveRejectStatus}
          handleClose={() => setOpenUpdateStatus(false)}
        />
      ) : null}
    </Stack>
  );
};

export default KycDetails;
