import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import LocationSearchInput from "../../Component/AutoComplate/AddressAutoComplate";
import { ADD_UPDATE_NEW_ON_SPOT_LOCATION } from "../../Apis/new_on_spot_api";
import { format } from "date-fns";
import { ToastShow } from "../Toast/Toast";

function AddOnShotLocationDailog({
  open,
  handleOnClose,
  row,
  id = 0,
  on_spot_id,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = React.useState(
    row ? Date.parse(row.date) :null
  );
  const [startTime, setStartTime] = React.useState(
    row ? new Date( Date.parse(format(Date.now(), "yyyy-MM-dd") + " " + row.from_time)) :null
  );
 // console.log("nn",startTime)
  const [endTime, setEndTime] = React.useState(
    row ? new Date(Date.parse(format(Date.now(), "yyyy-MM-dd") + " " + row.to_time)) : null
  );
  const [latLng, setLatLng] = React.useState(
    row
      ? {
          lat: row.latitude,
          lng: row.longitude,
        }
      : {}
  );
  const [address, setAddress] = React.useState(row ? row.address : "");

  const handleOnSubmit = async () => {
    if (!address.length) {
      ToastShow("Please enter address");
    } else {
      setIsLoading(true);
      //console.log(latLng);
      const d = await ADD_UPDATE_NEW_ON_SPOT_LOCATION({
        on_spot_city_id: on_spot_id,
        id: row?.id ?? 0,
        address: address,
        latitude: latLng?.lat,
        longitude: latLng?.lng,
        date: startDate ? format(startDate, "yyyy-MM-dd") : null,
        from_time: startTime ? format(startTime, "HH:mm:ss") : null,
        to_time: endTime ? format(endTime, "HH:mm:ss") : null,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow( d?.m ?? "Something went wrong. Please try again.");
      }
    }
  };

  const updateLatLng = (latLngs) => {
    setLatLng(latLngs);
    // console.log("lat",latLng.lat, "lng",latLng.lng);
  };

  const handleAddress = (address) => {
    // console.log(address);
    setAddress(address);
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!id ? "Add Location" : "Update Location"}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="dd-MM-yyyy"
              minDate={Date.now()}
              label="Date"
              value={startDate}
              onChange={(v) => {
                setStartDate(v);
              }}
              renderInput={(params) => (
                <TextField fullWidth sx={{ m: 1.4 }} {...params} />
              )}
            />

            <TimePicker
              label="From Time"
              value={startTime}
              onChange={(v) => {
                console.log(v)
                setStartTime(v);
                setEndTime(null);
              }}
              renderInput={(params) => (
                <TextField fullWidth sx={{ m: 1.4 }} {...params} />
              )}
            />

            <TimePicker
              label="To Time"
              value={endTime}
              onChange={(v) => {
                //console.log(v,startTime)
               // console.log(Date.parse(v),Date.parse(startTime));
                if(!startTime || !Date.parse(startTime)){
                  ToastShow("Please select the start time");
                }else if(Date.parse(v) < Date.parse(startTime)) {
                  ToastShow("End time should be greterthen start time");
                }else {
                  // console.log(v);
                  setEndTime(v);
                }
               
              }}
              renderInput={(params) => (
                <TextField fullWidth sx={{ m: 1.4 }} {...params} />
              )}
            />
          </LocalizationProvider>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <LocationSearchInput
            
              size="large"
              address={address}
              handleAddress={handleAddress}
              updateLatLng={updateLatLng}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddOnShotLocationDailog;
