import {
  Box,
  Dialog,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { GrClose } from "react-icons/gr";
import ButtonView from "../../../../Component/Button/ButtonView";
import TextFieldSmall from "../../../../Component/TextFields/TextFieldSmall";
import { ADD_UPDATE_ORDER_DISCOUNT } from "../../../../Apis/order_api";
import { ToastShow } from "../../../../Component/Toast/Toast";
import TextFieldLarge from "../../../../Component/TextFields/TextFieldLarge";
import RadioButtonNew from "../../../../Component/RadioButton/RadioButtonNew";

function AddOrderDiscount({
  open,
  handleClose,
  orderID,
  isAdded,
  getData,
  subTotal,
}) {
  const [inputs, setInputs] = useState({
    label: "",
    discount: "",
    discountPercent: "",
  });
  const [isCheck, setIsCheck] = useState(2);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "discountPercent" && value > 99) ||
      (name === "discountPercent" && value < 0)
    ) {
      ToastShow("Discount percent is only valid under 1 and 99");
    } else {
      setInputs({ ...inputs, [name]: value });
    }
  };

  const handleAddUpdateDiscount = async () => {
    if (inputs.label === "") {
      ToastShow("Please enter something for label");
      return;
    } else if (
      (isCheck === 1 && inputs.discountPercent) > 99 ||
      (isCheck === 1 && inputs.discountPercent < 1)
    ) {
      ToastShow("Discount percent is only valid under 1 and 99");
      return;
    }

    const res = await ADD_UPDATE_ORDER_DISCOUNT({
      orders_id: orderID,
      discount_by_admin:
        isCheck === 1
          ? calculatePercentage(inputs.discountPercent)
          : inputs.discount,
      discount_by_label:
        isCheck === 1 ? `${inputs.label} (In Percent %)` : inputs.label,
    });

    if (res && res.s === 1) {
      getData();
      ToastShow(res.m);
      handleClose();
    } else {
      ToastShow(res.m);
    }
  };

  const calculatePercentage = (percent) => {
    const parsedNumber = subTotal;
    const parsedPercent = percent;

    if (!isNaN(parsedNumber) && !isNaN(parsedPercent)) {
      const calculatedPercentage = (parsedNumber * parsedPercent) / 100;
      console.log(`calculatedPercentage`, calculatedPercentage);
      return calculatedPercentage;
    } else {
      return null;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Stack gap={1.5} m={1}>
          <Stack
            width="100%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.8rem"
            px="0.8rem"
          >
            <Typography
              fontWeight="500"
              fontSize="15px"
              sx={{ userSelect: "none" }}
            >
              {isAdded ? "Update Discount" : "Add Discount"}
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={handleClose}
            />
          </Stack>

          <Stack gap={1.4}>
            <TextFieldLarge
              width="300px"
              label="Label for Discount"
              placeholder="Enter label"
              name="label"
              value={inputs.label}
              onChange={handleChange}
            />

            <Box
              width="100%"
              bgcolor="#F6F6F6"
              borderRadius="6px"
              display="flex"
              flexDirection="column"
              paddingY="6px"
              gap="4px"
            >
              {/*Form Control*/}
              <Box width="95%">
                <Typography fontSize="11.8px" mb="0.3rem" pl="0.4rem">
                  Add Discount In Amount or Percent
                </Typography>

                <RadioGroup
                  row
                  value={isCheck}
                  defaultValue={isCheck}
                  sx={{
                    width: "100%",
                    height: "1.9rem",
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                    "& .MuiFormControlLabel-root": {
                      margin: "0",
                    },
                    "& .css-vxllfd-MuiFormControlLabel-root": {
                      margin: "0",
                    },
                  }}
                >
                  <FormControlLabel
                    label="Percent"
                    value={1}
                    onClick={(e) => {
                      setIsCheck(1);
                    }}
                    control={
                      <Radio
                        checkedIcon={
                          <img
                            src={require("../../../../Assets/Icons for pages/Radio_checked.png")}
                            width="14px"
                            height="14px"
                            alt=""
                          />
                        }
                        icon={
                          <img
                            src={require("../../../../Assets/Icons for pages/Radio_unchecked.png")}
                            width="14px"
                            height="14px"
                            alt=""
                          />
                        }
                      />
                    }
                    sx={{
                      width: "44%",
                      height: "100%",
                      userSelect: "none",
                      bgcolor: "white",
                      borderRadius: "6px",
                      color: "red",
                      ".MuiFormControlLabel-label": {
                        color: 1 === isCheck ? "#FFAB30" : "#969696",
                      },
                      "& .css-7jdnmo-MuiTypography-root": {
                        fontSize: "0.750rem",
                      },
                    }}
                  />
                  <FormControlLabel
                    label="Amount"
                    value={2}
                    onClick={(e) => {
                      setIsCheck(2);
                    }}
                    control={
                      <Radio
                        checkedIcon={
                          <img
                            src={require("../../../../Assets/Icons for pages/Radio_checked.png")}
                            width="14px"
                            height="14px"
                            alt=""
                          />
                        }
                        icon={
                          <img
                            src={require("../../../../Assets/Icons for pages/Radio_unchecked.png")}
                            width="14px"
                            height="14px"
                            alt=""
                          />
                        }
                      />
                    }
                    sx={{
                      width: "44%",
                      userSelect: "none",
                      bgcolor: "white",
                      borderRadius: "6px",
                      ".MuiFormControlLabel-label": {
                        color: 2 === isCheck ? "#FFAB30" : "#969696",
                      },
                      "& .css-7jdnmo-MuiTypography-root": {
                        fontSize: "0.750rem",
                      },
                    }}
                  />
                </RadioGroup>
              </Box>
            </Box>

            {isCheck === 1 && (
              <TextFieldLarge
                width="300px"
                type="number"
                label="Discount by POD"
                name="discountPercent"
                placeholder="Enter discount in percent %"
                value={inputs.discountPercent}
                onChange={handleChange}
              />
            )}

            {isCheck === 2 && (
              <TextFieldLarge
                width="300px"
                type="number"
                label="Discount by POD"
                name="discount"
                placeholder="Enter discount in numbers"
                value={inputs.discount}
                onChange={handleChange}
              />
            )}

            {isCheck === 1 && (
              <Typography pl="0.5rem" fontSize="15px">
                The calculated discount is:{" "}
                {calculatePercentage(inputs.discountPercent)}
              </Typography>
            )}
          </Stack>
        </Stack>

        <DialogActions>
          <ButtonView
            lable="Submit"
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={handleAddUpdateDiscount}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddOrderDiscount;
