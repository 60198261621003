import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonIcon from "../../../../Component/Button/ButtonIcon";
import ButtonIcon2 from "../../../../Component/Button/ButtonIcon2";
import time_icon from "../../../../Assets/Icons for pages/time_icon.png";
import IconButtomSmall from "../../../../Component/Button/IconButtomSmall";

const OrderDetailsTable = ({
  orderID,
  reschedule_count,
  edit_count,
  social_permission,
}) => {
  return (
    <Box
      width="29vw"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#FFAB30",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          Order Details
        </Typography>
      </Box>

      {/*Rows*/}
      <Row label="Order ID" prop={"#" + orderID} />
      <Row
        label="Reschedule Count"
        prop={reschedule_count}
        hasHistory={false}
        isRescheduleCount={true}
      />
      <Row label="Edit Count" prop={edit_count} hasHistory={false} />
      <Row
        label="Social Permission"
        prop={social_permission}
        isLastRow={true}
      />
      {/*isLastRow should be true as it's last row and we want borderRadius there*/}
    </Box>
  );
};

export default OrderDetailsTable;

function Row({ label, prop, hasHistory, isLastRow, isRescheduleCount }) {
  return (
    <Stack width="100%" direction="row">
      <Box
        width="30%"
        bgcolor="#EFEFEF"
        borderBottom="1px solid white"
        display="flex"
        alignItems="center"
        paddingY="0.4rem"
        pl="0.8rem"
        sx={{ borderBottomLeftRadius: isLastRow && "5px" }}
      >
        <Typography fontSize="13px">{label}</Typography>
      </Box>
      <Box
        width="70%"
        bgcolor="white"
        borderBottom="1px solid #EFEFEF"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingY="0.4rem"
        pl="0.8rem"
        pr="0.8rem"
        overflow={isRescheduleCount ? "auto" : null}
        sx={{ borderBottomRightRadius: isLastRow && "5px" }}
      >
        <Typography fontSize="13px" fontWeight="500" width="100%">
          {prop ? prop : "-"}
        </Typography>

        {hasHistory && (
          <IconButtomSmall
            label="History"
            buttonStyle={{
              backgroundColor: "#FFE0B2",
              padding: "2.6px 7.2px",
              borderRadius: "4px",
            }}
            imgStyle={{ width: "10px", height: "10px" }}
            icon={time_icon}
          />
        )}
      </Box>
    </Stack>
  );
}
