import React from "react";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertToast = ({ openAlert, setOpenAlert, alertMsg, type = "error" }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const successTheme = {
    "& .css-1d3i2bk-MuiPaper-root-MuiAlert-root": {
      bgcolor: "#cce9ff",
      color: "black",
    },
  };

  return (
    <Snackbar
      open={openAlert}
      autoHideDuration={4000}
      onClose={handleClose}
      sx={type === "success" && successTheme}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {alertMsg}
      </Alert>
    </Snackbar>
  );
};

export default AlertToast;
