import { API_URL, GETAPI, POSTAPI } from "./config";

export const GETONSPOTCAT = async () => {
  const res = await GETAPI(API_URL.onspot2GetCategory);
  return res;
};

export const ADD_UPDATE_SPOT_CATEGORY = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);

  if(data.id){
    formData.append("id", data.id);
  }

  const res = await POSTAPI(
    data.id ? API_URL.onspot2UpdateCategory : API_URL.onspot2AddCategory,
    formData
  );

  return res;
};

export const DELETEONSPOTCATEGORY = async (id) => {
  const res = await GETAPI(API_URL.onspot2DeleteCategory, "&id=" + id);
  return res;
};
