import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import {
  ADD_UPDATE_NEW_ON_SPOT_CITY,
  ADD_UPDATE_NEW_ON_SPOT_PROVIDER,
  ADD_UPDATE_NEW_ON_SPOT_PROVIDER_UPDATE,
  GETONSPOTNEWPROVIDER,
} from "../../Apis/new_on_spot_api";
import MultiSelection from "../Multiselection/MultiSelection";
import { useEffect } from "react";
import { API_URL } from "../../Apis/config";
import { motion } from "framer-motion";


let count = 0;
function AddNewOnSpotProvider({
  open,
  handleOnClose,
  row,
  role,
  rolesW,
  on_spot_city_location_zone_id,
  on_spot_city_location_id,
  on_spot_city_location_zone_provider_id = 0,
  provider_user_id = 0
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRoles, setSelectedRoles] = React.useState([role[0]]);
  const [roleId, setSelectedId] = React.useState([rolesW[0].id]);
  const [earn, setEarn] = React.useState(row ? row.earn : "");
  const [provider, setProvider] = React.useState([]);
  const [selected, setSelected] = useState([]);

  function handleSelected(v) {
    if (selected.length == 0) {
      const i = selected.indexOf(v);
      if (!(i > -1)) {
        setSelected((p) => [...p, v]);
      }
    }
    //if (selected.length < qty) {

    //}
    // else {
    //   ToastShow(`Required quantity is just : ${qty}.`);
    // }
  }

  function deleteHandleSelected(v) {
    // if(!(v.status > 2)){
    const i = selected.indexOf(v);
    if (i > -1) {
      const d = selected.filter((e) => e.user_id != v.user_id);
      setSelected([...d]);
    }
    // }
    //else {
    //   ToastShow("Order has been started. So you can not remove.")
    // }
  }

  const handleSelectRole = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedRoles([value]);
    const c = rolesW.filter((v) => value.includes(v.name));
    const i = [];

    for (let d of c) {
      i.push(d.id);
    }
    setSelectedId(i);
  };

  const handleOnSubmit = async () => {
    if (!roleId.length) {
      ToastShow("Please select the role");
    } else if (!+earn) {
      ToastShow("Please enter the earn");
    } else if (!selected.length) {
      ToastShow("Please select the provider");
    } else {
      setIsLoading(true);
      let d;
      for (let v of selected) {
        d = await ADD_UPDATE_NEW_ON_SPOT_PROVIDER({
          on_spot_city_location_zone_id: on_spot_city_location_zone_id,
          on_spot_roles_id: roleId[0],
          provider_user_id: v.user_id,
          earn: earn,
        });
      }
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  const handleOnUpdate = async () => {
    if (!+earn) {
      ToastShow("Please enter the earn");
    } else {
      setIsLoading(true);
      let d = await ADD_UPDATE_NEW_ON_SPOT_PROVIDER_UPDATE({
          on_spot_city_location_zone_provider_id:
          on_spot_city_location_zone_provider_id,
          provider_user_id: provider_user_id,
          earn: earn,
        });
     
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  const getData = async () => {
    const data = await GETONSPOTNEWPROVIDER({
      on_spot_city_location_id: on_spot_city_location_id,
      on_spot_roles_id: roleId[0],count
    });

    if (data && data.s && data.r) {
      count += (data.r ?? []).length;

    if ((data.r ?? []).length) {
      setProvider(p=>([...p,...data.r]));
      await getData();
    }
    }
  };

  useEffect(() => {
    count = 0;
    getData(0);
  }, [selectedRoles]);

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>
          {!row?.id ? "Add Provider" : "Update Provider"}
        </DialogTitle>
        <DialogContent
        
          sx={{
            width: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {!on_spot_city_location_zone_provider_id ? (
            <MultiSelection
              width={450}
              multiple={false}
              names={role}
              handleChange={handleSelectRole}
              selected={selectedRoles}
              lable="Role"
            />
          ) : null}

          <TextField
            sx={{
              width: "100%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Earn"
            type="number"
            value={earn}
            onChange={(e) => setEarn(e.target.value)}
          />

          {!on_spot_city_location_zone_provider_id ? (
            <>
              {selected.length ? (
                <ChipView
                  data={selected}
                  deleteHandleSelected={deleteHandleSelected}
                />
              ) : null}
              {provider.length ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                    p: 2,
                    flex:1,
                    overflowY:"auto",
                    height:"80%"
                  }}
                >
                  {provider.map((v) => {
                    return <ItemView v={v} handleSelected={handleSelected} />;
                  })}
                   
                </Box>
              ) : null}
            </>
          ) : null}
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={
                on_spot_city_location_zone_provider_id
                  ? handleOnUpdate
                  : handleOnSubmit
              }
              variant="text"
            >
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddNewOnSpotProvider;

function ChipView({ data, deleteHandleSelected }) {
  return (
    <Box display="flex" flexWrap="wrap">
      {data.map((v) => {
        return (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            key={v.user_id}
          >
            <Chip
              sx={{ m: 0.3 }}
              avatar={
                <Avatar
                  alt="Natacha"
                  src={`${
                    API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb
                  }`}
                />
              }
              label={v.first_name}
              variant="outlined"
              onDelete={() => deleteHandleSelected(v)}
            />
          </motion.div>
        );
      })}
    </Box>
  );
}

function ItemView({ v, handleSelected }) {
  return (
    <Paper
      component={"div"}
      elevation={0}
      onClick={() => {
        handleSelected(v);
      }}
      sx={{
        overflow: "hidden",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        width: "100px",
        p: 2,
        alignItems: "center",
        transition: "all",
        transitionDuration: "300ms",
        border: "solid",
        borderWidth: "1px",
        borderColor: "divider",
        boxShadow:
          "7px 8px 21px -10px rgba(0,0,0,0.1); -10px -12px 21px -10px rgba(0,0,0,0.1)",
        "&:hover": {
          backgroundColor: "divider",
          boxShadow: "11px 24px 28px -7px rgba(0,0,0,0.1);",
        },
      }}
    >
      <Avatar
        sx={{ height: "40px", width: "40px" }}
        src={`${API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb}`}
      />
      <Typography
        variant="body1"
        sx={{ fontSize: "14px", textAlign: "center", wordWrap: "break-word" }}
      >
        {(v.first_name ?? "") + " " + (v.last_name ?? "")}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ fontSize: "10px", wordWrap: "break-word" }}
      >
        {v.mobile}
      </Typography>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "10px", wordWrap: "break-word", fontWeight: "bold" }}
        >
          {v.cities}
        </Typography>
      </Box>
    </Paper>
  );
}
