import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  import React, { useState } from "react";
  import TextFieldView from "../TextField/TextFieldView";
  import ToastView, { ToastShow } from "../Toast/Toast";
  
  function ApproveRejectDailog({ open, handleOnClose, status, id }) {
  
    const [reason, setReason] = React.useState("");
  
    const handleOnSubmit = async () => {
      if (!reason) {
        ToastShow("Please add reason");
      } else {
        handleOnClose(1, status, id,reason);
      }
    };
  
    return (
      <>
        <Dialog open={open} onClose={() => handleOnClose(0)}>
          <DialogTitle>Add reason</DialogTitle>
          <DialogContent
            sx={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextFieldView
              value={reason}
              onchange={(e) => {
                setReason(e);
              }}
              label="Reason"
              type="text"
              sx={{ width: "92%" }}
            />
          </DialogContent>
  
          <DialogActions>
          <Button onClick={handleOnSubmit} variant="text">
                Submit
              </Button>
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastView />
      </>
    );
  }
  
  export default ApproveRejectDailog;
  