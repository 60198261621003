import { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { GrClose } from "react-icons/gr";
import TextFieldLarge from "../../../Component/TextFields/TextFieldLarge";
import ButtonView from "../../../Component/Button/ButtonView";
import { CREATE_TICKET, GET_SUPPORT_CATEGORY } from "../../../Apis/support_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import { GEBYMOBILENO } from "../../../Apis/booking_order_api";

const AddComplain = ({ open, handleClose, row, categories, getData }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [details, setDetails] = useState(row?.details ?? "");
  const [orderID, setOrderID] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSub, setSelectedSub] = useState(null);
  const [mobNum, setMobNum] = useState(null);
  const [isOrderID, setIsOrderID] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("ROW", row);

  const getCategories = async (parentCategoryID) => {
    const res = await GET_SUPPORT_CATEGORY(1, parentCategoryID);
    if (res && res.s) {
      setSubCategory(res?.r ?? []);
    } else {
      ToastShow(res.m);
    }
  };

  const handleFileChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setAttachments(selectedImages);
  };

  const handleSubmit = async (subOrCategory) => {
    setLoading(true);
    if (!mobNum) {
      ToastShow("Please enter user's mobile number!");
    } else if (mobNum.length < 10 || mobNum.length > 10) {
      ToastShow("Please enter a valid mobile number!");
    } else if (!selectedCategory) {
      ToastShow("Please select a Category!");
    } else if (subCategory.length && selectedSub === null) {
      ToastShow("Please select a Sub Category!");
    } else if (details === "") {
      ToastShow("Please fill Details!");
    } else if (subOrCategory.related_to === 1 && orderID === "") {
      ToastShow("Please enter Order ID for this Category");
    } else {
      const d = await GEBYMOBILENO(mobNum);
      if (d && d.s) {
        const res = await CREATE_TICKET({
          categoryId: subOrCategory.id,
          details,
          ordersId: subOrCategory.related_to === 1 ? orderID : "",
          attachments: attachments.length && attachments,
          userId: d?.r?.id,
        });
        if (res && res.s) {
          ToastShow(res.m);
          handleClose();
          getData();
        } else {
          ToastShow(res.m);
        }
      } else {
        ToastShow(`User ` + d.m);
      }
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (details === "") {
      ToastShow("Please fill Details to update!");
      return;
    }
    const res = await CREATE_TICKET({
      id: row?.id !== null ? row.id : null,
      details,
    });
    if (res && res.s) {
      ToastShow(res.m);
      handleClose();
      getData();
    } else {
      ToastShow(res.m);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    if (event.target.value.related_to === 1) {
      setIsOrderID(true);
    } else {
      setIsOrderID(false);
    }

    setSelectedSub(null);
    setSelectedCategory(event.target.value);
    getCategories(event.target.value.id);
  };

  const handleChangeSub = (event) => {
    if (event.target.value.related_to === 1) {
      setIsOrderID(true);
    } else {
      setIsOrderID(false);
    }

    setSelectedSub(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(4px)",
        bgcolor: "rgba(105, 55, 0, 0.4)",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "6px",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            width: "31.25rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: 2.2,
          }}
        >
          <Typography
            sx={{
              backgroundColor: "#F6F6F6",
              width: "100%",
              height: "44px",
              padding: "10px",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "17px",
              fontWeight: "500",
              userSelect: "none",
            }}
          >
            {!row?.id ? "Add Complain" : "Edit Complain"}
            <GrClose style={{ cursor: "pointer" }} onClick={handleClose} />
          </Typography>

          <Box
            width="100%"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            padding="10px"
            display="flex"
            flexDirection="column"
            gap=".8rem"
            pt="0.8rem"
          >
            {row === null ? (
              <TextFieldLarge
                label="User Mobile Number"
                placeholder="Enter User's mobile number"
                type="number"
                value={mobNum}
                onChange={(e) => setMobNum(e.target.value)}
                width="100%"
                alternateBg="#F6F6F6"
              />
            ) : null}

            {row === null ? (
              <FormControl>
                <InputLabel>Select a category</InputLabel>
                <Select
                  label="Select a category"
                  value={selectedCategory}
                  onChange={handleChange}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

            {selectedCategory && subCategory.length && row === null ? (
              <FormControl>
                <InputLabel>Sub category</InputLabel>
                <Select
                  label="Select a category"
                  value={selectedSub}
                  onChange={handleChangeSub}
                >
                  {subCategory.map((category) => (
                    <MenuItem key={category.id} value={category}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

            <TextFieldLarge
              label="Details"
              placeholder="Enter Details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              width="100%"
              alternateBg="#F6F6F6"
            />

            {isOrderID && row === null ? (
              <TextFieldLarge
                label="Order ID (If Category related to Order)"
                placeholder="Enter Order ID"
                value={orderID}
                onChange={(e) => setOrderID(e.target.value)}
                width="100%"
                alternateBg="#F6F6F6"
              />
            ) : null}

            {row === null ? (
              <Stack gap="0.5rem" pl="0.5rem" bgcolor="white" py="0.3rem">
                <Typography>Select Files</Typography>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                />
              </Stack>
            ) : null}
          </Box>

          <ButtonView
            lable={!row?.id ? "Create Complain" : "Update Complain"}
            sx={{ borderRadius: "6px" }}
            fontSize="18px"
            fontWeight="500"
            onClick={() =>
              row?.id
                ? handleUpdate()
                : handleSubmit(
                    subCategory.length ? selectedSub : selectedCategory
                  )
            }
            isLoading={loading}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AddComplain;
