import React from 'react'
import OrderList from './OrderList'

function All({status}) {

  return (
   <OrderList status={status}/>
  )
}

export default All