import React, { useEffect, useState } from "react";
import {
  GETONSPOTNEWDETAILS,
  PUBLISHONSPOTNEW,
  UnPUBLISHONSPOTNEW,
} from "../../../Apis/new_on_spot_api";
import { useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Sponser from "./DetailsComponent/Sponser/Sponser";
import Gallery from "./DetailsComponent/Gallery/Gallery";
import FAQ from "./DetailsComponent/Faq/Faq";
import CityOnSpot from "./DetailsComponent/Cities/City/City";
import CityDetails from "./DetailsComponent/Cities/Details/Details";
import RoleOnSpot from "./DetailsComponent/Role/Role";
import RoleList from "./DetailsComponent/Role/RoleList";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import { ToastShow } from "../../../Component/Toast/Toast";

function DetailsOnSpotNew() {
  const { id } = useParams();
  const [row, setRow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openConfirmationP, setOpenConfirmationP] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const getData = async () => {
    const data = await GETONSPOTNEWDETAILS(id);

    if (data && data.s && data.r) {
      setRow(data.r);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const onPublish = async (id) => {
    handleOpenConfirmationP(id);
  };

  const handleOpenConfirmationP = (id) => {
    setSelectedIdN(id);
    setOpenConfirmationP(true);
  };

  const handleOnCloseConfirmationP = async (v) => {
    if (v) {
      if (row?.status == 1) {
        const r = await UnPUBLISHONSPOTNEW(selectedId);
        if (r && r.s) {
          ToastShow("Un-Published successfully");
          getData();
        } else {
          ToastShow(r?.m ?? "Opps something went wrong. Please try again.");
        }
      } else {
        const r = await PUBLISHONSPOTNEW(selectedId);
        if (r && r.s) {
          ToastShow("Published successfully");
          getData();
        } else {
          ToastShow(r?.m ?? "Opps something went wrong. Please try again.");
        }
      }
    }
    setSelectedIdN(0);
    setOpenConfirmationP(false);
  };

  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        width: "100%",
        height: "90vh",
        overflowY: "auto",
        pb: 8,
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        <Typography>Oops! something went wrong. Please try again.</Typography>
      ) : (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr));",
              gap: 2,
              p: 3,
            }}
          >
            <Sponser row={row?.sponser ?? []} getData={getData} id={id} />
            <Gallery row={row?.gallery ?? []} getData={getData} id={id} />
            <FAQ row={row?.faq ?? []} getData={getData} id={id} />
            <RoleOnSpot row={row?.roles ?? []} getData={getData} id={id} />
          </Box>
          <Box
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {row?.roles?.length
              ? row.roles.map((v) => {
                  return (
                    <RoleList
                      key={v.id}
                      row={v}
                      getData={getData}
                      id={id}
                      is_paid={row.isPaid}
                    />
                  );
                })
              : null}
          </Box>

          <CityOnSpot row={row?.city ?? []} getData={getData} id={id} />
          <Box
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {row?.city?.length
              ? row.city.map((v) => {
                  return (
                    <CityDetails
                      key={v.id}
                      row={v}
                      getData={getData}
                      id={id}
                      role={row?.roles ?? []}
                    />
                  );
                })
              : null}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              paddingRight: "20px",
            }}
          >
            <Button onClick={()=>onPublish(row.id)} variant="contained" sx={{}}>
              {" "}
              {row?.status ? "Un-Published" : "Published"}
            </Button>
          </Box>
        </>
      )}

      {openConfirmationP ? (
        <ConfirmationDailog
          title={row?.status == 1 ? "Un publish" : "Publish !"}
          content={`Are you sure, You want to ${
            row?.status == 1 ? "un-publish" : "publish"
          } this?`}
          open={openConfirmationP}
          handleOnClose={handleOnCloseConfirmationP}
        />
      ) : null}
    </Box>
  );
}

export default DetailsOnSpotNew;
