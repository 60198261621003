import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";

import { Button, Fab, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";

import { ToastShow } from "../../../Component/Toast/Toast";
import SplitButton from "../../../Component/Button/SplitButton";

import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import {
  DELETEONSPOTCATEGORY,
  GETONSPOTCAT,
} from "../../../Apis/on_sport_category_api";
import AddOnSpotCategoryDailog from "../../../Component/Dailog/AddOnSpotCategory";

function OnSpotCategory() {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectdRow, setSelectedRows] = React.useState(null);

  const getData = async () => {
    setIsLoading(true);
    const t = await GETONSPOTCAT();

    if (t && t.s == 1) {
      if (t.r) {
        setRows([...t.r]);
      }
    }
    setIsLoading(false);
  };

  const handleOnClose = (p) => {
    setOpen(false);
    if (p) {
      setRows([]);
      getData();
    }
  };

  const handleOpen = () => {
    setSelectedRows(null);
    setOpen(true);
  };

  const onEdit = (v) => {
    // console.log(v);

    const t = rows.filter((j) => j.id == v);
    // console.log(t.length);
    setSelectedRows(t[0]);
    setOpen(true);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEONSPOTCATEGORY(selectedId);
      if (r && r.s) {
        const d = rows.filter((v) => v.id != selectedId);
        setRows([...d]);
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 190,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isDelete={1}
          onDelete={onDelete}
          title={params.value}
          onEdit={onEdit}
          isEdit={1}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "80vh",
      }}
    >
      <Stack direction="row" justifyContent="flex-end" mb="1rem">
        <Button
          startIcon={
            <img
              src={require("../../../Assets/add-button.png")}
              width="13px"
              alt=""
            />
          }
          onClick={() => setOpen(1)}
          sx={{
            height: "2.3rem",
            borderRadius: "5px",
            textTransform: "none",
            bgcolor: "#F8AF41",
            color: "black",
            "&:hover": {
              bgcolor: "rgb(173, 123, 44)",
            },
            fontSize: ".8rem",
            paddingX: "1.4rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
            }}
          >
            Add
          </Typography>
        </Button>
      </Stack>

      <DataGrideView isLoading={isLoading} rows={rows} columns={columns} />

      {open && (
        <AddOnSpotCategoryDailog
          open={open}
          handleOnClose={handleOnClose}
          row={selectdRow}
        />
      )}

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete category !"
          content="Are you sure, You want to delete the category?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </Box>
  );
}

export default OnSpotCategory;
