import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

import { useParams } from "react-router-dom";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";

import { ToastShow } from "../../../Component/Toast/Toast";
import SplitButton from "../../../Component/Button/SplitButton";
import { DELETEFAQ, GETFAQDATA } from "../../../Apis/faq_api";
import AddFaqDailog from "../../../Component/Dailog/AddFaqDailog";
import { GET_CITIE } from "../../../Apis/city_api";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { GETROLE } from "../../../Apis/podian_api";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import multiselectionIcon from "../../../Assets/multiselectionIcon.png";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import AddFaqModal from "./AddFaqModal";

function Faq() {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [selectdRow, setSelectedRows] = React.useState(null);

  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [cityId, setSelectedId] = React.useState(0);

  const type = ["Customer", "Provider"];
  const [selectedType, setSelectedType] = React.useState(["Customer"]);
  const [typeId, setTypeId] = React.useState(0);

  const [roles, setRoles] = React.useState([]);
  const [rolesW, setRolesW] = React.useState([]);
  const [rolesSelected, setRolesSelected] = React.useState(["All"]);
  const [roleId, setRoleId] = React.useState(0);

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
      setSelectedId(t.r[0].id);
      setSelectedCities([t.r[0].name]);
      getData(t.r[0].id, 0);
    }
  }

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([value]);
    const c = citiesW.filter((v) => v.name == value);
    setSelectedId(c.length ? c[0].id : 0);
    await getData(c.length ? c[0].id : 0, typeId, roleId, 0);
  };

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
    setTypeId(getValue(value));
    await getData(cityId, getValue(value), roleId, 0);
  };

  const getValue = (v) => {
    let r = 0;
    console.log("V", v);
    if (v == "All") {
      r = 0;
    } else if (v == "Customer") {
      r = 1;
    } else {
      r = 2;
    }

    return r;
  };

  const handleSelectPodian = async (e) => {
    const {
      target: { value },
    } = e;

    setRolesSelected([value]);
    let ids = 0;
    rolesW.map((v) => {
      if (value == v.name) {
        ids = v.id;
      }
    });

    setRoleId(ids);

    await getData(cityId, typeId, ids, 0);
  };

  const getData = async (id, typeId, roleId, v) => {
    let p = 1;
    if (typeId === 0) {
      typeId = p;
    }
    setIsLoading(true);
    const t = await GETFAQDATA(id, typeId, roleId ?? 0);

    if (t && t.s == 1) {
      if (v) {
        setRows([...rows, ...t.r]);
      } else {
        if (t.r == null) {
          setRows([]);
        } else {
          setRows([...t.r]);
        }
      }
    }
    setIsLoading(false);
  };

  const handleOnClose = (p) => {
    setOpen(false);
    if (p) {
      setRows([]);
      console.log(cityId, typeId, roleId);
      getData(cityId, typeId, roleId, 0);
    }
  };

  const handleOpen = () => {
    setSelectedRows(null);
    setOpen(true);
  };

  const onEdit = (v) => {
    // console.log(v);

    const t = rows.filter((j) => j.id == v);
    // console.log(t.length);
    setSelectedRows(t[0]);
    setOpen(true);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedId, setSelectedIdN] = useState(0);

  const onDelete = async (id) => {
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedIdN(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const r = await DELETEFAQ(selectedId);
      if (r && r.s) {
        const d = rows.filter((v) => v.id != selectedId);
        setRows([...d]);
        ToastShow("Deleted successfully");
      } else {
        ToastShow("Opps something went wrong. Please try again.");
      }
    }
    setSelectedIdN(0);
    setOpenConfirmation(false);
  };

  async function getRoles() {
    const d = await GETROLE();
    const names = [];
    names.push("All");
    if (d && d.s && d.r) {
      setRolesW(d.r);
      for (let v of d.r) {
        names.push(v.name);
      }
    }
    setRoles(names);
  }

  useEffect(() => {
    getCities();
    getRoles();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "question",
      headerName: "Question",
      width: 500,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "answer",
      headerName: "Answer",
      minWidth: 500,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: params.value }}
            style={{
              overflow: "hidden",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
            }}
          />
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 130,
      renderCell: (params) => (
        <Box sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          <SplitButton
            key={params.id}
            dataId={params.id}
            isDelete={1}
            onDelete={onDelete}
            title={params.value}
            onEdit={onEdit}
            isEdit={1}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: ".8rem",
          marginBottom: "16px",
        }}
      >
        <MultiSelection
          multiple={false}
          names={cities}
          handleChange={handleSelectCity}
          selected={selectedCities}
          lable="Cities"
          icon={multiselectionIcon}
        />

        <MultiSelection
          size="small"
          selected={selectedType}
          handleChange={handleSelectType}
          names={type}
          lable={"Type"}
          multiple={false}
        />

        <MultiSelection
          multiple={false}
          handleChange={handleSelectPodian}
          selected={rolesSelected}
          lable={"Role"}
          names={roles}
        />

        <Button
          onClick={handleOpen}
          sx={{
            height: "5.8vh",
            px: "1rem",
            fontWeight: "500",
            textTransform: "none",
            fontSize: "14.6px",
            borderRadius: "5px",
            bgcolor: "#F8AF41",
            color: "black",
            "&:hover": {
              bgcolor: "rgb(173, 123, 44)",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: ".280rem",
          }}
        >
          <img
            src={require("../../../Assets/add-button.png")}
            style={{
              width: "14.6px",
              height: "14.5px",
              marginBottom: ".1rem",
              objectFit: "fill",
            }}
            alt=""
          />
          Add FAQ
        </Button>
      </Box>
      {open && (
        <AddFaqDailog
          open={open}
          handleOnClose={handleOnClose}
          row={selectdRow}
          cId={id}
        />
      )}

      <DataGridCover
        component={
          <DataGrideView
            isLoading={isLoading}
            rows={rows}
            columns={columns}
            colHeight={null}
            sx={{ height: "70vh" }}
          />
        }
        title="FAQ List"
      />
      {/*Old Dialog*/}
      {/*   
      {id ? null : (
        <Fab
          onClick={handleOpen}
          sx={{
            position: "absolute",
            bottom: 45,
            right: 45,
            backgroundColor: "primary",
          }}
          size="small"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      )}

      {open && (
        <AddFaqDailog
          open={open}
          handleOnClose={handleOnClose}
          row={selectdRow}
          cId={id}
        />
      )} */}
      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete faq !"
          content="Are you sure, You want to delete the faq?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </>
  );
}

export default Faq;
