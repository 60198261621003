import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_USER_LIST = async (data) => {
  let query = "";

  query += "&count=" + data.count;

  if (data && data.status !== null) {
    query += "&status=" + data.status;
  }

  if (data && data.search) {
    query += "&search=" + data.search;
  }

  const res = await GETAPI(API_URL.getUserList, query);

  return res;
};

export const GET_USER_DETAILS = async (id) => {
  let query = "";

  query += "&user_id=" + id;
  const res = await GETAPI(API_URL.getUserDetails, query);
  return res;
};

export const GET_USER_WALLET = async (id, c) => {
  let query = "";

  query += "&user_id=" + id + "&count=" + c;
  const res = await GETAPI(API_URL.walletApi, query);
  return res;
};

export const GET_USER_WALLET_REF = async (id, c) => {
  let query = "";

  query += "&user_id=" + id + "&type=1,2" + "&count=" + c;
  const res = await GETAPI(API_URL.walletApi, query);
  return res;
};

export const GET_USER_ORDER = async (id, count,status = null) => {
  let query = "";

  query += "&user_id=" + id + "&count=" + count;

  if(status != null){
    query += "&status="+status;
  }
  const res = await GETAPI(API_URL.getUserOrder, query);
  return res;
};

export const GET_USER_RH = async (id) => {
  let query = "";

  query += "&id=" + id;
  const res = await GETAPI(API_URL.rescheduleHistoryApi, query);
  return res;
};

export const GET_USER_BASIC_DETAILS = async (id) => {
  let query = "";

  query += "&user_id=" + id;
  const res = await GETAPI(API_URL.getUserBasicDetails, query);
  return res;
};

export const GET_MY_ROLES = async () => {
  const res = await GETAPI(API_URL.getMyRole, "&type=3");
  return res;
};

export const APPROVE_REJECT_EXTENDS = async (
  status,
  orders_items_providers_extend_id
) => {
  const query =
    "&orders_items_providers_extend_id=" +
    orders_items_providers_extend_id +
    "&status=" +
    status;

  const res = await GETAPI(API_URL.approveOrRejectExtend, query);
  return res;
};

export const GET_BALANCE = async (id) => {
  const res = await GETAPI(API_URL.getBalance, "&user_id=" + id);
  return res;
};

export const UPDATE_USER = async (data) => {
  const formData = new FormData();

  if (data.fname) {
    formData.append("first_name", data.fname);
  }
  if (data.lname) {
    formData.append("last_name", data.lname);
  }
  if (data.mname) {
    formData.append("middle_name", data.mname);
  }
  formData.append("user_id", data.userId);

  const res = await POSTAPI(API_URL.userUpdate, formData);

  return res;
};
