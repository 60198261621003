import { Box } from "@mui/system";
import React from "react";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { useEffect } from "react";
import { GET_CITIE } from "../../../Apis/city_api";
import SplitButton from "../../../Component/Button/SplitButton";
import { Link, useNavigate } from "react-router-dom";
import {
  DELETE_SUB_ADMIN,
  GET_SUB_ADMIN_LIST,
} from "../../../Apis/subadmin_api";
import { Avatar, Button, Typography } from "@mui/material";
import { API_URL } from "../../../Apis/config";
import { ToastShow } from "../../../Component/Toast/Toast";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";

function SubAdminList() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [subAdminId, setSubAdminId] = React.useState(0);
  const navigation = useNavigate();

  const onEdit = async (id) => {
    // navigation("edit/"+id);
    window.open("list/edit/" + id);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "start",
      align: "center",
      width: 65,
      renderCell: (params) => {
        return (
          <Typography fontSize="13.4px">
            {params.api.getRowIndex(params.row.id) + 1}
          </Typography>
        );
      },
    },
    // { field: "id", headerName: "ID", width: 50 },
    {
      field: "photo",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 300,
      minWidth: 300,
      renderCell: (params) => (
        <Avatar
          sx={{ width: "1.9rem", height: "1.9rem", objectFit: "fill" }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 210,
      minWidth: 210,

      renderCell: (params) => (
        <Typography fontSize="12.8px">
          {params.row.first_name + " " + params.row.last_name}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 380,
      minWidth: 380,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography fontSize="12.8px">{params.row.email}</Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.row.user_id}
          isDelete={1}
          onDelete={onDelete}
          isEdit={1}
          title={params.value}
          onEdit={onEdit}
        />
      ),
    },
  ];

  const onDelete = async (id) => {
    const d = rows.filter((v) => v.user_id == id);
    setSubAdminId(d[0].id);
    setOpenConfirmation(true);
  };

  async function getData() {
    setIsLoading(true);
    const t = await GET_SUB_ADMIN_LIST();

    if (t && t.s == 1) {
      if (t.r) {
        setRows([...t.r]);
      }
    }
    setIsLoading(false);
  }

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const d = await DELETE_SUB_ADMIN(subAdminId);
      if (d && d.s) {
        ToastShow("Deleted successfully");
      }
      setSubAdminId(0);
      getData();
    }
    setOpenConfirmation(0);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          overflow: "auto",
          width: "100%",
          backgroundColor: "white",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "3px 3px 7px 0px #00000040",
          paddingTop: "13.2px",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            width: "97.5%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
            }}
          >
            Sub Admin List
          </Typography>

          <Link to="/sub-admin/add">
            <Button
              startIcon={
                <img
                  src={require("../../../Assets/add-button.png")}
                  style={{ objectFit: "fill", width: "12px" }}
                  alt=""
                />
              }
              // onClick={() => setOpen(true)}
              sx={{
                height: "2rem",
                borderRadius: "5px",
                textTransform: "none",
                bgcolor: "#F8AF41",
                color: "black",
                "&:hover": {
                  bgcolor: "rgb(173, 123, 44)",
                },
                fontSize: ".8rem",
                paddingX: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                }}
              >
                Add
              </Typography>
            </Button>
          </Link>
        </Box>

        <Box width="97.5%">
          <DataGrideView
            isLoading={isLoading}
            rows={rows}
            columns={columns}
            colHeight="3rem"
            sx={{ height: "80.5vh" }}
          />
        </Box>
      </Box>

      {openConfirmation ? (
        <ConfirmationDailog
          title="Subadmin delete!"
          content="Are you sure, You want to delete this subadmin."
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </Box>
  );
}

export default SubAdminList;
