import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import MultiSelection from "../../../../../Component/Multiselection/MultiSelection";
import { useEffect } from "react";
import { useState } from "react";
import { API_URL } from "../../../../../Apis/config";
import heic2any from "heic2any";
import {
  APPROVEREJECTSERVICEPROVIDER,
  GETPROVIDERSDETAILS,
  GETPROVIDERSFORMDETAILS,
  GETPROVIDERSKYC,
} from "../../../../../Apis/service_api";
import { History } from "@mui/icons-material";
import ApproveRejectDailog from "../../../../../Component/Dailog/ApproveRejectDailog";
import ShowHistoryDailog from "../../../../../Component/Dailog/ShowHistoryDailog";

function QuestingAnswer({ data, id, setData }) {
  const [selectedRole, setSelectedRole] = React.useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyId, setHistoryId] = useState(0);
  const [approveRejectData, setApproveRejectData] = useState({
    id: 1,
    status: 1,
  });
  const [approveOpen, setApproveOpen] = useState(false);

  const handleApproveOnClose = async (v, status, id, reason) => {
    setApproveOpen(false);
    if (v) {
      await APPROVEREJECTSERVICEPROVIDER(id, status, reason);
      getUser();
      getKYC();
    }
  };

  const getUser = async () => {
    const r = await GETPROVIDERSDETAILS(id);

    if (r && r.s) {
      const d = [];
      if (r.r) {
        for (const v of r.r.sroles) {
          d.push(" " + v.name);
        }
      }

      setData((p) => ({
        ...p,
        isLoading: false,
        user: r.r,
        roles: d.join(","),
      }));
      if (r.r && r.r.sroles.length) {
        if (!selectedRole.length) {
          setSelectedRole([r.r.sroles[0].name]);
          getFillForm(r.r.sroles[0].id);
        } else {
          const c = r.r.sroles.filter(
            (j) => j.name.replace(" ", "") == selectedRole[0].replace(" ", "")
          );
          getFillForm(c[0].id);
        }
      }
    } else {
      setData((p) => ({ ...p, isLoading: false }));
    }
  };

  const getKYC = async () => {
    const res = await GETPROVIDERSKYC(id);

    if (res && res.s && res.r) {
      setData((p) => ({ ...p, kyc: res.r }));
    }
  };

  const handleSelectRole = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedRole([value]);

    const c = data.user.sroles.filter((r) => r.name == value.replace(" ", ""));
    getFillForm(c[0].id);
  };

  const openHistoryDailog = (id) => {
    setHistoryId(id);
    setHistoryOpen(true);
  };

  const openApproveReject = async (id, status) => {
    setApproveRejectData({ id: id, status: status });
    if (status != 1) {
      setApproveOpen(true);
    } else {
      await APPROVEREJECTSERVICEPROVIDER(id, status, "");
      getUser();
      getKYC();
    }
  };

  const getFillForm = async (role) => {
    const res = await GETPROVIDERSFORMDETAILS(id, role);

    if (res && res.s && res.r) {
      setData((p) => ({ ...p, form: res.r }));
    }
  };

  useEffect(() => {
    getUser();
    getKYC();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        width: "100%",
        flexDirection: "column",
        mb: 3,
        gap: "1.4rem",
      }}
    >
      <MultiSelection
        multiple={false}
        names={data.roles.split(",")}
        handleChange={handleSelectRole}
        selected={selectedRole}
        lable="Role"
      />

      <ViewQueAns
        data={data.form}
        openApproveReject={openApproveReject}
        openHistoryDailog={openHistoryDailog}
      />

      {approveOpen && (
        <ApproveRejectDailog
          open={approveOpen}
          handleOnClose={handleApproveOnClose}
          id={approveRejectData.id}
          status={approveRejectData.status}
        />
      )}

      {historyOpen && (
        <ShowHistoryDailog
          open={historyOpen}
          handleClose={() => {
            setHistoryId(0);
            setHistoryOpen(false);
          }}
          id={historyId}
        />
      )}
    </Box>
  );
}

export default QuestingAnswer;

function ViewQueAns({ data, openApproveReject, openHistoryDailog }) {
  return data?.map((v, i) => {
    return (
      <Paper
        key={i}
        sx={{ width: "100%", boxShadow: "3px 3px 7px 0px #00000040" }}
      >
        <Box key={v.id}>
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              px: 2,
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {v.title}
            </Typography>
          </Box>
          <Box sx={{ px: 2 }}>
            {v.questions.map((j, q) => {
              return (
                <Box
                  key={j.id}
                  sx={{
                    borderBottom: "solid 1px #c0c0c090",
                    ":last-child": {
                      borderBottom: "none",
                    },
                    my: "6px",
                  }}
                >
                  <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    <Typography
                      sx={{
                        color: "#252525",
                        fontSize: "12px",
                        mb: "0.3rem",
                      }}
                    >
                      {j.name !== "please select" ? j.name : null}
                    </Typography>
                    {j.is_approval == 1 &&
                      j.role_form_pages_records_id != null ? (
                      <Box
                        sx={{
                          // width: "100%",
                          textAlign: "right",
                        }}
                      >
                        <Tooltip title="View History">
                          <Button
                            disableElevation
                            sx={{
                              backgroundColor: "#F8B03F",
                              padding: "0px",
                              ":hover": {
                                backgroundColor: "#F8B03F",
                              },
                              py: "0.4rem",
                            }}
                            onClick={() => {
                              openHistoryDailog(
                                j.role_form_pages_records_id
                              );
                            }}
                            variant="contained"
                          >
                            <Typography
                              sx={{
                                textDecoration: "none",
                                color: "white",
                                textTransform: "none",
                                fontSize: "12px",
                              }}
                            >
                              History
                            </Typography>
                          </Button>
                        </Tooltip>
                      </Box>
                    ) : null}
                  </Box>

                  <Typography
                    sx={{

                      color: "#252525",
                      fontSize: "12px",
                    }}
                  >
                    {((j?.answer === "" || j?.answer === null) && j?.answer2 === null) ? '-' : j?.answer ? j?.answer : j?.answer2}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#252525",
                      fontSize: "12px",
                      padding: (j?.name === "EXPECTED PER HOUR RATE") ? '5px 0px' : "0px",
                      borderTop:(j?.name === "EXPECTED PER HOUR RATE") ? 'solid 1px #c0c0c090' : "0px"
                    }}
                  >
                    {((j?.lhistory?.answer === "" || j?.lhistory?.answer === null) && j?.lhistory?.answer2 === null) ? '-' : j?.lhistory?.answer ? j?.lhistory?.answer : j?.lhistory?.answer2
                    }
                  </Typography>
                  <Box sx={{ mb: 1, display: "flex", alignItems: "start" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        {j.data_type2 == "photos" && (
                          <Typography>
                            {/* {j.lhistory != null ? j.lhistory.answer : j.answer} */}
                            {j.lhistory != null ? j.lhistory.answer : "-"}
                          </Typography>
                        )}

                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <AnswerView data={j} />
                        {/* //j.lhistory && j.is_approval == 1 */}

                        <Stack width="30%" height="60%">
                          {/* {j.lhistory == null && j.is_approval == 1 ? ( */}
                          {j.lhistory && j.is_approval == 1 ? (
                            <Box
                              sx={{
                                width: "100%",
                                textAlign: "right",
                                flex: 1,
                              }}
                            >
                              <Button
                                disableElevation
                                sx={{
                                  // backgroundColor: "#F8B03F",
                                  padding: "0px",
                                  ":hover": {
                                    backgroundColor: "transparent",
                                  },
                                  cursor: "default",
                                }}
                                onClick={null}
                                variant="text"
                                size="small"
                              >
                                <Typography
                                  sx={{
                                    textDecoration: "none",
                                    color:
                                      j.lhistory?.status == 1
                                        ? "#F8B03F"
                                        : j.lhistory?.status == 0
                                          ? "blue"
                                          : "red",
                                    textTransform: "none",
                                    fontSize: "13px",
                                  }}
                                >
                                  {j.lhistory?.status == 0
                                    ? "Pending"
                                    : j.lhistory?.status == 1
                                      ? "Activate"
                                      : "Rejected"}
                                </Typography>
                              </Button>
                            </Box>
                          ) : null}

                          {j.lhistory &&
                            j.lhistory?.status == 0 &&
                            j.is_approval == 1 ? (
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-end",
                                gap: "5px",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  openApproveReject(j.lhistory.id, 1)
                                }
                                disableElevation
                                sx={{
                                  backgroundColor: "#039600",
                                  padding: "0px",
                                  ":hover": {
                                    backgroundColor: "#039600",
                                  },
                                  px: "0.6rem",
                                  py: "0.4rem",
                                }}
                                variant="contained"
                              >
                                <Typography
                                  sx={{
                                    textDecoration: "none",
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "12px",
                                  }}
                                >
                                  Approve
                                </Typography>
                              </Button>

                              <Button
                                onClick={() =>
                                  openApproveReject(j.lhistory.id, -1)
                                }
                                disableElevation
                                sx={{
                                  backgroundColor: "red",
                                  padding: "0px",
                                  ":hover": {
                                    backgroundColor: "red",
                                  },
                                }}
                                variant="contained"
                                size="small"
                              >
                                <Typography
                                  sx={{
                                    textDecoration: "none",
                                    color: "white",
                                    textTransform: "none",
                                    fontSize: "12px",
                                  }}
                                >
                                  Reject
                                </Typography>
                              </Button>
                            </Box>
                          ) : null}
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    );
  });
}

function AnswerView({ data }) {
  if (data.data_type2 != null) {
    if (
      data.data_type2 == "photos" &&
      (data.answer2 != null ||
        (data.lhistory != null && data.lhistory.answer2 != null))
    ) {
      let j = [];

      try {
        j = JSON.parse(
          data.lhistory != null
            ? data?.lhistory?.answer2 ?? "[]"
            : data?.answer2 ?? "[]"
        );
      } catch (e) {
        j = [];
      }

      return (
        <Box>
          {j.map((v) => {
            return <ImageConvert key={v} v={v} />;
          })}
        </Box>
      );
    } else if (
      data.data_type2 == "photo" &&
      (data.answer2 != null ||
        (data.lhistory != null && data.lhistory.answer2 != null))
    ) {
      let j = [];

      try {
        j = JSON.parse(
          data.lhistory != null
            ? data?.lhistory?.answer2 ?? "[]"
            : data?.answer2 ?? "[]"
        );
      } catch (e) {
        j = [];
      }

      return (
        <Box>
          {j.map((v) => {
            return <ImageConvert key={v} v={v} />;
          })}
        </Box>
      );
    } else {
      return (
        <>
          <Typography></Typography>
        </>
      );
    }
  } else if (data.data_type == "boolean") {
    return <Typography> {data.answer == 1 ? "Yes" : "No"}</Typography>;
  } else if (data.data_type == "multichoice") {
    const t = JSON.parse(data.choices);
    return (
      <Box>
        {t.map((v) => {
          return (
            <Chip
              sx={{
                m: 0.5,
                backgroundColor: data?.answer?.split(",").includes(v)
                  ? "#F8B03F"
                  : "#CEE5FF",
                outline: "none",
                border: "none",
                borderRadius: "5px",
                p: "1px",
              }}
              key={v}
              label={v}
              variant={`${data?.answer?.split(",").includes(v) ? "filled" : "outlined"
                }`}
            />
          );
        })}
      </Box>
    );
  } else if ((data.data_type = "datetime")) {
    return (
      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
        {" "}
        {data.answer ?? "-"}
      </Typography>
    );
  } else {
    return (
      <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
        {" "}
        {data.answer ?? "-"}
      </Typography>
    );
  }
}

function ImageConvert({ v }) {
  const [i, setI] = useState("");
  let dataUrl = null;

  useEffect(() => {
    const getData = async () => {
      if (v.includes("heic")) {
        //console.log("aaya")
        try {
          const response = await fetch(
            API_URL.baseUrl + "/uploads/provider/forms/" + v
          );
          const blob = await response.blob();
          dataUrl = await heic2any({ blob });
          console.log(dataUrl);
          setI(dataUrl);
        } catch (e) {
          console.log(`err ${e}`);
        }
      } else {
        // console.log("aayaTTT")
        setI(API_URL.baseUrl + "/uploads/provider/forms/" + v);
      }
    };
    getData();
  }, []);

  return (
    <img
      onClick={() =>
        window.open(API_URL.baseUrl + "uploads/provider/forms/" + v)
      }
      style={{
        cursor: "pointer",
        height: "60px",
        width: "60px",
        marginTop: "8px",
        marginBottom: "8px",
        borderRadius: "5px",
        marginRight: "0.5rem",
      }}
      key={v}
      src={v.includes("heic") && i != "" ? URL.createObjectURL(i) : i}
    />
  );
}
