import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import Rate from "../../../../../Assets/Rate.png";

const Cards = ({ profile, defaultSelected = false }) => {
  const [state, setState] = useState(defaultSelected);

  return (
    <Stack
      width="8.7rem"
      px="0.6rem"
      pt="0.5rem"
      pb="1rem"
      borderRadius="6px"
      boxShadow="3px 3px 9px 0px #00000040"
      justifyContent="center"
      onClick={() => setState(!state)}
      bgcolor={state && "#FFF4E3"}
      style={{ cursor: "pointer" }}
    >
      <Stack direction="row">
        <Box
          width="90%"
          display="flex"
          justifyContent="center"
          paddingTop="0.8rem"
          marginBottom="0.3rem"
        >
          <img
            src={profile}
            style={{
              objectFit: "fill",
              width: "3.5rem",
              height: "3.5rem",
            }}
            alt=""
          />
        </Box>
        <Box>{state && <img src={""} style={{ width: "1rem" }} alt="" />}</Box>
      </Stack>

      <Typography fontSize="1.050rem">Chinmay Modi</Typography>

      <Stack gap="0.150rem">
        <Stack direction="row" alignItems="center" gap="0.2rem">
          <Typography fontSize="0.7rem">4.3</Typography>
          <img
            src={Rate}
            style={{
              width: "0.6rem",
              height: "0.6rem",
              objectFit: "fill",
              userSelect: "none",
            }}
            alt=""
          />
        </Stack>
        <Typography fontSize="0.7rem" color="#252525CC">
          +919414888466
        </Typography>
        <Typography fontSize="0.7rem" color="#252525CC">
          Goa, Ahmedabad
        </Typography>
        <Box
          width="45%"
          bgcolor="#FFE0B2"
          px="0.4rem"
          py="0.2rem"
          borderRadius="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography color="#252525CC" fontSize="0.7rem">
            20 min
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Cards;
