import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { addDays, format, lastDayOfMonth, subDays } from "date-fns";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomProvider, DateRangePicker } from "rsuite";
import { API_URL } from "../../../Apis/config";
import { GETROLE } from "../../../Apis/podian_api";
import { GETPROVIDERS, GETPROVIDERSAVL } from "../../../Apis/service_api";
import SplitButton from "../../../Component/Button/SplitButton";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import BOD from "../../../Component/Date/BOD";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { useTheme } from "@mui/material";

function Leave() {
  const navigator = useNavigate();
  const theme = useTheme();
  
  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },
    {
      field: "photo",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 130,
      renderCell: (params) => (
        <Avatar
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <Typography>
          {params.row.first_name + " " + params.row.last_name}
        </Typography>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        const d = [];
        // console.log(params.row.roles);
        for (const v of params.row.roles) {
          // console.log("role",v.name);
          d.push(" " + v.name);
        }
        // console.log("r",d.join(","));
        return <Typography>{d.join(",")}</Typography>;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Phone",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dob",
      headerName: "BOD",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <BOD date={params.value} />;
      },
    },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   width: 130,
    //   headerAlign: "center",
    //   align: "center",
    // },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <SplitButton
          key={params.id}
          isEdit={null}
          isDelete={null}
          isView={1}
          onView={() => navigator("/service-provider/list/" + params.row.id)}
          title={params.value}
        />
      ),
    },
  ];

  // const viewData = (v) => {
  //   var t = rows.filter((e) => e.id === v);
  //   navigator("/order/list/" + t[0].id);
  // };
  // const deleteData = (v) => {};

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRole, setIsLoadingRole] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [selected, setSelected] = useState([]);
  const [statusValue, setStatus] = useState(["All"]);

  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [dateRange, setDateRange] = useState({});

  const getData = async (d = {}, v = 0) => {
    setIsLoading(true);

    const data = await GETPROVIDERSAVL({
      count: v ? 0 : rows.length,
      dateRange:d
    });

    if (data && data.s && !v) {
      setRows([...rows, ...(data ? data.r ?? [] : [])]);
    } else {
      setRows([...(data ? data.r ?? [] : [])]);
    }

    setIsLoading(false);
    if (v) {
      setIsCall(true);
    }
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData({ role: [], statusV: "All" });
  }, []);

  const callBack = async (l) => {
    if (l >= rows.length && isCall) {
      await getData({});
    }
  };

  const handleChange = async (e) => {
    const {
      target: { value },
    } = e;
    setSelected([...value]);
    await getData({ role: value, statusV: statusValue[0] });
  };

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setStatus([value]);
    getData({ role: selected, statusV: value });
  };

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
      getData(d, 1);
    } else {
      setDateRange(v);
      getData(v, 1);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="start"
    >
      <Box width="100%" display="flex" justifyContent="end" alignItems="center">
        <CustomProvider theme={theme.palette.mode}>
          <DateRangePicker
            placement="bottomEnd"
            style={{ marginBottom: "10px" }}
            size="lg"
            onChange={(v) => handleSelectDate(v ?? {})}
            ranges={[
              {
                label: "Yesterday",
                value: [addDays(new Date(), -1), addDays(new Date(), -1)],
              },
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Tomorrow",
                value: [addDays(new Date(), 1), addDays(new Date(), 1)],
              },
              {
                label: "Last 7 days",
                value: [subDays(new Date(), 6), new Date()],
              },
              {
                label: "This week",
                value: [
                  subDays(
                    new Date(
                      `${firstdayW.getFullYear()}-${
                        firstdayW.getMonth() + 1
                      }-${firstdayW.getDate()}`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${lastdayW.getFullYear()}-${
                          lastdayW.getMonth() + 1
                        }-${lastdayW.getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This month",
                value: [
                  subDays(
                    new Date(
                      `${today.getFullYear()}-${today.getMonth() + 1}-01`
                    ),
                    0
                  ),
                  addDays(
                    new Date(
                      new Date(
                        `${today.getFullYear()}-${
                          today.getMonth() + 1
                        }-${lastDayOfMonth(today).getDate()}`
                      )
                    ),
                    0
                  ),
                ],
              },
              {
                label: "This year",
                value: [
                  subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                  addDays(
                    new Date(new Date(`${today.getFullYear()}-12-31`)),
                    0
                  ),
                ],
              },
            ]}
          />
        </CustomProvider>
      </Box>
      <DataGrideView
        rows={rows ?? []}
        columns={columns}
        callBack={callBack}
        isLoading={isLoading}
      />
    </Box>
  );
}

export default Leave;
