import { API, API_URL, GETAPI, POSTAPI } from "./config";

export const GETCOUPEN = async (search, count) => {
  let query = "";

  if (search) {
    query += "&search=" + search;
  }

  if (count) {
    query += "&count=" + count;
  }
  const res = await GETAPI(API_URL.coupenGet, query);
  return res;
};

export const GETCOUPENCUSTOMER = async (cityId, userId) => {
  let query = "";
  query += "&city_id=" + cityId + "&user_id=" + userId;
  const res = await GETAPI(API_URL.coupenForCustomer, query);

  return res;
};

export const COUPENAPPLYTOORDER = async (id, code, userId) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("code", code);
  formData.append("user_id", userId);
  const res = await POSTAPI(API_URL.coupenApplyToOrder, formData);

  return res;
};

export const COUPENCanAPPLYTOORDER = async (orderId, code, cityId, userId) => {
  const formData = new FormData();
  formData.append("id", orderId);
  formData.append("code", code);
  formData.append("city_id", cityId);
  formData.append("user_id", userId);

  const res = await POSTAPI(API_URL.coupenApplyToOrder, formData);

  return res;
};
export const DELETECOUPEN = async (id) => {
  let query = "";

  if (id) {
    query += "&id=" + id;
  }
  const res = await GETAPI(API_URL.coupenDelete, query);

  return res;
};

export const CREATECOUPON = async (data) => {
  const formData = new FormData();

  formData.append("affluence_user_id", data.affluence_user_id ?? 0);
  formData.append("assigned_user_id", data.assigned_user_id ?? 0);

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.orders_id) {
    formData.append("orders_id", data.orders_id);
  }

  if (data.city_id) {
    formData.append("city_ids", data.city_id);
  } else {
    formData.append("city_ids", 0);
  }

  if (data.nt_title) {
    formData.append("nt_title", data.nt_title);
  }

  if (data.nt_body) {
    formData.append("nt_body", data.nt_body);
  }

  if (data.code) {
    formData.append("code", data.code);
  }

  if (data.affluence_earn) {
    formData.append("affluence_earn", data.affluence_earn);
  }

  if (data.is_affluence_earn_per == 0 || data.is_affluence_earn_per == 1) {
    formData.append("is_affluence_earn_per", data.is_affluence_earn_per);
  }

  if (data.min_amount) {
    formData.append("min_amount", data.min_amount);
  }

  if (data.discount) {
    formData.append("discount", data.discount);
  }

  if (data.isPer == 0 || data.isPer == 1) {
    formData.append("isPer", data.isPer);
  }

  if (data.max_amount) {
    formData.append("max_amount", data.max_amount);
  }

  if (data.start_dt) {
    formData.append("start_dt", data.start_dt);
  }

  if (data.end_dt) {
    formData.append("end_dt", data.end_dt);
  }

  if (data.apply_limit) {
    formData.append("apply_limit", data.apply_limit);
  }

  const res = await POSTAPI(
    data.id ? API_URL.coupenUpdate : API_URL.coupenAdd,
    formData
  );
  return res;
};

export const ACTIVATEDEACTIVATECOUPEN = async (status, id) => {
  let query = "&id=" + id;

  const res = await GETAPI(
    status ? API_URL.coupenActivate : API_URL.coupenDeactivate,
    query
  );

  return res;
};
