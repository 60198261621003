import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";

const DateField = ({ label, value, setValue, width, icon, noIcon = false }) => {
  return (
    <Box sx={{ width }}>
      <Box
        display="flex"
        gap=".4rem"
        alignItems="center"
        width="90%"
        mb=".2rem"
      >
        {!noIcon ? (
          icon ? (
            <img
              style={{ width: "10px", objectFit: "fill" }}
              src={icon}
              alt=""
            />
          ) : (
            <img
              style={{ width: "10px", objectFit: "fill" }}
              src={require("../../Assets/calendar-icon.png")}
              alt=""
            />
          )
        ) : null}
        <Typography fontSize="12px">{label}</Typography>
      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={(newValue) => setValue(newValue)}
          renderInput={(props) => (
            <TextField
              sx={{
                width: "100%",
                height: "2.3rem",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                bgcolor: "#F6F6F6",
                borderRadius: "5px",
                "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
                  fontSize: "0.8rem",
                },
                display: "flex",
                justifyContent: "center",
              }}
              size="small"
              {...props}
            />
          )}
          disableOpenPicker
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateField;
