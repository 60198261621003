import { API_URL, GETAPI, POSTAPI } from "./config";

export const GET_SUB_ADMIN_DETAILS = async (id) => {
  const res = await GETAPI(API_URL.getSubAdminDetails, "&user_id=" + id);
  return res;
};

export const SAVE_SUB_ADMIN_DEYAILS = async (
  cityIds,
  modulesId,
  rightsId,
  userId
) => {
  const formdata = new FormData();
  formdata.append("modules_ids", modulesId.length == 0 ? 0 : modulesId.join(","));
  formdata.append("city_ids", cityIds.length == 0 ? 0 : cityIds.join(","));
  formdata.append("access_right_ids", rightsId.length == 0 ? 0 : rightsId.join(","));
  formdata.append("user_id", userId);
  const res = await POSTAPI(API_URL.saveSubAdminDetails, formdata);
  return res;
};

export const DELETE_SUB_ADMIN = async (
  id
) => {
  const res = await GETAPI(API_URL.deleteSubAdminList, "&id="+id);
  return res;
};

export const GET_SUB_ADMIN_LIST = async (count) => {
  const res = await GETAPI(API_URL.getSubAdminList);
  return res;
};
