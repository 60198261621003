import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ToastShow } from "../Toast/Toast";
import ButtonView from "../Button/ButtonView";

function CancelDailog({
  open,
  handleOnClose,
  title = "Delete Banner",
  content = "Are you sure, You want to delete this banner.",
  msg = "Reason",
}) {
  const [reason, setReason] = useState("");

  const submit = () => {
    if (reason?.length <= 0) {
      ToastShow("Please fill " + msg);
    } else {
      handleOnClose(1, reason);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0, null)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Typography
          sx={{ mx: 1.8, mt: 1, fontSize: "20px", fontWeight: "500" }}
        >
          {title}
        </Typography>
        <DialogContentText sx={{ width: "350px", px: 1.8, mb: 2 }}>
          {content}
        </DialogContentText>
        <Box
          sx={{
            mx: 1.5,
          }}
        >
          <TextField
            fullWidth
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder={`Enter ${msg}`}
            label={msg}
            variant="outlined"
            size="small"
            sx={{ mb: 1 }}
          />
        </Box>
        <DialogActions>
          <ButtonView
            lable="Cancel"
            color="#E50000"
            bgcolor="#E5000033"
            fontSize="14px"
            sx={{ width: "100%" }}
            hoverBg="pink"
            onClick={() => handleOnClose(0, null)}
          />

          <ButtonView
            lable="Yes"
            fontSize="14px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={() => submit()}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CancelDailog;
