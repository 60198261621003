import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Avatar, Grid, Paper, Stack, Typography } from "@mui/material";

import { Box } from "@mui/system";
import { API_URL } from "../../Apis/config";
import { GrClose } from "react-icons/gr";
import ButtonView from "../Button/ButtonView";
import SelectIcon from "../../Assets/Icons for pages/SelectIcon.png";

export default function UnavailableDailog({ data, handleClose, open }) {
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          width: "100vw",

          backdropFilter: "blur(3px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClose={handleClose}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          mx="1rem"
          mt="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography fontSize="18px" fontWeight="500">
            Unavailability
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        <DialogContent
          sx={{
            height: "100vh",
            width: "400px",
            paddingTop: "0px",
            flex: 1,
            overflowY: "auto",
          }}
        >
          <Typography fontWeight="500" fontSize="1.1rem">
            Unavailable Provider
          </Typography>

          {data.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UnavailableView data={data} handleSelected={() => {}} />
            </Box>
          ) : (
            <Box>
              <Typography>No data available</Typography>
            </Box>
          )}
        </DialogContent>
        {/* <DialogActions>
          <ButtonView
            lable="Close"
            fontSize="1.130rem"
            fontWeight="500"
            sx={{ py: "0.6rem", width: "100%" }}
            onClick={() => {
              handleClose(0);
            }}
          />
        </DialogActions> */}
      </Dialog>
    </>
  );
}

function UnavailableView({ data, handleSelected }) {
  return !data.length ? null : (
    <Box>
      <DetailsView data={data} handleSelected={handleSelected} />
    </Box>
  );
}

function DetailsView({ data, handleSelected }) {
  return (
    <Grid container columns={3} columnGap={1.5} rowGap={1.5}>
      {data.map((v, i) => {
        return (
          <ItemView key={v.user_id} v={v} handleSelected={handleSelected} />
        );
      })}
    </Grid>
  );
}

function ItemView({ v, handleSelected }) {
  return (
    <Paper
      component={"div"}
      elevation={0}
      onClick={() => {
        handleSelected(v);
      }}
      sx={{
        display: "flex",
        p: 2,
        width: "100%",
      }}
    >
      <Stack
        width="100%"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        borderBottom="solid 0.5px #c0c0c03e"
        gap="10px"
        pb="3px"
      >
        <Avatar
          variant="rounded"
          sx={{
            objectFit: "cover",
            height: "35px",
          }}
          src={`${API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb}`}
        />
        <Typography sx={{ fontSize: "13.5px", wordWrap: "break-word" }}>
          {(v.first_name ?? "") + " " + (v.last_name ?? "")}
        </Typography>
      </Stack>
    </Paper>
  );
}
