import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../../Toast/Toast";
import Compressor from "compressorjs";
import { API_URL } from "../../../Apis/config";
import { ADD_UPDATE_ANNOUNCEMENT } from "../../../Apis/web/announcement_api";

function AddUpdateAnnouncementDailog({ open, handleOnClose, row }) {
  // console.log(row)
  const [photoOne, setPhotoOne] = React.useState(null);
  const [photoTwo, setPhotoTwo] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = React.useState(row ? row.name : "");
  const [description, setDescription] = React.useState(
    row ? row.description : ""
  );

  const handleOnSubmit = async () => {
    if (!row && !photoOne) {
      ToastShow("Please select photo one");
    } else if (!row && !photoTwo) {
      ToastShow("Please select photo two");
    } else if (!name) {
      ToastShow("Please enter name");
    } else if (!description) {
      ToastShow("Please enter description");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_ANNOUNCEMENT({
        photoTwo: photoTwo,
        photoOne: photoOne,
        row: row,
        name: name,
        description: description,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>
          {!row ? "Add Announcement" : "Update Announcement"}
        </DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* photo one */}
          <Typography
          sx={{
            textAlign:"start",
            width:"start",
            alignSelf:"flex-start",
            ml:3  
          }}
          >Click here to pick the cover photo</Typography>
          <label htmlFor="photoone" style={{ width: "100%" }}>
            <Box
              sx={{
                height: "150px",
                width: "85%",
                border: "dotted .3px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                objectFit: "fill",
                mx: "auto",
                p: "1px",
                cursor:"pointer"
              }}
            >
              {row?.photo_1 || photoOne ? (
                <img
                  style={{ borderRadius: "5px" }}
                  height="150px"
                  width="100%"
                  src={
                    photoOne
                      ? URL.createObjectURL(photoOne)
                      : API_URL.baseUrl +
                        API_URL.webAnnouncementImage +
                        row?.photo_1
                  }
                  alt="photo image"
                />
              ) : (
                <Typography sx={{ width: "85%" }}>Click here to pick the cover photo</Typography>
              )}
            </Box>
          </label>
          <input
            id="photoone"
            hidden
            accept="image/*"
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                new Compressor(event.target.files[0], {
                  quality: 0.8,
                  success: (compressedResult) => {
                    setPhotoOne(compressedResult);
                  },
                });
              }
            }}
          />

          {/* photo two */}
          <Typography
          sx={{
            textAlign:"start",
            width:"start",
            alignSelf:"flex-start",
            ml:3,
            mt:2,
          }}
          >Click here to pick the title photo</Typography>

          <label style={{ width: "100%" }} htmlFor="phototwo">
            <Box
              sx={{
                height: "150px",
                width: "85%",
                border: "dotted .3px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                objectFit: "fill",
                p: "1px",
                mx:"auto",
                
                cursor:"pointer"
              }}
            >
              {row?.photo_2 || photoTwo ? (
                <img
                  style={{ borderRadius: "5px" }}
                  height="150px"
                  width="100%"
                  src={
                    photoTwo
                      ? URL.createObjectURL(photoTwo)
                      : API_URL.baseUrl +
                        API_URL.webAnnouncementImage +
                        row?.photo_2
                  }
                  alt="photo image"
                />
              ) : (
                <Typography sx={{width:'85%'}}>Click here to pick the title photo</Typography>
              )}
            </Box>
          </label>

          <input
          id="phototwo"
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      setPhotoTwo(compressedResult);
                    },
                  });
                }
              }}
            />

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            size="small"
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            sx={{
              width: "85%",
              mt: 2,
            }}
            multiline
            rows={3}
            size="small"
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text" sx={{color:"#F8B03F"}}>
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text" sx={{color:"#F8B03F"}}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddUpdateAnnouncementDailog;
