import { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { GET_PODIANS_PAYOUT } from "../../../Apis/payout_api";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import DataGridCover from "../../../Component/DataGridCover/DataGridCover";
import { API_URL } from "../../../Apis/config";
import SplitButton from "../../../Component/Button/SplitButton";
import SearchBox from "../../../Component/SearchBox/SearchBox";
import { ToastShow } from "../../../Component/Toast/Toast";

const filter = ["All", "Pending for payout", "Payout done"];
const order = ["Credit", "Balance", "Paid", "Lock"];

const PayoutList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterS, setFilterS] = useState(["All"]);
  const [orderS, setOrderS] = useState(["Credit"]);
  const [search, setSearch] = useState("");

  const getData = async ({ cityIds, filter, order, search }) => {
    setLoading(true);

    const res = await GET_PODIANS_PAYOUT({
      city_ids: cityIds,
      filter: getFilterValue(filter),
      order: getOrderValue(order),
      search: search,
    });

    if (res && res.s) {
      const dataWithCustomId = res.r?.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
      setData(dataWithCustomId ?? []);
    } else {
      console.log(res.m);
    }

    setLoading(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography>{params.api.getRowIndex(params.row.id) + 1}</Typography>
        );
      },
    },
    {
      field: "photo",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 140,
      minWidth: 140,
      renderCell: (params) => (
        <Avatar
          sx={{ width: "1.9rem", height: "1.9rem", objectFit: "fill" }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.row.photo}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 240,
      minWith: 220,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px">
            {params.row.first_name && params.row.last_name
              ? `${params.row.first_name} ${params.row.last_name}`
              : params.row.first_name
              ? params.row.first_name
              : params.row.last_name
              ? params.row.last_name
              : "-"}
          </Typography>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 120,
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px">{params.row.mobile ?? "-"}</Typography>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px">{params.row.balance ?? "-"}</Typography>
        );
      },
    },
    {
      field: "total_credit",
      headerName: "Total Credit",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px">
            {params.row.total_credit ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "total_debit",
      headerName: "Total Debit",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px">
            {params.row.total_debit ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "total_lock",
      headerName: "Total Lock",
      width: 100,
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12px">
            {params.row.total_lock ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          isEdit={null}
          isDelete={null}
          isView={1}
          onView={() => window.open("/payout/" + params.row.user_id)}
          title={params.value}
        />
      ),
    },
  ];

  const handleFilter = (e) => {
    const value = e.target.value;
    setFilterS([value]);
    getData({ cityIds: "", filter: value, order: orderS[0], search: search });
  };

  const handleOrder = (e) => {
    const value = e.target.value;
    setOrderS([value]);
    getData({ cityIds: "", filter: filterS[0], order: value, search: search });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (!value) {
      getData({
        cityIds: "",
        filter: filterS[0],
        order: orderS[0],
        search: value,
      });
    }
  };

  const getFilterValue = (v) => {
    switch (v) {
      case "All":
        return "0";
      case "Pending for payout":
        return "1";
      case "Payout done":
        return "2";
      default:
        return "0";
    }
  };

  const getOrderValue = (v) => {
    switch (v) {
      case "Credit":
        return "0";
      case "Balance":
        return "1";
      case "Paid":
        return "2";
      case "Lock":
        return "3";
      default:
        return "0";
    }
  };

  useEffect(() => {
    getData({
      cityIds: "",
      filter: filterS[0],
      order: orderS[0],
      search: "",
    });
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
              justifyContent: "space-between",
            }}
          >
            <SearchBox
              search={search}
              onChange={handleSearch}
              onClick={() => {
                if (search) {
                  getData({
                    cityIds: "",
                    filter: filterS[0],
                    order: orderS[0],
                    search: search,
                  });
                } else {
                  ToastShow("Please write something to search...");
                }
              }}
              cursor="pointer"
            />

            <Box display="flex" gap="1rem">
              <MultiSelection
                selected={filterS}
                handleChange={handleFilter}
                names={filter}
                lable="Status"
                multiple={false}
              />

              <MultiSelection
                selected={orderS}
                handleChange={handleOrder}
                names={order}
                lable="Order"
                multiple={false}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              boxShadow: "3px 3px 7px 0px #00000040",
              paddingBottom: ".2rem",
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <DataGrideView
              isLoading={loading}
              rows={data ?? []}
              columns={columns}
              colHeight="3rem"
              sx={{ height: "77vh" }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PayoutList;
