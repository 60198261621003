import React from "react";
import { Box, TextField } from "@mui/material";

function TextFieldView2({
  size = "medium",
  onChange,
  value,
  type = "text",
  variant = "outlined",
  sx = {},
  required = false,
  helperText = "",
  width,
  label,
  InputLabelProps,
  placeholder,
  bgcolor = "#F5F5F5",
}) {
  return (
    <TextField
      size={size}
      helperText={helperText}
      required={required}
      label={label}
      sx={{
        ...sx,
        backgroundColor: bgcolor,
        borderRadius: "6px",
        width: width,
        "& fieldset": { border: "none" },
        "& .MuiFormLabel-root": {
          color: "#757575",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "primary.main",
        },
        "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
          color: "black",
          fontWeight: "500",
        },
      }}
      // label={label}
      placeholder={placeholder}
      InputLabelProps={InputLabelProps}
      type={type}
      value={value}
      onChange={onChange}
      variant={variant}
    />
  );
}

export default TextFieldView2;
