import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import TextFieldView from "../TextField/TextFieldView";
import { ColorPicker } from "material-ui-color";
import { ToastShow } from "../Toast/Toast";

function AddLableDailog({ open, handleOnClose, row }) {
  const [name, setName] = useState(row?.name ?? "");
  const [color, setColor] = useState(row?.color ? "#" + row.color : "#E8D1D1");
  const [isLoading, setILoading] = useState(false);

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please enter the label name");
    } else {
        const t = color.replace("#","");
        handleOnClose(1,{color:t,name,id:row?.id ?? 0});
    }
  };

  const handleColor = (v) => {
    setColor(v);
  };

  const handleName = (v) => {
    setName(v);
  };
  return (
    <Dialog open={open} onClose={() => handleOnClose(0)}>
      <DialogTitle>{row ? "Update Label" : "Add Label"}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", mt: 1 }}>
          <TextFieldView
            value={name}
            onchange={handleName}
            label="Name"
            type="text"
            width="100%"
            size="small"
            sx={{ width: "100%" }}
          />
        </Box>

        <Box
          sx={{
            width: "350px",
            mt: 1,
            display: "flex",
            alignItems: "center",
            border: "solid 0.5px #00000014",
            borderRadius: "25px",
            px: "8px",
          }}
        >
          <ColorPicker
            hideTextfield
            value={color}
            onChange={(v) => {
              //   console.log(v);
              //   console.log("color", "=>", color, "==>", "#" + v.hex);
              handleColor("#" + v.hex);
            }}
          />
          <Chip
            size="small"
            sx={{ backgroundColor: color }}
            label={`Color code - ${color}`}
          />
        </Box>
        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              {row ? "Update" : "Submit"}
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default AddLableDailog;
