import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getDetailsOfOtherProviderToOrderItems,
  getDetailsOfProviderToOrderItems,
} from "../../Apis/order_api";
import { useState } from "react";
import { Box } from "@mui/system";
import { API_URL } from "../../Apis/config";
import { ToastShow } from "../Toast/Toast";
import TextFieldView from "../TextField/TextFieldView";
import { motion } from "framer-motion";
import { GrClose } from "react-icons/gr";
import ButtonView from "../Button/ButtonView";

let count = 0;
export default function OrderSwapDailog({
  status = 0,
  open,
  handleClose,
  orderItemId,
  handleSubmit,
  qty,
  service,
  userID,
  selectedUserID,
}) {
  //   console.log("orderItemId", orderItemId);

  const [data, setData] = useState(null);
  const [dataNot, setDataNot] = useState(null);
  const [dataOther, setOtherData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [approve, setApprove] = useState([]);
  const [disApprove, setDisApprove] = useState([]);
  const [search, setSearch] = useState();

  function handleSelected(v) {
    const i = selected.indexOf(v);
    if (!(i > -1)) {
      setSelected([v]);
    }
  }

  function deleteHandleSelected(v) {
    const i = selected.indexOf(v);
    if (i > -1) {
      setSelected([]);
    }
  }

  const getData = async () => {
    setIsLoading(true);
    const d = await getDetailsOfProviderToOrderItems(orderItemId);
    setData(d.r ?? []);
    await getOtherDetails();
    setIsLoading(false);
  };

  const getDataNoInterested = async () => {
    const d = await getDetailsOfProviderToOrderItems(orderItemId, 0);
    setDataNot(d?.r ?? []);
  };

  async function getOtherDetails(search = "", flag = 0) {
    if (flag) {
      const d = [];
      setOtherData([...d]);
    }

    // console.log(dataOther.length,"count");
    const d = await getDetailsOfOtherProviderToOrderItems(
      orderItemId,
      search,
      count
    );

    count += (d.r ?? []).length;
    setOtherData((p) => [...p, ...(d.r ?? [])]);

    if ((d.r ?? []).length) {
      await getOtherDetails(search);
    }
    return;
  }

  const onChangeHandle = (e) => {
    if (!e) {
      getOtherDetails(e, 0);
    }
    setSearch(e);
  };

  useEffect(() => {
    count = 0;
    getData();
    getDataNoInterested();
  }, []);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          width: "100vw",
          backdropFilter: "blur(3px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
          display: "flex",
          justifyContent: "flex-end",
        }}
        onClose={handleClose}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          mx="1rem"
          mt="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="18px" fontWeight="500">
            {service}
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          />
        </Stack>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <Box mx="50rem">
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <DialogContent sx={{ height: "auto", paddingTop: "0px" }}>
            <Box display="flex" alignItems="center" overflow="hidden">
              <TextField
                placeholder="Search"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alingItems: "center",
                  padding: "0.7rem 0",
                }}
                sx={{
                  width: "97%",
                  my: "0.9rem",
                  ml: "0.4rem",
                  backgroundColor: "white",
                  boxShadow: "3px 3px 16px 0px #00000026",
                  borderRadius: "5px",
                  "& fieldset": { border: "none" },
                  "& .MuiFormLabel-root": {
                    color: "#969696",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .css-j43tob-MuiInputBase-root-MuiOutlinedInput-root": {
                    fontSize: "14px",
                  },
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0",
                    paddingLeft: ".5rem",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          count = 0;
                          getOtherDetails(search, 1);
                        }}
                      >
                        <img
                          style={{
                            width: "13px",
                            objectFit: "fill",
                            cursor: "default",
                          }}
                          src={require("../../Assets/Search_icon.png")}
                          alt=""
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onChangeHandle(e.target.value);
                }}
              />
            </Box>

            {selected.length ? (
              <ChipView
                data={selected}
                deleteHandleSelected={deleteHandleSelected}
              />
            ) : null}

            {data.length || dataOther.length || dataNot.length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InterestedView
                  userID={selectedUserID}
                  data={data}
                  handleSelected={handleSelected}
                />
                <NotInterestedView
                  userID={selectedUserID}
                  data={dataNot}
                  handleSelected={handleSelected}
                />
                <OtherView
                  userID={selectedUserID}
                  dataOther={dataOther}
                  handleSelected={handleSelected}
                />
              </Box>
            ) : (
              <Box>
                <Typography>No data available</Typography>
              </Box>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <ButtonView
            lable="Submit"
            fontSize="1.130rem"
            fontWeight="500"
            sx={{ py: "0.6rem", width: "100%" }}
            onClick={() => {
              // handleSubmit(approve, disApprove);
              const d = [];
              for (let v of selected) {
                d.push(v.user_id);
              }
              handleSubmit(d, userID);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

function ChipView({ data, deleteHandleSelected }) {
  return (
    <Box display="flex" flexWrap="wrap">
      {data.map((v) => {
        return (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            key={v.user_id}
          >
            <Chip
              sx={{ m: 0.3 }}
              avatar={
                <Avatar
                  alt="Natacha"
                  src={`${
                    API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb
                  }`}
                />
              }
              label={v.first_name}
              variant="outlined"
              onDelete={() => deleteHandleSelected(v)}
            />
          </motion.div>
        );
      })}
    </Box>
  );
}

function InterestedView({ data, handleSelected, userID }) {
  return !data.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 1, fontSize: "1.1rem" }}>
        Interested Providers
      </Typography>
      <DetailsView
        userID={userID}
        data={data}
        handleSelected={handleSelected}
      />
    </Box>
  );
}

function NotInterestedView({ data, handleSelected, userID }) {
  return !data.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 1, fontSize: "1.1rem" }}>
        Not-Interested Providers
      </Typography>
      <DetailsView
        userID={userID}
        data={data}
        handleSelected={handleSelected}
      />
    </Box>
  );
}

function OtherView({ dataOther, handleSelected, userID }) {
  return !dataOther.length ? null : (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.8, mt: 1, fontSize: "1.1rem" }}>
        Other Providers
      </Typography>
      <DetailsView
        userID={userID}
        data={dataOther}
        handleSelected={handleSelected}
      />
    </Box>
  );
}

function DetailsView({ data, handleSelected, userID }) {
  // console.log("data",data);
  return (
    <Grid container columns={3} columnGap={1.5} rowGap={1.5}>
      {data.map((v) => {
        if (v.user_id != userID) {
          return (
            <ItemView key={v.user_id} v={v} handleSelected={handleSelected} />
          );
        }
      })}
    </Grid>
  );
}

function ItemView({ v, handleSelected }) {
  return (
    <Paper
      component={"div"}
      elevation={0}
      onClick={() => {
        handleSelected(v);
      }}
      sx={{
        overflow: "hidden",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        width: "8rem",
        p: 2,
        transition: "all",
        transitionDuration: "300ms",
        boxShadow: "3px 3px 9px 0px #00000040",
        "&:hover": {
          backgroundColor: "#fcebcf",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        },
        backgroundColor: false && "#ffeac9",
      }}
    >
      <Box display="flex" width="100%" justifyContent="center">
        <Avatar
          sx={{ height: "40px", width: "40px" }}
          src={`${API_URL.baseUrl + API_URL.userProfileImage + v.photo_thumb}`}
        />
      </Box>

      <Typography
        sx={{ fontSize: "13.5px", wordWrap: "break-word", mt: "0.3rem" }}
      >
        {(v.first_name ?? "") + " " + (v.last_name ?? "")}
      </Typography>
      <Typography sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
        {v.mobile}
      </Typography>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "10px", wordWrap: "break-word" }}>
          {v.cities}
        </Typography>
      </Box>
    </Paper>
  );
}
