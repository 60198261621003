import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { GET_CITIES_DETAILS } from "../../../Apis/city_api";
import CitiesAdd from "./CitiesAdd";
import { useParams } from "react-router-dom";
import CitiesDetails from "./CitiesDetails";

function CitiesEdit() {
  const [rows, setRows] = React.useState({});
  const [selectdChip, setSelectedChip] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { id } = useParams();

  const [loadOnUpdate, setLoadOnUpdate] = useState(false);

  async function getData(loadOnUpdate = 0) {
    setIsLoading(true);
    loadOnUpdate === 1 && setLoadOnUpdate(true);
    const t = await GET_CITIES_DETAILS(id);

    if (t && t.s == 1) {
      setRows(t.r);
    }

    const d = [];
    for (const v of t.r.alternate_names) {
      d.push(v.name);
    }
    setSelectedChip([...d]);

    setIsLoading(false);
    loadOnUpdate === 1 && setLoadOnUpdate(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return isLoading && Object.keys(rows ?? {}).length == 0 ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <CitiesDetails
      chip={selectdChip}
      row={rows}
      getData={getData}
      loadOnUpdate={loadOnUpdate}
    />
    // <CitiesAdd chip={selectdChip} row={rows} />
  );
}

export default CitiesEdit;
