import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import MultiSelection from "../Multiselection/MultiSelection";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";
import { GETROLE } from "../../Apis/podian_api";
import ReactQuill from "react-quill";
import { GET_CITIE } from "../../Apis/city_api";
import { ADD_UPDATE_FAQ, GET_FAQ_DETAILS } from "../../Apis/faq_api";
import MuiSelect from "../Multiselection/MuiSelect";

let podianRole = { r: [] };

function AddFaqDailog({ open, handleOnClose, cId, row }) {
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const [isLoading, setIsLoading] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [rolesSelected, setRolesSelected] = React.useState([]);
  const [isLoadingP, setIsLoadingP] = React.useState(true);
  const [question, setQuestion] = React.useState(row ? row.question : "");
  const [answer, setAnswer] = React.useState(row ? row.answer : "");
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const type = ["Customer", "Provider"];
  const [selectedType, setSelectedType] = React.useState([]);
  const [faqData, setFaqData] = React.useState({});

  const handleOnSubmit = async () => {
    if (!question) {
      ToastShow("Please fill question");
    } else if (!answer) {
      ToastShow("Please fill answer");
    } else if (!selectedCities.length) {
      ToastShow("Please select cities");
    } else if (!selectedType.length) {
      ToastShow("Please select type");
    } else if (!rolesSelected.length) {
      ToastShow("Please select role");
    } else {
      setIsLoading(true);
      let ids = 0;
      if (rolesSelected.length) {
        podianRole.r.map((v) => {
          if (rolesSelected.includes(v.name)) {
            ids = v.id;
          }
        });
      }

      let c = [];
      if (selectedCities.length === 1) {
        c = citiesW.filter((v) => v.name == selectedCities[0]);
      } else {
        for (let i = 0; i < selectedCities.length; i++) {
          let n = citiesW.filter((v) => v.name == selectedCities[i]);
          c.push(n[0].id);
        }
      }

      const t = type.filter((v) => v == selectedType[0]);
      const res = await ADD_UPDATE_FAQ({
        question: question,
        answer: answer,
        role: ids,
        city_id: c.length === 1 ? c[0].id : c.join(","),
        type: getValue(t[0]),
        id: row ? row.id : null,
      });

      setIsLoading(false);
      if (res && res.s == 1) {
        handleOnClose(1);
      }
    }
  };

  const getValue = (v) => {
    let r = 0;

    if (v == "All") {
      r = 0;
    } else if (v == "Customer") {
      r = 1;
    } else {
      r = 2;
    }

    return r;
  };

  const getValueName = (v) => {
    let r = "All";

    if (v == 0) {
      r = "All";
    } else if (v == 1) {
      r = "Customer";
    } else {
      r = "Provider";
    }

    return r;
  };

  const handleQue = (v) => {
    setQuestion(v);
  };

  const handleAnswer = (v) => {
    setAnswer(v);
  };

  const handleSelectPodian = (e) => {
    const {
      target: { value },
    } = e;
    setRolesSelected([value]);
  };

  async function getRoles() {
    setIsLoadingP(true);
    const d = await GETROLE();
    podianRole = d;
    const names = [];
    names.push("All");
    for (let v of d.r) {
      names.push(v.name);
    }
    setRoles([...names]);
    setIsLoadingP(false);

    if (row) {
      let n = "All";

      for (let v of d.r) {
        if (row.role == v.id) {
          n = v.name;
          break;
        }
      }
      setRolesSelected([n]);

      setIsLoadingP(false);
    }
  }

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    if (value[0] === "All") {
      setSelectedCities([...cities]);
    } else if (value.includes("All")) {
      setSelectedCities([]);
    } else {
      setSelectedCities(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW([...t.r]);
      const c = [];

      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
    }

    if (row) {
      setSelectedCities(
        row?.cities?.length > 1
          ? row?.cities.map((city) => city.name)
          : [row?.cities[0].name]
      );
    }
  }

  function getType() {
    if (row) {
      setSelectedType([getValueName(row.type)]);
    }
  }

  const getFaqDetails = async () => {
    const res = await GET_FAQ_DETAILS(row?.id);
    if (res && res.s) {
      setFaqData(res?.r);
    } else {
      ToastShow(res.m);
    }
  };

  useEffect(() => {
    podianRole = { r: [] };
    getCities();
    getRoles();
    getType();
  }, []);

  useEffect(() => {
    if (row && row.id) {
      getFaqDetails();
    }
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle sx={{}}> {row ? "Update Faq" : "Add Faq"}</DialogTitle>
        {isLoadingP ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent>
            <Box sx={{ width: "100%", mt: 1 }}>
              <TextFieldView
                value={question}
                onchange={handleQue}
                label="Question"
                type="text"
                sx={{ width: "100%" }}
                width="100%"
              />

              <Box
                width="100%"
                mb={1}
                sx={{
                  "& .ql-container": {
                    height: "auto",
                    resize: "both",
                  },
                  "& .ql-toolbar": {
                    backgroundColor: "white",
                  },
                }}
              >
                <Typography sx={{ textAlign: "start", mt: 1 }}>
                  Answer
                </Typography>
                <ReactQuill
                  style={{ width: "100%", marginBottom: "10px" }}
                  theme="snow"
                  value={answer}
                  onChange={(e) => {
                    handleAnswer(e);
                  }}
                  formats={formats}
                />
              </Box>

              <MuiSelect
                label="City"
                width="100%"
                margin="0px"
                multiple={true}
                names={cities}
                handleChange={handleSelectCity}
                selected={selectedCities}
              />
              <Box height="15px" />

              <MultiSelection
                width="100%"
                size="small"
                selected={selectedType}
                handleChange={handleSelectType}
                names={type}
                lable={"Type"}
                multiple={false}
                subContainerWidth="100%"
                containerWidth="100%"
              />
              <Box height="15px" />
              <MultiSelection
                multiple={false}
                width="100%"
                handleChange={handleSelectPodian}
                selected={rolesSelected}
                lable={"For whome's this FAQ ?"}
                names={roles}
                subContainerWidth="100%"
                containerWidth="100%"
              />
            </Box>

            <DialogActions>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button onClick={handleOnSubmit} variant="text">
                  {row ? "Update" : "Submit"}
                </Button>
              )}
              <Button onClick={() => handleOnClose(0)} variant="text">
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

export default AddFaqDailog;
