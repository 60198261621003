import { API_URL, GETAPI, POSTAPI } from "./config";

export const GETPROVIDERS = async (data) => {
  let query = "";

  if (data && data.role) {
    query += "&role=" + data.role;
  }

  if (data && data.boardStatus) {
    query += "&on_board_status=" + data.boardStatus;
  }

  if (data && data.cityId) {
    query += "&city_id=" + data.cityId;
  }

  if (data && data.count) {
    query += "&count=" + data.count;
  }

  if (data && data.search) {
    query += "&search=" + data.search;
  }

  if (data && data.status !== 2) {
    query += "&status=" + data.status;
  }

  if (data && data.min_cutoff) {
    query += "&min_cutoff=" + data.min_cutoff;
  }

  query += "&limit=35";

  const res = await GETAPI(API_URL.providersGet, query);

  return res;
};

export const GETPROVIDERSAVL = async (data) => {
  let query = "";

  if (data && data.count != null) {
    query += "&count=" + data.count;
  }

  if (data && data.userId) {
    query += "&user_id=" + data.userId;
  }

  if (Object.keys(data.dateRange).length) {
    query += "&from_date_time=" + data.dateRange.start;
    query += "&to_date_time=" + data.dateRange.end;
  }

  const res = await GETAPI(API_URL.getSchedule, query);

  return res;
};

export const GET_SERVICE = async (
  cityId,
  categoryid,
  isFixed,
  isExtra,
  isPost
) => {
  const formData = new FormData();

  if (cityId) {
    formData.append("city_id", cityId);
  }

  if (categoryid) {
    formData.append("category_id", categoryid);
  }
  if (isFixed) {
    formData.append("isFixed", isFixed);
  } else {
    formData.append("isFixed", 0);
  }

  if (isExtra) {
    formData.append("isExtra", isExtra);
  } else {
    formData.append("isExtra", 0);
  }

  if (isPost) {
    formData.append("isPost", isPost);
  } else {
    formData.append("isPost", 0);
  }

  const res = await POSTAPI(API_URL.getService, formData);

  return res;
};

export const ADD_SERVICE = async (data) => {
  // console.log(data)
  const formData = new FormData();
  formData.append("orders_id", data.orders_id);
  formData.append("service_id", data.service_id);
  formData.append("qty", data.qty);
  formData.append("hours", data.hours);

  if (data.orders_items_id) {
    formData.append("orders_items_id", data.orders_items_id);
  }

  if (data.provider_cut) {
    formData.append("provider_cut", data.provider_cut);
  }

  if (data.remarks) {
    formData.append("remarks", data.remarks);
  }

  const res = await POSTAPI(
    data.orders_items_id ? API_URL.updateOrderService : API_URL.addOrderService,
    formData
  );

  return res;
};

export const DELETE_SERVICE = async (id) => {
  let query = "&orders_items_id=" + id;

  const res = await GETAPI(API_URL.deleteService, query);

  return res;
};

export const GETPROVIDERSDETAILS = async (id) => {
  let query = "&user_id=" + id;

  const res = await GETAPI(API_URL.providerDetails, query);

  return res;
};

export const GETPROVIDERSFORMDETAILS = async (id, role = 0) => {
  let query = "&user_id=" + id;

  if (role) {
    query += "&role=" + role;
  }

  const res = await GETAPI(API_URL.providerFormDetails, query);

  return res;
};

export const UPDATEPROVIDERHIKE = async (data) => {
  const formData = new FormData();
  formData.append("provider_cities_id", data.provider_cities_id);
  formData.append("provider_cut", data.provider_cut);
  formData.append("min_amount", data.min_amount);
  formData.append("max_amount", data.max_amount);

  const res = await POSTAPI(API_URL.updateCut, formData);

  return res;
};

export const APPROVE_REJECT = async (status, roles, reason) => {
  let query =
    "&users_role_ids=" + roles + "&status=" + status + "&reason=" + reason;

  const res = await GETAPI(API_URL.providerApproveReject, query);

  return res;
};

export const GETPROVIDERSKYC = async (id) => {
  let query = "&user_id=" + id;

  const res = await GETAPI(API_URL.providerKYC, query);

  return res;
};

export const APPROVE_REJECT_KYC = async (status, id, reason) => {
  let query = "&id=" + id + "&reason=" + reason;

  const res = await GETAPI(
    status == 1 ? API_URL.providerKYCApprove : API_URL.providerKYCReject,
    query
  );

  return res;
};

export const GETAGGREMENTLINK = async (id) => {
  let query = "&user_id=" + id;

  const res = await GETAPI(API_URL.providerAggrementLink, query);

  return res;
};

export const APPROVEREJECTSERVICEPROVIDER = async (id, status, reason) => {
  let query = "&id=" + id + "&status=" + status + "&reason=" + reason;

  const res = await GETAPI(API_URL.approveRejectService, query);

  return res;
};

export const GETHISTORY = async (id) => {
  let query = "&role_form_pages_records_id=" + id;

  const res = await GETAPI(API_URL.getHistoryService, query);

  return res;
};

export const APPROVEAUTO = async (id, status) => {
  let query = "&users_role_ids=" + id + "&status=" + status;

  const res = await GETAPI(API_URL.updateServiceAutoApprove, query);

  return res;
};

export const GETORDERSANDEARNINGS = async (userID) => {
  let query = "&user_id=" + userID;

  const res = await GETAPI(API_URL.getOrdersAndEarnings, query);

  return res;
};

export const GETRATEREVIEWS = async (userID) => {
  let query = "&user_id=" + userID;

  const res = await GETAPI(API_URL.getRateReviews, query);

  return res;
};

export const GETUNAVAILABILITY = async (userID) => {
  let query = "&user_id=" + userID;

  const res = await GETAPI(API_URL.getServiceProviderUnavailability, query);

  return res;
};

export const GETBANKDETAILS = async (userID) => {
  let query = "&user_id=" + userID;

  const res = await GETAPI(API_URL.getBankDetails, query);

  return res;
};

export const ACTIVATE_DEACTIVATE_ACCOUNT = async (data) => {
  const formData = new FormData();
  formData.append("id", data.id);
  formData.append("status", data.status);

  const res = await POSTAPI(API_URL.activateOrDeactivateAccount, formData);

  return res;
};

export const GETCUTOFFLOGS = async (id) => {
  let query = "&provider_cities_id=" + id;

  const res = await GETAPI(API_URL.getCutOffLogs, query);
  return res;
};
