import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  import ToastView, { ToastShow } from "../Toast/Toast";
  import { ADD_UPDATE_NEW_ON_SPOT_ZONE } from "../../Apis/new_on_spot_api";
  
  function AddUpdateNewZoneDailog({ open, handleOnClose, on_spot_city_location_id, row }) {
   
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = React.useState(row?.name ?? "");
  
    const handleOnSubmit = async () => {
      if (!name) {
        ToastShow("Please select name");
      } else {
        setIsLoading(true);
        const d = await ADD_UPDATE_NEW_ON_SPOT_ZONE({
          id: row?.id ?? 0,
          on_spot_city_location_id:on_spot_city_location_id,
          name: name,
        });
        setIsLoading(false);
        if (d && d.s) {
          ToastShow("Successfully added");
          handleOnClose(1);
        } else {
          ToastShow("Something went wrong. Please try again.");
        }
      }
    };
  
    return (
      <>
        <Dialog open={open} onClose={() => handleOnClose(0)}>
          <DialogTitle>{!row?.id ? "Add Zone" : "Update Zone"}</DialogTitle>
          <DialogContent
            sx={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
  
            <TextField
              sx={{
                width: "85%",
                mt: 2,
              }}
              size="small"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </DialogContent>
  
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button onClick={handleOnSubmit} variant="text">
                Submit
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastView />
      </>
    );
  }
  
  export default AddUpdateNewZoneDailog;
  