import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_NOTIFICATION } from "../../../Apis/notification_api";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import DateMDYHMA from "../../../Component/Date/DateMDYHMA";
import { useNavigate } from "react-router-dom";
import SplitButton from "../../../Component/Button/SplitButton";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import AddNotificationModal from "./AddNotificationModal";
import { BsChevronDown } from "react-icons/bs";

import {
  DELETE_NOTIFICATION_ADMIN,
  GET_NOTIFICATION_ADMIN,
} from "../../../Apis/notification_api";
import { ToastShow } from "../../../Component/Toast/Toast";
import EditNotification from "../../../Component/Dailog/EditNotificaton";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";

const names = ["All", "Customer", "Provider", "Admin & Support"];

function ParentNotification() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCall, setIsCall] = React.useState(true);
  const [lookFor, setLookFor] = React.useState(0);
  const [selectedId, setSeletedId] = React.useState(0);
  const [selectedRow, setSeletedRow] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.api.getRowIndex(params.row.id) + 1;
      },
    },

    {
      field: "title",
      headerName: "Title",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography py="0.6rem" fontSize="12.4px">
            {params.row.title}
          </Typography>
        );
      },
    },

    {
      field: "message",
      headerName: "Message",
      width: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "date_time",
      headerName: "Date/Time",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Box py="0.7rem">
            <DateMDYHMA date={params.value} isUtc={false} />
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Typography> {params.value ? "Sent" : "Pending"} </Typography>;
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      minWidth: 150,
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          title={params.value}
          isView={null}
          dataId={params.id}
          isEdit={ 1}
          onEdit={onEdit}
          isDelete={1}
          onDelete={onDelete}
        />
      ),
    },
  ];

  const onEdit = (id) => {
    const t = rows.filter((v) => v.id == id);
    setSeletedRow(t[0]);
    setOpen(true);
  };

  async function getData(v = 0) {
    setIsLoading(true);
    const t = await GET_NOTIFICATION_ADMIN(v ? 0 : rows.length, lookFor);

    if (t && t.s == 1) {
      if (t.r) {
        if (v) {
          setRows([...t.r]);
        } else {
          setRows([...rows, ...t.r]);
        }
      } else {
        if (v) {
          setRows([]);
        }
      }
    }

    if ((t.r ?? []).length == 0) {
      setIsCall(false);
    }
    setIsLoading(false);
  }

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedIdN, setSelectedId] = useState(0);

  const handleChange = (e) => {
    setLookFor(e.target.value);
  };

  const onDelete = async (id) => {
    handleOpenConfirmation(id);
  };

  const handleOpenConfirmation = (id) => {
    setSelectedId(id);
    setOpenConfirmation(true);
  };

  const handleOnCloseConfirmation = async (v) => {
    if (v) {
      const v = await DELETE_NOTIFICATION_ADMIN(selectedIdN);

      if (v && v.s) {
        ToastShow("Deleted successfully");
        const t = rows.filter((e) => e.id != selectedIdN);
        setRows([...t]);
      } else {
        ToastShow("Opps! something went wrong. Please try again");
      }
    }
    setSelectedId(0);
    setOpenConfirmation(false);
  };

  useEffect(() => {
    getData(1);
  }, [lookFor]);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };

  const handleClose = (value = 0) => {
    setOpen(false);
    if (value === 1) {
      getData(1);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "16px",
        }}
      >
        {/*Multiselection*/}
        <Box
          sx={{
            bgcolor: "white",
            boxShadow: "3px 3px 7px 0px #00000040",
            borderRadius: "5px",
            width: "11rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingY: ".230rem",
            gap: ".1rem",
          }}
        >
          <Box width="95%">
            <Box
              sx={{
                display: "flex",
                gap: ".3rem",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  borderRadius: "0",
                  padding: "0",
                }}
              >
                <img
                  src={require("../../../Assets/User-selection-input.png")}
                  style={{ objectFit: "fill" }}
                  alt=""
                />
              </IconButton>
              <Typography fontSize="10px">Looking for All</Typography>
            </Box>
          </Box>

          <Box width="95%">
            <FormControl
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& fieldset": { border: "none" },
                "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    fontSize: "0.720rem",
                  },
                "& .css-3qbkez-MuiSelect-icon": {
                  width: "0.688rem",
                },
                "& .css-10q54uo-MuiSelect-icon": {
                  width: "0.688rem",
                },
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    fontSize: "0.720rem",
                  },
              }}
            >
              <Select
                label="Looking For"
                labelId="lookFor"
                multiple={false}
                value={lookFor}
                onChange={handleChange}
                IconComponent={BsChevronDown}
                style={{
                  backgroundColor: "#F6F6F6",
                  color: "#969696",
                  borderRadius: "6px",
                  height: "1.7rem",
                }}
              >
                {names.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={i}
                    sx={{
                      boxShadow: "0px 0.3px 0px 0px #00000040",
                    }}
                  >
                    <p style={{ fontSize: "0.8rem" }}>{item}</p>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {/*Multiselection*/}
      </Box>

      <Box
        sx={{
          overflow: "auto",
          width: "100%",
          backgroundColor: "white",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "2px 3px 4px rgba(0,0,0,0.3)",
          paddingTop: "16.3px",
          gap: "11.9px",
        }}
      >
        <Box
          sx={{
            width: "98%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#454545",
              fontSize: "1.150rem",
              fontWeight: "500",
            }}
          >
            Notification
          </Typography>

          <Button
            onClick={() => {
              setSeletedRow(null);
              setOpen(true);
            }}
            sx={{
              width: "11.7rem",
              height: "2.3rem",
              borderRadius: "6px",
              textTransform: "none",
              gap: "1px",
              bgcolor: "#F8AF41",
              "&:hover": {
                bgcolor: "rgb(173, 123, 44)",
              },
              display: "flex",
              alignItems: "center",
              gap: "0.280rem",
            }}
          >
            <img
              src={require("../../../Assets/add-button.png")}
              style={{ width: "15px", height: "14px", objectFit: "fill" }}
              alt=""
            />
            <Typography fontSize="15.5px" fontWeight="500">
              Create Notification
            </Typography>
          </Button>
        </Box>

        {open && <AddNotificationModal open={open} handleClose={handleClose} row={selectedRow}  />}

        <Box width="98%">
          <DataGrideView
            isLoading={isLoading}
            rows={rows}
            columns={columns}
            callBack={callBack}
            colHeight={null}
            sx={{ height: "68vh" }}
          />
        </Box>
      </Box>

      {openConfirmation ? (
        <ConfirmationDailog
          title="Delete notification !"
          content="Are you sure, You want to delete the notification?"
          open={openConfirmation}
          handleOnClose={handleOnCloseConfirmation}
        />
      ) : null}
    </Box>
  );
}

export default ParentNotification;
