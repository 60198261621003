import { Box, Dialog, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { GrClose } from "react-icons/gr";
import TextFieldLarge from "../../../../Component/TextFields/TextFieldLarge";
import ButtonView from "../../../../Component/Button/ButtonView";
import { ADD_PAYOUT, ADD_RAZOR_PAYOUT } from "../../../../Apis/payout_api";
import { ToastShow } from "../../../../Component/Toast/Toast";

function AddModal({ open, fetchData, userId, handleOnClose, title }) {
  const [amount, setAmount] = useState("");

  const handleSubmit = async () => {
    if (amount === "") {
      ToastShow("Please add some value");
      return;
    }

    let res = null;
    title === "Add Payout"
      ? (res = await ADD_PAYOUT({ userId, amount }))
      : (res = await ADD_RAZOR_PAYOUT({ userId, amount }));

    if (res && res.s) {
      ToastShow(res.m);
      fetchData();
      handleOnClose();
    } else {
      ToastShow(res.m);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleOnClose}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.3)",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "6px",
            display: "flex",
            padding: "1rem",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.7rem",
          }}
        >
          <Stack
            width="20rem"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.4rem"
            px="0.7rem"
          >
            <Typography fontWeight="500" sx={{ userSelect: "none" }}>
              {title}
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={handleOnClose}
            />
          </Stack>

          {/* FIELDS */}
          <Box
            bgcolor="#F6F6F6"
            width="20rem"
            borderRadius="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py="0.5rem"
          >
            <TextFieldLarge
              label="Amount"
              name="amount"
              type="number"
              variant="outlined"
              width="95%"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Box>
          <Stack width="100%" direction="row" gap="0.5rem">
            <ButtonView
              lable="Cancel"
              color="#E50000"
              bgcolor="#E5000033"
              fontSize="14px"
              sx={{ width: "100%" }}
              hoverBg="pink"
              onClick={handleOnClose}
            />

            <ButtonView
              lable="Submit"
              fontSize="14px"
              hoverBg="#ffb952"
              sx={{ width: "100%" }}
              onClick={handleSubmit}
            />
          </Stack>
        </Box>
      </Dialog>
    </>
  );
}

export default AddModal;
