import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  addUpdateOrderTopicsIssue,
  deleteOrderTopics,
  getOrderTopics,
} from "../../Apis/order_issue_topic_api";
import { ToastShow } from "../Toast/Toast";
import { Delete, Edit } from "@mui/icons-material";
import ConfirmationDailog from "./ConfirmationDailog";

// sx={{backdropFilter: "blur(1px)",}}
function OrderIssueTopics({ open, handleOnClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const [data, setData] = useState([]);
  const [ openC,setOpenC] = useState(false);

  const addData = async () => {
    if (!name) {
      ToastShow("Please fill the name");
    } else {
      const data = await addUpdateOrderTopicsIssue(name, id);

      if (data && data.s) {
        ToastShow("Success");
        getData();
      } else {
        ToastShow("Oops! something went wrong. Please try again.");
      }
    }

    setName("");
    setId(0);
  };

  const getData = async () => {
    const data = await getOrderTopics();

    if (data && data.s) {
      setData(data.r);
    } else {
      setData([]);
    }
  };

  const onDelete = async (v)=>{
    setOpenC(false);
    if(v){
        const data = await deleteOrderTopics(id);

        if(data && data.s){
            ToastShow("Success");
            getData();
        }else {
            ToastShow("Oops! something went wrong.")
        }
    }

    setId(0);
    

  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Dialog fullWidth open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>Order issue topic</DialogTitle>
        <DialogContent>
          <Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Topic"
                placeholder="Enter topic name "
                fullWidth
                size="small"
                sx={{
                  mt: 1,
                }}
              />
              <Button onClick={addData} size="small" sx={{ mt: 1, width: 40 }}>
                Submit
              </Button>
            </Box>

            {data.length ? (
              <Box
                sx={{
                  flex: 1,
                  maxHeight:"500px",
                  minHeight:"200px",
                  overflow:"auto"
                }}
              >
               
                  {data.map((v, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          my: 1,
                          boxShadow: "1px 1px 3px 1px #c0c0c0",
                          p: 1,
                        }}
                      >
                        <Box flex={1}>
                          <Typography>{v.name}</Typography>
                        </Box>
                        <Box display="flex" alignSelf="end">
                          <IconButton
                          onClick={()=>{
                            setId(v.id);
                            setOpenC(true);
                          }}
                          >
                            <Delete />
                          </IconButton>
                          <IconButton
                          onClick={()=>{
                            setName(v.name);
                            setId(v.id);
                          }}
                          >
                            <Edit />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}
                
              </Box>
            ) : null}
          </Box>
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button onClick={() => handleOnClose(1)} variant="text">
                Submit
              </Button>
            )}
            <Button onClick={() => handleOnClose(0)} variant="text">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>

        {
            openC && <ConfirmationDailog open={openC} handleOnClose={onDelete} title="Delete Topic!" content="Are are sure, You want to delete it."/>
        }
      </Dialog>
    </>
  );
}

export default OrderIssueTopics;
