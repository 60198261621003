import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import tokens, { API_URL } from "../../../Apis/config";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";

function CustomerExport() {
  const type = ["All", "Active", "Deleted"];
  const [selectedType, setSelectedType] = React.useState(["All"]);
  const [typeId, setTypeId] = React.useState("0,1");
  const [isIncludeProvider, setIsIncludeProvider] = React.useState(false);

  const geData = async () => {
    window.open(
      API_URL.baseUrl +
        API_URL.reportCustomer +
        "?apikey=" +
        tokens.apikey +
        "&token=" +
        tokens.token +
        "&status=" +  typeId +
        "&notIncludeProvider="+(isIncludeProvider ? 0 :1)
      
    );
  };

  const handleSelectType = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedType([value]);
    setTypeId(getValue(value));
  };

  const getValue = (v) => {
    let r = 0;

    if (v == "All") {
      r = "1,0";
    } else if (v == "Active") {
      r = "1";
    } else {
      r = "-1";
    }

    return r;
  };

  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <MultiSelection
        size="small"
        selected={selectedType}
        handleChange={handleSelectType}
        names={type}
        lable={"Type"}
        multiple={false}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isIncludeProvider}
            onChange={(e) => setIsIncludeProvider(e.target.checked)}
          />
        }
        label="Include provider details ?"
      />
      <Button sx={{ ml: 1, mt: 2 }} variant="outlined" onClick={() => geData()}>
        Export
      </Button>
    </Box>
  );
}

export default CustomerExport;
