import {
  Box,
  Checkbox,
  Dialog,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { GrClose } from "react-icons/gr";
import ButtonView from "../../../Component/Button/ButtonView";
import TextFieldSmall from "../../../Component/TextFields/TextFieldSmall";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateTime from "../../../Component/DateTime/DateTime";
import { ToastShow } from "../../../Component/Toast/Toast";
import { CREATECOUPON } from "../../../Apis/coupen_api";
import { format } from "date-fns";
import DateFieldNew from "../../../Component/DateField/DateFieldNew";
import TextFieldLarge from "../../../Component/TextFields/TextFieldLarge";

function AddCoupon({ open, handleClose, orderId, getData }) {
  const [code, setCode] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [applyLimit, setApplyLimit] = useState("");
  const [ntTitle, setNtTitle] = useState("");
  const [ntBody, setNtBody] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPer, setIsPer] = useState(2);

  const handleSubmit = async () => {
    if (!code) {
      ToastShow("Please fill the Code field");
      return;
    } else if (!discount) {
      ToastShow("Please fill the Discount field");
      return;
    }

    const res = await CREATECOUPON({
      orders_id: orderId,
      code: code,
      min_amount: minAmount,
      discount: discount,
      max_amount: maxAmount,
      apply_limit: applyLimit,
      nt_title: ntTitle,
      nt_body: ntBody,
      start_dt: startDate ? format(startDate, "yyyy-MM-dd") : null,
      end_dt: endDate ? format(endDate, "yyyy-MM-dd") : null,
      isPer: isPer === true ? 1 : 0,
    });

    if (res && res.s) {
      window.location.reload();
      handleClose();
    } else {
      ToastShow(res ? res.m : "Opps! something went wrong. Please try again.");
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Stack
          width="26rem"
          gap={2}
          p={1.8}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            width="100%"
            direction="row"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            justifyContent="space-between"
            alignItems="center"
            py="0.5rem"
            px="0.5rem"
          >
            <Typography
              fontWeight="500"
              fontSize="17px"
              sx={{ userSelect: "none" }}
            >
              Add Coupon
            </Typography>
            <GrClose
              style={{ cursor: "pointer", fontSize: "0.9rem" }}
              onClick={handleClose}
            />
          </Stack>

          {/* Fields */}
          <TextFieldLarge
            label="Code*"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            width="100%"
            fieldHeight="2.1rem"
            placeholder="Enter coupon code"
          />

          <TextFieldLarge
            label="Title"
            value={ntTitle}
            onChange={(e) => setNtTitle(e.target.value)}
            width="100%"
            placeholder="Enter title"
          />

          <TextFieldLarge
            label="Min Amount"
            type="number"
            value={minAmount}
            onChange={(e) => setMinAmount(e.target.value)}
            width="100%"
            fieldHeight="2.1rem"
            placeholder="Enter minimum amount"
          />

          <TextFieldLarge
            label="Discount*"
            type="number"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            width="100%"
            fieldHeight="2.1rem"
            placeholder="Enter discount"
          />

          <TextFieldLarge
            label="Max Amount"
            type="number"
            value={maxAmount}
            onChange={(e) => setMaxAmount(e.target.value)}
            width="100%"
            fieldHeight="2.1rem"
            placeholder="Enter maximum amount"
          />

          <TextFieldLarge
            label="Apply Limit"
            type="number"
            value={applyLimit}
            onChange={(e) => setApplyLimit(e.target.value)}
            width="100%"
            fieldHeight="2.1rem"
            placeholder="Enter apply limit"
          />

          <TextFieldLarge
            label="Message"
            value={ntBody}
            onChange={(e) => setNtBody(e.target.value)}
            width="100%"
            placeholder="Enter message"
          />

          {/*Date*/}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTime
              label="Start Date"
              width="100%"
              paddingY="0.4rem"
              onlyDate={true}
              noHeight={true}
              bgColor="#c0c0c02c"
              fieldBgColor="white"
              value={startDate}
              onChange={(v) => {
                if (endDate != null && v.getTime() > endDate.getTime()) {
                  ToastShow("Start date bigger than end date is not valid");
                  return;
                }
                setStartDate(v);
              }}
              renderInput={(params) => (
                <TextField sx={{ m: 1.4 }} {...params} />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTime
              label="End Date"
              width="100%"
              value={endDate}
              onlyDate={true}
              bgColor="#c0c0c02c"
              fieldBgColor="white"
              noHeight={true}
              paddingY="0.4rem"
              onChange={(v) => {
                if (startDate != null && v.getTime() < startDate.getTime()) {
                  ToastShow("Start date bigger than end date is not valid");
                  return;
                }
                setEndDate(v);
              }}
            />
          </LocalizationProvider>

          {/*Checkbox*/}
          <Box width="100%" pl="0.1rem">
            <FormControlLabel
              label="Is in Percentage ?"
              required
              style={{ margin: "0px" }}
              control={
                <Checkbox
                  icon={
                    <img
                      style={{ width: ".9rem", objectFit: "fill" }}
                      src={require("../../../Assets/UnCheckedIcon.png")}
                    />
                  }
                  checkedIcon={
                    <img
                      style={{ width: ".9rem", objectFit: "fill" }}
                      src={require("../../../Assets/Icons for pages/checked_icon.png")}
                    />
                  }
                  checked={isPer == 1 ? true : false}
                  onChange={(e) => setIsPer(e.target.checked)}
                />
              }
              sx={{
                "& .css-1x2jons-MuiFormControlLabel-asterisk": {
                  display: "none",
                },
                "& .css-fbhpqu-MuiButtonBase-root-MuiCheckbox-root": {
                  padding: "0px",
                  marginRight: "0.3rem",
                },
                "& .css-7jdnmo-MuiTypography-root": {
                  fontSize: "0.8rem",
                  color: isPer === true ? "#F8AF41" : "inherit",
                  userSelect: "none",
                },
              }}
            />
          </Box>

          <ButtonView
            lable="Submit"
            fontSize="16px"
            hoverBg="#ffb952"
            sx={{ width: "100%" }}
            onClick={handleSubmit}
          />
        </Stack>
      </Dialog>
    </>
  );
}

export default AddCoupon;
