import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import ToastView, { ToastShow } from "../Toast/Toast";
import MultiSelection from "../Multiselection/MultiSelection";
import { statusChange } from "../../Apis/order_api";
import ButtonView from "../Button/ButtonView";
import { BsChevronDown } from "react-icons/bs";

const status = [
  "Not interested",
  "Interested",
  "Approved",
  "Started work",
  "Finish work",
  "Submitted work",
  "Completed",
];

function ChangeStatus({ statusV, open, handleOnClose, id }) {
  const [statusValue, setStatus] = useState([statusV]);
  const [loading, setLoading] = useState(false);

  const handleStatus = (e) => {
    const {
      target: { value },
    } = e;

    // console.log(value);
    setStatus([value]);
  };

  const getStatusV = (s) => {
    // console.log(s[0],"Status");

    switch (s[0]) {
      case "Not interested":
        return 0;
      case "Interested":
        return 1;
      case "Approved":
        return 2;
      case "Started work":
        return 3;
      case "Finish work":
        return 4;
      case "Submitted work":
        return 5;
      case "Completed":
        return 6;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = await statusChange(id, getStatusV(statusValue));

    if (data) {
      ToastShow("Change successfully");
      handleOnClose(1);
    } else {
      handleOnClose(0);
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOnClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Typography
          sx={{ mx: 1.8, mt: 1, fontSize: "20px", fontWeight: "500" }}
        >
          Change Status
        </Typography>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            padding: "8px 14px",
          }}
        >
          <SelectComp
            selected={statusValue}
            handleChange={handleStatus}
            names={status}
          />
        </DialogContent>

        <DialogActions>
          <ButtonView
            lable="Cancel"
            color="#E50000"
            bgcolor="#E5000033"
            fontSize="14px"
            sx={{ width: "100%" }}
            hoverBg="pink"
            onClick={() => handleOnClose(0)}
          />

          {loading ? (
            <CircularProgress />
          ) : (
            <ButtonView
              lable="Submit"
              fontSize="14px"
              hoverBg="#ffb952"
              sx={{ width: "100%" }}
              onClick={handleSubmit}
            />
          )}
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default ChangeStatus;

function SelectComp({ selected, handleChange, names }) {
  return (
    <FormControl
      sx={{
        width: "100%",
        borderRadius: "5px",
        "& fieldset": { border: "none" },
        // "& .css-1cdoino-MuiInputBase-root-MuiOutlinedInput-root": {
        //   fontSize: "0.720rem",
        // },
        "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
          {
            fontSize: "0.720rem",
          },
        "& .css-3qbkez-MuiSelect-icon": {
          width: "0.688rem",
        },
        "& .css-10q54uo-MuiSelect-icon": {
          width: "0.688rem",
        },
        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
          {
            fontSize: "0.8rem",
          },
      }}
    >
      {/* <InputLabel id="demo-multiple-checkbox-label">{lable}</InputLabel> */}
      <Select
        defaultValue=""
        multiple={false}
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput label={"Status"} />}
        renderValue={(select) => select.join(", ")}
        style={{
          backgroundColor: "#F6F6F6",
          color: "#969696",
          borderRadius: "6px",
          height: "3rem",
        }}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        IconComponent={BsChevronDown}
        // MenuProps={MenuProps}
      >
        {names.map((name, i) => (
          <MenuItem
            key={i}
            value={name}
            sx={{
              boxShadow: "0px 0.3px 0px 0px #00000040",
            }}
          >
            <Checkbox
              sx={{ padding: "0", marginRight: ".4rem" }}
              icon={
                <IconButton
                  sx={{
                    border: "1px solid black",
                    padding: "0",
                    border: "0",
                  }}
                >
                  <img
                    src={require("../../Assets/Unchecked_2.png")}
                    style={{ width: "13px", objectFit: "fill" }}
                    alt=""
                  />
                </IconButton>
              }
              checkedIcon={
                <IconButton
                  sx={{
                    border: "1px solid black",
                    padding: "0",
                    border: "0",
                  }}
                >
                  <img
                    src={require("../../Assets/CheckedIcon.png")}
                    style={{ width: "13px", objectFit: "fill" }}
                    alt=""
                  />
                </IconButton>
              }
              checked={selected.indexOf(name) > -1}
            />
            <Typography fontSize="14px">{name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
