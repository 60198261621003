import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import SplitButton from "../../../../../Component/Button/SplitButton";
import DataGrideView from "../../../../../Component/DataGrideView/DataGrideView";
import RolesModal from "./RolesModal.jsx";
import UpdateStatusDialog from "./UpdateStatusDialog.jsx";
import { APPROVE_REJECT } from "../../../../../Apis/service_api.jsx";

const Roles = ({ data, id, getUser }) => {
  const [open, setOpen] = useState();
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [viewHistory, setViewHistory] = useState(false);
  const [rowId, setRowId] = useState(null);

  const rows = data?.map((row, index) => ({ ...row, renderId: index + 1 }));

  const handleApproveReject = async (status, roles_id, reason) => {
    const res = await APPROVE_REJECT(status, roles_id, reason);
    res && res.s === 1 ? getUser() : console.log("error");
  };

  const cols = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "center",
      align: "center",
      width: 60,
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.renderId}
          </Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Service",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px" my={2}>
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        console.log('params', params);
        return (
          <Typography
            fontSize="12.4px"
            color={
              params.row.status === 1
                ? "#0DA800"
                : params.row.status === 0
                ? "blue"
                : "red"
            }
          >
            {params.row.status === 1
              ? "Approved"
              : params.row.status === 0
              ? "Pending"
              : "Rejected"}
          </Typography>
        );
      },
    },
    {
      field: "is_auto_approve",
      headerName: "Auto Approve Categories",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            sx={{
              bgcolor: "#FFE0B2",
              height: "2rem",
            }}
            onClick={() => {
              if (params?.row?.is_auto_approve) {
                setSelectedData(params?.row);
                setViewHistory(false);
                setOpen(true);
              } else {
                setSelectedData(params?.row);
                setViewHistory(false);
                setOpen(true);

              }
            }}
          >
            <Typography fontSize="12.4px" textTransform="none" my={2}>
              {'Manage'}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <SplitButton
          key={params.id}
          dataId={params.id}
          isHistory={true}
          isEdit={true}
          editText="Update Status"
          onHistory={() => {
            setSelectedData(params.row);
            setViewHistory(true);
            setOpen(true);
          }}
          onEdit={() => {
            setRowId(params.row.users_role_id);
            setOpenUpdateStatus(true);
          }}
        />
      ),
    },
  ];

  return (
    <Box>
      <Box width="100%" boxShadow="3px 3px 7px 0px #00000040">
        <DataGrideView columns={cols} rows={rows} colHeight={null} />
      </Box>

      {open ? (
        <RolesModal
          open={open}
          setOpen={setOpen}
          v={data}
          id={id}
          selectedData={selectedData}
          viewHistory={viewHistory}
        />
      ) : null}

      {openUpdateStatus ? (
        <UpdateStatusDialog
          open={openUpdateStatus}
          id={rowId}
          handleApproveRejectStatus={handleApproveReject}
          handleClose={() => setOpenUpdateStatus(false)}
        />
      ) : null}
    </Box>
  );
};

export default Roles;
