import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import ButtonView from "../../../../../Component/Button/ButtonView";
import {
  GET_CATEGORIES_FOR_PROVIDER,
  SET_CATEGORIES_FOR_PROVIDER,
} from "../../../../../Apis/categories_for_provider_api";
import { ToastShow } from "../../../../../Component/Toast/Toast";
import checked_primarycolor from "./../../../../../Assets/checked_primarycolor.png";
import UnCheckedIcon from "./../../../../../Assets/UnCheckedIcon.png";

const RolesModal = ({ open, setOpen, v, id, selectedData, viewHistory }) => {
  const [data, setDatas] = useState({
    categoriesForProvider: [], //[{...}]
    catgegoriesName: [], //name
    selectedCategories: [], //Staus===1 ? name
  });

  const handleChange = async (e) => {
    const {
      target: { value },
    } = e;
    setDatas((p) => ({ ...p, selectedCategories: value }));
  };

  const submitCategories = async () => {
    const ids = data.categoriesForProvider
      .filter((v) => v.status === 1)
      .map((v) => v.id);
    const t = ids.length ? ids.join(",") : "0";

    const res = await SET_CATEGORIES_FOR_PROVIDER(
      selectedData.users_role_id,
      t,
      id
    );

    if (res && res.s) {
      ToastShow("Submitted");
      setOpen(false);
    } else {
      ToastShow("Opps! something went wrong. Please try again");
    }
  };

  const getCategoriesForProvider = async (id) => {
    const data = await GET_CATEGORIES_FOR_PROVIDER(id);

    if (data && data.s) {
      if (data?.r?.length) {
        const d = data.r.map((v) => v.name);
        const ds = data.r
          .filter((v) => v.status === 1)
          .map((v) => v.name);
        setDatas((p) => ({
          ...p,
          categoriesForProvider: data.r,
          catgegoriesName: d,
          selectedCategories: ds,
        }));
      }
    }
  };

  useEffect(() => {
    getCategoriesForProvider(selectedData.users_role_id);
  }, []);

  return (
    <Popover
      open={open}
      onClose={() => setOpen(false)}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "#1C1000B2",
        zIndex: 1,
      }}
    >
      <Stack justifyContent="space-between" width="22vw" height="92vh" m={1.8}>
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            sx={{ userSelect: "none" }}
          >
            Auto Approve categories
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
        </Stack>

        <Box flex={1} paddingTop="0.7rem">
          {data?.categoriesForProvider.map((v) => (
            <Box display="flex" gap="10px" key={v.id}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      icon={<img src={UnCheckedIcon} alt="" />}
                      checkedIcon={<img src={checked_primarycolor} alt="" />}
                      checked={v.status === 1}
                      onChange={(e) => {
                        if (!viewHistory) {
                          const d = data?.categoriesForProvider.map((t) => {
                            if (t.id === v.id) {
                              return {
                                ...t,
                                status: e.target.checked ? 1 : 0,
                              };
                            }
                            return t;
                          });

                          setDatas((p) => ({
                            ...p,
                            categoriesForProvider: d,
                          }));
                        }
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{
                        color: v.status === 1 ? "#FAAF40" : "black",
                        userSelect: "none",
                      }}
                    >
                      {v.name}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          ))}
        </Box>

        {!viewHistory ? (
          <Box py="0.7rem">
            <ButtonView
              lable={data.selectedCategories.length > 0 ? "Update" : "Add"}
              onClick={submitCategories}
              sx={{ width: "100%" }}
            />
          </Box>
        ) : null}
         {/* <Box py="0.7rem">
            <ButtonView
              lable={data.selectedCategories.length > 0 ? "Update" : "Add"}
              onClick={submitCategories}
              sx={{ width: "100%" }}
            />
          </Box> */}
      </Stack>
    </Popover>
  );
};

export default RolesModal;
