import { API_URL, GETAPI, POSTAPI } from "./config";

export const GEBYMOBILENO = async (mobile) => {
  let query = "&mobile=" + `91${mobile}`;
  const data = await GETAPI(API_URL.getByMobile, query);
  return data;
};

export const CREATEUSER = async (data) => {
   const formData = new FormData();

   if(data.firstName){
    formData.append("first_name",data.firstName);
   }

   if(data.lastName){
    formData.append("last_name",data.lastName);
   }

   if(data.mobile){
    formData.append("mobile","+91"+data.mobile);
   }

   if(data.middleName){
    formData.append("middle_name",data.middleName);
   }

   if(data.email){
    formData.append("email",data.email);
   }

   if(data.gender){
    formData.append("gender",data.gender);
   }

    const res = await POSTAPI(API_URL.addByAdmin, formData);
    return res;
  };


