import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import tokens, { API_URL } from "../../../Apis/config";
import MultiSelection from "../../../Component/Multiselection/MultiSelection";
import { addDays, subDays, lastDayOfMonth, format } from "date-fns";
import { DateRangePicker, CustomProvider } from "rsuite";
import { useState } from "react";
import { useEffect } from "react";
import { GETROLE } from "../../../Apis/podian_api";
import { GET_CITIE } from "../../../Apis/city_api";
import {
  GET_REPORT_Total_Shoot,
  GET_REPORT_Total_Shoot_By_Hours,
} from "../../../Apis/report_api";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { GETCATEGORY } from "../../../Apis/category_api";
import TextFieldView from "../../../Component/TextField/TextFieldView";
import SearchBox from "../../../Component/SearchBox/SearchBox";
import { ToastShow } from "../../../Component/Toast/Toast";

let names = [];
let podianRole = { r: [] };

function TotalShootsByHours() {
  const [categories, setCategories] = React.useState([]);
  const [categoriesW, setCategoriesW] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const [typeId, setTypeId] = React.useState("0,1");
  const theme = useTheme();
  const today = new Date();
  var firstdayW = new Date(today.setDate(today.getDate() - today.getDay()));
  var lastdayW = new Date(today.setDate(today.getDate() - today.getDay() + 6));
  const [dateRange, setDateRange] = useState({});
  const [selected, setSelected] = useState([]);

  const [cities, setCities] = React.useState([]);
  const [citiesW, setCitiesW] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [cityId, setSelectedId] = React.useState("");

  const [rows, setRow] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shoots, setShoots] = useState("");
  const [hours, setHours] = useState("");

  async function getCategories() {
    const t = await GETCATEGORY();

    if (t && t.s == 1) {
      setCategoriesW([...t.r]);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCategories([...c]);
      //   setSelectedId(t.r[0].id);
      setSelectedCategories([]);
      //   getData(t.r[0].id, 0);
    }
  }

  const handleSelectCategories = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCategories(value);
    const c = categoriesW.filter((v) => v.name == value);
    // setSelectedId(c.length ? c[0].id : 0);
    // await getData(c.length ? c[0].id : 0, 0);
  };

  async function getCities() {
    const t = await GET_CITIE();

    if (t && t.s == 1) {
      setCitiesW(t.r);
      const c = [];
      for (const v of t.r) {
        c.push(v.name);
      }
      setCities([...c]);
      //   setSelectedId(t.r[0].id);
      //   setSelectedCities([t.r[0].name]);
    }
  }

  const handleSelectCity = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedCities([...value]);
    const c = citiesW.filter((v) => value.includes(v.name));
    const i = [];

    for (let d of c) {
      i.push(d.id);
    }

    const r = i.join(",");
    setSelectedId(r);
  };

  const handleSelectDate = (v) => {
    if (Object.keys(v).length) {
      const d = {
        start:
          format(v[0], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 00:00:01",
        end:
          format(v[1], "yyyy-MM-dd hh:mm:ss").toString().split(" ")[0] +
          " 23:59:59",
      };
      setDateRange(d);
    } else {
      setDateRange(v);
    }
  };

  const geData = async () => {
    let query = "";

    let dd = "";

    if (selected.length) {
      dd = getRoleByValue(selected, podianRole);
    }

    if (dd) {
      query += "&role_ids=" + dd;
    }

    if (cityId) {
      query += "&city_ids=" + cityId;
    }

    if (Object.keys(dateRange).length > 0) {
      query += "&from_date=" + dateRange.start + "&to_date=" + dateRange.end;
    }

    if (shoots) {
      query += "&shoots=" + shoots;
    }

    if (hours) {
      query += "&hours=" + hours;
    }

    if (selectedCategories.length) {
      const ids = Object.groupBy(
        categoriesW.filter((v) => selectedCategories.includes(v.name)),
        (p) => p.id
      );
      const oids = Object.keys(ids).join(",");
      //   console.log(oids);
      query += "&category_ids=" + oids;
    }

    // console.log(selectedCategories);

    // console.log(query);

    window.open(
      API_URL.baseUrl +
        API_URL.podianTotalShoot +
        "?apikey=" +
        tokens.apikey +
        "&token=" +
        tokens.token +
        "&export=1" +
        query
    );
  };

  const getData = async () => {

    setIsLoading(true);

    let roleIds = "";

    if (selected.length) {
      roleIds = getRoleByValue(selected, podianRole);
    }

    const ids = Object.groupBy(
      categoriesW.filter((v) => selectedCategories.includes(v.name)),
      (p) => p.id
    );
    const oids = Object.keys(ids).join(",");

    const d = await GET_REPORT_Total_Shoot_By_Hours(
      roleIds,
      cityId,
      dateRange,
      oids,
      shoots,
      hours
    );

    if (d && d.s) {
      if (d.r) {
        setRow(d.r);
      } else {
        setRow([]);
      }
    }
    setIsLoading(false);
  };

  const handleChange = async (e) => {
    const {
      target: { value },
    } = e;
    setSelected([...value]);
  };

  function getRoleByValue(v, t) {
    let q = "";
    for (let d of t.r) {
      if (v.includes(d.name)) {
        q += d.id + ",";
      }
    }

    if (q.length) {
      q = q.substring(0, q.length - 1);
    }

    return q;
  }

  const getValue = (v) => {
    let r = "1,0";
    if (v == "All") {
      r = "1,0";
    } else if (v == "Active") {
      r = "1";
    } else if (v == "Pending") {
      r = "0";
    } else {
      r = "-1";
    }

    return r;
  };

  async function getRoles() {
    const d = await GETROLE();
    podianRole = d;
    names = [];

    for (let v of d.r) {
      names.push(v.name);
    }
  }

  useEffect(() => {
    getRoles();
    getCities();
    getCategories();
  }, []);

  useEffect(() => {
    getData();
  }, [cityId, dateRange, selected, selectedCategories]);

  const columns = [
    {
      field: "id",
      headerName: "Sr.",
      filterable: false,
      headerAlign: "start",
      align: "center",
      width: 65,
      renderCell: (params) => {
        return (
          <Typography fontSize="12.4px">
            {params.api.getRowIndex(params.row.id) + 1}
          </Typography>
        );
      },
    },
    // { field: "id", headerName: "ID", width: 50 },
    {
      field: "photo_thumb",
      headerName: "Profile",
      headerAlign: "center",
      align: "center",
      width: 100,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Avatar
          sx={{
            width: "1.9rem",
            height: "1.9rem",
            objectFit: "fill",
            my: "0.5rem",
          }}
          src={API_URL.baseUrl + API_URL.userProfileImage + params.value}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 210,
      flex: 1,
      minWidth: 210,
      renderCell: (params) => {
        return <Typography fontSize="12.4px">{params.row.name}</Typography>;
      },
    },
    {
      field: "shoots",
      headerName: "Shoots",
      headerAlign: "center",
      align: "center",
      width: 100,
      flex: 1,
      minWidth: 100,
    },
  ];

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={3}>
        <SearchBox
          search={shoots}
          type="number"
          lable="Shoots"
          // cursor={}
          onChange={(v) => {
            setShoots(v.target.value);
            if (!v.target.value) {
              getData();
            }
          }}
          onClick={() => getData()}
        />

        <SearchBox
          search={hours}
          type="number"
          lable="Hours"
          // cursor={}
          onChange={(v) => {
            setHours(v.target.value);
            if (!v.target.value) {
              getData();
            }
          }}
          onClick={() => getData()}
        />

        <MultiSelection
          selected={selected}
          handleChange={handleChange}
          names={names}
          lable={"Role"}
          multiple={true}
        />

        <MultiSelection
          multiple={true}
          names={categories}
          handleChange={handleSelectCategories}
          selected={selectedCategories}
          lable="Categories"
        />

        <MultiSelection
          names={cities}
          handleChange={handleSelectCity}
          selected={selectedCities}
          lable="Cities"
          multiple={true}
        />

        <Box
          sx={{
            bgcolor: "white",
            boxShadow: "3px 3px 7px 0px #00000040",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingY: ".245rem",
            gap: ".1rem",
          }}
        >
          <Box width="95%">
            <Box
              sx={{
                display: "flex",
                gap: ".3rem",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  borderRadius: "0",
                  padding: "0",
                }}
              >
                <img
                  src={require("../../../Assets/calendar-icon.png")}
                  style={{ objectFit: "fill", width: "11px" }}
                  alt=""
                />
              </IconButton>
              <Typography fontSize="10px">Date Select</Typography>
            </Box>
          </Box>

          <Box width="95%">
            <CustomProvider theme={theme.palette.mode}>
              <DateRangePicker
                className="date"
                placeholder="dd/mm/yyyy ~ dd/mm/yyyy"
                appearance="subtle"
                placement="bottomEnd"
                caretAs="a"
                style={{
                  backgroundColor: "#F6F6F6",
                  width: "11rem",
                  height: "1.7rem",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
                size="sm"
                onChange={(v) => handleSelectDate(v ?? {})}
                ranges={[
                  {
                    label: "Yesterday",
                    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
                  },
                  {
                    label: "Today",
                    value: [new Date(), new Date()],
                  },
                  {
                    label: "Tomorrow",
                    value: [addDays(new Date(), 1), addDays(new Date(), 1)],
                  },
                  {
                    label: "Last 7 days",
                    value: [subDays(new Date(), 6), new Date()],
                  },
                  {
                    label: "This week",
                    value: [
                      subDays(
                        new Date(
                          `${firstdayW.getFullYear()}-${
                            firstdayW.getMonth() + 1
                          }-${firstdayW.getDate()}`
                        ),
                        0
                      ),
                      addDays(
                        new Date(
                          new Date(
                            `${lastdayW.getFullYear()}-${
                              lastdayW.getMonth() + 1
                            }-${lastdayW.getDate()}`
                          )
                        ),
                        0
                      ),
                    ],
                  },
                  {
                    label: "This month",
                    value: [
                      subDays(
                        new Date(
                          `${today.getFullYear()}-${today.getMonth() + 1}-01`
                        ),
                        0
                      ),
                      addDays(
                        new Date(
                          new Date(
                            `${today.getFullYear()}-${
                              today.getMonth() + 1
                            }-${lastDayOfMonth(today).getDate()}`
                          )
                        ),
                        0
                      ),
                    ],
                  },
                  {
                    label: "This year",
                    value: [
                      subDays(new Date(`${today.getFullYear()}-01-01`), 0),
                      addDays(
                        new Date(new Date(`${today.getFullYear()}-12-31`)),
                        0
                      ),
                    ],
                  },
                ]}
              />
            </CustomProvider>
          </Box>
        </Box>
        <Button
          sx={{ ml: 1, mt: 2 }}
          variant="contained"
          size="small"
          onClick={() => geData()}
        >
          Export
        </Button>
      </Box>
      <DataGrideView
        isLoading={isLoading}
        rows={rows}
        columns={columns}
        sx={{ height: "100%", mt: 3, width: "100%" }}
        autoHeight={true}
      />
    </Box>
  );
}

export default TotalShootsByHours;
