import { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import three_dots from "../../../../../Assets/Icons for pages/three_dots.png";
import Link from "../../../../../Assets/Link.png";
import SubmittedLink from "./SubmittedLink";
import SubmittedLinksHistory from "./SubmittedLinksHistory";
import SplitButton from "../../../../../Component/Button/SplitButton";
import CustomerSubmitLinkModal from "./CustomerSubmitLinkModal";
import { format } from "date-fns";

const AlreadySubmitted = ({
  v,
  value,
  handleOpenAddLink,
  handleOpenEditLink,
  handleOpenDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [openSplitButton, setOpenSplitButton] = useState(false);

  return (
    <>
      <Stack
        width="35rem"
        bgcolor="white"
        boxShadow="3px 3px 7px 0px #00000040"
        sx={{ borderRadius: "5px" }}
      >
        {/*Header*/}
        <Box
          sx={{
            bgcolor: "#FFAB30",
            height: "2.370rem",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: "0.8rem",
          }}
        >
          <Typography fontSize="15px" fontWeight="500">
            Customer submit link
          </Typography>
          <SplitButton
            key={1}
            dataId={1}
            title={"split"}
            isEdit={true}
            isDelete={true}
            isHistory={true}
            isAdd={true}
            onAdd={handleOpenAddLink}
            onEdit={handleOpenEditLink}
            onDelete={handleOpenDelete}
            onHistory={handleOpenPopOver}
            deleteText={"Delete"}
            change={true}
          />
          {openSplitButton && ""}
        </Box>

        <Stack direction="row" bgcolor="white">
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              width: "10.2rem",
              pl: "0.8rem",
              paddingY: "0.5rem",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid white",
            }}
          >
            <Typography fontSize="13px">Editor Name</Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              pl: "0.8rem",
              paddingY: "0.5rem",
              bgcolor: "white",
              borderBottom: "1px solid #EFEFEF",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              {v?.editor ?? "-"}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" bgcolor="white">
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              width: "10.2rem",
              pl: "0.8rem",
              paddingY: "0.5rem",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid white",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              Number of Clicks
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              pl: "0.8rem",
              paddingY: "0.5rem",
              bgcolor: "white",
              borderBottom: "1px solid #EFEFEF",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              {v.clicks}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" bgcolor="white">
          <Box
            sx={{
              bgcolor: "#EFEFEF",
              width: "10.2rem",
              pl: "0.8rem",
              paddingY: "0.5rem",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid white",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              Number of Clips
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              pl: "0.8rem",
              paddingY: "0.5rem",
              bgcolor: "white",
              borderBottom: "1px solid #EFEFEF",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              {v.clips}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" bgcolor="white">
          <Box
            sx={{
              width: "10.2rem",
              bgcolor: "#EFEFEF",
              width: "8.3rem",
              pl: "0.8rem",
              paddingY: "0.5rem",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid white",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              Link Expiry-Date
            </Typography>
          </Box>

          <Box
            sx={{
              width: "80%",
              pl: "0.8rem",
              paddingY: "0.5rem",
              bgcolor: "white",
              borderBottom: "1px solid #EFEFEF",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              {!v?.expiry
                ? "-"
                : format(Date.parse(v.expiry), "yyyy-MMM-dd hh:mm a")}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" bgcolor="white">
          <Box
            sx={{
              width: "10.2rem",
              bgcolor: "#EFEFEF",
              width: "8.3rem",
              pl: "0.8rem",
              paddingY: "0.5rem",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid white",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              Link Created-Date
            </Typography>
          </Box>

          <Box
            sx={{
              width: "80%",
              pl: "0.8rem",
              paddingY: "0.5rem",
              bgcolor: "white",
              borderBottom: "1px solid #EFEFEF",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              {!v?.created_at
                ? "-"
                : format(Date.parse(v.created_at), "yyyy-MMM-dd hh:mm a")}
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" bgcolor="white">
          <Box
            sx={{
              width: "10.2rem",
              bgcolor: "#EFEFEF",
              width: "8.3rem",
              pl: "0.8rem",
              paddingY: "0.5rem",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid white",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              Link Type
            </Typography>
          </Box>

          <Box
            sx={{
              width: "80%",
              pl: "0.8rem",
              paddingY: "0.5rem",
              bgcolor: "white",
              borderBottom: "1px solid #EFEFEF",
            }}
          >
            <Typography
              fontSize="13px"
              fontWeight="600"
              color={v.type == 1 ? "red" : "#0DA800"}
            >
              {`${v.type == 1 ? "RAW" : "Edited"}`}
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" bgcolor="white" borderRadius="5px">
          <Box
            sx={{
              width: "10.2rem",
              bgcolor: "#EFEFEF",
              width: "8.3rem",
              pl: "0.8rem",
              paddingY: "0.5rem",
              display: "flex",
              alignItems: "center",
              borderBottomRightRadius: "5px",
            }}
          >
            <Typography fontSize="13px" fontWeight="500">
              Link
            </Typography>
          </Box>

          <Box
            sx={{
              width: "80%",
              pl: "0.8rem",
              pr: "1rem",
              paddingY: "0.5rem",
              bgcolor: "white",
              borderBottomRightRadius: "5px",
              display: "flex",
              gap: "0.4rem",
            }}
          >
            <SubmittedLink link={v.link} />
          </Box>
        </Stack>
      </Stack>

      {/*Add New Link modal*/}
      {/*  <CustomerSubmitLinkModal
           open={openAddNewLink}
          setOpen={setOpenAddNewLink}
         /> */}

      {/*Submitted links history modal*/}
      <SubmittedLinksHistory
        rows={value}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClosePopOver}
      />
    </>
  );
};

export default AlreadySubmitted;
