import { InputAdornment, TextField } from "@mui/material";
import React from "react";

const SearchBox = ({
  search,
  onChange,
  onClick,
  cursor,
  type = "text",
  lable,
}) => {
  return (
    <TextField
      type={type}
      label={lable}
      value={search}
      onChange={onChange}
      placeholder={lable ?? "Search"}
      size="small"
      style={{
        display: "flex",
        justifyContent: "center",
        alingItems: "center",
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
      sx={{
        width: "10.1rem",
        backgroundColor: "#FFFFFF",
        boxShadow: "2px 3px 4px rgba(0,0,0,0.2)",
        borderRadius: "6px",
        "& fieldset": { border: "none" },
        "& .MuiFormLabel-root": {
          color: "#969696",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "primary.main",
        },
        "& .css-j43tob-MuiInputBase-root-MuiOutlinedInput-root": {
          fontSize: "14px",
        },
        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
          padding: "0",
          paddingLeft: ".5rem",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <img
              style={{
                cursor: cursor ? "pointer" : "default",
                width: "12.6px",
                objectFit: "fill",
              }}
              src={require("../../Assets/Search_icon.png")}
              alt=""
              onClick={onClick}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBox;
