import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function LocationSearchInput({address , handleAddress, updateLatLng,label="Address",size="small",disabled=false,updateArea}) {
  

  const handleSelect = (address) => {
    handleAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        if(updateArea){
          updateArea(results);
        }
        // console.log(results)
        return getLatLng(results[0]);
      })
      .then((latLng) => updateLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };
  return (
    <PlacesAutocomplete    
      value={address}
      onChange={handleAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box>
          <TextField
          
            size={size}
            label={label}
            sx={{
              mt: 2,
              width: "100%",
            }}
            {...getInputProps({
              disabled:disabled,
              placeholder: "Search Places ...",
              className: "location-search-input",
            })}
          />
          <Box className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              //   const style = suggestion.active
              //     ? { backgroundColor: '#fafafa', cursor: 'pointer' }
              //     : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <Box
                sx={{
                    cursor:"pointer",
                    mb:1,
                    borderBottom:"solid 0.01px",
                    borderBottomColor:"divider"
                }}
                  width={"250px"}
                  key={suggestion.description}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    // style,
                  })}
                >
                  <Typography
                    sx={
                      {
                        color:`${suggestion.active
                            ? 'primary.main' : 'info.main' }`
                      }
                    }
                    variant="subtitle2"
                  >
                    {suggestion.description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}

export default LocationSearchInput;
