import React, { useState } from "react";
import { Box, Modal, TextField, Tooltip, Typography } from "@mui/material";
import { GrClose } from "react-icons/gr";
import TextFieldLarge from "../../../Component/TextFields/TextFieldLarge";
import TextFieldDuo from "../../../Component/TextFields/TextFieldDuo";
import ButtonView from "../../../Component/Button/ButtonView";
import InfoIcon from "../../../Assets/InfoIcon.png";

import { ADD_UPDATE_CITY } from "../../../Apis/city_api";
import LocationSearchInput from "../../../Component/AutoComplate/AddressAutoComplate";
import { ToastShow } from "../../../Component/Toast/Toast";
import ReactDragListView from "react-drag-listview/lib/index";
import LocationSearchField from "../../../Component/TextFields/LocationSearchField";
import TextFieldChip from "../../../Component/TextField/TextFieldChip";
import ChipsField from "../../../Component/TextFields/ChipsField";
import RadioButtonNew from "../../../Component/RadioButton/RadioButtonNew";
import TextFieldView from "../../../Component/TextField/TextFieldView";

const AddCitiesModal = ({
  open,
  handleClose,
  id,
  row,
  chip,
  getData,
  statusV,
  s,
}) => {
  //Code copied from CitiesAdd file
  const [chips, setChips] = React.useState(chip ?? []);
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState(row ? row.name : "");
  const [address, setAddress] = React.useState(row ? row.address : "");
  const [freeDistance, setFreeDistance] = React.useState(
    row ? row.free_distance : ""
  );
  const [charges, setCharges] = React.useState(row ? row.charge_per_km : "");
  const [status, setStatus] = React.useState(statusV);
  console.log(statusV);
  const [distanceCharge, setDistanceCharge] = React.useState(
    row ? row.fixed_distance_charges : ""
  );
  const [priceHike, setPriceHike] = React.useState(row ? row.price_hike : "");
  const [canEdit, setCanEdit] = React.useState(row ? row.canEditBefore : 0);
  const [sgstLabel, setSgstLabel] = React.useState(
    row ? row.sgst_label : "SGST"
  );
  const [cgstLabel, setCgstLabel] = React.useState(
    row ? row.cgst_label : "CGST"
  );
  const [cgst, setCgst] = React.useState(row ? row.cgst : 9);
  const [sgst, setSgst] = React.useState(row ? row.sgst : 9);
  const [providerSM, setProviderSM] = React.useState(
    row ? row.provider_support_mobile : ""
  );
  const [latLng, setLatLng] = React.useState(
    row
      ? {
          lat: row.latitude,

          lng: row.longitude,
        }
      : {}
  );
  const [rowData, setRowData] = useState(row ? row.categories ?? [] : []);
  const [bufferTime, setBufferTime] = React.useState(row ? row.bufferTime : "");
  const [orderCanAfterTime, setOrderCanAfterTime] = React.useState(
    row ? row.orderCanAfterTime : ""
  );
  //Code copied from CitiesAdd file

  //For ProviderSm
  const maxTotalLength = 15;
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatPhoneNumber(inputValue);
    setProviderSM(formattedValue);
  };
  const formatPhoneNumber = (inputValue) => {
    const digitsOnly = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

    let formattedValue = "+";
    for (let i = 0; i < digitsOnly.length && i < maxTotalLength - 1; i++) {
      if (i === 2 || i === 7) {
        formattedValue += " " + digitsOnly[i];
      } else {
        formattedValue += digitsOnly[i];
      }
    }

    // Handle case when user clears all input
    if (digitsOnly.length === 0) {
      formattedValue = "";
    }

    return formattedValue;
  };

  //Code copied from CitiesAdd file
  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please fill name");
    } else if (!address) {
      ToastShow("Please fill address");
      // } else if (!freeDistance) {
      //   ToastShow("Please fill free distance");
    } else if (charges === "") {
      ToastShow("Please fill charge per KM");
    } else if (status == 2) {
      ToastShow("Please select Active/InActive status");
    } else if (!chips.length) {
      ToastShow("Please fill synonyms name");
    }
    //  else if (!priceHike) {
    //   ToastShow("Please fill price hike");
    // }
    else if (distanceCharge === "") {
      ToastShow("Please fill distance charge");
    } else {
      setIsLoading(true);
      // console.log("canEdit",canEdit);
      const res = await ADD_UPDATE_CITY({
        name: name,
        status: status,
        latitude: latLng.lat,
        longitude: latLng.lng,
        address: address,
        freeDistance: "0.0",
        fixedDistanceCharges: distanceCharge ?? 0,
        priceHike: priceHike ?? 0,
        // freeDistance,
        chargePerKm: charges ?? 0,
        alternateNames: chips,
        // categories: rowData,
        row: row,
        bufferTime: bufferTime,
        orderCanAfterTime: orderCanAfterTime,
        canEditBefore: canEdit,
        sgstLabel: sgstLabel,
        sgst: sgst,
        cgstLabel: cgstLabel,
        cgst: cgst,
        id: id ?? 0,
        providerSM: providerSM,
      });
      setIsLoading(false);
      if (res && res.s == 1) {
        if (!id) {
          setName("");
          setAddress("");
          setCharges("");
          setChips([]);
          setDistanceCharge("");
          setLatLng({});
          setPriceHike("");
          setStatus(0);
          handleClose();
          ToastShow("Added successfully");
        } else {
          handleClose(false);
          ToastShow("Updated successfully");
        }
        window.location.reload();
      }
    }
  };

  const handleChange = (newChips) => {
    setChips(newChips);
  };

  const handleAddress = (address) => {
    // console.log(address);
    setAddress(address);
  };

  const updateLatLng = (latLngs) => {
    setLatLng(latLngs);
    // console.log("lat",latLng.lat, "lng",latLng.lng);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(4px)",
        bgcolor: "rgba(105, 55, 0, 0.4)",
        display: "grid",
        placeItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "6px",
          overflow: "auto",
          height: "96%",
        }}
      >
        <Box
          sx={{
            width: "31.25rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: 2.2,
          }}
        >
          <Typography
            sx={{
              backgroundColor: "#F6F6F6",
              width: "100%",
              height: "44px",
              padding: "10px",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "17px",
              fontWeight: "500",
              userSelect: "none",
            }}
          >
            {!id ? "Add City" : "Edit City"}
            <GrClose style={{ cursor: "pointer" }} onClick={handleClose} />
          </Typography>

          <Box
            width="100%"
            bgcolor="#F6F6F6"
            borderRadius="6px"
            padding="10px"
            display="flex"
            flexDirection="column"
            gap=".8rem"
          >
            <TextFieldLarge
              label="City Name"
              placeholder="Enter City Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              width="100%"
              alternateBg="#F6F6F6"
            />

            <LocationSearchField
              address={address}
              handleAddress={handleAddress}
              updateLatLng={updateLatLng}
              width="100%"
            />

            {/*Charge per km & order can after time*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Charge per km
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter km"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setCharges(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                    value={charges}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Fix distance charge
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Time"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setDistanceCharge(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                    value={distanceCharge}
                  />
                </Box>
              </Box>
            </Box>

            {/*orderCanAfterTime & bufferTime*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Order can after time
                    </Typography>
                    <Tooltip title="Customer can book the shoot after given time.">
                      <img src={InfoIcon} height="10px" alt="" />
                    </Tooltip>
                  </Box>

                  <TextField
                    placeholder="Enter Time"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={orderCanAfterTime}
                    onChange={(e) => setOrderCanAfterTime(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Buffer time
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Time"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={bufferTime}
                    onChange={(e) => setBufferTime(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Box>
              </Box>
            </Box>

            {/*hikePrice & canEditBefore*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Hike price %
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter price %"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={priceHike}
                    onChange={(e) => setPriceHike(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      Can edit before ?
                    </Typography>
                    <Tooltip title="Customer can edit order before shoot time.">
                      <img src={InfoIcon} height="9.3px" alt="" />
                    </Tooltip>
                  </Box>

                  <TextField
                    placeholder="Enter Number of Clips"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={canEdit}
                    onChange={(e) => setCanEdit(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Box>
              </Box>
            </Box>

            {/*Capital SGST 1 & Capital SGST 2*/}
            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      SGST
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Label"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={sgstLabel}
                    onChange={(e) => setSgstLabel(e.target.value)}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      SGST Applicable %
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter GST"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={sgst}
                    onChange={(e) => setSgst(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Box>
              </Box>
            </Box>

            {/*Capital CGST 1 & Capital CCGST 2*/}

            <Box
              width="100%"
              bgcolor="white"
              borderRadius="6px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="95%" display="flex" justifyContent="space-between">
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      CGST
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter Label"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={cgstLabel}
                    onChange={(e) => setCgstLabel(e.target.value)}
                  />
                </Box>
                <Box sx={{ width: "49%", marginY: "0.350rem" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".6rem",
                    }}
                  >
                    <Typography fontSize="12px" style={{ userSelect: "none" }}>
                      CGST Applicable %
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Enter CGST"
                    type="number"
                    size="small"
                    sx={{
                      width: "100%",
                      height: "2.3rem",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.8rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={cgst}
                    onChange={(e) => setCgst(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Box>
              </Box>
            </Box>

            <TextFieldLarge
              label="Mobile no for support for provider"
              placeholder="Enter number"
              value={providerSM}
              onChange={handlePhoneNumberChange}
              maxLength={maxTotalLength}
              width="100%"
              alternateBg={true}
            />

            <ChipsField
              sx={{ width: "95%" }}
              chips={chips}
              handleChange={handleChange}
              label="Enter names"
            />

            {/*Radio button*/}
            <RadioButtonNew
              value={status}
              handleChange={setStatus}
              width="100%"
              btnWidth="30%"
              justifyContent="none"
              gapInButtons="1rem"
              labelA="Active"
              labelB="Inactive"
            />
          </Box>

          <ButtonView
            lable={!id ? "Create City" : "Update City"}
            sx={{ borderRadius: "6px", height: "41px" }}
            fontSize="18px"
            fontWeight="500"
            onClick={handleOnSubmit}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCitiesModal;
