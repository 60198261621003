import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { GrClose } from "react-icons/gr";
import ButtonView from "../../../Component/Button/ButtonView";
import { useState } from "react";
import { ToastShow } from "../../../Component/Toast/Toast";
import { BsChevronDown } from "react-icons/bs";
import { ADD_NOTIFICATION } from "../../../Apis/notification_api";
import { format } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const AddNotificationModal = ({ open, handleClose,row }) => {

  const [title, setTitle] = React.useState(row?.title ?? "");
  const [message, setMessage] = React.useState(row?.message ?? "");
  const [dateTime, setDateTime] = useState(row?.date_time ? Date.parse(row.date_time) : Date.now());
  const [notificationFor, setNotificationFor] = useState(row?.app ?? 0);

  const handleChange = (e) => {
    setNotificationFor(e.target.value);
  };

  const addNotification = async () => {
    if (!title) {
      ToastShow("Please fill title");
    } else if (!message) {
      ToastShow("Please fill message");
    } else {

      //console.log(dateTime,"dateTime")
      const d = await ADD_NOTIFICATION({
        title: title,
        message: message,
        app: notificationFor,
        date_time: format(dateTime, "yyyy-MM-dd HH:mm:ss"),
        id:row?.id ?? 0
      });

      if (d && d.s) {
        ToastShow(row?.id ?  "Edited successfully":"Created successfully");
        setTitle("");
        setMessage("");
        setDateTime(new Date());
        setNotificationFor(0);
        handleClose(1);
      } else {
        ToastShow(d?.m ?? "Opps! something went wrong please try again.");
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(4px)",
          bgcolor: "rgba(105, 55, 0, 0.4)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "6px",
            display: "flex",
            padding: "1.3rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "540px",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                backgroundColor: "#F6F6F6",
                height: "44px",
                width: "540px",
                padding: ".6rem",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.150rem",
                fontWeight: "500",
              }}
            >
             {row?.id ? "Edit Notification" : "Create Notification"} 
              <GrClose style={{ cursor: "pointer" }} onClick={handleClose} />
            </Typography>

            <Box
              sx={{
                bgcolor: "#F6F6F6",
                width: "540px",
                height: "468px",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                gap: ".9rem",
                alignItems: "center",
                py: "10px",
              }}
            >
              <Box
                sx={{
                  bgcolor: "white",
                  width: "95%",
                  height: "85px",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "6px",
                    width: "100%",
                    height: "85px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <Box width="95%" paddingTop=".3rem" paddingBottom=".1rem">
                    <Typography fontSize="13.5px">
                      Target Notification for
                    </Typography>
                  </Box>

                  <Box width="95%">
                    <FormControl
                      sx={{
                        width: "100%",
                        borderRadius: "6px",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      {/* <InputLabel id="demo-multiple-checkbox-label">{lable}</InputLabel> */}
                      <Select
                        labelId="notificationFor"
                        size="small"
                        defaultValue=""
                        id="selection"
                        label="Notification for"
                        multiple={false}
                        value={notificationFor}
                        onChange={handleChange}
                        input={<OutlinedInput label={"Categories"} />}
                        style={{
                          backgroundColor: "#F6F6F6",
                          "& fieldset": { border: "none" },
                          color: "#969696",
                          borderRadius: "6px",
                        }}
                        IconComponent={BsChevronDown}
                        sx={{
                          height: "42px",
                          "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                            {
                              fontSize: "0.820rem",
                            },
                        }}
                      >
                        <MenuItem value={0}>
                          <Typography fontSize="0.8rem" color="#969696">
                            All
                          </Typography>
                        </MenuItem>
                        <MenuItem value={1}>
                          <Typography color="black" fontSize="0.8rem">
                            Customer
                          </Typography>
                        </MenuItem>
                        <MenuItem value={2}>
                          <Typography color="black" fontSize="0.8rem">
                            Provider
                          </Typography>
                        </MenuItem>
                        <MenuItem value={3}>
                          <Typography color="black" fontSize="0.8rem">
                            Admin & Support
                          </Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "6px",
                  width: "95%",
                  height: "85px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box width="95%" paddingTop=".3rem" paddingBottom=".1rem">
                  <Typography pl=".2rem" pb=".2rem" fontSize="13.5px">
                    Title
                  </Typography>

                  <TextField
                    placeholder="Enter Text"
                    type="text"
                    size="small"
                    sx={{
                      height: "42px",
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.820rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "6px",
                  width: "95%",
                  height: "166.4px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box width="95%" height="100%">
                  <Typography pl=".2rem" py=".2rem" fontSize="13.5px">
                    Message
                  </Typography>

                  <TextField
                    multiline
                    rows={5}
                    maxRows={5}
                    placeholder="Enter Message"
                    type="text"
                    size="small"
                    sx={{
                      backgroundColor: "#F6F6F6",
                      borderRadius: "6px",
                      width: "100%",
                      height: "120px",
                      "& fieldset": { border: "none" },
                      "& .MuiFormLabel-root": {
                        color: "#969696",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          fontSize: "0.820rem",
                        },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "6px",
                  width: "95%",
                  height: "85px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box width="95%" paddingTop=".3rem" paddingBottom=".1rem">
                  <Box
                    pl=".2rem"
                    pb=".2rem"
                    display="flex"
                    gap=".5rem"
                    alignItems="center"
                  >
                    <img
                      src={require("../../../Assets/calendar-icon.png")}
                      alt=""
                      style={{
                        width: "14px",
                        height: "13px",
                        objectFit: "fill",
                      }}
                    />
                    <Typography fontSize="13.5px">Date Select</Typography>
                  </Box>

                  <Box sx={{ backgroundColor: "#F6F6F6", borderRadius: "6px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                       
                        value={dateTime}
                        onChange={(v) => setDateTime(v)}
                        renderInput={(props) => (
                          <TextField
                            sx={{
                              width: "100%",
                              height: "42px",
                              display: "flex",
                              justifyContent: "center",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  fontSize: "0.820rem",
                                },
                            }}
                            size="small"
                            {...props}
                          />
                        )}
                        // disableOpenPicker
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
            </Box>

            <ButtonView
              lable= {row?.id ? "Edit Notification" : "Create Notification"} 
              sx={{ borderRadius: "6px", height: "44px" }}
              fontSize="18px"
              fontWeight="500"
              onClick={addNotification}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddNotificationModal;
