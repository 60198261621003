import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import ToastView, { ToastShow } from "../../Toast/Toast";
  import Compressor from "compressorjs";
  import { API_URL } from "../../../Apis/config";
import { ADD_UPDATE_WEB_FOR_PODIAN } from "../../../Apis/web/forPodian_api";
  
  function AddUpdateWebForPodianDailog({ open, handleOnClose, row }) {
    // console.log(row)
    const [photo, setPhoto] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = React.useState(row ? row.name : "");
    const [label, setLabel] = React.useState(
      row ? row.label : ""
    );
    const [url, setUrl] = React.useState(row ? row.url : "");
  
    const handleOnSubmit = async () => {
      if (!row && !photo) {
        ToastShow("Please select logo");
      } else if (!name) {
        ToastShow("Please enter name");
      } 
      else if (!label) {
        ToastShow("Please enter label");
      } 
      else if (!url) {
        ToastShow("Please enter url");
      } 
      else {
        setIsLoading(true);
        const d = await ADD_UPDATE_WEB_FOR_PODIAN({
          photo: photo,
          row: row,
          name: name,
          label:label,
          url:url
        });
        setIsLoading(false);
        if (d && d.s) {
          ToastShow("Successfully added");
          handleOnClose(1);
        } else {
          ToastShow("Something went wrong. Please try again.");
        }
      }
    };
  
    return (
      <>
        <Dialog open={open} onClose={() => handleOnClose(0)}>
          <DialogTitle>{!row ? "Add For-Podian" : "Update For-Podian"}</DialogTitle>
          <DialogContent
            sx={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* photo one */}
            <label
              htmlFor="photoone"
              style={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  height: "150px",
                  width: "85%",
                  border: "dotted .3px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  objectFit: "fill",
                  p: "1px",
                  mx: "auto",
                }}
              >
                {row?.photo || photo ? (
                  <img
                    style={{ borderRadius: "5px" }}
                    height="150px"
                    width="100%"
                    src={
                      photo
                        ? URL.createObjectURL(photo)
                        : API_URL.baseUrl + "uploads/web/forpodian/" + row?.photo
                    }
                    alt="photo image"
                  />
                ) : (
                  <Typography sx={{ width: "85%" }}>
                    Click here to select the feature logo
                  </Typography>
                )}
              </Box>
            </label>
  
            <input
              hidden
              id="photoone"
              accept="image/*"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  new Compressor(event.target.files[0], {
                    quality: 0.8,
                    success: (compressedResult) => {
                      setPhoto(compressedResult);
                    },
                  });
                }
              }}
            />
  
            <TextField
              sx={{
                width: "85%",
                mt: 2,
              }}
              type="text"
              size="small"
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{
                width: "85%",
                mt: 2,
              }}
              type="text"
              size="small"
              fullWidth
              label="Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            <TextField
              sx={{
                width: "85%",
                mt: 2,
              }}
              type="url"
              size="small"
              fullWidth
              label="Url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </DialogContent>
  
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={handleOnSubmit}
                variant="text"
                sx={{ color: "#F8B03F" }}
              >
                Submit
              </Button>
            )}
            <Button
              onClick={() => handleOnClose(0)}
              variant="text"
              sx={{ color: "#F8B03F" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastView />
      </>
    );
  }
  
  export default AddUpdateWebForPodianDailog;
  