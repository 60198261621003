import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import {ADD_UPDATE_NEW_ON_SPOT_ROLE } from "../../Apis/new_on_spot_api";
import MultiSelection from "../Multiselection/MultiSelection";
import { useEffect } from "react";

function AddNewOnSpotRole({
  open,
  handleOnClose,
  on_spot_id,
  row,
  role,
  roleW,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [roleId, setSelectedId] = React.useState([]);

  const handleSelectRole = async (e) => {
    const {
      target: { value },
    } = e;

    setSelectedRoles([...value]);
    const c = roleW.filter((v) => value.includes(v.name));
    const i = [];

    for (let d of c) {
      i.push(d.id);
    }
    setSelectedId(i);
  };

  const handleOnSubmit = async () => {
    if (!roleId.length) {
      ToastShow("Please select the role");
    } else {
      setIsLoading(true);
      const d = await ADD_UPDATE_NEW_ON_SPOT_ROLE({
        role_ids: roleId.map((v) => v).join(","),
        on_spot_id: on_spot_id,
      });
      setIsLoading(false);
      if (d && d.s) {
        ToastShow("Successfully added");
        handleOnClose(1);
      } else {
        ToastShow("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    const name = row.map((v) => v.name);
    const rId = row.map((v) => v.role_id);

    setSelectedId(rId);
    setSelectedRoles(name);
  }, []);

  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>{!row?.id ? "Add Role" : "Update Role"}</DialogTitle>
        <DialogContent
          sx={{
            width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MultiSelection
            multiple={true}
            names={role}
            handleChange={handleSelectRole}
            selected={selectedRoles}
            lable="Roles"
          />
        </DialogContent>

        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Submit
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastView />
    </>
  );
}

export default AddNewOnSpotRole;
