import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import { UPDATE_POLICY_DETAILS } from "../../Apis/policy_api";
import TextFieldView from "../TextField/TextFieldView";
import { ToastShow } from "../Toast/Toast";

function UpdatePolicy({ data, open, handleOnClose }) {
  // console.log(chip)
  const [isLoading, setIsLoading] = React.useState(false);
  const [name, setName] = React.useState(data.name ?? "");
  const [value, setValue] = React.useState(data.value ?? "");

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const handleOnSubmit = async () => {
    if (!name) {
      ToastShow("Please fill name");
    } else if (!value) {
      ToastShow("Please fill value");
    } else {
      setIsLoading(true);
      const res = await UPDATE_POLICY_DETAILS({
        value: value,
        id: data.id,
      });
      setIsLoading(false);
      if (res && res.s == 1) {
        handleOnClose(1);
      }
    }
  };

  const handleName = (v) => {
    setName(v);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => handleOnClose(0)}
      sx={{
        backdropFilter: "blur(3px)",
        bgcolor: "rgba(105, 55, 0, 0.3)",
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Update Policy
        <DialogActions>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleOnSubmit} variant="text">
              Update
            </Button>
          )}
          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", mt: 1 }}>
          {/* <TextFieldView
              value={name}
              onchange={handleName}
              label="Policy name"
              type="text"
              sx={{ width: "100%",mb:1 }}
            /> */}

          <ReactQuill
            style={{ width: "100%", marginBottom: "10px" }}
            theme="snow"
            value={value}
            onChange={(e) => {
              setValue(e);
            }}
            formats={formats}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UpdatePolicy;
