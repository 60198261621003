import { Box, Stack, Typography } from "@mui/material";
import { format } from "date-fns";

const CancelReason = ({ cancel_dt, cancel_reason }) => {
  return (
    <Box
      width="100%"
      boxShadow="3px 3px 7px 0px #00000040"
      sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
    >
      {/*Header*/}
      <Box
        sx={{
          width: "100%",
          bgcolor: "#e500002a",
          height: "2.370rem",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontSize="15px" fontWeight="500" ml="0.8rem">
          Cancel Reason
        </Typography>
      </Box>

      {/*Rows*/}
      <Stack direction="row" bgcolor="white">
        <Box
          sx={{
            bgcolor: "#e500002a",
            height: "3.1rem",
            width: "8.8vw",
            pl: "0.8rem",
            paddingY: "0.4rem",
            borderBottom: "1px solid #e500002a",
            borderRight: "1px solid #e500002a",
          }}
        >
          <Typography fontSize="13px">Date</Typography>
        </Box>

        <Box
          sx={{
            height: "3.1rem",
            width: "75vw",
            pl: "0.8rem",
            paddingY: "0.4rem",
            bgcolor: "white",
            borderBottom: "1px solid #e500002a",
            backgroundColor:"#e500002a"
          }}
        >
          <Typography fontSize="13px" fontWeight="500">
            {!cancel_dt
              ? "-"
              : format(Date.parse(cancel_dt), "dd-MMM-yyyy hh:mm a")}
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction="row"
        bgcolor="white"
        sx={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
      >
        <Box
          sx={{
            bgcolor: "#e500002a",
            width: "8.8vw",
            pl: "0.8rem",
            paddingY: "0.5rem",
            display: "flex",
            alignItems: "center",
            borderRight: "1px solid #e500002a",
          }}
        >
          <Typography fontSize="13px">Reaosn</Typography>
        </Box>

        <Box
          sx={{
            width: "75vw",
            pl: "0.8rem",
            paddingY: "0.5rem",
            bgcolor: "white",
            borderBottomRightRadius: "5px",
            backgroundColor:"#e500002a"
          }}
        >
          <Typography fontSize="12.4px" >{cancel_reason}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default CancelReason;
