import React, { useState } from "react";
import TextFieldView from "../TextField/TextFieldView";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ToastShow } from "../Toast/Toast";
import { UPDATE_USER } from "../../Apis/users_api";
import { GrClose } from "react-icons/gr";
import ButtonView from "../Button/ButtonView";
import TextFieldSmall from "../TextFields/TextFieldSmall";

function ProviderEdit({ open, handleOpenClose, userId, data }) {
  const [isLoadingP, setIsLoadingP] = useState(false);
  const [fname, setFName] = useState(data?.first_name ?? "");
  const [lname, setLName] = useState(data?.last_name ?? "");
  const [mname, setMName] = useState(data?.middle_name ?? "");

  const handleOnSubmit = async () => {
    if (!fname) {
      ToastShow("Please enter first name");
      return;
    } else if (!lname) {
      ToastShow("Please enter last name");
      return;
    } else {
      setIsLoadingP(true);
      const res = await UPDATE_USER({
        fname,
        lname,
        mname: mname === "" ? " " : mname,
        userId,
      });

      if (res && res.s) {
        ToastShow("Update successfully");
        handleOpenClose(1);
      } else {
        ToastShow(res?.m ?? "Opps! something went wrong. Please try again.");
      }
      setIsLoadingP(false);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleOpenClose(0)}
        sx={{
          backdropFilter: "blur(2px)",
          bgcolor: "rgba(105, 55, 0, 0.230)",
        }}
      >
        <Stack
          direction="row"
          bgcolor="#F6F6F6"
          paddingY="0.450rem"
          paddingX="0.6rem"
          borderRadius="5px"
          justifyContent="space-between"
          alignItems="center"
          m={1.4}
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            sx={{ userSelect: "none" }}
          >
            Update Provider
          </Typography>
          <GrClose
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => handleOpenClose(0)}
          />
        </Stack>

        <Stack m={1.4} gap={2}>
          <TextFieldSmall
            label="First Name"
            placeholder="Enter First name"
            width="430px"
            value={fname}
            onChange={(e) => setFName(e.target.value)}
          />

          <TextFieldSmall
            label="Middle Name"
            placeholder="Enter Middle name"
            width="430px"
            value={mname === " " ? null : mname}
            onChange={(e) => setMName(e.target.value)}
          />

          <TextFieldSmall
            label="Last Name"
            placeholder="Enter Last name"
            width="430px"
            value={lname}
            onChange={(e) => setLName(e.target.value)}
          />

          <ButtonView
            lable="Add"
            onClick={handleOnSubmit}
            sx={{ width: "100%" }}
          />
        </Stack>
      </Dialog>
    </>
  );
}

export default ProviderEdit;
